import React from 'react'
import { GridSortModel } from '@mui/x-data-grid'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'

export const useDataGridQueryFiltersState = (defaultSort?: GridSortModel[number]) => {
    const [search, setSearch] = useQueryParams({
        page: NumberParam,
        sort: StringParam,
        field: StringParam,
        search: StringParam,
    })

    React.useEffect(() => {
        if (search.page === undefined && defaultSort) {
            setSearch(
                {
                    ...search,
                    page: 0,
                    field: defaultSort.field,
                    sort: defaultSort.sort,
                    search: undefined,
                },
                'replace',
            )
        }
    }, [defaultSort, search, setSearch])

    const handleSortChange = React.useCallback(
        (model: GridSortModel) => {
            if (model.length === 0) {
                return setSearch({ field: undefined, sort: undefined })
            }

            setSearch({ field: model[0].field, sort: model[0].sort })
        },
        [setSearch],
    )

    const handlePageChange = React.useCallback(
        (page: number) => {
            setSearch({ page })
        },
        [setSearch],
    )

    const handleSearchChange = React.useCallback(
        (query: string) => {
            setSearch({ search: query })
        },
        [setSearch],
    )

    return {
        search,
        handlePageChange,
        handleSortChange,
        handleSearchChange,
    }
}
