import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    SelectProps,
    FormHelperText,
} from '@mui/material'
import { Control, Controller, Path, FieldValues, PathValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export interface IOption {
    label: string
    value: number | string
    disabled?: boolean
    description?: string
}

interface IControlledSelectProps<T extends FieldValues> extends Omit<SelectProps, 'name' | 'error' | 'onChange'> {
    control: Control<T>
    name: Path<T>
    options: Array<IOption> | null
    defaultValue?: PathValue<T, Path<T>>
    label?: string
    hideDisabledOptions?: boolean
    onChangeCb?: (e: SelectChangeEvent<unknown>) => void
}

const ControlledMuiSelect = <T extends FieldValues>({
    name,
    label,
    control,
    options,
    onChangeCb,
    defaultValue,
    hideDisabledOptions,
    fullWidth,
    ...props
}: IControlledSelectProps<T>) => {
    const { t } = useTranslation()

    return (
        <Controller
            name={name}
            // defaultValue={defaultValue}
            control={control}
            render={({ field: { ref, value, ...rest }, fieldState: { error } }) => (
                <FormControl fullWidth={fullWidth} error={!!error?.message}>
                    {label && <InputLabel>{label}</InputLabel>}
                    <Select
                        {...rest}
                        {...props}
                        onChange={(e) => {
                            onChangeCb?.(e)
                            rest.onChange(e)
                        }}
                        inputProps={{ ref }}
                        MenuProps={{
                            style: { zIndex: 10000 },
                        }}
                        value={value ? value : props.multiple ? [] : ''}
                        error={Boolean(error?.message)}
                    >
                        {(options || []).map((option) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                disabled={option.disabled}
                                sx={{
                                    display: option.disabled && hideDisabledOptions ? 'none' : 'inherit',
                                }}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {error?.message && <FormHelperText>{t(error.message)}</FormHelperText>}
                </FormControl>
            )}
        />
    )
}
export default ControlledMuiSelect
