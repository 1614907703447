import * as yup from 'yup'
import { emailRegex } from './util'

const contact = yup.object().shape({
    email: yup.string().required('required').trim().matches(emailRegex, { message: 'invalidEmail' }),
})

export const sendLinkToCustomers = yup.object().shape({
    contacts: yup.array().of(contact).min(1, 'required').required('required'),
})
