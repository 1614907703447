import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Stack, styled } from '@mui/material'
import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { LoadingButton } from '@mui/lab'
import { Contact, Customer } from 'redux/types/customer.type'
import { contactSchema } from 'schemas/customers'
import { useUpdateCustomerMutation } from 'services/customers.service'
import { Organization } from 'redux/types/organization.type'
import { useUpdateOrganizationMutation } from 'services/organizations.service'

const DEFAULT_VALUES: Contact = {
    name: '',
    description: null,
    email: '',
    phoneNumber: null,
}

type DialogProps =
    | {
          contactType: 'customer'
          customer: Customer
          organization?: never
      }
    | { contactType: 'organization'; customer?: never; organization: Organization }

type ContactCreateEditDialogProps = { contact?: Contact | null } & DialogProps &
    Omit<ModalWrapperProps, 'title' | 'body' | 'actions'>

export const ContactCreateEditDialog: React.FC<ContactCreateEditDialogProps> = ({
    contact,
    handleClose,
    open,
    customer,
    contactType,
    organization,
}) => {
    const { t } = useTranslation()
    const [updateCustomer, { isLoading: isUpdatingCustomer }] = useUpdateCustomerMutation()
    const [updateOrg, { isLoading: isUpdatingOrg }] = useUpdateOrganizationMutation()

    const isUpdating = isUpdatingCustomer || isUpdatingOrg

    const { handleSubmit, control, reset } = useForm<Contact>({
        defaultValues: contact ? contact : DEFAULT_VALUES,
        resolver: yupResolver(contactSchema),
    })

    React.useEffect(() => {
        reset(contact || DEFAULT_VALUES)
    }, [contact, reset])

    const onClose = () => {
        reset()
        handleClose()
    }

    const handleSubmitForCustomer = async (data: Contact, newCustomer: Customer) => {
        if (contact) {
            const editContactIdx = newCustomer.contacts.findIndex((cContact) => cContact.email === contact.email)
            if (editContactIdx === -1) throw new Error('contact wasnt found, please report the issue')

            const updatedContacts = [
                ...newCustomer.contacts.slice(0, editContactIdx),
                data,
                ...newCustomer.contacts.slice(editContactIdx + 1),
            ]

            await updateCustomer({ ...newCustomer, contacts: updatedContacts }).unwrap()
            onClose()
            return
        }

        const updatedContacts = [...newCustomer.contacts, data]
        await updateCustomer({ ...newCustomer, contacts: updatedContacts }).unwrap()
        onClose()
    }

    const handleSubmitForOrganization = async (data: Contact, newOrg: Organization) => {
        const { address, id, contacts, email, name, organizationNumber, phoneNumber, type } = newOrg
        if (contact) {
            const editContactIdx = contacts.findIndex((cContact) => cContact.email === contact.email)

            if (editContactIdx === -1) throw new Error('contact wasnt found, please report the issue')

            const updatedContacts = [
                ...newOrg.contacts.slice(0, editContactIdx),
                data,
                ...newOrg.contacts.slice(editContactIdx + 1),
            ]

            await updateOrg({
                id,
                organization: {
                    address,
                    contacts: updatedContacts,
                    email,
                    name,
                    organizationNumber,
                    phoneNumber,
                    type,
                },
            }).unwrap()
            onClose()
            return
        }

        const updatedContacts = [...newOrg.contacts, data]

        await updateOrg({
            id,
            organization: {
                address,
                contacts: updatedContacts,
                email,
                name,
                organizationNumber,
                phoneNumber,
                type,
            },
        }).unwrap()
        onClose()
    }

    const onSubmit = async (data: Contact) => {
        if (contactType === 'customer') {
            await handleSubmitForCustomer(data, customer)
        } else {
            await handleSubmitForOrganization(data, organization)
        }
    }

    return (
        <ModalsWrapper
            sx={{
                zIndex: 9999,
            }}
            open={open}
            title={contact ? t('customerPage.contactEditDialogTitle') : t('customerPage.contactCreateDialogTitle')}
            handleClose={onClose}
            fullWidth={true}
            maxWidth="sm"
            body={
                <form id="customer-contact-form" onSubmit={handleSubmit(onSubmit)}>
                    <ContentWrapper>
                        <ControlledMuiTextField
                            control={control}
                            fullWidth
                            name="name"
                            variant="filled"
                            placeholder={t('customersPage.customerDialog.customerNamePlaceholder')}
                        />
                        <ControlledMuiTextField
                            control={control}
                            fullWidth
                            name="description"
                            variant="filled"
                            placeholder={t('common.description')}
                        />
                        <ControlledMuiTextField
                            control={control}
                            fullWidth
                            name="email"
                            variant="filled"
                            placeholder={t('common.email')}
                        />
                        <ControlledMuiTextField
                            control={control}
                            fullWidth
                            name="phoneNumber"
                            variant="filled"
                            placeholder={t('customersPage.customerDialog.phoneNumberPlaceholder')}
                        />
                    </ContentWrapper>
                </form>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button variant="outlined" onClick={onClose} color="secondary" disabled={isUpdating}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="customer-contact-form"
                        loading={isUpdating}
                    >
                        {t('common.confirm')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
