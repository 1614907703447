import React, { ReactNode } from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import { useIsAuthenticated } from 'redux/slices/auth.slice'
import { ROUTES } from 'constants/routes'

interface PrivateRouteProps {
    children: ReactNode
}
// used for private routes with user/admin role users
const PrivateRoute = ({ children }: PrivateRouteProps) => {
    const { pathname: currentPath } = useLocation()
    const isAuthenticated = useIsAuthenticated()

    // // users with higher power can view these routes i guess?
    if (!isAuthenticated) {
        return <Navigate to={ROUTES.login} state={{ from: currentPath }} replace />
    }

    return <>{children}</>
}

export default PrivateRoute
