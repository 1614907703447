import * as yup from 'yup'
import { emailRegex } from './util'
import { ForgotPasswordRequest, SendLoginEmailRequest, LoginRequest } from 'services/auth.service'

const emailValidation = yup.string().required('required').trim().matches(emailRegex, { message: 'invalidEmail' })

export const loginSchema: yup.ObjectSchema<LoginRequest> = yup.object().shape({
    email: emailValidation,
    password: yup.string().required('required'),
})

export const loginCustomerSchema: yup.ObjectSchema<SendLoginEmailRequest> = yup.object().shape({
    email: emailValidation,
})

export const forgotPasswordSchema: yup.ObjectSchema<ForgotPasswordRequest> = yup.object().shape({
    email: emailValidation,
})
