import React from 'react'
import { Box, Button, IconButton, Stack, Typography, styled } from '@mui/material'

import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useFieldArray } from 'react-hook-form'
import { Add, ContentCopy, Delete } from '@mui/icons-material'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { sendLinkToCustomers } from 'schemas/sendLinkToCustomers'
import { useSendLinksByMailMutation } from 'services/projects.service'
import { navigationLinks } from 'utils/navigation-utils'
import { ProjectWithoutForms } from 'redux/types/project.type'

type SendLinkFormEntity = {
    contacts: { email: string }[]
}

const DEFAULT_VALUES = {
    contacts: [{ email: '' }],
}

interface SendLinkToCustomerDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions'> {
    project: ProjectWithoutForms
}

export const SendLinkToCustomerDialog: React.FC<SendLinkToCustomerDialogProps> = ({ handleClose, open, project }) => {
    const { t } = useTranslation()
    const { customer } = project
    const [sendMail, { isLoading }] = useSendLinksByMailMutation()

    const defaultCustomerData = customer && { contacts: customer.contacts?.map(({ email }) => ({ email })) }
    const { handleSubmit, control, reset } = useForm<SendLinkFormEntity>({
        defaultValues: defaultCustomerData || DEFAULT_VALUES,
        resolver: yupResolver(sendLinkToCustomers),
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'contacts',
    })

    const [isCopied, setIsCopied] = React.useState(false)
    const timerRef = React.useRef<NodeJS.Timeout | null>(null)

    const customerViewLink = `${window.location.origin}${navigationLinks.toCustomerViewProject(
        project.id,
    )}?accessToken=${project.accessToken}`

    React.useEffect(() => {
        reset(defaultCustomerData || DEFAULT_VALUES)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer])

    React.useEffect(() => {
        return () => {
            if (timerRef.current) clearTimeout(timerRef.current)
        }
    }, [])

    const addNewChild = () => {
        append({
            email: '',
        })
    }

    const removeChild = (childIndex: number) => {
        if (fields.length === 1) return
        remove(childIndex)
    }

    const onSubmit = async (values: SendLinkFormEntity) => {
        try {
            await sendMail({
                mailTo: values.contacts.map((contact) => contact.email),
                projectId: project.id,
            }).unwrap()
            handleClose()
        } catch (e) {}
    }

    const onCopyToClipboard = () => {
        navigator.clipboard.writeText(customerViewLink)
        setIsCopied(true)
        timerRef.current = setTimeout(() => setIsCopied(false), 3000)
    }

    return (
        <ModalsWrapper
            sx={{
                zIndex: 9999,
            }}
            open={open}
            title={t('common.sendLink')}
            handleClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
            body={
                <form id="contacts-link-send" onSubmit={handleSubmit(onSubmit)}>
                    <ContentWrapper>
                        <Box my={2} pl={5}>
                            <Button variant="outlined" color="primary" startIcon={<Add />} onClick={addNewChild}>
                                {t('projectPage.sendCustomerDialog.addContact')}
                            </Button>
                        </Box>
                        <Typography variant="body1" sx={{ fontWeight: 600, paddingLeft: 5 }}>
                            {t('projectPage.sendCustomerDialog.contactsToSend')}:
                        </Typography>
                        {fields.map((field, index) => (
                            <Stack direction="row" key={field.id}>
                                <IconButton
                                    color="error"
                                    onClick={() => removeChild(index)}
                                    disabled={fields.length === 1}
                                >
                                    <Delete />
                                </IconButton>
                                <ControlledMuiTextField
                                    name={`contacts.${index}.email`}
                                    control={control}
                                    placeholder={t('projectPage.sendCustomerDialog.contactEmailPlaceholder')}
                                    fullWidth
                                    variant="filled"
                                />
                            </Stack>
                        ))}
                        <Box display="flex" alignItems="center" gap={2}>
                            <a href={customerViewLink} target="_blank" rel="noreferrer">
                                {t('projectPage.sendCustomerDialog.customerProjectLink')}
                            </a>
                            {isCopied ? (
                                <Typography variant="body1">{`${t('common.copied')}!`}</Typography>
                            ) : (
                                <IconButton onClick={onCopyToClipboard}>
                                    <ContentCopy color="primary" />
                                </IconButton>
                            )}
                        </Box>
                    </ContentWrapper>
                </form>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={2}>
                    <Button variant="outlined" onClick={handleClose} color="secondary" disabled={isLoading}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="contacts-link-send"
                        disabled={isLoading}
                        loading={isLoading}
                    >
                        {t('projectPage.sendCustomerDialog.sendLinkActionButton')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
