import { ROUTES } from 'constants/routes'

export const generateNestedRouteUrl = (route: string, basePath: string) => route.replace(basePath, '').slice(1)

export const navigationLinks = {
    toBuilding: (buildingId: string) => ROUTES.building.replace(':id', buildingId),
    toBuildingPublic: (buildingId: string) => ROUTES.buildingPublic.replace(':id', buildingId),
    toEditTemplate: (templateId: string) => ROUTES.schemTemplateEdit.replace(':templateId', templateId),
    toCreateProject: (projectId: string) => ROUTES.projectCreate.replace(':projectId', projectId),
    toReviewProject: (projectId: string) => ROUTES.projectReview.replace(':projectId', projectId),
    toPreviewProject: (projectId: string) => ROUTES.projectPreview.replace(':projectId', projectId),
    toPreviewInProgressProject: (projectId: string) => ROUTES.projectPreviewInProgress.replace(':projectId', projectId),
    toCustomerViewProject: (projectId: string) => ROUTES.projectCustomerView.replace(':projectId', projectId),
    toTenant: (tenantId: string) => ROUTES.tenant.replace(':id', tenantId),
    toCustomer: (customerId: string) => ROUTES.customer.replace(':id', customerId),
    toOrganization: (orgId: string) => ROUTES.organization.replace(':id', orgId),
    toAddBuilding: (customerId?: string) =>
        customerId ? ROUTES.buildingsAddCustomer.replace(':customerId', customerId) : ROUTES.buildingAdd,
    toAddSchema: (schemaId?: string) => (schemaId ? ROUTES.schemaEdit.replace(':id', schemaId) : ROUTES.schemaAdd),
    toAddForm: (formId?: string) => (formId ? ROUTES.formEdit.replace(':id', formId) : ROUTES.formAdd),
    toCustomerBuilding: (buildingId: string) => ROUTES.customerBuilding.replace(':buildingId', buildingId),
}
