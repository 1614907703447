import { useFormContext, useWatch } from 'react-hook-form'
import { Cancel } from '@mui/icons-material'
import { ProjectGeneralInfoRequestForm } from './ProjectDetails'
import { StyledIconButton } from 'components/projects/EditButton'
import { CustomProjectFileUpload } from 'components/projects/CustomProjectFileUpload'

interface ProjectDocumentsEditModeProps {
    isEditMode: boolean
    onEditModeChange: (value: boolean) => void
}

export const ProjectDocumentsEditMode: React.FC<ProjectDocumentsEditModeProps> = ({ isEditMode, onEditModeChange }) => {
    const {
        control,
        setValue,
        reset,
        formState: { errors },
    } = useFormContext<ProjectGeneralInfoRequestForm>()
    const files = useWatch({ control, name: 'files' })
    const selectedBuildings = useWatch({ control, name: 'buildings' })

    const handleCancelEditMode = () => {
        reset()
        onEditModeChange(false)
    }

    return (
        <div>
            {isEditMode && (
                <StyledIconButton onClick={handleCancelEditMode}>
                    <Cancel />
                </StyledIconButton>
            )}
            <CustomProjectFileUpload
                control={control}
                setValue={setValue}
                files={files}
                errors={errors}
                selectedBuildings={selectedBuildings}
            />
        </div>
    )
}
