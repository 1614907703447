import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const DataGridNoRows: React.FC = () => {
    const { t } = useTranslation()

    return (
        <Box width="100%" display="flex" height="100%" justifyContent="center" alignItems="center">
            <Typography variant="h5">{t('common.noResults')}</Typography>
        </Box>
    )
}
