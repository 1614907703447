import * as React from 'react'
import { ProjectStructureSectionProgress } from 'redux/types/formStructure.type'
import { RadioButtonUncheckedOutlined, TaskAlt, BlurCircular, NotInterested, Circle } from '@mui/icons-material'

const ICONS: Record<ProjectStructureSectionProgress, React.ReactNode> = {
    'not-started': <RadioButtonUncheckedOutlined />,
    complete: <TaskAlt color="success" />,
    draft: <BlurCircular color="success" />,
    disabled: <NotInterested color="warning" />,
    'no-form': <Circle />,
}

export const TreeProgressIcon: React.FC<{ progress?: ProjectStructureSectionProgress }> = ({ progress }) => {
    if (!progress) return <RadioButtonUncheckedOutlined />
    return <>{ICONS[progress]}</>
}
