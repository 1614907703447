import { TenantPage } from 'pages/tenants/tenant.page'
import { TenantsPage } from 'pages/tenants/tenants.page'
import { ROUTES } from 'constants/routes'
import { generateNestedRouteUrl } from 'utils/navigation-utils'

const tenantsRoutes = [
    {
        path: '',
        element: <TenantsPage />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.tenant, ROUTES.tenants),
        element: <TenantPage />,
    },
]

export default tenantsRoutes
