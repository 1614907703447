import { useFormContext, useFieldArray } from 'react-hook-form'
import { StructureBuilderForm } from './useStructureForm'

export const useStructureFormField = (prefix: string) => {
    const {
        control,
        setValue,
        getValues,
        formState: { errors },
        register,
    } = useFormContext<StructureBuilderForm>()

    const labelInputPath = `${prefix}label` as `sections.${number}.label`
    const formIdInputPath = `${prefix}schemaId` as `sections.${number}.schemaId`
    const childrenArrayInputPath = `${prefix}children` as `sections`

    const { fields, append, remove } = useFieldArray({
        control,
        name: childrenArrayInputPath,
    })

    const addNewChild = () => {
        append({
            label: '',
            children: [],
            sectionId: Date.now().toString(),
        })
    }

    const removeChild = (childIndex: number) => () => {
        remove(childIndex)
    }

    return {
        fields,
        control,
        addNewChild,
        removeChild,
        labelInputPath,
        formIdInputPath,
        setValue,
        errors,
        register,
        getValues,
    }
}
