import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ControlledMuiRadio from 'components/inputs/ControlledMuiRadio'

import { ControlledTagInput } from 'components/inputs/custom/ControlledTagInput'
import { CreateOrUpdateBuildingForm } from 'redux/types/building.type'
import { useFormContext } from 'react-hook-form'
import { useAppSelector } from 'redux/hooks'
import { selectHeritageTagOptions } from 'redux/selectors/settings.selectors'
import { SectionBlock } from 'components/buildings/SectionBlock'
import { FormItem } from 'components/buildings/FormItem'

export const CultureSectionForm: React.FC = () => {
    const { t } = useTranslation()
    const { control, watch, setValue } = useFormContext<CreateOrUpdateBuildingForm>()

    const isCulturalHeritage = watch('culturalHeritage')
    const heritageTagOptions = useAppSelector(selectHeritageTagOptions)

    const handleCulturalHeritageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== 'true') setValue('culturalHeritageTags', undefined)
    }

    return (
        <SectionBlock>
            <FormItem label={t('createEditBuildingPage.culturalHeritage')}>
                <Box display="flex" alignItems="center">
                    <ControlledMuiRadio
                        control={control}
                        name="culturalHeritage"
                        options={[
                            { label: t('common.yes'), value: true },
                            { label: t('common.no'), value: false },
                        ]}
                        onChangeCb={handleCulturalHeritageChange}
                        row
                    />
                    {isCulturalHeritage && (
                        <ControlledTagInput
                            options={heritageTagOptions}
                            control={control}
                            name="culturalHeritageTags"
                        />
                    )}
                </Box>
            </FormItem>
        </SectionBlock>
    )
}
