import { Hidden } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { HeaderIconButton } from 'components/general/buttons/HeaderIconButton'
import React from 'react'

type ProjectMobileActionsProps = {
    onOpenNavigationDrawer: () => void
}

export const ProjectMobileActions: React.FC<ProjectMobileActionsProps> = ({ onOpenNavigationDrawer }) => {
    return (
        <Hidden mdUp>
            <HeaderIconButton icon={<MenuIcon />} onClick={onOpenNavigationDrawer} size="small" />
        </Hidden>
    )
}
