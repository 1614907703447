import React from 'react'
import { Helmet } from 'react-helmet'
import { PageContainer } from 'components/general/containers/PageContainer'
import { UnauthorizedTopBar } from 'components/general/top-bar/UnauthorizedTopBar'
import { MainPageCard } from 'modules/customer/home/MainPageCard'
import { useTranslation } from 'react-i18next'

export const HomePage: React.FC = () => {
    const { t } = useTranslation()

    return (
        <>
            <Helmet>
                <title>{t('customerHomePage.pageTitle')}</title>
            </Helmet>

            <UnauthorizedTopBar />

            <PageContainer>
                <MainPageCard />
            </PageContainer>
        </>
    )
}
