import React from 'react'
import { List, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ContentCopy, Delete, Edit } from '@mui/icons-material'

type TemplatesActionsMenuProps = {
    anchorEl?: null | HTMLElement
    id?: string
    onClose: () => void
    onCopyTemplate: (id: string) => void
    onTemplateNameChange: (id: string) => void
    onDeleteAction: (id: string) => void
}

export const TemplatesActionsMenu: React.FC<TemplatesActionsMenuProps> = ({
    anchorEl,
    onClose,
    id,
    onCopyTemplate,
    onTemplateNameChange,
    onDeleteAction,
}) => {
    const { t } = useTranslation()

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
            }}
            onClose={onClose}
            PaperProps={{ sx: { minWidth: 200 } }}
        >
            <List sx={{ width: '100%' }}>
                <ListItemButton onClick={() => onTemplateNameChange(id!)}>
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    <ListItemText primary={t('common.edit')} />
                </ListItemButton>

                <ListItemButton onClick={() => onCopyTemplate(id!)}>
                    <ListItemIcon>
                        <ContentCopy />
                    </ListItemIcon>
                    <ListItemText primary={t('common.copy')} />
                </ListItemButton>

                <ListItemButton onClick={() => onDeleteAction(id!)}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText primary={t('common.delete')} />
                </ListItemButton>
            </List>
        </Popover>
    )
}
