import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Stack, styled } from '@mui/material'
import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { BuildingsAutoComplete } from 'components/organizations/BuildingAutoComplete'
import ControlledMuiSelect, { IOption } from 'components/inputs/ControlledMuiSelect'
import { ORGANIZATION_PERMISSIONS_FOR_BUILDING, OrganizationBuilding } from 'redux/types/organization.type'
import {
    useCreateOrganizationBuildingMutation,
    useUpdateOrganizationBuildingMutation,
} from 'services/organizations.service'
import { CreateUpdateOrganizationPermissionForm, organizationPermissionSchema } from 'schemas/organizationPermissions'
import { Building } from 'redux/types/building.type'

const DEFAULT_VALUES: CreateUpdateOrganizationPermissionForm = {
    building: null,
    permissionType: ORGANIZATION_PERMISSIONS_FOR_BUILDING.Owner,
}

type PermissionCreateEditDialogProps = { orgBuilding?: OrganizationBuilding | null; organizationId: string } & Omit<
    ModalWrapperProps,
    'title' | 'body' | 'actions'
>

const createDefaultValueFromPermission = (orgBuilding: OrganizationBuilding) => ({
    // we dont need full building entity here
    building: {
        id: orgBuilding.buildingId,
        address: orgBuilding.address,
        cadastre: orgBuilding.cadastre,
        name: orgBuilding.name,
    },
    permissionType: orgBuilding.type,
})

export const PermissionCreateEditDialog: React.FC<PermissionCreateEditDialogProps> = ({
    orgBuilding,
    handleClose,
    open,
    organizationId,
}) => {
    const { t } = useTranslation()

    const [create, { isLoading: isCreating }] = useCreateOrganizationBuildingMutation()
    const [update, { isLoading: isUpdating }] = useUpdateOrganizationBuildingMutation()

    const { handleSubmit, control, reset } = useForm<CreateUpdateOrganizationPermissionForm>({
        defaultValues: orgBuilding ? createDefaultValueFromPermission(orgBuilding) : DEFAULT_VALUES,
        resolver: yupResolver(organizationPermissionSchema),
    })

    const isLoading = isCreating || isUpdating
    const PERMISSIONS: IOption[] = [
        { label: t('organizationPage.permissionOptLabels.owner'), value: ORGANIZATION_PERMISSIONS_FOR_BUILDING.Owner },
        {
            label: t('organizationPage.permissionOptLabels.manager'),
            value: ORGANIZATION_PERMISSIONS_FOR_BUILDING.FacilityManager,
        },
        { label: t('organizationPage.permissionOptLabels.other'), value: ORGANIZATION_PERMISSIONS_FOR_BUILDING.Other },
    ]

    React.useEffect(() => {
        reset(orgBuilding ? createDefaultValueFromPermission(orgBuilding) : DEFAULT_VALUES)
    }, [orgBuilding, reset])

    const onClose = () => {
        reset()
        handleClose()
    }

    const onSubmit = async (data: CreateUpdateOrganizationPermissionForm) => {
        if (orgBuilding) {
            await update({
                id: organizationId,
                permission: {
                    buildingId: data.building!.id,
                    type: data.permissionType,
                    recordId: orgBuilding.id,
                },
            }).unwrap()
        } else {
            await create({
                id: organizationId,
                permission: {
                    buildingId: data.building!.id,
                    type: data.permissionType,
                },
            }).unwrap()
        }

        handleClose()
    }

    return (
        <ModalsWrapper
            open={open}
            title={
                orgBuilding
                    ? t('organizationPage.customerPermissionEditDialogTitle')
                    : t('organizationPage.customerPermissionCreateDialogTitle')
            }
            handleClose={onClose}
            fullWidth={true}
            maxWidth="sm"
            body={
                <form id="permission-form" onSubmit={handleSubmit(onSubmit)}>
                    <ContentWrapper>
                        <BuildingsAutoComplete
                            disabled={Boolean(orgBuilding)}
                            control={control}
                            defaultValue={orgBuilding as unknown as Building}
                        />
                        <ControlledMuiSelect
                            control={control}
                            name="permissionType"
                            label={t('organizationPage.selectPermissionPlaceholder')}
                            fullWidth
                            options={PERMISSIONS}
                        />
                    </ContentWrapper>
                </form>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button variant="outlined" onClick={onClose} color="secondary">
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="permission-form"
                        loading={isLoading}
                    >
                        {t('common.confirm')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
