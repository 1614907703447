import React from 'react'
import { styled } from '@mui/system'
import { ReactComponent as Logo } from 'assets/icons/logo_theme_color.svg'
import { Trans } from 'react-i18next'
import { Typography } from '@mui/material'

export const Footer: React.FC = React.memo(() => {
    const currentYear = new Date().getFullYear()

    return (
        <Container>
            <LogoContainer>
                <Logo width={140} height="100%" />
            </LogoContainer>

            <CopyRightContainer>
                <Typography variant="body2" component="span">
                    <Trans i18nKey="footer.copyRight" values={{ currentYear }} />
                </Typography>
            </CopyRightContainer>
        </Container>
    )
})

const Container = styled('footer')(({ theme }) => ({
    width: '100%',
    height: 40,
    margin: theme.spacing(3, 0),
    display: 'flex',
    justifyContent: 'space-between',
}))

const LogoContainer = styled('div')({
    maxWidth: 140,
    width: '100%',
})

const CopyRightContainer = styled('div')(({ theme }) => ({
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}))
