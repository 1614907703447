import { useMatch, useParams } from 'react-router-dom'
import { ROUTES } from 'constants/routes'
import {
    useGetArchivedProjectQuery,
    useGetCustomersFullProjectQuery,
    useGetProjectNormalizedQuery,
} from 'services/projects.service'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useProjectQueryParams } from './useProjectQueryParams'
import { useGetProjectRevisionsQuery, useGetRevisionQuery } from 'services/projectRevision.service'

export type ProjectReviewParams = {
    projectId: string
}

export const useProjectView = () => {
    const { projectId } = useParams<keyof ProjectReviewParams>() as ProjectReviewParams
    const isPreview = useMatch(ROUTES.projectPreview)
    const isPreviewInProgress = useMatch(ROUTES.projectPreviewInProgress)
    const isCustomerView = useMatch(ROUTES.projectCustomerView)
    const isReviewPage = useMatch(ROUTES.projectReview)

    const [search] = useProjectQueryParams()

    // only on review page and preview-in-progress
    const {
        isFetching: isLoadingFullProject,
        data,
        isError: isErrorFullProject,
    } = useGetProjectNormalizedQuery(
        Boolean(isReviewPage) || Boolean(isPreviewInProgress)
            ? { id: projectId, isPreview: Boolean(isPreviewInProgress), asCustomer: Boolean(isPreviewInProgress) }
            : skipToken,
    )

    // only on customer view page
    const {
        isFetching: isLoadingCustomerFullProject,
        data: data2,
        isError: isErrorCustomerProject,
    } = useGetCustomersFullProjectQuery(
        search.accessToken && Boolean(isCustomerView) ? { id: projectId, accessToken: search.accessToken } : skipToken,
    )
    // only on preview page, and revision is not defined in url
    const {
        isLoading: isLoadingArchivedProject,
        data: data3,
        isError: isErrorPreviewProject,
    } = useGetArchivedProjectQuery(Boolean(isPreview) && !search.revision ? projectId : skipToken)
    // full revision - only in preview page and when revision is specified in url
    const {
        isFetching: isLoadingProjectRevision,
        data: projectRevision,
        isError: isErrorArchivedProject,
    } = useGetRevisionQuery(!search.revision ? skipToken : { id: search.revision })
    // only in preview ( gets all revisions for the project )
    const {
        isLoading: isLoadingRevisions,
        data: revisions,
        isError: isErrorRevisions,
    } = useGetProjectRevisionsQuery(Boolean(isPreview) ? { projectId: projectId } : skipToken)

    const isLoading =
        isLoadingFullProject ||
        isLoadingCustomerFullProject ||
        isLoadingRevisions ||
        isLoadingProjectRevision ||
        isLoadingArchivedProject
    const isError =
        isErrorFullProject ||
        isErrorCustomerProject ||
        isErrorRevisions ||
        isErrorArchivedProject ||
        isErrorPreviewProject
    const project = data || data2 || data3 || projectRevision?.revisedProject

    return {
        isLoading,
        project,
        projectId,
        isPreview,
        isCustomerView: Boolean(isCustomerView || isPreview || isPreviewInProgress),
        isError,
        revisions,
    }
}
