import { appApi } from '../redux/api'
import { PaginatedResults } from '../components/general/models/paginated-results'
import { CreateTenantUserRequest, GetTenantUsers, User } from 'redux/types/user.type'

export const tenantUsersApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getTenantUsers: builder.query<PaginatedResults<User>, GetTenantUsers>({
            query: (payload) => ({
                url: '/v1/users/by-tenant',
                method: 'GET',
                params: payload,
            }),
            providesTags: ['TenantUsers'],
        }),

        createTenantUser: builder.mutation<User, CreateTenantUserRequest>({
            query: (payload) => ({
                url: '/v1/users',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result) => (result ? ['TenantUsers'] : []),
        }),

        updateTenantUser: builder.mutation<void, User>({
            query: (payload) => ({
                url: '/v1/users',
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (result) => (result ? ['TenantUsers'] : []),
        }),

        deleteTenantUser: builder.mutation<void, { id: string }>({
            query: (payload) => ({
                url: '/v1/users',
                method: 'DELETE',
                body: payload,
            }),
            invalidatesTags: (result) => (result ? ['TenantUsers'] : []),
        }),

        getMyself: builder.query<User, void>({
            query: () => ({
                url: '/v1/users/me',
                method: 'GET',
            }),
            providesTags: [],
            keepUnusedDataFor: 3600,
        }),
    }),
})

export const {
    useGetTenantUsersQuery,
    useLazyGetTenantUsersQuery,
    useCreateTenantUserMutation,
    useUpdateTenantUserMutation,
    useDeleteTenantUserMutation,
    useGetMyselfQuery,
    useLazyGetMyselfQuery,
} = tenantUsersApi
