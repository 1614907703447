import React from 'react'
import { useTranslation } from 'react-i18next'
import { TabContentContainer } from 'components/customers/styles'
import { ECustomerHomeTabs } from './CustomerHomeTabs'
import { useDataGridFiltersState } from 'utils/hooks/useDataGridFiltersState'
import { useGetArchivedProjectsQuery, useGetProjectsQuery } from 'services/projects.service'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { Box, SortDirection } from '@mui/material'
import { DataGrid, GridColDef, GridRowHeightParams } from '@mui/x-data-grid'
import { ProjectWithoutForms } from 'redux/types/project.type'
import { dateOrDefaultEmpty, valueOrDefaultEmpty } from 'utils/general'
import { ProjectBuildingListCell } from 'components/general/table/ProjectBuildingListCell'
import { ProjectStatusCell } from 'components/projects/ProjectStatusCell'
import { DataGridNoRows } from 'components/general/table/DataGridNoRows'
import { DataGridTitle } from 'components/general/table/DataGridTitle'
import { DataGridLinkCell } from 'components/general/table/DataGridLinkCell'
import { ROUTES } from 'constants/routes'
import { navigationLinks } from 'utils/navigation-utils'
import { DATE_LOCALE_OPTIONS } from 'constants/locales'

type CustomerProjectsProps = {
    activeTab: ECustomerHomeTabs
    searchQuery: string
    customerIds: string[]
}

export const CustomerProjects: React.FC<CustomerProjectsProps> = React.memo(
    ({ activeTab, searchQuery, customerIds }) => {
        const { t } = useTranslation()

        const { handlePageChange, handleSortChange, page, sortBy } = useDataGridFiltersState({
            sort: 'desc',
            field: 'name',
        })

        const {
            handlePageChange: handleFinishedProjectPageChange,
            handleSortChange: handleFinishedProjectSortChange,
            page: finishedProjectsPage,
            sortBy: finishedProjectsSortBy,
        } = useDataGridFiltersState({ sort: 'desc', field: 'name' })

        const { data: projects, isFetching: isFetchingProjects } = useGetProjectsQuery(
            {
                page: page || 0,
                itemsPerPage: DEFAULT_ROWS_PER_PAGE,
                direction: sortBy?.sort as SortDirection,
                sortBy: sortBy?.field,
                searchQuery: searchQuery || undefined,
                customerIds,
            },
            { skip: activeTab !== ECustomerHomeTabs.Projects },
        )
        const { data: finishedProjects, isFetching: isFetchingFinishedProjects } = useGetArchivedProjectsQuery(
            {
                page: finishedProjectsPage || 0,
                itemsPerPage: DEFAULT_ROWS_PER_PAGE,
                direction: finishedProjectsSortBy?.sort as SortDirection,
                sortBy: finishedProjectsSortBy?.field,
                searchQuery: searchQuery || undefined,
                customerIds,
            },
            { skip: activeTab !== ECustomerHomeTabs.Projects },
        )

        const ongoingProjectColumns: GridColDef<ProjectWithoutForms>[] = [
            {
                field: 'type',
                headerName: t('dashboard.projectType'),
                flex: 1,
                editable: false,
                minWidth: 160,
                valueGetter: (params) => valueOrDefaultEmpty(params.row.type?.label),
            },
            {
                field: 'name',
                headerName: t('dashboard.projectName'),
                flex: 1,
                editable: false,
                minWidth: 300,
                valueGetter: (params) => params.row.name || t('common.noProjectNamePlaceholder'),
            },
            {
                field: 'buildings',
                headerName: t('dashboard.projectBuildings'),
                flex: 1,
                editable: false,
                minWidth: 350,
                width: 350,
                sortable: false,
                renderCell: (params) => <ProjectBuildingListCell buildings={params.row.buildings} />,
            },
            {
                field: 'status',
                headerName: t('dashboard.projectStatus'),
                flex: 1,
                editable: false,
                maxWidth: 170,
                minWidth: 170,
                renderCell: (params) => <ProjectStatusCell status={params.row.status} />,
            },
            {
                field: 'customer',
                headerName: t('customerHomePage.responsableCompany'),
                flex: 1,
                editable: false,
                minWidth: 220,
                maxWidth: 220,
                valueGetter: (params) => valueOrDefaultEmpty(params.row.responsableCompany),
            },
        ]

        const finishedProjectsColumns: GridColDef<ProjectWithoutForms>[] = [
            {
                field: 'type',
                headerName: t('dashboard.projectType'),
                flex: 1,
                editable: false,
                minWidth: 160,
                valueGetter: (params) => valueOrDefaultEmpty(params.row.type?.label),
            },
            {
                field: 'name',
                headerName: t('dashboard.projectName'),
                flex: 1,
                editable: false,
                minWidth: 300,
                renderCell: (params) => {
                    const state = {
                        from: ROUTES.customerHome,
                    }
                    const link = {
                        search: `accessToken=${params.row.accessToken!}`,
                        pathname: navigationLinks.toCustomerViewProject(params.row.id),
                    }

                    return (
                        <DataGridLinkCell
                            label={params.row.name || t('common.noProjectNamePlaceholder')}
                            to={link}
                            state={state}
                        />
                    )
                },
            },
            {
                field: 'buildings',
                headerName: t('dashboard.projectBuildings'),
                flex: 1,
                editable: false,
                minWidth: 350,
                width: 350,
                sortable: false,
                renderCell: (params) => <ProjectBuildingListCell buildings={params.row.buildings} />,
            },
            {
                field: 'publishDate',
                headerName: t('dashboard.projectPublishDate'),
                flex: 1,
                editable: false,
                maxWidth: 170,
                minWidth: 170,
                valueGetter: (params) =>
                    dateOrDefaultEmpty(params.row.publishDate, (value) =>
                        new Date(value).toLocaleDateString('no', DATE_LOCALE_OPTIONS['no']),
                    ),
            },
            {
                field: 'customer',
                headerName: t('customerHomePage.responsableCompany'),
                flex: 1,
                editable: false,
                minWidth: 220,
                maxWidth: 220,
                valueGetter: (params) => valueOrDefaultEmpty(params.row.responsableCompany),
            },
        ]

        return (
            <TabContentContainer role="tabpanel" hidden={activeTab !== ECustomerHomeTabs.Projects}>
                <Box sx={{ width: '100%' }}>
                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                textTransform: 'capitalize',
                            },
                        }}
                        autoHeight
                        rows={finishedProjects?.data || []}
                        columns={finishedProjectsColumns}
                        rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                        pageSize={DEFAULT_ROWS_PER_PAGE}
                        rowCount={finishedProjects?.total || 0}
                        isRowSelectable={() => false}
                        disableColumnMenu
                        loading={isFetchingFinishedProjects}
                        hideFooterSelectedRowCount
                        components={{
                            NoRowsOverlay: DataGridNoRows,
                            Toolbar: DataGridTitle,
                        }}
                        componentsProps={{
                            toolbar: { title: t('projectsPage.completeProjects') },
                        }}
                        paginationMode="server"
                        sortingMode="server"
                        onPageChange={handleFinishedProjectPageChange}
                        onSortModelChange={handleFinishedProjectSortChange}
                        page={page}
                        sortModel={sortBy ? [{ field: sortBy.field, sort: sortBy.sort }] : []}
                        getRowHeight={({ model }: GridRowHeightParams) => {
                            if (model.buildings.length > 1) return 'auto'

                            return null
                        }}
                    />
                </Box>

                <Box sx={{ width: '100%' }}>
                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                textTransform: 'capitalize',
                            },
                            mt: 6,
                        }}
                        autoHeight
                        rows={projects?.data || []}
                        columns={ongoingProjectColumns}
                        rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                        pageSize={DEFAULT_ROWS_PER_PAGE}
                        rowCount={projects?.total || 0}
                        isRowSelectable={() => false}
                        disableColumnMenu
                        loading={isFetchingProjects}
                        hideFooterSelectedRowCount
                        components={{
                            NoRowsOverlay: DataGridNoRows,
                            Toolbar: DataGridTitle,
                        }}
                        componentsProps={{
                            toolbar: { title: t('projectsPage.currentProjects') },
                        }}
                        paginationMode="server"
                        sortingMode="server"
                        onPageChange={handlePageChange}
                        onSortModelChange={handleSortChange}
                        page={page}
                        sortModel={sortBy ? [{ field: sortBy.field, sort: sortBy.sort }] : []}
                        getRowHeight={({ model }: GridRowHeightParams) => {
                            if (model.buildings.length > 1) return 'auto'

                            return null
                        }}
                    />
                </Box>
            </TabContentContainer>
        )
    },
)
