import React from 'react'
import { Grid, styled } from '@mui/material'

export const PrebuiltFormGridContainer = ({ children }: { children: React.ReactNode }) => {
    return (
        <Container container spacing={2}>
            {children}
        </Container>
    )
}

const Container = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(2, 0, 0),

    '& .MuiPaper-root': {
        height: '100%',
        maxHeight: '100vh',
    },
}))
