import React from 'react'
import { ProjectDetailsViewMode } from './ProjectDetailsViewMode'
import { ProjectDetailsEditMode } from './ProjectDetailsEditMode'
import { Project } from 'redux/types/project.type'

interface ProjectDetailsTabProps {
    editable: boolean
    currentProject: Project
    isEditMode: boolean
    onEditModeChange: (value: boolean) => void
    completed: boolean
}

export const ProjectDetailsTab: React.FC<ProjectDetailsTabProps> = ({
    editable,
    currentProject,
    isEditMode,
    onEditModeChange,
    completed,
}) => {
    if ((editable && !isEditMode) || completed) {
        return (
            <ProjectDetailsViewMode
                currentProject={currentProject}
                onEditModeChange={onEditModeChange}
                completed={completed}
            />
        )
    }

    return <ProjectDetailsEditMode isEditMode={isEditMode} onEditModeChange={onEditModeChange} />
}
