import { JsonFormsDispatch, withJsonFormsArrayLayoutProps } from '@jsonforms/react'
import { Box, IconButton, Tooltip, Typography, styled } from '@mui/material'
import { ArrayLayoutProps, createDefaultValue, findUISchema } from '@jsonforms/core'
import { Add, Delete } from '@mui/icons-material'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledOrderderList, StyledUnorderedList } from 'components/general/jsonForm/view/styles'

const ArrayListPresentation = (props: ArrayLayoutProps) => {
    const { t } = useTranslation()
    const { uischema, schema, path, addItem, uischemas, rootSchema, enabled, renderers, cells, removeItems, visible } =
        props

    const foundUISchema = useMemo(
        () => findUISchema(uischemas!, schema, uischema.scope, path, undefined, uischema, rootSchema),
        [path, rootSchema, schema, uischema, uischemas],
    )
    const innerCreateDefaultValue = useCallback(() => createDefaultValue(props.schema), [props.schema])

    const arrayItem = new Array(props.data).fill(null)
    const options = uischema.options

    if (visible === false) return null

    return (
        <Box display="flex" flexDirection="column" gap={0.5} my={2}>
            <Box display="flex" justifyContent="space-between" sx={{ pl: 2 }}>
                <Typography variant="h6">{props.label}</Typography>
                <Tooltip id="tooltip-add" title={t('schemaPage.addTo', { name: props.label })} placement="bottom">
                    <IconButton onClick={addItem(path, innerCreateDefaultValue())} size="large">
                        <Add />
                    </IconButton>
                </Tooltip>
            </Box>
            {options?.listType === 'ordered' ? (
                <StyledOrderderList>
                    {(arrayItem || []).map((_: any, index: number) => {
                        return (
                            <StyledListItem key={`${path}-${index}`}>
                                <StyledIndexText variant="subtitle1">{index + 1}.</StyledIndexText>
                                <JsonFormsDispatch
                                    uischema={foundUISchema}
                                    schema={schema}
                                    path={`${path}.${index}`}
                                    key={`${path}.${index}`}
                                    enabled={enabled}
                                    renderers={renderers}
                                    cells={cells}
                                    uischemas={uischemas}
                                />
                                <IconButton onClick={removeItems ? removeItems(path, [index]) : undefined} size="large">
                                    <Delete />
                                </IconButton>
                            </StyledListItem>
                        )
                    })}
                </StyledOrderderList>
            ) : (
                <StyledUnorderedList>
                    {(arrayItem || []).map((_: any, index: number) => {
                        return (
                            <StyledListItem key={`${path}-${index}`}>
                                <StyledIndexText variant="subtitle1">&#x2022;</StyledIndexText>
                                <JsonFormsDispatch
                                    uischema={foundUISchema}
                                    schema={schema}
                                    path={`${path}.${index}`}
                                    key={`${path}.${index}`}
                                    enabled={enabled}
                                    renderers={renderers}
                                    cells={cells}
                                    uischemas={uischemas}
                                />
                                <IconButton onClick={removeItems ? removeItems(path, [index]) : undefined} size="large">
                                    <Delete />
                                </IconButton>
                            </StyledListItem>
                        )
                    })}
                </StyledUnorderedList>
            )}
        </Box>
    )
}

export const ArrayListControl = withJsonFormsArrayLayoutProps(ArrayListPresentation)

const StyledListItem = styled('li')(({ theme }) => ({
    display: 'flex',
    listStyleType: 'none',
    alignItems: 'flex-end',
    '& h6': {
        paddingRight: theme.spacing(1),
    },
}))

const StyledIndexText = styled(Typography)({
    fontSize: '1.125rem',
})
