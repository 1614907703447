import { ROUTES } from 'constants/routes'
import { CustomerBuildingPage } from 'pages/customer/CustomerBuilding.page'
import { HomePage } from 'pages/customer/Home.page'

const customerRoutes = [
    {
        path: ROUTES.customerHome,
        element: <HomePage />,
    },
    {
        path: ROUTES.customerBuilding,
        element: <CustomerBuildingPage />,
    },
]

export default customerRoutes
