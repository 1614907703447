import { appApi } from '../redux/api'
import { PaginatedResults } from '../components/general/models/paginated-results'
import {
    CrudOrganizationBuildingRequest,
    CreateOrganizationRequest,
    Organization,
    OrganizationPaginationParams,
    UpdateOrganizationRequest,
} from 'redux/types/organization.type'

const organizationsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getOrganizations: builder.query<PaginatedResults<Organization>, OrganizationPaginationParams>({
            query: (params) => ({
                url: '/v1/organizations',
                method: 'GET',
                params,
            }),
            providesTags: ['Organizations'],
        }),
        getOrganization: builder.query<Organization, { id: string }>({
            query: (params) => ({
                url: `/v1/organizations/${params.id}`,
                method: 'GET',
            }),
            providesTags: ['Organizations'],
        }),
        createOrganization: builder.mutation<Organization, CreateOrganizationRequest>({
            query: (params) => ({
                url: '/v1/organizations',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: (result) => (result ? ['Organizations'] : []),
        }),
        updateOrganization: builder.mutation<Organization, UpdateOrganizationRequest>({
            query: (params) => ({
                url: `/v1/organizations/${params.id}`,
                method: 'PUT',
                body: params.organization,
            }),
            invalidatesTags: (result) => (result ? ['Organizations'] : []),
        }),

        createOrganizationBuilding: builder.mutation<void, CrudOrganizationBuildingRequest>({
            query: (params) => ({
                url: '/v1/organizations/permissions',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: (result) => (result === null ? ['Organizations', 'Buildings'] : []),
        }),

        updateOrganizationBuilding: builder.mutation<void, CrudOrganizationBuildingRequest>({
            query: (params) => ({
                url: '/v1/organizations/permissions',
                method: 'PUT',
                body: params,
            }),
            invalidatesTags: (result) => (result === null ? ['Organizations', 'Buildings'] : []),
        }),
    }),
})

export const {
    useGetOrganizationsQuery,
    useLazyGetOrganizationsQuery,
    useGetOrganizationQuery,
    useCreateOrganizationMutation,
    useUpdateOrganizationMutation,
    useCreateOrganizationBuildingMutation,
    useUpdateOrganizationBuildingMutation,
} = organizationsApi
