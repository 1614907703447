import { Alert, Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../redux/hooks'
import { LoginRequest, useLoginMutation } from '../../services/auth.service'
import { authActions } from '../../redux/slices/auth.slice'
import { ROUTES } from 'constants/routes'
import { useLazyGetMyselfQuery } from 'services/tenantUsers.service'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginSchema } from 'schemas/auth'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { LoadingButton } from '@mui/lab'
import { ControlledPasswordInput } from 'components/inputs/ControlledPasswordInput'

const DEFAULT_VALUES: LoginRequest = {
    email: '',
    password: '',
}

export const LoginAdminPage: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [login, { isLoading: isLoadingLogin, error }] = useLoginMutation()
    const [getMe, { isLoading: isLoadingMe }] = useLazyGetMyselfQuery()
    const isLoading = isLoadingLogin || isLoadingMe

    const { control, handleSubmit } = useForm<LoginRequest>({
        mode: 'onSubmit',
        shouldFocusError: true,
        reValidateMode: 'onBlur',
        defaultValues: DEFAULT_VALUES,
        resolver: yupResolver(loginSchema),
    })

    const onSubmit = async (values: LoginRequest) => {
        if (!isLoading) {
            login(values)
                .unwrap()
                .then((response) => {
                    dispatch(authActions.setCredentials(response))
                })
                .then(() => getMe().unwrap())
                .then(() => navigate(ROUTES.dashboard))
        }
    }

    return (
        <Paper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box p={6}>
                    <Typography component="h1" variant="h5" align="center">
                        {t('authenticationPages.signIn')}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <ControlledMuiTextField
                            fullWidth
                            control={control}
                            name="email"
                            placeholder={t('common.email')}
                        />
                        <ControlledPasswordInput
                            fullWidth
                            control={control}
                            name="password"
                            placeholder={t('common.password')}
                            sx={{ mt: 2 }}
                        />
                        <LoadingButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            loading={isLoading}
                        >
                            {t('authenticationPages.login')}
                        </LoadingButton>
                        {error &&
                        'data' in error &&
                        (error.data as { translationKey: string })?.translationKey === 'UNAUTHORIZED_ERROR' ? (
                            <Alert sx={{ mb: 2 }} severity="error">
                                {t('authenticationPages.incorrectCredentials')}
                            </Alert>
                        ) : null}
                        <Grid container>
                            <Grid item xs>
                                <Link href="/forgot" variant="body2">
                                    {t('authenticationPages.forgotPassword')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </form>
        </Paper>
    )
}
