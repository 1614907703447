import { styled } from '@mui/material'
import { FOOTER_CONTENT_HEIGHT, HEADER_HEIGHT } from 'constants/styles'

export const PageContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <StyledContainer>
            <InnerContainer>{children}</InnerContainer>
        </StyledContainer>
    )
}

const StyledContainer = styled('main')({
    minHeight: `calc(100vh - ${HEADER_HEIGHT}px - 15px - ${FOOTER_CONTENT_HEIGHT}px)`,
    height: '100%',
    display: 'grid',

    '& .section:last-child': {
        borderBottom: 'none',
    },
})

const InnerContainer = styled('div')({
    minWidth: 0,
    width: '100%',
})
