import React from 'react'
import { Outlet } from 'react-router-dom'
import { PageContentWrapper } from 'components/general/styles'
import { Footer } from 'components/general/footer'
import PrivateRoute from 'router/PrivateRoute'

export const PrivateEmpty: React.FC = React.memo(() => {
    return (
        <PrivateRoute>
            <PageContentWrapper>
                <Outlet />
                <Footer />
            </PageContentWrapper>
        </PrivateRoute>
    )
})
