import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { persistor, store } from './redux/store'
import { App } from './app/app'
import reportWebVitals from './report-web-vitals'
import './index.css'
import { initTranslation } from './translation/i18n'
import { PersistGate } from 'redux-persist/integration/react'
import { initAppInsights } from 'TelemetryService'

const container = document.getElementById('root')!
const root = createRoot(container)

initAppInsights()
initTranslation()

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
