import { HomeUriRedirectPage } from 'pages/redirect/HomeUriRedirect.page'

const redirectRoutes = [
    {
        path: '/',
        element: <HomeUriRedirectPage />,
    },
]

export default redirectRoutes
