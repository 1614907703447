import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers'
import queryString from 'query-string'
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import { LoginResponse } from '../services/auth.service'
import { authActions } from './slices/auth.slice'
import { Config } from './config'
import { RootState } from './store'
import { getLang } from 'translation/translation-helper'

export const prepareAuthHeaders = (
    headers: Headers,
    { getState }: { getState: () => unknown },
): MaybePromise<Headers> => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.accessToken
    if (token) {
        headers.set('authorization', `Bearer ${token}`)
    }

    headers.set('Accept-Language', getLang())
    return headers
}

// create a new mutex
const mutex = new Mutex()

const baseQuery = fetchBaseQuery({
    baseUrl: Config.backendUrl,
    prepareHeaders: prepareAuthHeaders,
    paramsSerializer: (params: Record<string, unknown>) => queryString.stringify(params, { arrayFormat: 'none' }),
})

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)

    const isAuthorized = result.meta?.request.headers.has('authorization')
    if (isAuthorized && result.error && result.error.status === 401) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire()
            try {
                const state = api.getState() as RootState
                const refreshResult = await baseQuery(
                    `/auth/refresh/${state.auth.id}?refreshToken=${state.auth.refreshToken}`,
                    api,
                    extraOptions,
                )
                if (refreshResult.data) {
                    api.dispatch(authActions.setCredentials(refreshResult.data as LoginResponse))
                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions)
                } else {
                    api.dispatch(authActions.logout())
                }
            } finally {
                // release must be called once the mutex should be released again.
                release()
            }
        } else {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock()
            result = await baseQuery(args, api, extraOptions)
        }
    }
    return result
}
