import { Tab, Tabs } from '@mui/material'
import { ROUTES } from 'constants/routes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'

// doc tab disabled for mvp
export enum EBuildingTabs {
    BuildingInfo = 'BuildingInfo',
    // Documents = 'Documents',
    Organizations = 'BuildingRelatedOrganizations',
}

const BUILDING_TABS = [
    {
        label: 'buildingInfo',
        value: EBuildingTabs.BuildingInfo,
    },
    {
        label: 'buildingRelevantOrganizations',
        value: EBuildingTabs.Organizations,
    },
    // {
    //     label: 'documents',
    //     value: EBuildingTabs.Documents,
    // },
]

type BuildingTabsProps = {
    activeTab: EBuildingTabs
    onActiveTabChange: (newTab: EBuildingTabs) => void
}

export const BuildingTabs: React.FC<BuildingTabsProps> = ({ activeTab, onActiveTabChange }) => {
    const { t } = useTranslation()
    const isAddBuildingPage = useMatch(ROUTES.buildingAdd)

    return (
        <Tabs
            sx={(theme) => ({ borderBottom: `1px solid ${theme.general.borderColor}` })}
            value={activeTab}
            onChange={(_, newTab) => onActiveTabChange(newTab)}
        >
            {BUILDING_TABS.map((tab) => {
                return (
                    <Tab
                        key={tab.value}
                        disabled={tab.value === EBuildingTabs.Organizations && Boolean(isAddBuildingPage)}
                        value={tab.value}
                        label={t(`createEditBuildingPage.tabs.${tab.label}`)}
                    />
                )
            })}
        </Tabs>
    )
}
