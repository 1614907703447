import React from 'react'
import { useRoutes } from 'react-router-dom'
import globalRouter from 'router'

const RouteManager: React.FC = () => {
    const appContent = useRoutes(globalRouter)

    return <>{appContent}</>
}

export default RouteManager
