import { RootState } from 'redux/store'
import { appApi } from '../redux/api'
import { PaginatedResults } from '../components/general/models/paginated-results'
import { CreateCustomerRequest, Customer, GetCustomersRequest } from '../redux/types/customer.type'

const customersApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getCustomers: builder.query<PaginatedResults<Customer>, GetCustomersRequest>({
            query: (params) => ({
                url: '/v1/customers',
                method: 'GET',
                params,
            }),
            providesTags: ['Customers'],
        }),
        getCustomer: builder.query<Customer, string>({
            query: (params) => ({
                url: `/v1/customers/${params}`,
                method: 'GET',
            }),
            providesTags: ['Customers'],
        }),
        createCustomer: builder.mutation<Customer, CreateCustomerRequest>({
            query: (params) => ({
                url: '/v1/customers',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['Customers'],
        }),
        updateCustomer: builder.mutation<Customer, Customer>({
            query: (params) => ({
                url: `/v1/customers/${params.id}`,
                method: 'PUT',
                body: params,
            }),
            invalidatesTags: ['Customers'],
        }),
        deleteCustomer: builder.mutation<void, { id: string }>({
            query: (params) => ({
                url: `v1/customers/${params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result) => (result === null ? ['Customers'] : []),
        }),
    }),
})

export const {
    useGetCustomersQuery,
    useUpdateCustomerMutation,
    useLazyGetCustomersQuery,
    useCreateCustomerMutation,
    useGetCustomerQuery,
    useDeleteCustomerMutation,
} = customersApi

export const selectCustomer = (state: RootState, customerId?: string) =>
    customersApi.endpoints.getCustomer.select(customerId || '')(state)
