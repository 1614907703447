import React from 'react'
import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TopBar } from 'components/general/top-bar/top-bar'
import { Helmet } from 'react-helmet'
import { PageContainer } from 'components/general/containers/PageContainer'
import { TemplatesTable } from 'modules/settings/templates/TemplatesTable'
import { CreateTemplateDialog } from 'modules/settings/templates/CreateTemplateDialog'

export const TemplatePage: React.FC = () => {
    const { t } = useTranslation()
    const [isCreateTemplateDialogOpen, setIsCreateTemplateDialogOpen] = React.useState(false)

    return (
        <>
            <Helmet>
                <title>{t('common.templatesTitle')}</title>
            </Helmet>
            <TopBar
                rightActions={[
                    <div>
                        <Button
                            startIcon={<Add />}
                            color="secondary"
                            variant="contained"
                            onClick={() => setIsCreateTemplateDialogOpen(true)}
                        >
                            {t('templatesPage.addTemplate')}
                        </Button>
                    </div>,
                ]}
            />
            <PageContainer>
                <TemplatesTable />
            </PageContainer>
            {isCreateTemplateDialogOpen && (
                <CreateTemplateDialog
                    isOpen={isCreateTemplateDialogOpen}
                    handleClose={() => setIsCreateTemplateDialogOpen(false)}
                />
            )}
        </>
    )
}
