import React from 'react'
import { Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { CustomTable } from '../../../components/general/table/custom-table'
import { useTablePagination } from '../../../components/general/table/pagination'
import { selectSelectedTenants, selectTenantFilters, tenantsActions } from '../../../redux/slices/tenant.slice'
import { Tenant } from '../../../redux/types/tenant.type'
import { useGetTenantsQuery } from '../../../services/tenants.service'
import { getTenantsTableStructure } from '../tenant/table-structure'
import { navigationLinks } from 'utils/navigation-utils'

export const TenantsTable: React.FC = React.memo(() => {
    const navigate = useNavigate()
    const selectedItems = useAppSelector(selectSelectedTenants)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const filters = useAppSelector(selectTenantFilters)
    const handleOnSelectChange = (selectedItems: Tenant[]) => {
        dispatch(tenantsActions.setSelectedItems(selectedItems))
    }

    const pagination = useTablePagination()
    const items = useGetTenantsQuery({
        page: pagination.page,
        itemsPerPage: pagination.itemsPerPage,
        sortBy: pagination.sortBy,
        direction: pagination.direction,
        ...filters,
    })

    const tableStructure = getTenantsTableStructure(t)

    return (
        <Paper elevation={0} square={true}>
            {items.isSuccess ? (
                <CustomTable
                    onRowClick={(item: Tenant) => navigate(navigationLinks.toTenant(item.id))}
                    selectedItems={selectedItems}
                    tableStructure={tableStructure}
                    rows={items.data?.data}
                    onSelectChange={handleOnSelectChange}
                    total={items.data?.total ?? 0}
                    pagination={pagination}
                />
            ) : null}
        </Paper>
    )
})
