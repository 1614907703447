import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react'
import { Hidden, TableBody, TableContainer, TableRow, Typography } from '@mui/material'
import { GroupLayout, LayoutProps } from '@jsonforms/core'
import { GroupTableSchemaElement } from 'redux/types/schema.type'
import React from 'react'
import { groupElementsByRowsWithLabel } from 'utils/forms-utils'
import { GroupTableHiddenRow } from 'components/general/jsonForm/view/GroupTableHiddenRow'
import { StyledFixedTable, StyledTableCell } from 'components/general/jsonForm/view/styles'

const GroupTableControlPresentation = ({ uischema, schema, path, visible, renderers, ...props }: LayoutProps) => {
    const groupLayout = uischema as GroupLayout
    const options = groupLayout.options as GroupTableSchemaElement['options']

    if (!groupLayout.elements || groupLayout.elements.length === 0) return null

    const rows = groupElementsByRowsWithLabel(groupLayout.elements, options.columnCount)

    return (
        <Hidden xsUp={!visible}>
            <Typography variant="body1" sx={{ my: 1 }}>
                {groupLayout.label}
            </Typography>
            <TableContainer>
                <StyledFixedTable>
                    <TableBody>
                        <GroupTableHiddenRow
                            columnDefinitions={options.columnDefinitions}
                            tableName={groupLayout.label || ''}
                        />
                        {rows.map((row, rowIdx) => (
                            <TableRow key={`row-${path}-${rowIdx}`}>
                                {row.map((cell, cellIdx) => {
                                    return (
                                        <React.Fragment key={`cell-${path}-${rowIdx}-${cellIdx}`}>
                                            {'label' in cell && typeof cell.label === 'string' && (
                                                <StyledTableCell>{cell.label}</StyledTableCell>
                                            )}
                                            <StyledTableCell>
                                                <JsonFormsDispatch
                                                    uischema={cell}
                                                    schema={schema}
                                                    path={path}
                                                    enabled={props.enabled}
                                                    renderers={renderers}
                                                    cells={props.cells}
                                                />
                                            </StyledTableCell>
                                        </React.Fragment>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledFixedTable>
            </TableContainer>
        </Hidden>
    )
}

export const GroupTableControl = withJsonFormsLayoutProps(GroupTableControlPresentation)
