import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TopBar } from '../../components/general/top-bar/top-bar'
import { navigationLinks } from 'utils/navigation-utils'
import { Helmet } from 'react-helmet'
import { PageContainer } from 'components/general/containers/PageContainer'
import { SchemasTable } from 'modules/settings/schemas/SchemasTable'

export const SchemasPage: React.FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <>
            <Helmet>
                <title>{t('common.schemasTitle')}</title>
            </Helmet>
            <TopBar
                rightActions={[
                    <div>
                        <Button
                            startIcon={<Add />}
                            color={'secondary'}
                            variant={'contained'}
                            onClick={() => navigate(navigationLinks.toAddSchema())}
                        >
                            {t('addSchema', 'Add schema')}
                        </Button>
                    </div>,
                ]}
            />
            <PageContainer>
                <SchemasTable />
            </PageContainer>
        </>
    )
}
