import React from 'react'
import { Logout } from '@mui/icons-material'
import { Divider, ListItem, ListItemIcon, Menu, MenuItem } from '@mui/material'
import { User } from 'redux/types/user.type'
import { useAppDispatch } from 'redux/hooks'
import { authActions } from 'redux/slices/auth.slice'
import { useTranslation } from 'react-i18next'

export type AccountMenuProps = {
    user: User
    onClose: () => void
    anchorEl: HTMLElement | null
}

export const AccountMenu: React.FC<AccountMenuProps> = React.memo(({ user, onClose, anchorEl }) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const createClickHandlerWithClose = (clickHandler: () => void) => {
        return () => {
            clickHandler()
            onClose()
        }
    }

    const handleLogout = createClickHandlerWithClose(() => {
        dispatch(authActions.logout())
    })

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <ListItem>{user.fullName}</ListItem>
                <ListItem>{user.email}</ListItem>

                <Divider />

                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    {t('navigationMenu.logout')}
                </MenuItem>
            </Menu>
        </>
    )
})
