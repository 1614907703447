import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import i18next from 'i18next'
import { toast } from 'react-toastify'

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        // do not handle 401 response
        if (action?.payload?.data?.status === 401) {
            return next(action)
        }
        if (action?.payload?.data?.status === 429) {
            toast.error(i18next.t('API_RATE_LIMIT_ERROR'))
            return next(action)
        }
        const errorKey = action?.payload?.data?.translationKey

        const error = i18next?.t(errorKey) || i18next?.t('genericErrorMessage')
        toast.error(error)
    }

    return next(action)
}
