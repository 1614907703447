import React from 'react'
import { Typography } from '@mui/material'
import { JSONFormViewComp, SchemaElementTypeMap } from 'redux/types/schemaView.type'

export const LabelView: React.FC<JSONFormViewComp<SchemaElementTypeMap['Label']>> = React.memo(({ node }) => {
    if (!node.isVisible || !node.isVisibleInWeb || !node.label || !node?.isLabelVisible) return null

    return (
        <div>
            <Typography sx={{ fontWeight: 600 }} variant="h6">
                {node.label}
            </Typography>
        </div>
    )
})
