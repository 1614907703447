import * as yup from 'yup'
import { emailRegex } from './util'
import { CreateCustomerRequest, Customer } from 'redux/types/customer.type'
import { Organization } from 'redux/types/organization.type'

export type CustomerOrOrganizationForm = Omit<CreateCustomerRequest, 'type'> & {
    isPrivatePerson: boolean
    organizationImport: Organization | null
    customerImport: Customer | null
}

export type GeneralFormEntity = Omit<CustomerOrOrganizationForm, 'contacts'>

export const contactSchema = yup.object().shape({
    name: yup.string().required('required'),
    email: yup.string().required('required').trim().matches(emailRegex, { message: 'invalidEmail' }),
    description: yup.string().nullable(),
    phoneNumber: yup.string().nullable(),
})

const adressSchema = yup.object().shape({
    addressText: yup.string().nullable(),
    postalCode: yup.string().nullable(),
    country: yup.string().nullable(),
})

// --- if customer/organization is a company ----
export const companyCustomerGeneralSchema = yup.object().shape({
    name: yup.string().required('required'),
    organizationNumber: yup.string().required('required'),
    isPrivatePerson: yup.boolean().required('required'),
    email: yup.string().nullable(),
    phoneNumber: yup.string().nullable(),
    address: adressSchema.nullable(),
})
export const companyCustomerFormSchema = companyCustomerGeneralSchema.shape({
    contacts: yup.array().of(contactSchema).nullable(),
})

// ---- if customer/organization is a private person ----
export const personCustomerGeneralSchema = yup.object().shape({
    name: yup.string().required('required'),
    isPrivatePerson: yup.boolean().required('required'),
    email: yup.string().nullable(),
    phoneNumber: yup.string().nullable(),
    address: adressSchema.nullable(),
})

export const personCustomerFormSchema = personCustomerGeneralSchema.shape({
    contacts: yup.array().of(contactSchema).min(1, 'required').required('required'),
})
