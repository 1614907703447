import { Box } from '@mui/material'
import { FormItem } from 'components/buildings/FormItem'
import { SectionBlock } from 'components/buildings/SectionBlock'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Building } from 'redux/types/building.type'
import { BuildingMap } from '../shared/BuildingMap'

export type ViewSectionProps = {
    building: Building
}

export const GeneralSection: React.FC<ViewSectionProps> = ({ building }) => {
    const { t } = useTranslation()
    const { name, cadastre, buildingNumber, address, googleMetadata } = building

    return (
        <SectionBlock density="dense">
            <BuildingMap address={address.addressText} metadata={googleMetadata} />
            <FormItem label={t('common.buildingName')}>{name}</FormItem>

            <FormItem label={t('common.cadastreNumber')}>{cadastre?.cleanNumber ?? '-'}</FormItem>

            {buildingNumber && <FormItem label={t('common.buildingNumber')}>{buildingNumber ?? '-'}</FormItem>}

            <FormItem label={t('common.address')}>{address.addressText}</FormItem>

            <FormItem label={t('common.postalCode')}>
                <Box display="flex" gap={2.5}>
                    <span>{address.postalCode}</span>

                    <span>{address.city}</span>
                </Box>
            </FormItem>

            <FormItem label={t('common.country')}>{address.country}</FormItem>
        </SectionBlock>
    )
}
