import { Box, Paper, Stack, Typography } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ForgotPasswordRequest, useForgotPasswordMutation } from '../../services/auth.service'
import { forgotPasswordSchema } from 'schemas/auth'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { LoadingButton } from '@mui/lab'
import { toast } from 'react-toastify'

const DEFAULT_VALUES: ForgotPasswordRequest = {
    email: '',
}

export const ForgotPasswordPage: React.FC = () => {
    const { t } = useTranslation()

    const [forgot, { isLoading }] = useForgotPasswordMutation()

    const onSubmit = (values: ForgotPasswordRequest) => {
        if (!isLoading) {
            forgot(values).unwrap()
            toast.success(t('authenticationPages.passwordResetMailSent'))
        }
    }

    const { control, handleSubmit } = useForm<ForgotPasswordRequest>({
        mode: 'onSubmit',
        shouldFocusError: true,
        reValidateMode: 'onBlur',
        defaultValues: DEFAULT_VALUES,
        resolver: yupResolver(forgotPasswordSchema),
    })

    return (
        <Paper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box p={6}>
                    <Typography component="h1" variant="h5" align="center">
                        {t('authenticationPages.forgotPassword')}
                    </Typography>
                    <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                        <ControlledMuiTextField
                            fullWidth
                            control={control}
                            name="email"
                            placeholder={t('common.email')}
                        />
                        <LoadingButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            loading={isLoading}
                        >
                            {t('common.send')}
                        </LoadingButton>
                    </Stack>
                </Box>
            </form>
        </Paper>
    )
}
