import { MyProjectsPage } from 'pages/dashboard/MyProjects.page'

const dashboardRoutes = [
    {
        path: '',
        element: <MyProjectsPage />,
    },
]

export default dashboardRoutes
