import { MyFileEntity } from 'redux/types/file.type'
import { ProjectDocumentsEditMode } from './ProjectDocumentsEditMode'
import { ProjectDocumentsViewMode } from './ProjectDocumentsViewMode'
import { Building } from 'redux/types/building.type'

interface ProjectDocumentsTabProps {
    editable: boolean
    isEditMode: boolean
    onEditModeChange: (value: boolean) => void
    completed: boolean
    currentFiles: MyFileEntity[]
    currentBuildings: Building[]
    name: string | null
}

export const ProjectDocumentsTab: React.FC<ProjectDocumentsTabProps> = ({
    editable,
    isEditMode,
    onEditModeChange,
    completed,
    currentFiles,
    currentBuildings,
}) => {
    if ((editable && !isEditMode) || completed) {
        return (
            <ProjectDocumentsViewMode
                files={currentFiles}
                onEditModeChange={onEditModeChange}
                completed={completed}
                projectReport={null}
                projectBuildings={currentBuildings}
            />
        )
    }

    return <ProjectDocumentsEditMode isEditMode={isEditMode} onEditModeChange={onEditModeChange} />
}
