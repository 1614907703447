import React from 'react'
import { FormControl, IconButton, InputAdornment, Paper, TextField, styled } from '@mui/material'
import { TabContainer } from 'components/buildings/TabContainer'
import { CustomerHomeTabs, ECustomerHomeTabs } from './CustomerHomeTabs'
import { CustomerBuildings } from './CustomerBuildings'
import { CustomerProjects } from './CustomerProjects'
import { FOOTER_CONTENT_HEIGHT, HEADER_HEIGHT } from 'constants/styles'
import { Search } from '@mui/icons-material'
import { useAppSelector } from 'redux/hooks'
import { selectUserCustomerIds, selectUserOrganizationIds } from 'redux/slices/auth.slice'
import { useTranslation } from 'react-i18next'

function assertIsFormFieldElement(
    element: Element,
): asserts element is HTMLInputElement | HTMLSelectElement | HTMLButtonElement {
    if (!('value' in element)) {
        throw new Error(`Element is not a form field element`)
    }
}

export const MainPageCard: React.FC = () => {
    const { t } = useTranslation()
    const userOrgIds = useAppSelector(selectUserOrganizationIds)
    const userCustomerIds = useAppSelector(selectUserCustomerIds)

    const [activeTab, setActiveTab] = React.useState<ECustomerHomeTabs>(
        userOrgIds?.length ? ECustomerHomeTabs.Buildings : ECustomerHomeTabs.Projects,
    )
    const [searchQuery, setSearchQuery] = React.useState('')

    const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const firstField = e.currentTarget[0]
        assertIsFormFieldElement(firstField)
        setSearchQuery(firstField.value)
    }

    return (
        <>
            <FilterContainer onSubmit={handleSearch}>
                <FormControl fullWidth>
                    <TextField
                        type="text"
                        fullWidth
                        placeholder={`${t('common.search')}...`}
                        variant="outlined"
                        name="inputSearch"
                        sx={{ '& .MuiOutlinedInput-notchedOutline': { borderColor: '#FFF' } }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type="submit" color="secondary">
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </FilterContainer>

            <StyledPaper elevation={0}>
                <CustomerHomeTabs
                    activeTab={activeTab}
                    onActiveTabChange={setActiveTab}
                    isCustomerFromOrganization={Boolean(userOrgIds?.length)}
                    isCustomerAssignedToTenantCustomers={Boolean(userCustomerIds?.length)}
                />
                <TabContainer>
                    {userOrgIds && userOrgIds.length !== 0 && (
                        <CustomerBuildings activeTab={activeTab} searchQuery={searchQuery} />
                    )}
                    {userCustomerIds && userCustomerIds.length !== 0 && activeTab === ECustomerHomeTabs.Projects && (
                        <CustomerProjects
                            activeTab={activeTab}
                            searchQuery={searchQuery}
                            customerIds={userCustomerIds}
                        />
                    )}
                </TabContainer>
            </StyledPaper>
        </>
    )
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    minHeight: `calc(100vh - ${HEADER_HEIGHT}px - 15px - ${FOOTER_CONTENT_HEIGHT}px - 60px)`,
    paddingBottom: theme.spacing(4),
    width: '100%',
}))

const FilterContainer = styled('form')(({ theme }) => ({
    marginBottom: theme.spacing(2.5),
    background: '#FFF',
}))
