import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { Box, Button, Grid } from '@mui/material'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import ControlledMuiSelect, { IOption } from 'components/inputs/ControlledMuiSelect'
import { Add, Cancel } from '@mui/icons-material'
import { ProjectGeneralInfoRequestForm } from './ProjectDetails'
import { ControlledMuiSwitch } from 'components/inputs/ControlledMuiSwitch'
import { ControlledMuiDateField } from 'components/inputs/ControlledMuiDateField'
import { Building } from 'redux/types/building.type'
import { StyledIconButton } from 'components/projects/EditButton'
import { FormItem } from 'components/projects/FormItem'
import { BuildingsAutoComplete } from 'components/projects/BuildingsAutoComplete'
import { ControlledCustomerAutoComplete } from 'components/projects/ControlledCustomerAutoComplete'
import { useGetCreateProjectFormOptions } from '../hooks/useGetCreateProjectFormOptions'
import { CustomerEditAndCreateDialog } from 'modules/customersAdmin/customers/CustomerEditAndCreateDialog'
import { MyFileEntity, ProjectFileWithMetaData } from 'redux/types/file.type'

interface ProjectDetailsEditModeProps {
    isEditMode: boolean
    onEditModeChange: (value: boolean) => void
}

export const ProjectDetailsEditMode: React.FC<ProjectDetailsEditModeProps> = ({ isEditMode, onEditModeChange }) => {
    const { t } = useTranslation()
    const {
        control,
        setValue,
        getValues,
        reset,
        formState: { defaultValues },
    } = useFormContext<ProjectGeneralInfoRequestForm>()

    const selectedType = useWatch({ control, name: 'type' })
    const selectedStructure = useWatch({ control, name: 'structureId' })
    const selectedManager = useWatch({ control, name: 'projectManagerId' })
    const selectedQA = useWatch({ control, name: 'qualityAssuranceId' })
    const isCreateByUpload = useWatch({ control, name: 'isCreateByUpload' })
    const selectedBuildings = useWatch({ control, name: 'buildings' })

    const {
        structureOptions,
        managerOptions,
        structureTypeOptions,
        templateOptions,
        regulationOptions,
        QAOptions,
        classesOptions,
    } = useGetCreateProjectFormOptions(selectedType, selectedStructure!, selectedManager!, selectedQA!)

    const [isCreateCustomerDialogOpen, setIsCreateCustomerDialogOpen] = React.useState(false)

    const availableFloorOptions = React.useMemo<IOption[]>(() => {
        if (selectedBuildings.length !== 1) return []
        return selectedBuildings[0].floors.map((floor) => ({
            label: floor.name || floor.number.toString(),
            value: floor.number,
        }))
    }, [selectedBuildings])

    const handleRegulationTypeChange = () => {
        setValue('structureId', '')
        setValue('templateId', '')
    }

    const handleStructureChange = () => {
        setValue('templateId', '')
    }

    const handleBuildingChange = (value: Building[]) => {
        setValue('buildingFloors', [])

        const fileState = getValues('files')
        if (fileState.length) {
            const newFileState = fileState.map((file) => ({ ...file, relatedFloors: [] }))
            setValue('files', newFileState as ProjectFileWithMetaData[] | MyFileEntity[])
        }
    }

    const handleCancelEditMode = () => {
        reset()
        onEditModeChange(false)
    }

    return (
        <>
            <div>
                {isEditMode && (
                    <StyledIconButton onClick={handleCancelEditMode}>
                        <Cancel />
                    </StyledIconButton>
                )}
                {/* do not allow to change type when project was already edited */}
                {!isEditMode && (
                    <ControlledMuiSwitch
                        control={control}
                        name="isCreateByUpload"
                        label={t('projectPage.createArchivedProject')}
                    />
                )}
                <Grid
                    container
                    columnSpacing={1}
                    rowSpacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ maxWidth: '90%' }}
                >
                    <FormItem title={t('projectPage.projectName')}>
                        <ControlledMuiTextField control={control} fullWidth name="name" />
                    </FormItem>

                    <FormItem title={t('projectPage.projectType')}>
                        <ControlledMuiSelect
                            name="type"
                            control={control}
                            options={structureTypeOptions}
                            onChangeCb={handleRegulationTypeChange}
                            fullWidth
                        />
                    </FormItem>

                    {isCreateByUpload === true && (
                        <FormItem title={t('projectPage.projectPublishDate')}>
                            <ControlledMuiDateField control={control} name="publishDate" disableFuture fullWidth />
                        </FormItem>
                    )}

                    {isCreateByUpload === false && (
                        <FormItem title={t('common.structure')}>
                            <ControlledMuiSelect
                                name="structureId"
                                control={control}
                                options={structureOptions}
                                fullWidth
                                onChangeCb={handleStructureChange}
                                disabled={!selectedType}
                            />
                        </FormItem>
                    )}

                    {isCreateByUpload === false && (
                        <FormItem title={t('projectPage.projectTemplateForm')}>
                            <ControlledMuiSelect
                                name="templateId"
                                control={control}
                                options={templateOptions}
                                fullWidth
                                disabled={!selectedType || !selectedStructure}
                            />
                        </FormItem>
                    )}

                    <FormItem title={t('projectPage.projectComments')}>
                        <ControlledMuiTextField control={control} fullWidth multiline name="comment" rows={3} />
                    </FormItem>

                    <FormItem title={t('projectPage.projectResponsableCompany')}>
                        <ControlledMuiTextField control={control} fullWidth name="responsableCompany" />
                    </FormItem>

                    <FormItem title={t('projectPage.projectManager')}>
                        <ControlledMuiSelect
                            name="projectManagerId"
                            defaultValue=""
                            control={control}
                            options={managerOptions}
                            fullWidth
                        />
                    </FormItem>

                    <FormItem title={t('projectPage.projectQa')}>
                        <ControlledMuiSelect
                            name="qualityAssuranceId"
                            defaultValue=""
                            control={control}
                            options={QAOptions}
                            fullWidth
                        />
                    </FormItem>

                    <FormItem title={t('projectPage.projectBuildings')}>
                        <BuildingsAutoComplete
                            control={control}
                            defaultValue={(defaultValues?.buildings as Building[]) || []}
                            onChangeCb={handleBuildingChange}
                        />
                    </FormItem>

                    <FormItem title={t('projectPage.relevantBuildingFloorForTheProject')}>
                        <ControlledMuiSelect
                            name="buildingFloors"
                            control={control}
                            options={availableFloorOptions}
                            fullWidth
                            disabled={selectedBuildings.length !== 1}
                            multiple
                        />
                    </FormItem>

                    <FormItem title={t('common.regulation')}>
                        <ControlledMuiSelect
                            name="regulation"
                            control={control}
                            options={regulationOptions}
                            fullWidth
                        />
                    </FormItem>

                    <FormItem title={t('projectPage.regulationDate')}>
                        <ControlledMuiDateField control={control} name="regulationDate" disableFuture fullWidth />
                    </FormItem>

                    <FormItem title={t('common.customer')}>
                        <Box display="flex">
                            <ControlledCustomerAutoComplete />
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => setIsCreateCustomerDialogOpen(true)}
                                sx={{ minWidth: 'unset' }}
                            >
                                <Add />
                            </Button>
                        </Box>
                    </FormItem>

                    <FormItem title={t('projectPage.projectReferenceId')}>
                        <ControlledMuiTextField control={control} fullWidth name="internalReferenceId" />
                    </FormItem>

                    <FormItem title={t('projectPage.projectClass')}>
                        <ControlledMuiSelect name="projectClass" control={control} options={classesOptions} fullWidth />
                    </FormItem>
                </Grid>
            </div>

            <CustomerEditAndCreateDialog
                handleClose={() => setIsCreateCustomerDialogOpen(false)}
                open={isCreateCustomerDialogOpen}
                customer={null}
            />
        </>
    )
}
