import React from 'react'
import {
    Autocomplete,
    AutocompleteChangeReason,
    Box,
    FormControl,
    FormLabel,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography,
    autocompleteClasses,
    lighten,
    styled,
} from '@mui/material'
import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { useTranslation } from 'react-i18next'
import { Search } from '@mui/icons-material'
import { useAppSelector } from 'redux/hooks'
import { selectBuildingPartsOptionsFlatWithAll, selectDisciplinesOptions } from 'redux/selectors/settings.selectors'
import { BuildingPartGroupableOption, NormalizedEntity } from 'types/util'
import { isEqual } from 'lodash'
import { FullProjectWithNormalizedData } from 'redux/types/project.type'
import { FormSectionWithProgress, NormalizedFormSectionsWithProgress } from 'redux/types/formStructure.type'
import { Link, useLocation } from 'react-router-dom'
import { useProjectQueryParams } from '../hooks/useProjectQueryParams'
import { getProjectTreeQueryUrl } from 'components/general/navigation/NormalizedRecursiveTreeView'
import { TagChip } from 'components/general/chips/TagChip'

const INITIAL_FILTER = {
    query: '',
    tag: 'all',
    buildingPart: 'all',
}

export type NavigationFilters = typeof INITIAL_FILTER

const getActiveFilter = (filter: NavigationFilters, query: string) => {
    const filterConditions: Record<string, boolean> = {
        building: filter.buildingPart !== 'all',
        tag: filter.tag !== 'all',
        query: Boolean(query),
    }

    const filtered = Object.entries(filterConditions).filter(([key, value]) => value)
    return Object.fromEntries(filtered)
}

const getCurrentFilterMatches = (activeFilter: Record<string, boolean>) => {
    const filter: Record<string, boolean> = {}
    Object.keys(activeFilter).forEach((key) => (filter[key] = false))

    return filter
}

interface NavigationFilterDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions'> {
    projectFilterData: FullProjectWithNormalizedData['filteringData']
    projectTreeStructure: NormalizedFormSectionsWithProgress
}

export const NavigationFilterDialog: React.FC<NavigationFilterDialogProps> = ({
    handleClose,
    open,
    projectFilterData,
    projectTreeStructure,
}) => {
    // keep the state when browsing throught sections in order for back button to work correctly
    const { state } = useLocation()
    const [search] = useProjectQueryParams()
    const { t } = useTranslation()

    const disciplineOptions = useAppSelector(selectDisciplinesOptions)
    const buildingPartOptionsFlattened = useAppSelector(selectBuildingPartsOptionsFlatWithAll)

    const [filter, setFilter] = React.useState(INITIAL_FILTER)
    const [suggestions, setSuggestions] = React.useState<null | NormalizedEntity<FormSectionWithProgress>[]>(null)

    const accessTokenQueryString = search.accessToken

    const availableDisciplineOptions = React.useMemo(() => {
        if (!projectFilterData.formDisciplineTags) return []

        const allSectionTags = new Set<string>()

        Object.values(projectFilterData.formDisciplineTags).forEach((sectionDisciplines) => {
            sectionDisciplines.forEach(allSectionTags.add, allSectionTags)
        })

        const usedUniqueDisciplineTags = Array.from(allSectionTags)

        if (usedUniqueDisciplineTags.length === 0) return []

        return disciplineOptions.filter((dOpt) => usedUniqueDisciplineTags.includes(dOpt.value as string))
    }, [disciplineOptions, projectFilterData.formDisciplineTags])

    const availableBuildingPartOptions = React.useMemo(() => {
        if (!projectFilterData.formBuildingPartTags) return []
        const allSectionTags = new Set<string>()

        Object.values(projectFilterData.formBuildingPartTags).forEach((sectionBuildingPart) => {
            sectionBuildingPart.forEach(allSectionTags.add, allSectionTags)
        })

        const usedUniqueBuildingPartTags = Array.from(allSectionTags)

        if (usedUniqueBuildingPartTags.length === 0) return []

        const options = buildingPartOptionsFlattened.filter((dOpt) =>
            usedUniqueBuildingPartTags.includes(dOpt.value as string),
        )
        options.unshift({ codeGroup: '0', description: '-', label: t('common.all'), value: 'all' })

        return options
    }, [buildingPartOptionsFlattened, projectFilterData.formBuildingPartTags, t])

    const handleSearch = (filter: NavigationFilters) => {
        // no active filter -> return all sections
        if (filter.tag === 'all' && filter.buildingPart === 'all' && !filter.query) {
            setSuggestions(null)
            return
        }

        const matchingFormIds = new Set<string>()
        const sanitezedQuery = filter.query.trim().toLowerCase()
        // get active filters
        const activeFilters = getActiveFilter(filter, sanitezedQuery)

        for (const sectionKey in projectTreeStructure) {
            const section = projectTreeStructure[sectionKey]

            // only complete sections will have something filterable in the web view
            if (section.progress !== 'complete') continue
            // matching filters map
            const filterMatches = getCurrentFilterMatches(activeFilters)

            // loop throught each active filter
            // if at least one case fails we break out of the loop and dont need to continue
            for (const filterKey in activeFilters) {
                if (filterKey === 'tag') {
                    if (!(sectionKey in projectFilterData.formDisciplineTags)) break

                    const foundDisciplineTagIdx = projectFilterData.formDisciplineTags[sectionKey].findIndex(
                        (formTag) => formTag === filter.tag,
                    )

                    if (foundDisciplineTagIdx !== -1) {
                        filterMatches.tag = true
                    } else {
                        break
                    }
                }

                if (filterKey === 'building') {
                    if (!(sectionKey in projectFilterData.formBuildingPartTags)) break

                    const foundBuildingTagIdx = projectFilterData.formBuildingPartTags[sectionKey].findIndex(
                        (formTag) => formTag.includes(filter.buildingPart),
                    )

                    if (foundBuildingTagIdx !== -1) {
                        filterMatches.building = true
                    } else {
                        break
                    }
                }

                // searches both answers and labels
                if (filterKey === 'query') {
                    if (
                        !(sectionKey in projectFilterData.formattedFormAnswers) &&
                        !(sectionKey in projectFilterData.formLabels)
                    ) {
                        break
                    }

                    const foundAnswerIdx = projectFilterData.formattedFormAnswers[sectionKey]?.findIndex((answer) =>
                        answer.includes(sanitezedQuery),
                    )

                    if (foundAnswerIdx !== -1) {
                        filterMatches.query = true
                        break
                    }

                    const foundLabelIdx = projectFilterData.formLabels[sectionKey]?.findIndex((label) =>
                        label.includes(sanitezedQuery),
                    )

                    if (foundLabelIdx !== -1) {
                        filterMatches.query = true
                    }
                }
            }

            const fulfilsAllFilterConditions =
                Object.values(filterMatches).filter((cond) => cond).length === Object.keys(activeFilters).length

            if (fulfilsAllFilterConditions) {
                matchingFormIds.add(sectionKey)
            }
        }

        const newSuggestions = []

        for (const id of Array.from(matchingFormIds)) {
            newSuggestions.push(projectTreeStructure[id])
        }

        setSuggestions(newSuggestions)
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        handleSearch(filter)
    }

    const handleDisciplineTagChange = (e: SelectChangeEvent<string>) => {
        const newFilterState = { ...filter, [e.target.name]: e.target.value as string }
        setFilter(newFilterState)
        handleSearch(newFilterState)
    }

    const handleBuildingTagChange = (tag: BuildingPartGroupableOption | null, reason: AutocompleteChangeReason) => {
        if (reason === 'clear') {
            const newFilterState = { ...filter, buildingPart: 'all' }
            setFilter(newFilterState)
            handleSearch(newFilterState)
            return
        }
        const newFilterState = { ...filter, buildingPart: tag?.value.toString() || 'all' }
        setFilter(newFilterState)
        handleSearch(newFilterState)
    }

    return (
        <ModalsWrapper
            open={open}
            handleClose={handleClose}
            fullWidth
            maxWidth="sm"
            body={
                <>
                    <form onSubmit={handleSubmit}>
                        <Stack gap={1.5} sx={{ mb: 3 }}>
                            <FormControl fullWidth>
                                <StyledFormLabel>{t('common.search')}</StyledFormLabel>
                                <TextField
                                    sx={{ mt: 1.5 }}
                                    type="text"
                                    name="query"
                                    value={filter.query}
                                    onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.value })}
                                    size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton type="submit">
                                                    <Search />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                            <StyledFormLabel>{t('projectPage.responsableTag')}</StyledFormLabel>
                            <FormControl fullWidth>
                                <Select
                                    value={filter.tag}
                                    name="tag"
                                    size="small"
                                    variant="outlined"
                                    onChange={handleDisciplineTagChange}
                                >
                                    <MenuItem value="all">{t('common.all')}</MenuItem>
                                    {availableDisciplineOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <StyledFormLabel>{t('projectPage.buildingPart')}</StyledFormLabel>
                            <FormControl fullWidth>
                                <Autocomplete
                                    size="small"
                                    fullWidth
                                    options={availableBuildingPartOptions}
                                    groupBy={(option) => option.codeGroup}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>{params.group}</GroupHeader>
                                            <GroupItems>{params.children}</GroupItems>
                                        </li>
                                    )}
                                    renderOption={(props, option: BuildingPartGroupableOption, state) => {
                                        return (
                                            <Box
                                                sx={{
                                                    flexDirection: 'column',
                                                    margin: '2px 0px',
                                                    [`&.${autocompleteClasses.option}`]: {
                                                        padding: '4px',
                                                        alignItems: 'flex-start',
                                                    },
                                                }}
                                                component="li"
                                                {...props}
                                                key={option.value}
                                            >
                                                <Typography variant="subtitle1">{option.label}</Typography>
                                                {option.description && (
                                                    <Typography variant="caption" sx={{ fontSize: 10 }}>
                                                        {option.description}
                                                    </Typography>
                                                )}
                                            </Box>
                                        )
                                    }}
                                    value={buildingPartOptionsFlattened.find(
                                        (opt) => opt.value === filter.buildingPart,
                                    )}
                                    renderInput={(params) => (
                                        <TextField variant="outlined" inputRef={params.InputProps.ref} {...params} />
                                    )}
                                    onChange={(_, newValue, reason) => handleBuildingTagChange(newValue, reason)}
                                    isOptionEqualToValue={(option, value) => isEqual(option.value, value.value)}
                                />
                            </FormControl>
                        </Stack>
                    </form>
                    {suggestions !== null && (
                        <SuggestionContainer>
                            {suggestions.length === 0 ? (
                                <ListItem sx={{ textAlign: 'center', padding: 0 }}>
                                    <ListItemText>{t('common.noResultsFound')}</ListItemText>
                                </ListItem>
                            ) : (
                                suggestions.map((section) => (
                                    <SuggestionItem key={section.sectionId}>
                                        <ListItemButton
                                            component={Link}
                                            to={getProjectTreeQueryUrl(section.sectionId, accessTokenQueryString)}
                                            state={state}
                                            onClick={handleClose}
                                        >
                                            <span>{section.label}</span>

                                            {filter.buildingPart !== INITIAL_FILTER.buildingPart && (
                                                <FoundBuiltingTagContainer>
                                                    {projectFilterData.formBuildingPartTags[section.sectionId]
                                                        .filter((tag) => tag.includes(filter.buildingPart))
                                                        .map((tag) => (
                                                            <TagChip label={tag} size="small" />
                                                        ))}
                                                </FoundBuiltingTagContainer>
                                            )}
                                        </ListItemButton>
                                    </SuggestionItem>
                                ))
                            )}
                        </SuggestionContainer>
                    )}
                </>
            }
        />
    )
}

const StyledFormLabel = styled(FormLabel)({
    fontWeight: 700,
    color: '#000',
})

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
}))

const GroupItems = styled('ul')({
    padding: 0,
})

const SuggestionContainer = styled(List)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    overflowX: 'hidden',
    overflowY: 'auto',
    marginBottom: theme.spacing(4),
    maxHeight: 400,
}))

const SuggestionItem = styled(ListItem)(({ theme }) => ({
    border: `2px solid ${theme.general.borderColor}`,
    padding: theme.spacing(0.5, 0),
    borderRadius: 4,
    fontWeight: 600,

    '& .MuiListItemButton-root:hover': {
        backgroundColor: 'transparent',
    },

    '&:hover': {
        borderColor: theme.palette.secondary.main,
        backgroundColor: 'transparent',
        color: theme.palette.secondary.main,
    },

    '& .MuiListItemButton-root': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
}))

const FoundBuiltingTagContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),

    '& > div': {
        fontSize: '12px',
        height: '20px',
    },
}))
