import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Building } from 'redux/types/building.type'
import { TagChip } from 'components/general/chips/TagChip'

interface BuildingInfoChipProps {
    building: Building
    size?: 'small' | 'medium'
}

export const BuildingInfoChip: React.FC<BuildingInfoChipProps> = ({ building, size = 'medium' }) => {
    const { t } = useTranslation()

    return (
        <Tooltip title={`${t('common.buildingNumber')}: ${building.buildingNumber}`} arrow key={building.id}>
            <TagChip
                label={`${building.address.addressText} ${
                    building.cadastre?.cleanNumber ? `(${building.cadastre?.cleanNumber})` : '-'
                }`}
                size={size}
            />
        </Tooltip>
    )
}
