import { Box, Paper, Stack, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch } from '../../redux/hooks'
import { useResetPasswordMutation } from '../../services/auth.service'
import { authActions } from '../../redux/slices/auth.slice'
import { LoadingButton } from '@mui/lab'
import { ResetPasswordForm, resetPasswordSchema } from 'schemas/resetPassword'
import { yupResolver } from '@hookform/resolvers/yup'
import { ControlledPasswordInput } from 'components/inputs/ControlledPasswordInput'

const DEFAULT_VALUES: ResetPasswordForm = {
    password: '',
    confirmPassword: '',
}

export const ResetPasswordPage: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const userId = searchParams.get('userId')
    const code = searchParams.get('code')
    const [forgot, { isLoading }] = useResetPasswordMutation()

    const { control, handleSubmit } = useForm<ResetPasswordForm>({
        mode: 'onSubmit',
        shouldFocusError: true,
        shouldUnregister: false,
        reValidateMode: 'onBlur',
        defaultValues: DEFAULT_VALUES,
        resolver: yupResolver(resetPasswordSchema),
    })

    const onSubmit = async (values: ResetPasswordForm) => {
        if (code && userId) {
            const email = atob(userId)
            forgot({ ...values, email, token: code })
                .unwrap()
                .then((response) => {
                    dispatch(authActions.setCredentials(response))
                    navigate('/')
                })
        }
    }

    return (
        <Paper>
            <Box p={6}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Typography component="h1" variant="h5" align="center">
                        {t('resetPasswordPage.setNewPassword')}
                    </Typography>
                    <Stack direction="column" spacing={0} sx={{ mt: 2, gap: 2 }}>
                        <ControlledPasswordInput
                            placeholder={t('common.password')}
                            name="password"
                            control={control}
                            fullWidth
                        />
                        <ControlledPasswordInput
                            placeholder={t('common.passwordConfirm')}
                            name="confirmPassword"
                            control={control}
                            fullWidth
                        />
                    </Stack>
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        loading={isLoading}
                    >
                        {t('common.save')}
                    </LoadingButton>
                </form>
            </Box>
        </Paper>
    )
}
