import { Box, SortDirection } from '@mui/material'
import React from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { ProjectWithoutForms } from 'redux/types/project.type'
import { useTranslation } from 'react-i18next'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { DataGridTitle } from 'components/general/table/DataGridTitle'
import { DataGridNoRows } from 'components/general/table/DataGridNoRows'
import { useGetArchivedProjectsQuery } from 'services/projects.service'
import { ROUTES } from 'constants/routes'
import { navigationLinks } from 'utils/navigation-utils'
import { DataGridLinkCell } from 'components/general/table/DataGridLinkCell'
import { DATE_LOCALE_OPTIONS } from 'constants/locales'
import { valueOrDefaultEmpty } from 'utils/general'
import { ProjectStatusCell } from 'components/projects/ProjectStatusCell'
import { BuildingProjectsProps } from './BuildingProjects'
import { useDataGridFiltersState } from 'utils/hooks/useDataGridFiltersState'
import { useMatch } from 'react-router-dom'

// NOTE: this component is used in admin buildings page and reused in customer building page
export const BuildingFinishedProjects: React.FC<BuildingProjectsProps> = React.memo(({ buildingId, customerId }) => {
    const { t } = useTranslation()
    const matchesCustomerBuilding = useMatch(ROUTES.customerBuilding)
    const isCustomerBuildingPage = Boolean(matchesCustomerBuilding)

    const { handlePageChange, handleSortChange, page, sortBy } = useDataGridFiltersState({
        sort: 'desc',
        field: 'name',
    })

    const { data: projects, isLoading } = useGetArchivedProjectsQuery({
        page: page || 0,
        direction: sortBy?.sort as SortDirection,
        sortBy: sortBy?.field,
        buildingId,
        customerIds: customerId ? [customerId] : undefined,
        itemsPerPage: DEFAULT_ROWS_PER_PAGE,
    })

    const columns: GridColDef<ProjectWithoutForms>[] = [
        {
            field: 'type',
            headerName: t('buildingProjectsTable.type'),
            flex: 1,
            editable: false,
            valueGetter: (params) => valueOrDefaultEmpty(params.row.type?.label),
        },
        {
            field: 'name',
            headerName: t('buildingProjectsTable.name'),
            flex: 1,
            editable: false,
            // this logic will be different then in MyProjectsTable when we implement users
            // so just copy pasting for now 2023-08-03
            renderCell: (params) => {
                const state = {
                    from: isCustomerBuildingPage ? ROUTES.customerBuilding : ROUTES.projects,
                }

                const link = isCustomerBuildingPage
                    ? {
                          search: `accessToken=${params.row.accessToken!}`,
                          pathname: navigationLinks.toCustomerViewProject(params.row.id),
                      }
                    : navigationLinks.toPreviewProject(params.row.id)

                return (
                    <DataGridLinkCell
                        label={params.row.name || t('common.noProjectNamePlaceholder')}
                        to={link}
                        state={state}
                    />
                )
            },
        },
        {
            field: 'regulation',
            headerName: t('buildingProjectsTable.regulation'),
            flex: 1,
            editable: false,
            valueGetter: (params) => params.row.regulation?.name || '-',
        },
        {
            field: 'publishDate',
            headerName: t('buildingProjectsTable.publicationDate'),
            flex: 1,
            editable: false,
            valueGetter: (params) =>
                params.row.publishDate
                    ? new Date(params.row.publishDate).toLocaleDateString('no', DATE_LOCALE_OPTIONS['no'])
                    : '-',
        },
        {
            field: 'status',
            headerName: t('buildingProjectsTable.status'),
            flex: 1,
            editable: false,
            maxWidth: 150,
            renderCell: (params) => <ProjectStatusCell status={params.row.status} />,
        },
    ]

    return (
        <Box>
            <DataGrid
                sx={{
                    padding: '1.25rem 1rem',
                    '& .MuiDataGrid-columnHeader': {
                        textTransform: 'capitalize',
                    },
                }}
                autoHeight
                rows={projects?.data || []}
                columns={columns}
                pageSize={DEFAULT_ROWS_PER_PAGE}
                rowCount={projects?.total || 0}
                isRowSelectable={() => false}
                disableColumnMenu
                loading={isLoading}
                hideFooterSelectedRowCount
                rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                components={{
                    Toolbar: DataGridTitle,
                    NoRowsOverlay: DataGridNoRows,
                }}
                componentsProps={{
                    toolbar: { title: t('buildingProjectsTable.titleFinished') },
                }}
                paginationMode="server"
                sortingMode="server"
                onPageChange={handlePageChange}
                onSortModelChange={handleSortChange}
                page={page || 0}
                sortModel={sortBy ? [{ field: sortBy.field, sort: sortBy.sort }] : []}
            />
        </Box>
    )
})
