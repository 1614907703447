import React from 'react'
import { TextItem } from './TextItem'
import { JSONFormViewComp, SchemaElementTypeMap } from 'redux/types/schemaView.type'
import { DATE_LOCALE_OPTIONS } from 'constants/locales'

export const DateView: React.FC<JSONFormViewComp<SchemaElementTypeMap['date']>> = React.memo(
    ({ node, direction = 'row' }) => {
        if (!node.isVisible || !node.isVisibleInWeb || node.value === undefined) return null

        return (
            <TextItem
                direction={direction}
                label={node.label}
                value={new Date(node.value).toLocaleDateString('no', DATE_LOCALE_OPTIONS['no'])}
                isLabelVisible={node.isLabelVisible}
            />
        )
    },
)
