import { Alert, Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material'
import * as O from 'optics-ts'
import React from 'react'
import { RulesEditor } from '../rules/rules-editor'
import { Render } from '../schema-builder'
import { useName, useRule } from '../hooks/schema-hooks'
import { AddFieldDialog } from './add-field-dialog'
import { BuilderFieldProps } from './builder-field-props'
import { Draggable, Droppable } from './droppable'
import { FieldDialog } from './field-dialog'
import { FieldItem } from './field-item'
import { NameLabel } from './general/field-name-label'
import { TabPanel } from './general/field-tab-panel'
import { FieldTabs } from './general/field-tabs'
import { ArraySchemaElement, SchemaElement } from 'redux/types/schema.type'
import { useTranslation } from 'react-i18next'
import { getColSizes, validateColumnsSizes } from 'utils/schema-utils'
import { ArrColSizeDefinition } from 'types/util'

const buildColumnDictionaryFromNodes = (nodes: SchemaElement[], oldColSizes: Record<string, ArrColSizeDefinition>) => {
    const newColSizes: Record<string, ArrColSizeDefinition> = {}

    nodes.forEach((childNode: any, idx) => {
        const name = childNode.name || idx.toString()
        const oldCol = oldColSizes[name]

        if (oldCol) {
            newColSizes[name] = {
                isDirty: oldCol.isDirty,
                unit: oldCol.unit,
                size: oldCol.isDirty ? oldCol.size : 'auto',
            }
        } else {
            newColSizes[name] = {
                unit: '%',
                isDirty: false,
                size: 'auto',
            }
        }
    })

    return newColSizes
}

export const BuilderArray: React.FC<BuilderFieldProps<ArraySchemaElement>> = ({
    node,
    state,
    setState,
    lens,
    path,
}) => {
    const { t } = useTranslation()
    const nameHook = useName(lens, state, setState)
    const rule = useRule(lens, state, setState)
    const showSortButtons = lens.prop('options').prop('showSortButtons')
    const showSortButtonsValue = O.get(showSortButtons)(state) as boolean
    const nodes = lens.prop('nodes')
    const sizeOptions = getColSizes()

    const [tabIndex, setTabIndex] = React.useState(0)
    const [colSizes, setColSizes] = React.useState<Record<string, ArrColSizeDefinition>>(node.options.columnDefinitions)
    const [isColSizeValid, setIsColSizeValid] = React.useState(true)

    const remove = () => setState(O.remove(lens)(state))

    React.useEffect(() => {
        if (node.nodes.length === 0) return

        setColSizes((prev) => {
            const newColSizes = buildColumnDictionaryFromNodes(node.nodes, prev)
            return newColSizes
        })
    }, [node.nodes])

    const handleSave = () => {
        const isValid = validateColumnsSizes(colSizes)
        setIsColSizeValid(isValid)
        if (!isValid) return isValid

        rule.onSaveRules()
        nameHook.setColSizesForArr(colSizes)
        return true
    }

    return (
        <Draggable id={`${path}`} styling={{ alignItems: 'center' }}>
            <FieldItem node={node} nameValue={nameHook.name} hasRules={rule.hasRules} />
            <FieldDialog node={node} remove={remove} onSave={handleSave}>
                <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                <TabPanel value={tabIndex} index={0}>
                    <NameLabel nameHook={nameHook} fieldType={node.type} />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showSortButtonsValue}
                                onChange={(x) => setState(O.set(showSortButtons)(x.target.checked))}
                            />
                        }
                        label={t('schemaPage.showSortButtons')}
                    />
                    <Droppable id={`${path}.nodes.${0}`} />
                    {node.nodes.map((x: any, i: number) => {
                        const nodeLens = nodes.at(i)
                        const nodeName = x.name || i.toString()

                        return (
                            <React.Fragment key={i}>
                                <Box display="flex" maxWidth={400}>
                                    <Render
                                        state={state}
                                        setState={setState}
                                        lens={nodeLens}
                                        path={`${path}.nodes.${i}`}
                                    />
                                    <FormControl sx={{ minWidth: 100 }}>
                                        <InputLabel>{t('common.size')}</InputLabel>
                                        <Select
                                            name={nodeName}
                                            size="small"
                                            label={t('common.size')}
                                            value={colSizes[nodeName]?.size || ''}
                                            onChange={(e) =>
                                                setColSizes({
                                                    ...colSizes,
                                                    [nodeName]: {
                                                        unit: '%',
                                                        isDirty: true,
                                                        size: e.target.value,
                                                    },
                                                })
                                            }
                                        >
                                            {sizeOptions.map((size) => (
                                                <MenuItem key={size} value={size}>
                                                    {`${size} ${size === 'auto' ? '' : '%'}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Droppable id={`${path}.nodes.${i + 1}`} />
                            </React.Fragment>
                        )
                    })}
                    <AddFieldDialog nodesLens={nodes} setState={setState} parentField="array" />
                    {!isColSizeValid && (
                        <Alert severity="error" sx={{ my: 2 }}>
                            {t('schemaPage.incorrectColSizeSumTitle')}
                        </Alert>
                    )}
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                </TabPanel>
            </FieldDialog>
        </Draggable>
    )
}
