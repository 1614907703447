import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ViewSectionProps } from './GeneralSection'
import { TagChip } from 'components/general/chips/TagChip'
import { SectionBlock } from 'components/buildings/SectionBlock'
import { FormItem } from 'components/buildings/FormItem'

export const CultureSection: React.FC<ViewSectionProps> = ({ building }) => {
    const { t } = useTranslation()
    const { culturalHeritage, culturalHeritageTags } = building

    return (
        <SectionBlock density="dense">
            <FormItem label={t('createEditBuildingPage.culturalHeritage')}>
                <Box display="flex" gap={1} alignItems="center">
                    <Typography variant="body1" component="span" sx={{ mr: 4 }}>
                        {culturalHeritage === true ? t('common.yes') : t('common.no')}
                    </Typography>
                    {culturalHeritageTags.length > 0
                        ? culturalHeritageTags.map((tag) => <TagChip label={tag.label} key={tag.value} />)
                        : null}
                </Box>
            </FormItem>
        </SectionBlock>
    )
}
