import React from 'react'
import { Delete, Edit } from '@mui/icons-material'
import { Card, IconButton, SortDirection } from '@mui/material'
import { DataGrid, GridColDef, GridSortDirection } from '@mui/x-data-grid'
import { DataGridNoRows } from 'components/general/table/DataGridNoRows'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { useTranslation } from 'react-i18next'
import { User } from 'redux/types/user.type'
import { useDeleteTenantUserMutation, useGetTenantUsersQuery } from 'services/tenantUsers.service'
import { valueOrDefaultEmpty } from 'utils/general'
import { useDataGridQueryFiltersState } from 'utils/hooks/useDataGridQueryFiltersState'
import { CreateOrEditUserDialog } from './CreateOrEditUserDialog'
import DeleteDialog from 'components/general/dialogs/DeleteDialog'

type TenantUsersTableProps = {
    tenantId: string
    isSuperAdmin: boolean
}

export const TenantUsersTable: React.FC<TenantUsersTableProps> = ({ isSuperAdmin, tenantId }) => {
    const { t } = useTranslation()
    const {
        handlePageChange,
        handleSortChange,
        search: { field, page, sort },
    } = useDataGridQueryFiltersState({ sort: 'asc', field: 'email' })

    const { data: users, isFetching } = useGetTenantUsersQuery(
        {
            page: page || 0,
            itemsPerPage: DEFAULT_ROWS_PER_PAGE,
            direction: sort as SortDirection,
            sortBy: field ?? undefined,
            tenantId: tenantId!,
        },
        { skip: page === undefined || !tenantId },
    )

    const [deleteUser, { isLoading: isDeleting }] = useDeleteTenantUserMutation()

    const [userToEdit, setUserToEdit] = React.useState<User | null>(null)
    const [userIdToDelete, setUserIdToDelete] = React.useState<string | null>(null)

    const columns: GridColDef<User>[] = [
        {
            field: 'fullName',
            headerName: t('usersPage.nameColumnHeader'),
            flex: 1,
            editable: false,
            // TODO: name shouldnt be nullable remove when finished converting existing records
            valueGetter: (params) => valueOrDefaultEmpty(params.row.fullName),
        },
        {
            field: 'email',
            headerName: t('usersPage.emailColumnHeader'),
            flex: 1,
            editable: false,
        },
        {
            field: 'occupation',
            headerName: t('usersPage.occupationColumnHeader'),
            flex: 1,
            editable: false,
            // TODO: name shouldnt be nullable remove when finished converting existing records
            valueGetter: (params) => valueOrDefaultEmpty(params.row.occupation),
        },
        {
            field: '',
            width: isSuperAdmin ? 100 : 50,
            filterable: false,
            sortable: false,
            editable: false,
            type: 'action',
            renderCell: (params) => (
                <div>
                    <IconButton onClick={() => setUserToEdit(params.row)} color="secondary">
                        <Edit />
                    </IconButton>
                    {isSuperAdmin && (
                        <IconButton onClick={() => setUserIdToDelete(params.row.id)} color="error">
                            <Delete />
                        </IconButton>
                    )}
                </div>
            ),
        },
    ]

    const handleDelete = async () => {
        if (isDeleting) return
        if (!userIdToDelete) throw new Error('Cant call this method without initialized userIdToDelete state')
        await deleteUser({ id: userIdToDelete })
        setUserIdToDelete(null)
    }

    return (
        <>
            <Card sx={{ minHeight: '400px', padding: 2.5 }} elevation={1}>
                <DataGrid
                    sx={{
                        '& .MuiDataGrid-columnHeader': {
                            textTransform: 'capitalize',
                        },
                    }}
                    autoHeight
                    rows={users?.data || []}
                    columns={columns}
                    rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                    pageSize={DEFAULT_ROWS_PER_PAGE}
                    rowCount={users?.total || 0}
                    isRowSelectable={() => false}
                    disableColumnMenu
                    loading={isFetching}
                    hideFooterSelectedRowCount
                    components={{
                        NoRowsOverlay: DataGridNoRows,
                    }}
                    paginationMode="server"
                    sortingMode="server"
                    onPageChange={handlePageChange}
                    onSortModelChange={handleSortChange}
                    page={page || 0}
                    sortModel={field && sort ? [{ field, sort: sort as GridSortDirection }] : []}
                />
            </Card>
            {userToEdit && (
                <CreateOrEditUserDialog
                    handleClose={() => setUserToEdit(null)}
                    open={Boolean(userToEdit)}
                    user={userToEdit}
                />
            )}

            <DeleteDialog
                open={Boolean(userIdToDelete)}
                handleClose={() => setUserIdToDelete(null)}
                deleteAction={handleDelete}
                isDeleting={isDeleting}
            />
        </>
    )
}
