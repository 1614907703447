import { SortDirection } from '@mui/material'
import { useState } from 'react'

export type Order = 'asc' | 'desc'

export type Pagination = {
    sortBy: string
    direction: SortDirection
    itemsPerPage: number
    page: number
    handleChangePage: (newPage: number) => any
    onSortChanged?: (orderBy: string, order: Order) => any
    handleChangeRowsPerPage: (itemsPerPage: any) => any
}

export const useTablePagination = (): Pagination => {
    const [pageNumber, setPageNumber] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(50)
    const [sortBy, setSortBy] = useState('name')
    const [direction, setDirection] = useState<SortDirection>('desc')

    const handleOnSortChange = (orderBy: string, order: SortDirection) => {
        setSortBy(orderBy)
        setDirection(order)
    }

    return {
        sortBy,
        direction,
        itemsPerPage: itemsPerPage,
        page: pageNumber,
        onSortChanged: handleOnSortChange,
        handleChangePage: setPageNumber,
        handleChangeRowsPerPage: setItemsPerPage,
    }
}
