import React from 'react'
import { Card, SortDirection, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DataGrid, GridColDef, GridRowHeightParams } from '@mui/x-data-grid'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { useGetProjectsQuery, useUpdateProjectStatusMutation } from 'services/projects.service'
import { DataGridTitle } from 'components/general/table/DataGridTitle'
import { DATE_LOCALE_OPTIONS } from 'constants/locales'
import { useDataGridFiltersState } from 'utils/hooks/useDataGridFiltersState'
import { ProjectWithoutForms } from 'redux/types/project.type'
import { DataGridNoRows } from 'components/general/table/DataGridNoRows'
import { ProjectBuildingListLinkCell } from 'components/general/table/ProjectBuildingListCell'
import { DataGridLinkCell } from 'components/general/table/DataGridLinkCell'
import { navigationLinks } from 'utils/navigation-utils'
import { ROUTES } from 'constants/routes'
import { valueOrDefaultEmpty } from 'utils/general'
import { ProjectStatusCell } from 'components/projects/ProjectStatusCell'
import { DataGridActionsCell } from 'components/general/table/DataGridActionsCell'
import ConfirmDialog from 'components/general/dialogs/ConfirmDialog'
import { OnGoingProjectActionsMenu } from './OnGoingProjectActionsMenu'
import { TableActionMenuProps } from 'types/util'

export const MyProjectsTable: React.FC<{ userId: string | null }> = ({ userId }) => {
    const { t } = useTranslation()

    const { page, sortBy, handlePageChange, handleSortChange } = useDataGridFiltersState({
        sort: 'desc',
        field: 'name',
    })

    const { data: myProjects, isFetching } = useGetProjectsQuery(
        {
            managerId: userId || '',
            page,
            itemsPerPage: DEFAULT_ROWS_PER_PAGE,
            direction: sortBy?.sort as SortDirection,
            sortBy: sortBy?.field,
        },
        { skip: !userId },
    )

    const [updateStatus, { isLoading: isUpdatingStatus }] = useUpdateProjectStatusMutation()

    const [anchorElProps, setAnchorPropsEl] = React.useState<TableActionMenuProps>(null)
    const [projectIdForConfirmation, setProjectIdForConfirmation] = React.useState<string | null>(null)

    const openActionMenu = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
        setAnchorPropsEl(anchorElProps ? null : { anchorEl: event.currentTarget, rowId })
    }

    const closeActionMenu = () => setAnchorPropsEl(null)

    const handleConfirmCancelQaProcess = (rowId: string) => {
        setProjectIdForConfirmation(rowId)
        closeActionMenu()
    }

    const handleCancelQaProcess = async () => {
        const project = myProjects?.data.find((project) => project.id === projectIdForConfirmation)

        if (!project) throw new Error('no project from rows found')
        await updateStatus({ id: project.id, status: 'FillingBuiltForm' })
        setProjectIdForConfirmation(null)
    }

    const columns: GridColDef<ProjectWithoutForms>[] = [
        {
            field: 'name',
            headerName: t('projectsTable.nameHeader'),
            flex: 1,
            editable: false,
            maxWidth: 200,
            minWidth: 180,
            renderCell: (params) => {
                let link
                const state = {
                    from: ROUTES.dashboard,
                }
                if (params.row.status === 'WaitingForQa')
                    link = navigationLinks.toPreviewInProgressProject(params.row.id)
                else link = navigationLinks.toCreateProject(params.row.id)

                return (
                    <DataGridLinkCell
                        label={params.row.name || t('common.noProjectNamePlaceholder')}
                        to={link}
                        state={state}
                    />
                )
            },
        },
        {
            field: 'regulationType',
            headerName: t('projectsTable.typeHeader'),
            flex: 1,
            minWidth: 200,
            width: 200,
            valueGetter: (params) => valueOrDefaultEmpty(params.row.type?.label),
            editable: false,
        },
        {
            field: 'buildings',
            headerName: t('projectsTable.buildingHeader'),
            flex: 1,
            editable: false,
            minWidth: 400,
            width: 400,
            renderCell: (params) => <ProjectBuildingListLinkCell buildings={params.row.buildings} />,
        },
        {
            field: 'status',
            headerName: t('projectsTable.statusHeader'),
            flex: 1,
            editable: false,
            maxWidth: 200,
            minWidth: 180,
            renderCell: (params) => <ProjectStatusCell status={params.row.status} />,
        },
        {
            field: 'created',
            headerName: t('projectsTable.createdHeader'),
            flex: 1,
            editable: false,
            width: 110,
            minWidth: 110,
            // TODO: if more languages will be supported the locale value should be hardcoded
            valueGetter: (params) => new Date(params.row.created).toLocaleDateString('no', DATE_LOCALE_OPTIONS['no']),
        },
        {
            field: 'updated',
            headerName: t('projectsTable.updatedHeader'),
            flex: 1,
            editable: false,
            minWidth: 110,
            width: 110,
            // TODO: if more languages will be supported the locale value should be hardcoded
            valueGetter: (params) => new Date(params.row.updated).toLocaleDateString('no', DATE_LOCALE_OPTIONS['no']),
        },
        {
            field: '',
            maxWidth: 50,
            filterable: false,
            sortable: false,
            editable: false,
            type: 'action',
            renderCell: (params) =>
                params.row.status === 'WaitingForQa' ? (
                    <DataGridActionsCell onOpen={openActionMenu} rowId={params.row.id} />
                ) : null,
        },
    ]

    return (
        <>
            <Card sx={{ minHeight: '400px' }} elevation={1}>
                <DataGrid
                    sx={{
                        padding: '1.25rem 1rem',
                        '& .MuiDataGrid-columnHeader': {
                            textTransform: 'capitalize',
                        },
                    }}
                    autoHeight
                    rows={myProjects?.data || []}
                    columns={columns}
                    rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                    pageSize={DEFAULT_ROWS_PER_PAGE}
                    rowCount={myProjects?.total || 0}
                    isRowSelectable={() => false}
                    disableColumnMenu
                    loading={isFetching}
                    hideFooterSelectedRowCount
                    components={{
                        Toolbar: DataGridTitle,
                        NoRowsOverlay: DataGridNoRows,
                    }}
                    componentsProps={{
                        toolbar: { title: t('dashboard.myProjects') },
                    }}
                    paginationMode="server"
                    sortingMode="server"
                    onPageChange={handlePageChange}
                    onSortModelChange={handleSortChange}
                    page={page}
                    sortModel={sortBy ? [sortBy] : []}
                    getRowHeight={({ model }: GridRowHeightParams) => {
                        if (model.buildings.length > 1) return 'auto'

                        return null
                    }}
                />
            </Card>
            <OnGoingProjectActionsMenu
                anchorEl={anchorElProps?.anchorEl}
                id={anchorElProps?.rowId}
                onClose={closeActionMenu}
                onCancelQaAction={handleConfirmCancelQaProcess}
            />
            <ConfirmDialog
                handleClose={() => setProjectIdForConfirmation(null)}
                open={Boolean(projectIdForConfirmation)}
                onConfirm={handleCancelQaProcess}
                content={
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        {t('projectPage.revertQaProcessQuestion')}
                    </Typography>
                }
                isLoading={isUpdatingStatus}
            />
        </>
    )
}
