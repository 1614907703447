import React from 'react'
import Dropzone, { DropzoneProps, FileRejection } from 'react-dropzone'
import { Typography, styled } from '@mui/material'
import { Control, Controller, FieldValues, Path, UseFormSetValue, PathValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { convertByte } from 'utils/general'

type RenderFileRejectionErrorProps = {
    rejection: FileRejection
    maxSize?: number
}

const RenderFileRejectionError: React.FC<RenderFileRejectionErrorProps> = ({ rejection, maxSize }) => {
    const { t } = useTranslation()
    const reason = rejection.errors[0].code

    switch (reason) {
        case 'file-too-large':
            return (
                <Typography component="span" role="alert" color="error" variant="body1">
                    {t('dropZoneFileRejections.fileToLarge', { size: convertByte(maxSize) })}
                </Typography>
            )
    }
    return (
        <Typography component="span" role="alert">
            {t('genericErrorMessage')}
        </Typography>
    )
}

interface DropzoneFieldProps<T extends FieldValues> extends Omit<DropzoneProps, 'multiple'> {
    control: Control<T>
    name: Path<T>
    setValue: UseFormSetValue<T>
    acceptedFileDescription: string
}

export const ControlledImageUpload = <T extends FieldValues>({
    name,
    control,
    setValue,
    acceptedFileDescription,
    ...rest
}: DropzoneFieldProps<T>) => {
    const { t } = useTranslation()
    const previewRef = React.useRef<string | null>(null)

    React.useEffect(() => {
        return () => revokeFile()
    }, [])

    const revokeFile = () => {
        if (previewRef.current) {
            URL.revokeObjectURL(previewRef.current)
        }
    }

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value }, fieldState }) => (
                <Dropzone
                    noClick
                    onDrop={(acceptedFiles) => {
                        if (acceptedFiles.length === 0) return
                        setValue(name, acceptedFiles[0] as PathValue<T, Path<T>>, {
                            shouldValidate: true,
                        })
                        previewRef.current = URL.createObjectURL(acceptedFiles[0])
                    }}
                    onDropRejected={() => {
                        previewRef.current = null
                        setValue(name, null as PathValue<T, Path<T>>)
                    }}
                    multiple={false}
                    {...rest}
                >
                    {({ getRootProps, getInputProps, open, fileRejections }) => (
                        <section>
                            <UploadBlock {...getRootProps({ className: 'dropzone' })} onClick={open} role="button">
                                <input
                                    {...getInputProps({
                                        onChange,
                                        onBlur,
                                    })}
                                />

                                <p>{t('common.dropOrSelectFile')}</p>
                                <p>{acceptedFileDescription}</p>
                            </UploadBlock>
                            <aside>
                                {previewRef.current && (
                                    <PreviewFileContainer>
                                        <img
                                            src={previewRef.current}
                                            onLoad={revokeFile}
                                            alt={t('common.uploadedImageAlt')}
                                        />
                                    </PreviewFileContainer>
                                )}
                            </aside>
                            {fieldState.error && (
                                <div>
                                    <span role="alert">{fieldState.error.message}</span>
                                </div>
                            )}
                            {fileRejections.length > 0 ? (
                                <div>
                                    {fileRejections.map((err) => (
                                        <RenderFileRejectionError rejection={err} maxSize={rest.maxSize} />
                                    ))}
                                </div>
                            ) : null}
                        </section>
                    )}
                </Dropzone>
            )}
        />
    )
}

const UploadBlock = styled('div')(({ theme }) => ({
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: '#eee',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
}))

const PreviewFileContainer = styled('div')({
    width: '100%',
    height: '100%',
    maxHeight: 200,
    maxWidth: 200,

    '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
        minWidth: 40,
        minHeight: 40,
        objectFit: 'contain',
    },
})
