import React from 'react'
import { Typography, styled } from '@mui/material'
import { StyledOrderderList, StyledUnorderedList } from './styles'
import { JSONFormViewComp, ListViewSchemaElement, SchemaElementTypeMap } from 'redux/types/schemaView.type'
import { valueOrDefaultEmpty } from 'utils/general'

type ListDisplayProps = {
    values: Exclude<ListViewSchemaElement['value'], undefined>
}

export const OrderedListDisplay: React.FC<ListDisplayProps> = ({ values }) => (
    <StyledOrderderList>
        {values.map((value, idx) => (
            <StyledListItem key={`ordered-li-${value}-${idx}`}>{valueOrDefaultEmpty(value)}</StyledListItem>
        ))}
    </StyledOrderderList>
)

export const UnorderedListDisplay: React.FC<ListDisplayProps> = ({ values }) => (
    <StyledUnorderedList>
        {values.map((value, idx) => (
            <StyledListItem key={`unordered-li-${value}-${idx}`}>{valueOrDefaultEmpty(value)}</StyledListItem>
        ))}
    </StyledUnorderedList>
)

export const ListView: React.FC<JSONFormViewComp<SchemaElementTypeMap['list']>> = React.memo(({ node }) => {
    if (!node.isVisible || !node.isVisibleInWeb || node.value === undefined || node.value.length === 0) return null

    return (
        <div>
            {node.label && node.isLabelVisible && (
                <Typography sx={{ fontWeight: 600, mb: 1 }} variant="body1">
                    {node.label}
                </Typography>
            )}

            {node.isOrdered ? <OrderedListDisplay values={node.value} /> : <UnorderedListDisplay values={node.value} />}
        </div>
    )
})

const StyledListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.75, 0),
}))
