import { useTranslation } from 'react-i18next'
import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { CustomerOrOrganizationForm } from 'schemas/customers'
import { Button, Stack, styled } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React from 'react'
import { CustomerForm } from 'components/customersAdmin/CustomerForm'
import { useCreateOrganizationMutation } from 'services/organizations.service'
import { CreateOrganizationRequest } from 'redux/types/organization.type'

interface OrganizationCreateDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions'> {}

export const OrganizationCreateDialog: React.FC<OrganizationCreateDialogProps> = ({ open, handleClose }) => {
    const { t } = useTranslation()
    const [create, { isLoading: isCreating }] = useCreateOrganizationMutation()

    const onSubmit = async (values: CustomerOrOrganizationForm) => {
        const request: CreateOrganizationRequest = {
            ...values,
            type: values.isPrivatePerson ? 'PrivatePerson' : 'Company',
        }

        await create(request).unwrap()
        handleClose()
    }

    return (
        <ModalsWrapper
            open={open}
            onClick={(e) => e.stopPropagation()}
            handleClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
            body={
                <CustomerForm
                    formId="organization-form"
                    onSubmit={onSubmit}
                    title={t('organizationsPage.organizationDetails')}
                    isImportFromCustomersInputVisible={true}
                />
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={2}>
                    <Button variant="outlined" onClick={handleClose} color="secondary" disabled={isCreating}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="organization-form"
                        loading={isCreating}
                    >
                        {t('common.save')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))
