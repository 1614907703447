import { useGetSchemasQuery } from '../../../services/schemas.service'
import { useGetStandardsQuery } from 'services/standards.service'
import { StructureForm } from './StructureForm'
import { FormStructureEntity } from 'redux/types/formStructure.type'
import { DEFAULT_PREBUILT_OPTIONS_QUERY, useGetPrebuiltOptionsQuery } from 'services/prebuiltOptions.service'
import { SortDirection } from '@mui/material'
import { SchemasPaginationParams } from 'redux/types/schema.type'
import { PageLoader } from 'components/general/loaders/PageLoader'

type StructureProps = {
    initialData?: FormStructureEntity
}

export const FORM_STRUCTURE_PAGE_DEFAULT_SCHEMA_QUERY: SchemasPaginationParams = {
    page: 0,
    itemsPerPage: 999,
    sortBy: 'name',
    direction: 'asc' as SortDirection,
    withCommon: true,
}

export const FormStructure: React.FC<StructureProps> = ({ initialData }) => {
    const { data: schemas, isSuccess: isSchemasLoaded } = useGetSchemasQuery({
        ...FORM_STRUCTURE_PAGE_DEFAULT_SCHEMA_QUERY,
        assignedStructureId: initialData?.id,
    })
    const { isSuccess: isStandardsLoaded } = useGetStandardsQuery()
    const { isSuccess: isPrebuiltOptionsLoaded } = useGetPrebuiltOptionsQuery(DEFAULT_PREBUILT_OPTIONS_QUERY)

    return (
        <>
            {isSchemasLoaded && isStandardsLoaded && schemas && isPrebuiltOptionsLoaded ? (
                <div>
                    <StructureForm schemas={schemas.data} initialData={initialData as unknown as FormStructureEntity} />
                </div>
            ) : (
                <PageLoader />
            )}
        </>
    )
}
