import { createSelector } from '@reduxjs/toolkit'
import { IOption } from 'components/inputs/ControlledMuiSelect'
import { BuildingPartRootGroup, BuildingPartSubGroup } from 'redux/types/settings.type'
import { settingsApi } from 'services/settings.service'
import {
    BuildingPartGroupableOption,
    OptionWithCustomCheck,
    OptionWithRiskClass,
    OptionWithStaticStructureType,
} from 'types/util'
import i18next from 'i18next'

export const selectTenantSettings = settingsApi.endpoints.getTenantSettings.select()
export const selectPublicSettings = settingsApi.endpoints.getPublicSettings.select()

export const selectStructureTypeOptions = createSelector(
    selectTenantSettings,
    (state): IOption[] => state.data?.structureTypes || [],
)
export const selectRegulationOptions = createSelector(selectTenantSettings, (state): IOption[] =>
    (state.data?.regulations || []).map((regulation) => ({ label: regulation.name, value: regulation.id })),
)
export const selectProjectFileTagOptions = createSelector(
    selectTenantSettings,
    (state) => state.data?.projectFileTags || [],
)
export const selectHeritageTagOptions = createSelector(
    selectTenantSettings,
    (state): IOption[] => state.data?.heritageTags || [],
)
export const selectFloorRiskClassOptions = createSelector(
    selectTenantSettings,
    (state): IOption[] => state.data?.floorRiskClasses || [],
)
export const selectClearableFireClassTagOptions = createSelector(selectTenantSettings, (state): IOption[] =>
    state.data?.fireClassTags ? [{ label: i18next.t('common.clear'), value: '' }, ...state.data.fireClassTags] : [],
)
export const selectDisciplinesOptions = createSelector(
    selectTenantSettings,
    selectPublicSettings,
    (tenantState, publicState): IOption[] => {
        if (tenantState.isUninitialized) {
            return (publicState.data?.disciplines || []).map((discipline) => ({
                label: discipline.tag,
                value: discipline.tag,
                description: discipline.description,
            }))
        }
        return (tenantState.data?.disciplines || []).map((discipline) => ({
            label: discipline.tag,
            value: discipline.tag,
            description: discipline.description,
        }))
    },
)
export const selectBuildingParts = createSelector(
    selectTenantSettings,
    selectPublicSettings,
    (tenantState, publicState) => {
        if (tenantState.isUninitialized) {
            return publicState.data?.buildingParts || []
        }
        return tenantState.data?.buildingParts || []
    },
)

const flattenBuildingPartsGroup = (
    groups: (BuildingPartSubGroup | BuildingPartRootGroup)[],
    result: BuildingPartGroupableOption[] = [],
) => {
    for (const group of groups) {
        result.push({
            value: group.code.toString(),
            label: group.code.toString(),
            description: group.description,
            codeGroup: group.code.toString()[0],
        })
        if ('subGroups' in group && Array.isArray(group.subGroups) && group.subGroups.length > 0) {
            flattenBuildingPartsGroup(group.subGroups, result)
        }
    }
    return result
}

export const selectBuildingPartsOptionsFlat = createSelector(
    selectTenantSettings,
    selectPublicSettings,
    (tenantState, publicState): BuildingPartGroupableOption[] => {
        if (tenantState.isUninitialized) {
            return flattenBuildingPartsGroup(publicState.data?.buildingParts || [])
        }
        return flattenBuildingPartsGroup(tenantState.data?.buildingParts || [])
    },
)

export const selectBuildingPartsOptionsFlatWithAll = createSelector(
    selectTenantSettings,
    selectPublicSettings,
    (tenantState, publicState): BuildingPartGroupableOption[] => {
        if (tenantState.isUninitialized) {
            return flattenBuildingPartsGroup(publicState.data?.buildingParts || [], [
                { codeGroup: '0', description: '-', label: i18next.t('common.all'), value: 'all' },
            ])
        }
        return flattenBuildingPartsGroup(tenantState.data?.buildingParts || [], [
            { codeGroup: '0', description: '-', label: i18next.t('common.all'), value: 'all' },
        ])
    },
)
export const selectMunicipalitiesOptions = createSelector(selectTenantSettings, (state): IOption[] =>
    (state.data?.municipalities || []).map((municipality) => ({
        label: municipality.name,
        value: municipality.number,
    })),
)
export const selectOccupancyOptions = createSelector(selectTenantSettings, (state): OptionWithRiskClass[] =>
    (state.data?.occupancies || []).map((occ) => ({
        label: occ.name,
        value: occ.name,
        description: occ.description,
        riskClass: occ.riskClass,
    })),
)
export const selectMergedWithCustomOccupancyOptions = createSelector(
    [selectOccupancyOptions],
    (occupancyOptions) =>
        (customOccupations: { value: string }[]): OptionWithRiskClass[] => {
            return [
                ...customOccupations.map((customOcc) => ({
                    label: customOcc.value,
                    value: customOcc.value,
                    riskClass: '-',
                    isCustom: true,
                })),
                ...occupancyOptions,
            ]
        },
)
export const selectProjectClassOptions = createSelector(selectTenantSettings, (state): IOption[] => {
    const classes: IOption[] = [{ value: 'empty', label: i18next.t('common.empty') }]

    state.data?.projectClasses?.forEach((pClass) => classes.push(pClass))

    return classes
})
export const selectPrebuiltSchemaOptions = createSelector(
    selectTenantSettings,
    (state): OptionWithStaticStructureType[] =>
        (state.data?.builtInSchemas || []).map((structure) => ({
            label: structure.name,
            value: structure.id,
            staticType: structure.type,
        })),
)
export const selectSafetyInstallationOptions = createSelector(
    selectTenantSettings,
    (state): IOption[] => state.data?.safetyInstallations || [],
)
export const selectCustomRisksOptions = createSelector(
    selectTenantSettings,
    (state): IOption[] => state.data?.buildingSpecialRisks || [],
)
export const selectMergedWithCustomInstallationOptions = createSelector(
    [selectSafetyInstallationOptions],
    (installationOptions) =>
        (customInstallations: { value: string }[]): OptionWithCustomCheck[] => {
            return [
                ...customInstallations.map((customOcc) => ({
                    label: customOcc.value,
                    value: customOcc.value,
                    isCustom: true,
                })),
                ...installationOptions,
            ]
        },
)
export const selectMergedWithCustomSpecialRisksOptions = createSelector(
    [selectCustomRisksOptions],
    (installationOptions) =>
        (customSpecialRisks: { value: string }[]): OptionWithCustomCheck[] => {
            return [
                ...customSpecialRisks.map((customRisk) => ({
                    label: customRisk.value,
                    value: customRisk.value,
                    isCustom: true,
                })),
                ...installationOptions,
            ]
        },
)
