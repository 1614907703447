import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import { CreateOrEditUserDialog } from './CreateOrEditUserDialog'

type Props = {
    tenantId: string
}

export const AddTenantUser: React.FC<Props> = ({ tenantId }) => {
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <Button startIcon={<Add />} color="secondary" variant="contained" onClick={() => setIsOpen(true)}>
                {t('usersPage.addTenantUserButton')}
            </Button>
            <CreateOrEditUserDialog handleClose={() => setIsOpen(false)} open={isOpen} tenantId={tenantId} />
        </>
    )
}
