import { FormControlLabel, FormHelperText, Switch } from '@mui/material'
import { Path, FieldValues, Controller, Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type TControlledMuiSwitchProps<T extends FieldValues> = {
    name: Path<T>
    control: Control<T>
    label: string
    onChangeCb?: (value: boolean) => void
}

export const ControlledMuiSwitch = <T extends FieldValues>({
    name,
    control,
    label,
    onChangeCb,
}: TControlledMuiSwitchProps<T>) => {
    const { t } = useTranslation()

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <>
                    <FormControlLabel
                        control={
                            <Switch
                                {...field}
                                checked={field.value}
                                onChange={(e) => {
                                    field.onChange(e.target.checked)
                                    onChangeCb?.(e.target.checked)
                                }}
                            />
                        }
                        label={label}
                    />
                    {error?.message && (
                        <FormHelperText sx={(theme) => ({ color: theme.palette.warning.main })}>
                            {t(error.message)}
                        </FormHelperText>
                    )}
                </>
            )}
        />
    )
}
