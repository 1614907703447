import { createAjv } from '@jsonforms/core'
import { materialCells } from '@jsonforms/material-renderers'
import { JsonForms, JsonFormsInitStateProps, JsonFormsReactProps } from '@jsonforms/react'
import React from 'react'
import { renderers } from 'utils/jsonForm'

type RegisteredJsonFormProps = JsonFormsInitStateProps & JsonFormsReactProps

export const defaultAjv = createAjv({
    useDefaults: true,
})

export const RegisteredJsonForm: React.FC<Omit<RegisteredJsonFormProps, 'renderers' | 'cells'>> = (props) => {
    return <JsonForms {...props} renderers={renderers} cells={materialCells} ajv={defaultAjv} />
}
