import React from 'react'
import { TopBar } from 'components/general/top-bar/top-bar'
import { Paper } from '@mui/material'
import { BuildingFormTabs } from 'modules/buildings/shared/BuildingFormTabs'
import { BuildingTabs, EBuildingTabs } from 'modules/buildings/shared/BuildingTabs'
import { PageContainer } from 'components/general/containers/PageContainer'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { TabContainer } from 'components/buildings/TabContainer'

export const AddBuildingPage: React.FC = () => {
    const { t } = useTranslation()
    const [activeTab, setActiveTab] = React.useState<EBuildingTabs>(EBuildingTabs.BuildingInfo)

    return (
        <>
            <Helmet>
                <title>{t('createEditBuildingPage.addBuilding')}</title>
            </Helmet>
            <TopBar />
            <PageContainer>
                <Paper elevation={0} sx={{ mb: 4 }}>
                    <BuildingTabs activeTab={activeTab} onActiveTabChange={setActiveTab} />
                    <TabContainer>
                        <BuildingFormTabs isEdit={false} activeTab={activeTab} />
                    </TabContainer>
                </Paper>
            </PageContainer>
        </>
    )
}
