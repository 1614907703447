import { Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CustomTable } from '../../../components/general/table/custom-table'
import { useTablePagination } from '../../../components/general/table/pagination'
import { getTenantUsersTableStructure } from './table-structure'
import { useGetTenantUsersQuery } from 'services/tenantUsers.service'

type Props = {
    tenantId: string
}

export const TenantUsersTable: React.FC<Props> = ({ tenantId }) => {
    const { t } = useTranslation()

    const pagination = useTablePagination()
    const items = useGetTenantUsersQuery({
        page: pagination.page,
        itemsPerPage: pagination.itemsPerPage,
        sortBy: pagination.sortBy,
        direction: pagination.direction,
        tenantId,
    })

    const tableStructure = getTenantUsersTableStructure(t)

    return (
        <Paper elevation={0} square={true}>
            {items.isSuccess ? (
                <CustomTable
                    tableStructure={tableStructure}
                    rows={items.data?.data}
                    total={items.data?.total ?? 0}
                    pagination={pagination}
                />
            ) : null}
        </Paper>
    )
}
