import React from 'react'
import { BuildingMetadata, CreateOrUpdateBuildingForm } from 'redux/types/building.type'
import ControlledMuiRadio from 'components/inputs/ControlledMuiRadio'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SectionBlock } from 'components/buildings/SectionBlock'
import { FormItem } from 'components/buildings/FormItem'
import { GoogleAddressAutocomplete } from 'components/inputs/custom/GoogleAddressAutocomplete'
import { BuildingMap } from '../shared/BuildingMap'
import { Box } from '@mui/material'

const GOOGLE_COUNTRY_DICTIONARY: Record<string, string> = {
    SE: 'Sweden',
    DK: 'Denmark',
    NO: 'Norway',
}

export const GeneralSectionForm: React.FC = () => {
    const { t } = useTranslation()
    const { control, setValue } = useFormContext<CreateOrUpdateBuildingForm>()
    const metadata = useWatch({ control, name: 'buildingMetadata' })
    const address = useWatch({ control, name: 'address.addressText' })

    // prevents from typing more than 4 chars
    const handleOnInput = (e: React.FormEvent<HTMLDivElement>, maxChars: number = 4) => {
        if (!(e.target instanceof HTMLInputElement)) return
        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, maxChars)
    }

    const handleOnMunicipalityInput = (e: React.FormEvent<HTMLDivElement>) => {
        if (!(e.target instanceof HTMLInputElement)) return
        let inputValue = e.target.value
        inputValue = inputValue.replace(/\D/g, '')
        inputValue = inputValue.slice(0, 4)

        e.target.value = inputValue
    }

    const handleGoogleAddressDetails = (details: any) => {
        details.address_components.forEach((component: any) => {
            if (component.types.includes('country'))
                setValue('address.country', GOOGLE_COUNTRY_DICTIONARY[component.short_name] || component.long_name)
            else if (component.types.includes('locality') || component.types.includes('postal_town'))
                setValue('address.city', component.long_name)
            else if (component.types.includes('postal_code')) setValue('address.postalCode', component.long_name)
        })

        const buildingGoogleMetadata: BuildingMetadata = {
            mapsUrl: details.url,
            placeId: details.place_id,
            location: {
                latitude: details.geometry.location.lat(),
                longitude: details.geometry.location.lng(),
            },
        }

        setValue('buildingMetadata', buildingGoogleMetadata)
    }

    return (
        <SectionBlock>
            <BuildingMap address={address} metadata={metadata} />
            <FormItem label={t('createEditBuildingPage.buildingIsPublic')}>
                <ControlledMuiRadio
                    control={control}
                    name="isPublic"
                    options={[
                        { label: t('common.yes'), value: true },
                        { label: t('common.no'), value: false },
                    ]}
                    row
                />
            </FormItem>

            <FormItem label={t('common.buildingName')}>
                <ControlledMuiTextField
                    control={control}
                    name="name"
                    sx={{ maxWidth: 390 }}
                    fullWidth
                    autoFocus
                    placeholder={t('common.name')}
                />
            </FormItem>

            <FormItem label={t('common.municipality')}>
                <ControlledMuiTextField
                    control={control}
                    name="municipality"
                    sx={{ maxWidth: 390 }}
                    fullWidth
                    autoFocus
                    placeholder={t('common.municipality')}
                    type="text"
                    onInput={handleOnMunicipalityInput}
                />
            </FormItem>

            <FormItem label={t('common.landNumber')}>
                <ControlledMuiTextField
                    control={control}
                    name="landNumber"
                    sx={{ maxWidth: 390 }}
                    fullWidth
                    autoFocus
                    placeholder={t('common.landNumber')}
                    type="number"
                    onInput={(e) => handleOnInput(e, 3)}
                />
            </FormItem>

            <FormItem label={t('common.titleNumber')}>
                <ControlledMuiTextField
                    control={control}
                    name="titleNumber"
                    sx={{ maxWidth: 390 }}
                    fullWidth
                    autoFocus
                    placeholder={t('common.titleNumber')}
                    type="number"
                    onInput={handleOnInput}
                />
            </FormItem>

            <FormItem label={t('common.buildingNumber')}>
                <ControlledMuiTextField
                    control={control}
                    name="buildingNumber"
                    sx={{ maxWidth: 390 }}
                    fullWidth
                    placeholder={t('common.buildingNumber')}
                />
            </FormItem>

            <FormItem label={t('common.address')}>
                <GoogleAddressAutocomplete
                    name="address.addressText"
                    control={control}
                    sx={{ maxWidth: 390 }}
                    placeholder={t('common.address')}
                    fullWidth
                    onDetailsChange={handleGoogleAddressDetails}
                />
            </FormItem>

            <FormItem label={t('common.postalCode')}>
                <Box display="flex" gap={2.5}>
                    <ControlledMuiTextField
                        control={control}
                        name="address.postalCode"
                        sx={{ maxWidth: 120, width: '100%' }}
                        placeholder={t('common.postalCode')}
                    />
                    <ControlledMuiTextField
                        control={control}
                        name="address.city"
                        placeholder={t('common.city')}
                        sx={{ maxWidth: 150, width: '100%' }}
                    />
                </Box>
            </FormItem>
        </SectionBlock>
    )
}
