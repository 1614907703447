import { createTheme, Theme } from '@mui/material'
import { deepmerge } from '@mui/utils'
import AppThemedComponents from './themedComponents'

const theme = createTheme({
    typography: {
        fontFamily: ['Open Sans', 'Roboto', 'sans-serif'].join(','),
    },
    palette: {
        primary: {
            light: '#414141',
            main: '#1E0E31',
            dark: '#171924',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#E77800',
            contrastText: '#000000',
        },
    },
    general: {
        chip: '#1E0E31',
        gray: '#F2F2F2',
        tableHeaderBg: '#656565',
        borderColor: '#CBCBCB',
    },
})

export const appTheme = createTheme(deepmerge(theme, AppThemedComponents(theme)))
export const MOBILE_MEDIA_QUERY = '(max-width:900px)'
export const getMobileMediaQuery = (theme?: Theme) => {
    return MOBILE_MEDIA_QUERY
}
