import { TenantUsersPage } from 'pages/users/TenantUsers.page'

const usersRoutes = [
    {
        path: '',
        element: <TenantUsersPage />,
    },
]

export default usersRoutes
