import { appApi } from '../redux/api'
import { PaginatedResults } from '../components/general/models/paginated-results'
import { PaginationParams } from '../components/general/models/pagination-params'

import { TenantFilters } from '../redux/slices/tenant.slice'
import { AcceptInviteRequest, GetAttributesRequest, Tenant, TenantAttributes } from '../redux/types/tenant.type'

const tenantsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getTenants: builder.query<PaginatedResults<Tenant>, PaginationParams<Tenant, TenantFilters>>({
            query: (params) => ({
                url: '/v1/tenants',
                method: 'GET',
                params: params,
            }),
            providesTags: ['Tenants'],
        }),
        getTenant: builder.query<Tenant, string>({
            query: (params) => ({
                url: `/v1/tenants/${params}`,
                method: 'GET',
            }),
            providesTags: ['Tenants'],
        }),
        getUserTenant: builder.query<Tenant, void>({
            query: (params) => ({
                url: `/v1/tenants/me`,
                method: 'GET',
            }),
            providesTags: ['Tenants'],
        }),
        createTenant: builder.mutation<Tenant, FormData>({
            query: (params) => ({
                url: '/v1/tenants',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: (result) => (result ? ['Tenants'] : []),
        }),
        acceptInvite: builder.mutation<void, AcceptInviteRequest>({
            query: (params) => ({
                url: '/v1/tenants/accept-invite',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['Tenants'],
        }),
        deleteTenant: builder.mutation<void, string[]>({
            query: (params) => ({
                url: `/v1/tenants`,
                method: 'DELETE',
                body: { ids: params },
            }),
            invalidatesTags: ['Tenants'],
        }),
        getTenantAttributes: builder.query<TenantAttributes, GetAttributesRequest>({
            query: (params) => ({
                url: 'v1/tenants/attributes',
                method: 'GET',
                params,
            }),
            providesTags: ['Tenants'],
        }),
    }),
})

export const {
    useAcceptInviteMutation,
    useGetTenantsQuery,
    useGetUserTenantQuery,
    useGetTenantQuery,
    useLazyGetTenantQuery,
    useLazyGetTenantsQuery,
    useCreateTenantMutation,
    useDeleteTenantMutation,
    useGetTenantAttributesQuery,
} = tenantsApi
