import { Stack } from '@mui/material'
import * as O from 'optics-ts'
import React from 'react'
import { RulesEditor } from '../rules/rules-editor'
import { Render } from '../schema-builder'
import { useRule } from '../hooks/schema-hooks'
import { AddFieldDialog } from './add-field-dialog'
import { BuilderFieldProps } from './builder-field-props'
import { Draggable, Droppable } from './droppable'
import { FieldDialog } from './field-dialog'
import { FieldItem } from './field-item'
import { TabPanel } from './general/field-tab-panel'
import { FieldTabs } from './general/field-tabs'
import { HorizontalLayoutSchemaElement, VerticalLayoutSchemaElement } from 'redux/types/schema.type'

export const BuilderVerticalLayout: React.FC<BuilderFieldProps<VerticalLayoutSchemaElement>> = ({
    node,
    state,
    setState,
    lens,
    path,
}) => {
    const rule = useRule(lens, state, setState)
    const nodes = lens.prop('nodes')
    const [tabIndex, setTabIndex] = React.useState(0)

    const remove = () => setState(O.remove(lens)(state))
    return (
        <Draggable id={`${path}`}>
            <Stack sx={{ border: '1px dashed #666' }} width="100%">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FieldItem node={node} nameValue={''} hasRules={rule.hasRules} />
                    <FieldDialog node={node} remove={remove} onSave={rule.onSaveRules}>
                        <FieldTabs value={tabIndex} handleChange={setTabIndex} isLayoutItem />
                        <TabPanel value={tabIndex} index={0}>
                            <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                        </TabPanel>
                    </FieldDialog>
                </div>
                <Droppable id={`${path}.nodes.${0}`} />
                {node.nodes?.map((x: any, i: number) => {
                    const nodeLens = nodes.at(i)
                    return (
                        <React.Fragment key={i}>
                            <Render state={state} setState={setState} lens={nodeLens} path={`${path}.nodes.${i}`} />
                            <Droppable id={`${path}.nodes.${i + 1}`} />
                        </React.Fragment>
                    )
                })}
                <AddFieldDialog nodesLens={nodes} setState={setState} parentField="VerticalLayout" />
            </Stack>
        </Draggable>
    )
}

export const BuilderHorizontalLayout: React.FC<BuilderFieldProps<HorizontalLayoutSchemaElement>> = ({
    node,
    state,
    setState,
    lens,
    path,
}) => {
    const nodes = lens.prop('nodes')
    const [tabIndex, setTabIndex] = React.useState(0)
    const remove = () => setState(O.remove(lens)(state))
    const rule = useRule(lens, state, setState)

    return (
        <Draggable id={`${path}`}>
            <Stack sx={{ border: '1px dashed #666', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FieldItem node={node} nameValue={''} hasRules={rule.hasRules} />
                    <FieldDialog node={node} remove={remove} onSave={rule.onSaveRules}>
                        <FieldTabs value={tabIndex} handleChange={setTabIndex} isLayoutItem />
                        <TabPanel value={tabIndex} index={0}>
                            <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                        </TabPanel>
                    </FieldDialog>
                </div>
                <Droppable id={`${path}.nodes.${0}`} />
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {node.nodes?.map((x: any, i: number) => {
                        const nodeLens = nodes.at(i)
                        return (
                            <React.Fragment key={i}>
                                <div>
                                    <Render
                                        state={state}
                                        setState={setState}
                                        lens={nodeLens}
                                        path={`${path}.nodes.${i}`}
                                    />
                                    <Droppable id={`${path}.nodes.${i + 1}`} />
                                </div>
                            </React.Fragment>
                        )
                    })}
                </div>
                <AddFieldDialog nodesLens={nodes} setState={setState} parentField="HorizontalLayout" />
            </Stack>
        </Draggable>
    )
}
