import React from 'react'
import { useProjectView } from '../hooks/useProjectView'
import { ProjectDetailsDisplay } from 'components/projects/DetailsDisplay'
import { Accordion, AccordionDetails, AccordionSummary, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ExpandMore } from '@mui/icons-material'

export const DetailsArchived: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { t } = useTranslation()
    const { project, isCustomerView } = useProjectView()

    const [expanded, setExpanded] = React.useState<boolean>(!isMobile)

    if (!project) return null

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded((prev) => !prev)}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.25rem', lineHeight: '1.5rem' }}>
                    {t('common.project')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ProjectDetailsDisplay isCustomerView={Boolean(isCustomerView)} project={project} />
            </AccordionDetails>
        </Accordion>
    )
}
