import { BuildingPage } from 'pages/buildings/building.page'
import { CustomerPage } from 'pages/customerAdmin/customer.page'
import { CustomersPage } from 'pages/customerAdmin/customers.page'
import { ROUTES } from 'constants/routes'
import { generateNestedRouteUrl } from 'utils/navigation-utils'

const customerAdminRoutes = [
    {
        path: '',
        element: <CustomersPage />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.customer, ROUTES.customers),
        element: <CustomerPage />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.customerBuilding, ROUTES.customers),
        element: <BuildingPage />,
    },
]

export default customerAdminRoutes
