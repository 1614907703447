import { FormSectionWithProgress } from 'redux/types/formStructure.type'
import { NormalizedData } from 'types/util'

// for now in only supports recursive data that have children key
// if need occurs rewrite to mure generic
export class NormalizationUtils {
    static NormalizeNestedData<T extends { sectionId: string; children: T[] }>(data?: T[], accessor?: keyof T) {
        if (!data) return null

        const entities: NormalizedData<T> = {}
        const result: string[] = []
        const property = accessor || 'sectionId'

        function normalizeItem(item: T, parentId?: string) {
            const normalizationKey = item[property] as string
            result.push(normalizationKey)

            if (!entities[normalizationKey]) {
                const { children, ...rest } = item

                entities[normalizationKey] = { ...rest, [property]: normalizationKey, parentId }
            }

            if (item.children && item.children.length !== 0) {
                entities[normalizationKey].children = item.children.map((child) => child[property] as string)
                item.children.forEach((child) => normalizeItem(child, normalizationKey))
            }
        }

        data.forEach((item) => {
            normalizeItem(item)
        })

        return { entities, result }
    }

    static GetSectionChildren<T extends { sectionId: string; children: T[] }>(
        parentSchemaId: string,
        data: NormalizedData<T>,
    ): string[] {
        const result: string[] = []

        function traverseChildren(currentId: string) {
            const currentStructure = data[currentId]

            if (currentStructure.children) {
                currentStructure.children.forEach((childId) => {
                    result.push(childId)
                    traverseChildren(childId)
                })
            }
        }
        const normalizationId = Object.keys(data).find((key) => data[key].schemaId === parentSchemaId)

        traverseChildren(normalizationId || parentSchemaId)

        return result
    }

    static GetChildParents<T extends { sectionId: string; children: T[] }>(
        nodeId: string,
        data: NormalizedData<T>,
    ): string[] {
        const result: string[] = []

        function findParentId(data: NormalizedData<T>, target: string) {
            Object.keys(data).forEach((key) => {
                if (data[key] && data[key].children?.includes(target)) {
                    result.push(key)
                    findParentId(data, key)
                }
            })
        }

        findParentId(data, nodeId)

        return result
    }

    static filterDisabledSchemas = (data?: NormalizedData<FormSectionWithProgress>, disabledSchemas?: string[]) => {
        if (!data || !disabledSchemas) return null
        if (disabledSchemas.length === 0) return data

        const entities: NormalizedData<FormSectionWithProgress> = {}

        Object.keys(data).forEach((normalizationKey) => {
            if (data[normalizationKey].progress !== 'disabled') {
                const nodeCopy = { ...data[normalizationKey] }

                if (nodeCopy.children) {
                    const newChildren = nodeCopy.children.filter((childId) => !disabledSchemas.includes(childId))
                    nodeCopy.children = newChildren
                }
                entities[normalizationKey] = nodeCopy
            }
        })

        return entities
    }

    static getOnlyCompleteSchemas = (
        staticSchemas: string[],
        data?: NormalizedData<FormSectionWithProgress>,
        completeSchemas?: string[],
        unassignedSchemas?: string[],
    ) => {
        if (!data || !completeSchemas || !unassignedSchemas) return null
        if (completeSchemas.length === 0 && unassignedSchemas.length === 0) return null

        const entities: NormalizedData<FormSectionWithProgress> = {}
        Object.keys(data).forEach((normalizationKey) => {
            if (
                (data[normalizationKey].progress === 'complete' && data[normalizationKey].staticType === null) ||
                data[normalizationKey].progress === 'no-form'
            ) {
                const nodeCopy = { ...data[normalizationKey] }
                // for now leaving like this before we know how to display no-form pages in view mode
                if (nodeCopy.children) {
                    const newChildren = nodeCopy.children.filter((childId) => {
                        if (staticSchemas.includes(childId)) {
                            return false
                        }
                        return completeSchemas.includes(childId) || unassignedSchemas.includes(childId)
                    })
                    nodeCopy.children = newChildren
                }

                entities[normalizationKey] = nodeCopy
            }
        })
        return entities
    }

    // finds the next structure that has formId
    static findFirstViableSection = (data: NormalizedData<FormSectionWithProgress>) => {
        const keys = Object.keys(data)
        if (keys.length === 0) return null

        for (let i = 0; i < keys.length; i++) {
            const section = data[keys[i]]

            if (section.schemaId) {
                return section
            }
        }
    }
}
