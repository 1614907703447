import React from 'react'
import { List, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ContentCopy, Delete } from '@mui/icons-material'

type SchemasActionsMenuProps = {
    anchorEl?: null | HTMLElement
    id?: string
    isDeletable: boolean
    onClose: () => void
    onDeleteSchemaAction: (id: string) => void
    onCopySchemaAction: (id: string) => void
}

export const SchemasActionsMenu: React.FC<SchemasActionsMenuProps> = ({
    anchorEl,
    onClose,
    id,
    onDeleteSchemaAction,
    onCopySchemaAction,
    isDeletable,
}) => {
    const { t } = useTranslation()

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
            }}
            onClose={onClose}
            PaperProps={{ sx: { minWidth: 200 } }}
        >
            <List sx={{ width: '100%' }}>
                <ListItemButton onClick={() => onCopySchemaAction(id!)}>
                    <ListItemIcon>
                        <ContentCopy />
                    </ListItemIcon>
                    <ListItemText primary={t('common.copy')} />
                </ListItemButton>

                <ListItemButton
                    onClick={() => onDeleteSchemaAction(id!)}
                    sx={{ display: isDeletable ? 'flex' : 'none' }}
                >
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText primary={t('common.delete')} />
                </ListItemButton>
            </List>
        </Popover>
    )
}
