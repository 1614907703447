import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, FormControlLabel, Switch, Typography } from '@mui/material'
import { PrebuiltFormNavigationControlls } from './PrebuiltFormNavigationControlls'
import { StructureStaticType } from 'redux/types/formStructure.type'

type PrebuiltFormStaticProps = {
    title?: string
    handleNavigateToNextStructure: (direction: 'next' | 'prev') => void
    prevNode: string | null
    nextNode: string | null
    handleDisableSchema?: (event: React.ChangeEvent<HTMLInputElement>) => void
    isFormDisabled?: boolean
    staticType: StructureStaticType
}

export const PrebuiltFormStatic: React.FC<PrebuiltFormStaticProps> = ({
    handleNavigateToNextStructure,
    title,
    prevNode,
    nextNode,
    isFormDisabled,
    handleDisableSchema,
    staticType,
}) => {
    const { t } = useTranslation()

    return (
        <Card sx={{ px: 4, height: '100%', py: 2, borderRadius: '5px 5px 0 0' }} elevation={1}>
            <PrebuiltFormNavigationControlls
                title={title}
                prevNode={prevNode}
                nextNode={nextNode}
                handleNavigateToNextStructure={handleNavigateToNextStructure}
            />
            <FormControlLabel
                control={<Switch onChange={handleDisableSchema} color="success" checked={isFormDisabled} />}
                label={t('projectPage.disableSchema')}
                sx={(theme) => ({ paddingLeft: theme.spacing(2) })}
            />
            <Box sx={{ mt: 4 }}>
                <Typography variant="body1">
                    {t('createProjectPage.assignedStaticSchemaIs')}
                    <Typography variant="body1" sx={{ fontWeight: 700, ml: 1 }} component="span">
                        {t(`staticSchema.${staticType}`)}
                    </Typography>
                </Typography>
            </Box>
        </Card>
    )
}
