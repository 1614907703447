import React from 'react'
import { Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { ElementValueBlock } from './ElementValueBlock'
import { JSONFormViewComp, SchemaElementTypeMap } from 'redux/types/schemaView.type'
import { StyledTableCell, StyledTableHeaderCell } from './styles'

export const ArrayView: React.FC<JSONFormViewComp<SchemaElementTypeMap['array']>> = React.memo(({ node }) => {
    if (!node.isVisible || !node.isVisibleInWeb || node.value === undefined || node.value.length === 0) return null

    return (
        <div>
            {node.label && node.isLabelVisible && (
                <Typography sx={{ fontWeight: 600 }} variant="caption">
                    {node.label}
                </Typography>
            )}

            <TableContainer>
                <Table
                    sx={{
                        width: '100%',
                        overflowX: 'auto',
                        tableLayout: 'fixed',
                    }}
                >
                    <TableHead>
                        <TableRow>
                            {node.nodes.map((col) => {
                                if (!('name' in col)) return null
                                const colSize = node.options.columnDefinitions[col.name].size

                                return (
                                    <StyledTableHeaderCell
                                        sx={{ width: colSize === 'auto' ? 'initial' : `${colSize}%` }}
                                        key={`header-${col.name}`}
                                    >
                                        {col.label}
                                    </StyledTableHeaderCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {node.value.map((nodeValue, idx) => (
                            <TableRow key={idx}>
                                {node.nodes.map((childNode) =>
                                    'name' in childNode ? (
                                        <StyledTableCell key={`body-${idx}-${childNode.name}`}>
                                            <ElementValueBlock
                                                value={nodeValue[childNode.name]}
                                                type={childNode.type}
                                                isOrderedList={childNode.type === 'list' && childNode.isOrdered}
                                            />
                                        </StyledTableCell>
                                    ) : null,
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
})
