/** @jsxImportSource @emotion/react */
import { ThemeProvider } from '@mui/material'
import { appTheme } from './material-ui/material-ui.config'
import { css } from '@emotion/react'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { ToastContainer } from 'react-toastify'
import RouteManager from './RouteManager'

import 'react-toastify/dist/ReactToastify.css'

const styles = {
    container: css`
        display: flex;
    `,
}
export const App = () => {
    return (
        <ThemeProvider theme={appTheme}>
            <BrowserRouter>
                <QueryParamProvider adapter={ReactRouter6Adapter} options={{ includeAllParams: true }}>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        draggable={false}
                        closeOnClick
                        pauseOnHover
                    />
                    <div css={styles.container}>
                        <RouteManager />
                    </div>
                </QueryParamProvider>
            </BrowserRouter>
        </ThemeProvider>
    )
}
