import React from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { Add } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import { CreateBuildingFloorEntity, CreateOrUpdateBuildingForm } from 'redux/types/building.type'
import { useTranslation } from 'react-i18next'
import { FloorForm } from 'modules/buildings/create/FloorForm'
import { FloorColumnText, FloorContainer } from '../shared/styles'
import { useAppSelector } from 'redux/hooks'
import { selectFloorRiskClassOptions, selectMergedWithCustomOccupancyOptions } from 'redux/selectors/settings.selectors'

export const DEFAULT_FLOOR = {
    number: 0,
    name: '',
    area: '',
    terrain: true,
    riskClasses: [],
    counting: true,
    isHalfFloor: false,
    occupations: [],
}

export const FloorFormTable: React.FC = React.memo(() => {
    const { t } = useTranslation()
    const { control, getValues, setValue } = useFormContext<CreateOrUpdateBuildingForm>()
    const {
        fields: floors,
        append,
        prepend,
        remove,
        replace,
    } = useFieldArray({
        control,
        name: 'floors',
    })

    const customOccupationTags = useWatch({ control, name: 'customOccupationTags' })
    const occupancyOptions = useAppSelector(selectMergedWithCustomOccupancyOptions)(customOccupationTags || [])
    const allRiskClassOptions = useAppSelector(selectFloorRiskClassOptions)

    const handleAddFloor = () => {
        const floorsState = getValues('floors')
        const lastTopFloor = floorsState.at(-1)?.number
        append({ ...DEFAULT_FLOOR, number: lastTopFloor !== undefined ? Math.floor(lastTopFloor) + 1 : 0 })
    }

    const handleAddBasement = () => {
        const floorsState = getValues('floors')
        const lastBottomFloor = floorsState.at(0)?.number
        prepend({ ...DEFAULT_FLOOR, number: lastBottomFloor !== undefined ? Math.ceil(lastBottomFloor) - 1 : -1 })
    }

    const handleRemoveFloor = (floorIdx: number) => {
        const floorsState = getValues('floors')
        const isRemovedFloorHalfFloor = floorsState[floorIdx].isHalfFloor

        if (isRemovedFloorHalfFloor) {
            remove(floorIdx)
            return
        }

        const isRemovedFloorBasement = floorsState[floorIdx].number < 0
        const shiftedFloors = floorsState.map((f) => ({ ...f }))

        if (isRemovedFloorBasement) {
            for (let i = floorIdx - 1; i >= 0; i--) {
                shiftedFloors[i].number += 1
            }
        } else {
            for (let i = floorIdx + 1; i < shiftedFloors.length; i++) {
                shiftedFloors[i].number -= 1
            }
        }

        replace(shiftedFloors)
        remove(floorIdx)
    }

    const shiftFloorsByHalf = ({
        floors,
        changedFloorIdx,
    }: {
        floors: CreateBuildingFloorEntity[]
        changedFloorIdx: number
    }) => {
        const isBasementFloor = floors[changedFloorIdx].number < 0

        if (isBasementFloor) {
            floors[changedFloorIdx].number += 0.5
            setValue(`floors.${changedFloorIdx}`, floors[changedFloorIdx])

            for (let i = changedFloorIdx - 1; i >= 0; i--) {
                floors[i].number += 1
                setValue(`floors.${i}`, floors[i])
            }

            return
        }

        floors[changedFloorIdx].number -= 0.5
        setValue(`floors.${changedFloorIdx}`, floors[changedFloorIdx])

        for (let i = changedFloorIdx + 1; i < floors.length; i++) {
            floors[i].number -= 1
            setValue(`floors.${i}`, floors[i])
        }
    }

    const unshiftFloorsFromHalf = ({
        floors,
        changedFloorIdx,
    }: {
        floors: CreateBuildingFloorEntity[]
        changedFloorIdx: number
    }) => {
        const isBasementFloor = floors[changedFloorIdx].number < 0

        if (isBasementFloor) {
            floors[changedFloorIdx].number = Math.floor(floors[changedFloorIdx].number)
            setValue(`floors.${changedFloorIdx}`, floors[changedFloorIdx])

            for (let i = changedFloorIdx - 1; i >= 0; i--) {
                floors[i].number -= 1
                setValue(`floors.${i}`, floors[i])
            }

            return
        }

        floors[changedFloorIdx].number = Math.ceil(floors[changedFloorIdx].number)
        setValue(`floors.${changedFloorIdx}`, floors[changedFloorIdx])

        for (let i = changedFloorIdx + 1; i < floors.length; i++) {
            floors[i].number += 1
            setValue(`floors.${i}`, floors[i])
        }
    }

    const handleOnHalfFloorChange = (isHalfFloor: boolean, floorIdx: number) => {
        const floorsState = getValues('floors')
        const floorCopy = floorsState.map((f) => ({ ...f }))

        if (isHalfFloor) {
            shiftFloorsByHalf({ floors: floorCopy, changedFloorIdx: floorIdx })
            return
        }

        unshiftFloorsFromHalf({ floors: floorCopy, changedFloorIdx: floorIdx })
    }

    return (
        <>
            <FloorContainer>
                <span />
                {Object.keys(DEFAULT_FLOOR).map((key) => (
                    <FloorColumnText key={key} variant="body1" className="floor-header">
                        {t(`createEditBuildingPage.floorTableHeaders.${key}`)}
                    </FloorColumnText>
                ))}
                {floors.map((floor, idx) => (
                    <FloorForm
                        key={floor.id}
                        idx={idx}
                        onRemove={handleRemoveFloor}
                        onHalfFloorChange={handleOnHalfFloorChange}
                        occupancyOptions={occupancyOptions}
                        allRiskClassOptions={allRiskClassOptions}
                    />
                ))}
            </FloorContainer>
            <Grid item xs={12} display="flex" gap={2} mt={2}>
                <Button type="button" onClick={handleAddFloor} variant="outlined" startIcon={<Add />}>
                    {t('createEditBuildingPage.addFloor')}
                </Button>

                <Button type="button" onClick={handleAddBasement} variant="outlined" startIcon={<Add />}>
                    {t('createEditBuildingPage.addBasement')}
                </Button>
            </Grid>
        </>
    )
})
