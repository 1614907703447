import { Alert, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DynamicTableColSizeDefinition } from 'types/util'
import { getColSizes } from 'utils/schema-utils'

type DynamicTableColumnSizeTabProps = {
    columnSizeDefinitions: Record<number, DynamicTableColSizeDefinition>
    isColSizeValid: boolean
    totalColumnsSelected: number
    setColumnSizeDefinitions: (newValue: Record<number, DynamicTableColSizeDefinition>) => void
}

export const DynamicTableColumnSizeTab: React.FC<DynamicTableColumnSizeTabProps> = ({
    columnSizeDefinitions,
    isColSizeValid,
    totalColumnsSelected,
    setColumnSizeDefinitions,
}) => {
    const { t } = useTranslation()

    const columns = new Array(totalColumnsSelected).fill(null)
    const sizeOptions = getColSizes()

    return (
        <>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                {columns.map((_, idx) => {
                    const name = `sizeForCol-${idx}`
                    return (
                        <React.Fragment key={name}>
                            <Grid item xs={1}>
                                <Typography variant="body1" align="right">
                                    {`${idx + 1}.`}
                                </Typography>
                            </Grid>
                            <Grid item xs={11}>
                                <TextField
                                    value={columnSizeDefinitions[idx]?.columnName || ''}
                                    id="label-field"
                                    label={t('schemaPage.columnLabel')}
                                    onChange={(e) =>
                                        setColumnSizeDefinitions({
                                            ...columnSizeDefinitions,
                                            [idx]: {
                                                isDirty: true,
                                                columnName: e.target.value,
                                                size: columnSizeDefinitions[idx].size,
                                                unit: '%',
                                            },
                                        })
                                    }
                                />
                                <FormControl fullWidth sx={{ maxWidth: 220, ml: 2 }}>
                                    <InputLabel>{t('common.size')}</InputLabel>
                                    <Select
                                        name={name}
                                        label={t('common.size')}
                                        value={columnSizeDefinitions[idx]?.size || ''}
                                        onChange={(e) =>
                                            setColumnSizeDefinitions({
                                                ...columnSizeDefinitions,
                                                [idx]: {
                                                    isDirty: true,
                                                    columnName: columnSizeDefinitions[idx].columnName,
                                                    size: e.target.value,
                                                    unit: '%',
                                                },
                                            })
                                        }
                                    >
                                        {sizeOptions.map((size) => (
                                            <MenuItem key={size} value={size}>
                                                {`${size} ${size === 'auto' ? '' : '%'}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </React.Fragment>
                    )
                })}
            </Grid>
            {!isColSizeValid && (
                <Alert severity="error" sx={{ my: 2 }}>
                    {t('schemaPage.incorrectColSizeSumTitle')}
                </Alert>
            )}
        </>
    )
}
