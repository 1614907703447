import { FormControlLabel, Checkbox, FormHelperText, CheckboxProps } from '@mui/material'
import { Path, FieldValues, Controller, Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export type TCheckboxInputProps<T extends FieldValues> = {
    name: Path<T>
    control: Control<T>
    label?: string
    onChangeCb?: (value: boolean) => void
} & Omit<CheckboxProps, 'name' | 'onChange'>

export const ControlledCheckbox = <T extends FieldValues>({
    name,
    control,
    label,
    onChangeCb,
    ...rest
}: TCheckboxInputProps<T>) => {
    const { t } = useTranslation()
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: props, fieldState: { error } }) => (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...rest}
                                {...props}
                                checked={props.value || false}
                                onChange={(e) => {
                                    props.onChange(e.target.checked)
                                    onChangeCb?.(e.target.checked)
                                }}
                            />
                        }
                        label={label}
                    />
                    {error?.message && (
                        <FormHelperText sx={(theme) => ({ color: theme.palette.warning.main })}>
                            {t(error.message)}
                        </FormHelperText>
                    )}
                </>
            )}
        />
    )
}
