import { Grid, Typography, styled } from '@mui/material'

interface IFormItemProps {
    title: string
    children: React.ReactNode
}

export const FormItem: React.FC<IFormItemProps> = ({ title, children }) => {
    return (
        <>
            <Grid item xs={12} sm={2} sx={{ pt: 0 }}>
                <Title variant="body1" align="left" sx={{ fontWeight: 600 }}>
                    {title}
                </Title>
            </Grid>
            <Grid item xs={12} sm={10}>
                {children}
            </Grid>
        </>
    )
}

const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
    },
}))
