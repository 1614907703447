import React from 'react'
import { Delete } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { ControlledCheckbox } from 'components/inputs/ControlledCheckbox'
import ControlledMuiSelect, { IOption } from 'components/inputs/ControlledMuiSelect'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { CreateOrUpdateBuildingForm } from 'redux/types/building.type'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OptionWithRiskClass } from 'types/util'
import { DeleteBtn } from 'modules/buildings/shared/styles'
import { ControlledAddableTagInput } from 'components/inputs/custom/ControlledAddableTagInput'
import { CustomOccupationDialog } from './CustomOccupationDialog'

type FloorFormProps = {
    idx: number
    onRemove: (floorIdx: number) => void
    onHalfFloorChange: (isHalfFloor: boolean, floorIdx: number) => void
    occupancyOptions: OptionWithRiskClass[]
    allRiskClassOptions: IOption[]
}

export const FloorForm: React.FC<FloorFormProps> = ({
    idx,
    onRemove,
    onHalfFloorChange,
    occupancyOptions,
    allRiskClassOptions,
}) => {
    const { t } = useTranslation()
    const { control, setValue } = useFormContext<CreateOrUpdateBuildingForm>()
    const floorNumber = useWatch({ control, name: `floors.${idx}.number` })
    const occupations = useWatch({ control, name: `floors.${idx}.occupations` })

    const [riskClassOptions, setRiskClassOptions] = React.useState<IOption[]>([])
    const mountedOnce = React.useRef(false)

    React.useEffect(() => {
        if (mountedOnce.current) return
        mountedOnce.current = true

        if (!occupations.length) return

        const floorOccupations = occupancyOptions.filter((occ) =>
            occupations.some((floorOcc) => floorOcc?.value === occ.value),
        )
        const hasCustomOccupation = floorOccupations.findIndex((occ) => occ.isCustom === true) !== -1

        if (hasCustomOccupation) {
            setRiskClassOptions(allRiskClassOptions)
            return
        }

        const uniqueRiskClasses = [...new Set(floorOccupations.map((item) => item.riskClass))]
        setRiskClassOptions(uniqueRiskClasses.map((rClass) => ({ label: rClass, value: rClass })))
    }, [occupations, idx, occupancyOptions, allRiskClassOptions])

    const onOccupanyAdd = (value: Array<OptionWithRiskClass> | Array<IOption>, idx: number) => {
        const selected = occupancyOptions.filter((opt) => value.some((occ) => occ.value === opt.value))
        const hasCustomOccupation = selected.findIndex((occ) => occ.isCustom === true) !== -1

        if (hasCustomOccupation) {
            setRiskClassOptions(allRiskClassOptions)
            return
        }

        const uniqueRiskClasses = [...new Set(selected.map((item) => item.riskClass))]
        setValue(`floors.${idx}.riskClasses`, uniqueRiskClasses)
        setRiskClassOptions(uniqueRiskClasses.map((rClass) => ({ label: rClass, value: rClass })))
    }

    return (
        <React.Fragment>
            <div>
                <DeleteBtn type="button" color="error" onClick={() => onRemove(idx)}>
                    <Delete />
                </DeleteBtn>
            </div>
            <div>
                <ControlledMuiTextField
                    control={control}
                    name={`floors.${idx}.number`}
                    sx={{ display: 'none' }}
                    disabled
                />
                <Typography variant="body1" component="span" sx={{ fontWeight: 700 }}>
                    {t('buildingsPage.floorName', { number: floorNumber })}
                </Typography>
            </div>

            <ControlledMuiTextField control={control} name={`floors.${idx}.name`} />

            <ControlledMuiTextField
                control={control}
                name={`floors.${idx}.area`}
                placeholder={t('common.area')}
                fullWidth
                type="number"
                inputSuffix={
                    <div>
                        m
                        <sup>
                            <sup>2</sup>
                        </sup>
                    </div>
                }
            />
            <ControlledCheckbox control={control} name={`floors.${idx}.terrain`} />
            <ControlledMuiSelect
                control={control}
                name={`floors.${idx}.riskClasses`}
                label={t('buildingsPage.riskClass')}
                fullWidth
                options={riskClassOptions}
                disabled={riskClassOptions.length === 0}
                multiple
            />
            <ControlledCheckbox control={control} name={`floors.${idx}.counting`} />
            <ControlledCheckbox
                control={control}
                name={`floors.${idx}.isHalfFloor`}
                onChangeCb={(checked) => onHalfFloorChange(checked, idx)}
            />
            <Box justifySelf="flex-start">
                <ControlledAddableTagInput
                    options={occupancyOptions}
                    control={control}
                    name={`floors.${idx}.occupations`}
                    searchable
                    onChangeCb={(value) => onOccupanyAdd(value, idx)}
                    renderDialog={(open, onClose, controlFromRender) => (
                        <CustomOccupationDialog isOpen={open} control={controlFromRender} handleClose={onClose} />
                    )}
                />
            </Box>
        </React.Fragment>
    )
}
