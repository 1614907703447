import { useCallback, useState } from 'react'

const useHistoryState = <T>(initialValue?: T) => {
    const [history, setHistory] = useState<T[]>(
        initialValue !== undefined && initialValue !== null ? [initialValue] : [],
    )
    const [pointer, setPointer] = useState(initialValue !== undefined && initialValue !== null ? 0 : -1)

    const pushHistory = useCallback(
        (value: T) => {
            setHistory((prev) => [...prev.slice(0, pointer + 1), value])
            setPointer((prev) => prev + 1)
        },
        [pointer],
    )

    const undo = useCallback(() => {
        if (pointer <= 0) return
        setPointer((prev) => prev - 1)
        return history[pointer - 1]
    }, [history, pointer, setPointer])

    const redo = useCallback(() => {
        if (pointer + 1 >= history.length) return
        setPointer((prev) => prev + 1)
        return history[pointer + 1]
    }, [pointer, history, setPointer])

    return [pushHistory, undo, redo, history, pointer] as const
}

export default useHistoryState
