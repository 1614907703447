import React, { ReactNode } from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import { ROUTES } from 'constants/routes'
import { useGetPublicSettingsQuery } from 'services/settings.service'
import { PageLoader } from 'components/general/loaders/PageLoader'

interface PublicRouteProps {
    children: ReactNode
}

const PublicRoute = ({ children }: PublicRouteProps) => {
    const { pathname: currentPath } = useLocation()
    const { isLoading: isLoadingSettings, isError: isSettingsError } = useGetPublicSettingsQuery()

    if (isSettingsError) {
        return <Navigate to={ROUTES.login} state={{ from: currentPath }} />
    }

    if (isLoadingSettings) {
        return <PageLoader />
    }

    return <>{children}</>
}

export default PublicRoute
