import React from 'react'
import { Control } from 'react-hook-form'
import { ControlledMuiAutoComplete } from 'components/inputs/ControlledMuiAutoComplete'
import { useGetDepartmentsQuery } from 'services/fireDepartments.service'
import { CreateOrUpdateBuildingForm } from 'redux/types/building.type'

interface ControlledFireDepartmentAutoCompleteProps {
    control: Control<CreateOrUpdateBuildingForm>
    onChangeCb: () => void
}

export const ControlledFireDepartmentAutoComplete: React.FC<ControlledFireDepartmentAutoCompleteProps> = ({
    control,
    onChangeCb,
}) => {
    const { isFetching, data: departments } = useGetDepartmentsQuery({})

    return (
        <ControlledMuiAutoComplete
            name="fireDepartment"
            options={departments || []}
            control={control}
            loading={isFetching}
            getOptionLabel={(opt) => opt?.name || ''}
            isOptionEqualToValue={(opt, value) => opt?.id === value?.id}
            onChangeCb={onChangeCb}
        />
    )
}
