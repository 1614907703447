import React from 'react'
import { Delete } from '@mui/icons-material'
import { Grid, Hidden, IconButton, SelectChangeEvent } from '@mui/material'
import ControlledMuiSelect, { IOption } from 'components/inputs/ControlledMuiSelect'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { Control, useWatch } from 'react-hook-form'
import { GoldenStandard } from 'redux/types/goldenStandards.type'
import { ProjectGeneralInfoRequestForm } from './ProjectDetails'
import { ControlledCheckbox } from 'components/inputs/ControlledCheckbox'
import { ControlledMuiSelectSearchable } from 'components/inputs/ControlledMuiSelectSearchable'

type GoldenStandardFormRowProps = {
    idx: number
    onRemove: (floorIdx: number) => void
    goldenStandardOptions: GoldenStandard[]
    fieldValueTypeOptions: IOption[]
    control: Control<ProjectGeneralInfoRequestForm>
    onValueTypeChange: (e: SelectChangeEvent<unknown>, idx: number) => void
}

export const GoldenStandardFormRow: React.FC<GoldenStandardFormRowProps> = ({
    idx,
    onRemove,
    goldenStandardOptions,
    fieldValueTypeOptions,
    control,
    onValueTypeChange,
}) => {
    const standardValueType = useWatch({ control, name: `goldenStandards.${idx}.fieldValueType` })

    return (
        <>
            <Grid item xs={4}>
                <ControlledMuiSelectSearchable
                    name={`goldenStandards.${idx}.name`}
                    options={goldenStandardOptions.map((std) => ({ label: std.label, value: std.name }))}
                    control={control}
                />
            </Grid>

            <Grid item xs={2}>
                <ControlledMuiSelect
                    control={control}
                    name={`goldenStandards.${idx}.fieldValueType`}
                    options={fieldValueTypeOptions}
                    onChangeCb={(e) => onValueTypeChange(e, idx)}
                    fullWidth
                />
            </Grid>

            <Grid item xs={4}>
                <Hidden xsUp={standardValueType !== 'string'}>
                    <ControlledMuiTextField control={control} fullWidth name={`goldenStandards.${idx}.stringValue`} />
                </Hidden>

                <Hidden xsUp={standardValueType !== 'number'}>
                    <ControlledMuiTextField
                        control={control}
                        fullWidth
                        name={`goldenStandards.${idx}.numberValue`}
                        type="number"
                    />
                </Hidden>

                <Hidden xsUp={standardValueType !== 'boolean'}>
                    <ControlledCheckbox control={control} name={`goldenStandards.${idx}.booleanValue`} />
                </Hidden>
            </Grid>

            <Grid item xs={1}>
                <IconButton color="error" onClick={() => onRemove(idx)}>
                    <Delete />
                </IconButton>
            </Grid>
        </>
    )
}
