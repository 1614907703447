import React from 'react'

import { Button, Stack, Typography, styled } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { FormStructureEntity } from 'redux/types/formStructure.type'
import { useForm } from 'react-hook-form'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { useGetTemplatesQuery } from 'services/templates.service'
import { ControlledMuiSelectSearchable } from 'components/inputs/ControlledMuiSelectSearchable'
import { LoadingButton } from '@mui/lab'
import { useCopyFormStructureAndTemplateMutation } from 'services/formStructures.service'

type CopyStructureFormEntity = {
    name: string
    template: string
}

const DEFAULT_VALUES: CopyStructureFormEntity = {
    name: '',
    template: '',
}

interface CopyStructureWithTemplateDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions'> {
    structure: FormStructureEntity
}

const CopyStructureWithTemplateDialog: React.FC<CopyStructureWithTemplateDialogProps> = ({
    open,
    handleClose,
    structure,
}) => {
    const { t } = useTranslation()

    const { data: templateOptions, isLoading } = useGetTemplatesQuery(
        { structureId: structure.id },
        {
            selectFromResult: ({ data, isLoading, isError }) => ({
                data: (data || []).map((template) => ({ label: template.name, value: template.id })),
                isLoading,
                isError,
            }),
        },
    )
    const [copyStructure, { isLoading: isCreating }] = useCopyFormStructureAndTemplateMutation()

    const { handleSubmit, control } = useForm<CopyStructureFormEntity>({
        defaultValues: DEFAULT_VALUES,
    })

    const onSubmit = async (values: CopyStructureFormEntity) => {
        await copyStructure({
            id: structure.id,
            copyName: values.name || undefined,
            templateId: values.template || undefined,
        })
        handleClose()
    }

    return (
        <ModalsWrapper
            open={open}
            title={
                <Trans i18nKey="structurePage.copyStructureDialogTitle" values={{ structureName: structure.name }} />
            }
            handleClose={handleClose}
            fullWidth
            maxWidth="sm"
            isLoading={isLoading}
            body={
                <form id="copy-structure-and-template-form" onSubmit={handleSubmit(onSubmit)}>
                    <ContentWrapper>
                        <Typography variant="body2">{t('structurePage.copyStructureExplanation')}</Typography>
                        <FormInsideContainer>
                            <ControlledMuiTextField
                                fullWidth
                                control={control}
                                name="name"
                                placeholder={t('structurePage.copyStructureNamePlaceholder')}
                            />
                            <ControlledMuiSelectSearchable
                                control={control}
                                name="template"
                                options={templateOptions}
                                placeholder={t('structurePage.copyStructureTemplatePlaceholder')}
                            />
                        </FormInsideContainer>
                    </ContentWrapper>
                </form>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={2}>
                    <Button variant="outlined" onClick={handleClose} color="secondary" disabled={isCreating}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="copy-structure-and-template-form"
                        loading={isCreating}
                    >
                        {t('common.save')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

export default CopyStructureWithTemplateDialog

const ActionWrapper = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))

const FormInsideContainer = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(2),
    margin: '0 auto',
    minWidth: 300,
}))
