import React from 'react'
import { Card } from '@mui/material'
import NormalizedRecursiveTreeView from 'components/general/navigation/NormalizedRecursiveTreeView'
import { NormalizationUtils } from 'utils/normalization-utils'
import { useCreateProjectContext } from 'modules/projects/context/CreateProjectContext'
import { useProjectQueryParams } from '../hooks/useProjectQueryParams'

export const PrebuiltFormNavigation: React.FC = () => {
    const [search, setSearch] = useProjectQueryParams()
    const { normalizedStructure, project } = useCreateProjectContext()

    React.useEffect(() => {
        if (!normalizedStructure || search.structureId || project?.status === 'Created') return

        const nextStructure = NormalizationUtils.findFirstViableSection(normalizedStructure.entities)
        if (!nextStructure) return

        setSearch(
            {
                structureId: nextStructure.sectionId,
            },
            'replace',
        )
    }, [normalizedStructure, project, search.structureId, setSearch])

    if (!normalizedStructure || !search.structureId) return null

    return (
        <Card sx={{ height: '100%', padding: 2, overflow: 'auto' }} elevation={1}>
            <NormalizedRecursiveTreeView data={normalizedStructure.entities} withProgressIcon withNumbering />
        </Card>
    )
}
