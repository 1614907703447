import React from 'react'
import { useTranslation } from 'react-i18next'

import { CreateOrUpdateBuildingForm } from 'redux/types/building.type'
import { useFormContext, useWatch } from 'react-hook-form'
import { useAppSelector } from 'redux/hooks'
import { selectMergedWithCustomSpecialRisksOptions } from 'redux/selectors/settings.selectors'
import { SectionBlock } from 'components/buildings/SectionBlock'
import { FormItem } from 'components/buildings/FormItem'
import { ControlledAddableTagInput } from 'components/inputs/custom/ControlledAddableTagInput'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { CustomSpecialRisksDialog } from './CustomSpecialRisksDialog'

export const SpecialRiskSectionForm: React.FC = () => {
    const { t } = useTranslation()
    const { control } = useFormContext<CreateOrUpdateBuildingForm>()

    const customSpecialRisks = useWatch({ control, name: 'customSpecialRisks' })
    const specialRisksOptions = useAppSelector(selectMergedWithCustomSpecialRisksOptions)(customSpecialRisks || [])

    return (
        <SectionBlock>
            <FormItem label={t('createEditBuildingPage.specialRisks')}>
                <ControlledAddableTagInput
                    options={specialRisksOptions}
                    control={control}
                    name="specialRisks"
                    searchable
                    renderDialog={(open, onClose, controlFromRender) => (
                        <CustomSpecialRisksDialog isOpen={open} control={controlFromRender} handleClose={onClose} />
                    )}
                />
            </FormItem>
            <FormItem
                label={t('createEditBuildingPage.specialRisksDescription')}
                sxTitleContainer={{ alignSelf: 'flex-start' }}
            >
                <ControlledMuiTextField
                    control={control}
                    sx={{ maxWidth: 600 }}
                    fullWidth
                    multiline
                    placeholder={t('common.description')}
                    name="specialRiskDescription"
                    variant="outlined"
                    rows={5}
                />
            </FormItem>
        </SectionBlock>
    )
}
