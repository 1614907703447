import { FullProjectWithNormalizedData, ProjectFormState } from 'redux/types/project.type'
import i18next from 'i18next'
import { NormalizationUtils } from './normalization-utils'
import { FormSectionWithProgress } from 'redux/types/formStructure.type'
import { AllViewSchemaElements, ArrayViewSchemaElement } from 'redux/types/schemaView.type'

const formatBaseValue = (value: unknown): string => {
    if (typeof value === 'string') return value.trim().toLowerCase()
    if (typeof value === 'boolean')
        return value === true ? i18next.t('common.yes').toLowerCase() : i18next.t('common.no').toLowerCase()
    if (typeof value === 'number') return value.toString()
    throw new Error('unhandled case, base value cant be other type then of string, bool or number')
}

const extractValueFromBaseElement = (value: unknown, formAnswers: Record<string, string[]>, sectionKey: string) => {
    if (Array.isArray(value)) {
        formAnswers[sectionKey].push(...value.map((v) => formatBaseValue(v)))
        return
    }
    formAnswers[sectionKey].push(formatBaseValue(value))
}

// array view eleemnt stores values differently then other
// complex elements so wrote different function for easier read
const extractInfoFromArrayElement = (
    element: ArrayViewSchemaElement,
    formLabels: Record<string, string[]>,
    formDisciplineTags: Record<string, string[]>,
    formBuildingPartTags: Record<string, string[]>,
    formAnswers: Record<string, string[]>,
    sectionKey: string,
) => {
    if (element.isVisible === false || element.isVisibleInWeb === false) return

    formLabels[sectionKey].push(element.label.trim().toLowerCase())

    for (const childElement of element.nodes) {
        for (const row of element.value) {
            const cellValue = row[childElement.name]
            if (cellValue && childElement.type === 'disciplinesTag') {
                formDisciplineTags[sectionKey].push(...(cellValue as string[]))
                continue
            }
            if (cellValue && childElement.type === 'buildingPart') {
                formBuildingPartTags[sectionKey].push(...(cellValue as string[]))
                continue
            }
            if (
                cellValue !== undefined &&
                childElement.type !== 'disciplinesTag' &&
                childElement.type !== 'buildingPart'
            ) {
                extractValueFromBaseElement(cellValue, formAnswers, sectionKey)
            }
        }
    }

    formLabels[sectionKey].push(
        ...Object.keys(element.options.columnDefinitions).map((colName) => colName.trim().toLowerCase()),
    )
}

const extractInfoFromElement = (
    element: AllViewSchemaElements,
    formLabels: Record<string, string[]>,
    formDisciplineTags: Record<string, string[]>,
    formBuildingPartTags: Record<string, string[]>,
    formAnswers: Record<string, string[]>,
    sectionKey: string,
) => {
    if (element.isVisible === false || element.isVisibleInWeb === false) return

    if ('nodes' in element) {
        if (element.type !== 'array') {
            for (const childElement of element.nodes) {
                extractInfoFromElement(
                    childElement,
                    formLabels,
                    formDisciplineTags,
                    formBuildingPartTags,
                    formAnswers,
                    sectionKey,
                )
            }
        } else {
            extractInfoFromArrayElement(
                element,
                formLabels,
                formDisciplineTags,
                formBuildingPartTags,
                formAnswers,
                sectionKey,
            )
        }
    }

    if ('label' in element) {
        formLabels[sectionKey].push(element.label.trim().toLowerCase())
    }
    if (element.type === 'disciplinesTag' && element.value) {
        formDisciplineTags[sectionKey].push(...element.value)
        return
    }
    if (element.type === 'buildingPart' && element.value) {
        formBuildingPartTags[sectionKey].push(...element.value)
        return
    }

    if ('value' in element && element.value !== undefined) {
        if (element.type === 'array') return
        extractValueFromBaseElement(element.value, formAnswers, sectionKey)
    }
}

const extractFilteringInfoFromFormState = (formState: Exclude<ProjectFormState['formSnapshot'], null>) => {
    const formLabels: Record<string, string[]> = {}
    const formDisciplineTags: Record<string, string[]> = {}
    const formBuildingPartTags: Record<string, string[]> = {}
    // doesnt contain discipline tags and building part tags
    const formAnswers: Record<string, string[]> = {}

    for (const sectionKey in formState) {
        const section = formState[sectionKey]
        if (!section || section.staticType) continue

        formLabels[sectionKey] = []
        formDisciplineTags[sectionKey] = []
        formBuildingPartTags[sectionKey] = []
        formAnswers[sectionKey] = []

        for (const element of section.elements) {
            if (element.type === 'array') {
                extractInfoFromArrayElement(
                    element,
                    formLabels,
                    formDisciplineTags,
                    formBuildingPartTags,
                    formAnswers,
                    sectionKey,
                )
            } else {
                extractInfoFromElement(
                    element,
                    formLabels,
                    formDisciplineTags,
                    formBuildingPartTags,
                    formAnswers,
                    sectionKey,
                )
            }
        }

        if (formLabels[sectionKey].length === 0) delete formLabels[sectionKey]
        if (formDisciplineTags[sectionKey].length === 0) delete formDisciplineTags[sectionKey]
        if (formBuildingPartTags[sectionKey].length === 0) delete formBuildingPartTags[sectionKey]
        if (formAnswers[sectionKey].length === 0) delete formAnswers[sectionKey]
    }

    return {
        formAnswers,
        formBuildingPartTags,
        formDisciplineTags,
        formLabels,
    }
}

export const transformFullProjectRequest = (initialRequest: FullProjectWithNormalizedData, isPreview?: boolean) => {
    if (initialRequest.creationType === 'ByDocumentUpload') return initialRequest

    const { formState } = initialRequest
    const staticSchemas: string[] = []

    const normalizedStructure = NormalizationUtils.NormalizeNestedData<FormSectionWithProgress>(
        initialRequest.structure ? initialRequest.structure.sections : [],
        'sectionId',
    )

    if (formState?.formSnapshot) {
        const { formAnswers, formBuildingPartTags, formDisciplineTags, formLabels } = extractFilteringInfoFromFormState(
            formState.formSnapshot,
        )

        initialRequest.filteringData = {
            formattedFormAnswers: formAnswers,
            formBuildingPartTags,
            formDisciplineTags,
            formLabels,
        }
    }

    if (normalizedStructure) {
        Object.keys(normalizedStructure.entities).forEach((normalizationKey) => {
            if (normalizedStructure.entities[normalizationKey].staticType) staticSchemas.push(normalizationKey)
        })

        formState?.completeSchemas.forEach((id) => {
            if (normalizedStructure.entities[id]) {
                normalizedStructure.entities[id].progress = 'complete'
            }
        })
        formState?.draftSchemas.forEach((id) => {
            if (normalizedStructure.entities[id]) {
                normalizedStructure.entities[id].progress = 'draft'
            }
        })
        formState?.disabledSchemas.forEach((id) => {
            if (normalizedStructure.entities[id]) {
                normalizedStructure.entities[id].progress = 'disabled'
            }
        })
        formState?.unassignedSchemas.forEach((id) => {
            if (normalizedStructure.entities[id]) {
                normalizedStructure.entities[id].progress = 'no-form'
            }
        })
    }

    if (!isPreview) {
        initialRequest.normalizedStructure = normalizedStructure
        return initialRequest
    }

    if (normalizedStructure) {
        const filteredStructures = { ...normalizedStructure }
        const newEntities = NormalizationUtils.getOnlyCompleteSchemas(
            staticSchemas,
            filteredStructures.entities,
            initialRequest.formState?.completeSchemas,
            initialRequest.formState?.unassignedSchemas,
        )

        if (formState?.completeSchemas.length || formState?.unassignedSchemas.length || staticSchemas.length) {
            const newResults = filteredStructures.result.filter((id) => {
                if (staticSchemas.includes(id)) {
                    return false
                }
                return formState?.completeSchemas.includes(id) || formState?.unassignedSchemas.includes(id)
            })
            filteredStructures.result = newResults
        } else {
            filteredStructures.result = []
        }

        if (newEntities) {
            filteredStructures.entities = newEntities
        }

        initialRequest.normalizedStructure = filteredStructures
    }

    const newProjectForms: Record<string, any> = {}

    if (formState?.answers) {
        for (const id of Object.keys(formState.answers)) {
            if (formState?.completeSchemas.includes(id)) {
                newProjectForms[id] = formState.answers[id]
            }
        }
    }
    if (initialRequest.formState) {
        initialRequest.formState.answers = newProjectForms
    }
    return initialRequest
}
