import React from 'react'
import { useTranslation } from 'react-i18next'
import { TabContentContainer } from 'components/customers/styles'
import { ECustomerHomeTabs } from './CustomerHomeTabs'
import { useAppSelector } from 'redux/hooks'
import { selectUserOrganizationIds } from 'redux/slices/auth.slice'
import { useGetCustomerBuildingsQuery } from 'services/buildings.service'
import { useDataGridFiltersState } from 'utils/hooks/useDataGridFiltersState'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { SortDirection, useMediaQuery, useTheme } from '@mui/material'
import { DataGrid, GridColDef, GridSortDirection } from '@mui/x-data-grid'
import { DataGridNoRows } from 'components/general/table/DataGridNoRows'
import { Building } from 'redux/types/building.type'
import { DataGridLinkCell } from 'components/general/table/DataGridLinkCell'
import { navigationLinks } from 'utils/navigation-utils'

type CustomerBuildingsProps = {
    activeTab: ECustomerHomeTabs
    searchQuery: string
}

export const CustomerBuildings: React.FC<CustomerBuildingsProps> = React.memo(({ activeTab, searchQuery }) => {
    const { t } = useTranslation()

    const userOrgIds = useAppSelector(selectUserOrganizationIds)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const { handlePageChange, handleSortChange, page, sortBy } = useDataGridFiltersState({
        sort: 'desc',
        field: 'name',
    })

    const { data: buildings, isFetching } = useGetCustomerBuildingsQuery(
        {
            page: page || 0,
            itemsPerPage: DEFAULT_ROWS_PER_PAGE,
            direction: sortBy?.sort as SortDirection,
            sortBy: sortBy?.field,
            organizationIds: userOrgIds || undefined,
            searchQuery: searchQuery || undefined,
        },
        { skip: userOrgIds === null || userOrgIds.length === 0 },
    )

    const columns: GridColDef<Building>[] = [
        {
            field: 'name',
            headerName: t('common.name'),
            flex: 1,
            minWidth: 300,
            editable: false,
            renderCell: (params) => (
                <DataGridLinkCell
                    label={params.row.name || '-'}
                    to={navigationLinks.toCustomerBuilding(params.row.id)}
                />
            ),
        },
        {
            field: 'address',
            headerName: t('common.address'),
            flex: 1,
            editable: false,
            sortable: false,
            minWidth: 350,
            valueGetter: (params) => `${params.row.address.addressText}, ${params.row.address.city}` || '-',
        },
        {
            field: 'postal',
            headerName: t('common.postal'),
            flex: 1,
            editable: false,
            sortable: false,
            minWidth: 140,
            maxWidth: 140,
            valueGetter: (params) => params.row.address.postalCode || '-',
        },
        {
            field: 'cadastre',
            headerName: t('common.cadastreNumber'),
            flex: 1,
            editable: false,
            sortable: false,
            minWidth: 220,
            maxWidth: 250,
            valueGetter: (params) => params.row.cadastre?.cleanNumber || '-',
        },
        {
            field: 'year',
            headerName: t('common.year'),
            flex: 1,
            editable: false,
            minWidth: 100,
            maxWidth: 100,
        },
        {
            field: 'updated',
            hideable: true,
        },
    ]

    return (
        <TabContentContainer role="tabpanel" hidden={activeTab !== ECustomerHomeTabs.Buildings}>
            <div>
                <DataGrid
                    sx={{
                        '& .MuiDataGrid-columnHeader': {
                            textTransform: 'capitalize',
                        },
                    }}
                    autoHeight
                    rows={buildings?.data || []}
                    columns={columns}
                    pageSize={DEFAULT_ROWS_PER_PAGE}
                    rowCount={buildings?.total || 0}
                    isRowSelectable={() => false}
                    disableColumnMenu
                    loading={isFetching}
                    hideFooterSelectedRowCount
                    rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                    components={{
                        NoRowsOverlay: DataGridNoRows,
                    }}
                    paginationMode="server"
                    sortingMode="server"
                    onPageChange={handlePageChange}
                    onSortModelChange={handleSortChange}
                    page={page || 0}
                    sortModel={
                        sortBy?.field && sortBy?.sort
                            ? [{ field: sortBy.field, sort: sortBy.sort as GridSortDirection }]
                            : []
                    }
                    columnVisibilityModel={
                        isMobile
                            ? {
                                  name: true,
                                  address: true,
                                  postal: false,
                                  cadastre: true,
                                  year: false,
                                  updated: false,
                              }
                            : {
                                  name: true,
                                  address: true,
                                  postal: true,
                                  cadastre: true,
                                  year: true,
                                  updated: false,
                              }
                    }
                />
            </div>
        </TabContentContainer>
    )
})
