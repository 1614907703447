import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormItem } from './FormItem'
import { Project } from 'redux/types/project.type'
import dayjs from 'dayjs'
import { Check, DoNotDisturbAlt } from '@mui/icons-material'
import { valueOrDefaultEmpty } from 'utils/general'
import { BuildingInfoChip } from 'components/general/chips/BuildingInfoChip'
import { DATE_FORMATING_LOCALE } from 'constants/locales'
import { formatVersionNumber } from 'utils/string-utils'

interface ProjectDetailsDisplayProps {
    project: Project
    isCustomerView?: boolean
    selectedTemplate?: string
}

export const ProjectDetailsDisplay: React.FC<ProjectDetailsDisplayProps> = ({
    project,
    isCustomerView,
    selectedTemplate,
}) => {
    const { t } = useTranslation()
    const {
        creationType,
        name,
        type,
        comment,
        responsableCompany,
        projectManager,
        qualityAssurance,
        buildings,
        regulation,
        customer,
        publishDate,
        internalReferenceId,
        projectClass,
        readableUniqueIdentifier,
        currentRevisionNumber,
        lastRevisionDate,
        buildingFloors,
    } = project
    const isFirstVersion = currentRevisionNumber === 0

    const relevantBuildingFloors =
        buildingFloors.length > 0 && buildings.length === 1
            ? buildings[0].floors.filter((floor) => buildingFloors.includes(floor.number))
            : null

    return (
        <Grid container rowGap={2} wrap="wrap" columnSpacing={1} justifyContent="flex-start" alignItems="flex-start">
            {!isCustomerView && (
                <FormItem title={t('projectPage.projectCreationType')}>
                    {creationType === 'ByDocumentUpload' ? (
                        <Check color="success" />
                    ) : (
                        <DoNotDisturbAlt color="error" />
                    )}
                </FormItem>
            )}
            <FormItem title={t('projectPage.projectName')}>
                <Typography variant="body2">{name}</Typography>
            </FormItem>

            {!isFirstVersion && (
                <FormItem title={t('projectPage.projectRevisionNumber')}>
                    <Typography variant="body2">{formatVersionNumber(currentRevisionNumber)}</Typography>
                </FormItem>
            )}

            {!isFirstVersion && lastRevisionDate && (
                <FormItem title={t('projectPage.projectRevisionDate')}>
                    <Typography variant="body2">
                        {dayjs(lastRevisionDate).format(DATE_FORMATING_LOCALE.no.yearDisplay)}
                    </Typography>
                </FormItem>
            )}

            <FormItem title={t('projectPage.projectReadableIdentifier')}>
                <Typography variant="body2">{readableUniqueIdentifier}</Typography>
            </FormItem>

            {type && (
                <FormItem title={t('projectPage.projectType')}>
                    <Typography variant="body2">{type.label}</Typography>
                </FormItem>
            )}

            {selectedTemplate && (
                <FormItem title={t('projectPage.projectTemplateForm')}>
                    <Typography variant="body2">{selectedTemplate}</Typography>
                </FormItem>
            )}

            {comment && (
                <FormItem title={t('projectPage.projectComments')}>
                    <Typography variant="body2">{comment}</Typography>
                </FormItem>
            )}

            <FormItem title={t('projectPage.projectResponsableCompany')}>
                <Typography variant="body2">{valueOrDefaultEmpty(responsableCompany)}</Typography>
            </FormItem>

            {projectManager && (
                <FormItem title={t('projectPage.projectManager')}>
                    <Typography variant="body2">{projectManager.fullName}</Typography>
                </FormItem>
            )}

            {qualityAssurance && (
                <FormItem title={t('projectPage.projectQa')}>
                    <Typography variant="body2">{qualityAssurance.fullName}</Typography>
                </FormItem>
            )}

            {!isCustomerView && (
                <>
                    <FormItem title={t('projectPage.projectBuildings')}>
                        <Box display="flex" gap={1} flexWrap="wrap" maxWidth={600}>
                            {buildings.map((building) => (
                                <BuildingInfoChip building={building} key={building.id} />
                            ))}
                        </Box>
                    </FormItem>
                </>
            )}
            {relevantBuildingFloors && (
                <FormItem title={t('projectPage.relevantBuildingFloorForTheProject')}>
                    <Box display="flex" gap={1} flexWrap="wrap" maxWidth={600}>
                        {relevantBuildingFloors.map((floor) => floor.name || floor.number).join(', ')}
                    </Box>
                </FormItem>
            )}

            {regulation && (
                <>
                    <FormItem title={t('common.regulation')}>
                        <Typography variant="body2">{`${regulation.name}, ${dayjs(regulation.date).format(
                            DATE_FORMATING_LOCALE.no.yearDisplay,
                        )}`}</Typography>
                    </FormItem>
                </>
            )}

            {customer && !isCustomerView && (
                <FormItem title={t('common.customer')}>
                    <Typography variant="body2">{customer.name}</Typography>
                </FormItem>
            )}
            <FormItem title={t('projectPage.projectPublished')}>
                <Typography variant="body2">
                    {publishDate ? dayjs(publishDate).format(DATE_FORMATING_LOCALE.no.yearDisplay) : '-'}
                </Typography>
            </FormItem>
            {!isCustomerView && internalReferenceId && (
                <FormItem title={t('projectPage.projectReferenceId')}>
                    <Typography variant="body2">{internalReferenceId}</Typography>
                </FormItem>
            )}
            <FormItem title={t('projectPage.projectClass')}>
                <Typography variant="body2">{valueOrDefaultEmpty(projectClass?.label)}</Typography>
            </FormItem>
        </Grid>
    )
}
