import React from 'react'
import { Box, Button, Card, styled } from '@mui/material'
import NormalizedRecursiveTreeView from 'components/general/navigation/NormalizedRecursiveTreeView'
import { NormalizationUtils } from 'utils/normalization-utils'
import { useProjectQueryParams } from '../hooks/useProjectQueryParams'
import { useProjectView } from '../hooks/useProjectView'
import { Search } from '@mui/icons-material'
import { NavigationFilterDialog } from './NavigationFilterDialog'
import { useTranslation } from 'react-i18next'

export const PrebuiltFormNavigationFilterable: React.FC = () => {
    const { t } = useTranslation()
    const [search, setSearch] = useProjectQueryParams()
    const { project, isCustomerView } = useProjectView()
    const { normalizedStructure, filteringData } = project || {}

    const [isFilterDialogOpen, setIsFilterDialogOpen] = React.useState(false)

    React.useEffect(() => {
        if (!normalizedStructure || search.structureId || project?.status === 'Created') return

        const nextStructure = NormalizationUtils.findFirstViableSection(normalizedStructure.entities)

        if (!nextStructure) return
        setSearch({ ...search, structureId: nextStructure.sectionId }, 'replace')
    }, [normalizedStructure, project, search, setSearch])

    if (!normalizedStructure || !filteringData || !search.structureId) return null

    return (
        <>
            <StyledCard
                sx={{ height: '100%', px: 3, pt: 3, pb: 5, borderRadius: '5px 5px 0 0', overflow: 'auto' }}
                elevation={1}
            >
                <Box mb={2} pl={1}>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        endIcon={<Search />}
                        onClick={() => setIsFilterDialogOpen(true)}
                    >
                        {t('common.search')}
                    </Button>
                </Box>

                <NormalizedRecursiveTreeView
                    data={normalizedStructure.entities}
                    withProgressIcon={!isCustomerView}
                    withNumbering
                />
            </StyledCard>
            <NavigationFilterDialog
                open={isFilterDialogOpen}
                handleClose={() => setIsFilterDialogOpen(false)}
                projectFilterData={filteringData}
                projectTreeStructure={normalizedStructure.entities}
            />
        </>
    )
}

const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    padding: theme.spacing(3, 3, 5, 3),
    borderRadius: '5px 5px 0 0',
    overflow: 'auto',

    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
        borderRadius: 0,
        border: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
    },
}))
