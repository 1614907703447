import React from 'react'
import { Autocomplete, Button, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material'
import { NameHook } from 'components/forms/hooks/schema-hooks'
import { useAppSelector } from 'redux/hooks'
import { selectStandards } from 'redux/selectors/goldenStandards.selectors'
import { FIELD_TYPES, FieldType } from 'redux/types/schema.type'
import { useTranslation } from 'react-i18next'
import { makeRandomId } from 'utils/string-utils'

const Exclude_STANDART_SELECT: Partial<FieldType[]> = [
    FIELD_TYPES.HorizontalLayout,
    FIELD_TYPES.VerticalLayout,
    FIELD_TYPES.Group,
    FIELD_TYPES.Label,
    FIELD_TYPES.Array,
    FIELD_TYPES.GroupTable,
]

type Props = {
    nameHook: NameHook
    fieldType: FieldType
    children?: React.ReactNode
}

export const NameLabel: React.FC<Props> = ({ nameHook, fieldType, children }) => {
    const { t } = useTranslation()
    const standards = useAppSelector(selectStandards)

    const {
        name,
        setName,
        label,
        setLabel,
        required,
        setRequired,
        setGoldenStandard,
        clearGoldenStandard,
        selectedStandardId,
        options,
        setNewOptions,
    } = nameHook

    const isStandartFieldVisible = !Exclude_STANDART_SELECT.includes(fieldType)

    const selectedStandart = React.useMemo(() => {
        return standards.find((opt) => opt.id === selectedStandardId) || null
    }, [selectedStandardId, standards])

    const generateRandomName = () => {
        const randomStr = makeRandomId(8)
        setName(randomStr)
    }

    return (
        <>
            {isStandartFieldVisible && (
                <Stack spacing={2} direction="row">
                    <Autocomplete
                        disablePortal
                        sx={{ width: '100%', maxWidth: 225 }}
                        options={standards}
                        renderInput={(params) => <TextField {...params} label={t('schemaPage.goldenStandartLabel')} />}
                        onChange={(_, value) => setGoldenStandard(value)}
                        value={selectedStandart}
                        onInputChange={(_e, _v, reason) => {
                            if (reason === 'clear') clearGoldenStandard()
                        }}
                    />
                </Stack>
            )}
            <Stack spacing={2} direction="row">
                <TextField
                    style={{ marginTop: '10px' }}
                    value={label}
                    id="label-field"
                    label={t('common.fieldLabel')}
                    onChange={(e) => setLabel(e.target.value)}
                />
                <FormControlLabel
                    control={<Checkbox checked={required} onChange={(x) => setRequired(x.target.checked)} />}
                    label={t('required')}
                />
            </Stack>
            <Stack my={2} spacing="25px" direction="row">
                <TextField
                    value={name}
                    id="name-field"
                    label={t('common.name')}
                    onChange={(e) => setName(e.target.value)}
                />
                <Button type="button" variant="contained" onClick={generateRandomName}>
                    {t('schemaPage.createRandomName')}
                </Button>
            </Stack>
            {children ? children : null}
            <FormControlLabel
                control={
                    <Checkbox
                        checked={Boolean(options.hideLabel)}
                        onChange={(e) => setNewOptions({ hideLabel: e.target.checked })}
                    />
                }
                label={t('schemaPage.hideLabel')}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={Boolean(options.hideInPdf)}
                        onChange={(e) => setNewOptions({ hideInPdf: e.target.checked })}
                    />
                }
                label={t('schemaPage.hideFieldInCustomerView')}
            />
        </>
    )
}
