import React from 'react'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { OrderedListDisplay, UnorderedListDisplay } from './ListView'
import { TagDisplay } from './DisciplinesTag'
import { FieldViewType } from 'redux/types/schemaView.type'
import { DATE_LOCALE_OPTIONS } from 'constants/locales'
import { valueOrDefaultEmpty } from 'utils/general'

type ElementValueBlockProps = {
    value: unknown
    type: FieldViewType
    isOrderedList?: boolean
}

export const ElementValueBlock: React.FC<ElementValueBlockProps> = ({ value, type, isOrderedList }) => {
    if (type === 'date' && typeof value === 'string')
        return <>{new Date(value).toLocaleDateString('no', DATE_LOCALE_OPTIONS['no'])}</>
    if (typeof value === 'undefined') return <>-</>
    if (typeof value === 'string' || typeof value === 'number') return <>{valueOrDefaultEmpty(value)}</>
    if (typeof value === 'boolean')
        return value === true ? <CheckBox color="primary" /> : <CheckBoxOutlineBlank color="primary" />

    // either checkbox or list
    if (Array.isArray(value)) {
        if (type && type === 'list') {
            return isOrderedList ? <OrderedListDisplay values={value} /> : <UnorderedListDisplay values={value} />
        }
        if (type && (type === 'disciplinesTag' || type === 'buildingPart')) {
            return <TagDisplay values={value} />
        }
        return <>{value.join(', ')}</>
    }

    throw new Error('Unhandled case')
}
