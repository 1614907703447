import { Grid, SxProps, Typography, styled } from '@mui/material'

interface FormItemProps {
    label: string
    children: React.ReactNode
    sxTitleContainer?: SxProps
}

export const FormItem: React.FC<FormItemProps> = ({ label, children, sxTitleContainer }) => {
    return (
        <>
            <Grid item xs={12} sm={2.5} sx={{ pt: 0, ...sxTitleContainer }}>
                <Title variant="body1" align="left">
                    {label}
                </Title>
            </Grid>
            <Grid item xs={12} sm={9.5}>
                {children}
            </Grid>
        </>
    )
}

const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
    },
}))
