import { Divider, styled } from '@mui/material'
import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTranslation } from 'react-i18next'

interface PrebuiltFormActionsProps {
    isSubmitting: boolean
    onSaveDraft: () => void
    onSubmit: () => void
    isFormDisabled: boolean
}

export const PrebuiltFormActions: React.FC<PrebuiltFormActionsProps> = ({
    isSubmitting,
    onSaveDraft,
    onSubmit,
    isFormDisabled,
}) => {
    const { t } = useTranslation()

    return (
        <>
            <Divider sx={{ width: '100%' }} />
            <Container>
                <LoadingButton
                    type="button"
                    disabled={isSubmitting || isFormDisabled}
                    loading={isSubmitting}
                    variant="contained"
                    color="secondary"
                    onClick={onSaveDraft}
                >
                    {t('projectPage.saveDraft')}
                </LoadingButton>
                <LoadingButton
                    type="button"
                    disabled={isSubmitting || isFormDisabled}
                    variant="outlined"
                    loading={isSubmitting}
                    color="primary"
                    onClick={onSubmit}
                >
                    {t('projectPage.finishSchema')}
                </LoadingButton>
            </Container>
        </>
    )
}

const Container = styled('div')(({ theme }) => ({
    display: 'grid',
    justifyItems: 'center',
    gridTemplateColumns: 'repeat(auto-fit, minmax(220px, max-content))',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),

    '& button': {
        width: '100%',
    },

    '& a': {
        width: '100%',
    },

    [theme.breakpoints.down(790)]: {
        justifyContent: 'center',
    },
}))
