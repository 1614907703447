import React from 'react'
import { useTranslation } from 'react-i18next'
import { useEditTemplateMutation } from 'services/templates.service'
import { useParams } from 'react-router'
import { JsonFormsCore } from '@jsonforms/core'
import { Box } from '@mui/system'
import { RegisteredJsonForm } from 'components/general/jsonForm/RegisteredJsonForm'
import { extractFieldValues, removeEmptyValues } from 'utils/forms-utils'
import { LoadingButton } from '@mui/lab'
import { TemplateEntityWithPartialStructure } from 'redux/types/template.type'
import { NormalizedData } from 'types/util'
import { FormSection } from 'redux/types/formStructure.type'
import { TemplateEditParams } from 'pages/settings/TemplateEdit.page'
import { useProjectQueryParams } from 'modules/projects/hooks/useProjectQueryParams'
import { Schema } from 'redux/types/schema.type'

export type TemplateEditFormProps = {
    template: TemplateEntityWithPartialStructure
    normalizedStructure: NormalizedData<FormSection> | null
    navigateToNextForm: () => void
    schema: Schema
}

export const TemplateEditForm: React.FC<TemplateEditFormProps> = ({
    template,
    normalizedStructure,
    navigateToNextForm,
    schema,
}) => {
    const { t } = useTranslation()
    const { templateId } = useParams<keyof TemplateEditParams>() as TemplateEditParams
    const [search] = useProjectQueryParams()

    const [editTemplate, { isLoading: isSubmitting }] = useEditTemplateMutation()

    const [formValues, setFormValues] = React.useState<JsonFormsCore['data']>({})

    const getNewSchemaTemplateValues = () => {
        const schemaFieldNames = extractFieldValues(schema!.appSchema, 'name', 'nodes')
        if (!schemaFieldNames) {
            throw new Error('Unhandled case')
        }

        const filteredTemplateValues = removeEmptyValues(formValues)
        const templateValues: Record<string, any> = {}

        schemaFieldNames.forEach((name) => {
            if (filteredTemplateValues[name] !== undefined) {
                templateValues[name] = filteredTemplateValues[name]
            }
        })

        return templateValues
    }

    const handleFormChange = ({ data }: Pick<JsonFormsCore, 'data'>) => {
        setFormValues(data)
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!search.structureId || !normalizedStructure || !schema) return

        const templateValues = getNewSchemaTemplateValues()

        const newTemplate = {
            ...template?.template,
        }

        newTemplate[search.structureId] = templateValues

        try {
            await editTemplate({
                id: templateId,
                template: newTemplate,
            }).unwrap()

            navigateToNextForm()
        } catch (e) {
            // do nothing we summon toast from middleware
        }
    }

    return (
        <Box mb={4}>
            <form onSubmit={handleSubmit}>
                <RegisteredJsonForm
                    data={
                        search.structureId && template.template?.[search.structureId]
                            ? template.template[search.structureId]
                            : formValues
                    }
                    onChange={handleFormChange}
                    schema={schema.jsonSchema}
                    uischema={schema.uiSchema}
                />
                <Box display="flex" justifyContent="center">
                    <LoadingButton
                        type="submit"
                        disabled={isSubmitting}
                        variant="outlined"
                        color="primary"
                        loading={isSubmitting}
                    >
                        {t('common.continue')}
                    </LoadingButton>
                </Box>
            </form>
        </Box>
    )
}
