import React from 'react'
import { Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { ViewSectionProps } from './GeneralSection'
import { valueOrDefaultEmpty } from 'utils/general'
import { SectionBlock } from 'components/buildings/SectionBlock'
import { FormItem } from 'components/buildings/FormItem'

export const EnergySection: React.FC<ViewSectionProps> = ({ building }) => {
    const { t } = useTranslation()
    const { fireLoad, fireLoadDescription } = building

    return (
        <SectionBlock density="dense">
            <FormItem label={t('createEditBuildingPage.fireLoad')}>
                <Typography variant="body1" component="span" sx={{ mr: 4 }}>
                    {valueOrDefaultEmpty(fireLoad)}
                </Typography>
                <span>
                    MJ/m
                    <sup>
                        <sup>2</sup>
                    </sup>
                </span>
            </FormItem>

            <FormItem
                label={t('createEditBuildingPage.fireLoadDescription')}
                sxTitleContainer={{ alignSelf: 'flex-start' }}
            >
                {valueOrDefaultEmpty(fireLoadDescription)}
            </FormItem>
        </SectionBlock>
    )
}
