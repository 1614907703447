import React from 'react'
import { TopBar } from '../../components/general/top-bar/top-bar'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { PageContainer } from 'components/general/containers/PageContainer'
import { OrganizationsTable } from 'modules/organizations/organizations/OrganizationsTable'

export const OrganizationsPage: React.FC = () => {
    const { t } = useTranslation()

    return (
        <>
            <Helmet>
                <title>{t('navigationMenu.organizations')}</title>
            </Helmet>
            <TopBar />
            <PageContainer>
                <OrganizationsTable />
            </PageContainer>
        </>
    )
}
