import { Delete } from '@mui/icons-material'
import { Autocomplete, Button, IconButton, Stack, TextField } from '@mui/material'
import * as O from 'optics-ts'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RulesEditor } from '../rules/rules-editor'
import { useName, useRule } from '../hooks/schema-hooks'
import { BuilderFieldProps } from './builder-field-props'
import { Draggable } from './droppable'
import { FieldDialog } from './field-dialog'
import { FieldItem } from './field-item'
import { NameLabel } from './general/field-name-label'
import { TabPanel } from './general/field-tab-panel'
import { FieldTabs } from './general/field-tabs'
import { useAppSelector } from 'redux/hooks'
import { selectAllOptionsByDefaultQuery } from 'redux/selectors/preBuiltOptions.selectors'
import { DEFAULT_PREBUILT_OPTIONS_QUERY } from 'services/prebuiltOptions.service'
import { CheckboxSchemaElement, RadioSchemaElement, SelectSchemaElement } from 'redux/types/schema.type'

export const BuilderSelect: React.FC<
    BuilderFieldProps<SelectSchemaElement | RadioSchemaElement | CheckboxSchemaElement>
> = ({ node, state, setState, lens, path, droppable }) => {
    const { t } = useTranslation()

    const prebuiltOptions = useAppSelector((state) =>
        selectAllOptionsByDefaultQuery(state, DEFAULT_PREBUILT_OPTIONS_QUERY),
    )

    const nameHook = useName(lens, state, setState)
    const rule = useRule(lens, state, setState)

    const enums = lens.prop('enum')
    const remove = () => setState(O.remove(lens)(state))
    const [tabIndex, setTabIndex] = React.useState(0)

    const autoCompleteOptions = React.useMemo(() => {
        return (prebuiltOptions.data?.data || []).map((list) => ({ value: list.id, label: list.name }))
    }, [prebuiltOptions.data?.data])

    const selectedPrebuiltOptionList = React.useMemo(() => {
        return prebuiltOptions.data?.data.find((opt) => opt.id === nameHook.selectedPrebuiltOptionid) || null
    }, [nameHook.selectedPrebuiltOptionid, prebuiltOptions.data?.data])

    const append = () => {
        setState(
            O.modify(enums)((x) => {
                const nodes = x as string[]
                return [...nodes, '']
            }),
        )
    }

    if (!droppable) {
        return (
            <>
                <FieldItem
                    node={node}
                    nameValue={nameHook.name}
                    hasRules={rule.hasRules}
                    isGoldenStandard={nameHook.isGoldenStard}
                />
                <FieldDialog node={node} remove={remove} onSave={rule.onSaveRules}>
                    <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                    <TabPanel value={tabIndex} index={0}>
                        <NameLabel nameHook={nameHook} fieldType={node.type} />
                        <h4>{t('common.addOptionList')}</h4>
                        <Stack spacing={2} direction="row" sx={{ my: 2 }}>
                            <Autocomplete
                                disablePortal
                                sx={{ width: '100%', maxWidth: 225 }}
                                options={autoCompleteOptions}
                                renderInput={(params) => <TextField {...params} label={t('common.addOptionList')} />}
                                onChange={(_, value) => {
                                    const optionListToAppend = prebuiltOptions?.data?.data
                                        .find((list) => list.id === value?.value)
                                        ?.options.map((opt) => opt.label)
                                    if (optionListToAppend) {
                                        nameHook.setPrebuiltOptionId(value?.value)
                                        nameHook.setPrebuiltList(optionListToAppend)
                                    }
                                }}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                value={
                                    selectedPrebuiltOptionList
                                        ? {
                                              label: selectedPrebuiltOptionList?.name,
                                              value: selectedPrebuiltOptionList?.id,
                                          }
                                        : null
                                }
                                onInputChange={(_e, _v, reason) => {
                                    if (reason === 'clear') nameHook.clearPrebuiltList()
                                }}
                            />
                        </Stack>
                        <h4>{`${t('common.options')}:`}</h4>
                        {node.enum.map((x: string, i: number) => {
                            const value = enums.at(i)
                            const remove = () => setState(O.remove(value)(state))
                            return (
                                <Stack direction="row" key={i} sx={{ my: 1 }}>
                                    <TextField
                                        value={O.get(value)(state)}
                                        id="outlined-basic"
                                        label="Name"
                                        variant="outlined"
                                        onChange={(e) => setState(O.set(value)(e.target.value))}
                                    />
                                    <IconButton aria-label="remove" color="error" onClick={remove}>
                                        <Delete />
                                    </IconButton>
                                </Stack>
                            )
                        })}
                        <Button variant="outlined" onClick={append}>
                            {t('common.add')}
                        </Button>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                    </TabPanel>
                </FieldDialog>
            </>
        )
    }

    return (
        <Draggable id={`${path}`} styling={{ alignItems: 'center' }}>
            <FieldItem
                node={node}
                nameValue={nameHook.name}
                hasRules={rule.hasRules}
                isGoldenStandard={nameHook.isGoldenStard}
            />
            <FieldDialog node={node} remove={remove} onSave={rule.onSaveRules}>
                <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                <TabPanel value={tabIndex} index={0}>
                    <NameLabel nameHook={nameHook} fieldType={node.type} />
                    <h4>{t('common.addOptionList')}</h4>
                    <Stack spacing={2} direction="row" sx={{ my: 2 }}>
                        <Autocomplete
                            disablePortal
                            sx={{ width: '100%', maxWidth: 225 }}
                            options={autoCompleteOptions}
                            renderInput={(params) => <TextField {...params} label={t('common.addOptionList')} />}
                            onChange={(_, value) => {
                                const optionListToAppend = prebuiltOptions?.data?.data
                                    .find((list) => list.id === value?.value)
                                    ?.options.map((opt) => opt.label)
                                if (optionListToAppend) {
                                    nameHook.setPrebuiltOptionId(value?.value)
                                    nameHook.setPrebuiltList(optionListToAppend)
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={
                                selectedPrebuiltOptionList
                                    ? { label: selectedPrebuiltOptionList?.name, value: selectedPrebuiltOptionList?.id }
                                    : null
                            }
                            onInputChange={(_e, _v, reason) => {
                                if (reason === 'clear') nameHook.clearPrebuiltList()
                            }}
                        />
                    </Stack>
                    <h4>{`${t('common.options')}:`}</h4>
                    {node.enum.map((x: string, i: number) => {
                        const value = enums.at(i)
                        const remove = () => setState(O.remove(value)(state))
                        return (
                            <Stack direction="row" key={i} sx={{ my: 1 }}>
                                <TextField
                                    value={O.get(value)(state)}
                                    id="outlined-basic"
                                    label="Name"
                                    variant="outlined"
                                    onChange={(e) => setState(O.set(value)(e.target.value))}
                                />
                                <IconButton aria-label="remove" color="error" onClick={remove}>
                                    <Delete />
                                </IconButton>
                            </Stack>
                        )
                    })}
                    <Button variant="outlined" onClick={append}>
                        {t('common.add')}
                    </Button>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                </TabPanel>
            </FieldDialog>
        </Draggable>
    )
}
