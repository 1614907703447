import React from 'react'
import { CreacteProjectProvider } from 'modules/projects/context/CreateProjectContext'
import { ProjectJourney } from 'modules/projects/create/ProjectJourney'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export const ProjectCreate: React.FC = () => {
    const { t } = useTranslation()

    return (
        <>
            <Helmet>{<title>{t('projectPage.createProject')}</title>}</Helmet>
            <CreacteProjectProvider>
                <ProjectJourney />
            </CreacteProjectProvider>
        </>
    )
}
