import React from 'react'
import { Control } from 'react-hook-form'
import { ControlledMuiAutoComplete } from 'components/inputs/ControlledMuiAutoComplete'
import { Building } from 'redux/types/building.type'
import { useLazyGetBuildingsQuery } from 'services/buildings.service'
import { debounce } from 'lodash'
import { Box, Typography, autocompleteClasses } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProjectGeneralInfoRequestForm } from 'modules/projects/create/ProjectDetails'

interface BuildingsAutoCompleteProps {
    control: Control<ProjectGeneralInfoRequestForm>
    defaultValue: Array<Building>
    onChangeCb: (value: Building[]) => void
}

export const BuildingsAutoComplete = ({ control, defaultValue, onChangeCb }: BuildingsAutoCompleteProps) => {
    const { t } = useTranslation()
    const [fetchBuildings, { isFetching, data: buildings }] = useLazyGetBuildingsQuery({})

    const fetchedOnce = React.useRef(false)

    const fetch = React.useMemo(
        () =>
            debounce((searchQuery) => {
                if (searchQuery.length < 2) return

                fetchedOnce.current = true
                fetchBuildings(
                    {
                        page: 0,
                        itemsPerPage: 20,
                        sortBy: 'created',
                        direction: 'desc',
                        searchQuery,
                    },
                    true,
                )
            }, 400),
        [fetchBuildings],
    )

    const handleInputChange = (value: string) => {
        fetch(value)
    }

    return (
        <ControlledMuiAutoComplete
            control={control}
            multiple={true}
            name="buildings"
            options={fetchedOnce.current ? buildings?.data || [] : defaultValue}
            getOptionLabel={(opt) => `${opt.address.addressText}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onInputChange={(event, newInputValue) => handleInputChange(newInputValue)}
            filterOptions={(opt) => opt}
            loading={isFetching}
            onChangeCb={onChangeCb}
            renderOption={(props, option: Building, state) => {
                return (
                    <Box
                        sx={{
                            flexDirection: 'column',
                            margin: '2px 0px',
                            [`&.${autocompleteClasses.option}`]: {
                                padding: '4px',
                                alignItems: 'flex-start',
                            },
                        }}
                        component="li"
                        {...props}
                        key={option.id}
                    >
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                            {option.address.addressText}
                        </Typography>
                        <Typography variant="caption">
                            {`${t('common.cadastreNumber')}: ${option.cadastre?.cleanNumber || '-'}, ${t(
                                'buildingNumber',
                            )}: ${option.buildingNumber || '-'}`}
                        </Typography>
                    </Box>
                )
            }}
        />
    )
}
