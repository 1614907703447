import React from 'react'
import { GridSortModel } from '@mui/x-data-grid'

export const useDataGridFiltersState = (defaultSort: GridSortModel[number]) => {
    const [page, setPage] = React.useState(0)
    const [sortBy, setSortBy] = React.useState<GridSortModel[number] | undefined>(defaultSort)
    const [searchQuery, setSearchQUery] = React.useState('')

    const handlePageChange = React.useCallback((page: number) => {
        setPage(page)
    }, [])

    const handleSortChange = React.useCallback((model: GridSortModel) => {
        if (model.length === 0) {
            return setSortBy(undefined)
        }

        setSortBy({
            field: model[0].field,
            sort: model[0].sort,
        })
    }, [])

    const handleSearchChange = React.useCallback((query: string) => {
        setSearchQUery(query)
    }, [])

    return {
        page,
        sortBy,
        searchQuery,
        handlePageChange,
        handleSortChange,
        handleSearchChange,
    }
}
