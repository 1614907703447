import React from 'react'
import { Edit } from '@mui/icons-material'
import { Project } from 'redux/types/project.type'
import { useCreateProjectContext } from 'modules/projects/context/CreateProjectContext'
import { StyledIconButton } from 'components/projects/EditButton'
import { ProjectDetailsDisplay } from 'components/projects/DetailsDisplay'

interface ProjectDetailsViewModeProps {
    onEditModeChange?: (value: boolean) => void
    currentProject: Project
    completed: boolean
}

export const ProjectDetailsViewMode: React.FC<ProjectDetailsViewModeProps> = ({
    currentProject,
    onEditModeChange,
    completed,
}) => {
    const { selectedTemplate } = useCreateProjectContext()

    return (
        <div>
            {!completed && onEditModeChange && (
                <StyledIconButton onClick={() => onEditModeChange(true)}>
                    <Edit />
                </StyledIconButton>
            )}
            <ProjectDetailsDisplay selectedTemplate={selectedTemplate?.name} project={currentProject} />
        </div>
    )
}
