import React from 'react'
import { Button } from '@mui/material'
import { FormItem } from 'components/buildings/FormItem'
import {
    CustomerTabCard,
    CustomerTabCardButtonContainer,
    CustomerTabCardContent,
} from 'components/customersAdmin/CustomerTabCard'
import { useTranslation } from 'react-i18next'
import { Organization } from 'redux/types/organization.type'
import { valueOrDefaultEmpty } from 'utils/general'
import { EditOrganizationGeneralInfoDialog } from './EditOrganizationGeneralInfoDialog'

type OrganizationGeneralInfoProps = {
    organization: Organization
}

export const OrganizationGeneralInfo: React.FC<OrganizationGeneralInfoProps> = ({ organization }) => {
    const { t } = useTranslation()
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)

    return (
        <>
            <CustomerTabCard>
                <CustomerTabCardContent>
                    <FormItem label={t('customersPage.customersTable.name')}>{organization.name}</FormItem>
                    <FormItem label={t('customersPage.customersTable.organizationNumber')}>
                        {valueOrDefaultEmpty(organization.organizationNumber)}
                    </FormItem>
                    <FormItem label={t('common.email')}>{valueOrDefaultEmpty(organization.email)}</FormItem>
                    <FormItem label={t('common.phoneNumber')}>{valueOrDefaultEmpty(organization.phoneNumber)}</FormItem>
                    <FormItem label={t('common.address')}>
                        {valueOrDefaultEmpty(organization.address?.addressText)}
                    </FormItem>
                    <FormItem label={t('common.postalCode')}>
                        {valueOrDefaultEmpty(organization.address?.postalCode)}
                    </FormItem>
                    <FormItem label={t('common.country')}>
                        {valueOrDefaultEmpty(organization.address?.country)}
                    </FormItem>
                    <FormItem label={t('common.type')}>{t(`common.customerType.${organization.type}`)}</FormItem>
                </CustomerTabCardContent>

                <CustomerTabCardButtonContainer>
                    <Button variant="contained" color="primary" onClick={() => setIsDialogOpen(true)}>
                        {t('common.edit')}
                    </Button>
                </CustomerTabCardButtonContainer>
            </CustomerTabCard>
            <EditOrganizationGeneralInfoDialog
                open={isDialogOpen}
                handleClose={() => setIsDialogOpen(false)}
                organization={organization}
            />
        </>
    )
}
