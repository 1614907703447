import { Stack } from '@mui/material'
import * as O from 'optics-ts'
import React from 'react'
import { RulesEditor } from '../rules/rules-editor'
import { Render } from '../schema-builder'
import { useName, useRule } from '../hooks/schema-hooks'
import { AddFieldDialog } from './add-field-dialog'
import { BuilderFieldProps } from './builder-field-props'
import { Draggable, Droppable } from './droppable'
import { FieldDialog } from './field-dialog'
import { FieldItem } from './field-item'
import { NameLabel } from './general/field-name-label'
import { TabPanel } from './general/field-tab-panel'
import { FieldTabs } from './general/field-tabs'
import { GroupSchemaElement } from 'redux/types/schema.type'

export const BuilderGroupLayout: React.FC<BuilderFieldProps<GroupSchemaElement>> = ({
    node,
    state,
    setState,
    lens,
    path,
}) => {
    const nameHook = useName(lens, state, setState)
    const rule = useRule(lens, state, setState)
    const nodes = lens.prop('nodes')
    const [tabIndex, setTabIndex] = React.useState(0)

    const prevName = React.useRef<string>(nameHook.name as string)

    const remove = () => setState(O.remove(lens)(state))

    const handleGroupSave = () => {
        rule.onSaveRules({ isGroup: true, newGroupName: nameHook.name as string, oldGroupname: prevName.current })
        prevName.current = nameHook.name as string
    }

    return (
        <Draggable id={`${path}`}>
            <Stack sx={{ border: '1px dashed #666' }} width="100%">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FieldItem node={node} nameValue={nameHook.name} />
                    <FieldDialog node={node} remove={remove} onSave={handleGroupSave}>
                        <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                        <TabPanel value={tabIndex} index={0}>
                            <NameLabel nameHook={nameHook} fieldType={node.type} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1}>
                            <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                        </TabPanel>
                    </FieldDialog>
                </div>
                <Droppable id={`${path}.nodes.${0}`} />
                {node.nodes?.map((x: any, i: number) => {
                    const nodeLens = nodes.at(i)
                    return (
                        <React.Fragment key={i}>
                            <Render state={state} setState={setState} lens={nodeLens} path={`${path}.nodes.${i}`} />
                            <Droppable id={`${path}.nodes.${i + 1}`} />
                        </React.Fragment>
                    )
                })}
                <AddFieldDialog nodesLens={nodes} setState={setState} parentField="Group" />
            </Stack>
        </Draggable>
    )
}
