import { StringParam, useQueryParams } from 'use-query-params'

export const useProjectQueryParams = () => {
    const [search, setSearch] = useQueryParams({
        accessToken: StringParam,
        structureId: StringParam,
        revision: StringParam,
    })

    return [search, setSearch] as const
}
