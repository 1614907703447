import React from 'react'
import { Card, CircularProgress, FormControlLabel, Switch, styled } from '@mui/material'
import { useUpdateProjectFormMutation } from 'services/projects.service'
import { JsonFormsCore } from '@jsonforms/core'
import { useGetSchemaQuery } from 'services/schemas.service'
import { PrebuiltFormNavigationControlls } from './PrebuiltFormNavigationControlls'
import { PrebuiltFormActions } from './PrebuiltFormActions'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { RegisteredJsonForm } from 'components/general/jsonForm/RegisteredJsonForm'
import { getAdjacentIds } from 'utils/forms-utils'
import { DraftRequestBuilder } from 'utils/project-draft-utils'
import { PrebuiltFormNotAssigned } from './PrebuiltFormNotAssigned'
import { useCreateProjectContext } from 'modules/projects/context/CreateProjectContext'
import { PrebuiltFormStatic } from './PrebuiltFormStatic'
import { useProjectQueryParams } from '../hooks/useProjectQueryParams'

export const PrebuiltForm: React.FC = () => {
    const { t } = useTranslation()
    const [{ structureId }, setSearch] = useProjectQueryParams()
    const { project } = useCreateProjectContext()
    const { normalizedStructure, selectedTemplate: currentTemplate } = useCreateProjectContext()

    const currentSchema =
        structureId && normalizedStructure?.entities ? normalizedStructure.entities[structureId] : null
    const schemaId = currentSchema && currentSchema.schemaId ? currentSchema.schemaId : undefined
    const isStaticSchema = currentSchema?.staticType !== null
    const { data: schema, isFetching: isSchemaLoading } = useGetSchemaQuery(schemaId!, {
        skip: !schemaId || isStaticSchema,
    })

    const [updateForm, { isLoading: isSubmitting }] = useUpdateProjectFormMutation()

    const [formValues, setFormValues] = React.useState<JsonFormsCore['data']>()
    const [formErrors, setFormErrors] = React.useState<JsonFormsCore['errors']>([])
    const [isFormDisabled, setIsFormDisabled] = React.useState(false)

    const requestBuilder = React.useMemo(
        () => new DraftRequestBuilder(normalizedStructure, project),
        [project, normalizedStructure],
    )
    const adjacentNodeIds = getAdjacentIds(structureId || '', normalizedStructure?.result)

    React.useEffect(() => {
        const formDisabled = project?.formState?.disabledSchemas.findIndex((nodeId) => nodeId === structureId)
        setIsFormDisabled(formDisabled !== -1)

        // should only run if structureId changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [structureId])

    const handleFormChange = ({ data, errors }: Pick<JsonFormsCore, 'data' | 'errors'>) => {
        setFormValues(data)
        setFormErrors(errors)
    }

    const handleFinishChapter = async () => {
        if (!project || (formErrors && formErrors.length > 0) || !normalizedStructure || !structureId || !schema) return

        const finishChapterRequest = requestBuilder.buildRequest({
            type: 'finish-schema',
            currentSchema: schema,
            formValues: formValues,
            structureId,
        })

        try {
            await updateForm(finishChapterRequest).unwrap()

            // last schema, user can finish the draft now
            if (!project.formState) return
            if (
                finishChapterRequest.completeSchemas?.length ===
                normalizedStructure.result.length -
                    (finishChapterRequest.disabledSchemas?.length || 0) -
                    project.formState.unassignedSchemas.length
            ) {
                toast.success(t('projectPage.customFormComplete'))
                return
            }

            if (adjacentNodeIds.next) {
                setSearch({
                    structureId: adjacentNodeIds.next,
                })
                return
            }
        } catch (e) {}
    }

    const handleSaveDraft = async () => {
        if (!project || !normalizedStructure || !structureId || !schema) return

        const projectRequest = requestBuilder.buildRequest({
            type: 'save-draft',
            formValues: formValues,
            structureId,
        })

        await updateForm(projectRequest)
    }

    const handleDisableSchema = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!project || !structureId || !normalizedStructure || !schema || isSubmitting) return

        const projectRequest = requestBuilder.buildRequest({
            type: event.target.checked ? 'disable' : 'enable',
            structureId,
        })
        try {
            await updateForm(projectRequest).unwrap()
            setIsFormDisabled((prev) => !prev)
        } catch {}
    }

    const handleDisableStaticSchema = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!project || !structureId || !normalizedStructure || isSubmitting) return

        const projectRequest = requestBuilder.buildRequest({
            type: event.target.checked ? 'disable' : 'enable-static',
            structureId,
            staticFormType: currentSchema!.staticType!,
        })
        try {
            await updateForm(projectRequest).unwrap()
            setIsFormDisabled((prev) => !prev)
        } catch {}
    }

    const handleNavigateToNextStructure = (direction: 'next' | 'prev') => {
        const structureId = direction === 'next' ? adjacentNodeIds.next : adjacentNodeIds.prev
        if (!structureId) return
        setSearch({
            structureId,
        })
    }

    // url has no structureid, wait till its set in effect
    if (!structureId || !currentSchema) return null

    // structure has no schema
    if (!schemaId) {
        return (
            <PrebuiltFormNotAssigned
                title={normalizedStructure?.entities?.[structureId]?.label}
                handleNavigateToNextStructure={handleNavigateToNextStructure}
                prevNode={adjacentNodeIds.prev}
                nextNode={adjacentNodeIds.next}
                isFormDisabled={isFormDisabled}
                handleDisableSchema={handleDisableSchema}
            />
        )
    }

    if (isStaticSchema) {
        return (
            <PrebuiltFormStatic
                title={normalizedStructure?.entities?.[structureId]?.label}
                handleNavigateToNextStructure={handleNavigateToNextStructure}
                prevNode={adjacentNodeIds.prev}
                nextNode={adjacentNodeIds.next}
                isFormDisabled={isFormDisabled}
                handleDisableSchema={handleDisableStaticSchema}
                staticType={currentSchema.staticType!}
            />
        )
    }

    return (
        <Card sx={{ padding: 2, height: '100%', overflow: 'auto' }} elevation={1}>
            {normalizedStructure && (
                <PrebuiltFormNavigationControlls
                    title={normalizedStructure.entities?.[structureId]?.label}
                    prevNode={adjacentNodeIds.prev}
                    nextNode={adjacentNodeIds.next}
                    handleNavigateToNextStructure={handleNavigateToNextStructure}
                />
            )}
            {isSchemaLoading && (
                <CardLoaderContainer>
                    <CircularProgress />
                </CardLoaderContainer>
            )}
            {!isSchemaLoading && schema && (
                <JsonFormContainer>
                    <FormControlLabel
                        control={<Switch onChange={handleDisableSchema} color="success" checked={isFormDisabled} />}
                        label={t('projectPage.disableSchema')}
                        sx={(theme) => ({ paddingLeft: theme.spacing(2) })}
                    />

                    <RegisteredJsonForm
                        data={
                            project?.formState?.answers?.[structureId] ||
                            currentTemplate?.template?.[structureId] ||
                            undefined
                        }
                        onChange={handleFormChange}
                        schema={schema.jsonSchema}
                        uischema={schema.uiSchema}
                        readonly={isFormDisabled}
                    />
                    <PrebuiltFormActions
                        isSubmitting={isSubmitting}
                        onSaveDraft={handleSaveDraft}
                        onSubmit={handleFinishChapter}
                        isFormDisabled={isFormDisabled}
                    />
                    {formErrors?.length ? (
                        <ErrorContainer>
                            {formErrors.map((err) => (
                                <li key={err.keyword}>
                                    <pre>{JSON.stringify(err, null, 2)}</pre>
                                </li>
                            ))}
                        </ErrorContainer>
                    ) : null}
                </JsonFormContainer>
            )}
        </Card>
    )
}

const ErrorContainer = styled('ol')({
    marginTop: '32px',
})

const CardLoaderContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
})

const JsonFormContainer = styled('div')({
    '& > div': {
        boxShadow: 'none',
    },

    '& .MuiPaper-root': {
        height: 'unset',
        maxHeight: 'none',
    },
})
