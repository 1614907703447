import { createSlice } from '@reduxjs/toolkit'
import { formstructuresApi } from 'services/formStructures.service'
import { FormStructureState } from 'redux/types/formStructure.type'
import { NormalizationUtils } from 'utils/normalization-utils'

const initialStructureState: FormStructureState = {
    currentViewedStructure: null,
    normalizedStructure: null,
    normalizedIds: [],
}

const structureSlice = createSlice({
    name: 'structures',
    initialState: initialStructureState,
    reducers: {
        clearStructure: (state: FormStructureState) => {
            state.normalizedStructure = initialStructureState.normalizedStructure
            state.currentViewedStructure = initialStructureState.currentViewedStructure
            state.normalizedIds = initialStructureState.normalizedIds
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(formstructuresApi.endpoints.getFormStructure.matchFulfilled, (state, { payload }) => {
            const normalized = NormalizationUtils.NormalizeNestedData(payload.sections, 'sectionId')

            if (normalized) {
                state.currentViewedStructure = payload
                state.normalizedStructure = normalized.entities
                state.normalizedIds = normalized.result
            }
        })
    },
})

export default structureSlice.reducer
export const { clearStructure } = structureSlice.actions
