import { appApi } from 'redux/api'
import { PublicSettings, TenantSettings } from 'redux/types/settings.type'

export const settingsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getTenantSettings: builder.query<TenantSettings, void>({
            query: () => ({
                url: '/v1/settings/tenant-settings',
                method: 'GET',
            }),
            providesTags: ['TenantSettings'],
            // 1 hour
            keepUnusedDataFor: 3600,
        }),
        getPublicSettings: builder.query<PublicSettings, void>({
            query: () => ({
                url: '/v1/settings/public-filters',
                method: 'GET',
            }),
            providesTags: ['PublicSettings'],
            // 1 hour
            keepUnusedDataFor: 3600,
        }),
    }),
})

export const { useGetTenantSettingsQuery, useGetPublicSettingsQuery } = settingsApi
