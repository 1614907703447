import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Button, Typography, styled } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface PrebuiltFormNavigationControllsProps {
    title?: string
    prevNode: string | null
    nextNode: string | null
    handleNavigateToNextStructure: (direction: 'next' | 'prev') => void
}

export const PrebuiltFormNavigationControlls: React.FC<PrebuiltFormNavigationControllsProps> = ({
    title,
    prevNode,
    nextNode,
    handleNavigateToNextStructure,
}) => {
    const { t } = useTranslation()

    return (
        <Container>
            <StyledBtn
                variant="outlined"
                startIcon={<ChevronLeft />}
                disabled={!prevNode}
                onClick={() => handleNavigateToNextStructure('prev')}
            >
                {t('common.previous')}
            </StyledBtn>
            {title && (
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                    {title}
                </Typography>
            )}
            <StyledBtn
                variant="outlined"
                endIcon={<ChevronRight />}
                disabled={!nextNode}
                onClick={() => handleNavigateToNextStructure('next')}
            >
                {t('common.next')}
            </StyledBtn>
        </Container>
    )
}

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& h6': {
            order: -1,
            textAlign: 'center',
        },
        gap: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
}))

const StyledBtn = styled(Button)(({ theme }) => ({
    minWidth: 100,
    height: 30,
    textTransform: 'none',
    background: '#FFF',
    color: '#000',
    border: '1px solid #727272',

    '&:hover': {
        background: theme.palette.primary.main,
        color: '#FFF',
    },
}))
