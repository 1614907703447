import { CheckBox, CheckBoxOutlineBlank, Download, FileCopy } from '@mui/icons-material'
import { IconButton, Typography, styled } from '@mui/material'
import { ReactComponent as XlsxIcon } from 'assets/icons/xlsx.svg'
import { ReactComponent as XlsIcon } from 'assets/icons/xls.svg'
import { ReactComponent as PdfIcon } from 'assets/icons/pdf.svg'
import { ReactComponent as WordIcon } from 'assets/icons/word.svg'
import { ReactComponent as DwgIcon } from 'assets/icons/dwg.svg'
import { MIME_TYPES } from 'constants/util'
import { MyFileEntity } from 'redux/types/file.type'
import { useTranslation } from 'react-i18next'
import { Config } from 'redux/config'
import { StyledAnchor } from 'components/general/links/styles'

interface FileListItemProps {
    file: MyFileEntity
    isCustomerView?: boolean
}

export const IconFromMimeType: React.FC<{ mimeType: string }> = ({ mimeType }) => {
    switch (mimeType) {
        case MIME_TYPES.doc: {
            return <WordIcon />
        }
        case MIME_TYPES.docx: {
            return <WordIcon />
        }
        case MIME_TYPES.dwg: {
            return <DwgIcon />
        }
        case MIME_TYPES.pdf: {
            return <PdfIcon />
        }
        case MIME_TYPES.xls: {
            return <XlsIcon />
        }
        case MIME_TYPES.xlsx: {
            return <XlsxIcon />
        }
    }

    return <FileCopy />
}

export const FileListItem: React.FC<FileListItemProps> = ({ file, isCustomerView }) => {
    const { t } = useTranslation()
    return (
        <Container>
            <LeftContainer>
                <IconFromMimeType mimeType={file.mimeType} />
                <Typography variant="body2" sx={{ fontWeight: 400, marginLeft: 2 }}>
                    <StyledAnchor href={`${Config.backendUrl}/v1/files/${file.id}?accessToken=${file.accessToken}`}>
                        {file.filename}
                    </StyledAnchor>
                </Typography>
                {file.projectTag && (
                    <Typography variant="body2" sx={{ marginLeft: 2, marginRight: 2 }}>
                        {file.projectTag.label}
                    </Typography>
                )}
                {file?.isInternal !== undefined && !isCustomerView && (
                    <>
                        {file.isInternal ? (
                            <CheckBox color="primary" fontSize="small" />
                        ) : (
                            <CheckBoxOutlineBlank color="primary" fontSize="small" />
                        )}
                        <Typography variant="body2" sx={{ marginLeft: 0.5, marginRight: 2 }}>
                            {t('common.isInternal')}
                        </Typography>
                    </>
                )}
                {file?.fileDescription && <Typography variant="body2">{file.fileDescription}</Typography>}
            </LeftContainer>
            <div>
                <IconButton
                    component="a"
                    href={`${Config.backendUrl}/v1/files/${file.id}?accessToken=${file.accessToken}`}
                >
                    <Download />
                </IconButton>
            </div>
        </Container>
    )
}

const Container = styled('div')(({ theme }) => ({
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 2),
    justifyContent: 'space-between',

    '& svg:first-of-type': {
        width: '100%',
        height: '100%',
        maxWidth: 32,
        maxHeight: 32,
    },
}))

const LeftContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
})
