import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { CircularProgress, styled } from '@mui/material'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

export const Transition = React.forwardRef(
    (
        props: TransitionProps & {
            children: React.ReactElement<any, any>
        },
        ref: React.Ref<unknown>,
    ) => <Slide direction="up" ref={ref} {...props} />,
)

export interface ModalWrapperProps extends Omit<DialogProps, 'title'> {
    open: boolean
    handleClose: () => void
    title?: React.ReactNode
    body?: React.ReactNode
    actions?: React.ReactNode
    isLoading?: boolean
}

const ModalsWrapper: React.FC<ModalWrapperProps> = ({
    open,
    handleClose,
    title,
    body,
    actions,
    isLoading,
    ...rest
}) => (
    <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={(_event, reason) => {
            if (reason && reason === 'backdropClick') return
            handleClose()
        }}
        PaperProps={{
            style: {
                borderRadius: 15,
            },
        }}
        {...rest}
    >
        <CloseWrapper>
            <IconButton onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </CloseWrapper>
        {title && (
            <DialogTitle variant="body1" sx={{ textAlign: 'center' }}>
                {title}
            </DialogTitle>
        )}
        <DialogContent sx={{ pb: 0, overflowX: 'hidden', minHeight: 200, position: 'relative' }}>
            {isLoading ? (
                <LoadingContainer>
                    <CircularProgress color="primary" />
                </LoadingContainer>
            ) : (
                body
            )}
        </DialogContent>
        {actions && !isLoading && <DialogActions sx={{ pt: 0 }}>{actions}</DialogActions>}
    </Dialog>
)

const CloseWrapper = styled('div')({
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    zIndex: 1,
})

const LoadingContainer = styled('div')({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
})

export default ModalsWrapper
