import { ROUTES } from 'constants/routes'
import { OrganizationPage } from 'pages/organizations/organization.page'
import { OrganizationsPage } from 'pages/organizations/organizations.page'
import { generateNestedRouteUrl } from 'utils/navigation-utils'

const organizationRoutes = [
    {
        path: '',
        element: <OrganizationsPage />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.organization, ROUTES.organizations),
        element: <OrganizationPage />,
    },
]

export default organizationRoutes
