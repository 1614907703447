import { useAppSelector } from 'redux/hooks'
import { IOption } from 'components/inputs/ControlledMuiSelect'
import {
    selectProjectClassOptions,
    selectRegulationOptions,
    selectStructureTypeOptions,
} from 'redux/selectors/settings.selectors'
import React from 'react'
import { useCreateProjectContext } from '../context/CreateProjectContext'

export const useGetCreateProjectFormOptions = (
    selectedStructureType: string,
    selectedStructure: string,
    selectedManager: string,
    selectedQA: string,
) => {
    const { structures, users, templates } = useCreateProjectContext()
    const availableStructureTypes = useAppSelector(selectStructureTypeOptions)
    const availableRegulations = useAppSelector(selectRegulationOptions)
    const availableClasses = useAppSelector(selectProjectClassOptions)

    const availableStructureOptions = React.useMemo<IOption[]>(() => {
        const selectedType = availableStructureTypes.find((type) => type.value === selectedStructureType)?.value

        if (!selectedType) return []
        return (structures || [])
            .filter((form) => form.type.value === selectedType)
            .map((form) => ({
                label: form.name,
                value: form.id,
            }))
    }, [availableStructureTypes, selectedStructureType, structures])

    const availableUserOptions = React.useMemo<IOption[]>(
        () =>
            (users || []).map((user) => ({
                // TODO remove the user.email later, because name is now mandatory for user
                label: user.fullName || user.email,
                value: user.id,
            })),
        [users],
    )

    const availableTemplates = React.useMemo<IOption[]>(() => {
        const selectedType = availableStructureTypes.find((type) => type.value === selectedStructureType)?.value
        if (!selectedStructure) return []

        return (templates || [])
            .filter((template) => template.structureType === selectedType && template.structureId === selectedStructure)
            .map((template) => ({
                label: template.name,
                value: template.id,
            }))
    }, [availableStructureTypes, selectedStructure, templates, selectedStructureType])

    const availableQAOptions = React.useMemo<IOption[]>(() => {
        if (!selectedManager) return availableUserOptions
        return availableUserOptions.filter((opt) => opt.value !== selectedManager)
    }, [availableUserOptions, selectedManager])

    const availableManagerOptions = React.useMemo<IOption[]>(() => {
        if (!selectedQA) return availableUserOptions
        return availableUserOptions.filter((opt) => opt.value !== selectedQA)
    }, [availableUserOptions, selectedQA])

    return {
        structureOptions: availableStructureOptions,
        structureTypeOptions: availableStructureTypes,
        regulationOptions: availableRegulations,
        templateOptions: availableTemplates,
        QAOptions: availableQAOptions,
        managerOptions: availableManagerOptions,
        classesOptions: availableClasses,
    }
}
