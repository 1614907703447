import React from 'react'
import { Box, Hidden, IconButton, SwipeableDrawer, styled } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { PrebuiltFormNavigationFilterable } from './PrebuiltFormNavigationFilterable'
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from 'constants/styles'
import { useProjectQueryParams } from '../hooks/useProjectQueryParams'

type PrebuiltFormNavigationFilterableMobileProps = {
    isNavigationDrawerOpen: boolean
    onDrawerToggle: (open: boolean) => void
}

export const PrebuiltFormNavigationFilterableMobile: React.FC<PrebuiltFormNavigationFilterableMobileProps> = ({
    onDrawerToggle,
    isNavigationDrawerOpen,
}) => {
    const [search] = useProjectQueryParams()

    React.useEffect(() => {
        if (!isNavigationDrawerOpen) return
        // introduce short delay after search query change to close the drawer
        const timeoutId = setTimeout(() => onDrawerToggle(false), 200)

        return () => clearTimeout(timeoutId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    return (
        <Hidden mdUp>
            <SwipeableDrawer
                anchor="left"
                open={isNavigationDrawerOpen}
                onClose={() => onDrawerToggle(false)}
                onOpen={() => onDrawerToggle(true)}
                variant="temporary"
                swipeAreaWidth={50}
                SwipeAreaProps={{
                    sx: { marginTop: `${HEADER_HEIGHT}px` },
                }}
            >
                <Box sx={{ overflow: 'auto', width: '100%', maxWidth: 400, position: 'relative' }}>
                    <HeaderContainer>
                        <IconButton
                            size="large"
                            edge="start"
                            color="primary"
                            sx={{ mr: 'auto', ml: '-5px' }}
                            onClick={() => onDrawerToggle(false)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </HeaderContainer>
                    <PrebuiltFormNavigationFilterable />
                </Box>
            </SwipeableDrawer>
        </Hidden>
    )
}

const HeaderContainer = styled('div')(({ theme }) => ({
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2.5),

    [theme.breakpoints.down('sm')]: {
        height: HEADER_HEIGHT_MOBILE,
    },
}))
