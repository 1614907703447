import React from 'react'
import { TextItem } from './TextItem'
import { useTranslation } from 'react-i18next'
import { JSONFormViewComp, SchemaElementTypeMap } from 'redux/types/schemaView.type'

export const BoolView: React.FC<JSONFormViewComp<SchemaElementTypeMap['boolean']>> = React.memo(
    ({ node, direction = 'row' }) => {
        const { t } = useTranslation()

        if (!node.isVisible || !node.isVisibleInWeb || node.value === undefined) return null

        return (
            <TextItem
                direction={direction}
                label={node.label}
                value={node.value === true ? t('common.yes') : t('common.no')}
                isLabelVisible={node.isLabelVisible}
            />
        )
    },
)
