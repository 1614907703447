import { Card, Stack } from '@mui/material'
import React from 'react'
import { Render } from '../schema-builder'
import { AddFieldDialog } from './add-field-dialog'
import { BuilderFieldProps } from './builder-field-props'
import { Droppable } from './droppable'
import { RootSchemaElement } from 'redux/types/schema.type'

export const BuilderRoot: React.FC<BuilderFieldProps<RootSchemaElement>> = ({ node, state, setState, lens, path }) => {
    const nodes = lens.prop('nodes')

    return (
        <Card>
            <Stack p={2}>
                <Droppable id={`${path}.nodes.${0}`} />
                {node.nodes?.map((x: any, i: number) => {
                    const nodeLens = nodes.at(i)
                    return (
                        <React.Fragment key={i}>
                            <Render state={state} setState={setState} lens={nodeLens} path={`${path}.nodes.${i}`} />
                            <Droppable id={`${path}.nodes.${i + 1}`} />
                        </React.Fragment>
                    )
                })}
                <AddFieldDialog nodesLens={nodes} setState={setState} />
            </Stack>
        </Card>
    )
}
