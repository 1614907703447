import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { PageLoader } from 'components/general/loaders/PageLoader'
import { UnauthorizedTopBar } from 'components/general/top-bar/UnauthorizedTopBar'
import { HeaderButton } from 'components/general/buttons/HeaderButton'
import { useTranslation } from 'react-i18next'
import { ProjectReadView } from 'modules/projects/view/ReadView'
import { useProjectView } from 'modules/projects/hooks/useProjectView'
import { Helmet } from 'react-helmet'
import { FileService } from 'services/file.service'
import { toast } from 'react-toastify'
import { PdfViewDialog } from 'components/general/dialogs/PdfViewDialog'
import { ReadViewArchived } from 'modules/projects/view/ReadViewARchived'
import { PageContainer } from 'components/general/containers/PageContainer'
import { Config } from 'redux/config'
import { LoadingButton } from '@mui/lab'
import { ProjectMobileActions } from 'components/projects/ProjectMobileActions'
import { HeaderBackButton } from 'components/general/navigation/HeaderBackButton'

export const ProjectCustomerView: React.FC = () => {
    const { t } = useTranslation()
    const { isLoading, project, isError } = useProjectView()
    const { state: routeState } = useLocation()

    const [report, setReport] = React.useState<string | null>(null)
    const [isDownloading, setIsDownloading] = React.useState(false)
    const [isFormNavigationDrawerOpen, setIsFormNavigationDrawerOpen] = React.useState(false)

    const reportRef = React.useRef<string | null>(null)

    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    const shouldRenderPdfAnchor = isMobileDevice || navigator.pdfViewerEnabled === false

    const handlePdfDownload = async () => {
        if (!project?.report) return

        if (reportRef.current) {
            setReport(reportRef.current)
            return
        }

        setIsDownloading(true)
        try {
            const url = await FileService.DownloadAndCreateBlobUrl(project.report.id, project.report.accessToken)

            setReport(url)
            reportRef.current = url
        } catch (err: any) {
            toast.error(err?.translationKey ? t(err.translationKey) : t('genericErrorMessage'))
        }
        setIsDownloading(false)
    }

    const handleToggleDrawer = (open: boolean) => {
        setIsFormNavigationDrawerOpen(open)
    }

    if (isLoading) {
        return <PageLoader />
    }

    if (isError) {
        return <Navigate to="/" replace />
    }

    if (!project) return null

    return (
        <>
            <Helmet>
                {project && <title>{`${project.type?.label} - ${project.buildings[0].address.addressText}`}</title>}
            </Helmet>
            <UnauthorizedTopBar
                preLogoActions={
                    project.creationType === 'ByDefaultFlow' ? (
                        <ProjectMobileActions onOpenNavigationDrawer={() => handleToggleDrawer(true)} />
                    ) : undefined
                }
                leftActions={
                    routeState?.from ? <HeaderBackButton label={t('common.back')} href={routeState.from} /> : undefined
                }
                rightActions={
                    project.creationType === 'ByDefaultFlow'
                        ? [
                              shouldRenderPdfAnchor ? (
                                  <LoadingButton
                                      component="a"
                                      variant="outlined"
                                      href={`${Config.backendUrl}/v1/files/${project.report?.id}?accessToken=${project.report?.accessToken}`}
                                      target="_blank"
                                      sx={(theme) => ({
                                          minWidth: 140,
                                          borderColor: theme.general.gray,
                                          color: theme.general.gray,
                                          '&:hover': {
                                              background: theme.palette.primary.light,
                                          },
                                          '&:disabled': {
                                              borderColor: theme.palette.primary.light,
                                          },
                                          '& .MuiLoadingButton-loadingIndicator': {
                                              color: theme.palette.secondary.main,
                                          },
                                      })}
                                  >
                                      {t('projectPage.customerViewPdf')}
                                  </LoadingButton>
                              ) : (
                                  <HeaderButton
                                      label={t('projectPage.customerViewPdf')}
                                      onClick={handlePdfDownload}
                                      isLoading={isDownloading}
                                  />
                              ),
                          ]
                        : []
                }
                tenantId={project.tenantId}
            />
            <PageContainer>
                {project?.creationType === 'ByDefaultFlow' ? (
                    <ProjectReadView
                        isNavigationDrawerOpen={isFormNavigationDrawerOpen}
                        onDrawerToggle={handleToggleDrawer}
                        isNotificationHidden={true}
                    />
                ) : (
                    <ReadViewArchived />
                )}
            </PageContainer>
            <PdfViewDialog open={report !== null} handleClose={() => setReport(null)} url={report} />
        </>
    )
}
