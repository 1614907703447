import { Building } from 'redux/types/building.type'
import { OrganizationBuildingPermission } from 'redux/types/organization.type'
import * as yup from 'yup'

export type CreateUpdateOrganizationPermissionForm = {
    building: Building | null
    permissionType: OrganizationBuildingPermission
}

export const organizationPermissionSchema = yup.object().shape({
    building: yup.object().required('required'),
    permissionType: yup.string().trim().required('required'),
})
