import { MoreVert } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'

type DataGridActionsCellProps = {
    onOpen: (event: React.MouseEvent<HTMLElement>, rowId: string) => void
    rowId: string
}

export const DataGridActionsCell: React.FC<DataGridActionsCellProps> = React.memo(({ onOpen, rowId }) => {
    return (
        <IconButton onClick={(e) => onOpen(e, rowId)}>
            <MoreVert />
        </IconButton>
    )
})
