import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    LinearProgress,
    Tab,
    Tabs,
    styled,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider, Path, useWatch } from 'react-hook-form'
import {
    projectGeneralInfoDraftSchema,
    projectGeneralInfoFinishSchemaByDefault,
    projectGeneralInfoFinishSchemaByUpload,
} from 'schemas/projects'
import { Building } from 'redux/types/building.type'
import { ProjectDetailsActions } from './ProjectDetailsActions'
import { Project, ProjectGoldenStandardValue, UpdateProjectGeneralInfoRequest } from 'redux/types/project.type'
import { useUpdateFilesMetaDataMutation } from 'services/fileRTK.service'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import { ProjectDetailsTab } from './ProjectDetailsTab'
import { ProjectDocumentsTab } from './ProjectDocumentsTab'
import { ProjectBuildingsInfoTab } from './ProjectBuildingsInfoTab'
import { Customer } from 'redux/types/customer.type'
import { PROJECT_NO_EDIT_ACTIONS } from 'constants/util'
import { useUpdateProjectGeneralInfoMutation, useUpdateProjectStatusMutation } from 'services/projects.service'
import { MyFileEntity, ProjectFileWithMetaData, UpdateFilesMetaDataRequest } from 'redux/types/file.type'
import { ROUTES } from 'constants/routes'
import { LoadingButton } from '@mui/lab'
import { useAppSelector } from 'redux/hooks'
import { selectAuthToken } from 'redux/selectors/auth.selectors'
import { FileService } from 'services/file.service'
import { toast } from 'react-toastify'
import { ValidationError } from 'yup'
import { useCreateProjectContext } from 'modules/projects/context/CreateProjectContext'
import { useProjectQueryParams } from '../hooks/useProjectQueryParams'
import { ProjectGoldenStandardTab } from './ProjectGoldenStandardTab'

export enum EProjectTabs {
    ProjectInfo = 'ProjectInfo',
    Documents = 'Documents',
    Buildings = 'Buildings',
    GoldenStandards = 'GoldenStandards',
}

const ADD_PROJECT_TABS = [
    {
        label: 'projectPage.tabs.projectData',
        value: EProjectTabs.ProjectInfo,
        defaultTranslation: 'Project data',
    },
    {
        label: 'projectPage.tabs.documents',
        value: EProjectTabs.Documents,
        defaultTranslation: 'Documents',
    },
    {
        label: 'projectPage.tabs.buildingInfo',
        value: EProjectTabs.Buildings,
        defaultTranslation: 'Building information',
        renderAfterCreateMode: true,
    },
    {
        label: 'projectPage.tabs.goldenStandards',
        value: EProjectTabs.GoldenStandards,
        defaultTranslation: 'Golden standards',
    },
]

type ProjectGoldenStandardFormValue = Omit<ProjectGoldenStandardValue, 'name'> & {
    name: string | null
    fieldValueType: 'boolean' | 'string' | 'number'
}

export type ProjectGeneralInfoRequestForm = {
    buildings: Building[]
    customer: Customer | null
    isCreateByUpload: boolean
    files: ProjectFileWithMetaData[] | MyFileEntity[]
    goldenStandards?: ProjectGoldenStandardFormValue[]
} & Omit<UpdateProjectGeneralInfoRequest, 'id' | 'buildingIds' | 'customerId' | 'creationType' | 'goldenStandards'>

const getStandardValueType = (
    standard: ProjectGoldenStandardValue,
): ProjectGoldenStandardFormValue['fieldValueType'] => {
    if (standard.stringValue !== undefined && standard.stringValue !== null) return 'string'
    else if (standard.numberValue !== undefined && standard.numberValue !== null) return 'number'
    return 'boolean'
}

const fillDefaultValues = (project?: Project): ProjectGeneralInfoRequestForm => {
    if (!project) return {} as ProjectGeneralInfoRequestForm

    return {
        buildings: project.buildings,
        projectClass: project.projectClass?.value || 'empty',
        comment: project.comment,
        customer: project.customer,
        internalReferenceId: project.internalReferenceId,
        isCreateByUpload: project.creationType === 'ByDocumentUpload',
        name: project.name || '',
        projectManagerId: project.projectManager?.id || null,
        publishDate: project.publishDate,
        qualityAssuranceId: project.qualityAssurance?.id || null,
        regulation: project.regulation?.id || null,
        regulationDate: project.regulation?.date || null,
        responsableCompany: project.responsableCompany,
        structureId: project.structure?.id || null,
        templateId: project.templateId,
        type: project.type?.value || '',
        files: project.files,
        goldenStandards: project?.goldenStandards.map((standard) => ({
            ...standard,
            fieldValueType: getStandardValueType(standard),
        })),
        buildingFloors: project.buildingFloors || [],
    }
}

export const ProjectDetails: React.FC<{ isArchivedProject: boolean }> = ({ isArchivedProject }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [, setSearch] = useProjectQueryParams()

    const { project, structures } = useCreateProjectContext()

    const authToken = useAppSelector(selectAuthToken)

    const [updateStatus, { isLoading: isSubmittigStatusUpdate }] = useUpdateProjectStatusMutation()
    const [updateFilesMetaData, { isLoading: isUpdatingFiles }] = useUpdateFilesMetaDataMutation()

    const [updateInformation, { isLoading: isSubmitting }] = useUpdateProjectGeneralInfoMutation()

    const methods = useForm<ProjectGeneralInfoRequestForm>({
        mode: 'onSubmit',
        defaultValues: fillDefaultValues(project),
        shouldFocusError: true,
        resolver: (values, context, options) =>
            yupResolver(
                values.isCreateByUpload
                    ? projectGeneralInfoFinishSchemaByUpload
                    : projectGeneralInfoFinishSchemaByDefault,
            )(values, context, options),
    })

    const {
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
        getValues,
        control,
    } = methods

    const isCreateByUpload = useWatch({ control, name: 'isCreateByUpload' })

    const [isEditMode, setIsEditMode] = React.useState(false)
    const [activeTab, setActiveTab] = React.useState<EProjectTabs>(EProjectTabs.ProjectInfo)
    const [errorTabs, setErrorTabs] = React.useState<null | Record<EProjectTabs, true>>(null)
    const [expanded, setExpanded] = React.useState<boolean>(true)
    const [fileUploadProgress, setFileUploadProgress] = React.useState(0)

    const isActionsVisible =
        project?.status === 'Created' || isEditMode || project?.status === 'FillingGeneralInformation'
    const isFilled = Boolean(project && PROJECT_NO_EDIT_ACTIONS.includes(project.status))
    const canFinishArchivedProject =
        !isActionsVisible &&
        isArchivedProject &&
        (project?.status === 'WaitingForConfirmation' || project?.status === 'MinorIssueFix')
    const isLoading = isSubmitting || isSubmittigStatusUpdate || isUpdatingFiles || fileUploadProgress > 0

    React.useEffect(() => {
        let errorKeys = Object.keys(errors)

        if (errorKeys.length === 0) {
            setErrorTabs(null)
            return
        }

        const newErrorTabs = {} as Record<EProjectTabs, true>

        if ('files' in errors) {
            newErrorTabs[EProjectTabs.Documents] = true
            errorKeys = errorKeys.filter((err) => err !== 'files')
        }
        if ('goldenStandards' in errors) {
            newErrorTabs[EProjectTabs.GoldenStandards] = true
            errorKeys = errorKeys.filter((err) => err !== 'goldenStandards')
        }

        // we have more errors so that means its in project info tab
        if (errorKeys.length !== 0) {
            newErrorTabs[EProjectTabs.ProjectInfo] = true
        }
        window.scrollTo(0, 0)
        setErrorTabs(newErrorTabs)
    }, [errors])

    const handleFileUpload = async (files: ProjectGeneralInfoRequestForm['files']) => {
        if (!files || files.length === 0) return

        const tempFileUpload = new FormData()
        const fileMetaDataUpdateRequest: UpdateFilesMetaDataRequest = {
            files: [],
        }

        let index = 0
        // check if meta data was changed for already uploaded files
        for (const file of files) {
            if ('id' in file) {
                const oldUploadedFile = project?.files.find((projectFile) => projectFile.id === file.id)

                if (oldUploadedFile) {
                    const wasInternalValueChaned =
                        (file.isInternal !== undefined || file.isInternal !== null) &&
                        file.isInternal !== oldUploadedFile.isInternal
                    const wasTagValueChanged = Boolean(
                        file.projectTag && file.projectTag.value !== oldUploadedFile.projectTag?.value,
                    )
                    const wasDescriptionChanged = Boolean(
                        file.fileDescription && file.fileDescription !== oldUploadedFile.fileDescription,
                    )

                    if (wasInternalValueChaned || wasTagValueChanged || wasDescriptionChanged) {
                        fileMetaDataUpdateRequest.files.push({
                            id: file.id,
                            metaData: {
                                isInternal: file.isInternal,
                                projectTag: file.projectTag?.value,
                                fileDescription: file.fileDescription,
                            },
                        })
                    }
                }
                continue
            }

            tempFileUpload.append(`file_${index}`, file.file)
            tempFileUpload.append(
                `metadata_${index}`,
                JSON.stringify({
                    projectTag: file.projectTag,
                    isInternal: file.isInternal,
                    fileDescription: file.fileDescription,
                    relatedFloors: file.relatedFloors,
                }),
            )

            index++
        }

        try {
            if (fileMetaDataUpdateRequest.files.length) {
                await updateFilesMetaData(fileMetaDataUpdateRequest).unwrap()
            }
            // check if has formData value
            if (!tempFileUpload.entries().next().done) {
                const uploadedFiles = await FileService.uploadProjectFiles(
                    tempFileUpload,
                    project!.id,
                    authToken!,
                    (fileUploadProgress) => setFileUploadProgress(fileUploadProgress),
                )
                // sync form with uploaded files
                const newFormFiles = []
                for (let i = 0; i < files.length; i++) {
                    const formFile = files[i]
                    if ('file' in formFile) {
                        const foundFileIdx = uploadedFiles.findIndex((uf) => uf.filename === formFile.file.name)

                        if (foundFileIdx !== -1) newFormFiles.push(uploadedFiles[foundFileIdx])
                    } else {
                        newFormFiles.push(formFile)
                    }
                }
                methods.setValue('files', newFormFiles)
                setFileUploadProgress(0)
                return uploadedFiles
            }
        } catch (err) {
            setFileUploadProgress(0)
            if (typeof err === 'object' && err !== null && 'statusText' in err) {
                toast.error(t(err.statusText as string) || t('genericErrorMessage'))
            }
        }
    }

    const getFormattedUpdateRequest = (data: ProjectGeneralInfoRequestForm): UpdateProjectGeneralInfoRequest => {
        const { files, ...rest } = data
        const request: UpdateProjectGeneralInfoRequest = {
            ...rest,
            id: project!.id,
            customerId: data.customer?.id || null,
            buildingIds: data.buildings.map((b) => b.id),

            templateId: data.templateId || null,
            creationType: data.isCreateByUpload ? 'ByDocumentUpload' : 'ByDefaultFlow',
            projectClass: data.projectClass === 'empty' ? null : data.projectClass,
            publishDate: data.isCreateByUpload ? data.publishDate : null,
            structureId: data.isCreateByUpload ? null : data.structureId,
            goldenStandards: data.isCreateByUpload
                ? data.goldenStandards?.map(({ name, booleanValue, numberValue, stringValue }) => ({
                      name: name!,
                      booleanValue,
                      numberValue,
                      stringValue,
                  }))
                : undefined,
        }
        return request
    }

    const onSubmit = async (data: ProjectGeneralInfoRequestForm) => {
        if (!project) return

        const request = getFormattedUpdateRequest(data)

        try {
            await handleFileUpload(data.files)
            await updateInformation(request).unwrap()

            if (data.isCreateByUpload && project.status !== 'MinorIssueFix') {
                await updateStatus({ id: project!.id, status: 'WaitingForQa' }).unwrap()
                navigate(ROUTES.dashboard)
                return
            }

            if (project.status === 'Created' || project.status === 'FillingGeneralInformation') {
                await updateStatus({ id: project.id, status: 'FillingBuiltForm' }).unwrap()
            }

            if (project.structure && data.structureId !== project.structure.id) {
                const newRootSchemaId = structures?.find((structure) => structure.id === data.structureId)?.sections[0]
                    .sectionId
                setSearch({ structureId: newRootSchemaId }, 'replace')
            }

            if (isEditMode) {
                setIsEditMode(false)
            }
        } catch {}
    }

    const handleFinishProject = async () => {
        if (!project) return

        try {
            await updateStatus({ id: project.id, status: 'Complete' }).unwrap()

            navigate(ROUTES.dashboard)
        } catch (e) {}
    }

    const handleSendToReview = async () => {
        if (!project) return

        try {
            await updateStatus({ id: project.id, status: 'WaitingForQa' }).unwrap()

            navigate(ROUTES.dashboard)
        } catch (e) {}
    }

    const handleSaveDraft = async () => {
        clearErrors()
        const formValues = getValues()

        try {
            await projectGeneralInfoDraftSchema.validate(formValues, { abortEarly: false })
            const request = getFormattedUpdateRequest(formValues)

            if (project?.status === 'Created') {
                await updateStatus({ id: project.id, status: 'FillingGeneralInformation' }).unwrap()
            }

            await handleFileUpload(formValues.files)
            await updateInformation(request).unwrap()
            toast.success(t('common.successfull'))
        } catch (error) {
            if (error instanceof ValidationError) {
                error.inner?.map((inner, index) => {
                    const { type, path, errors } = inner
                    return setError(path as Path<ProjectGeneralInfoRequestForm>, { type, message: errors[0] })
                })
            }
        }
    }

    if (!project) return null

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Accordion expanded={expanded}>
                        <StyledAccordionSummary
                            expandIcon={
                                <IconButton onClick={() => setExpanded((prev) => !prev)}>
                                    <ExpandMoreIcon />
                                </IconButton>
                            }
                        >
                            <Tabs value={activeTab} onChange={(_, newTab) => setActiveTab(newTab)}>
                                {ADD_PROJECT_TABS.map((tab) => {
                                    if (tab.renderAfterCreateMode && project.status === 'Created') return null
                                    if (tab.value === EProjectTabs.GoldenStandards && !isCreateByUpload) return null
                                    return (
                                        <Tab
                                            key={tab.value}
                                            value={tab.value}
                                            label={t(tab.label, tab.defaultTranslation)}
                                            sx={(theme) => ({
                                                color:
                                                    errorTabs && errorTabs[tab.value]
                                                        ? `${theme.palette.error.main} !important`
                                                        : theme.palette.primary.main,
                                            })}
                                        />
                                    )
                                })}
                            </Tabs>
                        </StyledAccordionSummary>
                        <AccordionDetails>
                            <Box px={4} py={2} position="relative">
                                {activeTab === EProjectTabs.ProjectInfo && (
                                    <ProjectDetailsTab
                                        // on other statuses we redirect to different routes
                                        editable={
                                            project.status !== 'Created' &&
                                            project.status !== 'FillingGeneralInformation'
                                        }
                                        isEditMode={isEditMode}
                                        onEditModeChange={(newMode) => setIsEditMode(newMode)}
                                        currentProject={project}
                                        completed={isFilled}
                                    />
                                )}
                                {activeTab === EProjectTabs.Documents && (
                                    <ProjectDocumentsTab
                                        editable={
                                            project.status !== 'Created' &&
                                            project.status !== 'FillingGeneralInformation'
                                        }
                                        isEditMode={isEditMode}
                                        onEditModeChange={(newMode) => setIsEditMode(newMode)}
                                        completed={isFilled}
                                        currentFiles={project.files}
                                        name={project.name}
                                        currentBuildings={project.buildings}
                                    />
                                )}
                                {activeTab === EProjectTabs.Buildings && (
                                    <ProjectBuildingsInfoTab currentBuilding={project.buildings?.[0]} />
                                )}
                                {activeTab === EProjectTabs.GoldenStandards && (
                                    <ProjectGoldenStandardTab
                                        editable={
                                            project.status !== 'Created' &&
                                            project.status !== 'FillingGeneralInformation'
                                        }
                                        isEditMode={isEditMode}
                                        onEditModeChange={(newMode) => setIsEditMode(newMode)}
                                        completed={isFilled}
                                        projectGoldenStandards={project.goldenStandards}
                                    />
                                )}
                                {isActionsVisible && fileUploadProgress > 0 && (
                                    <LinearProgress variant="determinate" value={fileUploadProgress} color="primary" />
                                )}
                                {isActionsVisible && (
                                    <ProjectDetailsActions
                                        onCancel={() => navigate(ROUTES.dashboard)}
                                        isSubmitting={isLoading}
                                        onSaveDraft={handleSaveDraft}
                                        isDraftBtnVisible={
                                            project.status === 'Created' ||
                                            project.status === 'FillingGeneralInformation'
                                        }
                                    />
                                )}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </form>
            </FormProvider>
            {canFinishArchivedProject && (
                <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
                    {project.status === 'MinorIssueFix' && (
                        <LoadingButton
                            type="button"
                            variant="outlined"
                            color="secondary"
                            onClick={handleSendToReview}
                            loading={isSubmittigStatusUpdate}
                            sx={{ mr: 2 }}
                        >
                            {t('projectPage.sendToReview')}
                        </LoadingButton>
                    )}
                    <LoadingButton
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={handleFinishProject}
                        loading={isSubmittigStatusUpdate}
                    >
                        {t('projectPage.finishProject')}
                    </LoadingButton>
                </Box>
            )}
        </>
    )
}

const StyledAccordionSummary = styled(AccordionSummary)({
    '& .MuiAccordionSummary-content.Mui-expanded': {
        marginBottom: 0,
    },
    '& .MuiAccordionSummary-content': {
        cursor: 'default',
    },
})
