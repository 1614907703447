import { withJsonFormsControlProps } from '@jsonforms/react';
import { UploadRenderer } from './upload-renderer'

type UploadControlProps = {
    data: any,
    handleChange(path: string, value: any): void;
    path: string;
}
const UploadControlPresentation: React.FC<UploadControlProps> = ({data, handleChange, path}) => {
    return <UploadRenderer value={data} updateValue={(newValue: any) => handleChange(path, newValue)} />
}

export const UploadControl =  withJsonFormsControlProps(UploadControlPresentation);