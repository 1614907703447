import { appApi } from '../redux/api'
import { FireDepartmentBase, FireStationBase, GetFireDepartmentsRequest } from 'redux/types/fireDepartment.type'

export const fireDepartmentsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getDepartments: builder.query<FireDepartmentBase[], GetFireDepartmentsRequest>({
            query: (params) => ({
                url: '/v1/fireDepartments',
                method: 'GET',
                params,
            }),
            providesTags: ['Projects'],
        }),
        getDepartmentStations: builder.query<FireStationBase[], { departmentId: string }>({
            query: (params) => ({
                url: `/v1/fireDepartments/${params.departmentId}/stations`,
                method: 'GET',
            }),
            providesTags: ['Projects'],
        }),
    }),
})

export const { useGetDepartmentsQuery, useGetDepartmentStationsQuery, useLazyGetDepartmentStationsQuery } =
    fireDepartmentsApi
