import React from 'react'
import { Button, Stack, styled } from '@mui/material'

import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreateTemplateRequest } from 'redux/types/template.type'
import { addTemplateSchema } from 'schemas/addTemplate'
import { useGetFormStructuresQuery } from 'services/formStructures.service'
import { MAX_RECORDS_QUERY } from 'constants/pagination'
import ControlledMuiSelect from 'components/inputs/ControlledMuiSelect'
import { useCreateTemplateMutation } from 'services/templates.service'
import { LoadingButton } from '@mui/lab'

const DEFAULT_VALUES: CreateTemplateRequest = {
    name: '',
    structureId: '',
}

interface CreateOptionListDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions' | 'open'> {
    isOpen: boolean
}

export const CreateTemplateDialog: React.FC<CreateOptionListDialogProps> = ({ isOpen, handleClose }) => {
    const { t } = useTranslation()

    const [createTemplate, { isLoading: isCreating }] = useCreateTemplateMutation()
    const { structureOptions, isLoading } = useGetFormStructuresQuery(MAX_RECORDS_QUERY, {
        selectFromResult: ({ data, isLoading }) => ({
            structureOptions: (data?.data || []).map((form) => ({
                label: form.name,
                value: form.id,
            })),
            isLoading,
        }),
    })

    const { handleSubmit, control } = useForm<CreateTemplateRequest>({
        defaultValues: DEFAULT_VALUES,
        resolver: yupResolver(addTemplateSchema),
    })

    const onSubmit = async (values: CreateTemplateRequest) => {
        await createTemplate(values).unwrap()

        handleClose()
    }

    return (
        <ModalsWrapper
            sx={{
                zIndex: 9999,
            }}
            open={isOpen}
            title={t('templatesPage.addTemplate')}
            handleClose={handleClose}
            fullWidth={true}
            maxWidth={'sm'}
            isLoading={isLoading}
            body={
                <form id="create-template-form" onSubmit={handleSubmit(onSubmit)}>
                    <ContentWrapper>
                        <ControlledMuiTextField
                            control={control}
                            fullWidth
                            name="name"
                            variant="filled"
                            placeholder={t('templatesPage.templateNamePlaceholder')}
                        />
                        <ControlledMuiSelect
                            name="structureId"
                            defaultValue=""
                            control={control}
                            options={structureOptions}
                            variant="filled"
                            label={t('templatesPage.templateStructurePlaceholder')}
                            fullWidth
                        />
                    </ContentWrapper>
                </form>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button variant="outlined" onClick={handleClose} color="secondary" disabled={isCreating}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="create-template-form"
                        disabled={isCreating}
                        loading={isCreating}
                    >
                        {t('common.confirm')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 3),
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
