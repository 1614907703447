import { useTranslation } from 'react-i18next'
import { useForm, useFieldArray, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CustomerOrOrganizationForm, companyCustomerFormSchema, personCustomerFormSchema } from 'schemas/customers'
import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import React from 'react'
import { ControlledMuiSwitch } from 'components/inputs/ControlledMuiSwitch'
import { ContactFormPart } from 'components/customersAdmin/ContactFormPart'
import { DetailsFormPart } from 'components/customersAdmin/DetailsFormPart'
import { OrganizationAutoComplete } from './OrganizationAutoComplete'
import { Organization } from 'redux/types/organization.type'
import { CustomerAutoComplete } from 'components/organizations/CustomerAutoComplete'
import { Customer } from 'redux/types/customer.type'

const DEFAULT_VALUES: CustomerOrOrganizationForm = {
    address: {
        addressText: null,
        country: null,
        postalCode: null,
    },
    contacts: [],
    email: null,
    name: '',
    organizationNumber: null,
    phoneNumber: null,
    isPrivatePerson: false,
    organizationImport: null,
    customerImport: null,
}

interface CustomerFormProps {
    onSubmit: (values: CustomerOrOrganizationForm) => void
    formId: string
    title: string
    isImportFromOrganizationsInputVisible?: boolean
    isImportFromCustomersInputVisible?: boolean
}

// This form is reused for creating organizations
// because they are basicly same entity
export const CustomerForm: React.FC<CustomerFormProps> = ({
    onSubmit,
    formId,
    title,
    isImportFromOrganizationsInputVisible = false,
    isImportFromCustomersInputVisible = false,
}) => {
    const { t } = useTranslation()

    const { handleSubmit, control, reset } = useForm<CustomerOrOrganizationForm>({
        defaultValues: DEFAULT_VALUES,
        shouldFocusError: true,
        mode: 'onSubmit',
        shouldUnregister: true,
        resolver: (values, context, options) =>
            yupResolver(values.isPrivatePerson ? personCustomerFormSchema : companyCustomerFormSchema)(
                values,
                context,
                options,
            ),
    })

    const isPrivatePerson = useWatch({ control, name: 'isPrivatePerson' })

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'contacts',
    })

    const addNewContact = () => {
        append({ description: null, email: '', name: '', phoneNumber: null })
    }

    const removeContact = (childIndex: number) => {
        remove(childIndex)
    }

    const handleChangeCustomerType = (isPrivate: boolean) => {
        if (isPrivate && fields.length === 0) addNewContact()
    }

    const handleFillFromOrganization = (org: Organization) => {
        if (org === null) return

        const newFormValues: CustomerOrOrganizationForm = {
            ...org,
            organizationImport: org,
            customerImport: null,
            isPrivatePerson: org.type === 'PrivatePerson',
            contacts: org.contacts.map((contact) => ({
                ...contact,
                description: contact.description || null,
                phoneNumber: contact.phoneNumber || null,
            })),
        }

        reset(newFormValues)
    }

    const handleFillFromCustomer = (customer: Customer) => {
        if (customer === null) return

        const newFormValues: CustomerOrOrganizationForm = {
            ...customer,
            customerImport: customer,
            organizationImport: null,
            isPrivatePerson: customer.type === 'PrivatePerson',
            contacts: customer.contacts.map((contact) => ({
                ...contact,
                description: contact.description || null,
                phoneNumber: contact.phoneNumber || null,
            })),
        }

        reset(newFormValues)
    }

    return (
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            {isImportFromOrganizationsInputVisible && (
                <Box mt={2}>
                    <Typography>{t('customerPage.importFromOrganizations')}</Typography>
                    <OrganizationAutoComplete control={control} onChange={handleFillFromOrganization} />
                </Box>
            )}
            {isImportFromCustomersInputVisible && (
                <Box mt={2}>
                    <Typography>{t('customerPage.importFromCustomer')}</Typography>
                    <CustomerAutoComplete control={control} onChange={handleFillFromCustomer} />
                </Box>
            )}
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <ControlledMuiSwitch
                        control={control}
                        name="isPrivatePerson"
                        label={t('common.isPrivatePerson')}
                        onChangeCb={handleChangeCustomerType}
                    />
                    <Divider />
                </Grid>

                <DetailsFormPart title={title} control={control} isPrivatePerson={isPrivatePerson} />

                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">{t('customersPage.customerDialog.contacts')}</Typography>
                    <div>
                        {fields.map((field, index) => (
                            <ContactFormPart
                                key={field.id}
                                control={control}
                                index={index}
                                removeContact={removeContact}
                                isRemoveContactDisabled={isPrivatePerson && fields.length <= 1}
                            />
                        ))}
                        <Button sx={{ mt: 2 }} endIcon={<Add />} type="button" onClick={addNewContact}>
                            {t('customersPage.customerDialog.addContact')}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    )
}
