import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { authReducer } from './slices/auth.slice'
import tenantsReducer from './slices/tenant.slice'
import structureReducer from './slices/structure.slice'
import { appApi } from './api'
import { rtkQueryErrorLogger } from './middleware/errorHandler'

const persistConfig = {
    key: 'strgi_auth',
    storage,
}

const persistedAuthReducer = persistReducer(persistConfig, authReducer)

export const store = configureStore({
    reducer: {
        [appApi.reducerPath]: appApi.reducer,
        auth: persistedAuthReducer,
        tenants: tenantsReducer,
        structures: structureReducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat([appApi.middleware])
            // .concat(logger) // uncomment if you want action logging
            .concat(rtkQueryErrorLogger),
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
