import React from 'react'
import { Card, SortDirection } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DataGrid, GridColDef, GridRowHeightParams, GridSortItem, GridSortModel } from '@mui/x-data-grid'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { useDeleteProjectMutation, useGetProjectsQuery } from 'services/projects.service'
import { DATE_LOCALE_OPTIONS } from 'constants/locales'
import { ProjectWithoutForms } from 'redux/types/project.type'
import { DataGridNoRows } from 'components/general/table/DataGridNoRows'
import { DataGridLinkCell } from 'components/general/table/DataGridLinkCell'
import { navigationLinks } from 'utils/navigation-utils'
import { PROJECT_CAN_DELETE, PROJECT_NO_EDIT_ACTIONS } from 'constants/util'
import { ROUTES } from 'constants/routes'
import { dateOrDefaultEmpty, valueOrDefaultEmpty } from 'utils/general'
import { ActionsMenu } from './ActionsMenu'
import DeleteDialog from 'components/general/dialogs/DeleteDialog'
import { DataGridActionsCell } from 'components/general/table/DataGridActionsCell'
import { ProjectBuildingListLinkCell } from 'components/general/table/ProjectBuildingListCell'
import { ProjectStatusCell } from 'components/projects/ProjectStatusCell'
import { DataGridTitle } from 'components/general/table/DataGridTitle'
import { TableActionMenuProps } from 'types/util'

export type TenantProjectsTableProps = {
    filter: {
        searchQuery: string
        page: number
        sortBy: GridSortItem | undefined
    }
    actions: {
        onPageChange: (page: number) => void
        onSortChange: (model: GridSortModel) => void
    }
}

export const TenantProjectsTable: React.FC<TenantProjectsTableProps> = ({ filter, actions }) => {
    const { t } = useTranslation()

    const { data: projects, isFetching } = useGetProjectsQuery({
        page: filter.page || 0,
        itemsPerPage: DEFAULT_ROWS_PER_PAGE,
        direction: filter.sortBy?.sort as SortDirection,
        sortBy: filter.sortBy?.field,
        searchQuery: filter.searchQuery || undefined,
    })
    const [deleteProject, { isLoading: isDeleting }] = useDeleteProjectMutation()

    const [anchorElProps, setAnchorPropsEl] = React.useState<TableActionMenuProps>(null)
    const [selectedProjectIdToDelete, setSelectedProjectIdToDelete] = React.useState<string | null>(null)

    const openActionMenu = React.useCallback((event: React.MouseEvent<HTMLElement>, rowId: string) => {
        setAnchorPropsEl((prev) => (prev ? null : { anchorEl: event.currentTarget, rowId }))
    }, [])

    const closeActionMenu = () => setAnchorPropsEl(null)

    const handleDelete = async () => {
        setSelectedProjectIdToDelete(null)
        await deleteProject({ id: selectedProjectIdToDelete! })
    }

    const columns: GridColDef<ProjectWithoutForms>[] = [
        {
            field: 'readableUniqueIdentifier',
            headerName: t('dashboard.projectNumber'),
            flex: 1,
            editable: false,
            sortable: false,
            valueGetter: (params) => valueOrDefaultEmpty(params.row.readableUniqueIdentifier),
            width: 150,
            minWidth: 150,
        },
        {
            field: 'name',
            headerName: t('dashboard.projectName'),
            flex: 1,
            editable: false,
            minWidth: 200,
            renderCell: (params) => {
                let link
                const state = {
                    from: ROUTES.projects,
                }

                if (PROJECT_NO_EDIT_ACTIONS.includes(params.row.status))
                    link = navigationLinks.toReviewProject(params.row.id)
                else link = navigationLinks.toCreateProject(params.row.id)

                return (
                    <DataGridLinkCell
                        label={params.row.name || t('common.noProjectNamePlaceholder')}
                        to={link}
                        state={state}
                    />
                )
            },
        },
        {
            field: 'type',
            headerName: t('dashboard.projectType'),
            flex: 1,
            editable: false,
            minWidth: 160,
            valueGetter: (params) => valueOrDefaultEmpty(params.row.type?.label),
        },
        {
            field: 'projectManagerId',
            headerName: t('dashboard.projectManager'),
            flex: 1,
            editable: false,
            minWidth: 220,
            sortable: false,
            valueGetter: (params) => valueOrDefaultEmpty(params.row.projectManager?.fullName),
        },
        {
            field: 'buildings',
            headerName: t('dashboard.projectBuildings'),
            flex: 1,
            editable: false,
            minWidth: 300,
            width: 300,
            sortable: false,
            renderCell: (params) => <ProjectBuildingListLinkCell buildings={params.row.buildings} />,
        },
        {
            field: 'status',
            headerName: t('dashboard.projectStatus'),
            flex: 1,
            editable: false,
            width: 170,
            minWidth: 170,
            renderCell: (params) => <ProjectStatusCell status={params.row.status} />,
        },
        {
            field: 'publishDate',
            headerName: t('dashboard.projectPublishDate'),
            flex: 1,
            editable: false,
            width: 110,
            minWidth: 110,
            valueGetter: (params) =>
                dateOrDefaultEmpty(params.row.publishDate, (value) =>
                    new Date(value).toLocaleDateString('no', DATE_LOCALE_OPTIONS['no']),
                ),
        },
        {
            field: 'updated',
            headerName: t('projectsTable.updatedHeader'),
            flex: 1,
            editable: false,
            width: 110,
            minWidth: 110,
            valueGetter: (params) => new Date(params.row.updated).toLocaleDateString('no', DATE_LOCALE_OPTIONS['no']),
        },
        {
            field: '',
            maxWidth: 50,
            filterable: false,
            sortable: false,
            editable: false,
            renderCell: (params) => {
                // for now not showing action cell at all because
                // it only has 1 action
                if (!PROJECT_CAN_DELETE.includes(params.row.status)) return null
                return <DataGridActionsCell onOpen={openActionMenu} rowId={params.row.id} />
            },
        },
    ]

    return (
        <>
            <Card sx={{ minHeight: '400px', padding: 2.5 }} elevation={1}>
                <DataGrid
                    sx={{
                        '& .MuiDataGrid-columnHeader': {
                            textTransform: 'capitalize',
                        },
                    }}
                    autoHeight
                    rows={projects?.data || []}
                    columns={columns}
                    rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                    pageSize={DEFAULT_ROWS_PER_PAGE}
                    rowCount={projects?.total || 0}
                    isRowSelectable={() => false}
                    disableColumnMenu
                    loading={isFetching}
                    hideFooterSelectedRowCount
                    components={{
                        NoRowsOverlay: DataGridNoRows,
                        Toolbar: DataGridTitle,
                    }}
                    componentsProps={{
                        toolbar: { title: t('projectsPage.currentProjects') },
                    }}
                    paginationMode="server"
                    sortingMode="server"
                    onPageChange={actions.onPageChange}
                    onSortModelChange={actions.onSortChange}
                    page={filter.page}
                    sortModel={filter.sortBy ? [{ field: filter.sortBy.field, sort: filter.sortBy.sort }] : []}
                    getRowHeight={({ model }: GridRowHeightParams) => {
                        if (model.buildings.length > 1) return 'auto'

                        return null
                    }}
                />
            </Card>
            <ActionsMenu
                anchorEl={anchorElProps?.anchorEl}
                id={anchorElProps?.rowId}
                onClose={closeActionMenu}
                onDeleteAction={(id) => {
                    closeActionMenu()
                    setSelectedProjectIdToDelete(id)
                }}
            />
            <DeleteDialog
                open={Boolean(selectedProjectIdToDelete)}
                handleClose={() => setSelectedProjectIdToDelete(null)}
                deleteAction={handleDelete}
                isDeleting={isDeleting}
            />
        </>
    )
}
