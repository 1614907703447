import { IconButton, Typography, styled } from '@mui/material'

export const FloorContainer = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1rem 6rem 8rem 8rem 5rem 10rem 4rem 5rem auto',
    width: '100%',
    gap: theme.spacing(2.5),
    alignItems: 'center',
    marginTop: theme.spacing(2.5),
    overflow: 'auto',

    '& label': {
        marginRight: 0,
    },

    '& .floor-header:nth-of-type(7)': {
        justifySelf: 'flex-start',
    },

    [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1rem 5rem 6rem 6rem 4rem 8rem 3rem 4rem minmax(100px, auto)',
        gap: theme.spacing(1),
    },
}))

export const DisplayFloorContainer = styled(FloorContainer)(({ theme }) => ({
    gridTemplateColumns: 'auto 4rem 5rem auto 5rem auto',
    padding: theme.spacing(0, 1),
    overflow: 'auto',

    [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: 'minmax(100px, auto) 4rem 5rem minmax(100px, auto) 5rem minmax(100px, auto)',
        gap: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
}))

export const FloorColumnText = styled(Typography)({
    fontWeight: 700,
})

export const DeleteBtn = styled(IconButton)({
    padding: 0,
})
