import { Box, Card, CircularProgress, Divider, Grid, styled } from '@mui/material'
import React from 'react'

type CustomerTabCardProps = {
    children: React.ReactNode
    isLoading?: boolean
}

export const CustomerTabCard: React.FC<CustomerTabCardProps> = ({ children, isLoading }) => {
    return (
        <Card elevation={1} sx={{ mt: 2, minHeight: 400, position: 'relative' }}>
            {isLoading ? (
                <LoadingContainer>
                    <CircularProgress />
                </LoadingContainer>
            ) : (
                children
            )}
        </Card>
    )
}

type CustomerTabCardContentProps = {
    children: React.ReactNode
}

export const CustomerTabCardContent: React.FC<CustomerTabCardContentProps> = ({ children }) => {
    return (
        <Grid sx={{ padding: 4 }} container alignItems="center" columnSpacing={1} rowSpacing={1.25}>
            {children}
        </Grid>
    )
}

type CustomerTabCardButtonContainerProps = {
    children: React.ReactNode
}

export const CustomerTabCardButtonContainer: React.FC<CustomerTabCardButtonContainerProps> = ({ children }) => {
    return (
        <>
            <Divider />
            <Box display="flex" justifyContent="flex-end" sx={{ padding: 2 }}>
                {children}
            </Box>
        </>
    )
}

const LoadingContainer = styled('div')({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
})
