import React from 'react'
import { Outlet } from 'react-router-dom'
import { PageContentWrapper } from 'components/general/styles'
import { Footer } from 'components/general/footer'
import PublicRoute from 'router/PublicRoute'

export const PublicEmpty: React.FC = React.memo(() => {
    return (
        <PublicRoute>
            <PageContentWrapper>
                <Outlet />
                <Footer />
            </PageContentWrapper>
        </PublicRoute>
    )
})
