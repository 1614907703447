import React from 'react'
import { useParams } from 'react-router'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { clearStructure } from 'redux/slices/structure.slice'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TopBar } from 'components/general/top-bar/top-bar'
import { useGetFormStructureQuery } from 'services/formStructures.service'
import { useGetTemplateQuery } from 'services/templates.service'
import { PageLoader } from 'components/general/loaders/PageLoader'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { selectNormalizedStructure } from 'redux/selectors/structure.selectors'
import { ROUTES } from 'constants/routes'
import { StaticSchemaAssigned } from 'modules/settings/templates/StaticSchemaAssigned'
import { NoSchemaAssigned } from 'modules/settings/templates/NoSchemaAssigned'
import { TemplateEditForm } from 'modules/settings/templates/Form'
import { useProjectQueryParams } from 'modules/projects/hooks/useProjectQueryParams'
import { HeaderButton } from 'components/general/buttons/HeaderButton'
import { FileService } from 'services/file.service'
import { selectAuthToken } from 'redux/selectors/auth.selectors'
import { PdfViewDialog } from 'components/general/dialogs/PdfViewDialog'
import { useGetStructureSchemasQuery } from 'services/schemas.service'
import { FormViewState } from 'redux/types/schemaView.type'
import { buildViewSchemaFromForm } from 'utils/schema-to-view-utils'
import { PageContainer } from 'components/general/containers/PageContainer'
import { HeaderBackButton } from 'components/general/navigation/HeaderBackButton'

export type TemplateEditParams = {
    templateId: string
}

export const TemplateEditPage: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { templateId } = useParams<keyof TemplateEditParams>() as TemplateEditParams
    const [search, setSearch] = useProjectQueryParams()
    const { structure: normalizedStructure, list: structureNodeIds } = useAppSelector(selectNormalizedStructure)
    const token = useAppSelector(selectAuthToken)

    const { data: template, isFetching: isLoadingTemplate } = useGetTemplateQuery({ id: templateId })
    const { isLoading: isLoadingStructure } = useGetFormStructureQuery(template?.structureId ?? skipToken, {
        refetchOnMountOrArgChange: true,
    })

    const { data: allStructureSchemas, isLoading: isLoadingAllSchemas } = useGetStructureSchemasQuery(
        template?.structureId ? { structureId: template.structureId } : skipToken,
    )

    const [isDownloading, setIsDownloading] = React.useState(false)
    const [preview, setPreview] = React.useState<string | null>(null)

    const currentStructure = search.structureId ? normalizedStructure?.[search.structureId] : null
    const isLoading = isLoadingTemplate || isLoadingStructure || isLoadingAllSchemas
    const schemaId = currentStructure?.schemaId ? currentStructure.schemaId : undefined
    const isStaticSchema = currentStructure?.staticType !== null
    const currentSchema =
        isStaticSchema || !allStructureSchemas ? null : allStructureSchemas?.find((schema) => schema.id === schemaId)

    React.useEffect(() => {
        return () => {
            dispatch(clearStructure())
        }
    }, [dispatch])

    const navigateToNextForm = () => {
        const currentStructureIdx = structureNodeIds.findIndex((id) => id === search.structureId)
        const nextStructure = structureNodeIds[currentStructureIdx + 1]

        // was last schema return to tables
        if (!nextStructure) {
            navigate(ROUTES.schemaTemplates)
        } else {
            setSearch({
                structureId: nextStructure,
            })
        }
    }

    const handleDownloadPreview = async () => {
        if (!token) throw new Error('Not authenticated')
        if (!template?.template) return
        setIsDownloading(true)
        const mockFormViewState: Record<string, FormViewState> = {}

        for (const sectionKey in normalizedStructure) {
            const staticType = normalizedStructure[sectionKey].staticType
            if (staticType) {
                mockFormViewState[sectionKey] = {
                    elements: [],
                    staticType: staticType,
                    type: 'Group',
                }
                continue
            }
            if (sectionKey in template.template) {
                const schema = allStructureSchemas?.find(
                    (schema) => schema.id === normalizedStructure[sectionKey].schemaId,
                )
                if (!schema) continue
                mockFormViewState[sectionKey] = buildViewSchemaFromForm(schema, template.template[sectionKey])
            }
        }
        try {
            const url = await FileService.DownloadProjectPdfInProgress(templateId, token, mockFormViewState)
            setPreview(url)
        } catch (err: any) {
            toast.error(err?.translationKey ? t(err.translationKey) : t('genericErrorMessage'))
        }
        setIsDownloading(false)
    }

    if (isLoading || !template) {
        return <PageLoader />
    }

    return (
        <>
            <TopBar
                leftActions={
                    <HeaderBackButton
                        label={`${template.structureName} - ${template.name}`}
                        href={ROUTES.schemaTemplates}
                    />
                }
                rightActions={[
                    <HeaderButton
                        label={t('templateEditPage.previewPdf')}
                        onClick={handleDownloadPreview}
                        isLoading={isDownloading}
                    />,
                ]}
            />
            <PageContainer>
                {isStaticSchema && <StaticSchemaAssigned navigateToNextForm={navigateToNextForm} />}
                {!schemaId && <NoSchemaAssigned navigateToNextForm={navigateToNextForm} />}
                {currentSchema && (
                    <TemplateEditForm
                        template={template}
                        normalizedStructure={normalizedStructure}
                        schema={currentSchema}
                        navigateToNextForm={navigateToNextForm}
                    />
                )}
            </PageContainer>
            <PdfViewDialog open={preview !== null} handleClose={() => setPreview(null)} url={preview} />
        </>
    )
}
