import { BaseEntity } from 'components/general/models/base-entity'
import { DeepNullable, ObjectValues } from 'types/util'
import { Address, Contact } from './customer.type'
import { DefaultFilter, PaginationParams } from 'components/general/models/pagination-params'
import { Cadastre, Address as BuildingAddress } from './building.type'

export const ORGANIZATION_PERMISSIONS_FOR_BUILDING = {
    Owner: 'Owner',
    FacilityManager: 'FacilityManager',
    Other: 'Other',
} as const

export const ORGANIZATION_AND_CUSTOMER_TYPES = {
    PrivatePerson: 'PrivatePerson',
    Company: 'Company',
} as const

export type OrganizationBuildingPermission = ObjectValues<typeof ORGANIZATION_PERMISSIONS_FOR_BUILDING>
export type OrganizationAndCustomerType = ObjectValues<typeof ORGANIZATION_AND_CUSTOMER_TYPES>

export type Organization = {
    name: string
    email: string | null
    organizationNumber: string | null
    phoneNumber: string | null
    // for now they are same as in customer
    contacts: Contact[]
    address: DeepNullable<Address>
    buildings: OrganizationBuilding[]
    type: OrganizationAndCustomerType
} & BaseEntity

export type OrganizationBuilding = {
    id: string
    buildingId: string
    name: string
    cadastre: Cadastre
    address: BuildingAddress
    type: OrganizationBuildingPermission
}

export type BuildingPermission = { buildingId: string; type: OrganizationBuildingPermission }
export type CreateOrganizationRequest = Omit<Organization, 'id' | 'created' | 'updated' | 'buildings'>

export type UpdateOrganizationRequest = {
    id: string
    organization: CreateOrganizationRequest
}

export type CrudOrganizationBuildingRequest = {
    id: string
    permission: {
        buildingId: string
        type: OrganizationBuildingPermission
        recordId?: string
    }
}

export type OrganizationPaginationParams = PaginationParams<
    Organization,
    DefaultFilter<Organization> & { searchQuery?: string }
>
