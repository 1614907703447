import { JsonSchema4 } from '@jsonforms/core'
import { SchemaElement } from 'redux/types/schema.type'

const flatten = (
    acc: Record<string, JsonSchema4>,
    name: string | undefined,
    newNode: JsonSchema4,
): Record<string, JsonSchema4> => {
    if (name === undefined) {
        acc = { ...acc, ...newNode.properties }
    } else {
        acc[name] = newNode
    }
    return acc
}

const filterNodes = (nodes: SchemaElement[]) => {
    return nodes.filter((x) => x.type !== 'Label')
}

const getRequiredFields = (nodes: SchemaElement[]) => {
    return nodes.filter((x) => 'required' in x && x.required === true).map((x) => ('name' in x ? x.name : ''))
}

export const convertJsonSchema = (schema: SchemaElement): JsonSchema4 => {
    if (schema.type === 'root') {
        return {
            type: 'object',
            properties: {
                ...filterNodes(schema.nodes).reduce((acc, node) => {
                    // @ts-ignore
                    acc = flatten(acc, node.name, convertJsonSchema(node))
                    return acc
                }, {} as Record<string, JsonSchema4>),
            },
            required: getRequiredFields(schema.nodes),
        }
    }

    if (schema.type === 'VerticalLayout') {
        return {
            type: 'flatten',
            properties: {
                ...filterNodes(schema.nodes).reduce((acc, node) => {
                    //@ts-ignore
                    acc = flatten(acc, node.name, convertJsonSchema(node))
                    return acc
                }, {} as Record<string, JsonSchema4>),
            },
            required: getRequiredFields(schema.nodes),
        }
    }

    if (schema.type === 'HorizontalLayout') {
        return {
            type: 'flatten',
            properties: {
                ...filterNodes(schema.nodes).reduce((acc, node) => {
                    //@ts-ignore
                    acc = flatten(acc, node.name, convertJsonSchema(node))
                    return acc
                }, {} as Record<string, JsonSchema4>),
            },
            required: getRequiredFields(schema.nodes),
        }
    }

    if (schema.type === 'Label') {
        return undefined as unknown as JsonSchema4
    }

    if (schema.type === 'Group') {
        return {
            type: 'object',
            properties: {
                ...filterNodes(schema.nodes).reduce((acc, node) => {
                    // @ts-ignore
                    acc = flatten(acc, node.name, convertJsonSchema(node))
                    return acc
                }, {} as Record<string, JsonSchema4>),
            },
            required: getRequiredFields(schema.nodes),
        }
    }

    if (schema.type === 'groupTable') {
        return {
            type: 'object',
            properties: {
                ...filterNodes(schema.nodes).reduce((acc, node) => {
                    // @ts-ignore
                    acc = flatten(acc, node.name, convertJsonSchema(node))
                    return acc
                }, {} as Record<string, JsonSchema4>),
            },
            required: getRequiredFields(schema.nodes),
        }
    }

    if (schema.type === 'dynamicTable') {
        return {
            type: 'object',
            properties: {
                ...filterNodes(schema.nodes).reduce((acc, node) => {
                    // @ts-ignore
                    acc = flatten(acc, node.name, convertJsonSchema(node))
                    return acc
                }, {} as Record<string, JsonSchema4>),
            },
            required: getRequiredFields(schema.nodes),
        }
    }

    if (schema.type === 'disciplinesTag') {
        return {
            type: 'array',
            uniqueItems: true,
            items: {
                type: 'string',
                enum: schema.enum.map((en) => en.value),
            },
        }
    }

    if (schema.type === 'buildingPart') {
        return {
            type: 'array',
            uniqueItems: true,
            items: {
                type: 'string',
                enum: schema.enum.map((en) => en.value),
            },
        }
    }

    if (schema.type === 'array') {
        return {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    ...filterNodes(schema.nodes).reduce((acc, node) => {
                        // @ts-ignore
                        acc = flatten(acc, node.name, convertJsonSchema(node))
                        return acc
                    }, {} as Record<string, JsonSchema4>),
                },
                required: getRequiredFields(schema.nodes),
            },
        }
    }

    if (schema.type === 'list') {
        return {
            type: 'array',
            items: {
                type: schema.element,
            },
        }
    }

    if (schema.type === 'select') {
        return {
            type: 'string',
            enum: schema.enum,
        }
    }

    if (schema.type === 'radio') {
        return {
            type: 'string',
            enum: schema.enum,
        }
    }

    if (schema.type === 'checkbox') {
        return {
            type: 'array',
            uniqueItems: true,
            items: {
                type: 'string',
                enum: schema.enum,
            },
        }
    }

    if (schema.type === 'string') {
        return {
            type: 'string',
        }
    }

    if (schema.type === 'number') {
        return {
            type: 'number',
        }
    }
    if (schema.type === 'date') {
        return {
            type: 'string',
            format: 'date',
        }
    }
    if (schema.type === 'boolean') {
        return {
            type: 'boolean',
            default: false,
        }
    }

    if (schema.type === 'file') {
        return {
            type: 'string',
        }
    }

    throw Error('upos')
}
