import { useParams } from 'react-router-dom'
import { FormStructureBuilderPage } from './FormStructureBuilder.page.'
import { useGetFormStructureQuery } from '../../services/formStructures.service'
import { PageLoader } from 'components/general/loaders/PageLoader'

export const EditFormStructurePage: React.FC<any> = () => {
    const params = useParams()
    const id = params.id!

    const { data: structure, isLoading, isSuccess } = useGetFormStructureQuery(id)

    if (isLoading) return <PageLoader />

    return isSuccess ? <FormStructureBuilderPage initialData={structure} /> : null
}
