import React from 'react'

import { TextField, Autocomplete, CircularProgress, AutocompleteProps } from '@mui/material'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { Path, FieldValues, Control, useController } from 'react-hook-form'

type GAddressAutocompleteProps<T extends FieldValues> = {
    name: Path<T>
    control: Control<T>
    onDetailsChange: (googleDetails: any) => void
    debounceTimeMs?: number
} & Omit<
    AutocompleteProps<any, false, true, false>,
    | 'renderInput'
    | 'value'
    | 'onChange'
    | 'defaultValue'
    | 'freeSolo'
    | 'options'
    | 'multiple'
    | 'inputValue'
    | 'disableClearable'
>

export const GoogleAddressAutocomplete = <T extends FieldValues>({
    control,
    name,
    onDetailsChange,
    debounceTimeMs = 500,
    ...rest
}: GAddressAutocompleteProps<T>) => {
    const {
        field: { ref, name: _name, onChange, ...fieldProps },
        fieldState: { error, invalid },
    } = useController({ control, name })

    const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        options: {
            types: ['geocode', 'establishment'],
        },
        debounce: debounceTimeMs,
    })

    const fetchPlaceDetails = async (placeId: string) => {
        return new Promise((resolve, reject) => {
            placesService.getDetails({ placeId }, (placeDetails: any, status: string) => {
                if (status === 'OK' && placeDetails) {
                    resolve(placeDetails)
                } else {
                    console.error('Failed to fetch place details:', status)
                    reject(status)
                }
            })
        })
    }

    return (
        <Autocomplete
            {...rest}
            {...fieldProps}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(o) => o}
            onChange={async (e, newValue) => {
                onChange(newValue.structured_formatting.main_text)
                try {
                    const details = await fetchPlaceDetails(newValue.place_id)
                    onDetailsChange(details)
                } catch {} // TODO how do we handle this?
            }}
            autoComplete
            includeInputInList
            onInputChange={(e, newInputValue) => getPlacePredictions({ input: newInputValue })}
            options={placePredictions}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputRef={ref}
                    error={invalid}
                    helperText={error?.message}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isPlacePredictionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            disableClearable
        />
    )
}
