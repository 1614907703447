import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, RadioGroupProps } from '@mui/material'
import { Control, Controller, Path, FieldValues, PathValue } from 'react-hook-form'

interface RadioOption {
    label: string
    value: number | string | boolean
    disabled?: boolean
}

interface ControlledMuiRadioProps<T extends FieldValues> extends Omit<RadioGroupProps, 'name' | 'onChange'> {
    control: Control<T>
    name: Path<T>
    options: Array<RadioOption> | null
    defaultValue?: PathValue<T, Path<T>>
    label?: string
    hideDisabledOptions?: boolean
    onChangeCb?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ControlledMuiRadio = <T extends FieldValues>({
    name,
    label,
    control,
    options,
    onChangeCb,
    defaultValue,
    hideDisabledOptions,
    ...props
}: ControlledMuiRadioProps<T>) => (
    <Controller
        control={control}
        name={name}
        render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
            <FormControl>
                {label && <FormLabel>{label}</FormLabel>}
                <RadioGroup
                    {...rest}
                    {...props}
                    onChange={(e) => {
                        const value = e.target.value === 'true'
                        onChangeCb?.(e)
                        onChange(value)
                    }}
                >
                    {(options || []).map((opt) => (
                        <FormControlLabel key={opt.label} value={opt.value} control={<Radio />} label={opt.label} />
                    ))}
                </RadioGroup>
            </FormControl>
        )}
    />
)
export default ControlledMuiRadio
