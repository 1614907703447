import { useParams } from 'react-router-dom'
import { useGetFormStructuresQuery } from 'services/formStructures.service'
import { useGetProjectQuery } from '../../../services/projects.service'
import { MAX_RECORDS_QUERY } from 'constants/pagination'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useGetTemplatesQuery } from 'services/templates.service'
import { useGetTenantUsersQuery } from 'services/tenantUsers.service'
import { useAppSelector } from 'redux/hooks'
import { selectCurrentUserTenantId } from 'redux/selectors/auth.selectors'
import { useGetStandardsQuery } from 'services/standards.service'

export type CreateProjectParams = {
    projectId: string
}

/**
 * initializes all requests needed for creating/editing project
 */
export const useCreateProject = () => {
    const tenantId = useAppSelector(selectCurrentUserTenantId)
    const { projectId } = useParams<keyof CreateProjectParams>() as CreateProjectParams

    const { isLoading: isProjectLoading, data: project } = useGetProjectQuery(
        projectId ? { asCustomer: false, id: projectId } : skipToken,
    )
    const { isLoading: isLoadingFormStructures, data: formStructures } = useGetFormStructuresQuery({
        ...MAX_RECORDS_QUERY,
        progress: 'Published',
    })
    const { isLoading: isLoadingUsers, data: users } = useGetTenantUsersQuery(
        {
            ...MAX_RECORDS_QUERY,
            tenantId: tenantId!,
        },
        { skip: !tenantId },
    )
    const { isLoading: isTemplatesLoading, data: templates } = useGetTemplatesQuery({})
    const { isLoading: isStandardsLoading, data: standards } = useGetStandardsQuery()

    const isLoading =
        isLoadingFormStructures || isLoadingUsers || isProjectLoading || isTemplatesLoading || isStandardsLoading

    return {
        isLoading,
        project,
        formStructures,
        users,
        templates,
        projectId,
        standards,
    }
}
