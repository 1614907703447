import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Typography,
} from '@mui/material'
import * as O from 'optics-ts'
import React from 'react'
import { RulesEditor } from '../rules/rules-editor'
import { Render } from '../schema-builder'
import { useName, useRule } from '../hooks/schema-hooks'
import { AddFieldDialog } from './add-field-dialog'
import { BuilderFieldProps } from './builder-field-props'
import { Draggable, Droppable } from './droppable'
import { FieldDialog } from './field-dialog'
import { FieldItem } from './field-item'
import { NameLabel } from './general/field-name-label'
import { TabPanel } from './general/field-tab-panel'
import { FieldTabs } from './general/field-tabs'
import { DynamicTableShemaElement } from 'redux/types/schema.type'
import { DynamicTableColSizeDefinition } from 'types/util'
import { DynamicTableColumnSizeTab } from '../modules/create/DynamicTableColumnSizeTab'
import { useTranslation } from 'react-i18next'
import { groupElementsByRows } from 'utils/forms-utils'
import { validateGroupTableColumnsSizes } from 'utils/schema-utils'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'

const COLUMN_OPTIONS = [
    { key: '1-column-table', value: 1 },
    { key: '2-column-table', value: 2 },
    { key: '3-column-table', value: 3 },
    { key: '4-column-table', value: 4 },
    { key: '5-column-table', value: 5 },
    { key: '6-column-table', value: 6 },
]

const buildColumnDictionaryFromNodes = (
    oldColSizes: Record<number, DynamicTableColSizeDefinition>,
    columnCount: number,
) => {
    const newColSizes: Record<number, DynamicTableColSizeDefinition> = {}

    for (let i = 0; i < columnCount; i++) {
        const oldColumn = oldColSizes[i]
        if (oldColumn) {
            newColSizes[i] = oldColumn
        } else {
            newColSizes[i] = {
                isDirty: false,
                size: 'auto',
                unit: '%',
                columnName: '',
            }
        }
    }

    return newColSizes
}

export const DynamicTableBuilder: React.FC<BuilderFieldProps<DynamicTableShemaElement>> = ({
    node,
    state,
    setState,
    lens,
    path,
}) => {
    const { t } = useTranslation()
    const nameHook = useName(lens, state, setState)
    const rule = useRule(lens, state, setState)
    const nodesLens = lens.prop('nodes')
    const options = nameHook.options as DynamicTableShemaElement['options']

    const [tabIndex, setTabIndex] = React.useState(0)
    const [colSizes, setColSizes] = React.useState<Record<number, DynamicTableColSizeDefinition>>(
        options.columnDefinitions,
    )
    const [isColSizeValid, setIsColSizeValid] = React.useState(true)
    const [totalColumnsCount, setTotalColumnsCount] = React.useState(options.columnCount)

    const prevName = React.useRef<string>(nameHook.name as string)

    const remove = () => setState(O.remove(lens)(state))

    const handleGroupSave = () => {
        const isValid = validateGroupTableColumnsSizes(colSizes)
        setIsColSizeValid(isValid)
        if (!isValid) {
            setTabIndex(2)
            return isValid
        }

        rule.onSaveRules({ isGroup: true, newGroupName: nameHook.name as string, oldGroupname: prevName.current })
        nameHook.setNewOptions({ columnDefinitions: colSizes, columnCount: totalColumnsCount })
        prevName.current = nameHook.name as string
        return true
    }

    const handleColumnCountChange = (e: SelectChangeEvent<number>) => {
        setTotalColumnsCount(Number(e.target.value))
        setColSizes((prev) => {
            const newColSizes = buildColumnDictionaryFromNodes(prev, Number(e.target.value))
            return newColSizes
        })
    }

    const rows = groupElementsByRows(node.nodes, totalColumnsCount)
    const cellGridSize = Math.max(3, 12 / totalColumnsCount)

    const onCellDragEnd = (event: DragEndEvent) => {
        if (event.over) {
            const activePath = event.active.id.toString()
            const overPath = event.over.id.toString()

            if (activePath === overPath) return

            // working in the same path so just grab the last char which is index
            const activeIdx = Number(activePath.at(-1))
            const overIdx = Number(overPath.at(-1))

            const nodes = O.get(nodesLens)(state) as any[]

            const activeTemp = nodes[activeIdx]
            const newNodes = [...nodes]

            newNodes[activeIdx] = nodes[overIdx]
            newNodes[overIdx] = activeTemp

            setState(O.set(nodesLens)(newNodes))
        }
    }

    const onRowDragEnd = (event: DragEndEvent) => {
        if (event.over) {
            const activeIndex = event.active.data.current?.rowIndex as number
            const overIndex = event.over.data.current?.rowIndex as number

            if (activeIndex === overIndex) return

            const nodes = O.get(nodesLens)(state) as any[]

            const elementToMoveStartIdx = activeIndex * totalColumnsCount
            const elementToMoveEndIdx = elementToMoveStartIdx + totalColumnsCount

            const elementsToReplaceStartIdx = overIndex * totalColumnsCount
            const elementsToReplaceEndIdx = elementsToReplaceStartIdx + totalColumnsCount

            const nodesToMove = nodes.slice(elementToMoveStartIdx, elementToMoveEndIdx)
            const nodesToReplace = nodes.slice(elementsToReplaceStartIdx, elementsToReplaceEndIdx)

            // eslint-disable-next-line array-callback-return
            const newNodes = nodes.map((node, i) => {
                const isInMoveNodesIdx = i >= elementToMoveStartIdx && i < elementToMoveStartIdx + totalColumnsCount
                const isInReplaceNodesIdx =
                    i >= elementsToReplaceStartIdx && i < elementsToReplaceStartIdx + totalColumnsCount

                if (!isInMoveNodesIdx && !isInReplaceNodesIdx) {
                    return node
                }

                if (isInMoveNodesIdx) {
                    return nodesToReplace[i - elementToMoveStartIdx]
                }

                if (isInReplaceNodesIdx) {
                    return nodesToMove[i - elementsToReplaceStartIdx]
                }
            })

            setState(O.set(nodesLens)(newNodes))
        }
    }

    return (
        <Draggable id={`${path}`}>
            <FieldItem node={node} nameValue={nameHook.name} />
            <FieldDialog node={node} remove={remove} onSave={handleGroupSave}>
                <FieldTabs value={tabIndex} handleChange={setTabIndex} withColumnsTab />
                <TabPanel value={tabIndex} index={0}>
                    <NameLabel nameHook={nameHook} fieldType={node.type}>
                        <Stack spacing={2} direction="row">
                            <FormControl fullWidth sx={{ maxWidth: 225 }}>
                                <InputLabel>{t('schemaPage.totalColumns')}</InputLabel>
                                <Select
                                    label={t('schemaPage.totalColumns')}
                                    value={totalColumnsCount}
                                    onChange={handleColumnCountChange}
                                >
                                    {COLUMN_OPTIONS.map(({ key, value }) => (
                                        <MenuItem key={key} value={value}>
                                            {`${value} ${t('schemaPage.columns')}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    </NameLabel>
                    <DndContext onDragEnd={onRowDragEnd} modifiers={[restrictToVerticalAxis]}>
                        {rows.map((row, rowIndex) => {
                            const rowDndId = `row_${rowIndex}`

                            return (
                                <React.Fragment key={rowDndId}>
                                    <Draggable id={rowDndId} dragData={{ rowIndex }} styling={{ alignItems: 'center' }}>
                                        <Typography variant="body1" sx={{ margin: 'auto 0', fontWeight: 700, pr: 1 }}>
                                            {`${rowIndex + 1}.`}
                                        </Typography>
                                        <DndContext onDragEnd={onCellDragEnd}>
                                            <Grid
                                                container
                                                alignItems="center"
                                                rowGap={2}
                                                sx={{ border: '1px solid black', p: 0.5, mt: 1 }}
                                            >
                                                {row.map((_, cellIdx: number) => {
                                                    const nodeIdx = rowIndex * totalColumnsCount + cellIdx
                                                    const nodeLens = nodesLens.at(nodeIdx)
                                                    return (
                                                        <Grid item xs={cellGridSize} key={`${rowIndex}-${cellIdx}`}>
                                                            <Box display="flex" maxWidth="10rem">
                                                                <Render
                                                                    state={state}
                                                                    setState={setState}
                                                                    lens={nodeLens}
                                                                    path={`${path}.nodes.${nodeIdx}`}
                                                                />
                                                                <div>
                                                                    <Droppable
                                                                        id={`${path}.nodes.${nodeIdx}`}
                                                                        styling={{
                                                                            width: 30,
                                                                            height: '100%',
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Box>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </DndContext>
                                    </Draggable>
                                    <Droppable id={rowDndId} dragData={{ rowIndex }} />
                                </React.Fragment>
                            )
                        })}
                    </DndContext>
                    <AddFieldDialog nodesLens={nodesLens} setState={setState} parentField="groupTable" />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <DynamicTableColumnSizeTab
                        setColumnSizeDefinitions={setColSizes}
                        isColSizeValid={isColSizeValid}
                        totalColumnsSelected={totalColumnsCount}
                        columnSizeDefinitions={colSizes}
                    />
                </TabPanel>
            </FieldDialog>
        </Draggable>
    )
}
