import React from 'react'
import { TopBar } from '../../components/general/top-bar/top-bar'
import { useParams } from 'react-router-dom'
import { Paper } from '@mui/material'
import { useGetBuildingQuery } from '../../services/buildings.service'
import { navigationLinks } from 'utils/navigation-utils'
import { HeaderBackButton } from 'components/general/navigation/HeaderBackButton'
import { BuildingFormTabs } from 'modules/buildings/shared/BuildingFormTabs'
import { BuildingTabs, EBuildingTabs } from 'modules/buildings/shared/BuildingTabs'
import { PageContainer } from 'components/general/containers/PageContainer'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { BuildingRelevantOrganizations } from 'modules/buildings/building/BuildingRelevantOrganizations'
import { TabContainer } from 'components/buildings/TabContainer'

export const EditBuildingPage: React.FC = () => {
    const { t } = useTranslation()
    const params = useParams()
    const buildingId = params.id!
    const { data: building, isFetching, isSuccess } = useGetBuildingQuery(buildingId)

    const [activeTab, setActiveTab] = React.useState<EBuildingTabs>(EBuildingTabs.BuildingInfo)

    if (!isSuccess || isFetching) return null

    return (
        <>
            <Helmet>
                {building && (
                    <title>{`${t('common.edit')} - ${
                        building.address.addressText || t('common.building').toLowerCase()
                    }`}</title>
                )}
            </Helmet>
            <TopBar
                leftActions={<HeaderBackButton label={building.name} href={navigationLinks.toBuilding(building.id)} />}
            />
            <PageContainer>
                <Paper elevation={0} sx={{ mb: 4 }}>
                    <BuildingTabs activeTab={activeTab} onActiveTabChange={setActiveTab} />
                    <TabContainer>
                        <BuildingFormTabs isEdit activeTab={activeTab} building={building} />
                        <BuildingRelevantOrganizations organizations={building.organizations} activeTab={activeTab} />
                    </TabContainer>
                </Paper>
            </PageContainer>
        </>
    )
}
