import { Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import { TopBar } from '../../components/general/top-bar/top-bar'
import { AddTenantUser } from '../../modules/users/users/AddTenantUser'
import { TenantUsersTable } from '../../modules/tenants/tenant/tenant-users-table'
import { useGetTenantQuery } from '../../services/tenants.service'
import { PageContainer } from 'components/general/containers/PageContainer'

export const TenantPage: React.FC = () => {
    const params = useParams()
    const tenantId = params.id!

    const tenant = useGetTenantQuery(tenantId)

    return (
        <>
            <TopBar />
            <PageContainer>
                <Grid container spacing={2}>
                    {tenant.isSuccess ? (
                        <Grid item xs={12}>
                            <h1>{tenant.data.name}</h1>
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        <AddTenantUser tenantId={tenantId} />
                    </Grid>

                    <Grid item xs={12} lg={9}>
                        <TenantUsersTable tenantId={tenantId} />
                    </Grid>

                    <Grid item xs={12} lg={3}></Grid>
                </Grid>
            </PageContainer>
        </>
    )
}
