import React from 'react'
import { Button } from '@mui/material'
import { FormItem } from 'components/buildings/FormItem'
import {
    CustomerTabCard,
    CustomerTabCardButtonContainer,
    CustomerTabCardContent,
} from 'components/customersAdmin/CustomerTabCard'
import { useTranslation } from 'react-i18next'
import { Customer } from 'redux/types/customer.type'
import { EditCustomerGeneralInfoDialog } from './EditCustomerGeneralInfoDialog'

type CustomerGeneralInformationProps = {
    customer: Customer
}

export const CustomerGeneralInformation: React.FC<CustomerGeneralInformationProps> = ({ customer }) => {
    const { t } = useTranslation()
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)

    return (
        <>
            <CustomerTabCard>
                <CustomerTabCardContent>
                    <FormItem label={t('customersPage.customersTable.name')}>{customer.name}</FormItem>
                    <FormItem label={t('customersPage.customersTable.organizationNumber')}>
                        {customer.organizationNumber}
                    </FormItem>
                    <FormItem label={t('common.email')}>{customer.email}</FormItem>
                    <FormItem label={t('common.phoneNumber')}>{customer.phoneNumber}</FormItem>
                    <FormItem label={t('common.address')}>{customer.address?.addressText}</FormItem>
                    <FormItem label={t('common.postalCode')}>{customer.address?.postalCode}</FormItem>
                    <FormItem label={t('common.country')}>{customer.address?.country}</FormItem>
                </CustomerTabCardContent>

                <CustomerTabCardButtonContainer>
                    <Button variant="contained" color="primary" onClick={() => setIsDialogOpen(true)}>
                        {t('common.edit')}
                    </Button>
                </CustomerTabCardButtonContainer>
            </CustomerTabCard>
            <EditCustomerGeneralInfoDialog
                open={isDialogOpen}
                handleClose={() => setIsDialogOpen(false)}
                customer={customer}
            />
        </>
    )
}
