import React from 'react'
import { Box, Card, CircularProgress, styled } from '@mui/material'
import { useGetSchemaQuery } from 'services/schemas.service'
import { getAdjacentIds, getAdjacentCompleteIds, replaceFormViewStateTemplateValues } from 'utils/forms-utils'
import { PrebuiltFormNavigationControlls } from '../create/PrebuiltFormNavigationControlls'
import { RenderView } from 'components/general/jsonForm/view/RenderView'
import { RegisteredJsonForm } from 'components/general/jsonForm/RegisteredJsonForm'
import { PrebuiltFormNotAssigned } from '../create/PrebuiltFormNotAssigned'
import { PrebuiltFormStatic } from '../create/PrebuiltFormStatic'
import { useProjectQueryParams } from '../hooks/useProjectQueryParams'
import { useProjectView } from '../hooks/useProjectView'
import { FormViewState } from 'redux/types/schemaView.type'

export const PrebuiltFormView: React.FC = () => {
    const [{ structureId }, setSearch] = useProjectQueryParams()
    const { project, isCustomerView, isLoading: isProjectLoading } = useProjectView()

    const [formViewSchema, setFormViewSchema] = React.useState<FormViewState | null>(null)

    const currentSchema =
        structureId && project?.normalizedStructure?.entities ? project.normalizedStructure.entities[structureId] : null
    const schemaId = currentSchema && currentSchema.schemaId ? currentSchema.schemaId : undefined
    const isStaticSchema = currentSchema?.staticType !== null

    const { data: schema, isFetching: isSchemaLoading } = useGetSchemaQuery(schemaId!, {
        skip: !schemaId || Boolean(isCustomerView) || isStaticSchema,
    })

    const adjacentNodeIds = isCustomerView
        ? getAdjacentCompleteIds(structureId || '', project?.normalizedStructure?.entities)
        : getAdjacentIds(structureId || '', project?.normalizedStructure?.result)
    const currentSchemaValues = structureId && project?.formState?.answers?.[structureId]

    React.useEffect(() => {
        if (!project || !structureId) return

        const formViewSchemaWithReplacesTemplateValues = replaceFormViewStateTemplateValues(
            project.formState?.formSnapshot?.[structureId],
            project,
        )
        setFormViewSchema(formViewSchemaWithReplacesTemplateValues)
    }, [currentSchemaValues, project, structureId])

    const handleNavigateToNextStructure = (direction: 'next' | 'prev') => {
        const structureId = direction === 'next' ? adjacentNodeIds.next : adjacentNodeIds.prev
        if (!structureId) return
        setSearch({
            structureId,
        })
    }

    if (!structureId || !currentSchema) return null

    if (!schemaId) {
        return (
            <PrebuiltFormNotAssigned
                title={project?.normalizedStructure?.entities?.[structureId]?.label}
                handleNavigateToNextStructure={handleNavigateToNextStructure}
                prevNode={adjacentNodeIds.prev}
                nextNode={adjacentNodeIds.next}
            />
        )
    }

    if (isStaticSchema) {
        return (
            <PrebuiltFormStatic
                title={project?.normalizedStructure?.entities?.[structureId]?.label}
                handleNavigateToNextStructure={handleNavigateToNextStructure}
                prevNode={adjacentNodeIds.prev}
                nextNode={adjacentNodeIds.next}
                staticType={currentSchema.staticType!}
            />
        )
    }

    return (
        <StyledCard elevation={1}>
            {project?.normalizedStructure && (
                <PrebuiltFormNavigationControlls
                    title={project?.normalizedStructure.entities?.[structureId]?.label || ''}
                    prevNode={adjacentNodeIds.prev}
                    nextNode={adjacentNodeIds.next}
                    handleNavigateToNextStructure={handleNavigateToNextStructure}
                />
            )}
            {(isSchemaLoading || isProjectLoading) && (
                <CardLoaderContainer>
                    <CircularProgress />
                </CardLoaderContainer>
            )}
            {!isProjectLoading && formViewSchema && (
                <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
                    {formViewSchema?.elements.map((element, idx) => (
                        <RenderView element={element} key={`${idx}_${element.type}_base`} />
                    ))}
                </Box>
            )}
            {/* render in review when schema was disabled */}
            {!isCustomerView && !currentSchemaValues && schema && (
                <JsonFormContainer>
                    <RegisteredJsonForm
                        schema={schema.jsonSchema}
                        uischema={schema.uiSchema}
                        data={undefined}
                        readonly
                    />
                </JsonFormContainer>
            )}
        </StyledCard>
    )
}

const CardLoaderContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
})

const JsonFormContainer = styled('div')({
    '& > div': {
        boxShadow: 'none',
    },

    '& .MuiPaper-root': {
        height: 'unset',
        maxHeight: 'none',
    },
})

const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2, 4),
    height: '100%',
    borderRadius: '5px 5px 0 0',
    overflow: 'auto',
}))
