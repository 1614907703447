import { useTranslation } from 'react-i18next'
import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { useCreateCustomerMutation, useUpdateCustomerMutation } from 'services/customers.service'
import { Customer } from 'redux/types/customer.type'
import { CustomerOrOrganizationForm } from 'schemas/customers'
import { Button, Stack, styled } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React from 'react'
import { CustomerForm } from 'components/customersAdmin/CustomerForm'

interface CustomerEditAndCreateDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions'> {
    customer: Customer | null
}

export const CustomerEditAndCreateDialog: React.FC<CustomerEditAndCreateDialogProps> = ({
    customer,
    open,
    handleClose,
}) => {
    const { t } = useTranslation()

    const [update, { isLoading: isUpdatingCustomer }] = useUpdateCustomerMutation()
    const [create, { isLoading: isCreatingCustomer }] = useCreateCustomerMutation()

    const isLoading = isUpdatingCustomer || isCreatingCustomer

    const onSubmit = async (values: CustomerOrOrganizationForm) => {
        if (customer) {
            await update({ ...customer, ...values }).unwrap()
        } else {
            await create({
                address: values.address,
                contacts: values.contacts,
                email: values.email,
                name: values.name,
                organizationNumber: values.organizationNumber,
                phoneNumber: values.phoneNumber,
                type: values.isPrivatePerson ? 'PrivatePerson' : 'Company',
            }).unwrap()
        }
        handleClose()
    }

    return (
        <ModalsWrapper
            open={open}
            onClick={(e) => e.stopPropagation()}
            handleClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
            body={
                <CustomerForm
                    formId="customer-form"
                    onSubmit={onSubmit}
                    title={t('customersPage.customerDialog.customerDetails')}
                    isImportFromOrganizationsInputVisible={customer === null}
                />
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={2}>
                    <Button variant="outlined" onClick={handleClose} color="secondary" disabled={isLoading}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="customer-form"
                        loading={isLoading}
                    >
                        {t('common.save')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))
