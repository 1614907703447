import { appApi } from 'redux/api'
import {
    CreateTemplateCopyRequest,
    CreateTemplateRequest,
    EditTemplateRequest,
    GetTemplateRequest,
    GetTemplatesRequest,
    TemplateEntity,
    TemplateEntityWithPartialStructure,
} from 'redux/types/template.type'

export const templatesApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getTemplates: builder.query<TemplateEntityWithPartialStructure[], GetTemplatesRequest>({
            query: (params) => ({
                url: '/v1/StructureTemplates',
                method: 'GET',
                params: params,
            }),
            providesTags: ['Templates'],
        }),
        getTemplate: builder.query<TemplateEntityWithPartialStructure, GetTemplateRequest>({
            query: (params) => ({
                url: `/v1/StructureTemplates/${params.id}`,
                method: 'GET',
            }),
            providesTags: ['Templates'],
        }),
        createTemplate: builder.mutation<TemplateEntity, CreateTemplateRequest>({
            query: (params) => ({
                url: 'v1/StructureTemplates',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['Templates'],
        }),
        createTemplateCopy: builder.mutation<TemplateEntity, CreateTemplateCopyRequest>({
            query: (payload) => ({
                url: 'v1/StructureTemplates/copy',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result) => (result ? ['Templates'] : []),
        }),
        editTemplate: builder.mutation<TemplateEntity, EditTemplateRequest>({
            query: (params) => ({
                url: `v1/StructureTemplates/${params.id}`,
                method: 'PUT',
                body: params,
            }),
            invalidatesTags: ['Templates'],
        }),

        deleteTemplate: builder.mutation<void, { id: string }>({
            query: (params) => ({
                url: `v1/StructureTemplates/${params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result) => (result === null ? ['Templates'] : []),
        }),

        deleteTemplates: builder.mutation<void, { ids: string[] }>({
            query: (payload) => ({
                url: 'v1/StructureTemplates/many',
                method: 'DELETE',
                body: payload,
            }),
            invalidatesTags: (result) => (result === null ? ['Templates'] : []),
        }),
    }),
})

export const {
    useGetTemplatesQuery,
    useCreateTemplateMutation,
    useGetTemplateQuery,
    useEditTemplateMutation,
    useCreateTemplateCopyMutation,
    useDeleteTemplateMutation,
    useDeleteTemplatesMutation,
} = templatesApi
