import React from 'react'
import { Typography, styled } from '@mui/material'
import { valueOrDefaultEmpty } from 'utils/general'

interface TextItemProps {
    label: string
    isLabelVisible: boolean
    direction: 'column' | 'row'
    value: string | number
}

export const TextItem: React.FC<TextItemProps> = React.memo(({ label, isLabelVisible, value, direction }) => {
    return (
        <ItemContainer direction={direction}>
            {label && isLabelVisible && <Label variant="body1">{label}</Label>}

            <Typography variant="body2" component="span">
                {valueOrDefaultEmpty(value)}
            </Typography>
        </ItemContainer>
    )
})

const Label = styled(Typography)({
    fontWeight: 600,
    display: 'block',
    marginRight: 'auto',
    width: '30%',
    maxWidth: 200,
    minWidth: 150,
})

const ItemContainer = styled('div')<{ direction: 'row' | 'column' }>(({ theme, direction }) => ({
    display: 'flex',
    flexDirection: direction,
    width: '100%',
    maxWidth: 500,
    alignItems: 'baseline',

    '& span:last-of-type': {
        width: '70%',
        marginLeft: direction === 'column' ? 0 : theme.spacing(4),
        marginTop: direction === 'column' ? theme.spacing(1) : 0,
    },
}))
