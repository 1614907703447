import { AutocompleteFreeSoloValueMapping, CircularProgress } from '@mui/material'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import React from 'react'
import { FieldValues, useController, FieldPath, UseControllerProps, FieldPathValue } from 'react-hook-form'
import { ExtractArrayType } from 'types/util'

type AutocompleteFieldProps<
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined = undefined,
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> &
    Omit<
        AutocompleteProps<
            Multiple extends true
                ? ExtractArrayType<FieldPathValue<TFieldValues, TName>>
                : FieldPathValue<TFieldValues, TName> | AutocompleteFreeSoloValueMapping<FreeSolo>,
            Multiple,
            DisableClearable,
            FreeSolo
        >,
        'renderInput'
    > &
    Pick<TextFieldProps, 'label'> & {
        onChangeCb?: (value: any) => void
    }

export const ControlledMuiAutoComplete = <
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined = undefined,
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    control,
    name,
    label,
    rules,
    loading,
    onChangeCb,
    ...autocompleteProps
}: AutocompleteFieldProps<Multiple, DisableClearable, FreeSolo, TFieldValues, TName>) => {
    const {
        field: { ref, name: _name, onChange, ...fieldProps },
        fieldState: { error, invalid },
    } = useController({ control, name, rules })

    return (
        <Autocomplete
            {...fieldProps}
            {...autocompleteProps}
            onChange={(event, newValue) => {
                onChange(newValue)
                onChangeCb?.(newValue)
            }}
            disableCloseOnSelect
            renderInput={(inputProps) => (
                <TextField
                    {...inputProps}
                    name={_name}
                    label={label}
                    error={invalid}
                    inputRef={ref}
                    helperText={error?.message}
                    InputProps={{
                        ...inputProps.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {inputProps.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}
