import React from 'react'
import DOMPurify from 'dompurify'
import { Button, Stack, Typography, styled } from '@mui/material'

import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { Trans, useTranslation } from 'react-i18next'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import { useReviseProjectMutation } from 'services/projectRevision.service'
import { ReviseProjectRequest } from 'redux/types/projectRevision.type'
import { navigationLinks } from 'utils/navigation-utils'

type ArchiveProjectForm = Omit<ReviseProjectRequest, 'projectId'>

const DEFAULT_VALUES: ArchiveProjectForm = {
    description: null,
}

interface ArchiveProjectDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions'> {
    projectId: string
    projectName: string
}

export const ArchiveProjectDialog: React.FC<ArchiveProjectDialogProps> = ({
    handleClose,
    open,
    projectId,
    projectName,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [commitRevision, { isLoading }] = useReviseProjectMutation()

    const { handleSubmit, control } = useForm<ArchiveProjectForm>({
        defaultValues: DEFAULT_VALUES,
    })

    const onSubmit = async (values: ArchiveProjectForm) => {
        try {
            if (values.description) {
                const sanitezed = DOMPurify.sanitize(values.description)
                await commitRevision({ description: sanitezed, projectId }).unwrap()
            } else {
                await commitRevision({ projectId, description: null }).unwrap()
            }
            navigate(navigationLinks.toCreateProject(projectId))
        } catch (e) {}
    }

    return (
        <ModalsWrapper
            sx={{
                zIndex: 9999,
            }}
            open={open}
            title={t('common.confirmDialogTitle')}
            handleClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
            body={
                <>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        <Trans
                            i18nKey="projectPage.addProjectRevisionDialogContent"
                            values={{ projectName: projectName }}
                        />
                    </Typography>

                    <form id="archive-project-form" onSubmit={handleSubmit(onSubmit)}>
                        <ContentWrapper>
                            <ControlledMuiTextField
                                control={control}
                                fullWidth
                                name="description"
                                variant="filled"
                                placeholder={t('projectPage.revisionDescriptionPlaceholder')}
                                multiline
                                rows={10}
                            />
                        </ContentWrapper>
                    </form>
                </>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button variant="outlined" onClick={handleClose} color="secondary" disabled={isLoading}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="archive-project-form"
                        loading={isLoading}
                    >
                        {t('common.confirm')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
