import React from 'react'
import * as yup from 'yup'
import { Button, Stack, styled } from '@mui/material'

import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { CreateTemplateCopyRequest, TemplateEntityWithPartialStructure } from 'redux/types/template.type'
import { useEditTemplateMutation } from 'services/templates.service'
import { LoadingButton } from '@mui/lab'
import { yupResolver } from '@hookform/resolvers/yup'

type ChangeTemplateNameForm = Pick<CreateTemplateCopyRequest, 'name'>
const schema: yup.ObjectSchema<ChangeTemplateNameForm> = yup.object().shape({
    name: yup.string().trim().required('required'),
})

interface CopyTemplateDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions' | 'open'> {
    template: TemplateEntityWithPartialStructure
}

export const NameChangeDialog: React.FC<CopyTemplateDialogProps> = ({ handleClose, template }) => {
    const { t } = useTranslation()

    const [edit, { isLoading }] = useEditTemplateMutation()

    const { handleSubmit, control } = useForm<ChangeTemplateNameForm>({
        defaultValues: {
            name: template.name,
        },
        resolver: yupResolver(schema),
    })

    const onSubmit = async (values: ChangeTemplateNameForm) => {
        await edit({
            id: template.id,
            name: values.name,
        }).unwrap()

        handleClose()
    }

    return (
        <ModalsWrapper
            sx={{
                zIndex: 9999,
            }}
            open
            title={t('templatesPage.editTemplateDialogTitle')}
            handleClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
            isLoading={isLoading}
            body={
                <form id="edit-template-form" onSubmit={handleSubmit(onSubmit)}>
                    <ContentWrapper>
                        <ControlledMuiTextField
                            control={control}
                            fullWidth
                            name="name"
                            variant="filled"
                            placeholder={t('common.name')}
                        />
                    </ContentWrapper>
                </form>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button variant="outlined" onClick={handleClose} color="secondary" disabled={isLoading}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="edit-template-form"
                        loading={isLoading}
                    >
                        {t('common.confirm')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 3),
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
