import i18next from 'i18next'
import * as yup from 'yup'

const addressSchema = yup.object().shape({
    addressText: yup.string().required('required'),
    postalCode: yup.string().required('required'),
    country: yup.string().required('required'),
    city: yup.string().required('required'),
})

const floorSchema = yup.object().shape({
    area: yup
        .number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : Number(value)))
        .min(0, 'greaterThan0'),
    terrain: yup.boolean(),
})

export const buildingSchema = yup.object().shape({
    name: yup.string().required('required'),
    buildingNumber: yup.string().when('isPublic', {
        is: true,
        then: (schema) => schema.required('required'),
    }),
    municipality: yup.string().required('required').length(4, 'mustBeLengthOf4'),
    titleNumber: yup.string().required('required').max(4),
    landNumber: yup.string().required('required').max(3),
    address: addressSchema,
    height: yup
        .number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : Number(value)))
        .min(0, 'greaterThan0'),
    area: yup
        .number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : Number(value)))
        .min(0, 'greaterThan0'),
    floors: yup.array().of(floorSchema),
    fireLoad: yup
        .number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : Number(value)))
        .min(0, 'greaterThan0'),
    fireLoadDescription: yup.string().max(500, ({ max }) => i18next.t('maxLengthValidationError', { max })),
    fireClass: yup.string(),
    fireClassDescription: yup.string().max(500, ({ max }) => i18next.t('maxLengthValidationError', { max })),
    culturalHeritageTags: yup.array().when('culturalHeritage', {
        is: true,
        then: (schema) => schema.min(1, 'required'),
        otherwise: (schema) => schema.min(0),
    }),
    fireStation: yup.string(),
    distanceToStation: yup
        .number()
        .nullable()
        .transform((value) => (isNaN(value) ? null : Number(value)))
        .min(0, 'greaterThan0'),
    // couldnt find another solution besides using setLocale which adds additional payload on initial app load
    comment: yup.string().max(500, ({ max }) => i18next.t('maxLengthValidationError', { max })),
    specialRiskDescription: yup.string().max(500, ({ max }) => i18next.t('maxLengthValidationError', { max })),
})
