import React from 'react'
import { Box, Button, Card, IconButton, Typography } from '@mui/material'
import { FormProvider } from 'react-hook-form'
import { StructureBuilderForm, useStructureForm } from '../hooks/useStructureForm'
import ControlledMuiSelect, { IOption } from 'components/inputs/ControlledMuiSelect'
import { useTranslation } from 'react-i18next'
import { getAllChildrenIds } from 'utils/forms-utils'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import useHistoryState from 'utils/hooks/useHistoryState'
import useKeyBinding from 'utils/hooks/useKeyBinding'
import { useAppSelector } from 'redux/hooks'
import { selectStructureTypeOptions } from 'redux/selectors/settings.selectors'
import { LoadingButton } from '@mui/lab'
import { Schema } from 'redux/types/schema.type'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { Close } from '@mui/icons-material'
import { Draggable, Droppable } from 'components/forms/builder-fields/droppable'
import { FormStructureEntity } from 'redux/types/formStructure.type'
import { StructureFormField } from './StructureFormField'
import { useParams } from 'react-router-dom'
import { FullPageFormDialog } from './FullPageFormDialog'

type FormChildItem = `sections.${number}`
type FormChildrenItem = `sections`
export type SchemaOption = IOption & { schemaType: 'built-in' | 'general' | 'specific' }

const CHILDREN_NAME_LENGTH = 'children.0'.length

interface StructureFormProps2 {
    schemas: Schema[]
    initialData?: FormStructureEntity
}

export const StructureForm: React.FC<StructureFormProps2> = ({ schemas, initialData }) => {
    const { t } = useTranslation()
    const { id: structureId } = useParams()
    const structureTypeOptions = useAppSelector(selectStructureTypeOptions)

    const defaultValues: StructureBuilderForm = initialData
        ? { name: initialData.name, sections: initialData.sections, type: initialData.type.value }
        : {
              name: '',
              sections: [{ children: [], label: '', sectionId: Date.now().toString() }],
              type: '',
          }

    const { handleSubmit, publishStructure, methods, isLoading, prebuiltSchemaOptions, append, fields, remove } =
        useStructureForm(defaultValues, initialData?.id)

    const [setHistory, undo, redo] = useHistoryState(defaultValues)

    useKeyBinding('ctrl+z', 'keydown', () => {
        const prev = undo()
        if (prev) {
            onSchemaSet(prev)
            methods.reset(prev)
        }
    })
    useKeyBinding('ctrl+shift+z', 'keydown', () => {
        const next = redo()
        if (next) {
            onSchemaSet(next)
            methods.reset(next)
        }
    })
    useKeyBinding('ctrl+y', 'keydown', () => {
        const next = redo()
        if (next) {
            onSchemaSet(next)
            methods.reset(next)
        }
    })

    const [usedSchemas, setUsedSchemas] = React.useState(new Set<string>())
    const [isCreateSchemaDialogOpen, setIsCreateSchemaDialogOpen] = React.useState(false)

    const mountedOnce = React.useRef(false)

    const schemaOptions: SchemaOption[] = [
        ...prebuiltSchemaOptions.map(
            (schema): SchemaOption => ({
                value: schema.value,
                label: schema.label,
                schemaType: 'built-in',
                disabled: usedSchemas.has(schema.value as string),
            }),
        ),
        ...schemas.map(
            (schema): SchemaOption => ({
                value: schema.id,
                label: schema.name,
                disabled: usedSchemas.has(schema.id),
                schemaType: schema.assignedStructureId === null ? 'general' : 'specific',
            }),
        ),
    ].sort((a, b) => b.schemaType.localeCompare(a.schemaType))

    // disable already used form schemas
    React.useEffect(() => {
        if (!initialData || mountedOnce.current) return
        mountedOnce.current = true

        const ids = getAllChildrenIds(initialData.sections)
        const next = new Set(ids)
        setUsedSchemas(next)
    }, [initialData])

    const appendAndAddToHistory = () => {
        append()
        setHistory(methods.getValues())
    }
    const removeAndAddToHistory = (idx: number) => {
        remove(idx)
        setHistory(methods.getValues())
    }

    const onSchemaAdd = (currentValue: string | null, prevFormId?: string) => {
        const next = new Set(usedSchemas)
        if (currentValue) {
            next.add(currentValue)
        }

        if (prevFormId) {
            next.delete(prevFormId)
        }

        setUsedSchemas(next)
    }

    const onSchemaRemove = (schemaId: string | string[]) => {
        const next = new Set(usedSchemas)
        if (Array.isArray(schemaId)) {
            schemaId.forEach((id) => next.delete(id))
        } else {
            next.delete(schemaId)
        }
        setUsedSchemas(next)
    }

    const onSchemaSet = (currentFormState: StructureBuilderForm) => {
        const ids = getAllChildrenIds(currentFormState.sections)

        const newAvailableSchemas = new Set<string>(ids)
        setUsedSchemas(newAvailableSchemas)
    }

    const handleRootLevelDrag = (overPath: FormChildrenItem, overIndex: number, startIndex: number) => {
        const overNodes = methods.getValues(overPath)

        const newChildren = []

        for (let i = 0; i < overNodes.length; i++) {
            if (i !== startIndex && i !== overIndex) newChildren.push(overNodes[i])

            if (i === overIndex) newChildren.push(overNodes[startIndex])
            if (i === startIndex) newChildren.push(overNodes[overIndex])
        }

        methods.setValue(overPath, newChildren)
        setHistory(methods.getValues())
        return
    }

    const onDragEnd = (event: DragEndEvent) => {
        if (!event.over) return
        const activePath = event.active.id.toString() as FormChildItem
        const overPath = event.over.id.toString() as FormChildItem

        const activeIndex = event.active.data.current?.index
        const overIndex = event.over.data.current?.index
        // same path ignore
        if (activePath === overPath) return

        // trying to move parent element to its child - ignore
        if (
            overPath.length > activePath.length &&
            overPath.substring(0, overPath.length - CHILDREN_NAME_LENGTH - 1) === activePath
        )
            return

        // path example 'children.0'
        // path will always end with .{child index} so we can just remove 2 letters and get root array
        const overChildParentPath = overPath.substring(0, overPath.length - 2) as FormChildrenItem
        const startChildParentPath = activePath.substring(0, activePath.length - 2) as FormChildrenItem

        // root children
        if (overChildParentPath === startChildParentPath) {
            return handleRootLevelDrag(overChildParentPath, overIndex, activeIndex)
        }

        // promoting child to parent
        // or moving childs to different child groups
        const overPathParentChildren = overPath.substring(0, overPath.length - 2) as FormChildrenItem
        const startPathParentChildren = activePath.substring(0, activePath.length - 2) as FormChildrenItem

        const overNodes = methods.getValues(overPathParentChildren)
        const startNodes = methods.getValues(startPathParentChildren)

        const elementToMove = startNodes[activeIndex]

        const newStartNodes = []
        const newOverNodes = []

        for (let i = 0; i < startNodes.length; i++) {
            if (i === activeIndex) continue
            newStartNodes.push(startNodes[i])
        }

        for (let i = 0; i < overNodes.length; i++) {
            if (i === overIndex) newOverNodes.push(elementToMove)
            newOverNodes.push(overNodes[i])
        }

        if (overPath.length > activePath.length) {
            methods.setValue(overPathParentChildren, newOverNodes)
            methods.setValue(startPathParentChildren, newStartNodes)
        } else {
            methods.setValue(startPathParentChildren, newStartNodes)
            methods.setValue(overPathParentChildren, newOverNodes)
        }
        setHistory(methods.getValues())
        return
    }

    return (
        <>
            <DndContext onDragEnd={onDragEnd}>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit}>
                        <Box display="flex" gap={4} flexWrap="wrap">
                            <LoadingButton
                                type="submit"
                                variant="outlined"
                                color="primary"
                                disabled={isLoading}
                                sx={{ minWidth: 120 }}
                            >
                                {t('common.save')}
                            </LoadingButton>
                            {initialData && (
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setIsCreateSchemaDialogOpen(true)}
                                >
                                    {t('structurePage.createStructureSpecificSchema')}
                                </Button>
                            )}
                            {initialData?.progress === 'Started' && (
                                <LoadingButton
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    loading={isLoading}
                                    onClick={publishStructure}
                                    sx={{ minWidtH: 120 }}
                                >
                                    {t('common.publish')}
                                </LoadingButton>
                            )}
                        </Box>
                        <Box display="flex" gap={2} my={2} flexWrap="wrap">
                            <ControlledMuiSelect
                                control={methods.control}
                                name="type"
                                options={structureTypeOptions}
                                variant="filled"
                                defaultValue=""
                                sx={{ width: 300 }}
                                label={t('structurePage.structureType')}
                            />
                            <ControlledMuiTextField
                                control={methods.control}
                                name="name"
                                placeholder={t('common.name')}
                            />
                            <Button variant="contained" color="primary" size="small" onClick={appendAndAddToHistory}>
                                {t('structurePage.addMainSection')}
                            </Button>
                        </Box>
                        {fields.map((field, idx) => (
                            <React.Fragment key={field.id}>
                                <Draggable id={`sections.${idx}`} dragData={{ level: 0, index: idx }}>
                                    <Card sx={{ my: 2, px: 4, py: 2, width: '100%' }}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Typography variant="h5">{idx + 1}.</Typography>
                                            <IconButton onClick={() => removeAndAddToHistory(idx)}>
                                                <Close />
                                            </IconButton>
                                        </Box>
                                        <StructureFormField
                                            level={0}
                                            schemaOptions={schemaOptions}
                                            schemas={schemas}
                                            onSchemaAdd={onSchemaAdd}
                                            onSchemaRemove={onSchemaRemove}
                                            prefix={`sections.${idx}.`}
                                            setHistory={setHistory}
                                            structureId={structureId}
                                        />
                                    </Card>
                                </Draggable>
                                <Droppable id={`sections.${idx}`} dragData={{ level: 0 + 1, index: idx }} />
                            </React.Fragment>
                        ))}
                    </form>
                </FormProvider>
            </DndContext>
            {/* completly unmounting dialog resets state inside dialogs and dont need to do it manually */}
            {isCreateSchemaDialogOpen && initialData?.id && (
                <FullPageFormDialog
                    open={isCreateSchemaDialogOpen}
                    handleClose={() => setIsCreateSchemaDialogOpen(false)}
                    structureId={initialData?.id!}
                />
            )}
        </>
    )
}
