import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material'
import * as O from 'optics-ts'
import React from 'react'
import { RulesEditor } from '../rules/rules-editor'
import { useName, useRule } from '../hooks/schema-hooks'
import { BuilderFieldProps } from './builder-field-props'
import { Draggable } from './droppable'
import { FieldDialog } from './field-dialog'
import { FieldItem } from './field-item'
import { NameLabel } from './general/field-name-label'
import { TabPanel } from './general/field-tab-panel'
import { FieldTabs } from './general/field-tabs'
import { FieldType, ListSchemaElement } from 'redux/types/schema.type'
import { useTranslation } from 'react-i18next'

const LIST_ELEMENT_OPTIONS: Array<{ label: string; value: Partial<FieldType> }> = [
    { label: 'fields.string', value: 'string' },
    { label: 'fields.number', value: 'number' },
    { label: 'fields.bool', value: 'boolean' },
]

export const BuilderList: React.FC<BuilderFieldProps<ListSchemaElement>> = ({
    node,
    state,
    setState,
    lens,
    path,
    droppable,
}) => {
    const { t } = useTranslation()
    const nameHook = useName(lens, state, setState)
    const rule = useRule(lens, state, setState)

    const listTypeLens = lens.prop('options').prop('listType')
    const listElementLens = lens.prop('element')

    const listType = O.get(listTypeLens)(state) as 'ordered' | 'unordered'
    const listElement = O.get(listElementLens)(state) as FieldType

    const [tabIndex, setTabIndex] = React.useState(0)

    const remove = () => setState(O.remove(lens)(state))

    const handleSave = () => {
        rule.onSaveRules()
        return true
    }

    if (!droppable) {
        return (
            <>
                <FieldItem node={node} nameValue={nameHook.name} hasRules={rule.hasRules} />
                <FieldDialog node={node} remove={remove} onSave={handleSave}>
                    <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                    <TabPanel value={tabIndex} index={0}>
                        <Box mb={2}>
                            <FormControl fullWidth sx={{ maxWidth: 225 }}>
                                <InputLabel>{t('schemaPage.listType')}</InputLabel>
                                <Select
                                    label={t('schemaPage.listType')}
                                    value={listElement}
                                    onChange={(e) => setState(O.set(listElementLens)(e.target.value))}
                                >
                                    {LIST_ELEMENT_OPTIONS.map(({ label, value }) => (
                                        <MenuItem key={value} value={value}>
                                            {t(label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <NameLabel nameHook={nameHook} fieldType={node.type} />
                        <FormControlLabel
                            label={t('schemaPage.listTypeCheckboxLabel')}
                            control={
                                <Checkbox
                                    checked={listType === 'ordered'}
                                    onChange={(e) =>
                                        setState(O.set(listTypeLens)(e.target.checked ? 'ordered' : 'unordered'))
                                    }
                                />
                            }
                        />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                    </TabPanel>
                </FieldDialog>
            </>
        )
    }

    return (
        <Draggable id={`${path}`} styling={{ alignItems: 'center' }}>
            <FieldItem node={node} nameValue={nameHook.name} hasRules={rule.hasRules} />
            <FieldDialog node={node} remove={remove} onSave={handleSave}>
                <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                <TabPanel value={tabIndex} index={0}>
                    <Box mb={2}>
                        <FormControl fullWidth sx={{ maxWidth: 225 }}>
                            <InputLabel>{t('schemaPage.listType')}</InputLabel>
                            <Select
                                label={t('schemaPage.listType')}
                                value={listElement}
                                onChange={(e) => setState(O.set(listElementLens)(e.target.value))}
                            >
                                {LIST_ELEMENT_OPTIONS.map(({ label, value }) => (
                                    <MenuItem key={value} value={value}>
                                        {t(label)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <NameLabel nameHook={nameHook} fieldType={node.type} />
                    <FormControlLabel
                        label={t('schemaPage.listTypeCheckboxLabel')}
                        control={
                            <Checkbox
                                checked={listType === 'ordered'}
                                onChange={(e) =>
                                    setState(O.set(listTypeLens)(e.target.checked ? 'ordered' : 'unordered'))
                                }
                            />
                        }
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                </TabPanel>
            </FieldDialog>
        </Draggable>
    )
}
