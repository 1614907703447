import React from 'react'
import { Button } from '@mui/material'
import {
    CustomerTabCard,
    CustomerTabCardButtonContainer,
    CustomerTabCardContent,
} from 'components/customersAdmin/CustomerTabCard'
import { useTranslation } from 'react-i18next'
import { Contact, Customer } from 'redux/types/customer.type'
import DeleteDialog from 'components/general/dialogs/DeleteDialog'
import { useUpdateCustomerMutation } from 'services/customers.service'
import { Add } from '@mui/icons-material'
import { Organization } from 'redux/types/organization.type'
import { ContactTable } from 'components/customersAdmin/ContactTable'
import { ContactCreateEditDialog } from './ContactCreateEditDialog'
import { useUpdateOrganizationMutation } from 'services/organizations.service'

type ContactInfoProps =
    | {
          organization: Organization
          contactType: 'organization'
          customer?: never
      }
    | {
          contactType: 'customer'
          customer: Customer
          organization?: never
      }

export const ContactInfo: React.FC<ContactInfoProps> = ({ organization, contactType, customer }) => {
    const { t } = useTranslation()
    const [contactToDelete, setContactToDelete] = React.useState<null | Contact>(null)
    const [contactToEdit, setContactToEdit] = React.useState<null | Contact>(null)
    const [isCreateContactDialogOpen, setIsCreateContactDialogOpen] = React.useState(false)

    const [updateCustomer, { isLoading: isUpdatingCustomer }] = useUpdateCustomerMutation()
    const [updateOrg, { isLoading: isUpdatingOrg }] = useUpdateOrganizationMutation()

    const { contacts } = organization || customer
    const isUpdating = isUpdatingCustomer || isUpdatingOrg

    const handleDeleteContactAction = (contactEmail: string) => {
        const contact = contacts.find((contact) => contact.email === contactEmail)
        if (!contact) throw new Error('Cant open confirmation dialog with invalid contact')

        setContactToDelete(contact)
    }

    const handleEditContactAction = (contactEmail: string) => {
        const contact = contacts.find((contact) => contact.email === contactEmail)
        if (!contact) throw new Error('Cant open edit contact dialog with invalid contact')

        setContactToEdit(contact)
    }

    const handleDelete = async () => {
        if (!contactToDelete) throw new Error('Cant call delete without contactToDelete')

        if (contactType === 'organization') {
            const { address, id, contacts, email, name, organizationNumber, phoneNumber, type } = organization

            const newContacts = contacts.filter((contact) => contact.email !== contactToDelete.email)

            await updateOrg({
                id,
                organization: {
                    address,
                    contacts: newContacts,
                    email,
                    name,
                    organizationNumber,
                    phoneNumber,
                    type,
                },
            }).unwrap()
            setContactToDelete(null)
            return
        }

        const newCustomer = { ...customer }
        newCustomer.contacts = customer.contacts.filter((contact) => contact.email !== contactToDelete.email)

        await updateCustomer(newCustomer).unwrap()
        setContactToDelete(null)
    }

    const handleCloseCreateEditContactDialog = () => {
        setContactToEdit(null)
        setIsCreateContactDialogOpen(false)
    }

    return (
        <>
            <CustomerTabCard>
                <CustomerTabCardContent>
                    <ContactTable
                        contacts={contacts}
                        actions={{ handleDelete: handleDeleteContactAction, handleEdit: handleEditContactAction }}
                    />
                </CustomerTabCardContent>

                <CustomerTabCardButtonContainer>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={() => setIsCreateContactDialogOpen(true)}
                    >
                        {t('common.add')}
                    </Button>
                </CustomerTabCardButtonContainer>
            </CustomerTabCard>
            <DeleteDialog
                open={Boolean(contactToDelete)}
                handleClose={() => setContactToDelete(null)}
                deleteAction={handleDelete}
                isDeleting={isUpdating}
            />
            {contactType === 'customer' ? (
                <ContactCreateEditDialog
                    handleClose={handleCloseCreateEditContactDialog}
                    open={Boolean(contactToEdit) || isCreateContactDialogOpen}
                    contact={contactToEdit}
                    contactType="customer"
                    customer={customer}
                />
            ) : (
                <ContactCreateEditDialog
                    handleClose={handleCloseCreateEditContactDialog}
                    open={Boolean(contactToEdit) || isCreateContactDialogOpen}
                    contact={contactToEdit}
                    contactType="organization"
                    organization={organization}
                />
            )}
        </>
    )
}
