import React from 'react'

import { Button, SortDirection, Stack, Typography, styled } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { LoadingButton } from '@mui/lab'
import { useDeleteProjectsMutation, useGetProjectsQuery } from 'services/projects.service'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { useDeleteTemplateMutation } from 'services/templates.service'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useDataGridFiltersState } from 'utils/hooks/useDataGridFiltersState'
import { ProjectWithoutForms } from 'redux/types/project.type'
import { valueOrDefaultEmpty } from 'utils/general'
import { ProjectStatusCell } from 'components/projects/ProjectStatusCell'
import { DataGridNoRows } from 'components/general/table/DataGridNoRows'
import { TemplateEntityWithPartialStructure } from 'redux/types/template.type'
import { DataGridTitle } from 'components/general/table/DataGridTitle'

interface DeleteTemplateDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions'> {
    template: TemplateEntityWithPartialStructure
}

const DeleteTemplateDialog: React.FC<DeleteTemplateDialogProps> = ({ open, handleClose, template }) => {
    const { t } = useTranslation()

    const { handlePageChange, handleSortChange, page, sortBy } = useDataGridFiltersState({
        sort: 'desc',
        field: 'updated',
    })

    const {
        data: relatedOngoingProjects,
        isLoading,
        isFetching,
    } = useGetProjectsQuery({
        page: page || 0,
        itemsPerPage: DEFAULT_ROWS_PER_PAGE,
        direction: sortBy?.sort as SortDirection,
        sortBy: sortBy?.field,
        templateId: template.id,
    })

    const [deleteProjects, { isLoading: isDeletingProjects }] = useDeleteProjectsMutation()
    const [deleteTemplate, { isLoading: isDeletingTemplate }] = useDeleteTemplateMutation()
    const isDeleting = isDeletingProjects || isDeletingTemplate

    const columns: GridColDef<ProjectWithoutForms>[] = [
        {
            field: 'name',
            headerName: t('dashboard.projectName'),
            flex: 1,
            editable: false,
            minWidth: 200,
        },
        {
            field: 'type',
            headerName: t('dashboard.projectType'),
            flex: 1,
            editable: false,
            minWidth: 160,
            valueGetter: (params) => valueOrDefaultEmpty(params.row.type?.label),
        },
        {
            field: 'status',
            headerName: t('dashboard.projectStatus'),
            flex: 1,
            editable: false,
            width: 170,
            minWidth: 170,
            renderCell: (params) => <ProjectStatusCell status={params.row.status} />,
        },
        {
            field: 'updated',
            headerName: t('projectsTable.updatedHeader'),
            flex: 1,
            editable: false,
        },
    ]

    const handleClick = async () => {
        const deleteProjectsTask = deleteProjects({
            ids: (relatedOngoingProjects?.data || []).map((project) => project.id),
        }).unwrap()
        const deleteTemplateTask = deleteTemplate({
            id: template.id,
        }).unwrap()

        await Promise.all([deleteTemplateTask, deleteProjectsTask])

        handleClose()
    }

    return (
        <ModalsWrapper
            open={open}
            title={<Trans i18nKey="templatesPage.deleteTemplateDialogTitle" values={{ templateName: template.name }} />}
            handleClose={handleClose}
            fullWidth
            maxWidth="md"
            isLoading={isLoading}
            body={
                <ContentWrapper>
                    <Typography variant="body2">{t('templatesPage.deleteTemplateExplanation')}</Typography>
                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                textTransform: 'capitalize',
                            },
                            '& .MuiDataGrid-row': { cursor: 'pointer' },
                        }}
                        autoHeight
                        rows={relatedOngoingProjects?.data || []}
                        columns={columns}
                        pageSize={DEFAULT_ROWS_PER_PAGE}
                        rowCount={relatedOngoingProjects?.total || 0}
                        isRowSelectable={() => false}
                        disableColumnMenu
                        loading={isFetching}
                        hideFooterSelectedRowCount
                        rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                        components={{
                            NoRowsOverlay: DataGridNoRows,
                            Toolbar: DataGridTitle,
                        }}
                        componentsProps={{
                            toolbar: { title: t('common.projects') },
                        }}
                        paginationMode="server"
                        sortingMode="server"
                        onPageChange={handlePageChange}
                        onSortModelChange={handleSortChange}
                        page={page || 0}
                        sortModel={sortBy ? [{ field: sortBy.field, sort: sortBy.sort }] : []}
                        // hide updated column
                        // we need to add updated column
                        // so that we can initially sort by updated
                        columnVisibilityModel={{
                            updated: false,
                        }}
                    />
                </ContentWrapper>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={2}>
                    <Button variant="outlined" onClick={handleClose} color="secondary" disabled={isDeleting}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        form="copy-structure-and-template-form"
                        loading={isDeleting}
                        onClick={handleClick}
                        disabled={relatedOngoingProjects?.total !== 0}
                    >
                        {t('common.continue')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

export default DeleteTemplateDialog

const ActionWrapper = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
