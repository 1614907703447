import { DndContext, DragEndEvent } from '@dnd-kit/core'
import * as O from 'optics-ts'
import React from 'react'
import { BuilderArray } from './builder-fields/builder-array'
import { BuilderGroupLayout } from './builder-fields/builder-group'
import { BuilderHorizontalLayout, BuilderVerticalLayout } from './builder-fields/builder-layout'
import { BuilderRoot } from './builder-fields/builder-root'
import { BuilderSelect } from './builder-fields/builder-select'
import {
    BuilderBoolean,
    BuilderDate,
    BuilderFilePicker,
    BuilderLabel,
    BuilderNumber,
    BuilderString,
} from './builder-fields/builder-string'
import { createLens, getAdjustedPath, splitPath } from '../../utils/form-helper'
import { SchemaElement } from 'redux/types/schema.type'
import { UnqiueValueGroupTable } from './builder-fields/builder-group-table'
import { BuilderList } from './builder-fields/builder-list'
import { DynamicTableBuilder } from './builder-fields/builder-dynamic-table'
import { BuilderTagSelect } from './builder-fields/builder-tag-select'

type RenderProps = {
    state: SchemaElement
    setState: (x: any) => any
    lens: O.OpticFor_<SchemaElement>
    path: string
    droppable?: boolean
}

export const Render: React.FC<RenderProps> = ({ state, setState, lens, path, droppable = true }) => {
    const node = O.get(lens)(state)

    switch (node.type) {
        case 'root':
            return <BuilderRoot node={node} state={state} setState={setState} lens={lens} path={path} />
        case 'VerticalLayout':
            return <BuilderVerticalLayout node={node} state={state} setState={setState} lens={lens} path={path} />
        case 'HorizontalLayout':
            return <BuilderHorizontalLayout node={node} state={state} setState={setState} lens={lens} path={path} />
        case 'groupTable':
            return <UnqiueValueGroupTable node={node} state={state} setState={setState} lens={lens} path={path} />
        case 'Group':
            return <BuilderGroupLayout node={node} state={state} setState={setState} lens={lens} path={path} />
        case 'array':
            return <BuilderArray node={node} state={state} setState={setState} lens={lens} path={path} />
        case 'list':
            return (
                <BuilderList
                    node={node}
                    state={state}
                    setState={setState}
                    lens={lens}
                    path={path}
                    droppable={droppable}
                />
            )
        case 'select':
            return (
                <BuilderSelect
                    node={node}
                    state={state}
                    setState={setState}
                    lens={lens}
                    path={path}
                    droppable={droppable}
                />
            )
        case 'disciplinesTag':
            return <BuilderTagSelect node={node} state={state} setState={setState} lens={lens} path={path} />
        case 'buildingPart':
            return <BuilderTagSelect node={node} state={state} setState={setState} lens={lens} path={path} />
        case 'radio':
            return (
                <BuilderSelect
                    node={node}
                    state={state}
                    setState={setState}
                    lens={lens}
                    path={path}
                    droppable={droppable}
                />
            )
        case 'checkbox':
            return (
                <BuilderSelect
                    node={node}
                    state={state}
                    setState={setState}
                    lens={lens}
                    path={path}
                    droppable={droppable}
                />
            )
        case 'number':
            return (
                <BuilderNumber
                    node={node}
                    state={state}
                    setState={setState}
                    lens={lens}
                    path={path}
                    droppable={droppable}
                />
            )
        case 'date':
            return (
                <BuilderDate
                    node={node}
                    state={state}
                    setState={setState}
                    lens={lens}
                    path={path}
                    droppable={droppable}
                />
            )
        case 'string':
            return (
                <BuilderString
                    node={node}
                    state={state}
                    setState={setState}
                    lens={lens}
                    path={path}
                    droppable={droppable}
                />
            )
        case 'file':
            return <BuilderFilePicker node={node} state={state} setState={setState} lens={lens} path={path} />
        case 'boolean':
            return (
                <BuilderBoolean
                    node={node}
                    state={state}
                    setState={setState}
                    lens={lens}
                    path={path}
                    droppable={droppable}
                />
            )
        case 'dynamicTable':
            return <DynamicTableBuilder node={node} state={state} setState={setState} lens={lens} path={path} />
        case 'Label':
            return <BuilderLabel node={node} state={state} setState={setState} lens={lens} path={path} />
    }
    return null
}

type Props = {
    appSchema: SchemaElement
    setAppSchema: (schema: SchemaElement) => void
}

export const SchemaBuilder: React.FC<Props> = ({ appSchema, setAppSchema }) => {
    const rootLens = O.optic_<SchemaElement>()

    const onDragEnd = (event: DragEndEvent) => {
        if (event.over) {
            const activePath = event.active.id.toString()
            const overPath = event.over.id.toString()

            const activeLens = splitPath(activePath)
            const adjustedEverLens = createLens(getAdjustedPath(activePath, overPath))
            const active = O.get(activeLens.lens)(appSchema) as any[]

            setAppSchema(
                //@ts-ignore
                O.modify(activeLens.lens)((x: any[]) => [
                    ...x.slice(0, activeLens.index),
                    ...x.slice(activeLens.index + 1),
                ]),
            )
            setAppSchema(
                //@ts-ignore
                O.modify(adjustedEverLens.lens)((x: any[]) => [
                    ...x.slice(0, adjustedEverLens.index),
                    active[activeLens.index],
                    ...x.slice(adjustedEverLens.index),
                ]),
            )
        }
    }

    return (
        <DndContext onDragEnd={onDragEnd}>
            <Render state={appSchema} setState={setAppSchema} lens={rootLens} path={''} />
        </DndContext>
    )
}
