import { ArrColSizeDefinition, DynamicTableColSizeDefinition, GroupTableColSizeDefinition } from 'types/util'

export const getColSizes = () => {
    const sizes = ['auto']

    for (let i = 5; i <= 100; i += 5) {
        sizes.push(i.toString())
    }

    return sizes
}

export const validateColumnsSizes = (columns: Record<string, ArrColSizeDefinition>) => {
    let totalRegisteredColPercentage = 0
    let autoColumnsCount = 0
    const keys = Object.keys(columns)

    for (let i = 0; i < keys.length; i++) {
        const col = columns[keys[i]]

        if (col.size === 'auto') {
            autoColumnsCount++
        } else {
            totalRegisteredColPercentage += parseInt(col.size, 10)
        }
    }

    const minPercentageForAutoCols = autoColumnsCount * 5
    const totalSize = minPercentageForAutoCols + totalRegisteredColPercentage

    return totalSize <= 100
}

export const validateGroupTableColumnsSizes = (
    columns: Record<number, GroupTableColSizeDefinition> | Record<number, DynamicTableColSizeDefinition>,
) => {
    let totalRegisteredColPercentage = 0
    let autoColumnsCount = 0
    const keys = Object.keys(columns)

    for (let i = 0; i < keys.length; i++) {
        const col = columns[Number(keys[i])]

        if (col.size === 'auto') {
            autoColumnsCount++
        } else {
            totalRegisteredColPercentage += parseInt(col.size, 10)
        }
    }

    const minPercentageForAutoCols = autoColumnsCount * 5
    const totalSize = minPercentageForAutoCols + totalRegisteredColPercentage

    return totalSize <= 100
}
