import React from 'react'
import { Box, Typography } from '@mui/material'
import { TagChip } from 'components/general/chips/TagChip'
import { JSONFormViewComp, SchemaElementTypeMap } from 'redux/types/schemaView.type'

export const TagDisplay: React.FC<{ values: string[] }> = ({ values }) => (
    <Box display="flex" flexWrap="wrap" gap={1.5}>
        {values.map((tag: string) => (
            <TagChip label={tag} key={tag} />
        ))}
    </Box>
)

export const DisciplinesTagView: React.FC<JSONFormViewComp<SchemaElementTypeMap['disciplinesTag']>> = React.memo(
    ({ node }) => {
        if (!node.isVisible || !node.isVisibleInWeb || node.value === undefined || node.value.length === 0) {
            return null
        }
        return (
            <div>
                {node.label && node.isLabelVisible && (
                    <Typography sx={{ fontWeight: 600, mb: 1 }} variant="body1">
                        {node.label}
                    </Typography>
                )}

                <TagDisplay values={node.value} />
            </div>
        )
    },
)

export const BuildingPartTagView: React.FC<JSONFormViewComp<SchemaElementTypeMap['buildingPart']>> = React.memo(
    ({ node }) => {
        if (!node.isVisible || !node.isVisibleInWeb || node.value === undefined || node.value.length === 0) {
            return null
        }
        return (
            <div>
                <Typography sx={{ fontWeight: 600, mb: 1 }} variant="body1">
                    {node.label}
                </Typography>

                <TagDisplay values={node.value} />
            </div>
        )
    },
)
