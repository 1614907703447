import React from 'react'
import { Buildings } from './Buildings'
import { DetailsArchived } from './DetailsArchived'
import { useProjectView } from '../hooks/useProjectView'
import { ArchivedProjectFiles } from './ArchivedProjectFiles'
import { GoldenStandards } from './GoldenStandards'

export const ReadViewArchived: React.FC = () => {
    const { project } = useProjectView()

    if (!project) return null

    return (
        <>
            <DetailsArchived />
            <Buildings />
            <ArchivedProjectFiles />
            <GoldenStandards />
        </>
    )
}
