import Dropzone, { DropzoneProps } from 'react-dropzone'
import { Alert, Box, IconButton, styled, Typography } from '@mui/material'
import { Control, Controller, FieldErrors, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ControlledMuiSelect, { IOption } from 'components/inputs/ControlledMuiSelect'
import { selectProjectFileTagOptions } from 'redux/selectors/settings.selectors'
import { useAppSelector } from 'redux/hooks'
import { ControlledCheckbox } from 'components/inputs/ControlledCheckbox'
import { useDeleteFileMutation } from 'services/fileRTK.service'
import { Delete } from '@mui/icons-material'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import DeleteDialog from 'components/general/dialogs/DeleteDialog'
import React from 'react'
import { ProjectGeneralInfoRequestForm } from 'modules/projects/create/ProjectDetails'

interface DropzoneFieldProps extends DropzoneProps {
    control: Control<ProjectGeneralInfoRequestForm>
    setValue: UseFormSetValue<ProjectGeneralInfoRequestForm>
    multiple?: boolean
    files?: ProjectGeneralInfoRequestForm['files']
    selectedBuildings: ProjectGeneralInfoRequestForm['buildings']
    errors: FieldErrors<ProjectGeneralInfoRequestForm>
}

export const CustomProjectFileUpload = ({
    control,
    multiple,
    setValue,
    files,
    errors,
    selectedBuildings,
    ...rest
}: DropzoneFieldProps) => {
    const { t } = useTranslation()
    const tagOptions = useAppSelector(selectProjectFileTagOptions)
    const [deleteFile, { isLoading: isDeleting }] = useDeleteFileMutation()
    const [selectedFileToDeleteIdx, setSelectedFileToDeleteIdx] = React.useState<false | number>(false)

    const availableFloorOptions = React.useMemo<IOption[]>(() => {
        if (selectedBuildings.length !== 1) return []
        return selectedBuildings[0].floors.map((floor) => ({
            label: floor.name || floor.number.toString(),
            value: floor.number,
        }))
    }, [selectedBuildings])

    const handleFileDelete = async () => {
        if (selectedFileToDeleteIdx === false) return
        const file = files?.[selectedFileToDeleteIdx]

        if (!file) return
        const filteredFiles = [...files] as ProjectGeneralInfoRequestForm['files']
        filteredFiles.splice(selectedFileToDeleteIdx, 1)

        setValue('files', filteredFiles, {
            shouldValidate: true,
        })

        if ('id' in file) {
            await deleteFile({ id: file.id })
        }

        setSelectedFileToDeleteIdx(false)
    }

    const renderFileMetaControlls = (idx: number, alreadyUploaded: boolean = false) => {
        return (
            <>
                <ControlledMuiSelect
                    control={control}
                    name={alreadyUploaded ? `files.${idx}.projectTag.value` : `files.${idx}.projectTag`}
                    defaultValue=""
                    options={tagOptions}
                    variant="filled"
                    sx={{ width: 300 }}
                />
                <ControlledMuiSelect
                    control={control}
                    name={`files.${idx}.relatedFloors`}
                    options={availableFloorOptions}
                    variant="filled"
                    sx={{ width: 100 }}
                    label={t('common.floor')}
                    disabled={selectedBuildings.length !== 1}
                    multiple
                />
                <ControlledMuiTextField
                    control={control}
                    multiline
                    name={`files.${idx}.fileDescription`}
                    variant="filled"
                    rows={1}
                    placeholder={t('projectPage.projectFileDescriptionLabel')}
                />
                <ControlledCheckbox
                    control={control}
                    name={`files.${idx}.isInternal`}
                    label={t('projectPage.projectFileUploadInternal')}
                />
                <IconButton color="error" onClick={() => setSelectedFileToDeleteIdx(idx)}>
                    <Delete />
                </IconButton>
            </>
        )
    }

    return (
        <>
            <Controller
                control={control}
                name="files"
                render={({ field: { onChange, onBlur }, fieldState }) => (
                    <Dropzone
                        noClick
                        onDrop={(acceptedFiles) => {
                            const newFiles = [
                                ...(files || []),
                                ...acceptedFiles.map((f) => ({
                                    projectTag: tagOptions[0].value,
                                    file: f,
                                    isInternal: false,
                                    fileDescription: '',
                                    relatedFloors: [],
                                })),
                            ] as ProjectGeneralInfoRequestForm['files']
                            setValue('files', newFiles, {
                                shouldValidate: true,
                            })
                        }}
                        {...rest}
                    >
                        {({ getRootProps, getInputProps, open }) => (
                            <section>
                                <UploadBlock
                                    {...getRootProps({ className: 'dropzone' })}
                                    onClick={open}
                                    role="button"
                                    haserror={'files' in errors}
                                >
                                    <input
                                        {...getInputProps({
                                            onChange,
                                            onBlur,
                                        })}
                                    />

                                    <p>{t('common.dropOrSelectFiles')}</p>
                                </UploadBlock>
                                {fieldState.error?.message && (
                                    <Box display="flex" margin="0 auto" maxWidth={300}>
                                        <Alert severity="error" sx={{ width: '100%' }}>
                                            {t(fieldState.error.message)}
                                        </Alert>
                                    </Box>
                                )}
                                <aside>
                                    <Typography variant="h6">{t('common.files')}</Typography>
                                    <ul>
                                        {(files || []).map((projectFile, idx) => {
                                            // recently uploaded
                                            if ('file' in projectFile) {
                                                return (
                                                    <StyledListItem key={projectFile.file.name}>
                                                        <FileName variant="body1">{projectFile.file.name}</FileName>
                                                        {renderFileMetaControlls(idx)}
                                                    </StyledListItem>
                                                )
                                            }

                                            return (
                                                <StyledListItem key={projectFile.id}>
                                                    <FileName variant="body1">{projectFile.filename}</FileName>
                                                    {renderFileMetaControlls(idx, true)}
                                                </StyledListItem>
                                            )
                                        })}
                                    </ul>
                                </aside>
                            </section>
                        )}
                    </Dropzone>
                )}
            />
            <DeleteDialog
                open={selectedFileToDeleteIdx !== false}
                handleClose={() => setSelectedFileToDeleteIdx(false)}
                deleteAction={handleFileDelete}
                isDeleting={isDeleting}
            />
        </>
    )
}

const UploadBlock = styled('div')<{ haserror: boolean }>(({ theme, haserror }) => ({
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#eee',
    margin: theme.spacing(2, 0),
    border: haserror ? `1px dashed ${theme.palette.error.main}` : 'none',
}))

const StyledListItem = styled('li')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    padding: theme.spacing(1, 0),
}))

const FileName = styled(Typography)({
    maxWidth: 180,
    width: '100%',
})
