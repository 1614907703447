import { TopBar } from '../../components/general/top-bar/top-bar'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { navigationLinks } from 'utils/navigation-utils'
import { BuildingsTable } from 'modules/buildings/buildings/BuildingsTable'
import { Helmet } from 'react-helmet'
import { PageContainer } from 'components/general/containers/PageContainer'

export const BuildingsPage: React.FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <>
            <Helmet>
                <title>{t('navigationMenu.buildings')}</title>
            </Helmet>
            <TopBar
                rightActions={[
                    <div>
                        <Button
                            startIcon={<Add />}
                            color="secondary"
                            variant="contained"
                            onClick={() => navigate(navigationLinks.toAddBuilding())}
                        >
                            {t('buildingsPage.addBuilding')}
                        </Button>
                    </div>,
                ]}
            />
            <PageContainer>
                <BuildingsTable />
            </PageContainer>
        </>
    )
}
