import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { TopBar } from 'components/general/top-bar/top-bar'
import { PageLoader } from 'components/general/loaders/PageLoader'
import { navigationLinks } from 'utils/navigation-utils'
import { HeaderButton } from 'components/general/buttons/HeaderButton'
import { useTranslation } from 'react-i18next'
import { FileService } from 'services/file.service'
import { toast } from 'react-toastify'
import { HeaderBackButton } from 'components/general/navigation/HeaderBackButton'
import MenuIcon from '@mui/icons-material/Menu'
import { HeaderIconButton } from 'components/general/buttons/HeaderIconButton'
import { SendLinkToCustomerDialog } from 'modules/dashboard/main/SendLinkToCustomerDialog'
import { ProjectReadView } from 'modules/projects/view/ReadView'
import { ProjectActionsMenu } from 'modules/projects/preview/ProjectActionsMenu'
import { useProjectView } from 'modules/projects/hooks/useProjectView'
import { PdfViewDialog } from 'components/general/dialogs/PdfViewDialog'
import { ReadViewArchived } from 'modules/projects/view/ReadViewARchived'
import { PageContainer } from 'components/general/containers/PageContainer'
import { ProjectMobileActions } from 'components/projects/ProjectMobileActions'

export const ProjectPreview: React.FC = () => {
    const { t } = useTranslation()
    const { state } = useLocation()

    const { isLoading, project, projectId, isError, revisions } = useProjectView()

    const [isDownloading, setIsDownloading] = React.useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
    const [isFormNavigationDrawerOpen, setIsFormNavigationDrawerOpen] = React.useState(false)
    const [isSendLinkDialogOpen, setIsSendLinkDialogOpen] = React.useState(false)

    const [report, setReport] = React.useState<string | null>(null)
    // todo if more states appear move to seperate function
    const backLink = state?.from || navigationLinks.toCreateProject(projectId)

    const handlePdfDownload = async () => {
        if (!project?.report) return

        setIsDownloading(true)
        try {
            const url = await FileService.DownloadAndCreateBlobUrl(project.report.id, project.report.accessToken)
            setReport(url)
        } catch (err: any) {
            toast.error(err?.translationKey ? t(err.translationKey) : t('genericErrorMessage'))
        }
        setIsDownloading(false)
    }

    const handleToggleDrawer = (open: boolean, target: 'actionDrawer' | 'navigationDrawer') => {
        target === 'actionDrawer' ? setIsDrawerOpen(open) : setIsFormNavigationDrawerOpen(open)
    }

    if (isLoading) {
        return <PageLoader />
    }

    if (isError) {
        return <Navigate to="/" />
    }

    return (
        <>
            <Helmet>
                {project && <title>{`${project.type?.label} - ${project.buildings[0].address.addressText}`}</title>}
            </Helmet>
            <TopBar
                preLogoActions={
                    <ProjectMobileActions onOpenNavigationDrawer={() => handleToggleDrawer(true, 'navigationDrawer')} />
                }
                leftActions={<HeaderBackButton label={project?.name} href={backLink} />}
                rightActions={[
                    ...(project?.creationType === 'ByDocumentUpload'
                        ? []
                        : [
                              <HeaderButton
                                  label={t('projectPage.customerViewPdf')}
                                  onClick={handlePdfDownload}
                                  isLoading={isDownloading}
                              />,
                          ]),
                    <HeaderButton
                        label={t('projectPage.sendToCustomer')}
                        onClick={() => setIsSendLinkDialogOpen(true)}
                        isLoading={isDownloading}
                    />,
                    <HeaderIconButton
                        icon={<MenuIcon />}
                        onClick={() => handleToggleDrawer(true, 'actionDrawer')}
                        size="small"
                    />,
                ]}
            />
            <PageContainer>
                {project?.creationType === 'ByDefaultFlow' ? (
                    <ProjectReadView
                        isNavigationDrawerOpen={isFormNavigationDrawerOpen}
                        onDrawerToggle={(open: boolean) => handleToggleDrawer(open, 'navigationDrawer')}
                    />
                ) : (
                    <ReadViewArchived />
                )}
            </PageContainer>
            <ProjectActionsMenu
                isOpen={isDrawerOpen}
                onClose={() => handleToggleDrawer(false, 'actionDrawer')}
                project={project}
                revisions={revisions}
            />
            {isSendLinkDialogOpen && (
                <SendLinkToCustomerDialog
                    handleClose={() => setIsSendLinkDialogOpen(false)}
                    open={Boolean(isSendLinkDialogOpen)}
                    project={project!}
                />
            )}
            <PdfViewDialog open={report !== null} handleClose={() => setReport(null)} url={report} />
        </>
    )
}
