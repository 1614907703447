import { useGetTenantAttributesQuery } from 'services/tenants.service'
import {
    ActionWrapper,
    LeftActionsWrapper,
    LogoContainer,
    PreLogoActionsWrapper,
    RightAction,
    StyledAppBar,
} from './styles'
import { TopBarProps } from './top-bar'
import { ReactComponent as LogoWhite } from 'assets/icons/logo_white.svg'
import { Link } from 'react-router-dom'
import { LanguageSelect } from './LanguageSelect'
import { Hidden } from '@mui/material'

type UnauthorizedTopBarProps = {
    tenantId?: string
} & TopBarProps

export const UnauthorizedTopBar: React.FC<UnauthorizedTopBarProps> = ({
    rightActions = [],
    tenantId,
    leftActions,
    preLogoActions,
}) => {
    const { data: tenantAttributes, isFetching } = useGetTenantAttributesQuery({ id: tenantId! }, { skip: !tenantId })

    if (tenantId && (!tenantAttributes || isFetching)) return null

    return (
        <StyledAppBar>
            <ActionWrapper>
                {preLogoActions && <PreLogoActionsWrapper>{preLogoActions}</PreLogoActionsWrapper>}
                <Link to="/">
                    {tenantAttributes?.logoUrl ? (
                        <LogoContainer>
                            <img alt="logo" src={tenantAttributes.logoUrl} />
                        </LogoContainer>
                    ) : (
                        <div>
                            <LogoWhite width={140} height="100%" />
                        </div>
                    )}
                </Link>
                <LeftActionsWrapper>{leftActions && leftActions}</LeftActionsWrapper>
            </ActionWrapper>
            <ActionWrapper>
                <Hidden mdDown>
                    <LanguageSelect />
                </Hidden>
                {rightActions.map((a: any, index: number) => (
                    <RightAction key={`action_${index}`}>{a}</RightAction>
                ))}
            </ActionWrapper>
        </StyledAppBar>
    )
}
