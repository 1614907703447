export const ROUTES = {
    // projects
    projectCreate: '/projects/create/:projectId',
    projectReview: '/projects/review/:projectId',
    projectPreview: '/projects/preview/:projectId',
    projectPreviewInProgress: '/projects/preview-in-progress/:projectId',

    // public
    login: '/login',
    loginAdmin: '/login-admin',
    forgotPassword: '/forgot',
    resetPassword: '/auth/reset',
    authenticateCustomer: '/authenticate',

    // tenants
    tenant: '/tenants/:id',

    // base routes
    customers: '/customers',
    tenants: '/tenants',
    buildings: '/buildings',
    users: '/users',
    projects: '/projects',
    settings: '/settings',
    schemas: '/schemas',
    forms: '/forms',
    dashboard: '/dashboard',
    organizations: '/organizations',

    // buildings
    building: '/buildings/:id',
    buildingEdit: '/buildings/edit/:id',
    buildingAdd: '/buildings/add',
    buildingsAddCustomer: '/buildings/add/:customerId',

    // customers
    customer: '/customers/:id',

    // schemas
    schemaAdd: '/schemas/add',
    schemaEdit: '/schemas/edit/:id',

    // forms
    formAdd: '/forms/add',
    formEdit: '/forms/edit/:id',

    // settings
    prebuiltOptions: '/settings/prebuilt-options',
    schemaTemplates: '/settings/templates',
    schemTemplateEdit: '/settings/templates/edit/:templateId',

    // public
    buildingPublic: '/buildings/view/:id',
    projectCustomerView: '/projects/view/:projectId',

    // private routes for customer roles
    customerHome: '/home',
    customerBuilding: '/my-buildings/:buildingId',

    // organizations
    organization: '/organizations/:id',
} as const
