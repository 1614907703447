import React from 'react'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { FieldValues, Controller, Control, Path } from 'react-hook-form'
import { IOption } from './ControlledMuiSelect'

interface ControlledMuiSelectSearchableProps<O extends IOption & {}, TField extends FieldValues>
    extends Omit<
        AutocompleteProps<O, false, false, false>,
        'renderInput' | 'value' | 'onChange' | 'defaultValue' | 'freeSolo' | 'options' | 'multiple' | 'inputValue'
    > {
    control: Control<TField>
    name: Path<TField>
    options: O[]
    placeholder?: string
    onChangeCb?: (value: IOption['value'] | null) => void
    withDisabledOptions?: boolean
}

// this is simplified version of ControlledMuiAutoComplete with less props
// but its easier to work with
export const ControlledMuiSelectSearchable = <O extends IOption & {}, TField extends FieldValues>(
    props: ControlledMuiSelectSearchableProps<O, TField>,
) => {
    const { control, options, name, onChangeCb, withDisabledOptions, ...rest } = props

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                const { onChange, value, ref } = field
                return (
                    <>
                        <Autocomplete
                            value={
                                value
                                    ? options.find((option) => {
                                          return value === option.value
                                      }) ?? null
                                    : null
                            }
                            getOptionLabel={(option) => {
                                return option.label
                            }}
                            onChange={(event: any, newValue) => {
                                onChange(newValue ? newValue.value : null)
                                onChangeCb?.(newValue?.value ?? null)
                            }}
                            getOptionDisabled={withDisabledOptions ? (opt) => Boolean(opt.disabled) : undefined}
                            options={options}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={props.placeholder}
                                    inputRef={ref}
                                    error={Boolean(error)}
                                    helperText={error?.message}
                                />
                            )}
                            {...rest}
                        />
                    </>
                )
            }}
        />
    )
}
