import React from 'react'
import { HorizontalLayoutView } from './HorizontalLayout'
import { NumberView } from './Number'
import { LabelView } from './Label'
import { ArrayView } from './ArrayView'
import { GroupView } from './Group'
import { BoolView } from './Bool'
import { SelectView } from './Select'
import { RadioView } from './Radio'
import { CheckboxView } from './Checkbox'
import { DateView } from './Date'
import { GroupTable } from './GroupTable'
import { ListView } from './ListView'
import { DynamicTable } from './DynamicTable'
import { BuildingPartTagView, DisciplinesTagView } from './DisciplinesTag'
import { AllViewSchemaElements, ViewFieldComponents } from 'redux/types/schemaView.type'
import { TextFieldView } from './TextField'
import { TextAreaView } from './TextArea'

const VIEW: ViewFieldComponents = {
    textField: TextFieldView,
    textArea: TextAreaView,
    radio: RadioView,
    number: NumberView,
    select: SelectView,
    checkbox: CheckboxView,
    boolean: BoolView,
    date: DateView,
    disciplinesTag: DisciplinesTagView,
    buildingPart: BuildingPartTagView,
    Label: LabelView,
    list: ListView,
    array: ArrayView,
    dynamicTable: DynamicTable,
    groupTable: GroupTable,
    HorizontalLayout: HorizontalLayoutView,
    Group: GroupView,
}

export const RenderView: React.FC<{
    element: AllViewSchemaElements
    direction?: 'column' | 'row'
}> = React.memo(({ element, direction }) => {
    const Component = VIEW[element.type]
    return Component ? <Component node={element} key={element.type} direction={direction} /> : null
})
