import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/system'
import { Button, Typography } from '@mui/material'

type NoSchemaAssignedProps = {
    navigateToNextForm: () => void
}

export const NoSchemaAssigned: React.FC<NoSchemaAssignedProps> = ({ navigateToNextForm }) => {
    const { t } = useTranslation()

    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Typography textAlign="center" sx={{ my: 2 }}>
                {t('templateEditPage.noSchemaAssignedForStructure')}
            </Typography>
            <Button
                type="submit"
                variant="outlined"
                color="primary"
                sx={{ minWidth: 200 }}
                onClick={navigateToNextForm}
            >
                {t('common.next')}
            </Button>
        </Box>
    )
}
