import React from 'react'
import { Control } from 'react-hook-form'
import { ControlledMuiAutoComplete } from 'components/inputs/ControlledMuiAutoComplete'
import { Building, BuildingsPaginationParams } from 'redux/types/building.type'
import { useLazyGetBuildingsQuery } from 'services/buildings.service'
import { debounce } from 'lodash'
import { Box, Typography, autocompleteClasses } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CreateUpdateOrganizationPermissionForm } from 'schemas/organizationPermissions'

interface BuildingsAutoCompleteProps {
    control: Control<CreateUpdateOrganizationPermissionForm>
    defaultValue?: Building
    disabled: boolean
}

const FETCH_RECORD_COUNT = 20
const INITIAL_FETCH_QUERY: BuildingsPaginationParams = {
    page: 0,
    itemsPerPage: FETCH_RECORD_COUNT,
    sortBy: 'updated',
    direction: 'desc',
}

// this is almost the same input as in project general form, but cant think of a way of making this
// component reusable as passing a generic form type to control doesnt work - cant set the input name then
export const BuildingsAutoComplete = ({ control, defaultValue, disabled }: BuildingsAutoCompleteProps) => {
    const { t } = useTranslation()
    const [fetchBuildings, { isFetching, data: buildings }] = useLazyGetBuildingsQuery({})
    const fetchedOnce = React.useRef(false)

    React.useEffect(() => {
        if (defaultValue) return
        fetchBuildings(INITIAL_FETCH_QUERY, true)
    }, [defaultValue, fetchBuildings])

    const fetch = React.useMemo(
        () =>
            debounce((searchQuery) => {
                if (searchQuery.length === 0) {
                    fetchBuildings(INITIAL_FETCH_QUERY, true)
                    return
                }

                fetchedOnce.current = true
                fetchBuildings(
                    {
                        page: 0,
                        itemsPerPage: FETCH_RECORD_COUNT,
                        sortBy: 'updated',
                        direction: 'desc',
                        searchQuery,
                    },
                    true,
                )
            }, 400),
        [fetchBuildings],
    )

    const handleInputChange = (value: string) => {
        fetch(value)
    }

    return (
        <ControlledMuiAutoComplete
            control={control}
            name="building"
            options={buildings?.data || []}
            getOptionLabel={(opt) => opt?.address.addressText ?? ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onInputChange={(event, newInputValue) => handleInputChange(newInputValue)}
            filterOptions={(opt) => opt}
            loading={isFetching}
            label={t('organizationPage.selectBuildingPlaceholder')}
            disabled={disabled}
            renderOption={(props, option, state) => {
                return (
                    <Box
                        sx={{
                            flexDirection: 'column',
                            margin: '2px 0px',
                            [`&.${autocompleteClasses.option}`]: {
                                padding: '4px',
                                alignItems: 'flex-start',
                            },
                        }}
                        component="li"
                        {...props}
                        key={option?.id}
                    >
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                            {option?.address.addressText}
                        </Typography>
                        <Typography variant="caption">
                            {`${t('common.cadastreNumber')}: ${option?.cadastre?.cleanNumber || '-'}, ${t(
                                'buildingNumber',
                            )}: ${option?.buildingNumber || '-'}`}
                        </Typography>
                    </Box>
                )
            }}
        />
    )
}
