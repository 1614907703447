import React from 'react'
import { CreateOrUpdateBuildingForm } from 'redux/types/building.type'
import { Box } from '@mui/material'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { ControlledFireDepartmentAutoComplete } from 'components/inputs/custom/ControlledFireDepartmentAutoComplete'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ControlledMuiSelect from 'components/inputs/ControlledMuiSelect'
import { useGetDepartmentStationsQuery } from 'services/fireDepartments.service'
import { SectionBlock } from 'components/buildings/SectionBlock'
import { FormItem } from 'components/buildings/FormItem'
import { ControlledCheckbox } from 'components/inputs/ControlledCheckbox'

export const ServiceSectionForm: React.FC = () => {
    const { t } = useTranslation()
    const { control, watch, setValue } = useFormContext<CreateOrUpdateBuildingForm>()
    const selectedDepartment = watch('fireDepartment')

    const { data: stations } = useGetDepartmentStationsQuery(
        { departmentId: selectedDepartment?.id || '' },
        {
            skip: !selectedDepartment,
            selectFromResult: ({ data }) => ({
                data: (data || []).map((station) => ({ label: station.name, value: station.id })),
            }),
        },
    )

    const handleDepartmentChange = () => {
        setValue('fireStation', '')
    }

    return (
        <SectionBlock>
            <FormItem label={t('createEditBuildingPage.isFireDepartmentRegistered')}>
                <ControlledCheckbox control={control} name="isFireDepartmentRegistred" sx={{ paddingBottom: 0 }} />
            </FormItem>

            <FormItem label={t('common.fireDepartment')}>
                <Box maxWidth={390}>
                    <ControlledFireDepartmentAutoComplete control={control} onChangeCb={handleDepartmentChange} />
                </Box>
            </FormItem>

            <FormItem label={t('common.fireStation')}>
                <ControlledMuiSelect
                    disabled={!selectedDepartment}
                    control={control}
                    name="fireStation"
                    options={stations}
                    sx={{ maxWidth: 390 }}
                    fullWidth
                    label={t('common.fireStation')}
                />
            </FormItem>

            <FormItem label={t('createEditBuildingPage.fireStationDistance')}>
                <ControlledMuiTextField
                    control={control}
                    name="distanceToStation"
                    sx={{ maxWidth: 100 }}
                    type="number"
                    inputSuffix={<span>km</span>}
                    placeholder={t('common.distance')}
                />
            </FormItem>

            <FormItem label={t('common.comments')} sxTitleContainer={{ alignSelf: 'flex-start' }}>
                <ControlledMuiTextField
                    control={control}
                    sx={{ maxWidth: 600 }}
                    fullWidth
                    multiline
                    placeholder={t('common.comments')}
                    name="comment"
                    variant="outlined"
                    rows={5}
                />
            </FormItem>
        </SectionBlock>
    )
}
