import { Tab, Tabs } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export enum ECustomerHomeTabs {
    Buildings = 'Buildings',
    Projects = 'Projects',
}

const BUILDING_TAB = {
    label: 'buildings',
    value: ECustomerHomeTabs.Buildings,
}
const PROJECT_TAB = {
    label: 'projects',
    value: ECustomerHomeTabs.Projects,
}

type CustomerHomeTabsProps = {
    activeTab: ECustomerHomeTabs
    onActiveTabChange: (newTab: ECustomerHomeTabs) => void
    isCustomerFromOrganization: boolean
    isCustomerAssignedToTenantCustomers: boolean
}

export const CustomerHomeTabs: React.FC<CustomerHomeTabsProps> = ({
    activeTab,
    onActiveTabChange,
    isCustomerFromOrganization,
    isCustomerAssignedToTenantCustomers,
}) => {
    const { t } = useTranslation()

    const tabs = React.useMemo(() => {
        const tabs = []
        if (isCustomerFromOrganization) tabs.push(BUILDING_TAB)
        if (isCustomerAssignedToTenantCustomers) tabs.push(PROJECT_TAB)
        return tabs
    }, [isCustomerAssignedToTenantCustomers, isCustomerFromOrganization])

    return (
        <Tabs value={activeTab} onChange={(_, newTab) => onActiveTabChange(newTab)}>
            {tabs.map((tab) => {
                return (
                    <Tab key={tab.value} value={tab.value} label={t(`common.${tab.label}`)} sx={{ fontWeight: 700 }} />
                )
            })}
        </Tabs>
    )
}
