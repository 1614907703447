import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Divider, IconButton, InputAdornment, Stack, TextField, styled } from '@mui/material'
import ModalsWrapper from 'components/general/dialogs/DialogWrapper'
import { TagChip } from 'components/general/chips/TagChip'
import { Add, Search } from '@mui/icons-material'
import { IOption } from 'components/inputs/ControlledMuiSelect'

interface TabDialogProps {
    open: boolean
    onClose: () => void
    options: Array<IOption> | null
    onSave: (values: Array<IOption>) => void
    selectedOptions?: Array<IOption>
    searchable?: boolean
    onOpenCustomTagDialog?: () => void
}

/**
 * Dialog compoent used for ControlledTagInput and ControlledAddableTagInput components
 */
export const TagDialog: React.FC<TabDialogProps> = ({
    onClose,
    open,
    options,
    onSave,
    selectedOptions,
    searchable,
    onOpenCustomTagDialog,
}) => {
    const { t } = useTranslation()

    const [searchQuery, setSearchQuery] = React.useState('')

    const availableOptions = React.useMemo(() => {
        if (searchQuery) {
            return (options || []).filter((availableOpt) => {
                const hasLabel = availableOpt.label !== ''
                const selected = (selectedOptions || []).some((selectedOpt) => selectedOpt.value === availableOpt.value)
                const inQuery = availableOpt.label.toLowerCase().includes(searchQuery)

                return !selected && inQuery && hasLabel
            })
        }

        return (options || []).filter(
            (availableOpt) => !(selectedOptions || []).some((selectedOpt) => selectedOpt.value === availableOpt.value),
        )
    }, [options, searchQuery, selectedOptions])

    const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        e.stopPropagation()
        // ignoring it because its type safe, just ts doesnt have we have this input
        // @ts-ignore
        setSearchQuery(e.target.query.value.trim().toLowerCase())
    }

    const handleClose = () => {
        onClose()
        setSearchQuery('')
    }

    return (
        <ModalsWrapper
            open={open}
            title={t('createEditBuildingPage.selectOccupationsDialogTitle')}
            handleClose={handleClose}
            fullWidth
            maxWidth="md"
            body={
                <>
                    <ContentWrapper>
                        {selectedOptions?.map((opt, idx) => (
                            <TagChip
                                key={`${opt.value}-${idx}`}
                                label={opt.label}
                                tagDescription={opt.description}
                                onDelete={() =>
                                    onSave(selectedOptions.filter((selectedOpt) => selectedOpt.value !== opt.value))
                                }
                            />
                        ))}
                    </ContentWrapper>
                    <Divider />
                    <Box display="flex" alignItems="flex-end">
                        {searchable && (
                            <form onSubmit={handleSearch}>
                                <TextField
                                    sx={{ mt: 2, pl: 2 }}
                                    type="text"
                                    name="query"
                                    size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton type="submit">
                                                    <Search />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </form>
                        )}
                        {onOpenCustomTagDialog && (
                            <CustomTagDialogBtnContainer>
                                <Button variant="outlined" startIcon={<Add />} onClick={onOpenCustomTagDialog}>
                                    {t('common.addCustom')}
                                </Button>
                            </CustomTagDialogBtnContainer>
                        )}
                    </Box>
                    <ContentWrapper>
                        {availableOptions.map((opt, idx) => (
                            <TagChip
                                key={`available-${opt.value}-${idx}`}
                                label={opt.label}
                                value={opt}
                                tagDescription={opt.description}
                                onClick={() => onSave([...(selectedOptions || []), opt])}
                            />
                        ))}
                    </ContentWrapper>
                </>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button variant="outlined" onClick={handleClose} color="secondary">
                        {t('common.back')}
                    </Button>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(2),
    margin: theme.spacing(2, 0),
    overflow: 'auto',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
    },
}))

const CustomTagDialogBtnContainer = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(4),
}))
