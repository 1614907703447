import React from 'react'
import { FieldValues, Control } from 'react-hook-form'
import { ControlledTagInput, ControlledTagInputProps } from './ControlledTagInput'

interface ControlledAddableTagInputProps<T extends FieldValues> extends ControlledTagInputProps<T> {
    renderDialog: (open: boolean, onClose: () => void, control: Control<T>) => React.ReactNode
}

export const ControlledAddableTagInput = <T extends FieldValues>({
    options,
    control,
    name,
    searchable,
    onChangeCb,
    renderDialog,
}: ControlledAddableTagInputProps<T>) => {
    const [isAddCustomTagDialogOpen, setIsAddCustomTagDialogOpen] = React.useState(false)

    return (
        <>
            <ControlledTagInput
                options={options}
                control={control}
                name={name}
                searchable={searchable}
                onChangeCb={onChangeCb}
                onOpenCustomTagDialog={() => setIsAddCustomTagDialogOpen(true)}
            />
            {renderDialog(isAddCustomTagDialogOpen, () => setIsAddCustomTagDialogOpen(false), control)}
        </>
    )
}
