import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Box, Button, Grid, SelectChangeEvent, Typography } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { ProjectGeneralInfoRequestForm } from './ProjectDetails'
import { StyledIconButton } from 'components/projects/EditButton'
import { useCreateProjectContext } from '../context/CreateProjectContext'
import { GoldenStandardFormRow } from './GoldenStandardFormRow'

interface ProjectGoldenStandardEditModeProps {
    isEditMode: boolean
    onEditModeChange: (value: boolean) => void
}

export const ProjectGoldenStandardEditMode: React.FC<ProjectGoldenStandardEditModeProps> = ({
    isEditMode,
    onEditModeChange,
}) => {
    const { t } = useTranslation()
    const { control, setValue, reset } = useFormContext<ProjectGeneralInfoRequestForm>()
    const { standards } = useCreateProjectContext()

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'goldenStandards',
    })

    const handleCancelEditMode = () => {
        reset()
        onEditModeChange(false)
    }

    const handleAddStandard = () => {
        append({
            name: null,
            fieldValueType: 'string',
        })
    }

    const handleValueTypeChange = (e: SelectChangeEvent<unknown>, fieldListIdx: number) => {
        setValue(`goldenStandards.${fieldListIdx}.booleanValue`, e.target.value === 'boolean' ? false : undefined)
        setValue(`goldenStandards.${fieldListIdx}.stringValue`, undefined)
        setValue(`goldenStandards.${fieldListIdx}.numberValue`, undefined)
    }

    return (
        <Box sx={{ maxWidth: '1200px' }}>
            {isEditMode && (
                <StyledIconButton onClick={handleCancelEditMode}>
                    <Cancel />
                </StyledIconButton>
            )}
            <div>
                <Button onClick={handleAddStandard} variant="contained" color="primary" sx={{ mb: 2 }}>
                    {t('common.addField')}
                </Button>
            </div>
            <Grid container columnSpacing={1} rowSpacing={2} alignItems="flex-end">
                <Grid item xs={4}>
                    {t('projectPage.goldenStandardHeaders.standard')}
                </Grid>

                <Grid item xs={2}>
                    {t('projectPage.goldenStandardHeaders.valueType')}
                </Grid>

                <Grid item xs={4}>
                    {t('projectPage.goldenStandardHeaders.value')}
                </Grid>

                <Grid item xs={1} />

                {fields.length > 0 ? (
                    fields.map((field, idx) => (
                        <GoldenStandardFormRow
                            key={field.id}
                            idx={idx}
                            goldenStandardOptions={standards}
                            onRemove={remove}
                            control={control}
                            onValueTypeChange={handleValueTypeChange}
                            fieldValueTypeOptions={[
                                {
                                    label: t('projectPage.goldenStandardTypeOptionLabels.text'),
                                    value: 'string',
                                },
                                {
                                    label: t('projectPage.goldenStandardTypeOptionLabels.number'),
                                    value: 'number',
                                },
                                {
                                    label: t('projectPage.goldenStandardTypeOptionLabels.boolean'),
                                    value: 'boolean',
                                },
                            ]}
                        />
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Box sx={{ minHeight: 100 }}>
                            <Typography variant="h6" textAlign="center" sx={{ mt: '75px' }}>
                                {t('projectPage.noStandardsAssignedOnProject')}
                            </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}
