import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { jwtDecode } from 'jwt-decode'
import { useAppSelector } from '../hooks'
import { RootState } from '../store'
import { authApi, LoginResponse } from '../../services/auth.service'
import { Role } from 'types/role'

type AuthState =
    | {
          state: 'authorized'
          accessToken: string
          refreshToken: string
          expires: string
          roles: Role[]
          id: string
          tenantId: string | null
          customerIds: string[] | null
          organizationIds: string[] | null
      }
    | {
          state: 'unauthorized'
          accessToken: null
          refreshToken: null
          expires: null
          roles: null
          id: null
          tenantId: null
          customerIds: null
          organizationIds: null
      }

const slice = createSlice({
    name: 'auth',
    initialState: {
        accessToken: null,
        refreshToken: null,
        expires: null,
        id: null,
        roles: null,
        customerIds: null,
        organizationIds: null,
        tenantId: null,
    } as AuthState,
    reducers: {
        logout: (state) => {
            state.state = 'unauthorized'
            state.accessToken = null
            state.refreshToken = null
            state.expires = null
            state.id = null
            state.tenantId = null
            state.roles = null
            state.organizationIds = null
            state.customerIds = null
        },
        setCredentials: (state, { payload }: PayloadAction<LoginResponse>) => {
            state.state = 'authorized'
            state.accessToken = payload.accessToken
            state.refreshToken = payload.refreshToken
            state.expires = payload.expires
            state.id = payload.userId
            state.tenantId = payload.tenantId
            state.roles = payload.roles
            state.organizationIds = payload.userOrganizationIds
            state.customerIds = payload.userCustomerIds
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(authApi.endpoints.loginCustomer.matchFulfilled, (state, { payload }) => {
            state.state = 'authorized'
            state.accessToken = payload.accessToken
            state.refreshToken = payload.refreshToken
            state.expires = payload.expires
            state.id = payload.userId
            state.tenantId = payload.tenantId
            state.roles = payload.roles
            state.organizationIds = payload.userOrganizationIds
            state.customerIds = payload.userCustomerIds
        })
    },
})

export const authReducer = slice.reducer
export const authActions = slice.actions

const selectAuthState = (state: RootState) => state.auth
export const selectId = (state: RootState) => state.auth.id
export const selectTenantId = (state: RootState) => state.auth.tenantId
export const selectRoles = (state: RootState) => state.auth.roles
export const selectUser = (state: RootState) => state.auth.state

export const useUserTenantId = (): string => {
    return useAppSelector(selectTenantId)!
}

export const useHasRole = (role: Role): boolean => {
    const roles = useAppSelector(selectRoles)
    return roles ? roles.includes(role) : false
}

export const useIsAuthenticated = (): boolean => {
    return useAppSelector(selectId) != null
}

export const selectUserOrganizationIds = createSelector(selectAuthState, (state) => state.organizationIds)

export const selectUserCustomerIds = createSelector(selectAuthState, (state) => state.customerIds)

export const selectExpirationTokenTime = createSelector(selectAuthState, (state) => {
    if (!state.accessToken) return null

    const expTime = jwtDecode(state.accessToken).exp

    return expTime ? expTime * 1000 : null
})
