import React from 'react'
import { Building } from 'redux/types/building.type'
import { BuildingInformation } from 'modules/buildings/building/BuildingInformation'
import { Box } from '@mui/material'

interface ProjectBuildingsInfoTabProps {
    currentBuilding?: Building
}

export const ProjectBuildingsInfoTab: React.FC<ProjectBuildingsInfoTabProps> = ({ currentBuilding }) => {
    if (!currentBuilding) return null

    return (
        <Box sx={{ marginLeft: -2 }}>
            <BuildingInformation building={currentBuilding} />
        </Box>
    )
}
