import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { CustomerOrOrganizationForm } from 'schemas/customers'

type ContactProps = {
    control: Control<CustomerOrOrganizationForm>
    index: number
    removeContact: (contactIdx: number) => void
    isRemoveContactDisabled: boolean
}

export const ContactFormPart: React.FC<ContactProps> = ({ control, index, removeContact, isRemoveContactDisabled }) => {
    const { t } = useTranslation()

    return (
        <div>
            <div>
                <ControlledMuiTextField
                    name={`contacts.${index}.name`}
                    control={control}
                    placeholder={t('customersPage.customerDialog.customerNamePlaceholder')}
                    fullWidth
                    variant="filled"
                />
            </div>

            <div>
                <ControlledMuiTextField
                    name={`contacts.${index}.description`}
                    control={control}
                    placeholder={t('common.description')}
                    fullWidth
                    variant="filled"
                />
            </div>

            <div>
                <ControlledMuiTextField
                    name={`contacts.${index}.email`}
                    control={control}
                    placeholder={t('common.email')}
                    fullWidth
                    variant="filled"
                />
            </div>

            <div>
                <ControlledMuiTextField
                    name={`contacts.${index}.phoneNumber`}
                    control={control}
                    placeholder={t('customersPage.customerDialog.phoneNumberPlaceholder')}
                    fullWidth
                    variant="filled"
                />
            </div>

            <div>
                <Button
                    variant="outlined"
                    sx={{ mt: 2 }}
                    size="small"
                    onClick={() => removeContact(index)}
                    disabled={isRemoveContactDisabled}
                >
                    {t('customersPage.customerDialog.removeContact')}
                </Button>
            </div>
        </div>
    )
}
