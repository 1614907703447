import { Box, Container, Stack, Typography } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { ReactComponent as Logo } from 'assets/icons/logo_theme_color.svg'

export const AuthLayout: React.FC = () => {
    return (
        <Container component="main" maxWidth="xs">
            <Stack pt={8} pb={8} height="100vh" direction="column" justifyContent="space-between" alignItems="stretch">
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Logo width={200} />
                </Box>
                <Outlet />
                <Box sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
                    <Typography>© Strgi 2023</Typography>
                </Box>
            </Stack>
        </Container>
    )
}
