import { selectRoles, useUserTenantId } from 'redux/slices/auth.slice'
import { TopBar } from 'components/general/top-bar/top-bar'
import { AddTenantUser } from 'modules/users/users/AddTenantUser'
import { TenantUsersTable } from 'modules/users/users/TenantUsersTable'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'redux/hooks'
import { Role } from 'types/role'
import { PageContainer } from 'components/general/containers/PageContainer'

export const TenantUsersPage: React.FC = () => {
    const { t } = useTranslation()
    const tenantId = useUserTenantId()
    const roles = useAppSelector(selectRoles)
    const isSuperAdmin = (roles || []).includes(Role.Admin)

    return (
        <>
            <Helmet>
                <title>{t('navigationMenu.users')}</title>
            </Helmet>
            <TopBar rightActions={[<AddTenantUser tenantId={tenantId} />]} />
            <PageContainer>
                <TenantUsersTable tenantId={tenantId} isSuperAdmin={isSuperAdmin} />
            </PageContainer>
        </>
    )
}
