import React from 'react'
import { Edit } from '@mui/icons-material'
import { ProjectGoldenStandardValue } from 'redux/types/project.type'
import { StyledIconButton } from 'components/projects/EditButton'
import { GoldenStandardTable } from 'components/projects/GoldenStandardTable'

interface ProjectGoldenStandardViewModeProps {
    onEditModeChange?: (value: boolean) => void
    projectGoldenStandards: ProjectGoldenStandardValue[]
    completed: boolean
}

export const ProjectGoldenStandardViewMode: React.FC<ProjectGoldenStandardViewModeProps> = ({
    projectGoldenStandards,
    onEditModeChange,
    completed,
}) => {
    return (
        <div>
            {!completed && onEditModeChange && (
                <StyledIconButton onClick={() => onEditModeChange(true)}>
                    <Edit />
                </StyledIconButton>
            )}
            <GoldenStandardTable projectGoldenStandards={projectGoldenStandards} />
        </div>
    )
}
