import React from 'react'
import { Building } from 'redux/types/building.type'
import { GeneralSection } from './GeneralSection'
import { FloorSection } from './FloorsSection'
import { EnergySection } from './EnergySection'
import { FireClassSection } from './FireClassSection'
import { CultureSection } from './CultureSection'
import { ServiceSection } from './ServiceSection'
import { FireInstallationSection } from './FireInstallationSection'
import { SpecialRiskSection } from './SpecialRiskSection'

type BuildingInformationProps = {
    building: Building
}

export const BuildingInformation: React.FC<BuildingInformationProps> = ({ building }) => {
    return (
        <>
            <GeneralSection building={building} />
            <FloorSection building={building} />
            <EnergySection building={building} />
            <FireClassSection building={building} />
            <CultureSection building={building} />
            <FireInstallationSection building={building} />
            <SpecialRiskSection building={building} />
            <ServiceSection building={building} />
        </>
    )
}
