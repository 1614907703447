type Props = {
    id?: string,
    value: any,
    updateValue: (newValue: any) => void
}
export const UploadRenderer:React.FC<Props> = ({ id, value, updateValue }) => {
    return (
        <div style={{marginTop:'16px'}}>
            <input
                id={id}
                type="file"
                onChange={e => {
                    // @ts-ignore
                    const file = e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = function() {
                        updateValue(reader.result);
                    };
                    reader.readAsDataURL(file);
                }}
            />
        </div>
    );
}