import { JsonFormsCore } from '@jsonforms/core'
import { Box, Button, CircularProgress, Grid, Stack, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useGetStandardsQuery } from 'services/standards.service'
import { TopBar } from '../../components/general/top-bar/top-bar'
import { ErrorBoundary } from '../../components/forms/error'
import { Schema } from 'redux/types/schema.type'
import { SchemaBuilder } from '../../components/forms/schema-builder'
import { useAppSchema } from '../../components/forms/hooks/schema-hooks'
import { DEFAULT_PREBUILT_OPTIONS_QUERY, useGetPrebuiltOptionsQuery } from 'services/prebuiltOptions.service'
import { RegisteredJsonForm } from 'components/general/jsonForm/RegisteredJsonForm'
import { PageContainer } from 'components/general/containers/PageContainer'

type Props = {
    initialSchema?: Schema
}

export const SchemaBuilderPage: React.FC<Props> = ({ initialSchema }) => {
    const { t } = useTranslation()
    const [formData, setFormData] = useState({})
    const [formErrors, setFormErrors] = useState<any>()
    const navigate = useNavigate()

    const { isLoading: isLoadingStandards } = useGetStandardsQuery()
    const { isLoading: isLoadingPrebuiltOptions } = useGetPrebuiltOptionsQuery(DEFAULT_PREBUILT_OPTIONS_QUERY)

    const isLoading = isLoadingPrebuiltOptions || isLoadingStandards

    const { appSchema, setAppSchema, jsonSchema, uiSchema, updatePreview, name, setName, submit, fieldErrors } =
        useAppSchema(initialSchema, { onCreate: () => navigate('/schemas') })

    const handleChange = ({ errors, data }: Pick<JsonFormsCore, 'data' | 'errors'>) => {
        setFormData(data)
        setFormErrors(errors)
    }

    return (
        <>
            <TopBar />
            {isLoading ? (
                <Box display="flex" alignItems="center" justifyContent="center" height="90vh">
                    <CircularProgress />
                </Box>
            ) : (
                <PageContainer>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack spacing={2} direction="row">
                                <TextField
                                    label="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    error={Boolean(fieldErrors?.name)}
                                    helperText={Boolean(fieldErrors?.name) ? t(fieldErrors.name) : ''}
                                />
                                <Button variant="contained" onClick={() => submit()}>
                                    {t('common.save')}
                                </Button>
                                <Button onClick={() => updatePreview(appSchema)} variant="outlined">
                                    {t('update_preview', 'Update preview')}
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SchemaBuilder appSchema={appSchema} setAppSchema={setAppSchema} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ErrorBoundary>
                                <RegisteredJsonForm
                                    data={formData}
                                    onChange={(x) => handleChange(x)}
                                    schema={jsonSchema}
                                    uischema={uiSchema}
                                />
                                {jsonSchema ? (
                                    <Stack>
                                        <Button variant="outlined" onClick={() => console.log(formData, formErrors)}>
                                            {t('test_submit', 'Test submit')}
                                        </Button>
                                    </Stack>
                                ) : null}
                            </ErrorBoundary>
                        </Grid>
                    </Grid>
                </PageContainer>
            )}
        </>
    )
}
