import {
    ArrColSizeDefinition,
    BuildingPartGroupableOption,
    DynamicTableColSizeDefinition,
    GroupTableColSizeDefinition,
    ObjectValues,
} from 'types/util'

import { BaseEntity } from 'components/general/models/base-entity'
import { FieldRule } from './schemaRule.type'
import { DefaultFilter, PaginationParams } from 'components/general/models/pagination-params'
import { IOption } from 'components/inputs/ControlledMuiSelect'
import { StructureStaticType } from './formStructure.type'

export const FIELD_TYPES = {
    Select: 'select',
    Radio: 'radio',
    Checkbox: 'checkbox',
    String: 'string',
    Array: 'array',
    Section: 'section',
    Number: 'number',
    Date: 'date',
    File: 'file',
    Boolean: 'boolean',
    VerticalLayout: 'VerticalLayout',
    HorizontalLayout: 'HorizontalLayout',
    Label: 'Label',
    Group: 'Group',
    Root: 'root',
    GroupTable: 'groupTable',
    List: 'list',
    DynamicTable: 'dynamicTable',
    DisciplinesTag: 'disciplinesTag',
    BuildingPart: 'buildingPart',
} as const

export type FieldType = ObjectValues<typeof FIELD_TYPES>

export type GoldenStandard = {
    isGoldenStandard: boolean
    selectedStandardId?: string
}

type PrebuiltOption = {
    prebuiltOptionListId?: string
}

type MandatoryOptions = {
    hideLabel: boolean
    hideInPdf: boolean
    fieldName: string
}

export type SelectSchemaElement = {
    type: 'select'
    name: string
    label: string
    enum: string[]
    rule?: FieldRule
    options: MandatoryOptions
} & GoldenStandard &
    PrebuiltOption

export type DisciplinesTagSchemaElement = {
    type: 'disciplinesTag'
    name: string
    label: string
    rule?: FieldRule
    enum: IOption[]
    options: {
        disciplinesTag: true
        selectOptions: IOption[]
    } & MandatoryOptions
}

export type BuildingPartSchemaElement = {
    type: 'buildingPart'
    name: string
    label: string
    rule?: FieldRule
    enum: BuildingPartGroupableOption[]
    options: {
        buildingPart: true
        selectOptions: IOption[]
    } & MandatoryOptions
}

export type RadioSchemaElement = {
    type: 'radio'
    name: string
    label: string
    enum: string[]
    rule?: FieldRule
    options: MandatoryOptions
} & GoldenStandard &
    PrebuiltOption

export type CheckboxSchemaElement = {
    type: 'checkbox'
    name: string
    label: string
    enum: string[]
    rule?: FieldRule
    options: MandatoryOptions
} & GoldenStandard &
    PrebuiltOption

export type StringSchemaElement = {
    type: 'string'
    name: string
    label: string
    required: boolean
    rule?: FieldRule
    options?: {
        multi: boolean
    } & MandatoryOptions
} & GoldenStandard

export type ArraySchemaElement = {
    type: 'array'
    name: string
    label: string
    nodes: SchemaElement[]
    rule?: FieldRule
    options: {
        showSortButtons: boolean
        columnDefinitions: Record<string, ArrColSizeDefinition>
    } & MandatoryOptions
}

export type ListSchemaElement = {
    type: 'list'
    name: string
    label: string
    element: FieldType
    rule?: FieldRule
    options: {
        listType: 'ordered' | 'unordered'
        isList: true
    } & MandatoryOptions
}

export type NumberSchemaElement = {
    type: 'number'
    name: string
    label: string
    required: boolean
    rule?: FieldRule
    options: MandatoryOptions
} & GoldenStandard

export type DateSchemaElement = {
    type: 'date'
    name: string
    label: string
    required: boolean
    rule?: FieldRule
    options: {
        dateFormat: string
    } & MandatoryOptions
} & GoldenStandard

export type FileSchemaElement = {
    type: 'file'
    name: string
    label: string
    rule?: FieldRule
    options: MandatoryOptions
} & GoldenStandard

export type BooleanSchemaElement = {
    type: 'boolean'
    name: string
    label: string
    rule?: FieldRule
    options: MandatoryOptions
} & GoldenStandard

export type VerticalLayoutSchemaElement = {
    type: 'VerticalLayout'
    nodes: SchemaElement[]
    rule?: FieldRule
}

export type HorizontalLayoutSchemaElement = {
    type: 'HorizontalLayout'
    nodes: SchemaElement[]
    rule?: FieldRule
    options: {
        fieldName: string
    }
}

export type LabelSchemaElement = {
    type: 'Label'
    text: string
    rule?: FieldRule
}

export type GroupSchemaElement = {
    type: 'Group'
    name: string
    label: string
    nodes: SchemaElement[]
    rule?: FieldRule
    options: MandatoryOptions
}

export type GroupTableSchemaElement = {
    type: 'groupTable'
    name: string
    label: string
    nodes: SchemaElement[]
    rule?: FieldRule
    options: {
        uniqueValue: true
        columnDefinitions: Record<number, GroupTableColSizeDefinition>
        columnCount: number
    } & MandatoryOptions
}

export type DynamicTableShemaElement = {
    type: 'dynamicTable'
    name: string
    label: string
    nodes: SchemaElement[]
    rule?: FieldRule
    options: {
        dynamicTable: true
        columnDefinitions: Record<number, DynamicTableColSizeDefinition>
        columnCount: number
    } & MandatoryOptions
} & GoldenStandard

export type RootSchemaElement = {
    type: 'root'
    name: string
    label: string
    nodes: SchemaElement[]
    staticType?: StructureStaticType | null
}

export type SchemaElement =
    | RootSchemaElement
    | SelectSchemaElement
    | RadioSchemaElement
    | CheckboxSchemaElement
    | StringSchemaElement
    | ArraySchemaElement
    | NumberSchemaElement
    | DateSchemaElement
    | FileSchemaElement
    | BooleanSchemaElement
    | VerticalLayoutSchemaElement
    | HorizontalLayoutSchemaElement
    | GroupSchemaElement
    | LabelSchemaElement
    | GroupTableSchemaElement
    | ListSchemaElement
    | DynamicTableShemaElement
    | DisciplinesTagSchemaElement
    | BuildingPartSchemaElement

export type SchemaLight = {
    id: string
    tenantId: string
    name: string
} & BaseEntity

export type SchemaLightWithDeletable = {
    isDeletable: boolean
} & SchemaLight

export type Schema = {
    appSchema: RootSchemaElement
    jsonSchema: any
    uiSchema: any
    assignedStructureId: string | null
} & SchemaLight

export type CreateSchemaRequest = {
    name: string
    structureIdToAssign?: string
    appSchema: SchemaElement
    jsonSchema: any
    uiSchema: any
}

export type CopyGeneralSchemaRequest = {
    id: string
    name?: string
}

export type UpdateSchemaRequest = { id: string } & CreateSchemaRequest

export type SchemasPaginationParams = PaginationParams<
    Schema,
    DefaultFilter<Schema> & { searchQuery?: string; assignedStructureId?: string; withCommon?: boolean }
>
