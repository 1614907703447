import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { DEFAULT_LANG, getLang } from './translation-helper'

let nbTranslation = require('./nb/translation.json')
let enTranslation = require('./en/translation.json')

// https://github.com/i18next/react-i18next/issues/1587#issuecomment-1386909661
declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false
    }
}

export const initTranslation = () => {
    i18n.use(initReactI18next)
        .init({
            lng: DEFAULT_LANG,
            supportedLngs: ['nb', 'en'],
            resources: {
                nb: {
                    translation: nbTranslation,
                },
                en: {
                    translation: enTranslation,
                },
            },
            contextSeparator: '*',
            pluralSeparator: '%%',
            nsSeparator: '::',
            returnNull: false,
        })
        .then(() => i18n.changeLanguage(getLang()))
}
