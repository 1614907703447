import { CreateOrUpdateBuildingForm } from 'redux/types/building.type'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'

import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SectionBlock } from 'components/buildings/SectionBlock'
import { FormItem } from 'components/buildings/FormItem'

export const EnergySectionForm: React.FC = () => {
    const { t } = useTranslation()
    const { control } = useFormContext<CreateOrUpdateBuildingForm>()

    return (
        <SectionBlock>
            <FormItem label={t('createEditBuildingPage.fireLoad')}>
                <ControlledMuiTextField
                    control={control}
                    name="fireLoad"
                    sx={{ maxWidth: 100 }}
                    type="number"
                    placeholder={t('createEditBuildingPage.fireLoad')}
                    inputSuffix={
                        <div>
                            MJ/m
                            <sup>
                                <sup>2</sup>
                            </sup>
                        </div>
                    }
                />
            </FormItem>

            <FormItem
                label={t('createEditBuildingPage.fireLoadDescription')}
                sxTitleContainer={{ alignSelf: 'flex-start' }}
            >
                <ControlledMuiTextField
                    control={control}
                    name="fireLoadDescription"
                    placeholder={t('common.description')}
                    fullWidth
                    variant="outlined"
                    rows={5}
                    sx={{ maxWidth: 600 }}
                    multiline
                />
            </FormItem>
        </SectionBlock>
    )
}
