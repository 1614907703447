import React from 'react'
import { Button, Stack, styled } from '@mui/material'

import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { CreateTemplateCopyRequest } from 'redux/types/template.type'
import { useCreateTemplateCopyMutation } from 'services/templates.service'
import { LoadingButton } from '@mui/lab'

type CreateTemplateCopyForm = Omit<CreateTemplateCopyRequest, 'id'>

const DEFAULT_VALUES: CreateTemplateCopyForm = {
    name: '',
}

interface CopyTemplateDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions' | 'open'> {
    templateId: string
}

export const CopyTemplateDialog: React.FC<CopyTemplateDialogProps> = ({ handleClose, templateId }) => {
    const { t } = useTranslation()

    const [copyTemplate, { isLoading }] = useCreateTemplateCopyMutation()

    const { handleSubmit, control } = useForm<CreateTemplateCopyForm>({
        defaultValues: DEFAULT_VALUES,
    })

    const onSubmit = async (values: CreateTemplateCopyForm) => {
        await copyTemplate({
            id: templateId,
            name: values.name || undefined,
        }).unwrap()

        handleClose()
    }

    return (
        <ModalsWrapper
            sx={{
                zIndex: 9999,
            }}
            open
            title={t('templatesPage.areYouSureYouWantToCopyTemplate')}
            handleClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
            isLoading={isLoading}
            body={
                <form id="copy-template-form" onSubmit={handleSubmit(onSubmit)}>
                    <ContentWrapper>
                        <ControlledMuiTextField
                            control={control}
                            fullWidth
                            name="name"
                            variant="filled"
                            placeholder={t('templatesPage.templateNamePlaceholder')}
                        />
                    </ContentWrapper>
                </form>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button variant="outlined" onClick={handleClose} color="secondary" disabled={isLoading}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="copy-template-form"
                        loading={isLoading}
                    >
                        {t('common.confirm')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 3),
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
