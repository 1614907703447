import { yupResolver } from '@hookform/resolvers/yup'
import { useFieldArray, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import createFormStructureSchema from 'schemas/formStructure'
import {
    useCreateFormStructureMutation,
    usePublishFormStructureMutation,
    useUpdateFormStructureMutation,
} from '../../../services/formStructures.service'
import { ROUTES } from 'constants/routes'
import { useAppSelector } from 'redux/hooks'
import { selectPrebuiltSchemaOptions } from 'redux/selectors/settings.selectors'
import { FormSection, FormStructureEntity } from 'redux/types/formStructure.type'
import { OptionWithStaticStructureType } from 'types/util'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const sanitizeSection = (section: FormSection, predefinedSchemas: OptionWithStaticStructureType[]) => {
    if (!section.schemaId) delete section.schemaId

    const predefinedSchemaIdx = predefinedSchemas.findIndex((schema) => schema.value === section.schemaId)
    if (predefinedSchemaIdx !== -1) section.staticType = predefinedSchemas[predefinedSchemaIdx].staticType

    section.children.forEach((child) => sanitizeSection(child, predefinedSchemas))
}

const sanitizeStructure = (
    structure: FormStructureEntity['sections'],
    predefinedSchemas: OptionWithStaticStructureType[],
) => {
    structure.forEach((child) => sanitizeSection(child, predefinedSchemas))
}

export type StructureBuilderForm = { type: string } & Pick<FormStructureEntity, 'name' | 'sections'>

export const useStructureForm = (initialData: StructureBuilderForm, structureId?: string) => {
    const { t } = useTranslation()
    const prebuiltSchemaOptions = useAppSelector(selectPrebuiltSchemaOptions)
    const [createStructure, { isLoading: isCreatingStructure }] = useCreateFormStructureMutation()
    const [updateStructure, { isLoading: isUpdatingStructure }] = useUpdateFormStructureMutation()
    const [publishStructure, { isLoading: isPublishing }] = usePublishFormStructureMutation()

    const navigate = useNavigate()

    const isLoading = isCreatingStructure || isUpdatingStructure || isPublishing

    const methods = useForm<StructureBuilderForm>({
        defaultValues: initialData,
        resolver: yupResolver(createFormStructureSchema),
    })

    const { fields, append, remove } = useFieldArray({
        control: methods.control,
        name: 'sections',
    })

    const handleSubmit = async (values: StructureBuilderForm) => {
        if (isLoading) return

        sanitizeStructure(values.sections, prebuiltSchemaOptions)

        if (!structureId) {
            await createStructure({
                name: values.name,
                structure: values.sections,
                type: values.type,
            })
            navigate(ROUTES.forms)
        } else {
            updateStructure({
                name: values.name,
                structure: values.sections,
                type: values.type,
                id: structureId,
            })
            toast.success(t('common.successfull'))
        }
    }

    const addMainSection = () => {
        append({
            label: '',
            children: [],
            sectionId: Date.now().toString(),
        })
    }

    const deleteMainSection = (idx: number) => {
        remove(idx)
    }

    const onStructurePublish = () => {
        if (!structureId) throw new Error('Cant publish non existing structure')
        publishStructure({ id: structureId })
        toast.success(t('common.successfull'))
    }

    return {
        methods,
        isLoading,
        handleSubmit: methods.handleSubmit(handleSubmit),
        prebuiltSchemaOptions,
        fields,
        append: addMainSection,
        remove: deleteMainSection,
        publishStructure: onStructurePublish,
    }
}
