export const DATE_LOCALE_OPTIONS: Record<string, Intl.DateTimeFormatOptions> = {
    no: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    },
}

export const DATE_FORMATING_LOCALE = {
    no: {
        yearDisplay: 'DD.MM.YYYY',
    },
}

// map which translates our stored language to js locale
export const STORAGE_LOCALE_DICTIONARY: Record<string, string> = {
    en: 'en-Us',
    nb: 'no-NO',
}
