import Grid from '@mui/material/Grid'
import { Add } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { TopBar } from 'components/general/top-bar/top-bar'
import { useStartProjectMutation } from 'services/projects.service'
import { navigationLinks } from 'utils/navigation-utils'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { selectId } from 'redux/slices/auth.slice'
import { MyProjectsTasksTable } from 'modules/dashboard/main/MyProjectsTasksTable'
import { MyProjectsTable } from 'modules/dashboard/main/MyProjectsTable'
import { MyArchivedProjectsTable } from 'modules/dashboard/main/MyArchivedProjectsTable'
import { Helmet } from 'react-helmet'
import { PageContainer } from 'components/general/containers/PageContainer'

type Props = {}

export const MyProjectsPage: React.FC<Props> = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const userId = useAppSelector(selectId)

    const [createProject, { isLoading: isSubmitting }] = useStartProjectMutation()

    const handleCreateProject = async () => {
        if (!userId) return
        try {
            const newProject = await createProject({ managerId: userId }).unwrap()
            navigate(navigationLinks.toCreateProject(newProject.id))
        } catch (e) {}
    }

    return (
        <>
            <Helmet>
                <title>{t('navigationMenu.dashboard')}</title>
            </Helmet>
            <TopBar
                rightActions={[
                    <div>
                        <LoadingButton
                            startIcon={<Add />}
                            color="secondary"
                            variant="contained"
                            onClick={handleCreateProject}
                            loading={isSubmitting}
                        >
                            {t('common.addProject')}
                        </LoadingButton>
                    </div>,
                ]}
            />
            <PageContainer>
                <Grid container spacing={2.5}>
                    <Grid item xs={12}>
                        <MyProjectsTasksTable userId={userId} />
                    </Grid>
                    <Grid item xs={12}>
                        <MyProjectsTable userId={userId} />
                    </Grid>
                    <Grid item xs={12}>
                        <MyArchivedProjectsTable userId={userId} />
                    </Grid>
                </Grid>
            </PageContainer>
        </>
    )
}
