import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ViewSectionProps } from './GeneralSection'
import { TagChip } from 'components/general/chips/TagChip'
import { DEFAULT_FLOOR } from '../create/FloorFormTable'
import { valueOrDefaultEmpty } from 'utils/general'
import { DisplayFloorContainer, FloorColumnText } from '../shared/styles'
import { SectionBlock } from 'components/buildings/SectionBlock'
import { FormItem } from 'components/buildings/FormItem'

const FLOOR_HEADER = Object.keys(DEFAULT_FLOOR).filter((k) => k !== 'number' && k !== 'isHalfFloor')

export const FloorSection: React.FC<ViewSectionProps> = ({ building }) => {
    const { t } = useTranslation()
    const { year, floorCount, height, area, floors } = building

    return (
        <SectionBlock density="dense">
            <FormItem label={t('common.year')}>{year ?? '-'}</FormItem>
            <FormItem label={t('createEditBuildingPage.buildingFloorCount')}>
                {valueOrDefaultEmpty(floorCount)}
            </FormItem>

            <FormItem label={t('common.height')}>
                <Typography variant="body1" component="span" sx={{ mr: 4 }}>
                    {valueOrDefaultEmpty(height)}
                </Typography>
                <span>{t('common.meter')}</span>
            </FormItem>

            <FormItem label={t('createEditBuildingPage.buildingFootprint')}>
                <Typography variant="body1" component="span" sx={{ mr: 4 }}>
                    {valueOrDefaultEmpty(area)}
                </Typography>
                <span>
                    m<sup>2</sup>
                </span>
            </FormItem>
            {floors.length > 0 ? (
                <DisplayFloorContainer>
                    {FLOOR_HEADER.map((key) => (
                        <FloorColumnText key={key} variant="body1" className="floor-header">
                            {t(`createEditBuildingPage.floorTableHeaders.${key}`)}
                        </FloorColumnText>
                    ))}
                    {floors.map((floor, idx) => (
                        <React.Fragment key={floor.number}>
                            <Typography variant="body1" component="span">
                                {valueOrDefaultEmpty(floor.name)}
                            </Typography>

                            <Typography variant="body1" component="span">
                                {valueOrDefaultEmpty(floor.area)} m<sup>2</sup>
                            </Typography>

                            <Typography variant="body1" component="span">
                                {floor.terrain === 'Over'
                                    ? t('createEditBuildingPage.terrainOver')
                                    : t('createEditBuildingPage.terrainUnder')}
                            </Typography>

                            <Box justifySelf="start" display="flex" gap={2} alignItems="center">
                                {floor.riskClasses.length
                                    ? floor.riskClasses.map((risk) => <TagChip label={risk} key={`${risk}-${idx}`} />)
                                    : '-'}
                            </Box>

                            <Typography variant="body1" component="span">
                                {floor.counting === true ? t('common.yes') : t('common.no')}
                            </Typography>

                            <Box justifySelf="start" display="flex" gap={2} alignItems="center">
                                {floor.occupations.length
                                    ? floor.occupations.map((occupation) => (
                                          <TagChip label={occupation} key={`${occupation}-${idx}`} />
                                      ))
                                    : '-'}
                            </Box>
                        </React.Fragment>
                    ))}
                </DisplayFloorContainer>
            ) : null}
        </SectionBlock>
    )
}
