import { MyFileEntity, UpdateFilesMetaDataRequest } from 'redux/types/file.type'
import { appApi } from '../redux/api'

const filesApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        updateFilesMetaData: builder.mutation<MyFileEntity[], UpdateFilesMetaDataRequest>({
            query: (params) => ({
                url: '/v1/files/update-files-metadata',
                method: 'PUT',
                body: params,
            }),
        }),
        deleteFile: builder.mutation<void, { id: string }>({
            query: (params) => ({
                url: `/v1/files/${params.id}`,
                method: 'DELETE',
            }),
        }),
    }),
})

export const { useUpdateFilesMetaDataMutation, useDeleteFileMutation } = filesApi
