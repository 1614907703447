import React from 'react'
import { Add, Edit } from '@mui/icons-material'
import { Button, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { TopBar } from '../../components/general/top-bar/top-bar'
import { useStartProjectMutation } from '../../services/projects.service'
import { useGetBuildingQuery } from '../../services/buildings.service'
import { navigationLinks } from 'utils/navigation-utils'
import { LoadingButton } from '@mui/lab'
import { useAppSelector } from 'redux/hooks'
import { selectId } from 'redux/slices/auth.slice'
import { BuildingInformation } from 'modules/buildings/building/BuildingInformation'
import { BuildingTabs, EBuildingTabs } from 'modules/buildings/shared/BuildingTabs'
import { BuildingProjects } from 'modules/buildings/building/BuildingProjects'
import { BuildingFinishedProjects } from 'modules/buildings/building/BuildingFinishedProjects'
import { PageContainer } from 'components/general/containers/PageContainer'
import { BuildingRelevantOrganizations } from 'modules/buildings/building/BuildingRelevantOrganizations'
import { Helmet } from 'react-helmet'
import { TabContainer } from 'components/buildings/TabContainer'

export const BuildingPage: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const params = useParams()
    const userId = useAppSelector(selectId)

    const buildingId = params.id!
    const customerId = params.customerId!

    const building = useGetBuildingQuery(buildingId)
    const [createProject, { isLoading: isSubmitting }] = useStartProjectMutation()

    const [activeTab, setActiveTab] = React.useState<EBuildingTabs>(EBuildingTabs.BuildingInfo)

    const handleAddProject = async () => {
        if (!building.data || !userId) return

        try {
            const newProject = await createProject({ buildingId, managerId: userId }).unwrap()
            navigate(navigationLinks.toCreateProject(newProject.id))
        } catch (e) {}
    }

    if (!building.isSuccess) return null

    return (
        <>
            <Helmet>{building.data && <title>{building.data.address.addressText}</title>}</Helmet>
            <TopBar
                rightActions={[
                    <Button
                        startIcon={<Edit />}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => navigate('/buildings/edit/' + buildingId)}
                    >
                        {t('edit_building', 'Edit building')}
                    </Button>,
                    <LoadingButton
                        startIcon={<Add />}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={handleAddProject}
                        disabled={isSubmitting || !building.data}
                        loading={isSubmitting}
                    >
                        {t('common.addProject')}
                    </LoadingButton>,
                ]}
            />

            <PageContainer>
                <Paper elevation={0}>
                    <BuildingTabs activeTab={activeTab} onActiveTabChange={setActiveTab} />
                    <TabContainer>
                        {activeTab === EBuildingTabs.BuildingInfo && <BuildingInformation building={building.data} />}
                        <BuildingRelevantOrganizations
                            organizations={building.data.organizations}
                            activeTab={activeTab}
                        />
                    </TabContainer>
                </Paper>

                <Paper elevation={0} sx={{ my: 2 }}>
                    <BuildingProjects buildingId={buildingId} customerId={customerId} />
                </Paper>
                <Paper elevation={0} sx={{ my: 2 }}>
                    <BuildingFinishedProjects buildingId={buildingId} customerId={customerId} />
                </Paper>
            </PageContainer>
        </>
    )
}
