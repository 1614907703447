import { Button, Typography, styled } from '@mui/material'
import { ROUTES } from 'constants/routes'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useHasRole, useIsAuthenticated } from 'redux/slices/auth.slice'
import { Role } from 'types/role'

export const NotFoundPage: React.FC = () => {
    const isCustomer = useHasRole(Role.Customer)
    const isAuthenticated = useIsAuthenticated()
    const { t } = useTranslation()

    return (
        <Container>
            <ActionContainer>
                <Typography variant="h6" mb={2}>
                    {t('404Page.thisPageDoesntExist')}
                </Typography>
                {isAuthenticated ? (
                    <>
                        {isCustomer ? (
                            <div>
                                <Button to={ROUTES.customerHome} component={Link} variant="contained" color="primary">
                                    {t('404Page.returnToCustomerHomePageBtn')}
                                </Button>
                            </div>
                        ) : (
                            <div>
                                <Button to={ROUTES.dashboard} component={Link} variant="contained" color="primary">
                                    {t('404Page.returnToDashboardPageBtn')}
                                </Button>
                            </div>
                        )}
                    </>
                ) : (
                    <div>
                        <Button to={ROUTES.login} component={Link} variant="contained" color="primary">
                            {t('404Page.returnToLoginPageBtn')}
                        </Button>
                    </div>
                )}
            </ActionContainer>
        </Container>
    )
}

const Container = styled('main')({
    height: '100vh',
    width: '100%',
})

const ActionContainer = styled('div')({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
})
