import { Grid, styled } from '@mui/material'

interface SectionBlockProps {
    children: React.ReactNode
    density?: 'dense' | 'normal'
}

export const SectionBlock: React.FC<SectionBlockProps> = ({ children, density = 'normal' }) => {
    return (
        <Section className="section">
            <Grid
                container
                alignItems={density === 'normal' ? 'flex-end' : 'center'}
                columnSpacing={1}
                rowSpacing={density === 'normal' ? 3 : 1.25}
            >
                {children}
            </Grid>
        </Section>
    )
}

const Section = styled('div')(({ theme }) => ({
    borderBottom: `1px solid ${theme.general.borderColor}`,
    padding: theme.spacing(0, 2, 2),
    marginBottom: theme.spacing(1.5),
}))
