import { Alert, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { GroupTableColSizeDefinition } from 'types/util'
import { getColSizes } from 'utils/schema-utils'

type GroupTableColumnSizeTabProps = {
    columnSizeDefinitions: Record<number, GroupTableColSizeDefinition>
    isColSizeValid: boolean
    totalColumnsSelected: number
    setColumnSizeDefinitions: (newValue: Record<number, GroupTableColSizeDefinition>) => void
}

export const GroupTableColumnSizeTab: React.FC<GroupTableColumnSizeTabProps> = ({
    columnSizeDefinitions,
    isColSizeValid,
    totalColumnsSelected,
    setColumnSizeDefinitions,
}) => {
    const { t } = useTranslation()

    const columns = new Array(totalColumnsSelected).fill(null)
    const sizeOptions = getColSizes()

    return (
        <>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                {columns.map((_, idx) => {
                    const name = `sizeForCol-${idx}`
                    return (
                        <React.Fragment key={name}>
                            <Grid item xs={1}>
                                <Typography variant="body1" align="right">
                                    {`${idx + 1}.`}
                                </Typography>
                            </Grid>
                            <Grid item xs={11}>
                                <FormControl fullWidth sx={{ maxWidth: 220 }}>
                                    <InputLabel>{t('common.size')}</InputLabel>
                                    <Select
                                        name={name}
                                        label={t('common.size')}
                                        value={columnSizeDefinitions[idx]?.size || ''}
                                        onChange={(e) =>
                                            setColumnSizeDefinitions({
                                                ...columnSizeDefinitions,
                                                [idx]: {
                                                    isDirty: true,
                                                    columnNumber: idx,
                                                    size: e.target.value,
                                                    unit: '%',
                                                },
                                            })
                                        }
                                    >
                                        {sizeOptions.map((size) => (
                                            <MenuItem key={size} value={size}>
                                                {`${size} ${size === 'auto' ? '' : '%'}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </React.Fragment>
                    )
                })}
            </Grid>
            {!isColSizeValid && (
                <Alert severity="error" sx={{ my: 2 }}>
                    {t('schemaPage.incorrectColSizeSumTitle')}
                </Alert>
            )}
        </>
    )
}
