import { CircularProgress, styled, Fade } from '@mui/material'
import React from 'react'

interface PageLoaderProps {
    children?: React.ReactNode
}

export const PageLoader: React.FC<PageLoaderProps> = ({ children }) => {
    return (
        <Fade in>
            <Wrapper>
                <CircularProgress color="success" disableShrink size={70} />
                {children}
            </Wrapper>
        </Fade>
    )
}

const Wrapper = styled('div')`
    position: relative;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
`
