import * as yup from 'yup'
import { emailRegex } from './util'
import { CreateTenantUserRequest } from 'redux/types/user.type'

export type CreateTenantUserForm = Omit<CreateTenantUserRequest, 'tenantId'>

export const tenantUserSchema: yup.ObjectSchema<CreateTenantUserForm> = yup.object().shape({
    fullName: yup.string().required('required'),
    email: yup.string().required('required').trim().matches(emailRegex, { message: 'invalidEmail' }),
    occupation: yup.string().required('required'),
})
