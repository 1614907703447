import { ROUTES } from 'constants/routes'
import { Navigate } from 'react-router-dom'
import { useHasRole, useIsAuthenticated } from 'redux/slices/auth.slice'
import { Role } from 'types/role'

export const HomeUriRedirectPage: React.FC = () => {
    const isCustomer = useHasRole(Role.Customer)
    const isAuthenticated = useIsAuthenticated()

    if (!isAuthenticated) return <Navigate to={ROUTES.login} replace />
    if (isCustomer) return <Navigate to={ROUTES.customerHome} replace />

    return <Navigate to={ROUTES.dashboard} replace />
}
