import React from 'react'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { styled } from '@mui/material'

type HeaderButtonProps = {
    label: string
    onClick: () => void
    isLoading?: boolean
} & LoadingButtonProps

export const HeaderButton: React.FC<HeaderButtonProps> = ({ label, onClick, isLoading, ...rest }) => {
    return (
        <StyledLoadingButton {...rest} variant="outlined" onClick={onClick} loading={isLoading} disabled={isLoading}>
            {label}
        </StyledLoadingButton>
    )
}

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
    minWidth: 140,
    borderColor: theme.general.gray,
    color: theme.general.gray,
    '&:hover': {
        background: theme.palette.primary.light,
    },
    '&:disabled': {
        borderColor: theme.palette.primary.light,
    },
    '& .MuiLoadingButton-loadingIndicator': {
        color: theme.palette.secondary.main,
    },

    [theme.breakpoints.down('sm')]: {
        minWidth: 'fit-content',
    },
}))
