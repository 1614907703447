import React from 'react'
import { useProjectView } from '../hooks/useProjectView'
import { Box, Card, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { GoldenStandardTable } from 'components/projects/GoldenStandardTable'

export const GoldenStandards: React.FC = () => {
    const { t } = useTranslation()
    const { project, isCustomerView } = useProjectView()

    if (!project || isCustomerView) return null

    return (
        <Card sx={{ py: 2, px: 3, mt: 2 }}>
            <Box display="flex" justifyContent="space-between" mb={1.5}>
                <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.25rem', lineHeight: '1.5rem' }}>
                    {t('common.goldenStandards')}
                </Typography>
            </Box>
            <GoldenStandardTable projectGoldenStandards={project.goldenStandards || []} />
        </Card>
    )
}
