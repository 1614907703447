import React from 'react'
import { useAppSelector } from 'redux/hooks'
import { selectNormalizedStructure } from 'redux/selectors/structure.selectors'
import NormalizedRecursiveTreeView from 'components/general/navigation/NormalizedRecursiveTreeView'
import { NormalizationUtils } from 'utils/normalization-utils'
import { useProjectQueryParams } from 'modules/projects/hooks/useProjectQueryParams'

export const TemplateSchemaTree: React.FC = React.memo(() => {
    const [search, setSearch] = useProjectQueryParams()
    const { structure } = useAppSelector(selectNormalizedStructure)

    React.useEffect(() => {
        if (!structure) return

        if (search.structureId === undefined) {
            const nextStructure = NormalizationUtils.findFirstViableSection(structure)
            if (!nextStructure) return

            setSearch(
                {
                    structureId: nextStructure.sectionId,
                },
                'replace',
            )
        }
    }, [search, setSearch, structure])

    if (!structure || !search.structureId) return null

    return (
        <div>
            <NormalizedRecursiveTreeView data={structure} withProgressIcon={false} />
        </div>
    )
})
