import React from 'react'
import { Card, SortDirection } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DataGrid, GridColDef, GridRowHeightParams } from '@mui/x-data-grid'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { useGetProjectsQuery } from 'services/projects.service'
import { DataGridTitle } from 'components/general/table/DataGridTitle'
import { DATE_LOCALE_OPTIONS } from 'constants/locales'
import { useDataGridFiltersState } from 'utils/hooks/useDataGridFiltersState'
import { ProjectWithoutForms } from 'redux/types/project.type'
import { DataGridNoRows } from 'components/general/table/DataGridNoRows'
import { ProjectBuildingListLinkCell } from 'components/general/table/ProjectBuildingListCell'
import { DataGridLinkCell } from 'components/general/table/DataGridLinkCell'
import { navigationLinks } from 'utils/navigation-utils'
import { PROJECT_WAITING_FOR_QA_ACTIONS } from 'constants/util'
import { valueOrDefaultEmpty } from 'utils/general'
import { ProjectStatusCell } from 'components/projects/ProjectStatusCell'

export const MyProjectsTasksTable: React.FC<{ userId: string | null }> = ({ userId }) => {
    const { t } = useTranslation()

    const { page, sortBy, handlePageChange, handleSortChange } = useDataGridFiltersState({
        sort: 'desc',
        field: 'name',
    })

    const { data: myProjects, isFetching } = useGetProjectsQuery(
        {
            qualityAssuranceId: userId || '',
            page,
            itemsPerPage: DEFAULT_ROWS_PER_PAGE,
            direction: sortBy?.sort as SortDirection,
            sortBy: sortBy?.field,
            statuses: PROJECT_WAITING_FOR_QA_ACTIONS,
        },
        { skip: !userId },
    )

    const columns: GridColDef<ProjectWithoutForms>[] = [
        {
            field: 'name',
            headerName: t('projectsTable.nameHeader'),
            flex: 1,
            maxWidth: 200,
            minWidth: 180,
            editable: false,
            renderCell: (params) => {
                return (
                    <DataGridLinkCell
                        label={params.row.name || t('common.noProjectNamePlaceholder')}
                        to={navigationLinks.toReviewProject(params.row.id)}
                    />
                )
            },
        },
        {
            field: 'regulationType',
            headerName: t('projectsTable.typeHeader'),
            flex: 1,
            minWidth: 200,
            width: 200,
            valueGetter: (params) => valueOrDefaultEmpty(params.row.type?.label),
            editable: false,
        },
        {
            field: 'buildings',
            headerName: t('projectsTable.buildingHeader'),
            flex: 1,
            editable: false,
            minWidth: 400,
            width: 400,
            renderCell: (params) => <ProjectBuildingListLinkCell buildings={params.row.buildings} />,
        },
        {
            field: 'status',
            headerName: t('projectsTable.statusHeader'),
            flex: 1,
            editable: false,
            maxWidth: 200,
            minWidth: 180,
            renderCell: (params) => <ProjectStatusCell status={params.row.status} />,
        },
        {
            field: 'created',
            headerName: t('projectsTable.createdHeader'),
            flex: 1,
            editable: false,
            width: 110,
            minWidth: 110,
            // TODO: if more languages will be supported the locale value should be hardcoded
            valueGetter: (params) => new Date(params.row.created).toLocaleDateString('no', DATE_LOCALE_OPTIONS['no']),
        },
        {
            field: 'updated',
            headerName: t('projectsTable.updatedHeader'),
            flex: 1,
            editable: false,
            width: 110,
            minWidth: 110,
            // TODO: if more languages will be supported the locale value should be hardcoded
            valueGetter: (params) => new Date(params.row.updated).toLocaleDateString('no', DATE_LOCALE_OPTIONS['no']),
        },
    ]

    return (
        <>
            <Card elevation={1} sx={{ minHeight: '400px' }}>
                <DataGrid
                    sx={{
                        padding: '1.25rem 1rem',
                        '& .MuiDataGrid-columnHeader': {
                            textTransform: 'capitalize',
                        },
                    }}
                    autoHeight
                    rows={myProjects?.data || []}
                    columns={columns}
                    rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                    pageSize={DEFAULT_ROWS_PER_PAGE}
                    rowCount={myProjects?.total || 0}
                    isRowSelectable={() => false}
                    disableColumnMenu
                    loading={isFetching}
                    hideFooterSelectedRowCount
                    components={{
                        Toolbar: DataGridTitle,
                        NoRowsOverlay: DataGridNoRows,
                    }}
                    componentsProps={{
                        toolbar: { title: t('dashboard.myProjectTasks') },
                    }}
                    paginationMode="server"
                    sortingMode="server"
                    onPageChange={handlePageChange}
                    onSortModelChange={handleSortChange}
                    page={page}
                    sortModel={sortBy ? [sortBy] : []}
                    getRowHeight={({ model }: GridRowHeightParams) => {
                        if (model.buildings.length > 1) return 'auto'

                        return null
                    }}
                />
            </Card>
        </>
    )
}
