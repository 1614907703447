import React from 'react'
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react'
import { Hidden, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { GroupLayout, LayoutProps } from '@jsonforms/core'
import { DynamicTableShemaElement } from 'redux/types/schema.type'
import { groupElementsByRows } from 'utils/forms-utils'
import { StyledFixedTable, StyledTableCell, StyledTableHeaderCell } from 'components/general/jsonForm/view/styles'

const DynamicTablePresentation = (props: LayoutProps) => {
    const { uischema, schema, path, visible, renderers } = props
    const groupLayout = uischema as GroupLayout
    const options = groupLayout.options as DynamicTableShemaElement['options']

    if (!groupLayout.elements || groupLayout.elements.length === 0) return null

    const rows = groupElementsByRows(groupLayout.elements, options.columnCount)

    return (
        <Hidden xsUp={!visible}>
            <Typography variant="body1" sx={{ my: 1 }}>
                {groupLayout.label}
            </Typography>
            <TableContainer>
                <StyledFixedTable>
                    <TableHead>
                        <TableRow>
                            {Object.values(options.columnDefinitions).map((colDef) => (
                                <StyledTableHeaderCell
                                    sx={{
                                        width: colDef.size === 'auto' ? 'initial' : `${colDef.size}%`,
                                    }}
                                    key={colDef.columnName}
                                >
                                    {colDef.columnName}
                                </StyledTableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, rowIdx) => (
                            <TableRow key={`row-${path}-${rowIdx}`}>
                                {row.map((cell, cellIdx) => {
                                    return (
                                        <React.Fragment key={`cell-${path}-${rowIdx}-${cellIdx}`}>
                                            <StyledTableCell>
                                                <JsonFormsDispatch
                                                    uischema={cell}
                                                    schema={schema}
                                                    path={path}
                                                    enabled={props.enabled}
                                                    renderers={renderers}
                                                    cells={props.cells}
                                                />
                                            </StyledTableCell>
                                        </React.Fragment>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledFixedTable>
            </TableContainer>
        </Hidden>
    )
}

export const DynamicTableControl = withJsonFormsLayoutProps(DynamicTablePresentation)
