import React from 'react'
import { Add, Edit } from '@mui/icons-material'
import { Button, IconButton, SortDirection, Card } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TopBar } from 'components/general/top-bar/top-bar'
import { CreateOptionListDialog } from 'modules/settings/optionsBuilder/CreateOptionListDialog'
import { useGetPrebuiltOptionsQuery } from 'services/prebuiltOptions.service'
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { useQueryParams, StringParam, NumberParam } from 'use-query-params'
import { PageContainer } from 'components/general/containers/PageContainer'

export interface IQueryParams {
    page: number
    sort: string
    pageSize: number
    field: string
}

const DEFAULT_SORT: GridSortModel[number] = {
    field: 'updated',
    sort: 'desc',
}

export const OptionsBuilderPage: React.FC = () => {
    const { t } = useTranslation()
    const [idToEdit, setidToEdit] = React.useState<boolean | string>(false)

    const [search, setSearch] = useQueryParams({
        page: NumberParam,
        sort: StringParam,
        pageSize: NumberParam,
        field: StringParam,
    })

    const { data: prebuiltOptionList, isLoading } = useGetPrebuiltOptionsQuery({
        page: search.page || 0,
        direction: (search.sort as SortDirection) || 'desc',
        itemsPerPage: search.pageSize || DEFAULT_ROWS_PER_PAGE,
        sortBy: search.field || DEFAULT_SORT.field,
        byTenant: true,
    })

    React.useEffect(() => {
        if (search.page === undefined) {
            setSearch(
                {
                    ...DEFAULT_SORT,
                    page: 0,
                    pageSize: DEFAULT_ROWS_PER_PAGE,
                },
                'replace',
            )
        }
    }, [search, setSearch])

    const handleSortChange = (model: GridSortModel) => {
        if (model.length === 0) {
            return setSearch({
                field: DEFAULT_SORT.field,
                sort: DEFAULT_SORT.sort,
            })
        }

        setSearch({
            field: model[0].field,
            sort: model[0].sort,
        })
    }

    const handlePageChange = (page: number) => {
        setSearch({ page: page })
    }

    const columns: Array<GridColDef> = [
        {
            flex: 1,
            field: '',
            align: 'center',
            maxWidth: 60,
            editable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <IconButton color="secondary" aria-label={t('common.edit')} onClick={() => setidToEdit(params.row.id)}>
                    <Edit
                        sx={{
                            width: '1.5rem',
                            height: 'auto',
                        }}
                    />
                </IconButton>
            ),
        },
        {
            field: 'name',
            headerName: t('common.name'),
            flex: 1,
            minWidth: 250,
            headerAlign: 'center',
            align: 'center',
        },
    ]

    return (
        <>
            <TopBar
                rightActions={[
                    <div>
                        <Button
                            startIcon={<Add />}
                            color="secondary"
                            variant="contained"
                            onClick={() => setidToEdit(true)}
                        >
                            {t('common.addOptionList')}
                        </Button>
                    </div>,
                ]}
            />
            <PageContainer>
                <Card sx={{ minHeight: '70vh', height: '70vh' }}>
                    <DataGrid
                        sx={(theme) => ({
                            '& .MuiDataGrid-columnHeader--moving': {
                                bgcolor: '#f8f8f8',
                            },
                        })}
                        rows={prebuiltOptionList?.data || []}
                        columns={columns}
                        rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                        pageSize={search.pageSize || DEFAULT_ROWS_PER_PAGE}
                        rowCount={prebuiltOptionList?.total || 0}
                        isRowSelectable={() => false}
                        disableColumnMenu
                        loading={isLoading}
                        hideFooterSelectedRowCount
                        onPageChange={handlePageChange}
                        onSortModelChange={handleSortChange}
                        paginationMode="server"
                        sortingMode="server"
                        page={search.page || 0}
                    />
                </Card>
            </PageContainer>
            <CreateOptionListDialog
                recordId={idToEdit}
                handleClose={() => setidToEdit(false)}
                query={search as unknown as IQueryParams}
            />
        </>
    )
}
