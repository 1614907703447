import React from 'react'
import { TopBar } from '../../components/general/top-bar/top-bar'
import { CustomersTable } from '../../modules/customersAdmin/customers/CustomersTable'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { CustomerEditAndCreateDialog } from 'modules/customersAdmin/customers/CustomerEditAndCreateDialog'
import { PageContainer } from 'components/general/containers/PageContainer'

export const CustomersPage: React.FC = () => {
    const { t } = useTranslation()
    const [IscreateCustomerDialogOpen, setIsCreateCustomerDialogOpen] = React.useState(false)

    return (
        <>
            <Helmet>
                <title>{t('navigationMenu.customers')}</title>
            </Helmet>
            <TopBar
                rightActions={[
                    <Button
                        startIcon={<Add />}
                        color="secondary"
                        variant="contained"
                        onClick={() => setIsCreateCustomerDialogOpen(true)}
                    >
                        {t('customersPage.addCustomer')}
                    </Button>,
                ]}
            />
            <PageContainer>
                <CustomersTable />
            </PageContainer>

            <CustomerEditAndCreateDialog
                handleClose={() => setIsCreateCustomerDialogOpen(false)}
                open={IscreateCustomerDialogOpen}
                customer={null}
            />
        </>
    )
}
