import React from 'react'
import { Box, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { groupElementsByRows } from 'utils/forms-utils'
import { StyledTableCell, StyledTableHeaderCell } from './styles'
import { ElementValueBlock } from './ElementValueBlock'
import { JSONFormViewComp, SchemaElementTypeMap } from 'redux/types/schemaView.type'

export const DynamicTable: React.FC<JSONFormViewComp<SchemaElementTypeMap['dynamicTable']>> = React.memo(({ node }) => {
    if (!node.isVisible || !node.isVisibleInWeb || node.nodes.length === 0) return null
    const groupedNodes = groupElementsByRows(node.nodes, node.options.columnCount)

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {node.label && node.isLabelVisible && (
                <Typography sx={{ fontWeight: 600 }} variant="caption">
                    {node.label}
                </Typography>
            )}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {Object.values(node.options.columnDefinitions).map((colDef) => (
                                <StyledTableHeaderCell
                                    sx={{
                                        width: colDef.size === 'auto' ? 'initial' : `${colDef.size}%`,
                                    }}
                                    key={colDef.columnName}
                                >
                                    {colDef.columnName}
                                </StyledTableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groupedNodes.map((row, rowIdx) => (
                            <TableRow key={`row-${node.name}-${rowIdx}`}>
                                {row.map((cell, cellIdx) => (
                                    <StyledTableCell key={`cell-${cell.name}-${rowIdx}-${cellIdx}`}>
                                        <ElementValueBlock
                                            value={cell.value}
                                            type={cell.type}
                                            isOrderedList={cell.type === 'list' && cell.isOrdered}
                                        />
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
})
