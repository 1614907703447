import { ProjectStatus } from 'redux/types/project.type'

export const MIME_TYPES = {
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls: 'application/vnd.ms-excel',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pdf: 'application/pdf',
    dwg: 'image/vnd.dwg ,image/vnd.dxf',
}

export const PROJECT_WAITING_MANAGER_ACTIONS: ProjectStatus[] = [
    'Created',
    'FillingBuiltForm',
    'FillingGeneralInformation',
    'MinorIssueFix',
    'WaitingForConfirmation',
]

export const PROJECT_WAITING_FOR_QA_ACTIONS: ProjectStatus[] = ['WaitingForQa']
export const PROJECT_NO_EDIT_ACTIONS: ProjectStatus[] = ['WaitingForQa', 'Complete']
export const PROJECT_CAN_DELETE: ProjectStatus[] = ['Created', 'FillingBuiltForm', 'FillingGeneralInformation']
export const PROJECT_FINISH_BTN_VISIBLE: ProjectStatus[] = ['WaitingForConfirmation', 'MinorIssueFix']
export const PROJECT_FORM_VISIBLE_IN_CREATE_MODE: ProjectStatus[] = [
    'FillingBuiltForm',
    'WaitingForConfirmation',
    'MinorIssueFix',
]
