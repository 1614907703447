import camelCase from 'camelcase'
import * as O from 'optics-ts'
import React, { useState } from 'react'
import { convertJsonSchema } from '../../../utils/schema-converter'
import { useCreateSchemaMutation, useUpdateSchemaMutation } from '../../../services/schemas.service'
import { convertUiSchema } from '../../../utils/ui-schema-converter'
import { GoldenStandard } from 'redux/types/goldenStandards.type'
import { Schema, SchemaElement } from 'redux/types/schema.type'
import { FieldRule } from 'redux/types/schemaRule.type'

type ConditionalRuleSave =
    | {
          isGroup?: false
          newGroupName?: never
      }
    | {
          isGroup?: true
          newGroupName: string
          oldGroupname: string
      }

export const useRule = (lens: any, state: SchemaElement, setState: (x: any) => any) => {
    const [ruleValue, setRuleValue] = useState<any>()
    const ruleLens = lens.prop('rule')
    const rule = O.get(ruleLens)(state) as FieldRule

    const mountedOnce = React.useRef(false)

    React.useEffect(() => {
        if (!rule || mountedOnce.current) return
        setRuleValue(rule)
        mountedOnce.current = true
    }, [rule])

    const changeAllGroupRules = (newGroupName: string, oldGroupName: string, lens: any) => {
        const nodes = O.get(lens)(state)

        if (!nodes || !Array.isArray(nodes) || nodes.length === 0) return

        for (let i = 0; i < nodes.length; i++) {
            const nodeLens = lens.at(i)

            if (nodes[i].type === 'Group') {
                const nodeChildren = nodeLens.prop('nodes')
                changeAllGroupRules(newGroupName, oldGroupName, nodeChildren)
            }

            if (!nodes[i].rule) continue

            const groupRuleLens = nodeLens.prop('rule')
            const groupRuleConditions = groupRuleLens.prop('conditions')

            const conditions = O.get(groupRuleConditions)(state) as FieldRule['conditions']

            for (let j = 0; j < conditions.length; j++) {
                const conditionLens = groupRuleConditions.at(j)
                const fieldLens = conditionLens.prop('field')
                const field = O.get(fieldLens)(state) as FieldRule['conditions'][number]['field']

                const newField = {
                    node: field.node,
                    name: field.name.replaceAll(`.${oldGroupName}.`, `.${newGroupName}.`),
                    path: field.path.replaceAll(`.${oldGroupName}.`, `.${newGroupName}.`),
                }

                setState(O.set(fieldLens)(newField))
            }
        }
    }

    const onSaveRules = (values?: ConditionalRuleSave) => {
        if (values?.isGroup) {
            const rootLens = O.optic_<SchemaElement>() as any
            const nodesLens = rootLens.prop('nodes')

            changeAllGroupRules(values.newGroupName, values.oldGroupname, nodesLens)
        }

        setState(
            O.set(ruleLens)(
                ruleValue && ruleValue.conditions && ruleValue.conditions.length > 0 ? ruleValue : undefined,
            ),
        )
    }
    const hasRules = rule && rule.conditions && rule.conditions.length > 0

    return {
        rule,
        hasRules,
        setRuleValue,
        onSaveRules,
    }
}

export const useName = (lens: any, state: SchemaElement, setState: (x: any) => any) => {
    const enumLens = lens.prop('enum')
    const nameLens = lens.prop('name')
    const labelLens = lens.prop('label')
    const requiredLens = lens.prop('required')
    const isGoldenStandardLens = lens.prop('isGoldenStandard')
    const goldenStandardIdLens = lens.prop('selectedStandardId')
    const prebuiltOptionIdLens = lens.prop('prebuiltOptionListId')
    const optionsLens = lens.prop('options')

    const name = O.get(nameLens)(state)
    const label = (O.get(labelLens)(state) as string) ?? ''
    const required = O.get(requiredLens)(state) as boolean
    const selectedStandardId = O.get(goldenStandardIdLens)(state) as string
    const selectedPrebuiltOptionid = O.get(prebuiltOptionIdLens)(state) as string
    const isGoldenStard = O.get(isGoldenStandardLens)(state) as boolean
    const options = O.get(optionsLens)(state) as Record<string, any>

    const setLabel = (value: string) => {
        const stateOptions = {
            ...options,
        }
        const newState = O.set(labelLens)(value)(state)
        if (camelCase(label) === name) {
            const newName = camelCase(value)
            stateOptions.fieldName = newName
            setState(O.set(nameLens)(newName)(newState))
            setState(O.set(optionsLens)(stateOptions))
        } else {
            stateOptions.fieldName = value
            setState(newState)
        }
    }

    const setName = (value: string) => {
        const stateOptions = {
            ...options,
            fieldName: value,
        }
        setState(O.set(nameLens)(value))
        setState(O.set(optionsLens)(stateOptions))
    }
    const setRequired = (value: boolean) => setState(O.set(requiredLens)(value))
    const setStandard = (value: boolean) => setState(O.set(isGoldenStandardLens)(value))
    const setStandardId = (value?: string) => setState(O.set(goldenStandardIdLens)(value))
    const setPrebuiltList = (value?: string[]) => setState(O.set(enumLens)(value))

    const setPrebuiltOptionId = (value?: string) => {
        setState(O.set(prebuiltOptionIdLens)(value))
    }

    const setGoldenStandard = (option: GoldenStandard | null) => {
        if (!option) return

        setStandard(true)
        setName(option.name)
        setState(O.set(labelLens)(option.label))
        setStandardId(option.id)
    }

    const setColSizesForArr = (newOptions: Record<string, any>) => {
        const stateOptions = {
            ...options,
            columnDefinitions: newOptions,
        }
        setState(O.set(optionsLens)(stateOptions))
    }

    const setNewOptions = (newOptions: Record<string, any>) => {
        const stateOptions = {
            ...options,
            ...newOptions,
        }
        setState(O.set(optionsLens)(stateOptions))
    }

    const clearGoldenStandard = () => {
        setName('')
        setState(O.set(labelLens)(''))
        setStandard(false)
        setStandardId(undefined)
    }

    const clearPrebuiltList = () => {
        setState(O.set(prebuiltOptionIdLens)(undefined))
        setPrebuiltList(['opt1'])
    }

    return {
        name,
        setName,
        label,
        setLabel,
        required,
        setRequired,
        selectedStandardId,
        isGoldenStard,
        setGoldenStandard,
        clearGoldenStandard,
        selectedPrebuiltOptionid,
        setPrebuiltOptionId,
        setPrebuiltList,
        clearPrebuiltList,
        setColSizesForArr,
        setNewOptions,
        options,
    }
}

const emptyAppSchema: SchemaElement = {
    type: 'root',
    name: '',
    label: '',
    nodes: [],
}

type Options = {
    onCreate?: (schema: Schema) => void
    onUpdate?: (schema: Schema) => void
    assignedStructureId?: string
}

export const useAppSchema = (initialSchema?: Schema, options?: Options) => {
    const [appSchema, setAppSchema] = useState<SchemaElement>(initialSchema?.appSchema ?? emptyAppSchema)
    const [jsonSchema, setJsonSchema] = useState<any>(initialSchema?.jsonSchema)
    const [uiSchema, setUiSchema] = useState<any>(initialSchema?.uiSchema)
    const [name, setName] = useState<string>(initialSchema ? initialSchema.name : '')
    const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({})

    const updatePreview = (appSchema: SchemaElement) => {
        const jsonSchema = convertJsonSchema(appSchema)
        const uiSchema = convertUiSchema(appSchema)

        setJsonSchema(jsonSchema)
        setUiSchema(uiSchema)
    }

    const [create, { isLoading }] = useCreateSchemaMutation()
    const [update, { isLoading: isLoading2 }] = useUpdateSchemaMutation()

    const submit = (convertToGeneral: boolean = false) => {
        if (!name) {
            return setFieldErrors({ name: 'required' })
        } else {
            setFieldErrors({})
        }

        if (!isLoading && !isLoading2) {
            const jsonSchema = convertJsonSchema(appSchema)
            const uiSchema = convertUiSchema(appSchema, '#')
            if (initialSchema?.id == null) {
                create({
                    name,
                    appSchema: appSchema,
                    jsonSchema: jsonSchema,
                    uiSchema: uiSchema,
                    structureIdToAssign: options?.assignedStructureId,
                })
                    .unwrap()
                    .then((x) => {
                        if (options?.onCreate) {
                            options.onCreate(x)
                        }
                    })
            } else {
                update({
                    id: initialSchema.id,
                    name,
                    appSchema: appSchema,
                    jsonSchema: jsonSchema,
                    uiSchema: uiSchema,
                    structureIdToAssign: convertToGeneral === true ? undefined : options?.assignedStructureId,
                })
                    .unwrap()
                    .then((x) => {
                        if (options?.onCreate) {
                            options.onCreate(x)
                        } else if (options?.onUpdate) {
                            options.onUpdate(x)
                        }
                    })
            }
        }
    }

    return {
        appSchema,
        setAppSchema,
        jsonSchema,
        setJsonSchema,
        uiSchema,
        setUiSchema,
        updatePreview,
        name,
        setName,
        schema: {
            id: initialSchema?.id,
            name,
            appSchema,
            jsonSchema,
            uiSchema,
        } as Schema,
        submit,
        fieldErrors,
    }
}

export type RuleHook = ReturnType<typeof useRule>

export type NameHook = ReturnType<typeof useName>
