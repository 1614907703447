import React from 'react'
import { BuildingMetadata } from 'redux/types/building.type'

import { useTranslation } from 'react-i18next'
import { Typography, styled } from '@mui/material'

const MAP_OPTIONS = {
    zoom: 18,
    type: 'satellite',
}

const getMapSrcAttribute = (buildingMetadata?: BuildingMetadata | null, address?: string) => {
    if (buildingMetadata)
        return `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
        &q=${buildingMetadata.location.latitude},${buildingMetadata.location.longitude}&zoom=${MAP_OPTIONS.zoom}&maptype=${MAP_OPTIONS.type}`
    else if (address)
        return `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}
    &q=${address}&zoom=${MAP_OPTIONS.zoom}&maptype=${MAP_OPTIONS.type}`
    else return 'about:blank'
}

type BuildingMapProps = {
    metadata?: BuildingMetadata | null
    address?: string
}

export const BuildingMap: React.FC<BuildingMapProps> = React.memo(({ address, metadata }) => {
    const { t } = useTranslation()

    const frameSrc = getMapSrcAttribute(metadata, address)

    return (
        <FrameContainer hascoordinates={frameSrc !== 'about:blank'}>
            <div id="frame_inner_container">
                <iframe
                    width="100%"
                    height="400"
                    loading="lazy"
                    allowFullScreen
                    title={t('createEditBuildingPage.frameTitle')}
                    referrerPolicy="no-referrer-when-downgrade"
                    src={frameSrc}
                />
            </div>
            <div id="empty_location_display">
                <Typography variant="h5">{t('createEditBuildingPage.googleMapsPlaceholder')}</Typography>
            </div>
        </FrameContainer>
    )
})

const FrameContainer = styled('div')<{ hascoordinates: boolean }>(({ theme, hascoordinates }) => ({
    position: 'relative',
    width: '100%',
    minHeight: 400,
    marginBottom: theme.spacing(2),

    '& iframe': {
        border: 'none',
        visibility: hascoordinates ? 'initial' : 'hidden',
        width: '100%',
        minHeight: 400,
        maxWidth: 1000,
    },

    '& #empty_location_display': {
        visibility: hascoordinates ? 'hidden' : 'initial',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },

    '& #frame_inner_container': {
        height: 400,
        width: '100%',
    },

    [theme.breakpoints.down('sm')]: {
        minHeight: 300,
        '& iframe': {
            minHeight: 300,
        },
    },
}))
