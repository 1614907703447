import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { BuildingInformationForm } from 'modules/buildings/create/BuildingInformationForm'
import { yupResolver } from '@hookform/resolvers/yup'
import { buildingSchema } from 'schemas/addBuilding'
import { Building, CreateBuildingRequest, CreateOrUpdateBuildingForm } from 'redux/types/building.type'
import { ROUTES } from 'constants/routes'
import { useCreateBuildingMutation, useUpdateBuildingMutation } from 'services/buildings.service'
import { LoadingButton } from '@mui/lab'
import { navigationLinks } from 'utils/navigation-utils'
import { EBuildingTabs } from './BuildingTabs'

type PropertyConverter<T> = (value: T) => any

function convertProperty<T>(value: T, converter: PropertyConverter<T> = (val) => val) {
    return value !== undefined && value !== null && value !== '' ? converter(value) : undefined
}

const NEW_BUILDING_DEFAULT_VALUES: CreateOrUpdateBuildingForm = {
    name: '',
    municipality: '',
    titleNumber: '',
    landNumber: '',
    buildingNumber: '',
    address: {
        addressText: '',
        city: '',
        country: 'Norway',
        postalCode: '',
    },
    year: new Date().getFullYear().toString(),
    height: '',
    area: '',
    floors: [],
    fireLoad: '',
    fireLoadDescription: '',
    fireClass: '',
    fireClassDescription: '',
    culturalHeritage: false,
    fireDepartment: null,
    isFireDepartmentRegistred: false,
    fireStation: '',
    distanceToStation: '',
    comment: '',
    isPublic: true,
    culturalHeritageTags: undefined,
    customOccupationTags: [],
    customSafetyInstallations: [],
    safetyInstallations: [],
    specialRisks: [],
    specialRiskDescription: '',
    buildingMetadata: null,
}

const mapBuildingToFormEntity = (building?: Building): CreateOrUpdateBuildingForm => {
    if (!building) return NEW_BUILDING_DEFAULT_VALUES

    return {
        ...NEW_BUILDING_DEFAULT_VALUES,
        isPublic: building.status === 'Public',
        name: building.name,
        municipality: building.cadastre.municipality,
        landNumber: building.cadastre.land,
        titleNumber: building.cadastre.title,
        buildingNumber: building.buildingNumber ?? NEW_BUILDING_DEFAULT_VALUES.buildingNumber,
        address: building.address,
        year: building.year ?? NEW_BUILDING_DEFAULT_VALUES.year,
        height: building.height?.toString() ?? NEW_BUILDING_DEFAULT_VALUES.height,
        area: building.area?.toString() ?? NEW_BUILDING_DEFAULT_VALUES.area,
        floors: building?.floors.map((floor) => ({
            ...floor,
            name: floor.name ?? '',
            area: floor.area ? floor.area.toString() : '',
            terrain: floor.terrain === 'Over',
            occupations: floor.occupations.map((occ) => ({ label: occ, value: occ })),
        })),
        fireLoad: building.fireLoad?.toString() ?? NEW_BUILDING_DEFAULT_VALUES.fireLoad,
        fireLoadDescription: building.fireLoadDescription ?? NEW_BUILDING_DEFAULT_VALUES.fireLoadDescription,
        fireClass: building.fireClass ?? NEW_BUILDING_DEFAULT_VALUES.fireClass,
        fireClassDescription: building.fireClassDescription ?? NEW_BUILDING_DEFAULT_VALUES.fireClassDescription,
        culturalHeritage: building.culturalHeritage,
        culturalHeritageTags: building.culturalHeritageTags,
        fireDepartment: building.fireDepartment,
        fireStation: building.fireStation?.id ?? NEW_BUILDING_DEFAULT_VALUES.fireStation,
        isFireDepartmentRegistred: building.isFireDepartmentRegistred,
        distanceToStation: building.distanceToStation?.toString() ?? NEW_BUILDING_DEFAULT_VALUES.distanceToStation,
        comment: building.comment ?? NEW_BUILDING_DEFAULT_VALUES.comment,
        customOccupationTags: building.customOccupationTags.map((occ) => ({ value: occ })),
        customSafetyInstallations: building.customSafetyInstallations.map((instalation) => ({ value: instalation })),
        customSpecialRisks: building.customSpecialRisks.map((risk) => ({ value: risk })),
        safetyInstallations: building.safetyInstallations,
        specialRisks: building.specialRisks,
        specialRiskDescription: building.specialRiskDescription ?? NEW_BUILDING_DEFAULT_VALUES.specialRiskDescription,
        buildingMetadata: building.googleMetadata,
    }
}

type ConditionalProps =
    | {
          isEdit: false
          building?: never
      }
    | { isEdit: true; building: Building }

type BuildingFormTabsProps = {
    activeTab: EBuildingTabs
} & ConditionalProps

export const BuildingFormTabs: React.FC<BuildingFormTabsProps> = ({ building, activeTab, isEdit }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [createBuilding, { isLoading: isCreating }] = useCreateBuildingMutation()
    const [updateBuilding, { isLoading: isUpdating }] = useUpdateBuildingMutation()

    const isLoading = isCreating || isUpdating

    const methods = useForm<CreateOrUpdateBuildingForm>({
        mode: 'onSubmit',
        shouldFocusError: true,
        shouldUnregister: false,
        reValidateMode: 'onBlur',
        defaultValues: mapBuildingToFormEntity(building),
        resolver: yupResolver(buildingSchema),
    })

    const onSubmit = async (data: CreateOrUpdateBuildingForm) => {
        const {
            name,
            municipality,
            landNumber,
            titleNumber,
            buildingNumber,
            address,
            year,
            height,
            area,
            floors,
            fireLoad,
            fireLoadDescription,
            fireClass,
            culturalHeritage,
            culturalHeritageTags,
            fireDepartment,
            fireStation,
            distanceToStation,
            comment,
            isPublic,
            customOccupationTags,
            customSafetyInstallations,
            safetyInstallations,
            fireClassDescription,
            isFireDepartmentRegistred,
            specialRiskDescription,
            specialRisks,
            buildingMetadata,
        } = data

        const request: CreateBuildingRequest = {
            name,
            culturalHeritage,
            address,
            status: isPublic ? 'Public' : 'Private',
            municipality: convertProperty(municipality),
            landNumber: convertProperty(landNumber),
            titleNumber: convertProperty(titleNumber),
            buildingNumber: convertProperty(buildingNumber),
            year: year ? new Date(year).getFullYear().toString() : undefined,
            height: convertProperty(height, Number),
            area: convertProperty(area, Number),
            fireLoad: convertProperty(fireLoad, Number),
            fireLoadDescription: convertProperty(fireLoadDescription),
            fireClass: convertProperty(fireClass),
            fireClassDescription: convertProperty(fireClassDescription),
            fireStation: convertProperty(fireStation),
            distanceToStation: convertProperty(distanceToStation, Number),
            comment: convertProperty(comment),
            culturalHeritageTags: culturalHeritageTags?.map((tag) => tag.value) as string[],
            floors: floors.map((floor) => ({
                ...floor,
                area: floor.area ? Number(floor.area) : null,
                name: floor.name ? floor.name : null,
                occupations: floor.occupations.map((floorOccupation) => floorOccupation.value) as string[],
                terrain: floor.terrain ? 'Over' : 'Under',
            })),
            fireDepartment: convertProperty(fireDepartment?.id),
            isFireDepartmentRegistred,
            customOccupationTags: customOccupationTags?.map((occ) => occ.value),
            customSafetyInstallations: customSafetyInstallations?.map((inst) => inst.value),
            safetyInstallations: safetyInstallations?.map((inst) => inst.value) as string[],
            specialRiskDescription: convertProperty(specialRiskDescription),
            specialRisks: specialRisks?.map((risk) => risk.value) as string[],
            // building meta data from google will always exist if address is required,
            // the type is only made nullable for easier default value assigning
            buildingMetadata: buildingMetadata!,
        }

        try {
            if (isEdit) {
                await updateBuilding({ id: building!.id, building: request }).unwrap()
                navigate(navigationLinks.toBuilding(building!.id))
            } else {
                const createdBuilding = await createBuilding(request).unwrap()
                navigate(navigationLinks.toBuilding(createdBuilding.id))
            }
        } catch {}
    }

    return (
        <Container hidden={activeTab !== EBuildingTabs.BuildingInfo}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} id="building-form">
                    <BuildingInformationForm />
                </form>
            </FormProvider>
            <ButtonContainer>
                <Button
                    variant="outlined"
                    type="button"
                    color="secondary"
                    onClick={() => navigate(ROUTES.buildings)}
                    disabled={isLoading}
                >
                    {t('common.cancel')}
                </Button>

                <LoadingButton
                    variant="contained"
                    type="submit"
                    color="secondary"
                    form="building-form"
                    loading={isLoading}
                >
                    {t('common.save')}
                </LoadingButton>
            </ButtonContainer>
        </Container>
    )
}

const Container = styled('div')(({ theme }) => ({
    padding: theme.spacing(2, 0),
}))

const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1.5, 4, 0),
    gap: theme.spacing(2),
    borderTop: `1px solid ${theme.general.borderColor}`,

    '& button': {
        width: '100%',
        maxWidth: '7.5rem',
    },
}))
