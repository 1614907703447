import { Config } from 'redux/config'
import { MyFileEntity } from 'redux/types/file.type'
import { Project } from 'redux/types/project.type'
import { FormViewState } from 'redux/types/schemaView.type'
import { getLang } from 'translation/translation-helper'

export class FileService {
    static async DownloadAndCreateBlobUrl(fileId: string, fileAccessToken: string) {
        const res = await fetch(`${Config.backendUrl}/v1/files/${fileId}?accessToken=${fileAccessToken}`)
        await this.throwErrIfNotOk(res)
        const blob = await res.blob()
        const url = window.URL.createObjectURL(blob)

        return url
    }

    // mocked pdf from template data
    static async DownloadProjectPdfInProgress(
        templateId: string,
        token: string,
        mockedViewForm: Record<string, FormViewState>,
    ) {
        const data = {
            templateId,
            mockedViewForm,
        }
        const res = await fetch(`${Config.backendUrl}/v1/pdf/fake-by-template`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept-Language': getLang(),
            },
            body: JSON.stringify(data),
        })
        await this.throwErrIfNotOk(res)
        const blob = await res.blob()
        const url = window.URL.createObjectURL(blob)

        return url
    }

    // draft pdf from project data itself
    static async DownloadProjectPreviewPdf(projectId: string, token: string) {
        const data = {
            id: projectId,
        }

        const res = await fetch(`${Config.backendUrl}/v1/projects/pdf-preview`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept-Language': getLang(),
            },
            body: JSON.stringify(data),
        })

        await this.throwErrIfNotOk(res)
        const blob = await res.blob()
        const url = window.URL.createObjectURL(blob)

        return url
    }

    static async uploadProjectFiles(
        data: FormData,
        projectId: string,
        token: string,
        onProgressCb: (progressInPercentage: number) => void,
    ) {
        const xhr = new XMLHttpRequest()

        const success = await new Promise((resolve, reject) => {
            xhr.open('POST', `${Config.backendUrl}/v1/files/project?projectId=${projectId}`, true)
            xhr.setRequestHeader('authorization', `Bearer ${token}`)

            xhr.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    onProgressCb(Math.round((event.loaded / event.total) * 100))
                }
            })

            xhr.onload = function () {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(JSON.parse(this.responseText))
                } else {
                    reject({
                        status: xhr.status,
                        statusText: xhr.statusText,
                    })
                }
            }
            xhr.onerror = function () {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText,
                })
            }

            xhr.send(data)
        })
        return success as MyFileEntity[]
    }

    static BuildDownloadAllProjectFilesUrl(project: Project) {
        const searchParams = new URLSearchParams()

        project.files.forEach((file, idx) => {
            searchParams.append(`data[${idx}].Id`, file.id)
            searchParams.append(`data[${idx}].Accesstoken`, file.accessToken)
        })

        if (project.report) {
            searchParams.append(`data[${project.files.length}].Id`, project.report.id)
            searchParams.append(`data[${project.files.length}].Accesstoken`, project.report.accessToken)
        }

        searchParams.append('zipName', `${project.name}.zip`)

        const queryString = searchParams.toString()

        return `${Config.backendUrl}/v1/files/multiple?${queryString}`
    }

    private static async _CreateDownloadAnchor(response: Response, fileName: string) {
        const blob = await response.blob()

        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')

        a.href = url
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        a.remove()
    }

    private static async throwErrIfNotOk(res: Response) {
        if (!res.ok) {
            const errResponse = await res.json()
            throw errResponse
        }
        return
    }
}
