import React from 'react'
import { Control } from 'react-hook-form'
import { ControlledMuiAutoComplete } from 'components/inputs/ControlledMuiAutoComplete'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { CustomerOrOrganizationForm } from 'schemas/customers'
import { Customer, GetCustomersRequest } from 'redux/types/customer.type'
import { useLazyGetCustomersQuery } from 'services/customers.service'

interface CustomerAutoCompleteProps {
    control: Control<CustomerOrOrganizationForm>
    onChange: (customer: Customer) => void
}

const FETCH_RECORD_COUNT = 20

const INITIAL_FETCH_QUERY: GetCustomersRequest = {
    page: 0,
    itemsPerPage: FETCH_RECORD_COUNT,
    sortBy: 'updated',
    direction: 'desc',
}

// this is almost the same input as in project general form, but cant think of a way of making this
// component reusable as passing a generic form type to control doesnt work - cant set the input name then
export const CustomerAutoComplete = ({ control, onChange }: CustomerAutoCompleteProps) => {
    const { t } = useTranslation()
    const [fetchCustomers, { isFetching, data: customers }] = useLazyGetCustomersQuery({})

    React.useEffect(() => {
        fetchCustomers(INITIAL_FETCH_QUERY, true)
    }, [fetchCustomers])

    const fetch = React.useMemo(
        () =>
            debounce((searchQuery) => {
                if (searchQuery.length === 0) {
                    fetchCustomers(INITIAL_FETCH_QUERY, true)
                    return
                }
                fetchCustomers(
                    {
                        page: 0,
                        itemsPerPage: FETCH_RECORD_COUNT,
                        sortBy: 'updated',
                        direction: 'desc',
                        searchQuery,
                    },
                    true,
                )
            }, 400),
        [fetchCustomers],
    )

    const handleInputChange = (value: string) => {
        fetch(value)
    }

    return (
        <ControlledMuiAutoComplete
            control={control}
            name="customerImport"
            options={customers?.data || []}
            getOptionLabel={(opt) => opt?.name || ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onInputChange={(event, newInputValue) => handleInputChange(newInputValue)}
            filterOptions={(opt) => opt}
            loading={isFetching}
            onChangeCb={onChange}
            label={t('customerPage.chooseCustomer')}
        />
    )
}
