import React from 'react'
import { EProjectTabs } from '../create/ProjectDetails'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Tab,
    Tabs,
    styled,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { ProjectDocumentsViewMode } from '../create/ProjectDocumentsViewMode'
import { useProjectView } from '../hooks/useProjectView'
import { ProjectDetailsDisplay } from 'components/projects/DetailsDisplay'
import { LoadingButton } from '@mui/lab'
import { FileService } from 'services/file.service'

const PROJECT_TABS_DEFAULT = [
    {
        label: 'projectPage.tabs.projectData',
        value: EProjectTabs.ProjectInfo,
        defaultTranslation: 'Project data',
    },
    {
        label: 'projectPage.tabs.documents',
        value: EProjectTabs.Documents,
        defaultTranslation: 'Documents',
    },
]

export const Details: React.FC = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const { t } = useTranslation()
    const { project, isCustomerView } = useProjectView()

    const [expanded, setExpanded] = React.useState<boolean>(!isMobile)
    const [activeTab, setActiveTab] = React.useState<EProjectTabs>(EProjectTabs.ProjectInfo)

    const isDownloadAllFilesBtnVisible =
        project && activeTab === EProjectTabs.Documents && (project.files.length > 0 || project.report)

    const getDownloadAllLink = () => {
        if (!project) return

        return FileService.BuildDownloadAllProjectFilesUrl(project)
    }

    const handleChangeActiveTab = (newTab: EProjectTabs) => {
        setActiveTab(newTab)
        if (!expanded) setExpanded(true)
    }

    if (!project) return null

    return (
        <>
            <Accordion expanded={expanded}>
                <StyledAccordionSummary
                    expandIcon={
                        <IconButton onClick={() => setExpanded((prev) => !prev)}>
                            <ExpandMore />
                        </IconButton>
                    }
                >
                    <Tabs
                        value={activeTab}
                        onChange={(_, newTab) => handleChangeActiveTab(newTab)}
                        onClick={() => (expanded ? undefined : setExpanded(true))}
                    >
                        {PROJECT_TABS_DEFAULT.map((tab) => (
                            <Tab key={tab.value} value={tab.value} label={t(tab.label, tab.defaultTranslation)} />
                        ))}
                    </Tabs>
                    {isDownloadAllFilesBtnVisible && (
                        <LoadingButton
                            type="button"
                            variant="outlined"
                            size="small"
                            sx={(theme) => ({
                                ml: 'auto',
                                maxHeight: 35,
                                '&:hover': {
                                    background: theme.palette.primary.main,
                                    color: '#FFF',
                                },
                            })}
                            component="a"
                            href={getDownloadAllLink()}
                        >
                            {t('common.downloadAll')}
                        </LoadingButton>
                    )}
                </StyledAccordionSummary>
                <AccordionDetails sx={{ minHeight: 340 }}>
                    {activeTab === EProjectTabs.ProjectInfo && (
                        <ProjectDetailsDisplay isCustomerView={Boolean(isCustomerView)} project={project} />
                    )}
                    {activeTab === EProjectTabs.Documents && (
                        <ProjectDocumentsViewMode
                            files={project.files}
                            completed={true}
                            projectReport={project.report}
                            isCustomerView={Boolean(isCustomerView)}
                            projectBuildings={project.buildings}
                        />
                    )}
                </AccordionDetails>
            </Accordion>
        </>
    )
}

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    paddingLeft: 0,
    '& .MuiAccordionSummary-content.Mui-expanded': {
        marginBottom: 0,
        margin: theme.spacing(1.5, 0, 0),
    },
    '& .MuiAccordionSummary-content': {
        cursor: 'default',
        margin: theme.spacing(1.5, 0, 0),

        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            gap: theme.spacing(2),

            '& a': {
                marginLeft: theme.spacing(2),
            },
        },
    },
}))
