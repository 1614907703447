import { ROUTES } from 'constants/routes'
import { ProjectCustomerView } from 'pages/public/ProjectCustomerView.page'

const publicRoutes = [
    {
        path: ROUTES.projectCustomerView,
        element: <ProjectCustomerView />,
    },
]

export default publicRoutes
