import { STORAGE_LOCALE_DICTIONARY } from 'constants/locales'
import { getLang } from 'translation/translation-helper'

export const valueOrDefaultEmpty = <T>(value: T) => {
    if (value === null || value === undefined) return '-'
    if (typeof value === 'number') {
        const currentLang = getLang()
        return value.toLocaleString(STORAGE_LOCALE_DICTIONARY[currentLang])
    }
    return value || '-'
}

export const dateOrDefaultEmpty = (
    value: Date | string | number | null | undefined,
    formatCb: (value: Date | string | number) => string,
) => {
    if (!value) return '-'
    return formatCb(value)
}

const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

export const convertByte = (size: number | undefined | null) => {
    if (size === undefined || size === null) return
    let l = 0,
        n = size

    while (n >= 1024 && ++l) {
        n = n / 1024
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]
}

export const isObject = (value: unknown) => {
    return typeof value === 'object' && !Array.isArray(value) && value !== null
}
