import { useTranslation } from 'react-i18next'
import { Button, Stack, Typography, styled } from '@mui/material'
import { useCreateTenantMutation } from 'services/tenants.service'
import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreateTenantForm, tenantSchema } from 'schemas/addTenant'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { ControlledImageUpload } from 'components/inputs/ControlledImageUpload'

const DEFAULT_VALUES: CreateTenantForm = {
    name: '',
    logo: null,
    documentLogo: null,
}
// 2mb
const FILE_SIZE = 2 * 1024 * 1024

type CreateTenantDialogProps = Omit<ModalWrapperProps, 'title' | 'body' | 'actions'>

export const CreateTenantDialog: React.FC<CreateTenantDialogProps> = ({ open, handleClose }) => {
    const { t } = useTranslation()

    const [create, { isLoading }] = useCreateTenantMutation()

    const { handleSubmit, control, reset, setValue } = useForm<CreateTenantForm>({
        defaultValues: DEFAULT_VALUES,
        resolver: yupResolver(tenantSchema),
    })

    const onClose = () => {
        handleClose()
        reset()
    }

    const onSubmit = async (data: CreateTenantForm) => {
        try {
            const temp = new FormData()
            temp.append('name', data.name)
            temp.append('logo', data.logo!)
            temp.append('documentLogo', data.documentLogo!)

            await create(temp).unwrap()
            onClose()
        } catch {}
    }

    return (
        <ModalsWrapper
            sx={{
                zIndex: 9999,
            }}
            open={open}
            title={t('tenantsPage.addTenantDialog.title')}
            handleClose={onClose}
            fullWidth={true}
            maxWidth="md"
            body={
                <form id="tenant-form" onSubmit={handleSubmit(onSubmit)}>
                    <ContentWrapper>
                        <ControlledMuiTextField
                            control={control}
                            fullWidth
                            name="name"
                            variant="filled"
                            placeholder={t('tenantsPage.addTenantDialog.nameInputPlaceholder')}
                        />
                        <div>
                            <Typography variant="h6" sx={{ mb: -1 }}>
                                {t('tenantsPage.addTenantDialog.tenantLogoPlaceholder')}
                            </Typography>
                            <ControlledImageUpload
                                control={control}
                                name="logo"
                                setValue={setValue}
                                maxSize={FILE_SIZE}
                                accept={{
                                    'image/jpeg': [],
                                    'image/png': [],
                                    'image/webp': [],
                                }}
                                acceptedFileDescription={`${t('common.acceptedFormats')} .jpeg, .png, .webp`}
                            />
                        </div>
                        <div>
                            <Typography variant="h6" sx={{ mb: -1 }}>
                                {t('tenantsPage.addTenantDialog.tenantDocLogoPlaceholder')}
                            </Typography>
                            <ControlledImageUpload
                                control={control}
                                name="documentLogo"
                                setValue={setValue}
                                maxSize={FILE_SIZE}
                                accept={{
                                    'image/jpeg': [],
                                    'image/png': [],
                                }}
                                acceptedFileDescription={`${t('common.acceptedFormats')} .jpeg, .png, .webp`}
                            />
                        </div>
                    </ContentWrapper>
                </form>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button variant="outlined" onClick={onClose} color="secondary" disabled={isLoading}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="tenant-form"
                        loading={isLoading}
                    >
                        {t('common.confirm')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '30rem',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
