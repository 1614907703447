import { Paper } from '@mui/material'
import { PageContainer } from 'components/general/containers/PageContainer'
import { PageLoader } from 'components/general/loaders/PageLoader'
import { HeaderBackButton } from 'components/general/navigation/HeaderBackButton'
import { UnauthorizedTopBar } from 'components/general/top-bar/UnauthorizedTopBar'
import { ROUTES } from 'constants/routes'
import { BuildingFinishedProjects } from 'modules/buildings/building/BuildingFinishedProjects'
import { BuildingInformation } from 'modules/buildings/building/BuildingInformation'
import { BuildingProjects } from 'modules/buildings/building/BuildingProjects'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'
import { useGetBuildingQuery } from 'services/buildings.service'

export type CustomerBuildingPageParams = {
    buildingId: string
}

export const CustomerBuildingPage: React.FC = () => {
    const { buildingId } = useParams<CustomerBuildingPageParams>() as CustomerBuildingPageParams
    const { t } = useTranslation()

    const { isLoading, isError, isSuccess, data: building } = useGetBuildingQuery(buildingId)

    if (isLoading) return <PageLoader />
    if (isError) return <Navigate to="/404" />
    if (!isSuccess) return null

    return (
        <>
            <Helmet>{building && <title>{building.address.addressText}</title>}</Helmet>

            <UnauthorizedTopBar
                leftActions={
                    <HeaderBackButton
                        label={t('customerBuildingPage.headerBackButtonLabel')}
                        href={ROUTES.customerHome}
                    />
                }
            />

            <PageContainer>
                <Paper elevation={0} sx={{ py: 4, px: 2 }}>
                    <BuildingInformation building={building} />
                </Paper>

                <Paper elevation={0} sx={{ my: 2 }}>
                    <BuildingFinishedProjects buildingId={buildingId} />
                </Paper>
                <Paper elevation={0} sx={{ my: 2 }}>
                    <BuildingProjects buildingId={buildingId} />
                </Paper>
            </PageContainer>
        </>
    )
}
