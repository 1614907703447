import { ForgotPasswordPage } from 'pages/auth/ForgotPassword.page'
import { LoginAdminPage } from 'pages/auth/LoginAdmin.page'
import { ResetPasswordPage } from 'pages/auth/ResetPassword.page'
import { ROUTES } from 'constants/routes'
import { LoginPage } from 'pages/auth/Login.page'
import { VerifyCustomerTokenPage } from 'pages/auth/VerifyCustomerTokenPage'

const authRoutes = [
    {
        path: ROUTES.loginAdmin,
        element: <LoginAdminPage />,
    },
    {
        path: ROUTES.login,
        element: <LoginPage />,
    },
    {
        path: ROUTES.forgotPassword,
        element: <ForgotPasswordPage />,
    },
    {
        path: ROUTES.resetPassword,
        element: <ResetPasswordPage />,
    },
    {
        path: ROUTES.authenticateCustomer,
        element: <VerifyCustomerTokenPage />,
    },
]

export default authRoutes
