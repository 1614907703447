import React from 'react'
import { useWatch } from 'react-hook-form'
import { IconButton, Button, Typography, styled, Box, lighten } from '@mui/material'
import { useStructureFormField } from '../hooks/useStructureFormField'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { Add, Close, Edit } from '@mui/icons-material'
import { Draggable, Droppable } from 'components/forms/builder-fields/droppable'
import { getAllChildrenIds } from 'utils/forms-utils'
import { Schema } from 'redux/types/schema.type'
import { ControlledMuiSelectSearchable } from 'components/inputs/ControlledMuiSelectSearchable'
import { useTranslation } from 'react-i18next'
import { selectPrebuiltSchemaOptions } from 'redux/selectors/settings.selectors'
import { useAppSelector } from 'redux/hooks'
import { StructureBuilderForm } from '../hooks/useStructureForm'
import { FullPageFormDialog } from './FullPageFormDialog'
import { SchemaOption } from './StructureForm'

const extractHeadingsFromPrefix = (prefix: string) => {
    const regex = /[0-9]+/g
    const matches = prefix.match(regex)

    if (!matches) {
        return ''
    }

    const incrementedNumbers = matches.map((number) => parseInt(number) + 1)
    return incrementedNumbers.join('.')
}

interface StructureFormFieldProps {
    prefix?: string
    droppableId?: string
    level: number
    schemaOptions: SchemaOption[]
    schemas: Schema[]
    onSchemaAdd: (currentValue: string | null, prevFormId?: string) => void
    onSchemaRemove: (schemaId: string | string[]) => void
    setHistory: (value: StructureBuilderForm) => void
    structureId: string | undefined
}

// TODO: better styling
export const StructureFormField: React.FC<StructureFormFieldProps> = ({
    prefix = '',
    level,
    schemaOptions,
    schemas,
    onSchemaAdd,
    onSchemaRemove,
    setHistory,
    structureId,
}) => {
    const prebuiltSchemaOptions = useAppSelector(selectPrebuiltSchemaOptions)
    const { t } = useTranslation()
    const { fields, addNewChild, removeChild, labelInputPath, control, formIdInputPath, getValues } =
        useStructureFormField(prefix)

    const schemaId = useWatch({ control, name: formIdInputPath })
    const label = useWatch({ control, name: labelInputPath })

    const [isEditSchemaDialogOpen, setIsEditSchemaDialogOpen] = React.useState(false)

    const isStaticSchema = prebuiltSchemaOptions.findIndex((schema) => schema.value === schemaId) !== -1
    const currentSchema = schemas.find((schema) => schema.id === schemaId)

    const onChildRemove = (childIndex: number) => {
        removeChild(childIndex)()
        setHistory(getValues())
        const schema = fields[childIndex].schemaId

        if (fields[childIndex].children.length !== 0) {
            const ids = getAllChildrenIds(fields[childIndex].children)

            if (schema) {
                ids.push(schema)
            }

            return onSchemaRemove(ids)
        }

        if (schema) {
            onSchemaRemove(schema)
        }
    }

    const onChildAdd = () => {
        addNewChild()
        setHistory(getValues())
    }

    return (
        <div style={{ paddingLeft: 16 * level, margin: '16px 0' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {level !== 0 && <Heading variant="subtitle1">{extractHeadingsFromPrefix(prefix)}</Heading>}
                <div style={{ maxWidth: 250 }}>
                    <ControlledMuiTextField
                        control={control}
                        name={labelInputPath}
                        variant="filled"
                        placeholder={t('structurePage.formStructureNamePlaceholder')}
                    />
                </div>
                <div style={{ width: 350, marginLeft: 16 }}>
                    <ControlledMuiSelectSearchable
                        control={control}
                        name={formIdInputPath}
                        options={schemaOptions}
                        withDisabledOptions
                        onChangeCb={(value) => onSchemaAdd(value as string | null, schemaId)}
                        placeholder={t('structurePage.selectFormForStructurePlaceholder')}
                        groupBy={(opt) => opt.schemaType}
                        renderGroup={(params) => {
                            return (
                                <li key={params.key}>
                                    <GroupHeader>{t(`structurePage.schemaGroups.${params.group}`)}</GroupHeader>
                                    <GroupItems>{params.children}</GroupItems>
                                </li>
                            )
                        }}
                    />
                </div>
                {!isStaticSchema && structureId && schemaId && (
                    <IconButton onClick={() => setIsEditSchemaDialogOpen(true)}>
                        <Edit />
                    </IconButton>
                )}
                {isEditSchemaDialogOpen && currentSchema && (
                    <FullPageFormDialog
                        handleClose={() => setIsEditSchemaDialogOpen(false)}
                        open={isEditSchemaDialogOpen}
                        structureId={currentSchema.assignedStructureId ?? undefined}
                        schema={currentSchema}
                    />
                )}

                {label && !isStaticSchema && (
                    <Button variant="text" startIcon={<Add />} onClick={onChildAdd}>
                        {t('structurePage.addChildToSection', { sectionName: label })}
                    </Button>
                )}
            </div>
            {fields.map((field, index) => {
                const newPrefix = `${prefix}children.${index}`
                return (
                    <React.Fragment key={field.id}>
                        {index === 0 && <Droppable id={newPrefix} dragData={{ level: level + 1, index }} />}
                        <Draggable id={newPrefix} dragData={{ level: level + 1, index }}>
                            <Box display="flex">
                                <StructureFormField
                                    prefix={`${newPrefix}.`}
                                    droppableId={newPrefix}
                                    level={level + 1}
                                    schemaOptions={schemaOptions}
                                    schemas={schemas}
                                    onSchemaAdd={onSchemaAdd}
                                    onSchemaRemove={onSchemaRemove}
                                    setHistory={setHistory}
                                    structureId={structureId}
                                />
                                <Box mt={3}>
                                    <IconButton onClick={() => onChildRemove(index)}>
                                        <Close />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Draggable>
                        {index !== 0 && <Droppable id={newPrefix} dragData={{ level: level + 1, index }} />}
                    </React.Fragment>
                )
            })}
        </div>
    )
}

const Heading = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    marginRight: theme.spacing(1),
}))

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
}))

const GroupItems = styled('ul')({
    padding: 0,
})
