import React from 'react'
import { Control } from 'react-hook-form'
import { ControlledMuiAutoComplete } from 'components/inputs/ControlledMuiAutoComplete'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { CustomerOrOrganizationForm } from 'schemas/customers'
import { useLazyGetOrganizationsQuery } from 'services/organizations.service'
import { Organization, OrganizationPaginationParams } from 'redux/types/organization.type'

interface OrganizationAutoCompleteProps {
    control: Control<CustomerOrOrganizationForm>
    onChange: (org: Organization) => void
}

const FETCH_RECORD_COUNT = 20
const INITIAL_FETCH_QUERY: OrganizationPaginationParams = {
    page: 0,
    itemsPerPage: FETCH_RECORD_COUNT,
    sortBy: 'updated',
    direction: 'desc',
}

// this is almost the same input as in project general form, but cant think of a way of making this
// component reusable as passing a generic form type to control doesnt work - cant set the input name then
export const OrganizationAutoComplete = ({ control, onChange }: OrganizationAutoCompleteProps) => {
    const { t } = useTranslation()
    const [fetchOrganizations, { isFetching, data: organizations }] = useLazyGetOrganizationsQuery({})

    React.useEffect(() => {
        fetchOrganizations(INITIAL_FETCH_QUERY, true)
    }, [fetchOrganizations])

    const fetch = React.useMemo(
        () =>
            debounce((searchQuery) => {
                if (searchQuery.length === 0) {
                    fetchOrganizations(INITIAL_FETCH_QUERY, true)
                    return
                }
                fetchOrganizations(
                    {
                        page: 0,
                        itemsPerPage: FETCH_RECORD_COUNT,
                        sortBy: 'updated',
                        direction: 'desc',
                        searchQuery,
                    },
                    true,
                )
            }, 400),
        [fetchOrganizations],
    )

    const handleInputChange = (value: string) => {
        fetch(value)
    }

    return (
        <ControlledMuiAutoComplete
            control={control}
            name="organizationImport"
            options={organizations?.data || []}
            getOptionLabel={(opt) => opt?.name || ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onInputChange={(event, newInputValue) => handleInputChange(newInputValue)}
            filterOptions={(opt) => opt}
            loading={isFetching}
            onChangeCb={onChange}
            label={t('customerPage.chooseOrganization')}
        />
    )
}
