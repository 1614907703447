import React from 'react'
import { List, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Delete, Edit } from '@mui/icons-material'

type ContactActionMenuProps = {
    anchorEl?: null | HTMLElement
    id?: string
    onClose: () => void
    onDeleteContactAction: (id: string) => void
    onEditContactAction: (id: string) => void
}

export const ContactActionMenu: React.FC<ContactActionMenuProps> = ({
    anchorEl,
    onClose,
    onDeleteContactAction,
    onEditContactAction,
    id,
}) => {
    const { t } = useTranslation()

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
            }}
            onClose={onClose}
            PaperProps={{ sx: { minWidth: 200 } }}
        >
            <List sx={{ width: '100%' }}>
                <ListItemButton onClick={() => onDeleteContactAction(id!)}>
                    <ListItemIcon>
                        <Delete />
                    </ListItemIcon>
                    <ListItemText primary={t('common.delete')} />
                </ListItemButton>

                <ListItemButton onClick={() => onEditContactAction(id!)}>
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    <ListItemText primary={t('common.edit')} />
                </ListItemButton>
            </List>
        </Popover>
    )
}
