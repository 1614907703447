import React from 'react'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import { useDeleteSchemaMutation, useGetSchemasWithDeletableQuery } from 'services/schemas.service'
import { navigationLinks } from 'utils/navigation-utils'
import { SchemaLightWithDeletable } from 'redux/types/schema.type'
import { Box, FormControl, InputAdornment, SortDirection, TextField, styled } from '@mui/material'
import { Search } from '@mui/icons-material'
import { DataGrid, GridColDef, GridSortDirection } from '@mui/x-data-grid'
import { DataGridLinkCell } from 'components/general/table/DataGridLinkCell'
import DeleteDialog from 'components/general/dialogs/DeleteDialog'
import { useDataGridQueryFiltersState } from 'utils/hooks/useDataGridQueryFiltersState'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import useDebounce from 'utils/hooks/useDebounce'
import { DataGridNoRows } from 'components/general/table/DataGridNoRows'
import { DataGridActionsCell } from 'components/general/table/DataGridActionsCell'
import { TableActionMenuProps } from 'types/util'
import { SchemasActionsMenu } from './SchemasActionsMenu'
import CopyGeneralSchemaDialog from './CopyGeneralSchemaDialog'

type ExpandedTableActionMenuProps =
    | (TableActionMenuProps & {
          isDeletable: boolean
      })
    | null

export const SchemasTable: React.FC = () => {
    const { t } = useTranslation()

    const {
        handlePageChange,
        handleSortChange,
        handleSearchChange,
        search: { field, page, sort, search },
    } = useDataGridQueryFiltersState({ sort: 'desc', field: 'updated' })

    const [deleteSchema, { isLoading: isDeleting }] = useDeleteSchemaMutation()
    const { isFetching, data: schemas } = useGetSchemasWithDeletableQuery(
        {
            page: page || 0,
            itemsPerPage: DEFAULT_ROWS_PER_PAGE,
            direction: sort as SortDirection,
            sortBy: field ?? undefined,
            searchQuery: search || undefined,
        },
        { skip: page === undefined },
    )

    const searchSchemas = React.useCallback(
        async (search: string) => {
            if (search.length === 0) {
                handleSearchChange('')
                handlePageChange(0)
                return
            }
            if (search.length <= 2) return

            handlePageChange(0)
            handleSearchChange(search)
        },
        [handlePageChange, handleSearchChange],
    )

    const { search: inputSearchValue, onSearch } = useDebounce(searchSchemas, 500, search || '')

    const [anchorElProps, setAnchorPropsEl] = React.useState<ExpandedTableActionMenuProps>(null)
    const [schemaIdToDelete, setSchemaIdToDelete] = React.useState<string | null>(null)
    const [schemaIdToCopy, setSchemaIdToCopy] = React.useState<string | null>(null)

    const handleDelete = async () => {
        if (!schemaIdToDelete) throw new Error('Unhandled case, cannot call delete dialog without assigned schema')
        await deleteSchema({ id: schemaIdToDelete })
        setSchemaIdToDelete(null)
    }

    const closeActionMenu = () => setAnchorPropsEl(null)

    const openActionMenu = (event: React.MouseEvent<HTMLElement>, rowId: string, isDeletable: boolean) => {
        setAnchorPropsEl(anchorElProps ? null : { anchorEl: event.currentTarget, rowId, isDeletable })
    }

    const handleDeleteSchemaAction = (id: string) => {
        setSchemaIdToDelete(id)
        closeActionMenu()
    }

    const handleCopySchemaAction = (id: string) => {
        setSchemaIdToCopy(id)
        closeActionMenu()
    }

    const columns: GridColDef<SchemaLightWithDeletable>[] = [
        {
            field: 'name',
            headerName: t('common.name'),
            flex: 1,
            editable: false,
            renderCell: (params) => (
                <DataGridLinkCell label={params.row.name} to={navigationLinks.toAddSchema(params.row.id)} />
            ),
        },
        {
            field: '',
            maxWidth: 50,
            filterable: false,
            sortable: false,
            editable: false,
            type: 'action',
            renderCell: (params) => (
                <DataGridActionsCell
                    onOpen={(e, rowId) => openActionMenu(e, rowId, params.row.isDeletable)}
                    rowId={params.row.id}
                />
            ),
        },
        {
            field: 'updated',
            hideable: true,
        },
    ]

    return (
        <>
            <Paper elevation={0} sx={{ padding: 2.5, height: '100%' }}>
                <FilterContainer>
                    <FormControl fullWidth>
                        <TextField
                            sx={{ mt: 1.5 }}
                            type="text"
                            name="query"
                            value={inputSearchValue}
                            onChange={onSearch}
                            size="small"
                            placeholder={t('common.search')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </FilterContainer>
                <Box>
                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                textTransform: 'capitalize',
                            },
                            '& .MuiDataGrid-row': { cursor: 'pointer' },
                        }}
                        autoHeight
                        rows={schemas?.data || []}
                        columns={columns}
                        pageSize={DEFAULT_ROWS_PER_PAGE}
                        rowCount={schemas?.total || 0}
                        isRowSelectable={() => false}
                        disableColumnMenu
                        loading={isFetching}
                        hideFooterSelectedRowCount
                        rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                        components={{
                            NoRowsOverlay: DataGridNoRows,
                        }}
                        paginationMode="server"
                        sortingMode="server"
                        onPageChange={handlePageChange}
                        onSortModelChange={handleSortChange}
                        page={page || 0}
                        sortModel={field && sort ? [{ field, sort: sort as GridSortDirection }] : []}
                        // hide updated column
                        // we need to add updated column
                        // so that we can initially sort by updated
                        columnVisibilityModel={{
                            updated: false,
                        }}
                    />
                </Box>
            </Paper>
            <SchemasActionsMenu
                anchorEl={anchorElProps?.anchorEl}
                id={anchorElProps?.rowId}
                onClose={closeActionMenu}
                onDeleteSchemaAction={handleDeleteSchemaAction}
                onCopySchemaAction={handleCopySchemaAction}
                isDeletable={Boolean(anchorElProps?.isDeletable)}
            />
            <DeleteDialog
                deleteAction={handleDelete}
                handleClose={() => setSchemaIdToDelete(null)}
                open={Boolean(schemaIdToDelete)}
                isDeleting={isDeleting}
            />
            <CopyGeneralSchemaDialog
                handleClose={() => setSchemaIdToCopy(null)}
                open={Boolean(schemaIdToCopy)}
                schema={schemaIdToCopy ? schemas?.data.find((schema) => schema.id === schemaIdToCopy) : undefined}
            />
        </>
    )
}

const FilterContainer = styled('div')(({ theme }) => ({
    maxWidth: 300,
    marginBottom: theme.spacing(4),
}))
