import React from 'react'
import { ProjectGoldenStandardValue } from 'redux/types/project.type'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface GoldenStandardTableProps {
    projectGoldenStandards: ProjectGoldenStandardValue[]
}

export const GoldenStandardTable: React.FC<GoldenStandardTableProps> = ({ projectGoldenStandards }) => {
    const { t } = useTranslation()

    const renderStandardValue = (standard: ProjectGoldenStandardValue) => {
        if (standard.stringValue !== null) return standard.stringValue
        else if (standard.numberValue !== null) return standard.numberValue
        return standard.booleanValue ? t('common.yes') : t('common.no')
    }

    return (
        <TableContainer>
            <StyledTable size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('projectPage.goldenStandardHeaders.standard')}</TableCell>
                        <TableCell>{t('projectPage.goldenStandardHeaders.value')}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {projectGoldenStandards.map((standard, idx) => (
                        // For now stadards can be duplicated? there is no need for the idx if not
                        <TableRow key={`${standard.name}_${idx}`}>
                            <TableCell>{standard.name}</TableCell>
                            <TableCell>{renderStandardValue(standard)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </TableContainer>
    )
}

const StyledTable = styled(Table)(({ theme }) => ({
    minWidth: '38rem',
    '& th': {
        padding: theme.spacing(0.5, 1),
        fontWeight: 700,
        borderColor: theme.general.borderColor,
    },
    '& td': {
        padding: theme.spacing(0.5, 1),
    },
}))
