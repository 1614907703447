import { ControlProps, EnumOption, OwnPropsOfEnum, WithClassname, isDescriptionHidden } from '@jsonforms/core'
import { useFocus } from '@jsonforms/material-renderers'
import { WithOptionLabel } from '@jsonforms/material-renderers/lib/mui-controls/MuiAutocomplete'
import { withJsonFormsEnumProps } from '@jsonforms/react'
import {
    Autocomplete,
    Box,
    FormHelperText,
    Hidden,
    TextField,
    Typography,
    autocompleteClasses,
    lighten,
    styled,
} from '@mui/material'
import { isEqual, merge } from 'lodash'
import { BuildingPartGroupableOption } from 'types/util'

const BuildingPartTagPresentation = (props: ControlProps & OwnPropsOfEnum & WithClassname & WithOptionLabel) => {
    const [focused, onFocus, onBlur] = useFocus()
    const {
        description,
        className,
        config,
        id,
        label,
        required,
        errors,
        data,
        visible,
        handleChange,
        path,
        enabled,
        uischema,
    } = props

    const options: BuildingPartGroupableOption[] = uischema.options?.selectOptions || []

    const isValid = errors.length === 0
    const appliedUiSchemaOptions = merge({}, config, props.uischema.options)

    const showDescription = !isDescriptionHidden(
        visible,
        description,
        focused,
        appliedUiSchemaOptions.showUnfocusedDescription,
    )

    const firstFormHelperText = showDescription ? description : !isValid ? errors : null
    const secondFormHelperText = showDescription && !isValid ? errors : null

    const onChange = (_ev: any, newValues: EnumOption[]) => {
        // convert from an EnumOption to its value
        var values = newValues.map((o) => (o.value ? o.value : o))
        handleChange(path, values)
    }

    return (
        <Hidden xsUp={!visible}>
            <Autocomplete
                multiple
                id={id}
                size="small"
                className={className}
                disabled={!enabled}
                autoHighlight
                autoSelect
                autoComplete
                fullWidth
                options={options ? options : []}
                groupBy={(option) => option.codeGroup}
                renderGroup={(params) => (
                    <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
                renderOption={(props, option: BuildingPartGroupableOption, state) => {
                    return (
                        <Box
                            sx={{
                                flexDirection: 'column',
                                margin: '2px 0px',
                                [`&.${autocompleteClasses.option}`]: {
                                    padding: '4px',
                                    alignItems: 'flex-start',
                                },
                            }}
                            component="li"
                            {...props}
                            key={option.value}
                        >
                            <Typography variant="subtitle1">{option.label}</Typography>
                            {option.description && (
                                <Typography variant="caption" sx={{ fontSize: 10 }}>
                                    {option.description}
                                </Typography>
                            )}
                        </Box>
                    )
                }}
                value={data || []}
                renderInput={(params) => (
                    <TextField
                        label={label}
                        variant={appliedUiSchemaOptions.variant ? appliedUiSchemaOptions.variant : 'filled'}
                        inputRef={params.InputProps.ref}
                        autoFocus={appliedUiSchemaOptions.focus}
                        {...params}
                        id={id + '-input'}
                        required={required && !appliedUiSchemaOptions.hideRequiredAsterisk}
                        error={!isValid}
                        fullWidth={!appliedUiSchemaOptions.trim}
                        InputLabelProps={data ? { shrink: true } : undefined}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        focused={focused}
                    />
                )}
                onChange={onChange}
                isOptionEqualToValue={(option, value) => isEqual(option.value, value)}
            />
            <FormHelperText error={!isValid && !showDescription}>{firstFormHelperText}</FormHelperText>
            <FormHelperText error={!isValid}>{secondFormHelperText}</FormHelperText>
        </Hidden>
    )
}

export const BuildingPartTagControl = withJsonFormsEnumProps(BuildingPartTagPresentation)

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
}))

const GroupItems = styled('ul')({
    padding: 0,
})
