import React from 'react'
import { useTranslation } from 'react-i18next'
import { ViewSectionProps } from './GeneralSection'
import { valueOrDefaultEmpty } from 'utils/general'
import { SectionBlock } from 'components/buildings/SectionBlock'
import { FormItem } from 'components/buildings/FormItem'

export const FireClassSection: React.FC<ViewSectionProps> = ({ building }) => {
    const { t } = useTranslation()
    const { fireClass, fireClassDescription } = building

    return (
        <SectionBlock density="dense">
            <FormItem label={t('createEditBuildingPage.fireClass')}>{valueOrDefaultEmpty(fireClass)}</FormItem>
            <FormItem
                label={t('createEditBuildingPage.fireClassDescription')}
                sxTitleContainer={{ alignSelf: 'flex-start' }}
            >
                {valueOrDefaultEmpty(fireClassDescription)}
            </FormItem>
        </SectionBlock>
    )
}
