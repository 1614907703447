import { JsonFormsCore } from '@jsonforms/core'
import { FormSection, NormalizedFormSectionsWithProgress } from 'redux/types/formStructure.type'
import { FullProjectWithNormalizedData } from 'redux/types/project.type'
import {
    AllViewSchemaElements,
    ArrayViewSchemaElement,
    BaseViewSchemaElements,
    FormViewState,
} from 'redux/types/schemaView.type'

export const dispatchSubmit = (id: string) => {
    document.getElementById(id)?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
}

export function getAllChildrenIds(nodes: FormSection[]): string[] {
    const result: string[] = []

    function traverse(arr: FormSection[]) {
        for (const item of arr) {
            if (item.schemaId) {
                result.push(item.schemaId)
            }

            if (item.children) {
                traverse(item.children)
            }
        }
    }

    traverse(nodes)
    return result
}

export const extractFieldValues = <T extends Record<string, any>>(
    data: T,
    accessor: keyof T,
    recursiveFieldName: keyof T,
) => {
    const result: string[] = []
    const rootNodes: T[keyof T][] | null = data[recursiveFieldName] ? data[recursiveFieldName] : null

    if (!Array.isArray(rootNodes) || !rootNodes) return null

    function traverse(node: T) {
        if (accessor in node) {
            result.push(node[accessor])
        }

        if (recursiveFieldName in node && Array.isArray(node[recursiveFieldName])) {
            // @ts-ignore fix me later
            for (const childNode of node[recursiveFieldName]) {
                traverse(childNode)
            }
        }
    }

    rootNodes.forEach((node) => {
        traverse(node)
    })

    return result
}

export const getAdjacentIds = (currentId: string, nodeList?: string[]) => {
    const ids: Record<string, null | string> = { prev: null, next: null }
    if (!currentId || !nodeList) return ids

    const currentIdx = nodeList.findIndex((id) => id === currentId)

    if (currentIdx === -1) return ids

    ids.prev = nodeList[currentIdx - 1] || null
    ids.next = nodeList[currentIdx + 1] || null
    return ids
}

// Regular expression to find all occurrences of {{...}} in the template
const TEMPLATE_VALUE_REGEX = /{{(.*?)}}/g

const replaceTemplateValueFromBaseElement = (
    element: AllViewSchemaElements,
    project: FullProjectWithNormalizedData,
): AllViewSchemaElements => {
    if ((element.type === 'textArea' || element.type === 'textField') && element.value) {
        const replacedValue = element.value.replace(TEMPLATE_VALUE_REGEX, (match, group) => {
            const valueKey = group.trim()
            if (valueKey === 'CustomerName') return project?.customer?.name || ''
            return match
        })

        return {
            ...element,
            value: replacedValue,
        }
    }

    return element
}

const replaceTemplateValueFromElement = (
    element: AllViewSchemaElements,
    project: FullProjectWithNormalizedData,
): AllViewSchemaElements => {
    if (element.isVisible === false || element.isVisibleInWeb === false) return element

    if ('nodes' in element) {
        if (element.type !== 'array') {
            const updatedNodes = element.nodes.map((childElement) =>
                replaceTemplateValueFromElement(childElement, project),
            )

            return {
                ...element,
                nodes: updatedNodes as BaseViewSchemaElements[],
            }
        } else {
            const updatedElement = replaceTemplateValueFromArrayElement(element, project)
            return updatedElement
        }
    } else {
        return replaceTemplateValueFromBaseElement(element, project)
    }
}

const replaceTemplateValueFromArrayElement = (
    element: ArrayViewSchemaElement,
    project: FullProjectWithNormalizedData,
): ArrayViewSchemaElement => {
    if (element.isVisible === false || element.isVisibleInWeb === false) return element

    const newElement: ArrayViewSchemaElement = {
        ...element,
    }
    const newValues = []

    for (const row of element.value) {
        const rowCopy = { ...row }

        Object.keys(row).forEach((rowValueKey) => {
            const cellValue = row[rowValueKey]
            if (cellValue && typeof cellValue === 'string') {
                const replacedValue = cellValue.replace(TEMPLATE_VALUE_REGEX, (match, group) => {
                    const valueKey = group.trim()
                    if (valueKey === 'CustomerName') return project?.customer?.name || ''
                    return match
                })

                rowCopy[rowValueKey] = replacedValue
            }
        })
        newValues.push(rowCopy)
    }
    newElement.value = newValues
    return newElement
}

export const replaceFormViewStateTemplateValues = (
    formViewState: FormViewState | undefined | null,
    project: FullProjectWithNormalizedData,
): FormViewState | null => {
    if (!formViewState) return null
    const formViewSchemaWithReplacedTemplateValues: FormViewState = {
        ...formViewState,
        elements: formViewState.elements.map((element) => replaceTemplateValueFromElement(element, project)),
    }

    return formViewSchemaWithReplacedTemplateValues
}

/**
 * returns next and previous sections that are complete
 */
export const getAdjacentCompleteIds = (currentId: string, formTree?: NormalizedFormSectionsWithProgress) => {
    const ids: Record<string, null | string> = { prev: null, next: null }
    if (!currentId || !formTree) return ids

    const elementIds = Object.keys(formTree)
    const currentIndex = elementIds.indexOf(currentId)

    for (let i = currentIndex + 1; i < elementIds.length; i++) {
        if (formTree[elementIds[i]].progress === 'complete') {
            ids.next = formTree[elementIds[i]].sectionId
            break
        }
    }

    for (let i = currentIndex - 1; i >= 0; i--) {
        if (formTree[elementIds[i]].progress === 'complete') {
            ids.prev = formTree[elementIds[i]].sectionId
            break
        }
    }

    return ids
}

/**
 * use to group nodes in to rows for group table element
 */
export const groupElementsByRowsWithLabel = <T extends {}>(elements: T[], colCount: number) => {
    const rows: Array<T[]> = []
    // to account for label
    const elementsFittingInRow = colCount / 2
    const rowsCount = Math.ceil(elements.length / elementsFittingInRow)

    let currentIteration = 0
    for (let i = 0; i < rowsCount; i++) {
        const rowElements = []

        for (let j = currentIteration; j < elements.length; j++) {
            rowElements.push(elements[j])

            if (rowElements.length === elementsFittingInRow) {
                currentIteration++
                break
            }
            currentIteration++
        }
        rows.push(rowElements)
    }

    return rows
}

/**
 * use to group nodes in to rows for dynamic table element
 */
export const groupElementsByRows = <T extends {}>(elements: T[], colCount: number) => {
    const rows: Array<T[]> = []
    // to account for label

    const rowsCount = Math.ceil(elements.length / colCount)

    let currentIteration = 0
    for (let i = 0; i < rowsCount; i++) {
        const rowElements = []

        for (let j = currentIteration; j < elements.length; j++) {
            rowElements.push(elements[j])

            if (rowElements.length === colCount) {
                currentIteration++
                break
            }
            currentIteration++
        }
        rows.push(rowElements)
    }

    return rows
}

export function removeEmptyValues(parentObj: JsonFormsCore['data']): JsonFormsCore['data'] {
    if (typeof parentObj !== 'object' || parentObj === null) {
        return parentObj
    }

    if (Array.isArray(parentObj)) {
        const cleanedArray = parentObj.map((item) => removeEmptyValues(item))
        return cleanedArray.filter((item) => !isEmpty(item))
    }

    const cleanedObj: JsonFormsCore['data'] = {}

    for (const key in parentObj) {
        const value = parentObj[key]
        const cleanedValue = removeEmptyValues(value)
        if (!isEmpty(cleanedValue)) {
            cleanedObj[key] = cleanedValue
        }
    }

    return cleanedObj
}

function isEmpty(value: any): boolean {
    if (value === null || value === undefined) {
        return true
    }

    if (typeof value === 'string' && value.trim() === '') {
        return true
    }

    if (Array.isArray(value) && value.length === 0) {
        return true
    }

    if (typeof value === 'object' && Object.keys(value).length === 0) {
        return true
    }

    return false
}
