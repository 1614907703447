import React from 'react'
import {
    Box,
    Button,
    IconButton,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
} from '@mui/material'
import { CheckBox, CheckBoxOutlineBlank, Download, Edit, FolderOff } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { MyFileEntity } from 'redux/types/file.type'
import { Config } from 'redux/config'
import { StyledIconButton } from 'components/projects/EditButton'
import { IconFromMimeType } from 'components/projects/FileListItem'
import { valueOrDefaultEmpty } from 'utils/general'
import { StyledAnchor } from 'components/general/links/styles'
import { MIME_TYPES } from 'constants/util'
import { PdfViewDialog } from 'components/general/dialogs/PdfViewDialog'
import { FileService } from 'services/file.service'
import { toast } from 'react-toastify'
import { Building } from 'redux/types/building.type'

const NoFilesDisplay: React.FC = () => {
    const { t } = useTranslation()

    return (
        <Box display="flex" flexDirection="column" alignItems="center" minHeight="200px" justifyContent="center">
            <FolderOff fontSize="large" color="disabled" />
            <Typography variant="h5" sx={{ my: 2 }} textAlign="center">
                {t('common.empty')}
            </Typography>
            <Typography variant="body2" textAlign="center">
                {t('common.emptyFiles')}
            </Typography>
        </Box>
    )
}

type FileRowProps = {
    file: MyFileEntity
    handlePdfDownload: (fileId: string, fileAccessToken: string) => void
    group?: string
    isCustomerView?: boolean
    shouldRenderPdfAnchor: boolean
    buildings: Building[]
}

const FileRow: React.FC<FileRowProps> = ({
    file,
    handlePdfDownload,
    group,
    isCustomerView,
    shouldRenderPdfAnchor,
    buildings,
}) => {
    const fileUrl = `${Config.backendUrl}/v1/files/${file.id}?accessToken=${file.accessToken}`
    const relevantFloors =
        buildings.length === 1 && file.relatedFloors?.length
            ? buildings[0].floors.filter((floor) => file.relatedFloors?.includes(floor.number))
            : null

    return (
        <TableRow key={file.id}>
            <TableCell>
                <FileTypeIconContainer>
                    <IconFromMimeType mimeType={file.mimeType} />
                </FileTypeIconContainer>
            </TableCell>
            <TableCell>
                <div>
                    <Tooltip title={file.filename}>
                        {file.mimeType === MIME_TYPES.pdf ? (
                            <>
                                {shouldRenderPdfAnchor ? (
                                    <StyledEllipsisAnchor href={fileUrl} target="_blank">
                                        {file.filename}
                                    </StyledEllipsisAnchor>
                                ) : (
                                    <DownloadPdfButton
                                        variant="text"
                                        size="small"
                                        onClick={() => handlePdfDownload(file.id, file.accessToken)}
                                    >
                                        {file.filename}
                                    </DownloadPdfButton>
                                )}
                            </>
                        ) : (
                            <StyledEllipsisAnchor href={fileUrl}>{file.filename}</StyledEllipsisAnchor>
                        )}
                    </Tooltip>
                </div>
            </TableCell>
            <TableCell>{group}</TableCell>
            <TableCell>
                {relevantFloors ? relevantFloors.map((floor) => floor.name || floor.number)?.join(', ') : '-'}
            </TableCell>
            <TableCell>{valueOrDefaultEmpty(file.fileDescription)}</TableCell>
            <TableCell sx={{ display: isCustomerView ? 'none' : 'table-cell' }}>
                {file.isInternal ? (
                    <CheckBox color="primary" fontSize="small" />
                ) : (
                    <CheckBoxOutlineBlank color="primary" fontSize="small" />
                )}
            </TableCell>
            <TableCell>
                <IconButton component="a" href={fileUrl} sx={{ padding: 0 }}>
                    <Download />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

interface ProjectDocumentsViewModeProps {
    files: MyFileEntity[]
    onEditModeChange?: (value: boolean) => void
    completed: boolean
    isCustomerView?: boolean
    projectReport: MyFileEntity | null
    projectBuildings: Building[]
}

export const ProjectDocumentsViewMode: React.FC<ProjectDocumentsViewModeProps> = ({
    files,
    completed,
    onEditModeChange,
    isCustomerView,
    projectReport,
    projectBuildings,
}) => {
    const { t } = useTranslation()

    const [pdfToView, setPdfToView] = React.useState<string | null>(null)
    const [isDownloading, setIsDownloading] = React.useState(false)
    const cashedReportsRef = React.useRef<Record<string, string>>({})
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    const shouldRenderPdfAnchor = isMobileDevice || navigator.pdfViewerEnabled === false

    const groupedFiles = files.reduce((result: { [key: string]: MyFileEntity[] }, item) => {
        const key = (item.projectTag?.label || 'Other') as keyof typeof result
        if (!result[key]) {
            result[key] = []
        }
        result[key].push(item)
        return result
    }, {})

    const handlePdfDownload = async (fileId: string, fileAccessToken: string) => {
        if (isDownloading) return
        if (fileId in cashedReportsRef.current) {
            setPdfToView(cashedReportsRef.current[fileId])
            return
        }
        setIsDownloading(true)
        try {
            const url = await FileService.DownloadAndCreateBlobUrl(fileId, fileAccessToken)
            cashedReportsRef.current = {
                ...cashedReportsRef.current,
                [fileId]: url,
            }
            setPdfToView(url)
        } catch (err: any) {
            toast.error(err?.translationKey ? t(err.translationKey) : t('genericErrorMessage'))
        }
        setIsDownloading(false)
    }

    return (
        <>
            <div>
                {!completed && onEditModeChange && (
                    <StyledIconButton onClick={() => onEditModeChange(true)}>
                        <Edit />
                    </StyledIconButton>
                )}
                {files.length === 0 && projectReport === null ? (
                    <NoFilesDisplay />
                ) : (
                    <>
                        {isDownloading && <LinearProgress color="primary" />}
                        <TableContainer>
                            <StyledTable size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ minWidth: '3rem', width: '5%' }} />
                                        <TableCell sx={{ width: '20%' }}>{t('projectPage.filesTable.name')}</TableCell>
                                        <TableCell sx={{ width: '20%' }}>{t('projectPage.filesTable.type')}</TableCell>
                                        <TableCell sx={{ width: '20%' }}>
                                            {t('projectPage.filesTable.floors')}
                                        </TableCell>
                                        <TableCell sx={{ width: '40%' }}>
                                            {t('projectPage.filesTable.description')}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                minWidth: '5rem',
                                                width: '10%',
                                                display: isCustomerView ? 'none' : 'table-cell',
                                            }}
                                        >
                                            {t('projectPage.filesTable.internal')}
                                        </TableCell>
                                        <TableCell sx={{ minWidth: '3rem', width: '5%' }} />
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {projectReport && (
                                        <FileRow
                                            file={projectReport}
                                            isCustomerView={isCustomerView}
                                            handlePdfDownload={handlePdfDownload}
                                            shouldRenderPdfAnchor={shouldRenderPdfAnchor}
                                            buildings={projectBuildings}
                                        />
                                    )}
                                    {Object.entries(groupedFiles).map(([group, groupFiles]) => (
                                        <React.Fragment key={group}>
                                            <TableRow data-description-row="true">
                                                <TableCell colSpan={6}>{group}</TableCell>
                                            </TableRow>
                                            {groupFiles.map((file) => (
                                                <FileRow
                                                    key={file.id}
                                                    file={file}
                                                    group={group}
                                                    isCustomerView={isCustomerView}
                                                    handlePdfDownload={handlePdfDownload}
                                                    shouldRenderPdfAnchor={shouldRenderPdfAnchor}
                                                    buildings={projectBuildings}
                                                />
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </StyledTable>
                        </TableContainer>
                    </>
                )}
            </div>
            <PdfViewDialog open={pdfToView !== null} handleClose={() => setPdfToView(null)} url={pdfToView} />
        </>
    )
}

const StyledTable = styled(Table)(({ theme }) => ({
    minWidth: '38rem',
    '& th': {
        padding: theme.spacing(0.5, 1),
        fontWeight: 700,
        borderColor: theme.general.borderColor,
    },
    '& td': {
        padding: theme.spacing(0.5, 1),
        border: 'none',
    },
    '& [data-description-row="true"] > td': {
        fontWeight: 700,
        borderTop: `1px solid ${theme.general.borderColor}`,
        padding: theme.spacing(0.5, 1),
    },
}))

const FileTypeIconContainer = styled('div')({
    maxWidth: '1.5rem',
})

const DownloadPdfButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.main,
    textAlign: 'left',
    padding: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 320,
    whiteSpace: 'nowrap',
    display: 'block',

    '&:hover': {
        background: 'none',
        textDecoration: 'underline',
    },

    [theme.breakpoints.down('md')]: {
        width: 200,
    },

    [theme.breakpoints.down('sm')]: {
        width: 120,
    },
}))

const StyledEllipsisAnchor = styled(StyledAnchor)(({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 320,
    whiteSpace: 'nowrap',
    display: 'block',

    [theme.breakpoints.down('md')]: {
        width: 200,
    },

    [theme.breakpoints.down('sm')]: {
        width: 120,
    },
}))
