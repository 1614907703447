import React from 'react'
import { Button, Stack, styled, Box, IconButton, Typography } from '@mui/material'

import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { useTranslation } from 'react-i18next'
import { useFieldArray, Control } from 'react-hook-form'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { Add, Delete } from '@mui/icons-material'
import { CreateOrUpdateBuildingForm } from 'redux/types/building.type'

interface CustomSpecialRisksDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions' | 'open'> {
    control: Control<CreateOrUpdateBuildingForm>
    isOpen: boolean
}

export const CustomSpecialRisksDialog: React.FC<CustomSpecialRisksDialogProps> = ({ control, isOpen, handleClose }) => {
    const { t } = useTranslation()

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'customSpecialRisks',
    })

    const addNewChild = () => {
        append({
            value: '',
        })
    }

    const removeChild = (childIndex: number) => {
        remove(childIndex)
    }

    return (
        <ModalsWrapper
            open={isOpen}
            title={t('createEditBuildingPage.manageCustomSpecialRisks')}
            handleClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
            sx={{ zIndex: 10001 }}
            body={
                <ContentWrapper>
                    <Box my={2} pl={5}>
                        <Button variant="outlined" startIcon={<Add />} onClick={addNewChild}>
                            {t('common.add')}
                        </Button>
                    </Box>
                    <Typography variant="body1" sx={{ fontWeight: 600, paddingLeft: 5 }}>
                        {t('createEditBuildingPage.specialRisks')}:
                    </Typography>
                    {fields.map((field, index) => (
                        <Stack direction="row" key={field.id}>
                            <IconButton color="error" onClick={() => removeChild(index)}>
                                <Delete />
                            </IconButton>
                            <ControlledMuiTextField
                                name={`customSpecialRisks.${index}.value`}
                                control={control}
                                placeholder={t('createEditBuildingPage.customSpecialRiskPlaceholder')}
                                fullWidth
                                variant="filled"
                            />
                        </Stack>
                    ))}
                </ContentWrapper>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button variant="outlined" onClick={handleClose} color="secondary">
                        {t('common.close')}
                    </Button>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
