import React from 'react'
import { Box, FormControl, InputAdornment, Paper, SortDirection, TextField, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { navigationLinks } from 'utils/navigation-utils'
import { useGetBuildingsQuery } from 'services/buildings.service'
import { Search } from '@mui/icons-material'
import useDebounce from 'utils/hooks/useDebounce'
import { DataGrid, GridColDef, GridSortDirection } from '@mui/x-data-grid'
import { Building } from 'redux/types/building.type'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { DataGridNoRows } from 'components/general/table/DataGridNoRows'
import { useDataGridQueryFiltersState } from 'utils/hooks/useDataGridQueryFiltersState'

export const BuildingsTable: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const {
        handlePageChange,
        handleSortChange,
        handleSearchChange,
        search: { field, page, sort, search },
    } = useDataGridQueryFiltersState({ sort: 'desc', field: 'updated' })

    const { data: buildings, isFetching } = useGetBuildingsQuery(
        {
            page: page || 0,
            itemsPerPage: DEFAULT_ROWS_PER_PAGE,
            direction: sort as SortDirection,
            sortBy: field ?? undefined,
            searchQuery: search || undefined,
        },
        { skip: page === undefined },
    )

    const searchBuildings = React.useCallback(
        async (search: string) => {
            if (search.length === 0) {
                handleSearchChange('')
                handlePageChange(0)
                return
            }
            if (search.length <= 2) return

            handlePageChange(0)
            handleSearchChange(search)
        },
        [handlePageChange, handleSearchChange],
    )

    const { search: inputSearchValue, onSearch } = useDebounce(searchBuildings, 500, search || '')

    const columns: GridColDef<Building>[] = [
        {
            field: 'name',
            headerName: t('common.name'),
            flex: 1,
            editable: false,
        },
        {
            field: 'address',
            headerName: t('common.address'),
            flex: 1,
            editable: false,
            sortable: false,
            valueGetter: (params) => `${params.row.address.addressText}, ${params.row.address.city}` || '-',
        },
        {
            field: 'cadastre',
            headerName: t('common.cadastreNumber'),
            flex: 1,
            editable: false,
            sortable: false,
            maxWidth: 200,
            valueGetter: (params) => params.row.cadastre?.cleanNumber || '-',
        },
        {
            field: 'buildingNumber',
            headerName: t('common.buildingNumber'),
            flex: 1,
            maxWidth: 170,
            editable: false,
        },
        {
            field: 'status',
            headerName: t('buildingsPage.buildingType'),
            flex: 1,
            editable: false,
            maxWidth: 90,
            sortable: false,
            valueGetter: (params) =>
                params.row.status === 'Private'
                    ? t('buildingsPage.buildingPrivate')
                    : t('buildingsPage.buildingPublic'),
        },
        {
            field: 'updated',
            hideable: true,
        },
    ]

    return (
        <>
            <Paper elevation={0} sx={{ padding: 2.5, height: '100%' }}>
                <FilterContainer>
                    <FormControl fullWidth>
                        <TextField
                            sx={{ mt: 1.5 }}
                            type="text"
                            name="query"
                            value={inputSearchValue}
                            onChange={onSearch}
                            size="small"
                            placeholder={t('common.search')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </FilterContainer>
                <Box>
                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                textTransform: 'capitalize',
                            },
                            '& .MuiDataGrid-row': { cursor: 'pointer' },
                        }}
                        autoHeight
                        rows={buildings?.data || []}
                        onRowClick={(params) => navigate(navigationLinks.toBuilding(params.row.id))}
                        columns={columns}
                        pageSize={DEFAULT_ROWS_PER_PAGE}
                        rowCount={buildings?.total || 0}
                        isRowSelectable={() => false}
                        disableColumnMenu
                        loading={isFetching}
                        hideFooterSelectedRowCount
                        rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                        components={{
                            NoRowsOverlay: DataGridNoRows,
                        }}
                        paginationMode="server"
                        sortingMode="server"
                        onPageChange={handlePageChange}
                        onSortModelChange={handleSortChange}
                        page={page || 0}
                        sortModel={field && sort ? [{ field, sort: sort as GridSortDirection }] : []}
                        // hide updated column
                        // we need to add updated column
                        // so that we can initially sort by updated
                        columnVisibilityModel={{
                            updated: false,
                        }}
                    />
                </Box>
            </Paper>
        </>
    )
}

const FilterContainer = styled('div')(({ theme }) => ({
    maxWidth: 300,
    marginBottom: theme.spacing(4),
}))
