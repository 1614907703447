import React from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IOption } from '../ControlledMuiSelect'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { TagChip } from 'components/general/chips/TagChip'
import { Path, Control, FieldValues } from 'react-hook-form'
import { ModeEdit } from '@mui/icons-material'
import { OptionWithRiskClass } from 'types/util'
import { TagDialog } from 'components/general/dialogs/TagDialog'

export interface ControlledTagInputProps<T extends FieldValues> {
    name: Path<T>
    control: Control<T>
    options: Array<IOption> | Array<OptionWithRiskClass>
    searchable?: boolean
    onChangeCb?: (values: Array<IOption> | Array<OptionWithRiskClass>) => void
    onOpenCustomTagDialog?: () => void
}

export const ControlledTagInput = <T extends FieldValues>({
    options,
    control,
    name,
    searchable,
    onChangeCb,
    onOpenCustomTagDialog,
}: ControlledTagInputProps<T>) => {
    const { t } = useTranslation()
    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control,
    })

    const [isDialogOpen, setIsDialogOpen] = React.useState(false)

    const handleFilterChange = (values: Array<IOption> | Array<OptionWithRiskClass>) => {
        onChange(values)
        onChangeCb?.(values)
    }

    return (
        <>
            <Box display="flex" gap={2} alignItems="center">
                {Array.isArray(value) && value.length ? (
                    <>
                        <Box display="flex" gap={1} flexWrap="wrap">
                            {value.map((opt: IOption) => (
                                <TagChip
                                    key={`selected-${opt.value}`}
                                    label={opt.label}
                                    tagDescription={opt.description}
                                />
                            ))}
                        </Box>
                        <IconButton type="button" onClick={() => setIsDialogOpen(true)} color="primary">
                            <ModeEdit />
                        </IconButton>
                    </>
                ) : (
                    <Button
                        variant="outlined"
                        type="button"
                        color="primary"
                        size="small"
                        onClick={() => setIsDialogOpen(true)}
                    >
                        {t('common.addTag')}
                    </Button>
                )}
                {error?.message && (
                    <Typography variant="caption" sx={(theme) => ({ color: theme.palette.error.main })}>
                        {error.message}
                    </Typography>
                )}
            </Box>
            <TagDialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                options={options}
                selectedOptions={value}
                onSave={handleFilterChange}
                searchable={searchable}
                onOpenCustomTagDialog={onOpenCustomTagDialog}
            />
        </>
    )
}
