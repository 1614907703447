export const formatOrgNumber = (organizationNumber: string) => {
    if (!organizationNumber) return organizationNumber
    return organizationNumber.slice(0, 3) + ' ' + organizationNumber.slice(3, 6) + ' ' + organizationNumber.slice(6)
}

export const replaceLineBreaksWithBrTag = (s: string) => s.replace(/\n/g, '<br />')

export const formatVersionNumber = (version: number) => {
    return String(version).padStart(2, '0')
}

export const makeRandomId = (length: number) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
        counter += 1
    }
    return result
}
