import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { Tenant } from '../types/tenant.type'

export enum TenantFilterType {
    q = 'q',
}
export type TenantFilters = {
    q?: string
}
type TenantState = {
    selectedItems: Tenant[]
    filters: TenantFilters
}

const slice = createSlice({
    name: 'tenants',
    initialState: { selectedItems: [], filters: {} } as TenantState,
    reducers: {
        setSelectedItems: (state, { payload }: PayloadAction<Tenant[]>) => {
            state.selectedItems = payload
        },
        setFilters: (state, { payload }: PayloadAction<{ filter: TenantFilterType; value: any }>) => {
            state.filters[payload.filter] = payload.value
        },
    },
})

export const selectSelectedTenants = (state: RootState) => state.tenants.selectedItems
export const selectTenantFilters = (state: RootState) => state.tenants.filters

export default slice.reducer

export const tenantsActions = slice.actions
