import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Stack, styled } from '@mui/material'
import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
    CustomerOrOrganizationForm,
    companyCustomerGeneralSchema,
    personCustomerGeneralSchema,
} from 'schemas/customers'
import { Organization, UpdateOrganizationRequest } from 'redux/types/organization.type'
import { DetailsFormPart } from 'components/customersAdmin/DetailsFormPart'
import { useUpdateOrganizationMutation } from 'services/organizations.service'

type EditOrganizationGeneralInfoDialogProps = { organization: Organization } & Omit<
    ModalWrapperProps,
    'title' | 'body' | 'actions'
>

export const EditOrganizationGeneralInfoDialog: React.FC<EditOrganizationGeneralInfoDialogProps> = ({
    handleClose,
    open,
    organization,
}) => {
    const { t } = useTranslation()
    const [updateOrganization, { isLoading: isUpdating }] = useUpdateOrganizationMutation()

    const organizationIsPrivatePerson = organization.type === 'PrivatePerson'

    const { handleSubmit, control } = useForm<CustomerOrOrganizationForm>({
        shouldFocusError: true,
        defaultValues: {
            ...organization,
            isPrivatePerson: organizationIsPrivatePerson,
        },
        resolver: (values, context, options) =>
            yupResolver(values.isPrivatePerson ? personCustomerGeneralSchema : companyCustomerGeneralSchema)(
                values,
                context,
                options,
            ),
    })

    const onClose = () => {
        handleClose()
    }

    const onSubmit = async (data: CustomerOrOrganizationForm) => {
        const request: UpdateOrganizationRequest = {
            id: organization.id,
            organization: {
                type: organization.type,
                address: data.address,
                contacts: data.contacts,
                email: data.email,
                name: data.name,
                organizationNumber: data.organizationNumber,
                phoneNumber: data.phoneNumber,
            },
        }
        await updateOrganization(request).unwrap()
        onClose()
    }

    return (
        <ModalsWrapper
            sx={{
                zIndex: 9999,
            }}
            open={open}
            handleClose={onClose}
            fullWidth={true}
            maxWidth="sm"
            body={
                <form id="organization-details-form" onSubmit={handleSubmit(onSubmit)}>
                    <DetailsFormPart
                        title={t('organizationsPage.organizationDetails')}
                        control={control}
                        isPrivatePerson={organizationIsPrivatePerson}
                    />
                </form>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button variant="outlined" onClick={onClose} color="secondary" disabled={isUpdating}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="organization-details-form"
                        loading={isUpdating}
                    >
                        {t('common.confirm')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))
