import { useTranslation } from 'react-i18next'
import { Box, Card } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React from 'react'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { TableActionMenuProps } from 'types/util'
import { TemplateEntityWithPartialStructure } from 'redux/types/template.type'
import { useGetTemplatesQuery } from 'services/templates.service'
import { DataGridActionsCell } from 'components/general/table/DataGridActionsCell'
import { TemplatesActionsMenu } from './TemplatesActionsMenu'
import { CopyTemplateDialog } from './CopyTemplateDialog'
import { DataGridLinkCell } from 'components/general/table/DataGridLinkCell'
import { navigationLinks } from 'utils/navigation-utils'
import { NameChangeDialog } from './NameChangeDialog'
import DeleteTemplateDialog from './DeleteTemplateDialog'

export const TemplatesTable: React.FC = () => {
    const { t } = useTranslation()
    const { data: templates, isLoading } = useGetTemplatesQuery({})

    const [anchorElProps, setAnchorPropsEl] = React.useState<TableActionMenuProps>(null)
    const [templateToCopyId, setTemplateToCopyId] = React.useState<null | string>(null)
    const [templateToChangeNameId, setTemplateToChangeNameId] = React.useState<null | string>(null)
    const [templateToDeleteId, setTemplateToDeleteId] = React.useState<null | string>(null)

    const openActionMenu = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
        setAnchorPropsEl(anchorElProps ? null : { anchorEl: event.currentTarget, rowId })
    }

    const closeActionMenu = () => setAnchorPropsEl(null)

    const handleOpenCopyTemplateConfirmationDialog = () => {
        if (!anchorElProps?.rowId) throw new Error('Cant open confirmation dialog without assigned template id')
        setTemplateToCopyId(anchorElProps.rowId)
        closeActionMenu()
    }

    const handleOpenChangeTemplateNameDialog = (id: string) => {
        setTemplateToChangeNameId(id)
        closeActionMenu()
    }

    const handleOpenDeleteDialog = (id: string) => {
        setTemplateToDeleteId(id)
        closeActionMenu()
    }

    const columns: Array<GridColDef<TemplateEntityWithPartialStructure>> = [
        {
            field: 'name',
            headerName: t('common.name'),
            flex: 1,
            minWidth: 250,
            editable: false,
            renderCell: (params) => (
                <DataGridLinkCell label={params.row.name} to={navigationLinks.toEditTemplate(params.row.id)} />
            ),
        },
        {
            field: 'structureName',
            headerName: t('templatesPage.structureName'),
            flex: 1,
            minWidth: 250,
            editable: false,
        },
        {
            flex: 1,
            field: '',
            align: 'center',
            maxWidth: 60,
            sortable: false,
            editable: false,
            filterable: false,
            renderCell: (params) => <DataGridActionsCell onOpen={openActionMenu} rowId={params.row.id} />,
        },
    ]

    return (
        <>
            <Card elevation={0} sx={{ padding: 2.5, height: '100%' }}>
                <Box>
                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                textTransform: 'capitalize',
                            },
                            '& .MuiDataGrid-row': { cursor: 'pointer' },
                        }}
                        autoHeight
                        rows={templates || []}
                        columns={columns}
                        rowsPerPageOptions={[templates?.length || DEFAULT_ROWS_PER_PAGE]}
                        pageSize={templates?.length || DEFAULT_ROWS_PER_PAGE}
                        rowCount={templates?.length || 0}
                        isRowSelectable={() => false}
                        disableColumnMenu
                        loading={isLoading}
                        hideFooterSelectedRowCount
                    />
                </Box>
            </Card>

            <TemplatesActionsMenu
                anchorEl={anchorElProps?.anchorEl}
                id={anchorElProps?.rowId}
                onClose={closeActionMenu}
                onCopyTemplate={handleOpenCopyTemplateConfirmationDialog}
                onTemplateNameChange={handleOpenChangeTemplateNameDialog}
                onDeleteAction={handleOpenDeleteDialog}
            />
            {templateToCopyId && (
                <CopyTemplateDialog templateId={templateToCopyId} handleClose={() => setTemplateToCopyId(null)} />
            )}
            {templateToChangeNameId && templates && (
                <NameChangeDialog
                    handleClose={() => setTemplateToChangeNameId(null)}
                    template={templates.find((template) => template.id === templateToChangeNameId)!}
                />
            )}
            {templateToDeleteId && templates && (
                <DeleteTemplateDialog
                    open
                    handleClose={() => setTemplateToDeleteId(null)}
                    template={templates.find((template) => template.id === templateToDeleteId)!}
                />
            )}
        </>
    )
}
