import * as yup from 'yup'

export type ResetPasswordForm = {
    password: string
    confirmPassword: string
}

export const resetPasswordSchema: yup.ObjectSchema<ResetPasswordForm> = yup.object().shape({
    password: yup.string().trim().required('required'),
    confirmPassword: yup.string().trim().required('required'),
})
