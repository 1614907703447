import { string, object, array, lazy } from 'yup'

// @ts-ignore
const base = object({
    label: string().required('required').min(1, 'required'),
    formId: string().optional().nullable(),
    children: array().of(lazy(() => base.default(undefined))),
})

const createFormStructureSchema = object({
    sections: array().of(base),
    name: string().required('required').min(1, 'required'),
    type: string().required('required').min(1, 'required'),
})

export default createFormStructureSchema
