import React from 'react'
import DOMPurify from 'dompurify'
import { Button, Stack, styled } from '@mui/material'

import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { Trans, useTranslation } from 'react-i18next'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { FullProjectWithNormalizedData, ReviewProjectRequest } from 'redux/types/project.type'
import { useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { useSendProjectReviewMutation } from 'services/projects.service'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'constants/routes'

type ReviewProjectForm = Omit<ReviewProjectRequest, 'id'>

const DEFAULT_VALUES: ReviewProjectForm = {
    reviewComments: '',
}

interface AddProjectReviewDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions'> {
    project: FullProjectWithNormalizedData
}

export const AddProjectReviewDialog: React.FC<AddProjectReviewDialogProps> = ({ handleClose, open, project }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [sendReview, { isLoading }] = useSendProjectReviewMutation()

    const { handleSubmit, control } = useForm<ReviewProjectForm>({
        defaultValues: project?.reviewNotes ? { reviewComments: project.reviewNotes } : DEFAULT_VALUES,
    })

    const onSubmit = async (values: ReviewProjectForm) => {
        try {
            if (values.reviewComments) {
                const sanitezed = DOMPurify.sanitize(values.reviewComments)
                await sendReview({ reviewComments: sanitezed, id: project.id }).unwrap()
            } else {
                await sendReview({ id: project.id }).unwrap()
            }
            navigate(ROUTES.dashboard)
        } catch (e) {}
    }

    return (
        <ModalsWrapper
            sx={{
                zIndex: 9999,
            }}
            open={open}
            title={<Trans i18nKey="projectPage.addReviewForProject" values={{ projectName: project.name }} />}
            handleClose={handleClose}
            fullWidth={true}
            maxWidth={'sm'}
            body={
                <form id="add-review-form" onSubmit={handleSubmit(onSubmit)}>
                    <ContentWrapper>
                        <ControlledMuiTextField
                            control={control}
                            fullWidth
                            name="reviewComments"
                            variant="filled"
                            placeholder={t('projectPage.reviewPlaceholder')}
                            multiline
                            rows={10}
                        />
                    </ContentWrapper>
                </form>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button variant="outlined" onClick={handleClose} color="secondary" disabled={isLoading}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="error"
                        form="add-review-form"
                        disabled={isLoading}
                        loading={isLoading}
                    >
                        {t('common.confirm')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
