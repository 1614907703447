import { Table, TableCell, styled } from '@mui/material'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(0.75, 1.25),
    border: `1px solid ${theme.general.tableHeaderBg}`,
}))

export const StyledFixedTable = styled(Table)({
    width: '100%',
    overflowX: 'auto',
    tableLayout: 'fixed',
})

export const StyledUnorderedList = styled('ul')({
    margin: 0,
    paddingLeft: '1rem',
    fontSize: '0.875rem',
})

export const StyledOrderderList = styled('ol')({
    margin: 0,
    paddingLeft: '1rem',
    fontSize: '0.875rem',
})

export const StyledTableHeaderCell = styled(StyledTableCell)(({ theme }) => ({
    backgroundColor: theme.general.tableHeaderBg,
    color: '#FFF',
    fontWeight: 600,
    '&::first-letter': {
        textTransform: 'capitalize',
    },
}))
