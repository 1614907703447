import { appApi } from 'redux/api'
import { GoldenStandard } from 'redux/types/goldenStandards.type'

export const standardsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getStandards: builder.query<GoldenStandard[], void>({
            query: () => ({
                url: '/v1/GoldenStandards',
                method: 'GET',
            }),
        }),
    }),
})

export const { useGetStandardsQuery } = standardsApi
