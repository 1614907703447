import { useTranslation } from 'react-i18next'
import { useGetFormStructuresQuery } from '../../../services/formStructures.service'
import { navigationLinks } from 'utils/navigation-utils'
import {
    Box,
    Card,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SortDirection,
    TextField,
    styled,
} from '@mui/material'
import { Search } from '@mui/icons-material'
import { DataGrid, GridColDef, GridSortDirection } from '@mui/x-data-grid'
import { DataGridLinkCell } from 'components/general/table/DataGridLinkCell'
import React from 'react'
import useDebounce from 'utils/hooks/useDebounce'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { useDataGridQueryFiltersState } from 'utils/hooks/useDataGridQueryFiltersState'
import { FormStructureEntity } from 'redux/types/formStructure.type'
import { DataGridNoRows } from 'components/general/table/DataGridNoRows'
import { useAppSelector } from 'redux/hooks'
import { selectStructureTypeOptions } from 'redux/selectors/settings.selectors'
import { StringParam, useQueryParam } from 'use-query-params'
import { DataGridActionsCell } from 'components/general/table/DataGridActionsCell'
import { TableActionMenuProps } from 'types/util'
import { FormStructuresActionsMenu } from './FormStructuresActionsMenu'
import CopyStructureWithTemplateDialog from './CopyStructureWithTemplateDialog'
import DeleteStructureDialog from './DeleteStructureDialog'

export const StructuresTable: React.FC = () => {
    const { t } = useTranslation()
    const typeOptions = useAppSelector(selectStructureTypeOptions)

    const [selectedType, setSelectedType] = useQueryParam('type', StringParam)

    const {
        handlePageChange,
        handleSortChange,
        handleSearchChange,
        search: { field, page, sort, search },
    } = useDataGridQueryFiltersState({ sort: 'desc', field: 'updated' })

    const { isFetching, data: structures } = useGetFormStructuresQuery(
        {
            page: page || 0,
            itemsPerPage: DEFAULT_ROWS_PER_PAGE,
            direction: sort as SortDirection,
            sortBy: field ?? undefined,
            searchQuery: search || undefined,
            type: selectedType || undefined,
        },
        { skip: page === undefined },
    )

    const searchStructures = React.useCallback(
        async (search: string) => {
            if (search.length === 0) {
                handleSearchChange('')
                handlePageChange(0)
                return
            }
            if (search.length <= 2) return

            handlePageChange(0)
            handleSearchChange(search)
        },
        [handlePageChange, handleSearchChange],
    )

    const { search: inputSearchValue, onSearch } = useDebounce(searchStructures, 500, search || '')

    const [anchorElProps, setAnchorPropsEl] = React.useState<TableActionMenuProps>(null)
    const [structureIdForCopyStructure, setStructureIdForCopyStructure] = React.useState<string | null>(null)
    const [structureIdForDelete, setStructureIdForDelete] = React.useState<string | null>(null)

    const selectedStructureForCopyStructure = structureIdForCopyStructure
        ? structures?.data?.find((structure) => structure.id === structureIdForCopyStructure)
        : undefined
    const selectedStructureForDelete = structureIdForDelete
        ? structures?.data?.find((structure) => structure.id === structureIdForDelete)
        : undefined

    const openActionMenu = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
        setAnchorPropsEl(anchorElProps ? null : { anchorEl: event.currentTarget, rowId })
    }

    const closeActionMenu = () => setAnchorPropsEl(null)

    const handleOpenCopyStructureDialog = (structureId: string) => {
        setStructureIdForCopyStructure(structureId)
        closeActionMenu()
    }

    const handleDeleteStructureAction = (structureId: string) => {
        setStructureIdForDelete(structureId)
        closeActionMenu()
    }

    const columns: GridColDef<FormStructureEntity>[] = [
        {
            field: 'name',
            headerName: t('common.name'),
            flex: 1,
            editable: false,
            renderCell: (params) => (
                <DataGridLinkCell label={params.row.name} to={navigationLinks.toAddForm(params.row.id)} />
            ),
        },
        {
            field: 'type',
            headerName: t('structurePage.structureType'),
            flex: 1,
            editable: false,
            valueGetter: (params) => params.row.type.label,
        },
        {
            field: 'updated',
            hideable: true,
        },
        {
            field: '',
            maxWidth: 50,
            filterable: false,
            sortable: false,
            editable: false,
            type: 'action',
            renderCell: (params) => <DataGridActionsCell onOpen={openActionMenu} rowId={params.row.id} />,
        },
    ]

    return (
        <>
            <Card elevation={0} sx={{ padding: 2.5, height: '100%' }}>
                <FilterContainer>
                    <FormControl fullWidth>
                        <TextField
                            sx={{ mt: 1.5 }}
                            type="text"
                            name="query"
                            value={inputSearchValue}
                            onChange={onSearch}
                            size="small"
                            placeholder={t('common.search')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>{t('structurePage.structureType')}</InputLabel>
                        <Select value={selectedType || ''} onChange={(e) => setSelectedType(e.target.value)}>
                            <MenuItem value="">{t('common.clearFilter')}</MenuItem>
                            {typeOptions.map((opt) => (
                                <MenuItem key={opt.value} value={opt.value}>
                                    {opt.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FilterContainer>
                <Box>
                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                textTransform: 'capitalize',
                            },
                            '& .MuiDataGrid-row': { cursor: 'pointer' },
                        }}
                        autoHeight
                        rows={structures?.data || []}
                        columns={columns}
                        pageSize={DEFAULT_ROWS_PER_PAGE}
                        rowCount={structures?.total || 0}
                        isRowSelectable={() => false}
                        disableColumnMenu
                        loading={isFetching}
                        hideFooterSelectedRowCount
                        rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                        components={{
                            NoRowsOverlay: DataGridNoRows,
                        }}
                        paginationMode="server"
                        sortingMode="server"
                        onPageChange={handlePageChange}
                        onSortModelChange={handleSortChange}
                        page={page || 0}
                        sortModel={field && sort ? [{ field, sort: sort as GridSortDirection }] : []}
                        // hide updated column
                        // we need to add updated column
                        // so that we can initially sort by updated
                        columnVisibilityModel={{
                            updated: false,
                        }}
                    />
                </Box>
            </Card>

            <FormStructuresActionsMenu
                anchorEl={anchorElProps?.anchorEl}
                id={anchorElProps?.rowId}
                onClose={closeActionMenu}
                onCopyStructure={handleOpenCopyStructureDialog}
                onDeleteAction={handleDeleteStructureAction}
            />
            {selectedStructureForCopyStructure && (
                <CopyStructureWithTemplateDialog
                    handleClose={() => setStructureIdForCopyStructure(null)}
                    open={Boolean(structureIdForCopyStructure)}
                    structure={selectedStructureForCopyStructure}
                />
            )}
            {selectedStructureForDelete && (
                <DeleteStructureDialog
                    handleClose={() => setStructureIdForDelete(null)}
                    open
                    structure={selectedStructureForDelete}
                />
            )}
        </>
    )
}

const FilterContainer = styled('div')(({ theme }) => ({
    maxWidth: 500,
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(4),
}))
