import { SortDirection } from '@mui/material'
import { PaginatedResults } from 'components/general/models/paginated-results'
import { PaginationParams } from 'components/general/models/pagination-params'
import { MAX_RECORDS_QUERY } from 'constants/pagination'
import { appApi } from 'redux/api'
import {
    CreatePrebuiltOptionRequest,
    PrebuiltOptionEntity,
    UpdatePrebuiltOptionRequest,
} from 'redux/types/prebuiltOptions.type'

export const DEFAULT_PREBUILT_OPTIONS_QUERY = {
    ...MAX_RECORDS_QUERY,
    sortBy: 'created',
    direction: 'desc' as SortDirection,
}

export const preBuiltOptionsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getPrebuiltOptions: builder.query<
            PaginatedResults<PrebuiltOptionEntity>,
            PaginationParams<PrebuiltOptionEntity, { byTenant?: boolean }>
        >({
            query: (params) => ({
                url: '/v1/prebuiltOptions',
                method: 'GET',
                params: params,
            }),
            providesTags: ['PrebuiltOptions'],
        }),
        createPrebuiltOption: builder.mutation<PrebuiltOptionEntity, CreatePrebuiltOptionRequest>({
            query: (params) => ({
                url: 'v1/prebuiltOptions',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['PrebuiltOptions'],
        }),
        updatePrebuiltOption: builder.mutation<PrebuiltOptionEntity, UpdatePrebuiltOptionRequest>({
            query: (params) => ({
                url: `/v1/prebuiltOptions/${params.id}`,
                method: 'PUT',
                body: params,
            }),
            invalidatesTags: ['PrebuiltOptions'],
        }),
    }),
})

export const {
    useGetPrebuiltOptionsQuery,
    useCreatePrebuiltOptionMutation,
    useUpdatePrebuiltOptionMutation,
    useLazyGetPrebuiltOptionsQuery,
} = preBuiltOptionsApi
