import { TFunction } from 'i18next'
import { TableStructure } from '../../../components/general/table/table-structure.type'
import { User } from '../../../redux/types/user.type'
import { Tenant } from '../../../redux/types/tenant.type'
import { Edit } from '@mui/icons-material'

export const getTenantsTableStructure = (t: TFunction): TableStructure<Tenant>[] => [
    {
        label: t('common.name'),
        columnName: 'name',
        sortFunction: (item) => item.name,
        value: (item) => item.name,
    },
    {
        label: '',
        columnName: 'actions',
        sortFunction: (product) => product.name,
        value: (product) => <Edit />,
    },
]

export const getTenantUsersTableStructure = (t: TFunction): TableStructure<User>[] => [
    {
        label: t('common.email'),
        columnName: 'email',
        sortFunction: (item) => item.email,
        value: (item) => item.email,
    },
]
