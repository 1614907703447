import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ControlledMuiAutoComplete } from 'components/inputs/ControlledMuiAutoComplete'
import { useLazyGetCustomersQuery } from 'services/customers.service'
import { debounce } from '@mui/material/utils'
import { Customer } from 'redux/types/customer.type'
import { ProjectGeneralInfoRequestForm } from 'modules/projects/create/ProjectDetails'

export const ControlledCustomerAutoComplete: React.FC = () => {
    const {
        control,
        formState: { defaultValues },
    } = useFormContext<ProjectGeneralInfoRequestForm>()
    const [fetchCustomers, { isFetching, data: customers }] = useLazyGetCustomersQuery({})

    const fetchedOnce = React.useRef(false)

    const fetch = React.useMemo(
        () =>
            debounce((searchQuery) => {
                if (searchQuery.length < 2) return
                if (defaultValues?.customer && defaultValues.customer.name === searchQuery) return
                fetchedOnce.current = true
                fetchCustomers(
                    {
                        page: 0,
                        itemsPerPage: 20,
                        sortBy: 'created',
                        direction: 'desc',
                        searchQuery,
                    },
                    true,
                )
            }, 400),
        [defaultValues?.customer, fetchCustomers],
    )

    const handleInputChange = (value: string) => {
        fetch(value)
    }

    const initialValue = defaultValues?.customer ? [defaultValues.customer as Customer] : []

    return (
        <ControlledMuiAutoComplete
            name="customer"
            options={fetchedOnce.current ? customers?.data || [] : initialValue}
            control={control}
            loading={isFetching}
            filterOptions={(x) => x}
            getOptionLabel={(opt) => opt?.name || ''}
            isOptionEqualToValue={(opt, value) => opt?.id === value?.id}
            onInputChange={(event, newInputValue) => handleInputChange(newInputValue)}
            sx={{ width: '100%' }}
        />
    )
}
