import { Alert, Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SendLoginEmailRequest, useSendLoginEmailMutation } from '../../services/auth.service'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginCustomerSchema } from 'schemas/auth'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { LoadingButton } from '@mui/lab'
import { toast } from 'react-toastify'

const DEFAULT_VALUES: SendLoginEmailRequest = {
    email: '',
}

export const LoginPage: React.FC = () => {
    const { t } = useTranslation()
    const [sendEmail, { isLoading, error }] = useSendLoginEmailMutation()

    const { control, handleSubmit } = useForm<SendLoginEmailRequest>({
        mode: 'onSubmit',
        shouldFocusError: true,
        reValidateMode: 'onBlur',
        defaultValues: DEFAULT_VALUES,
        resolver: yupResolver(loginCustomerSchema),
    })

    const onSubmit = async (values: SendLoginEmailRequest) => {
        await sendEmail(values).unwrap()
        toast.success(t('authenticationPages.customerLoginMailSent'))
    }

    return (
        <Paper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box p={6}>
                    <Typography component="h1" variant="h5" align="center">
                        {t('authenticationPages.signIn')}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <ControlledMuiTextField
                            fullWidth
                            control={control}
                            name="email"
                            placeholder={t('common.email')}
                        />
                        <LoadingButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            loading={isLoading}
                        >
                            {t('common.continue')}
                        </LoadingButton>
                        <Typography variant="body2" textAlign="center" sx={{ mb: 2 }}>
                            {t('authenticationPages.loginlinkWillBeSent')}
                        </Typography>
                        {error &&
                        'data' in error &&
                        (error.data as { translationKey: string })?.translationKey === 'UNAUTHORIZED_ERROR' ? (
                            <Alert sx={{ mb: 2 }} severity="error">
                                {t('authenticationPages.incorrectCredentials')}
                            </Alert>
                        ) : null}
                    </Box>
                </Box>
            </form>
        </Paper>
    )
}
