import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import * as O from 'optics-ts'
import React from 'react'
import { RulesEditor } from '../rules/rules-editor'
import { Render } from '../schema-builder'
import { useName, useRule } from '../hooks/schema-hooks'
import { AddFieldDialog } from './add-field-dialog'
import { BuilderFieldProps } from './builder-field-props'
import { Draggable, Droppable } from './droppable'
import { FieldDialog } from './field-dialog'
import { FieldItem } from './field-item'
import { NameLabel } from './general/field-name-label'
import { TabPanel } from './general/field-tab-panel'
import { FieldTabs } from './general/field-tabs'
import { GroupTableSchemaElement } from 'redux/types/schema.type'
import { GroupTableColumnSizeTab } from '../modules/create/GroupTableColumnSizeTab'
import { GroupTableColSizeDefinition } from 'types/util'
import { validateGroupTableColumnsSizes } from 'utils/schema-utils'
import { useTranslation } from 'react-i18next'

const COLUMN_OPTIONS = [
    { key: '2-column-table', value: 2 },
    { key: '4-column-table', value: 4 },
    { key: '6-column-table', value: 6 },
]

const buildColumnDictionaryFromNodes = (
    oldColSizes: Record<number, GroupTableColSizeDefinition>,
    columnCount: number,
) => {
    const newColSizes: Record<number, GroupTableColSizeDefinition> = {}

    for (let i = 0; i < columnCount; i++) {
        const oldColumn = oldColSizes[i]
        if (oldColumn) {
            newColSizes[i] = oldColumn
        } else {
            newColSizes[i] = {
                columnNumber: i,
                isDirty: false,
                size: 'auto',
                unit: '%',
            }
        }
    }

    return newColSizes
}

export const UnqiueValueGroupTable: React.FC<BuilderFieldProps<GroupTableSchemaElement>> = ({
    node,
    state,
    setState,
    lens,
    path,
}) => {
    const { t } = useTranslation()
    const nameHook = useName(lens, state, setState)
    const rule = useRule(lens, state, setState)
    const nodes = lens.prop('nodes')

    const [tabIndex, setTabIndex] = React.useState(0)
    const [colSizes, setColSizes] = React.useState<Record<number, GroupTableColSizeDefinition>>(
        buildColumnDictionaryFromNodes({}, 2),
    )
    const [isColSizeValid, setIsColSizeValid] = React.useState(true)
    const [totalColumnsCount, setTotalColumnsCount] = React.useState(2)

    const prevName = React.useRef<string>(nameHook.name as string)

    const remove = () => setState(O.remove(lens)(state))

    const handleGroupSave = () => {
        const isValid = validateGroupTableColumnsSizes(colSizes)
        setIsColSizeValid(isValid)
        if (!isValid) {
            setTabIndex(2)
            return isValid
        }

        rule.onSaveRules({ isGroup: true, newGroupName: nameHook.name as string, oldGroupname: prevName.current })
        nameHook.setNewOptions({ columnDefinitions: colSizes, columnCount: totalColumnsCount })
        prevName.current = nameHook.name as string
        return true
    }

    const handleColumnCountChange = (e: SelectChangeEvent<number>) => {
        setTotalColumnsCount(Number(e.target.value))
        setColSizes((prev) => {
            const newColSizes = buildColumnDictionaryFromNodes(prev, Number(e.target.value))
            return newColSizes
        })
    }

    return (
        <Draggable id={`${path}`}>
            <FieldItem node={node} nameValue={nameHook.name} />
            <FieldDialog node={node} remove={remove} onSave={handleGroupSave}>
                <FieldTabs value={tabIndex} handleChange={setTabIndex} withColumnsTab />
                <TabPanel value={tabIndex} index={0}>
                    <NameLabel nameHook={nameHook} fieldType={node.type}>
                        <Stack spacing={2} direction="row">
                            <FormControl fullWidth sx={{ maxWidth: 225 }}>
                                <InputLabel>{t('schemaPage.totalColumns')}</InputLabel>
                                <Select
                                    label={t('schemaPage.totalColumns')}
                                    value={totalColumnsCount}
                                    onChange={handleColumnCountChange}
                                >
                                    {COLUMN_OPTIONS.map(({ key, value }) => (
                                        <MenuItem key={key} value={value}>
                                            {`${value} ${t('schemaPage.columns')}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    </NameLabel>
                    <Droppable id={`${path}.nodes.${0}`} />
                    {node.nodes?.map((_, idx: number) => {
                        const nodeLens = nodes.at(idx)

                        return (
                            <React.Fragment key={idx}>
                                <Box display="flex">
                                    <Typography variant="body1" sx={{ margin: 'auto 0', fontWeight: 700 }}>
                                        {`${Math.ceil((idx + 1) / (totalColumnsCount / 2))}.`}
                                    </Typography>
                                    <Render
                                        state={state}
                                        setState={setState}
                                        lens={nodeLens}
                                        path={`${path}.nodes.${idx}`}
                                    />
                                </Box>
                                <Droppable id={`${path}.nodes.${idx + 1}`} />
                            </React.Fragment>
                        )
                    })}
                    <AddFieldDialog nodesLens={nodes} setState={setState} parentField="groupTable" />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <GroupTableColumnSizeTab
                        setColumnSizeDefinitions={setColSizes}
                        isColSizeValid={isColSizeValid}
                        totalColumnsSelected={totalColumnsCount}
                        columnSizeDefinitions={colSizes}
                    />
                </TabPanel>
            </FieldDialog>
        </Draggable>
    )
}
