import * as React from 'react'
import TreeView from '@mui/lab/TreeView'
import TreeItem from '@mui/lab/TreeItem'
import { Link, To, useLocation } from 'react-router-dom'
import { styled } from '@mui/material'
import { NormalizedData } from 'types/util'
import { TreeProgressIcon } from './TreeProgressIcon'
import { ProjectStructureSectionProgress } from 'redux/types/formStructure.type'
import { useProjectQueryParams } from 'modules/projects/hooks/useProjectQueryParams'

type StructureItemWithProgress<T> = { sectionId: string; children: T[]; label: string; formId?: string } & {
    progress?: ProjectStructureSectionProgress
}
type TreeItemProps<T extends StructureItemWithProgress<T>> = {
    nodeId: string
    data: NormalizedData<T>
    accessTokenQueryString: string | undefined | null
    level: number
    indecieLabel?: string
    routeState?: any
}

export const getProjectTreeQueryUrl = (id: string, accessTokenQueryString: string | undefined | null) => {
    if (!accessTokenQueryString) {
        return {
            search: `structureId=${id}`,
        }
    }

    return {
        search: `structureId=${id}&accessToken=${accessTokenQueryString}`,
    }
}

const TreeLinkLabel: React.FC<{ label: string; link: To; level: number; routeState?: any }> = ({
    label,
    link,
    level,
    routeState,
}) => (
    <StyledLink to={link} state={routeState} level={level}>
        <span>{label}</span>
    </StyledLink>
)

const TreeLabel: React.FC<{ label: string; level: number }> = ({ label, level }) => (
    <StyledLabel level={level}>{label}</StyledLabel>
)

const TreeLinkLabelWithProgress: React.FC<{
    label: string
    link: To
    level: number
    progress?: ProjectStructureSectionProgress
    routeState?: any
}> = ({ label, link, progress, routeState, level }) => (
    <StyledLink to={link} state={routeState} level={level}>
        <span>{label}</span>
        <TreeProgressIcon progress={progress} />
    </StyledLink>
)

const renderTreeItem = <T extends StructureItemWithProgress<T>>({
    accessTokenQueryString,
    data,
    nodeId,
    indecieLabel,
    routeState,
    level,
}: TreeItemProps<T>) => {
    const label = indecieLabel ? `${indecieLabel} ${data[nodeId].label}` : data[nodeId].label
    const isNotAssignedForm = data[nodeId]?.progress === 'no-form'

    return (
        <StyledTreeItem
            nodeId={nodeId}
            label={
                isNotAssignedForm ? (
                    <TreeLabel label={label} level={level} />
                ) : (
                    <TreeLinkLabel
                        label={label}
                        link={getProjectTreeQueryUrl(nodeId, accessTokenQueryString)}
                        routeState={routeState}
                        level={level}
                    />
                )
            }
            key={nodeId}
        >
            {data[nodeId].children &&
                data[nodeId].children?.map((nodeId, index) => {
                    const nestedIndecieLabel = indecieLabel ? `${indecieLabel}.${index + 1}` : undefined
                    return renderTreeItem({
                        nodeId,
                        data,
                        accessTokenQueryString,
                        indecieLabel: nestedIndecieLabel,
                        routeState,
                        level: level + 1,
                    })
                })}
        </StyledTreeItem>
    )
}

const renderTreeItemWithProgressIcon = <T extends StructureItemWithProgress<T>>({
    accessTokenQueryString,
    data,
    nodeId,
    indecieLabel,
    routeState,
    level,
}: TreeItemProps<T>) => {
    const link = getProjectTreeQueryUrl(nodeId, accessTokenQueryString)
    const label = indecieLabel ? `${indecieLabel} ${data[nodeId].label}` : data[nodeId].label

    return (
        <StyledTreeItem
            nodeId={nodeId}
            label={
                <TreeLinkLabelWithProgress
                    label={label}
                    link={link}
                    progress={data[nodeId].progress}
                    routeState={routeState}
                    level={level}
                />
            }
            key={nodeId}
        >
            {data[nodeId].children &&
                data[nodeId].children?.map((nodeId, index) => {
                    const nestedIndecieLabel = indecieLabel ? `${indecieLabel}.${index + 1}` : undefined
                    return renderTreeItemWithProgressIcon({
                        nodeId,
                        data,
                        accessTokenQueryString,
                        indecieLabel: nestedIndecieLabel,
                        routeState,
                        level: level + 1,
                    })
                })}
        </StyledTreeItem>
    )
}

interface RecursiveTreeViewProps<T extends StructureItemWithProgress<T>> {
    data: NormalizedData<T>
    withProgressIcon: boolean
    withNumbering?: boolean
}

const NormalizedRecursiveTreeView = <T extends StructureItemWithProgress<T>>({
    data,
    withProgressIcon,
    withNumbering = false,
}: RecursiveTreeViewProps<T>) => {
    const { state } = useLocation()
    const [search] = useProjectQueryParams()
    const [selected, setSelected] = React.useState(search.structureId ?? undefined)
    const accessTokenQueryString = search.accessToken

    React.useEffect(() => {
        setSelected(search.structureId ?? undefined)
    }, [search.structureId])

    const handleSelect = (_event: React.SyntheticEvent, nodeId: string) => {
        if (data[nodeId].progress === 'no-form') return
        setSelected(nodeId)
    }

    return (
        <TreeView
            sx={{
                flexGrow: 1,
            }}
            expanded={Object.keys(data).filter((key) => data[key].children)}
            selected={selected}
            onNodeSelect={handleSelect}
            multiSelect={false}
        >
            {withProgressIcon
                ? Object.keys(data)
                      .filter((sectionId) => data[sectionId].parentId === undefined)
                      .map((sectionId, index) => {
                          const indecieLabel = withNumbering ? `${index + 1}` : undefined
                          return renderTreeItemWithProgressIcon({
                              nodeId: sectionId,
                              data,
                              accessTokenQueryString,
                              indecieLabel,
                              routeState: state,
                              level: 1,
                          })
                      })
                : Object.keys(data)
                      .filter((sectionId) => data[sectionId].parentId === undefined)
                      .map((sectionId, index) => {
                          const indecieLabel = withNumbering ? `${index + 1}` : undefined
                          return renderTreeItem({
                              nodeId: sectionId,
                              data,
                              accessTokenQueryString,
                              indecieLabel,
                              routeState: state,
                              level: 1,
                          })
                      })}
        </TreeView>
    )
}

export default NormalizedRecursiveTreeView

const getFontWeightByTreeLevel = (level: number) => {
    if (level === 1) return 700
    else if (level >= 3) return 400
    return 600
}

const StyledLink = styled(Link)<{ level: number }>(({ theme, level }) => ({
    textDecoration: 'none',
    width: '100%',
    display: 'flex',
    color: '#000',
    justifyContent: 'space-between',
    fontWeight: getFontWeightByTreeLevel(level),
    fontSize: level >= 3 ? '0.85rem' : '1.125rem',
    padding: theme.spacing(0.5, 0.5),
}))

const StyledLabel = styled('span')<{ level: number }>(({ theme, level }) => ({
    width: '100%',
    display: 'flex',
    color: theme.general.borderColor,
    justifyContent: 'space-between',
    fontWeight: getFontWeightByTreeLevel(level),
    fontSize: level >= 3 ? '0.85rem' : '1.125rem',
    padding: theme.spacing(0.5, 0.5),
    cursor: 'default',
}))

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    '& .MuiTreeItem-iconContainer': {
        display: 'none !important',
    },
    '& .MuiTreeItem-content': {
        padding: 0,
        margin: theme.spacing(0.5, 0),
    },
}))
