import React from 'react'
import { LinkProps, To } from 'react-router-dom'
import { StyledLink } from '../links/styles'

type DataGridLinkCellProps = {
    label: string | number
    to: To
    state?: LinkProps['state']
}

export const DataGridLinkCell: React.FC<DataGridLinkCellProps> = React.memo(({ label, to, state }) => {
    return (
        <StyledLink to={to} state={state}>
            {label}
        </StyledLink>
    )
})
