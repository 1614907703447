import React from 'react'
import { BuildingOrganization } from 'redux/types/building.type'
import { EBuildingTabs } from '../shared/BuildingTabs'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { OrganizationBuildingPermission } from 'redux/types/organization.type'
import { DataGridLinkCell } from 'components/general/table/DataGridLinkCell'
import { navigationLinks } from 'utils/navigation-utils'

type BuildingRelevantOrganizationsProps = {
    organizations: BuildingOrganization[]
    activeTab: EBuildingTabs
}

type GroupedBuildingOrganization = Omit<BuildingOrganization, 'type'> & { type: OrganizationBuildingPermission[] }

export const BuildingRelevantOrganizations: React.FC<BuildingRelevantOrganizationsProps> = React.memo(
    ({ organizations, activeTab }) => {
        const { t } = useTranslation()

        const groupedOrganizations = React.useMemo(() => {
            const groupedById = organizations.reduce((acc: { [key: string]: GroupedBuildingOrganization }, obj) => {
                const { organizationId, type, ...rest } = obj
                if (!(organizationId in acc)) {
                    acc[organizationId] = { organizationId, ...rest, type: [type] }
                } else {
                    acc[organizationId].type.push(type)
                }
                return acc
            }, {})

            return Object.values(groupedById)
        }, [organizations])

        return (
            <Container role="tabpanel" hidden={activeTab !== EBuildingTabs.Organizations}>
                {organizations.length ? (
                    <TableContainer sx={{ width: '100%', overflowX: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('common.name')}</TableCell>
                                    <TableCell>{t('createEditBuildingPage.organizationNumber')}</TableCell>
                                    <TableCell size="small">{t('common.type')}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {groupedOrganizations.map((org) => (
                                    <TableRow key={org.id}>
                                        <TableCell>
                                            <DataGridLinkCell
                                                label={org.name}
                                                to={navigationLinks.toOrganization(org.organizationId)}
                                            />
                                        </TableCell>
                                        <TableCell>{org.organizationNumber}</TableCell>
                                        <TableCell>
                                            {org.type.map((orgType) => t(`organizationTypes.${orgType}`)).join(', ')}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography variant="h6" textAlign="center">
                        {t('createEditBuildingPage.noOrganizations')}
                    </Typography>
                )}
            </Container>
        )
    },
)

const Container = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2, 2),
    minHeight: 100,
}))
