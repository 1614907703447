import { useEffect, useRef } from 'react'

type KeyBindingEvent = 'keydown' | 'keyup'

const useKeyBinding = (key: string, event: KeyBindingEvent = 'keydown', handler: () => void) => {
    const savedHandler = useRef<() => void>()

    useEffect(() => {
        savedHandler.current = handler
    }, [handler])

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            const keyControls = key.split('+')
            let withCtrl = false
            let withShift = false

            if (keyControls.includes('ctrl')) {
                withCtrl = true
            }
            if (keyControls.includes('shift')) {
                withShift = true
            }
            const isCtrlKeyPressed = event.ctrlKey || event.metaKey

            if ((withCtrl && !isCtrlKeyPressed) || (!withShift && event.shiftKey)) return
            if (withShift && !event.shiftKey) return
            if (event.key.toLowerCase() === keyControls[keyControls.length - 1]) {
                savedHandler.current && savedHandler.current()
            }
        }

        window.addEventListener(event, handleKeyPress)

        return () => {
            window.removeEventListener(event, handleKeyPress)
        }
    }, [event, key])
}

export default useKeyBinding
