import { appApi } from '../redux/api'
import { PaginatedResults } from '../components/general/models/paginated-results'
import {
    UpdateStatusRequest,
    StartProjectRequest,
    FullProjectWithNormalizedData,
    ReviewProjectRequest,
    GetProjectsRequest,
    MailLinksToCustomersRequest,
    GetCustomersFullProjectRequest,
    Project,
    UpdateProjectGeneralInfoRequest,
    UpdateProjectFormInfoRequest,
    ProjectWithoutForms,
} from 'redux/types/project.type'
import { transformFullProjectRequest } from 'utils/data-transforms'

export const projectsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getProjects: builder.query<PaginatedResults<ProjectWithoutForms>, GetProjectsRequest>({
            query: (params) => ({
                url: '/v1/projects',
                method: 'GET',
                params,
            }),
            providesTags: ['Projects'],
        }),
        getArchivedProjects: builder.query<PaginatedResults<ProjectWithoutForms>, GetProjectsRequest>({
            query: (params) => ({
                url: '/v1/projects/archived',
                method: 'GET',
                params,
            }),
            providesTags: ['ArchivedProjects', 'ArchivedProject'],
        }),

        getProject: builder.query<Project, { id: string; asCustomer: boolean }>({
            query: (payload) => ({
                url: `/v1/projects/${payload.id}?asCustomer=${payload.asCustomer}`,
                method: 'GET',
            }),
            providesTags: ['Projects'],
        }),
        getArchivedProject: builder.query<FullProjectWithNormalizedData, string>({
            query: (params) => ({
                url: `/v1/projects/archived/${params}`,
                method: 'GET',
            }),
            providesTags: ['ArchivedProject'],
            transformResponse: (response: FullProjectWithNormalizedData, meta) =>
                transformFullProjectRequest(response, true),
        }),
        // same as getProject but performs type transformation
        // if preview is true - performs transformation to the tree
        // to match the view same as for customer view
        getProjectNormalized: builder.query<
            FullProjectWithNormalizedData,
            { id: string; isPreview: boolean; asCustomer: boolean }
        >({
            query: (params) => ({
                url: `/v1/projects/${params.id}?asCustomer=${params.asCustomer}`,
                method: 'GET',
            }),
            transformResponse: (response: FullProjectWithNormalizedData, meta, { isPreview }) =>
                transformFullProjectRequest(response, isPreview),
            providesTags: ['Projects'],
        }),

        getCustomersFullProject: builder.query<FullProjectWithNormalizedData, GetCustomersFullProjectRequest>({
            query: (params) => ({
                url: `/v1/projects/${params.id}/customer`,
                method: 'GET',
                params: {
                    accessToken: params.accessToken,
                },
            }),
            transformResponse: (response: FullProjectWithNormalizedData, meta) =>
                transformFullProjectRequest(response, true),
            providesTags: ['ArchivedProject'],
        }),

        startProject: builder.mutation<Project, StartProjectRequest>({
            query: (params) => ({
                url: 'v1/projects/start',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: (result) => (!result ? [] : ['Projects']),
        }),

        updateProjectStatus: builder.mutation<void, UpdateStatusRequest>({
            query: (params) => ({
                url: `v1/projects/update-status/${params.id}`,
                method: 'PUT',
                body: {
                    status: params.status,
                },
            }),
            invalidatesTags: (result, error, arg) => {
                if (error) return []
                return arg.status === 'Complete' ? ['Projects', 'ArchivedProjects', 'ArchivedProject'] : ['Projects']
            },
        }),

        sendProjectReview: builder.mutation<void, ReviewProjectRequest>({
            query: (params) => ({
                url: `v1/projects/send-review`,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: (result, error) => (error ? [] : ['Projects']),
        }),

        sendLinksByMail: builder.mutation<void, MailLinksToCustomersRequest>({
            query: (params) => ({
                url: `v1/projects/mail-customer-link`,
                method: 'POST',
                body: params,
            }),
        }),

        deleteProject: builder.mutation<void, { id: string }>({
            query: (params) => ({
                url: `v1/projects/${params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result) => (result === null ? ['Projects'] : []),
        }),

        deleteProjects: builder.mutation<void, { ids: string[] }>({
            query: (payload) => ({
                url: 'v1/projects/many',
                method: 'DELETE',
                body: payload,
            }),
            invalidatesTags: (result) => (result === null ? ['Projects'] : []),
        }),

        updateProjectGeneralInfo: builder.mutation<Project, UpdateProjectGeneralInfoRequest>({
            query: (payload) => ({
                url: 'v1/projects/update-general',
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (result) => (result ? ['Projects'] : []),
        }),

        updateProjectForm: builder.mutation<Project, UpdateProjectFormInfoRequest>({
            query: (payload) => ({
                url: 'v1/projects/update-form',
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (result) => (result ? ['Projects'] : []),
        }),
    }),
})

export const {
    useGetProjectQuery,
    useGetProjectNormalizedQuery,
    useGetCustomersFullProjectQuery,
    useGetArchivedProjectQuery,
    useGetArchivedProjectsQuery,

    useStartProjectMutation,
    useUpdateProjectStatusMutation,
    useSendProjectReviewMutation,
    useSendLinksByMailMutation,
    useDeleteProjectMutation,
    useUpdateProjectGeneralInfoMutation,
    useUpdateProjectFormMutation,

    useGetProjectsQuery,
    useDeleteProjectsMutation,
} = projectsApi
