import React from 'react'
import { RenderView } from './RenderView'
import { Box, Typography } from '@mui/material'
import { JSONFormViewComp, SchemaElementTypeMap } from 'redux/types/schemaView.type'

export const GroupView: React.FC<JSONFormViewComp<SchemaElementTypeMap['Group']>> = React.memo(({ node }) => {
    if (!node.isVisible || !node.isVisibleInWeb || node.nodes.length === 0) return null

    return (
        <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
            {node.label && node.isLabelVisible && (
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {node.label}
                </Typography>
            )}
            {node.nodes.map((childNode, idx) => (
                <RenderView key={`${node.name}-${idx}`} element={childNode} direction="row" />
            ))}
        </Box>
    )
})
