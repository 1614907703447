import { useTranslation } from 'react-i18next'
import { Button, Stack, styled } from '@mui/material'
import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreateTenantUserForm, tenantUserSchema } from 'schemas/addTenantUser'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { LoadingButton } from '@mui/lab'
import { useCreateTenantUserMutation, useUpdateTenantUserMutation } from 'services/tenantUsers.service'
import { User } from 'redux/types/user.type'

const DEFAULT_VALUES: CreateTenantUserForm = {
    email: '',
    fullName: '',
    occupation: '',
}

type Props =
    | {
          user?: never
          tenantId: string
      }
    | { user: User; tenantId?: never }

type CreateOrEditUserDialogProps = Props & Omit<ModalWrapperProps, 'title' | 'body' | 'actions'>

export const CreateOrEditUserDialog: React.FC<CreateOrEditUserDialogProps> = ({
    tenantId,
    user,
    handleClose,
    open,
}) => {
    const { t } = useTranslation()

    const [create, { isLoading }] = useCreateTenantUserMutation()
    const [update, { isLoading: isUpdating }] = useUpdateTenantUserMutation()

    const { handleSubmit, control, reset } = useForm<CreateTenantUserForm>({
        defaultValues: user ? user : DEFAULT_VALUES,
        resolver: yupResolver(tenantUserSchema),
    })

    const onClose = () => {
        reset()
        handleClose()
    }

    const onSubmit = async (data: CreateTenantUserForm) => {
        try {
            if (!user) {
                await create({
                    ...data,
                    tenantId,
                }).unwrap()
            } else {
                await update({
                    ...user,
                    ...data,
                }).unwrap()
            }

            handleClose()
        } catch {}
    }

    return (
        <ModalsWrapper
            sx={{
                zIndex: 9999,
            }}
            open={open}
            title={user ? t('usersPage.createDialog.titleEdit') : t('usersPage.createDialog.title')}
            handleClose={onClose}
            fullWidth={true}
            maxWidth="sm"
            body={
                <form id="tenant-user-form" onSubmit={handleSubmit(onSubmit)}>
                    <ContentWrapper>
                        <ControlledMuiTextField
                            control={control}
                            fullWidth
                            name="fullName"
                            variant="filled"
                            placeholder={t('usersPage.createDialog.nameInputPlaceholder')}
                        />
                        <ControlledMuiTextField
                            control={control}
                            fullWidth
                            name="email"
                            variant="filled"
                            placeholder={t('usersPage.createDialog.emailInputPlaceholder')}
                        />
                        <ControlledMuiTextField
                            control={control}
                            fullWidth
                            name="occupation"
                            variant="filled"
                            placeholder={t('usersPage.createDialog.occupationsPlaceholder')}
                        />
                    </ContentWrapper>
                </form>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button variant="outlined" onClick={onClose} color="secondary" disabled={isLoading}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="tenant-user-form"
                        loading={isLoading || isUpdating}
                    >
                        {t('common.confirm')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
