import i18n from 'i18next'

export const LANG = 'language'
export const DEFAULT_LANG = process.env.NODE_ENV === 'development' ? 'en' : 'nb'

export const getLang = (): string => localStorage.getItem(LANG) ?? DEFAULT_LANG

export const changeLanguage = (language: string) => {
    localStorage.setItem(LANG, language)
    i18n.changeLanguage(language)
}
