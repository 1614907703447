import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Stack, styled } from '@mui/material'
import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { LoadingButton } from '@mui/lab'
import { Customer, CustomerGeneralInfoFormEntity } from 'redux/types/customer.type'
import { companyCustomerGeneralSchema } from 'schemas/customers'
import { useUpdateCustomerMutation } from 'services/customers.service'

const DEFAULT_VALUES: CustomerGeneralInfoFormEntity = {
    address: { addressText: '', country: '', postalCode: '' },
    email: '',
    name: '',
    organizationNumber: '',
    phoneNumber: '',
    type: 'Company',
}

type EditCustomerGeneralInfoDialogProps = { customer: Customer } & Omit<ModalWrapperProps, 'title' | 'body' | 'actions'>

export const EditCustomerGeneralInfoDialog: React.FC<EditCustomerGeneralInfoDialogProps> = ({
    handleClose,
    open,
    customer,
}) => {
    const { t } = useTranslation()
    const [updateCustomer, { isLoading: isUpdating }] = useUpdateCustomerMutation()

    const { handleSubmit, control, reset } = useForm<CustomerGeneralInfoFormEntity>({
        defaultValues: customer
            ? {
                  address: customer.address,
                  email: customer.email,
                  name: customer.name,
                  organizationNumber: customer.organizationNumber,
                  phoneNumber: customer.phoneNumber,
              }
            : DEFAULT_VALUES,
        resolver: yupResolver(companyCustomerGeneralSchema),
    })

    React.useEffect(() => {
        reset(customer || DEFAULT_VALUES)
    }, [customer, reset])

    const onClose = () => {
        reset()
        handleClose()
    }

    const onSubmit = async (data: CustomerGeneralInfoFormEntity) => {
        await updateCustomer({
            ...customer,
            address: data.address,
            email: data.email,
            name: data.name,
            organizationNumber: data.organizationNumber,
            phoneNumber: data.phoneNumber,
        }).unwrap()
        onClose()
    }

    return (
        <ModalsWrapper
            sx={{
                zIndex: 9999,
            }}
            open={open}
            handleClose={onClose}
            fullWidth={true}
            maxWidth="sm"
            title={t('customerPage.customerEditGeneralInfoDialogTitle')}
            body={
                <form id="customer-contact-form" onSubmit={handleSubmit(onSubmit)}>
                    <ContentWrapper>
                        <ControlledMuiTextField
                            name="name"
                            control={control}
                            placeholder={t('customersPage.customerDialog.customerNamePlaceholder')}
                            fullWidth
                            variant="filled"
                        />
                        <ControlledMuiTextField
                            name="organizationNumber"
                            control={control}
                            placeholder={t('customersPage.customerDialog.organizationNumberPlaceholder')}
                            fullWidth
                            variant="filled"
                        />
                        <ControlledMuiTextField
                            name="phoneNumber"
                            control={control}
                            placeholder={t('customersPage.customerDialog.phoneNumberPlaceholder')}
                            fullWidth
                            variant="filled"
                        />
                        <ControlledMuiTextField
                            name="email"
                            control={control}
                            placeholder={t('common.email')}
                            fullWidth
                            variant="filled"
                        />
                        <ControlledMuiTextField
                            name="address.addressText"
                            control={control}
                            placeholder={t('customersPage.customerDialog.addressTextPlaceholder')}
                            fullWidth
                            variant="filled"
                        />
                        <ControlledMuiTextField
                            name="address.postalCode"
                            control={control}
                            placeholder={t('customersPage.customerDialog.postalPlaceholder')}
                            fullWidth
                            variant="filled"
                        />
                        <ControlledMuiTextField
                            name="address.country"
                            control={control}
                            placeholder={t('customersPage.customerDialog.countryPlaceholder')}
                            fullWidth
                            variant="filled"
                        />
                    </ContentWrapper>
                </form>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button variant="outlined" onClick={onClose} color="secondary" disabled={isUpdating}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="customer-contact-form"
                        loading={isUpdating}
                    >
                        {t('common.confirm')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
