import React from 'react'
import { useCreateProject } from '../hooks/useCreateProject'
import { Project } from 'redux/types/project.type'
import { FormStructureEntity, FormSectionWithProgress } from 'redux/types/formStructure.type'
import { TemplateEntityWithPartialStructure } from 'redux/types/template.type'
import { User } from 'redux/types/user.type'
import { NormalizationUtils } from 'utils/normalization-utils'
import { NormalizedData } from 'types/util'
import { GoldenStandard } from 'redux/types/goldenStandards.type'

type CreacteProjectProviderProps = {
    children: React.ReactNode
}

type CreateProjectContextProps = {
    isLoading: boolean
    project: Project | undefined
    projectId: string
    structures: FormStructureEntity[]
    selectedTemplate?: TemplateEntityWithPartialStructure
    selectedStructure?: FormStructureEntity
    users: User[]
    templates: TemplateEntityWithPartialStructure[]
    standards: GoldenStandard[]
    normalizedStructure: {
        entities: NormalizedData<FormSectionWithProgress>
        result: string[]
    } | null
}

const CreateProjectContext = React.createContext<CreateProjectContextProps | null>(null)

export const CreacteProjectProvider = ({ children }: CreacteProjectProviderProps) => {
    const { isLoading, project, projectId, formStructures, templates, users, standards } = useCreateProject()

    const selectedTemplate = templates?.find((template) => template.id === project?.templateId)

    const normalizedStructureInfo = React.useMemo(() => {
        const normalized = NormalizationUtils.NormalizeNestedData<FormSectionWithProgress>(
            project?.structure ? project.structure.sections : undefined,
        )

        if (normalized && project?.formState) {
            project.formState.completeSchemas.forEach((id) => {
                if (normalized.entities[id]) {
                    normalized.entities[id].progress = 'complete'
                }
            })
            project.formState.draftSchemas.forEach((id) => {
                if (normalized.entities[id]) {
                    normalized.entities[id].progress = 'draft'
                }
            })
            project.formState.disabledSchemas.forEach((id) => {
                if (normalized.entities[id]) {
                    normalized.entities[id].progress = 'disabled'
                }
            })
            project.formState.unassignedSchemas.forEach((id) => {
                if (normalized.entities[id]) {
                    normalized.entities[id].progress = 'no-form'
                }
            })
        }

        return normalized
    }, [project?.formState, project?.structure])

    return (
        <CreateProjectContext.Provider
            value={{
                project,
                isLoading,
                projectId,
                structures: formStructures?.data || [],
                selectedTemplate,
                users: users?.data || [],
                templates: templates || [],
                normalizedStructure: normalizedStructureInfo,
                standards: standards || [],
            }}
        >
            {children}
        </CreateProjectContext.Provider>
    )
}

export const useCreateProjectContext = () => {
    const context = React.useContext(CreateProjectContext)
    if (!context) {
        throw new Error('useCreateProjectContext must be used within CreateProjectProvider')
    }
    return context
}
