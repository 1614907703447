import React from 'react'
import { TextItem } from './TextItem'
import { JSONFormViewComp, SchemaElementTypeMap } from 'redux/types/schemaView.type'

export const TextFieldView: React.FC<JSONFormViewComp<SchemaElementTypeMap['textField']>> = React.memo(
    ({ node, direction = 'row' }) => {
        if (!node.isVisible || !node.isVisibleInWeb || node.value === undefined) return null

        return (
            <TextItem
                direction={direction}
                label={node.label}
                value={node.value}
                isLabelVisible={node.isLabelVisible}
            />
        )
    },
)
