import {
    GetProjectRevisionsRequest,
    GetRevisionRequest,
    MinorPatchProjectRequest,
    ReviseProjectRequest,
    Revision,
    RevisionWithProject,
} from 'redux/types/projectRevision.type'
import { appApi } from '../redux/api'
import { transformFullProjectRequest } from 'utils/data-transforms'

export const projecRevisionService = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getProjectRevisions: builder.query<Revision[], GetProjectRevisionsRequest>({
            query: (payload) => ({
                url: '/v1/projectRevisions/by-project',
                method: 'GET',
                params: payload,
            }),
            providesTags: ['ProjectRevisions'],
        }),
        getRevision: builder.query<RevisionWithProject, GetRevisionRequest>({
            query: (payload) => ({
                url: '/v1/projectRevisions',
                method: 'GET',
                params: payload,
            }),
            transformResponse: (response: RevisionWithProject, meta) => {
                const transformedProject = transformFullProjectRequest(response.revisedProject, true)
                return {
                    created: response.created,
                    id: response.id,
                    revisedProject: transformedProject,
                    updated: response.updated,
                    version: response.version,
                }
            },
            providesTags: ['ProjectRevisions'],
        }),

        reviseProject: builder.mutation<void, ReviseProjectRequest>({
            query: (payload) => ({
                url: 'v1/projectRevisions/commit-revision',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result) =>
                result === null ? ['Projects', 'ProjectRevisions', 'ArchivedProjects', 'ArchivedProject'] : [],
        }),

        startMinorPatch: builder.mutation<void, MinorPatchProjectRequest>({
            query: (params) => ({
                url: 'v1/projectRevisions/minor-patch',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: (result) => (result === null ? ['Projects', 'ArchivedProjects'] : []),
        }),
    }),
})

export const {
    useGetProjectRevisionsQuery,
    useGetRevisionQuery,
    useReviseProjectMutation,
    useStartMinorPatchMutation,
} = projecRevisionService
