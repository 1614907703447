import React from 'react'
import { Button, Grid, IconButton, styled } from '@mui/material'
import { PrebuiltForm } from './PrebuiltForm'
import { PrebuiltFormNavigation } from './PrebuiltFormNavigation'
import { ProjectDetails } from './ProjectDetails'
import { ProjectReviewNotes } from './ProjectReviewNotes'
import { TopBar } from 'components/general/top-bar/top-bar'
import { ROUTES } from 'constants/routes'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { ArrowBackIos, TextSnippet } from '@mui/icons-material'
import { PageLoader } from 'components/general/loaders/PageLoader'
import {
    PROJECT_FINISH_BTN_VISIBLE,
    PROJECT_FORM_VISIBLE_IN_CREATE_MODE,
    PROJECT_NO_EDIT_ACTIONS,
} from 'constants/util'
import { useCreateProjectContext } from 'modules/projects/context/CreateProjectContext'
import { navigationLinks } from 'utils/navigation-utils'
import { PrebuiltFormGridContainer } from '../shared/PrebuiltFormGridContainer'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { useUpdateProjectStatusMutation } from 'services/projects.service'
import { PageContainer } from 'components/general/containers/PageContainer'
import { FloatingProjectCreateActions } from './FloatingProjectCreateActions'

export const ProjectJourney: React.FC = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const { t } = useTranslation()
    const { isLoading, project, normalizedStructure, projectId } = useCreateProjectContext()

    const [updateStatus, { isLoading: isUpdatingStatus }] = useUpdateProjectStatusMutation()

    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
    const isArchivedProject = project?.creationType === 'ByDocumentUpload'

    const canSendToReview =
        Boolean(
            normalizedStructure &&
                project?.formState?.completeSchemas &&
                normalizedStructure.result.length -
                    project.formState.disabledSchemas.length -
                    project.formState.unassignedSchemas.length ===
                    project.formState.completeSchemas.length,
        ) && !isArchivedProject
    const canFinish =
        project?.status && PROJECT_FINISH_BTN_VISIBLE.includes(project.status) && canSendToReview && !isArchivedProject

    const handleSendProjectForReview = async () => {
        if (!project) return

        try {
            await updateStatus({ id: project.id, status: 'WaitingForQa' }).unwrap()

            navigate(ROUTES.dashboard)
        } catch (e) {}
    }

    const handleFinishProject = async () => {
        if (!project) return
        try {
            await updateStatus({ id: project.id, status: 'Complete' }).unwrap()

            navigate(ROUTES.dashboard)
        } catch (e) {}
    }

    if (isLoading) {
        return <PageLoader />
    }

    if (project && PROJECT_NO_EDIT_ACTIONS.includes(project.status)) {
        return <Navigate to={navigationLinks.toReviewProject(project.id)} replace />
    }

    return (
        <>
            <TopBar
                leftActions={
                    <IconButton to={state?.from || ROUTES.dashboard} component={Link} sx={{ color: '#FFF' }}>
                        <ArrowBackIos />
                    </IconButton>
                }
                rightActions={[
                    <Button
                        to={navigationLinks.toPreviewInProgressProject(projectId)}
                        state={{ state: { from: ROUTES.projectCreate } }}
                        component={Link}
                        variant="contained"
                        color="secondary"
                    >
                        {t('projectPage.previewProject')}
                    </Button>,
                    ...(canSendToReview
                        ? [
                              <StyledLoadingButton
                                  type="button"
                                  disabled={isUpdatingStatus || !canSendToReview}
                                  variant="contained"
                                  loading={isUpdatingStatus}
                                  color="secondary"
                                  onClick={handleSendProjectForReview}
                              >
                                  {t('projectPage.sendToReview')}
                              </StyledLoadingButton>,
                          ]
                        : []),
                    ...(canFinish
                        ? [
                              <StyledLoadingButton
                                  type="button"
                                  disabled={isUpdatingStatus}
                                  variant="contained"
                                  loading={isUpdatingStatus}
                                  color="secondary"
                                  onClick={handleFinishProject}
                              >
                                  {t('projectPage.finishProject')}
                              </StyledLoadingButton>,
                          ]
                        : []),
                    ...(project?.status === 'WaitingForConfirmation'
                        ? [
                              <IconButton onClick={() => setIsDrawerOpen(true)} sx={{ color: '#FFF' }}>
                                  <TextSnippet />
                              </IconButton>,
                          ]
                        : []),
                ]}
            />
            <PageContainer>
                <PaddingContainer>
                    <FloatingProjectCreateActions project={project} />
                    <ProjectDetails isArchivedProject={isArchivedProject} />
                    {!isArchivedProject &&
                        project?.status &&
                        PROJECT_FORM_VISIBLE_IN_CREATE_MODE.includes(project.status) && (
                            <PrebuiltFormGridContainer>
                                <Grid item xs={4}>
                                    <PrebuiltFormNavigation />
                                </Grid>
                                <Grid item xs={8}>
                                    <PrebuiltForm />
                                </Grid>
                            </PrebuiltFormGridContainer>
                        )}
                </PaddingContainer>
            </PageContainer>
            <ProjectReviewNotes
                isDrawerOpen={isDrawerOpen}
                closeDrawer={() => setIsDrawerOpen(false)}
                project={project}
            />
        </>
    )
}

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
    '&:disabled': {
        borderColor: theme.palette.secondary.light,
        backgroundColor: theme.palette.secondary.main,
        opacity: 0.6,
    },
}))

const PaddingContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 5),
}))
