import React from 'react'
import { Button, Stack, styled, Box, IconButton, SortDirection, Typography } from '@mui/material'

import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { useTranslation } from 'react-i18next'
import { useForm, useFieldArray } from 'react-hook-form'
import { CreatePrebuiltOptionRequest } from 'redux/types/prebuiltOptions.type'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { Add, Delete } from '@mui/icons-material'
import { yupResolver } from '@hookform/resolvers/yup'
import { addPrebuiltOption } from 'schemas/addPrebuiltOption'
import {
    preBuiltOptionsApi,
    useCreatePrebuiltOptionMutation,
    useUpdatePrebuiltOptionMutation,
} from 'services/prebuiltOptions.service'
import { IQueryParams } from 'pages/settings/OptionsBuilder.page'
import { LoadingButton } from '@mui/lab'

const DEFAULT_VALUES: CreatePrebuiltOptionRequest = {
    name: '',
    options: [],
}

interface CreateOptionListDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions' | 'open'> {
    recordId: boolean | string
    query: IQueryParams
}

export const CreateOptionListDialog: React.FC<CreateOptionListDialogProps> = ({ recordId, handleClose, query }) => {
    const { t } = useTranslation()
    const [createList, { isLoading: isCreating }] = useCreatePrebuiltOptionMutation()
    const [updateList, { isLoading: isUpdating }] = useUpdatePrebuiltOptionMutation()
    const { list } = preBuiltOptionsApi.useGetPrebuiltOptionsQuery(
        {
            page: query.page,
            direction: query.sort as SortDirection,
            itemsPerPage: query.pageSize,
            sortBy: query.field,
        },
        {
            selectFromResult: ({ data }) => ({
                list: data?.data.find((prebuiltList) => prebuiltList.id === recordId),
            }),
            skip: !recordId,
        },
    )

    const isCreateNewDialog = typeof recordId === 'boolean'
    const isLoading = isCreating || isUpdating

    const { handleSubmit, control, reset } = useForm<CreatePrebuiltOptionRequest>({
        defaultValues: list ? list : DEFAULT_VALUES,
        resolver: yupResolver(addPrebuiltOption),
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'options',
    })

    React.useEffect(() => {
        if (!recordId) reset(DEFAULT_VALUES)
        if (list) reset(list)
    }, [reset, recordId, list])

    const onSubmit = async (values: CreatePrebuiltOptionRequest) => {
        const formattedOptions = values.options
            .filter((opt) => Boolean(opt.value))
            .map((opt) => ({ label: opt.value as string, value: opt.value }))

        if (isCreateNewDialog) {
            await createList({
                name: values.name,
                options: formattedOptions,
            })
        } else {
            await updateList({
                id: recordId,
                name: values.name,
                options: formattedOptions,
            })
        }
        handleClose()
    }

    const addNewChild = () => {
        append({
            label: '',
            value: '',
        })
    }

    const removeChild = (childIndex: number) => {
        remove(childIndex)
    }

    return (
        <ModalsWrapper
            open={Boolean(recordId)}
            title={
                isCreateNewDialog ? t('prebuiltListPage.createPrebuiltList') : t('prebuiltListPage.editPrebuiltList')
            }
            handleClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
            body={
                <form id="prebuilt-option-form" onSubmit={handleSubmit(onSubmit)}>
                    <ContentWrapper>
                        <Box my={2} pl={5}>
                            <Button variant="outlined" startIcon={<Add />} onClick={addNewChild}>
                                {t('prebuiltListPage.prebuiltListAddOption')}
                            </Button>
                        </Box>
                        <ControlledMuiTextField
                            control={control}
                            fullWidth
                            name="name"
                            variant="filled"
                            placeholder={t('prebuiltListPage.prebuiltListNamePlaceholder')}
                            sx={{ paddingLeft: 5 }}
                        />
                        <Typography variant="body1" sx={{ fontWeight: 600, paddingLeft: 5 }}>
                            {t('common.options')}:
                        </Typography>
                        {fields.map((field, index) => (
                            <Stack direction="row" key={field.id}>
                                <IconButton color="error" onClick={() => removeChild(index)}>
                                    <Delete />
                                </IconButton>
                                <ControlledMuiTextField
                                    name={`options.${index}.value`}
                                    control={control}
                                    placeholder={t('prebuiltListPage.prebuiltListOptionNamePlaceholder')}
                                    fullWidth
                                    variant="filled"
                                />
                            </Stack>
                        ))}
                    </ContentWrapper>
                </form>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button variant="outlined" onClick={handleClose} color="secondary" disabled={isLoading}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="prebuilt-option-form"
                        disabled={isLoading}
                        loading={isLoading}
                    >
                        {t('common.confirm')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
