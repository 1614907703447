import {
    AttachFile,
    CalendarToday,
    Contrast,
    DataObject,
    KeyboardArrowDown,
    List,
    Numbers,
    QuestionMark,
    TableRows,
    TextFields,
    ViewColumn,
    RadioButtonChecked,
    CheckBox,
    ShortText,
    TableView,
    FormatListNumbered,
    TableChart,
    LocalOffer,
} from '@mui/icons-material'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import LinkIcon from '@mui/icons-material/Link'
import React from 'react'
import { styled, Box } from '@mui/material'
import { FieldType, SchemaElement } from 'redux/types/schema.type'

const DEFAULT_BOX_COLOR = 'red'
const FIELD_BOX_ICON_COLORS = {
    goldenStandard: '#FFD700',
    simple: '#FFF',
}

const FIELD_BOX_COLORS: Partial<Record<FieldType, string>> = {
    string: 'rgb(55, 156, 251)',
    number: 'rgb(92, 214, 200)',
    boolean: 'rgb(224, 133, 179)',
    select: 'rgb(251, 161, 55)',
    date: 'rgb(151, 21, 55)',
    file: 'rgb(101, 121, 155)',
    HorizontalLayout: 'rgb(0, 0, 0)',
    VerticalLayout: 'rgb(0, 0, 0)',
    Group: 'rgb(0, 161, 55)',
    groupTable: 'rgb(0, 161, 55)',
    Label: 'rgb(150, 150, 150)',
    list: 'rgb(135, 188, 199)',
    dynamicTable: 'rgb(152, 107, 237)',
    disciplinesTag: 'rgb(201, 36, 174)',
    buildingPart: 'rgb(87, 255, 87)',
}

const FIELD_ICONS: Partial<Record<FieldType, React.ReactNode>> = {
    string: <TextFields />,
    number: <Numbers />,
    boolean: <Contrast />,
    select: <KeyboardArrowDown />,
    array: <List />,
    VerticalLayout: <TableRows />,
    HorizontalLayout: <ViewColumn />,
    Group: <DataObject />,
    date: <CalendarToday />,
    file: <AttachFile />,
    Label: <ShortText />,
    radio: <RadioButtonChecked />,
    checkbox: <CheckBox />,
    groupTable: <TableView />,
    list: <FormatListNumbered />,
    dynamicTable: <TableChart />,
    disciplinesTag: <LocalOffer />,
    buildingPart: <LocalOffer />,
}

type FieldIconProps = {
    nodeType: FieldType
    isGoldenStandard: boolean
}

const FieldIcon: React.FC<FieldIconProps> = ({ nodeType, isGoldenStandard }) => {
    const color = FIELD_BOX_COLORS[nodeType] ? FIELD_BOX_COLORS[nodeType] : DEFAULT_BOX_COLOR
    const iconColor = isGoldenStandard ? FIELD_BOX_ICON_COLORS.goldenStandard : FIELD_BOX_ICON_COLORS.simple

    return (
        <FieldIconContainer bgcolor={color} iconcolor={iconColor}>
            {FIELD_ICONS[nodeType] || <QuestionMark />}
        </FieldIconContainer>
    )
}

export const getFieldLabel = (node: SchemaElement, t: TFunction) => {
    switch (node.type) {
        case 'string':
            if (node.options?.multi) {
                return t('fields.textfield')
            }
            return t('fields.string')
        case 'number':
            return t('fields.number')
        case 'boolean':
            return t('fields.bool')
        case 'checkbox':
            return t('fields.checkbox')
        case 'select':
            return t('fields.select')
        case 'radio':
            return t('fields.radio')
        case 'array':
            return t('fields.array')
        case 'VerticalLayout':
            return t('fields.verticalLayout')
        case 'HorizontalLayout':
            return t('fields.horizontalLayout')
        case 'Group':
            return t('fields.group')
        case 'date':
            return t('fields.date')
        case 'file':
            return t('fields.file')
        case 'Label':
            return t('fields.label')
        case 'groupTable':
            return t('fields.uniqueGroupTable')
        case 'list':
            return t('fields.list')
        case 'dynamicTable':
            return t('fields.dynamicTable')
        case 'disciplinesTag':
            return t('fields.disciplinesTag')
        case 'buildingPart':
            return t('fields.buildingPart')
        default:
            return t('fields.unknown')
    }
}

type FieldItemProps = {
    node: SchemaElement
    nameValue: any
    hasRules?: boolean
    isGoldenStandard?: boolean
}

export const FieldItem: React.FC<FieldItemProps> = ({ node, nameValue, hasRules, isGoldenStandard }) => {
    const { t } = useTranslation()

    return (
        <FieldItemContainer>
            <Box sx={{ position: 'relative' }}>
                <FieldIcon isGoldenStandard={Boolean(isGoldenStandard)} nodeType={node.type} />
                {hasRules && (
                    <RulesIconContainer>
                        <LinkIcon fontSize={'small'} />
                    </RulesIconContainer>
                )}
            </Box>

            <AdditionalInfoContainer>
                <Label>{getFieldLabel(node, t)}</Label>
                {nameValue ? <div>{nameValue}</div> : 'name' in node ? <Error>{t('required')}</Error> : null}
            </AdditionalInfoContainer>
        </FieldItemContainer>
    )
}

const FieldIconContainer = styled(Box)<{ iconcolor: string }>(({ theme, bgcolor, iconcolor }) => ({
    padding: theme.spacing(1.5),
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColo: bgcolor,
    '& svg': {
        fill: iconcolor,
    },
}))

const FieldItemContainer = styled('div')({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    maxWidth: '19rem',
})

const RulesIconContainer = styled('div')({
    position: 'absolute',
    width: '26px',
    height: '26px',
    top: -10,
    right: -10,
    background: '#dedede',
    borderRadius: 13,
    padding: '3px',
})

const AdditionalInfoContainer = styled('div')(({ theme }) => ({
    margin: theme.spacing(0, 2),
}))

const Label = styled('div')({
    fontSize: '0.875rem',
    color: '#333333', // TODO: if we have uses in multiple places put to theme
})

const Error = styled('div')({
    color: '#ff0000', // TODO: if we have uses in multiple places put to theme
})
