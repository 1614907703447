import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './app-base-query'

export const appApi = createApi({
    tagTypes: [
        'Projects',
        'ArchivedProjects',
        'ArchivedProject',
        'Tenants',
        'TenantUsers',
        'Customers',
        'Buildings',
        'Projects',
        'Users',
        'Schemas',
        'FormStructures',
        'PrebuiltOptions',
        'Templates',
        'TenantSettings',
        'PublicSettings',
        'ProjectRevisions',
        'Organizations',
    ],
    reducerPath: 'appApi',
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
})
