import React from 'react'
import { Typography, styled } from '@mui/material'
import { JSONFormViewComp, SchemaElementTypeMap } from 'redux/types/schemaView.type'

export const CheckboxView: React.FC<JSONFormViewComp<SchemaElementTypeMap['checkbox']>> = React.memo(({ node }) => {
    if (!node.isVisible || !node.isVisibleInWeb || node.value === undefined || node.value.length === 0) return null

    return (
        <ItemContainer direction="row">
            {node.label && node.isLabelVisible && <Label variant="body1">{node.label}</Label>}

            <Container>
                {node.value.map((v: string, idx, self) => (
                    <Typography variant="body2" component="span" key={v}>
                        {`${v}${idx !== self.length - 1 ? ', ' : ''}`}
                    </Typography>
                ))}
            </Container>
        </ItemContainer>
    )
})

const Label = styled(Typography)({
    fontWeight: 600,
    display: 'block',
    width: '30%',
    maxWidth: 200,
    minWidth: 150,
})

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    marginLeft: theme.spacing(4),
    gap: theme.spacing(1),
}))

const ItemContainer = styled('div')<{ direction: 'row' | 'column' }>(({ direction }) => ({
    display: 'flex',
    flexDirection: direction,
    width: '100%',
    maxWidth: 500,
    alignItems: 'center',
}))
