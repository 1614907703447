import React from 'react'
import { IconButton, styled } from '@mui/material'
import { QrCode } from '@mui/icons-material'
import { Project } from 'redux/types/project.type'
import QrCodeDialog from './QRCodeDialog'

type FloatingProjectCreateActionsProps = {
    project?: Project
}

export const FloatingProjectCreateActions: React.FC<FloatingProjectCreateActionsProps> = ({ project }) => {
    const [isQRCodeDialogOpen, setIsQRCodeDialogOpen] = React.useState(false)

    if (!project) return null

    const isQRCodeBtnVisible = project.qrCode !== null

    return (
        <>
            <Container>
                <div aria-disabled={Boolean(project)}>
                    <IconButton
                        disabled={!isQRCodeBtnVisible}
                        sx={{ display: isQRCodeBtnVisible ? 'inline-flex' : 'none' }}
                        onClick={() => setIsQRCodeDialogOpen(true)}
                        color="primary"
                    >
                        <QrCode />
                    </IconButton>
                </div>
            </Container>
            <QrCodeDialog
                open={isQRCodeDialogOpen}
                project={project!}
                handleClose={() => setIsQRCodeDialogOpen(false)}
            />
        </>
    )
}

const Container = styled('div')(({ theme }) => ({
    position: 'fixed',
    right: 0,
    bottom: '50%',
    transform: 'translateY(-50%)',
}))
