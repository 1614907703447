import * as O from 'optics-ts'
import { SchemaElement } from 'redux/types/schema.type'

const isInteger = (x: string): boolean => {
    return Number.isInteger(+x)
}

export const partsToLens = (parts: string[]) => {
    const lens = parts.reduce((lens: any, x: string) => {
        if (isInteger(x)) {
            return lens.at(parseInt(x, 10))
        }
        return lens.prop(x)
    }, O.optic<SchemaElement>())
    return lens
}

export const splitPath = (allPath: string) => {
    const pathParts = allPath.substring(1).split('.')
    return createLens(pathParts)
}

export const createLens = (pathParts: string[]) => {
    const index = parseInt(pathParts.pop()!, 10)
    const lens = partsToLens(pathParts)
    return { lens, index }
}

export const getAdjustedPath = (path1: string, path2: string): string[] => {
    const arr1 = path1.substring(1).split('.')
    const arr2 = path2.substring(1).split('.')
    const count = Math.min(arr1.length, arr2.length)

    for (let i = 0; i < count; i++) {
        const el1 = arr1[i]
        const el2 = arr2[i]

        if (isInteger(el1) && isInteger(el2)) {
            const index1 = parseInt(el1, 10)
            const index2 = parseInt(el2, 10)
            if (index1 < index2) {
                arr2[i] = index2.toString()
                return arr2
            } else if (index1 > index2) {
                return arr2
            }
        } else {
            if (el1 !== el2) {
                return arr2
            }
        }
    }

    return arr2
}
