import { Theme } from '@mui/material'

export default function AppThemedComponents(theme: Theme) {
    return {
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        border: 'none',
                        color: '#000',
                        '& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
                            display: 'none',
                        },
                        '& .MuiDataGrid-columnHeader': {
                            textTransform: 'uppercase',
                            outline: 'none !important',
                            backgroundColor: '#FFF',
                        },
                        '&.MuiDataGrid-root .MuiDataGrid-cell': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnHeader--moving': {
                            backgroundColor: '#FFF !important',
                        },
                    },
                },
            },
            MuiPopover: {
                styleOverrides: {
                    root: {
                        zIndex: 10000,
                    },
                },
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        minWidth: 32,
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        background: theme.general.chip,
                        color: '#FFF',
                        textAlign: 'center',
                        borderRadius: '4px',
                        fontSize: '0.875rem',

                        '& .MuiChip-deleteIcon': {
                            color: '#FFF',
                            '&:hover': {
                                color: 'rgba(255, 255, 255, 0.86)',
                            },
                        },
                        '& .MuiChip-labelMedium': {
                            paddingLeft: '10px',
                            paddingRight: '10px',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 4,
                    },
                    textSecondary: {
                        color: '#FFF',
                    },
                    containedSecondary: {
                        color: '#FFF',
                    },
                    // outlinedSecondary:
                    // comment out for now because it ruins all old page outlined btns
                    // outlinedPrimary: {
                    //     color: theme.general.gray,
                    //     borderColor: theme.general.gray,
                    //     '&:hover': {
                    //         color: theme.general.gray,
                    //         borderColor: theme.general.gray,
                    //     },
                    // },
                },
            },
            MuiTextField: {
                defaultProps: {
                    variant: 'filled',
                    size: 'small',
                },
                styleOverrides: {
                    root: {},
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        background: 'none',
                        '&:before': {
                            borderBottom: '1px solid #000',
                        },
                    },
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: 'filled',
                    size: 'small',
                },
            },
            MuiRadio: {
                styleOverrides: {
                    root: {
                        padding: '0 9px',
                    },
                },
                defaultProps: {
                    color: 'secondary',
                },
            },
        },
    }
}
