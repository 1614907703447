import { AddBuildingPage } from 'pages/buildings/AddBuilding.page'
import { BuildingPage } from 'pages/buildings/building.page'
import { BuildingsPage } from 'pages/buildings/buildings.page'
import { EditBuildingPage } from 'pages/buildings/EditBuilding.page'
import { ROUTES } from 'constants/routes'
import { generateNestedRouteUrl } from 'utils/navigation-utils'

const buildingRoutes = [
    {
        path: '',
        element: <BuildingsPage />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.building, ROUTES.buildings),
        element: <BuildingPage />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.buildingEdit, ROUTES.buildings),
        element: <EditBuildingPage />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.buildingAdd, ROUTES.buildings),
        element: <AddBuildingPage />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.buildingsAddCustomer, ROUTES.buildings),
        element: <AddBuildingPage />,
    },
]

export default buildingRoutes
