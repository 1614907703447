import React from 'react'
import { useTranslation } from 'react-i18next'
import { ViewSectionProps } from './GeneralSection'
import { Typography } from '@mui/material'
import { valueOrDefaultEmpty } from 'utils/general'
import { SectionBlock } from 'components/buildings/SectionBlock'
import { FormItem } from 'components/buildings/FormItem'

export const ServiceSection: React.FC<ViewSectionProps> = ({ building }) => {
    const { t } = useTranslation()
    const { fireDepartment, fireStation, distanceToStation, comment, isFireDepartmentRegistred } = building

    return (
        <SectionBlock density="dense">
            <FormItem label={t('createEditBuildingPage.isFireDepartmentRegistered')}>
                {isFireDepartmentRegistred ? t('common.yes') : t('common.no')}
            </FormItem>
            <FormItem label={t('common.fireDepartment')}>{valueOrDefaultEmpty(fireDepartment?.name)}</FormItem>

            <FormItem label={t('common.fireStation')}>{valueOrDefaultEmpty(fireStation?.name)}</FormItem>

            <FormItem label={t('createEditBuildingPage.fireStationDistance')}>
                <Typography variant="body1" component="span" sx={{ mr: 4 }}>
                    {valueOrDefaultEmpty(distanceToStation)}
                </Typography>
                <span>km</span>
            </FormItem>

            <FormItem label={t('common.comments')} sxTitleContainer={{ alignSelf: 'flex-start' }}>
                {valueOrDefaultEmpty(comment)}
            </FormItem>
        </SectionBlock>
    )
}
