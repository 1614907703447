import { RootState } from 'redux/store'
import { appApi } from '../redux/api'
import { PaginatedResults } from '../components/general/models/paginated-results'
import {
    Building,
    BuildingsPaginationParams,
    CreateBuildingRequest,
    UpdateBuildingRequest,
} from '../redux/types/building.type'

const buildingsApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getBuildings: builder.query<PaginatedResults<Building>, BuildingsPaginationParams>({
            query: (payload) => ({
                url: `/v1/buildings`,
                method: 'GET',
                params: payload,
            }),
            providesTags: ['Buildings'],
        }),
        getCustomerBuildings: builder.query<PaginatedResults<Building>, BuildingsPaginationParams>({
            query: (payload) => ({
                url: '/v1/customerBuildings',
                method: 'GET',
                params: payload,
            }),
            providesTags: ['Buildings'],
        }),
        getBuilding: builder.query<Building, string>({
            query: (payload) => ({
                url: `/v1/buildings/${payload}`,
                method: 'GET',
            }),
            providesTags: ['Buildings'],
        }),
        createBuilding: builder.mutation<Building, CreateBuildingRequest>({
            query: (payload) => ({
                url: '/v1/buildings',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result) => (result ? ['Buildings'] : []),
        }),
        updateBuilding: builder.mutation<Building, UpdateBuildingRequest>({
            query: (payload) => ({
                url: `/v1/buildings/${payload.id}`,
                method: 'PUT',
                body: {
                    ...payload.building,
                },
            }),
            invalidatesTags: (result) => (result ? ['Buildings'] : []),
        }),
    }),
})

export const {
    useGetBuildingsQuery,
    useLazyGetBuildingsQuery,
    useCreateBuildingMutation,
    useGetBuildingQuery,
    useUpdateBuildingMutation,
    useGetCustomerBuildingsQuery,
} = buildingsApi

export const selectBuildings = (state: RootState, paginationParams: BuildingsPaginationParams) =>
    buildingsApi.endpoints.getBuildings.select(paginationParams)(state)
