import * as O from 'optics-ts'
import React from 'react'
import { RulesEditor } from '../rules/rules-editor'
import { useName, useRule } from '../hooks/schema-hooks'
import { BuilderFieldProps } from './builder-field-props'
import { Draggable } from './droppable'
import { FieldDialog } from './field-dialog'
import { FieldItem } from './field-item'
import { NameLabel } from './general/field-name-label'
import { TabPanel } from './general/field-tab-panel'
import { FieldTabs } from './general/field-tabs'
import { BuildingPartSchemaElement, DisciplinesTagSchemaElement } from 'redux/types/schema.type'

export const BuilderTagSelect: React.FC<BuilderFieldProps<DisciplinesTagSchemaElement | BuildingPartSchemaElement>> = ({
    node,
    state,
    setState,
    lens,
    path,
}) => {
    const nameHook = useName(lens, state, setState)
    const rule = useRule(lens, state, setState)

    const remove = () => setState(O.remove(lens)(state))
    const [tabIndex, setTabIndex] = React.useState(0)

    return (
        <Draggable id={`${path}`} styling={{ alignItems: 'center' }}>
            <FieldItem
                node={node}
                nameValue={nameHook.name}
                hasRules={rule.hasRules}
                isGoldenStandard={nameHook.isGoldenStard}
            />
            <FieldDialog node={node} remove={remove} onSave={rule.onSaveRules}>
                <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                <TabPanel value={tabIndex} index={0}>
                    <NameLabel nameHook={nameHook} fieldType={node.type} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                </TabPanel>
            </FieldDialog>
        </Draggable>
    )
}
