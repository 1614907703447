import * as yup from 'yup'

const requiredString = yup.string().required('required')
const validDate = yup.date().required('required').typeError('invalidDate')

const goldenStandard = yup.object().shape({
    name: yup.string().required('required'),
    booleanValue: yup
        .bool()
        .optional()
        .nullable()
        .test('booleanValue', 'required', (item, testContext) => {
            const { booleanValue, stringValue, numberValue } = testContext.parent
            return booleanValue !== undefined || stringValue !== undefined || numberValue !== undefined
        }),
    stringValue: yup
        .string()
        .optional()
        .nullable()
        .test('stringValue', 'required', (item, testContext) => {
            const { booleanValue, stringValue, numberValue } = testContext.parent
            return booleanValue !== undefined || stringValue !== undefined || numberValue !== undefined
        }),
    numberValue: yup
        .number()
        .optional()
        .nullable()
        .test('numberValue', 'required', (item, testContext) => {
            const { booleanValue, stringValue, numberValue } = testContext.parent
            return booleanValue !== undefined || stringValue !== undefined || numberValue !== undefined
        }),
})

export const projectGeneralInfoDraftSchema = yup.object({
    name: requiredString,
    projectManagerId: requiredString,
    type: requiredString,
})

export const projectGeneralInfoFinishSchemaByUpload = yup.object({
    name: requiredString,
    type: requiredString,
    responsableCompany: requiredString,
    projectManagerId: requiredString,
    qualityAssuranceId: requiredString,

    publishDate: validDate,
    files: yup.array().min(1, 'required'),
    goldenStandards: yup.array().of(goldenStandard),
})

export const projectGeneralInfoFinishSchemaByDefault = yup.object({
    name: requiredString,
    type: requiredString,
    responsableCompany: requiredString,
    projectManagerId: requiredString,
    qualityAssuranceId: requiredString,

    structureId: requiredString,
    templateId: requiredString,
    files: yup.array(),
})
