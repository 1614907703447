import React from 'react'
import { useTranslation } from 'react-i18next'

import { CreateOrUpdateBuildingForm } from 'redux/types/building.type'
import { useFormContext, useWatch } from 'react-hook-form'
import { useAppSelector } from 'redux/hooks'
import { selectMergedWithCustomInstallationOptions } from 'redux/selectors/settings.selectors'
import { SectionBlock } from 'components/buildings/SectionBlock'
import { FormItem } from 'components/buildings/FormItem'
import { ControlledAddableTagInput } from 'components/inputs/custom/ControlledAddableTagInput'
import { CustomInstallationDialog } from './CustomInstallationDialog'

export const FireInstallationForm: React.FC = () => {
    const { t } = useTranslation()
    const { control } = useFormContext<CreateOrUpdateBuildingForm>()

    const customSafetyInstallationTags = useWatch({ control, name: 'customSafetyInstallations' })
    const installationOptions = useAppSelector(selectMergedWithCustomInstallationOptions)(
        customSafetyInstallationTags || [],
    )

    return (
        <SectionBlock>
            <FormItem label={t('createEditBuildingPage.fireSafetyInstallation')}>
                <ControlledAddableTagInput
                    options={installationOptions}
                    control={control}
                    name="safetyInstallations"
                    searchable
                    renderDialog={(open, onClose, controlFromRender) => (
                        <CustomInstallationDialog isOpen={open} control={controlFromRender} handleClose={onClose} />
                    )}
                />
            </FormItem>
        </SectionBlock>
    )
}
