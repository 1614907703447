import React from 'react'
import { Chip, ChipProps, Theme, Tooltip } from '@mui/material'
import { IOption } from 'components/inputs/ControlledMuiSelect'

const getStyling = (theme: Theme, value?: IOption) => {
    if (value === undefined) {
        return {
            width: 'fit-content',
        }
    }

    return {
        width: 'fit-content',
        '&:hover': { background: theme.palette.primary.light, cursor: 'pointer' },
    }
}

type Props =
    | {
          value?: undefined
          onClick?: never
          tagDescription?: string
      }
    | { value: IOption; onClick: (value: IOption) => void; tagDescription?: string }

type TagChipProps = Props & Omit<ChipProps, 'onClick'>

export const TagChip: React.FC<TagChipProps> = React.forwardRef(
    ({ value, sx, onClick, tagDescription, ...rest }, ref) => {
        if (tagDescription) {
            return (
                <Tooltip title={tagDescription}>
                    <Chip
                        ref={ref}
                        {...rest}
                        variant="filled"
                        sx={(theme) => getStyling(theme, value)}
                        onClick={value && onClick ? () => onClick(value) : undefined}
                    />
                </Tooltip>
            )
        }

        return (
            <Chip
                ref={ref}
                {...rest}
                variant="filled"
                sx={(theme) => getStyling(theme, value)}
                onClick={value && onClick ? () => onClick(value) : undefined}
            />
        )
    },
)
