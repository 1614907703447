import React from 'react'
import { Box, FormControl, InputAdornment, Paper, SortDirection, TextField, styled } from '@mui/material'
import { DataGrid, GridColDef, GridSortDirection } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { useDeleteCustomerMutation, useGetCustomersQuery } from '../../../services/customers.service'
import { Customer } from '../../../redux/types/customer.type'
import { useDataGridQueryFiltersState } from 'utils/hooks/useDataGridQueryFiltersState'
import useDebounce from 'utils/hooks/useDebounce'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { formatOrgNumber } from 'utils/string-utils'
import { DataGridActionsCell } from 'components/general/table/DataGridActionsCell'
import { Search } from '@mui/icons-material'
import { DataGridNoRows } from 'components/general/table/DataGridNoRows'
import { CustomersActionsMenu } from './CustomersActionsMenu'
import DeleteDialog from 'components/general/dialogs/DeleteDialog'
import { navigationLinks } from 'utils/navigation-utils'
import { DataGridLinkCell } from 'components/general/table/DataGridLinkCell'
import { TableActionMenuProps } from 'types/util'

export const CustomersTable: React.FC = React.memo(() => {
    const { t } = useTranslation()

    const {
        handlePageChange,
        handleSortChange,
        handleSearchChange,
        search: { field, page, sort, search },
    } = useDataGridQueryFiltersState({ sort: 'desc', field: 'updated' })

    const { data: customers, isFetching } = useGetCustomersQuery({
        page: page || 0,
        itemsPerPage: DEFAULT_ROWS_PER_PAGE,
        direction: sort as SortDirection,
        sortBy: field ?? undefined,
        searchQuery: search || undefined,
    })
    const [deleteCustomer, { isLoading: isDeleting }] = useDeleteCustomerMutation()

    const searchCustomers = React.useCallback(
        async (search: string) => {
            if (search.length === 0) {
                handleSearchChange('')
                handlePageChange(0)
                return
            }
            if (search.length <= 2) return

            handlePageChange(0)
            handleSearchChange(search)
        },
        [handlePageChange, handleSearchChange],
    )

    const { search: inputSearchValue, onSearch } = useDebounce(searchCustomers, 500, search || '')

    const [anchorElProps, setAnchorPropsEl] = React.useState<TableActionMenuProps>(null)
    const [customerToDelete, setCustomerToDelete] = React.useState<Customer | null>(null)

    const openActionMenu = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
        setAnchorPropsEl(anchorElProps ? null : { anchorEl: event.currentTarget, rowId })
    }

    const closeActionMenu = () => setAnchorPropsEl(null)

    const handleDeleteCustomerAction = (customerId: string) => {
        const customer = customers?.data.find((customer) => customer.id === customerId)

        if (!customer) throw new Error('no customer found')

        setCustomerToDelete(customer)
        closeActionMenu()
    }

    const handleDelete = async () => {
        await deleteCustomer({ id: customerToDelete!.id })
        setCustomerToDelete(null)
    }

    const columns: GridColDef<Customer>[] = [
        {
            field: 'name',
            headerName: t('customersPage.customersTable.name'),
            flex: 1,
            editable: false,
            renderCell: (params) => (
                <DataGridLinkCell label={params.row.name} to={navigationLinks.toCustomer(params.row.id)} />
            ),
        },
        {
            field: 'organizationNumber',
            headerName: t('customersPage.customersTable.organizationNumber'),
            flex: 1,
            editable: false,
            valueGetter: (params) =>
                params.row.organizationNumber ? formatOrgNumber(params.row.organizationNumber) : '-',
        },
        {
            field: 'contacts',
            headerName: t('customersPage.customersTable.contact'),
            flex: 1,
            editable: false,
            sortable: false,
            maxWidth: 200,
            valueGetter: (params) => (params.row.contacts ? params.row.contacts.map((c) => c.name).join(', ') : '-'),
        },
        {
            field: 'phoneNumber',
            headerName: t('customersPage.customersTable.phone'),
            flex: 1,
            editable: false,
        },
        {
            field: '',
            maxWidth: 50,
            filterable: false,
            sortable: false,
            editable: false,
            type: 'action',
            renderCell: (params) => <DataGridActionsCell onOpen={openActionMenu} rowId={params.row.id} />,
        },
        {
            field: 'updated',
            hideable: true,
        },
    ]

    return (
        <>
            <Paper elevation={0} sx={{ padding: 2.5, height: '100%' }}>
                <FilterContainer>
                    <FormControl fullWidth>
                        <TextField
                            sx={{ mt: 1.5 }}
                            type="text"
                            name="query"
                            value={inputSearchValue}
                            onChange={onSearch}
                            size="small"
                            placeholder={t('common.search')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </FilterContainer>
                <Box>
                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                textTransform: 'capitalize',
                            },
                            '& .MuiDataGrid-row': { cursor: 'pointer' },
                        }}
                        autoHeight
                        rows={customers?.data || []}
                        columns={columns}
                        pageSize={DEFAULT_ROWS_PER_PAGE}
                        rowCount={customers?.total || 0}
                        isRowSelectable={() => false}
                        disableColumnMenu
                        loading={isFetching}
                        hideFooterSelectedRowCount
                        rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                        components={{
                            NoRowsOverlay: DataGridNoRows,
                        }}
                        paginationMode="server"
                        sortingMode="server"
                        onPageChange={handlePageChange}
                        onSortModelChange={handleSortChange}
                        page={page || 0}
                        sortModel={field && sort ? [{ field, sort: sort as GridSortDirection }] : []}
                        // hide updated column
                        // we need to add updated column
                        // so that we can initially sort by updated
                        columnVisibilityModel={{
                            updated: false,
                        }}
                    />
                </Box>
            </Paper>

            <CustomersActionsMenu
                anchorEl={anchorElProps?.anchorEl}
                id={anchorElProps?.rowId}
                onClose={closeActionMenu}
                onDeleteCustomer={handleDeleteCustomerAction}
            />
            <DeleteDialog
                open={Boolean(customerToDelete)}
                handleClose={() => setCustomerToDelete(null)}
                deleteAction={handleDelete}
                isDeleting={isDeleting}
            />
        </>
    )
})

const FilterContainer = styled('div')(({ theme }) => ({
    maxWidth: 300,
    marginBottom: theme.spacing(4),
}))
