import React from 'react'
import { ROUTES } from 'constants/routes'
import { Navigate } from 'react-router-dom'
import { TopBar } from 'components/general/top-bar/top-bar'
import { PageLoader } from 'components/general/loaders/PageLoader'
import { useTranslation } from 'react-i18next'
import { AddProjectReviewDialog } from 'modules/projects/view/AddProjectReviewDialog'
import { HeaderButton } from 'components/general/buttons/HeaderButton'
import { PROJECT_NO_EDIT_ACTIONS } from 'constants/util'
import { navigationLinks } from 'utils/navigation-utils'
import { HeaderBackButton } from 'components/general/navigation/HeaderBackButton'
import { ProjectReadView } from 'modules/projects/view/ReadView'
import { useProjectView } from 'modules/projects/hooks/useProjectView'
import { useAppSelector } from 'redux/hooks'
import { selectAuthToken, selectCurrentUserId } from 'redux/selectors/auth.selectors'
import { toast } from 'react-toastify'
import { ReadViewArchived } from 'modules/projects/view/ReadViewARchived'
import { Helmet } from 'react-helmet'
import { PageContainer } from 'components/general/containers/PageContainer'
import { ProjectMobileActions } from 'components/projects/ProjectMobileActions'
import { styled } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { FileService } from 'services/file.service'
import { PdfViewDialog } from 'components/general/dialogs/PdfViewDialog'

export const ProjectReview: React.FC = () => {
    const { t } = useTranslation()
    const { isLoading, project } = useProjectView()
    const token = useAppSelector(selectAuthToken)
    const userId = useAppSelector(selectCurrentUserId)

    const [isAddReviewDialogOpen, setIsAddReviewDialogOpen] = React.useState(false)
    const [isFormNavigationDrawerOpen, setIsFormNavigationDrawerOpen] = React.useState(false)
    const [preview, setPreview] = React.useState<string | null>(null)

    const handleToggleDrawer = (open: boolean) => {
        setIsFormNavigationDrawerOpen(open)
    }

    const handlePreviewProjectPdf = async () => {
        if (!token) throw new Error('Not authenticated')
        if (!project?.id) throw new Error('Expected to recieve project id')
        try {
            const url = await FileService.DownloadProjectPreviewPdf(project?.id, token)
            setPreview(url)
        } catch (err: any) {
            toast.error(err?.translationKey ? t(err.translationKey) : t('genericErrorMessage'))
        }
    }

    if (isLoading || !project) {
        return <PageLoader />
    }

    if (project && project.qualityAssurance?.id !== userId) {
        toast.error(t('projectPage.onlyReviewerCanAccess'))
        return <Navigate to={ROUTES.dashboard} />
    }

    if (!PROJECT_NO_EDIT_ACTIONS.includes(project.status)) {
        return <Navigate to={navigationLinks.toCreateProject(project.id)} />
    }

    return (
        <>
            <Helmet>
                {project && <title>{`${project.type?.label} - ${project.buildings[0].address.addressText}`}</title>}
            </Helmet>
            <TopBar
                preLogoActions={<ProjectMobileActions onOpenNavigationDrawer={() => handleToggleDrawer(true)} />}
                leftActions={<HeaderBackButton label={project?.name} href={ROUTES.dashboard} />}
                rightActions={[
                    <HeaderButton label={t('projectPage.review')} onClick={() => setIsAddReviewDialogOpen(true)} />,
                    <StyledLoadingButton
                        type="button"
                        variant="contained"
                        loading={false}
                        color="secondary"
                        onClick={handlePreviewProjectPdf}
                    >
                        {t('projectPage.viewDraftPdf')}
                    </StyledLoadingButton>,
                ]}
            />
            <PageContainer>
                {project?.creationType === 'ByDefaultFlow' ? (
                    <ProjectReadView
                        onDrawerToggle={handleToggleDrawer}
                        isNavigationDrawerOpen={isFormNavigationDrawerOpen}
                        isNotificationHidden={true}
                    />
                ) : (
                    <ReadViewArchived />
                )}
            </PageContainer>
            <AddProjectReviewDialog
                project={project}
                open={isAddReviewDialogOpen}
                handleClose={() => setIsAddReviewDialogOpen(false)}
            />
            <PdfViewDialog open={preview !== null} handleClose={() => setPreview(null)} url={preview} />
        </>
    )
}

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
    '&:disabled': {
        borderColor: theme.palette.secondary.light,
        backgroundColor: theme.palette.secondary.main,
        opacity: 0.6,
    },
}))
