import { RuleEffect } from '@jsonforms/core'
import { SchemaElement } from './schema.type'

export type RuleField = {
    name: string
    path: string
    node: SchemaElement
}

export const ConditionTypes = {
    Const: 'const',
    NotConst: 'notConst',
    Pattern: 'pattern',
    Enum: 'enum',
    Contains: 'contains',
    NotContains: 'notContains',
} as const

export type ConditionType = (typeof ConditionTypes)[keyof typeof ConditionTypes]

export type Condition = {
    field: RuleField
    value: string | boolean | number | string[] | undefined
    type: ConditionType
}

export const FieldRuleTypes = {
    And: 'AND',
    Or: 'OR',
} as const

export type FieldRuleType = (typeof FieldRuleTypes)[keyof typeof FieldRuleTypes]

export type FieldRule = {
    effect: RuleEffect
    type: FieldRuleType
    conditions: Condition[]
}
