import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { DataGridActionsCell } from 'components/general/table/DataGridActionsCell'
import { useTranslation } from 'react-i18next'
import { Contact } from 'redux/types/customer.type'
import { TableActionMenuProps } from 'types/util'
import { ContactActionMenu } from 'modules/shared/contacts/ContactActionMenu'
import { valueOrDefaultEmpty } from 'utils/general'

type ContactTableProps = {
    contacts: Contact[]
    actions: {
        handleDelete: (mail: string) => void
        handleEdit: (mail: string) => void
    }
}

export const ContactTable: React.FC<ContactTableProps> = ({ contacts, actions: { handleDelete, handleEdit } }) => {
    const { t } = useTranslation()
    const [anchorElProps, setAnchorPropsEl] = React.useState<TableActionMenuProps>(null)

    const openActionMenu = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
        setAnchorPropsEl(anchorElProps ? null : { anchorEl: event.currentTarget, rowId })
    }

    const closeActionMenu = () => setAnchorPropsEl(null)

    const handleDeleteContactAction = (mail: string) => {
        handleDelete(mail)
        closeActionMenu()
    }

    const handleEditContactAction = (mail: string) => {
        handleEdit(mail)
        closeActionMenu()
    }

    return (
        <>
            <TableContainer>
                <Table padding="none">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('common.name')}</TableCell>
                            <TableCell>{t('common.email')}</TableCell>
                            <TableCell>{t('common.phoneNumber')}</TableCell>
                            <TableCell sx={{ width: 50 }} />
                        </TableRow>
                    </TableHead>

                    <TableBody sx={{ '& tr:last-child > td': { borderBottom: 'none' } }}>
                        {contacts.map((contact) => (
                            <TableRow key={contact.email}>
                                <TableCell>{contact.name}</TableCell>
                                <TableCell>{contact.email}</TableCell>
                                <TableCell>{valueOrDefaultEmpty(contact.phoneNumber)}</TableCell>
                                <TableCell>
                                    <DataGridActionsCell onOpen={openActionMenu} rowId={contact.email} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ContactActionMenu
                anchorEl={anchorElProps?.anchorEl}
                id={anchorElProps?.rowId}
                onClose={closeActionMenu}
                onDeleteContactAction={handleDeleteContactAction}
                onEditContactAction={handleEditContactAction}
            />
        </>
    )
}
