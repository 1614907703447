import { styled } from '@mui/material'
import { Link } from 'react-router-dom'

export const StyledAnchor = styled('a')(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.secondary.main,

    '&:hover': {
        textDecoration: 'underline',
    },
}))

export const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.secondary.main,

    '&:hover': {
        textDecoration: 'underline',
    },
}))
