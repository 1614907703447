import {
    ComposableCondition,
    ControlElement,
    GroupLayout,
    HorizontalLayout,
    LabelElement,
    Rule,
    SchemaBasedCondition,
    UISchemaElement,
    VerticalLayout,
} from '@jsonforms/core'
import { last } from 'lodash'
import { SchemaElement } from 'redux/types/schema.type'
import { ConditionTypes, Condition as AppCondition, FieldRule } from 'redux/types/schemaRule.type'

type Nested = {
    [key: string]: Nested | any
}

function appConditionToSchemaCondition(condition: AppCondition): SchemaBasedCondition {
    // this means it was already converted and we just return old condition
    if (!condition.field.path) {
        return condition as unknown as SchemaBasedCondition
    }
    const keys = condition.field.path.substring(1).split('.')
    const result: Nested = {}
    const nodes: Nested[] = [result]

    let currentNode: Nested = result
    for (let i = 0; i < keys.length; i++) {
        const fieldName = keys[i]
        currentNode[fieldName] = {}
        currentNode = currentNode[fieldName]
        nodes.push(currentNode)
    }

    nodes[nodes.length - 3]['required'] = [last(keys)]

    if (condition.type === ConditionTypes.NotConst) {
        currentNode.not = { [ConditionTypes.Const]: condition.value }
    } else if (condition.type === ConditionTypes.NotContains) {
        currentNode.not = { [ConditionTypes.Contains]: { enum: condition.value } }
    } else if (condition.type === ConditionTypes.Contains) {
        currentNode[condition.type] = { enum: condition.value }
    } else {
        currentNode[condition.type] = condition.value
    }

    return { scope: '#', schema: result }
}

const convertRule = (rule?: FieldRule): Rule | undefined => {
    if (rule === undefined) {
        return undefined
    }

    const conditions = rule.conditions?.map((condition) => appConditionToSchemaCondition(condition))

    const result = {
        effect: rule.effect,
        condition: {
            type: rule.type,
            conditions: [...conditions],
        } as ComposableCondition,
    }

    return result
}

export const convertUiSchema = (
    schema: SchemaElement,
    path: string = '#',
): UISchemaElement | VerticalLayout | HorizontalLayout | GroupLayout | ControlElement | LabelElement => {
    if (schema.type === 'root') {
        return {
            type: 'Group',
            label: schema.label,
            elements: schema.nodes.map((node) => {
                return convertUiSchema(node, path)
            }),
        }
    }

    if (schema.type === 'VerticalLayout') {
        return {
            type: 'VerticalLayout',
            elements: schema.nodes.map((node) => {
                return convertUiSchema(node, path)
            }),
            rule: convertRule(schema.rule),
        }
    }

    if (schema.type === 'HorizontalLayout') {
        return {
            type: 'HorizontalLayout',
            elements: schema.nodes.map((node) => {
                return convertUiSchema(node, path)
            }),
            rule: convertRule(schema.rule),
            options: schema.options,
        }
    }

    if (schema.type === 'Label') {
        return {
            type: 'Label',
            text: schema.text,
            rule: convertRule(schema.rule),
        }
    }

    if (schema.type === 'Group') {
        return {
            type: 'Group',
            label: schema.label,
            elements: schema.nodes.map((node) => {
                return convertUiSchema(node, path + `/properties/${schema.name}`)
            }),
            rule: convertRule(schema.rule),
            options: schema.options,
        }
    }

    if (schema.type === 'groupTable') {
        return {
            type: 'Group',
            label: schema.label,
            elements: schema.nodes.map((node) => {
                return convertUiSchema(node, path + `/properties/${schema.name}`)
            }),
            rule: convertRule(schema.rule),
            options: schema.options,
        }
    }

    if (schema.type === 'dynamicTable') {
        return {
            type: 'Group',
            label: schema.label,
            elements: schema.nodes.map((node) => {
                return convertUiSchema(node, path + `/properties/${schema.name}`)
            }),
            rule: convertRule(schema.rule),
            options: schema.options,
        }
    }

    if (schema.type === 'array') {
        return {
            type: 'Control',
            label: schema.label,
            scope: path + `/properties/${schema.name}`,
            options: schema.options,
            rule: convertRule(schema.rule),
        }
    }

    if (schema.type === 'list') {
        return {
            type: 'Control',
            label: schema.label,
            scope: path + `/properties/${schema.name}`,
            options: schema.options,
            rule: convertRule(schema.rule),
        }
    }

    if (schema.type === 'disciplinesTag') {
        return {
            type: 'Control',
            label: schema.label,
            scope: path + `/properties/${schema.name}`,
            rule: convertRule(schema.rule),
            options: {
                ...schema.options,
                selectOptions: schema.enum,
            },
        }
    }

    if (schema.type === 'buildingPart') {
        return {
            type: 'Control',
            label: schema.label,
            scope: path + `/properties/${schema.name}`,
            rule: convertRule(schema.rule),
            options: {
                ...schema.options,
                selectOptions: schema.enum,
            },
        }
    }

    if (schema.type === 'select') {
        return {
            type: 'Control',
            label: schema.label,
            scope: path + `/properties/${schema.name}`,
            rule: convertRule(schema.rule),
            options: schema.options,
        }
    }

    if (schema.type === 'radio') {
        return {
            type: 'Control',
            label: schema.label,
            scope: path + `/properties/${schema.name}`,
            options: {
                ...schema.options,
                format: 'radio',
            },
            rule: convertRule(schema.rule),
        }
    }

    if (schema.type === 'string') {
        return {
            type: 'Control',
            label: schema.label,
            scope: path + `/properties/${schema.name}`,
            rule: convertRule(schema.rule),
            options: schema.options,
        }
    }

    if (schema.type === 'checkbox') {
        return {
            type: 'Control',
            label: schema.label,
            scope: path + `/properties/${schema.name}`,
            rule: convertRule(schema.rule),
            options: schema.options,
        }
    }

    if (schema.type === 'number') {
        return {
            type: 'Control',
            label: schema.label,
            scope: path + `/properties/${schema.name}`,
            rule: convertRule(schema.rule),
            options: schema.options,
        }
    }

    if (schema.type === 'boolean') {
        return {
            type: 'Control',
            label: schema.label,
            scope: path + `/properties/${schema.name}`,
            rule: convertRule(schema.rule),
            options: schema.options,
        }
    }
    if (schema.type === 'date') {
        return {
            type: 'Control',
            label: schema.label,
            scope: path + `/properties/${schema.name}`,
            rule: convertRule(schema.rule),
            options: schema.options,
        }
    }
    if (schema.type === 'file') {
        return {
            type: 'Control',
            label: schema.label,
            scope: path + `/properties/${schema.name}`,
            options: { fileUpload: true },
            rule: convertRule(schema.rule),
        }
    }

    throw new Error('Unknown schema type')
}

export type RuleField = {
    name: string
    path: string
    node: SchemaElement
}

export const getSchemaRuleFields = (schema: SchemaElement, name: string = '', path: string = ''): RuleField[] => {
    if (schema.type === 'root' || schema.type === 'VerticalLayout' || schema.type === 'HorizontalLayout') {
        return schema.nodes.map((node) => getSchemaRuleFields(node, name, path)).flat(1)
    }

    if (schema.type === 'Group') {
        return schema.nodes
            .map((node) => getSchemaRuleFields(node, `${name}.${schema.name}`, `${path}.properties.${schema.name}`))
            .flat(1)
    }

    if (schema.type === 'groupTable') {
        return schema.nodes
            .map((node) => getSchemaRuleFields(node, `${name}.${schema.name}`, `${path}.properties.${schema.name}`))
            .flat(1)
    }

    if (schema.type === 'dynamicTable') {
        return schema.nodes
            .map((node) => getSchemaRuleFields(node, `${name}.${schema.name}`, `${path}.properties.${schema.name}`))
            .flat(1)
    }

    if (schema.type === 'array') {
        return schema.nodes
            .map((node) =>
                getSchemaRuleFields(node, `${name}.${schema.name}`, `${path}.properties.${schema.name}.items`),
            )
            .flat(1)
    }

    if (
        schema.type === 'select' ||
        schema.type === 'radio' ||
        schema.type === 'string' ||
        schema.type === 'checkbox' ||
        schema.type === 'number' ||
        schema.type === 'boolean' ||
        schema.type === 'date' ||
        schema.type === 'file' ||
        schema.type === 'list' ||
        schema.type === 'disciplinesTag' ||
        schema.type === 'buildingPart'
    ) {
        return [{ name: `${name}.${schema.name}`, path: `${path}.properties.${schema.name}`, node: schema }]
    }

    if (schema.type === 'Label') {
        return [{ name: `${name}.${schema.text}`, path: `${path}.properties.${schema.text}`, node: schema }]
    }

    throw new Error('Unknown schema type')
}
