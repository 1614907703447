import React from 'react'
import {
    CustomerTabCard,
    CustomerTabCardButtonContainer,
    CustomerTabCardContent,
} from 'components/customersAdmin/CustomerTabCard'
import { useTranslation } from 'react-i18next'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { DataGridActionsCell } from 'components/general/table/DataGridActionsCell'
import { TableActionMenuProps } from 'types/util'
import { PermissionActionMenu } from './PermissionActionMenu'
import { PermissionCreateEditDialog } from './PermissionCreateEditDialog'
import { OrganizationBuilding } from 'redux/types/organization.type'

type OrganizationPermissionInfoProps = {
    organizationBuildings: OrganizationBuilding[]
    organizationId: string
}

export const OrganizationPermissionInfo: React.FC<OrganizationPermissionInfoProps> = ({
    organizationBuildings,
    organizationId,
}) => {
    const { t } = useTranslation()
    const [isCreatePermissionDialogOpen, setIsCreatePermissionDialogOpen] = React.useState(false)
    const [anchorElProps, setAnchorPropsEl] = React.useState<TableActionMenuProps>(null)
    const [permissionToEdit, setPermissionToEdit] = React.useState<OrganizationBuilding | null>(null)

    const openActionMenu = (event: React.MouseEvent<HTMLElement>, rowId: string) => {
        setAnchorPropsEl(anchorElProps ? null : { anchorEl: event.currentTarget, rowId })
    }

    const closeActionMenu = () => setAnchorPropsEl(null)

    const handleEditPermissionAction = (id: string) => {
        const orgBuilding = organizationBuildings.find((orgBuilding) => orgBuilding.id === id)
        if (!orgBuilding) throw new Error('Cant open edit organization building dialog with invalid building')
        setPermissionToEdit(orgBuilding)
        closeActionMenu()
    }

    const handlePermissionDialogClose = () => {
        setPermissionToEdit(null)
        setIsCreatePermissionDialogOpen(false)
    }

    return (
        <>
            <CustomerTabCard isLoading={false}>
                <CustomerTabCardContent>
                    <TableContainer sx={{ width: '100%', overflowX: 'auto', mt: 2 }}>
                        <Table
                            sx={{
                                minWidth: '100%',
                                '.MuiTableCell-head ': {
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                },
                            }}
                            padding="none"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell size="small">{t('common.name')}</TableCell>
                                    <TableCell size="small">{t('common.address')}</TableCell>
                                    <TableCell size="small">{t('common.cadastreNumber')}</TableCell>
                                    <TableCell size="small">{t('common.type')}</TableCell>
                                    <TableCell sx={{ width: 50 }} />
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ '& tr:last-child > td': { borderBottom: 'none' } }}>
                                {organizationBuildings.map((building) => (
                                    <TableRow key={building.id}>
                                        <TableCell>{building.name}</TableCell>
                                        <TableCell>
                                            {`${building.address.addressText}, ${building.address.city}` || '-'}
                                        </TableCell>
                                        <TableCell>{building.cadastre.cleanNumber}</TableCell>
                                        <TableCell>{building.type}</TableCell>
                                        <TableCell>
                                            <DataGridActionsCell onOpen={openActionMenu} rowId={building.id} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CustomerTabCardContent>
                <CustomerTabCardButtonContainer>
                    <Button variant="contained" color="primary" onClick={() => setIsCreatePermissionDialogOpen(true)}>
                        {t('organizationPage.AddPermission')}
                    </Button>
                </CustomerTabCardButtonContainer>
            </CustomerTabCard>

            <PermissionActionMenu
                anchorEl={anchorElProps?.anchorEl}
                id={anchorElProps?.rowId}
                onClose={closeActionMenu}
                onEditPermissionAction={handleEditPermissionAction}
            />
            <PermissionCreateEditDialog
                handleClose={handlePermissionDialogClose}
                open={isCreatePermissionDialogOpen || Boolean(permissionToEdit)}
                orgBuilding={permissionToEdit}
                organizationId={organizationId}
            />
        </>
    )
}
