import React from 'react'
import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import { TopBar } from 'components/general/top-bar/top-bar'
import { CreateTenantDialog } from 'modules/tenants/tenants/CreateTenantDialog'
import { TenantsTable } from 'modules/tenants/tenants/tenants-table'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { PageContainer } from 'components/general/containers/PageContainer'

export const TenantsPage: React.FC = () => {
    const { t } = useTranslation()
    const [isTenantDialogOpen, setIsTenantDialogOpen] = React.useState(false)

    return (
        <>
            <Helmet>
                <title>{t('common.tenantsTitle')}</title>
            </Helmet>
            <TopBar />
            <PageContainer>
                <div>
                    <Button
                        startIcon={<Add />}
                        color="secondary"
                        sx={{ mb: 2 }}
                        variant="contained"
                        onClick={() => setIsTenantDialogOpen(true)}
                    >
                        {t('tenantsPage.addTenant')}
                    </Button>
                    <CreateTenantDialog open={isTenantDialogOpen} handleClose={() => setIsTenantDialogOpen(false)} />
                    <TenantsTable />
                </div>
            </PageContainer>
        </>
    )
}
