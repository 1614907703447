import React from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useProjectView } from '../hooks/useProjectView'
import { PublicBuildingViewDialog } from '../shared/PublicBuildingViewDialog'

// community datagrid doesnt support row expanding
// so we use simple table
export const Buildings: React.FC = () => {
    const { t } = useTranslation()
    const { project } = useProjectView()

    const [buildingToViewId, setBuildingToViewId] = React.useState<string | null>(null)

    if (!project) return null

    return (
        <>
            <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'auto', mt: 2 }} elevation={1}>
                <Table
                    sx={{
                        minWidth: '100%',
                        '.MuiTableCell-head ': {
                            fontWeight: 'bold',
                            textTransform: 'capitalize',
                        },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell size="small">{t('common.name')}</TableCell>
                            <TableCell size="small">{t('common.address')}</TableCell>
                            <TableCell size="small" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                {t('common.postal')}
                            </TableCell>
                            <TableCell size="small">{t('common.cadastreNumber')}</TableCell>
                            <TableCell size="small" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                {t('common.year')}
                            </TableCell>
                            <TableCell size="small" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                {t('buildingsPage.floors')}
                            </TableCell>
                            <TableCell size="small" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                {t('common.area')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {project.buildings.map((row) => (
                            <TableRow
                                key={row.id}
                                onClick={() => setBuildingToViewId(row.id)}
                                sx={{ cursor: 'pointer' }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.address.addressText}</TableCell>
                                <TableCell
                                    sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                                >{`${row.address.postalCode}`}</TableCell>
                                <TableCell>{row.cadastre?.cleanNumber}</TableCell>
                                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{row.year}</TableCell>
                                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                    {row.floors.length}
                                </TableCell>
                                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                    {row.area ? (
                                        <>
                                            {row.area} m<sup>2</sup>
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <PublicBuildingViewDialog
                handleClose={() => setBuildingToViewId(null)}
                building={buildingToViewId ? project.buildings.find((b) => b.id === buildingToViewId)! : null}
            />
        </>
    )
}
