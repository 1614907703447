import { Edit, Delete } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Button from '@mui/material/Button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalsWrapper from 'components/general/dialogs/DialogWrapper'
import { SchemaElement } from 'redux/types/schema.type'

type FieldDialogProps = {
    node: SchemaElement | any
    children: React.ReactNode
    remove: () => void
    onSave?: any
}
export const FieldDialog: React.FC<FieldDialogProps> = ({ node, children, remove, onSave }) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)

    return (
        <div>
            <IconButton onClick={() => setOpen(true)} size="small">
                <Edit />
            </IconButton>
            <IconButton color="error" onClick={remove} size="small">
                <Delete />
            </IconButton>

            <ModalsWrapper
                sx={{
                    zIndex: 9999,
                }}
                open={open}
                title={`${node.name} [${node.type}]`}
                handleClose={() => setOpen(false)}
                fullWidth={true}
                maxWidth="lg"
                body={<div style={{ padding: '10px' }}>{children}</div>}
                actions={
                    <>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                setOpen(false)
                            }}
                        >
                            {t('common.cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => {
                                e.stopPropagation()
                                if (onSave) {
                                    // return type is only in builder-array, should prob figure better solution if needed
                                    // in more places
                                    const isArrSizesValid = onSave()
                                    if (isArrSizesValid === false) return
                                }
                                setOpen(false)
                            }}
                        >
                            {t('common.save')}
                        </Button>
                    </>
                }
            />
        </div>
    )
}
