import React from 'react'
import { GeneralSectionForm } from './GeneralSectionForm'
import { FloorsSectionForm } from './FloorsSectionForm'
import { EnergySectionForm } from './EnergySectionForm'
import { FireClassForm } from './FireClassForm'
import { CultureSectionForm } from './CultureSectionForm'
import { ServiceSectionForm } from './ServiceSectionForm'
import { FireInstallationForm } from './FireInstallationForm'
import { SpecialRiskSectionForm } from './SpecialRiskSectionForm'

export const BuildingInformationForm: React.FC = React.memo(() => {
    return (
        <>
            <GeneralSectionForm />
            <FloorsSectionForm />
            <EnergySectionForm />
            <FireClassForm />
            <CultureSectionForm />
            <FireInstallationForm />
            <SpecialRiskSectionForm />
            <ServiceSectionForm />
        </>
    )
})
