import React from 'react'
import { Box, Typography } from '@mui/material'

type DataGridTitleProps = {
    title: string
    textAlign?: 'center' | 'start' | 'end'
}

export const DataGridTitle: React.FC<DataGridTitleProps> = ({ title, textAlign = 'start' }) => {
    return (
        <Box width="100%" px={1.25}>
            <Typography variant="h5" sx={{ textAlign, fontSize: '1rem', lineHeight: '1.25rem', fontWeight: 600 }}>
                {title}
            </Typography>
        </Box>
    )
}
