import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import {
    BusinessCenter,
    Groups,
    Spoke,
    Star,
    Logout,
    Apartment,
    Settings,
    Dashboard,
    CorporateFare,
} from '@mui/icons-material'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useAppDispatch } from 'redux/hooks'
import { Drawer } from '../drawer'
import { ROUTES } from 'constants/routes'
import { authActions, useHasRole } from 'redux/slices/auth.slice'
import { Role } from 'types/role'

const getNavigation = (t: TFunction) => {
    return [
        { label: t('navigationMenu.tenants'), icon: <Star />, to: ROUTES.tenants },
        { label: t('navigationMenu.dashboard'), icon: <Dashboard />, to: ROUTES.dashboard },
        { label: t('navigationMenu.customers'), icon: <BusinessCenter />, to: ROUTES.customers },
        { label: t('navigationMenu.organizations'), icon: <CorporateFare />, to: ROUTES.organizations },
        { label: t('navigationMenu.projects'), icon: <Spoke />, to: ROUTES.projects },
        { label: t('navigationMenu.buildings'), icon: <Apartment />, to: ROUTES.buildings },
        { label: t('navigationMenu.users'), icon: <Groups />, to: ROUTES.users },
        { label: t('navigationMenu.settings'), icon: <Settings />, to: ROUTES.settings },
    ]
}

export const Menu: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const isAdmin = useHasRole(Role.Admin)

    const navigation = getNavigation(t)

    if (!isAdmin) {
        navigation.shift()
    }

    return (
        <Drawer>
            <Box sx={{ overflow: 'auto' }}>
                <List>
                    {navigation.map((item) => (
                        <ListItem key={item.to} disablePadding>
                            <ListItemButton component={Link} to={item.to}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => dispatch(authActions.logout())}>
                            <ListItemIcon>
                                <Logout />
                            </ListItemIcon>
                            <ListItemText primary={t('navigationMenu.logout')} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    )
}
