import React from 'react'
import PrivateAdminRoute from 'router/PrivateAdminRoute'
import { Outlet } from 'react-router-dom'
import { PageContentWrapper } from 'components/general/styles'
import { Footer } from 'components/general/footer'

export const PrivateAdminEmpty: React.FC = React.memo(() => {
    return (
        <PrivateAdminRoute>
            <PageContentWrapper>
                <Outlet />
                <Footer />
            </PageContentWrapper>
        </PrivateAdminRoute>
    )
})
