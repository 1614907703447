import React from 'react'
import { Box } from '@mui/material'
import PrivateAdminRoute from 'router/PrivateAdminRoute'
import { Outlet } from 'react-router-dom'
import { Drawer } from 'components/general/drawer'
import { TemplateSchemaTree } from 'modules/settings/templates/TemplateSchemaTree'
import { Footer } from 'components/general/footer'
import { PageContentWrapper } from 'components/general/styles'

export const PrivateAdminWithSchemaTree: React.FC = React.memo(() => {
    return (
        <PrivateAdminRoute>
            <Drawer customWidth={300}>
                <Box sx={{ overflow: 'auto', paddingTop: 4 }}>
                    <TemplateSchemaTree />
                </Box>
            </Drawer>
            <PageContentWrapper>
                <Outlet />
                <Footer />
            </PageContentWrapper>
        </PrivateAdminRoute>
    )
})
