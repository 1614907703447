import React from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { TopBar } from 'components/general/top-bar/top-bar'
import { PageLoader } from 'components/general/loaders/PageLoader'
import { navigationLinks } from 'utils/navigation-utils'
import { HeaderBackButton } from 'components/general/navigation/HeaderBackButton'
import { ProjectReadView } from 'modules/projects/view/ReadView'
import { useProjectView } from 'modules/projects/hooks/useProjectView'
import { ROUTES } from 'constants/routes'
import { ReadViewArchived } from 'modules/projects/view/ReadViewARchived'
import { useAppSelector } from 'redux/hooks'
import { selectId } from 'redux/slices/auth.slice'
import { styled } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useUpdateProjectStatusMutation } from 'services/projects.service'
import { useTranslation } from 'react-i18next'
import { PageContainer } from 'components/general/containers/PageContainer'
import { ProjectMobileActions } from 'components/projects/ProjectMobileActions'
import { FileService } from 'services/file.service'
import { selectAuthToken } from 'redux/selectors/auth.selectors'
import { toast } from 'react-toastify'
import { PdfViewDialog } from 'components/general/dialogs/PdfViewDialog'

export const ProjectPreviewInProgress: React.FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const userId = useAppSelector(selectId)
    const token = useAppSelector(selectAuthToken)
    const { state } = useLocation()
    const { isLoading, project, projectId, isError } = useProjectView()
    const [updateStatus, { isLoading: isUpdatingStatus }] = useUpdateProjectStatusMutation()

    const [isFormNavigationDrawerOpen, setIsFormNavigationDrawerOpen] = React.useState(false)
    const [preview, setPreview] = React.useState<string | null>(null)

    const backLink = state?.from || navigationLinks.toCreateProject(projectId)
    const isPreviewPdfHidden = !project?.formState?.formSnapshot

    const handleCancelQaProcess = async () => {
        if (!project) throw new Error('no project from rows found')
        try {
            await updateStatus({ id: project.id, status: 'FillingBuiltForm' }).unwrap()
            navigate(ROUTES.dashboard)
        } catch {}
    }

    const handlePreviewProjectPdf = async () => {
        if (!token) throw new Error('Not authenticated')
        if (!project?.id) throw new Error('Expected to recieve project id')
        try {
            const url = await FileService.DownloadProjectPreviewPdf(project?.id, token)
            setPreview(url)
        } catch (err: any) {
            toast.error(err?.translationKey ? t(err.translationKey) : t('genericErrorMessage'))
        }
    }

    const handleToggleDrawer = (open: boolean) => {
        setIsFormNavigationDrawerOpen(open)
    }

    if (isLoading) {
        return <PageLoader />
    }

    if (isError) {
        return <Navigate to="/" />
    }

    return (
        <>
            <Helmet>
                {project && <title>{`${project.type?.label} - ${project.buildings[0].address.addressText}`}</title>}
            </Helmet>
            <TopBar
                preLogoActions={<ProjectMobileActions onOpenNavigationDrawer={() => handleToggleDrawer(true)} />}
                leftActions={<HeaderBackButton label={project?.name} href={backLink} />}
                rightActions={
                    project && project.status === 'WaitingForQa' && project.projectManager?.id === userId
                        ? [
                              <StyledLoadingButton
                                  type="button"
                                  variant="contained"
                                  loading={isUpdatingStatus}
                                  color="secondary"
                                  onClick={handleCancelQaProcess}
                              >
                                  {t('common.cancelQa')}
                              </StyledLoadingButton>,
                              <StyledLoadingButton
                                  type="button"
                                  variant="contained"
                                  loading={false}
                                  color="secondary"
                                  onClick={handlePreviewProjectPdf}
                                  sx={{ display: isPreviewPdfHidden ? 'none' : 'inline-flex' }}
                              >
                                  {t('projectPage.viewDraftPdf')}
                              </StyledLoadingButton>,
                          ]
                        : [
                              <StyledLoadingButton
                                  type="button"
                                  variant="contained"
                                  loading={false}
                                  color="secondary"
                                  onClick={handlePreviewProjectPdf}
                                  sx={{ display: isPreviewPdfHidden ? 'none' : 'inline-flex' }}
                              >
                                  {t('projectPage.viewDraftPdf')}
                              </StyledLoadingButton>,
                          ]
                }
            />
            <PageContainer>
                {project?.creationType === 'ByDefaultFlow' ? (
                    <ProjectReadView
                        onDrawerToggle={handleToggleDrawer}
                        isNavigationDrawerOpen={isFormNavigationDrawerOpen}
                    />
                ) : (
                    <ReadViewArchived />
                )}
            </PageContainer>
            <PdfViewDialog open={preview !== null} handleClose={() => setPreview(null)} url={preview} />
        </>
    )
}

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
    '&:disabled': {
        borderColor: theme.palette.secondary.light,
        backgroundColor: theme.palette.secondary.main,
        opacity: 0.6,
    },
}))
