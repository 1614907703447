import React from 'react'
import { FormControl, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import ReactCountryFlag from 'react-country-flag'
import { changeLanguage, getLang } from 'translation/translation-helper'
import { useAppDispatch } from 'redux/hooks'
import { appApi } from 'redux/api'

type CountryOption = { label: string; value: string; flagCode: string }
const COUNTRY_OPTIONS: CountryOption[] = [
    {
        label: 'NO',
        value: 'nb',
        flagCode: 'NO',
    },
    {
        label: 'EN',
        value: 'en',
        flagCode: 'GB',
    },
]

export const LanguageSelect: React.FC = () => {
    const dispatch = useAppDispatch()

    const handleChange = (event: SelectChangeEvent<string>) => {
        const newLanguage = event.target.value || 'en'
        changeLanguage(newLanguage)
        dispatch(appApi.util.resetApiState())
    }

    return (
        <FormControl sx={{ mr: 1, width: '4.5rem' }}>
            <Select
                sx={(theme) => ({
                    color: '#FFF',
                    '& fieldset': {
                        borderBottom: 'none',
                        '&:hover': {
                            borderBottom: 'none',
                        },
                    },
                    '& svg': {
                        color: '#FFF',
                    },
                    '&::before': {
                        display: 'none',
                    },
                    '&::after': {
                        borderBottom: 'none',
                    },
                })}
                color="secondary"
                variant="filled"
                size="small"
                value={getLang()}
                onChange={handleChange}
                renderValue={(v) => {
                    const lang = COUNTRY_OPTIONS.find((opt) => opt.value === v)!.flagCode
                    return (
                        <ReactCountryFlag
                            countryCode={lang}
                            svg
                            style={{ width: '1.5rem', height: '1.5rem', marginBottom: '16px' }}
                        />
                    )
                }}
            >
                {COUNTRY_OPTIONS.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                        <ReactCountryFlag countryCode={opt.flagCode} svg />
                        <ListItemText primary={opt.label} sx={{ ml: 1.5 }} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
