import React from 'react'

import { Box, Button, Stack, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ModalsWrapper from './DialogWrapper'
import { LoadingButton } from '@mui/lab'
import { Delete } from '@mui/icons-material'

interface DeleteDialogProps {
    open: boolean
    handleClose: () => void
    deleteAction: () => void
    isDeleting?: boolean
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({ open, handleClose, deleteAction, isDeleting }) => {
    const { t } = useTranslation()

    return (
        <ModalsWrapper
            sx={{
                zIndex: 9999,
            }}
            open={open}
            title={t('common.deleteDialogTitle')}
            handleClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
            body={
                <ContentWrapper spacing={0}>
                    <Box margin="0 auto">
                        <Delete color="error" fontSize="large" />
                    </Box>
                    <Typography variant="body1" color="text" textAlign="center" mt={1.5} mb={2}>
                        {t('common.deleteDialogText')}
                    </Typography>
                </ContentWrapper>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button
                        variant="outlined"
                        onClick={(e) => {
                            e.stopPropagation()
                            handleClose()
                        }}
                        color="secondary"
                    >
                        {t('common.back')}
                    </Button>
                    <LoadingButton
                        loading={!!isDeleting}
                        disabled={isDeleting}
                        type="submit"
                        variant="contained"
                        color="error"
                        onClick={(e) => {
                            e.stopPropagation()
                            deleteAction()
                        }}
                        sx={(theme) => ({
                            [theme.breakpoints.down('sm')]: {
                                marginTop: theme.spacing(2),
                            },
                        })}
                    >
                        {t('common.delete')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

export default DeleteDialog

const ActionWrapper = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
