import React from 'react'
import { Search } from '@mui/icons-material'
import { TopBar } from '../../components/general/top-bar/top-bar'
import { FormControl, InputAdornment, Stack, TextField, styled } from '@mui/material'
import { TenantFinishedProjectsTable } from 'modules/projects/projects/TenantFinishedProjectsTable'
import { TenantProjectsTable } from 'modules/projects/projects/TenantProjectsTable'
import { useTranslation } from 'react-i18next'
import useDebounce from 'utils/hooks/useDebounce'
import { useDataGridFiltersState } from 'utils/hooks/useDataGridFiltersState'
import { Helmet } from 'react-helmet'
import { PageContainer } from 'components/general/containers/PageContainer'

export const ProjectsPage: React.FC = () => {
    const { t } = useTranslation()
    const { handlePageChange, handleSortChange, handleSearchChange, page, searchQuery, sortBy } =
        useDataGridFiltersState({ sort: 'desc', field: 'name' })
    // reusing searchquery from the above hook
    const {
        handlePageChange: handleFinishedProjectPageChange,
        handleSortChange: handleFinishedProjectSortChange,
        page: finishedProjectsPage,
        sortBy: finishedProjectsSortBy,
    } = useDataGridFiltersState({ sort: 'desc', field: 'name' })

    const searchProjects = React.useCallback(
        async (search: string) => {
            if (search.length === 0) {
                handleSearchChange('')
                handlePageChange(0)
                handleFinishedProjectPageChange(0)
                return
            }
            if (search.length <= 2) return

            handlePageChange(0)
            handleFinishedProjectPageChange(0)
            handleSearchChange(search)
        },
        [handlePageChange, handleFinishedProjectPageChange, handleSearchChange],
    )

    const { search: inputSearchValue, onSearch } = useDebounce(searchProjects, 500, '')

    return (
        <>
            <Helmet>
                <title>{t('navigationMenu.projects')}</title>
            </Helmet>
            <TopBar />
            <PageContainer>
                <Stack gap={4}>
                    <FilterContainer>
                        <FormControl fullWidth>
                            <TextField
                                sx={{ mt: 1.5 }}
                                type="text"
                                name="query"
                                value={inputSearchValue}
                                onChange={onSearch}
                                size="small"
                                placeholder={t('common.search')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </FilterContainer>
                    <TenantProjectsTable
                        filter={{ page, searchQuery, sortBy }}
                        actions={{ onPageChange: handlePageChange, onSortChange: handleSortChange }}
                    />
                    <TenantFinishedProjectsTable
                        filter={{ page: finishedProjectsPage, searchQuery, sortBy: finishedProjectsSortBy }}
                        actions={{
                            onPageChange: handleFinishedProjectPageChange,
                            onSortChange: handleFinishedProjectSortChange,
                        }}
                    />
                </Stack>
            </PageContainer>
        </>
    )
}

const FilterContainer = styled('div')({
    maxWidth: 300,
})
