import React from 'react'
import { Box, Button, FormControl, InputAdornment, Paper, SortDirection, TextField, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { navigationLinks } from 'utils/navigation-utils'
import { Add, Search } from '@mui/icons-material'
import useDebounce from 'utils/hooks/useDebounce'
import { DataGrid, GridColDef, GridSortDirection } from '@mui/x-data-grid'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { DataGridNoRows } from 'components/general/table/DataGridNoRows'
import { useDataGridQueryFiltersState } from 'utils/hooks/useDataGridQueryFiltersState'
import { useGetOrganizationsQuery } from 'services/organizations.service'
import { Organization } from 'redux/types/organization.type'
import { DataGridLinkCell } from 'components/general/table/DataGridLinkCell'
import { formatOrgNumber } from 'utils/string-utils'
import { OrganizationCreateDialog } from './OrganizationCreateDialog'

export const OrganizationsTable: React.FC = () => {
    const { t } = useTranslation()

    const {
        handlePageChange,
        handleSortChange,
        handleSearchChange,
        search: { field, page, sort, search },
    } = useDataGridQueryFiltersState({ sort: 'desc', field: 'updated' })

    const { data: organizations, isFetching } = useGetOrganizationsQuery(
        {
            page: page || 0,
            itemsPerPage: DEFAULT_ROWS_PER_PAGE,
            direction: sort as SortDirection,
            sortBy: field ?? undefined,
            searchQuery: search || undefined,
        },
        { skip: page === undefined },
    )

    const [isCreateOrgDialogOpen, setIsCreateOrgDialogOpen] = React.useState(false)

    const searchOrgs = React.useCallback(
        async (search: string) => {
            if (search.length === 0) {
                handleSearchChange('')
                handlePageChange(0)
                return
            }
            if (search.length <= 2) return

            handlePageChange(0)
            handleSearchChange(search)
        },
        [handlePageChange, handleSearchChange],
    )

    const { search: inputSearchValue, onSearch } = useDebounce(searchOrgs, 500, search || '')

    const columns: GridColDef<Organization>[] = [
        {
            field: 'name',
            headerName: t('organizationsPage.organizationsTable.name'),
            flex: 1,
            editable: false,
            renderCell: (params) => (
                <DataGridLinkCell label={params.row.name} to={navigationLinks.toOrganization(params.row.id)} />
            ),
            minWidth: 200,
            maxWidth: 220,
        },
        {
            field: 'organizationNumber',
            headerName: t('organizationsPage.organizationsTable.organizationNumber'),
            flex: 1,
            editable: false,
            minWidth: 200,
            maxWidth: 220,
            valueGetter: (params) =>
                params.row.organizationNumber ? formatOrgNumber(params.row.organizationNumber) : '-',
        },
        {
            field: 'contacts',
            headerName: t('organizationsPage.organizationsTable.contact'),
            flex: 1,
            editable: false,
            sortable: false,
            valueGetter: (params) => (params.row.contacts ? params.row.contacts.map((c) => c.name).join(', ') : '-'),
        },
        {
            field: 'phoneNumber',
            headerName: t('organizationsPage.organizationsTable.phone'),
            flex: 1,
            minWidth: 160,
            maxWidth: 180,
            editable: false,
        },
        {
            field: 'type',
            headerName: t('common.type'),
            flex: 1,
            editable: false,
            minWidth: 120,
            maxWidth: 160,
            valueGetter: (params) => t(`common.customerType.${params.row.type}`),
        },
        {
            field: 'updated',
            hideable: true,
        },
    ]

    return (
        <>
            <Paper elevation={0} sx={{ padding: 2.5, height: '100%' }}>
                <FilterContainer>
                    <div id="filters-container">
                        <FormControl fullWidth>
                            <TextField
                                sx={{ mt: 1.5 }}
                                type="text"
                                name="query"
                                value={inputSearchValue}
                                onChange={onSearch}
                                size="small"
                                placeholder={t('common.search')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </div>

                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ minWidth: 120 }}
                            startIcon={<Add />}
                            onClick={() => setIsCreateOrgDialogOpen(true)}
                        >
                            {t('common.add')}
                        </Button>
                    </div>
                </FilterContainer>
                <Box>
                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                textTransform: 'capitalize',
                            },
                            '& .MuiDataGrid-row': { cursor: 'pointer' },
                        }}
                        autoHeight
                        rows={organizations?.data || []}
                        columns={columns}
                        pageSize={DEFAULT_ROWS_PER_PAGE}
                        rowCount={organizations?.total || 0}
                        isRowSelectable={() => false}
                        disableColumnMenu
                        loading={isFetching}
                        hideFooterSelectedRowCount
                        rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                        components={{
                            NoRowsOverlay: DataGridNoRows,
                        }}
                        paginationMode="server"
                        sortingMode="server"
                        onPageChange={handlePageChange}
                        onSortModelChange={handleSortChange}
                        page={page || 0}
                        sortModel={field && sort ? [{ field, sort: sort as GridSortDirection }] : []}
                        // hide updated column
                        // we need to add updated column
                        // so that we can initially sort by updated
                        columnVisibilityModel={{
                            updated: false,
                        }}
                    />
                </Box>
            </Paper>

            <OrganizationCreateDialog
                handleClose={() => setIsCreateOrgDialogOpen(false)}
                open={isCreateOrgDialogOpen}
            />
        </>
    )
}

const FilterContainer = styled('div')(({ theme }) => ({
    width: '100%',
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    gap: theme.spacing(2),

    '& > #filters-container': {
        maxWidth: 300,
    },
}))
