import React from 'react'
import { Building } from 'redux/types/building.type'
import { navigationLinks } from 'utils/navigation-utils'
import { Tooltip, Typography, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { StyledLink } from '../links/styles'

type ProjectBuildingListCellLinkProps = {
    buildings: Building[]
    navigateToCustomerBuildingPage?: boolean
}

export const ProjectBuildingListLinkCell: React.FC<ProjectBuildingListCellLinkProps> = React.memo(
    ({ buildings, navigateToCustomerBuildingPage = false }) => {
        const { t } = useTranslation()

        if (buildings.length === 0) return <span>-</span>

        return (
            <Container>
                {buildings.map((building, idx, self) => (
                    <StyledLink
                        to={
                            navigateToCustomerBuildingPage
                                ? navigationLinks.toCustomerBuilding(building.id)
                                : navigationLinks.toBuilding(building.id)
                        }
                        key={building.id}
                    >
                        <Tooltip title={`${t('common.buildingNumber')}: ${building.buildingNumber}`} arrow>
                            <Typography variant="body2">
                                {`${building.address.addressText} ${
                                    building.cadastre?.cleanNumber ? `(${building.cadastre?.cleanNumber})` : '-'
                                }${idx !== self.length - 1 ? ', ' : ''}`}
                            </Typography>
                        </Tooltip>
                    </StyledLink>
                ))}
            </Container>
        )
    },
)

type ProjectBuildingListCellProps = {
    buildings: Building[]
}

export const ProjectBuildingListCell: React.FC<ProjectBuildingListCellProps> = React.memo(({ buildings }) => {
    const { t } = useTranslation()

    if (buildings.length === 0) return <span>-</span>

    return (
        <Container>
            {buildings.map((building, idx, self) => (
                <span key={building.id}>
                    <Tooltip title={`${t('common.buildingNumber')}: ${building.buildingNumber}`} arrow>
                        <Typography variant="body2">
                            {`${building.address.addressText} ${
                                building.cadastre?.cleanNumber ? `(${building.cadastre?.cleanNumber})` : '-'
                            }${idx !== self.length - 1 ? ', ' : ''}`}
                        </Typography>
                    </Tooltip>
                </span>
            ))}
        </Container>
    )
})

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    padding: theme.spacing(1, 0),
}))
