import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import React from 'react'
import { CustomerOrOrganizationForm } from 'schemas/customers'

interface DetailsFormPartProps {
    title: string
    control: Control<CustomerOrOrganizationForm>
    isPrivatePerson: boolean
}

export const DetailsFormPart: React.FC<DetailsFormPartProps> = ({ title, control, isPrivatePerson }) => {
    const { t } = useTranslation()

    return (
        <Grid item xs={12} sm={6}>
            <Typography variant="h6">{title}</Typography>
            <div>
                <ControlledMuiTextField
                    name="name"
                    control={control}
                    placeholder={t('customersPage.customerDialog.customerNamePlaceholder')}
                    fullWidth
                    variant="filled"
                />
                {!isPrivatePerson && (
                    <>
                        <ControlledMuiTextField
                            name="organizationNumber"
                            control={control}
                            placeholder={t('customersPage.customerDialog.organizationNumberPlaceholder')}
                            fullWidth
                            variant="filled"
                        />
                        <ControlledMuiTextField
                            name="phoneNumber"
                            control={control}
                            placeholder={t('common.companyPhoneNumber')}
                            fullWidth
                            variant="filled"
                        />
                        <ControlledMuiTextField
                            name="email"
                            control={control}
                            placeholder={t('common.companyEmail')}
                            fullWidth
                            variant="filled"
                        />
                    </>
                )}
                <ControlledMuiTextField
                    name="address.addressText"
                    control={control}
                    placeholder={t('customersPage.customerDialog.addressTextPlaceholder')}
                    fullWidth
                    variant="filled"
                />
                <ControlledMuiTextField
                    name="address.postalCode"
                    control={control}
                    placeholder={t('customersPage.customerDialog.postalPlaceholder')}
                    fullWidth
                    variant="filled"
                />
                <ControlledMuiTextField
                    name="address.country"
                    control={control}
                    placeholder={t('customersPage.customerDialog.countryPlaceholder')}
                    fullWidth
                    variant="filled"
                />
            </div>
        </Grid>
    )
}
