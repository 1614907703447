import { Box } from '@mui/material'
import ModalsWrapper from './DialogWrapper'

interface PdfViewDialogProps {
    open: boolean
    handleClose: () => void
    url: string | null
}

export const PdfViewDialog = ({ handleClose, open, url }: PdfViewDialogProps) => {
    if (url === null) return null

    return (
        <ModalsWrapper
            open={open}
            handleClose={handleClose}
            sx={{
                zIndex: 9999,
                padding: 4,
                '& .MuiDialogContent-root': {
                    padding: '2.5rem 1rem 1rem',
                },
            }}
            fullScreen
            maxWidth={false}
            body={
                <Box width="100%" height="100%">
                    <iframe src={url} width="100%" height="100%" title="report" allowFullScreen />
                </Box>
            }
        />
    )
}
