import { and, isStringControl, optionIs, rankWith } from '@jsonforms/core'
import { materialRenderers } from '@jsonforms/material-renderers'
import { ArrayListControl } from 'components/forms/custom-renderers/array/ArrayList'
import { DynamicTableControl } from 'components/forms/custom-renderers/group/DynamicTableControl'
import { GroupTableControl } from 'components/forms/custom-renderers/group/GroupTableControl'
import { BuildingPartTagControl } from 'components/forms/custom-renderers/select/BuildingPartControl'
import { DisciplinesTagControl } from 'components/forms/custom-renderers/select/DisciplinesTagControl'
import { UploadControl } from 'components/forms/custom-renderers/upload/upload-control'

const uploadControlTester = rankWith(
    3, //increase rank as needed
    and(isStringControl, optionIs('fileUpload', true)),
)

const uniqueGroupTableTester = rankWith(5, optionIs('uniqueValue', true))
const arrayListTester = rankWith(5, optionIs('isList', true))
const dynamicTableTester = rankWith(5, optionIs('dynamicTable', true))
const disciplinesTagInputTester = rankWith(999, optionIs('disciplinesTag', true))
const buildingPartTagInputTester = rankWith(999, optionIs('buildingPart', true))

export const renderers = [
    ...materialRenderers,
    //register custom renderers
    { tester: uploadControlTester, renderer: UploadControl },
    { tester: uniqueGroupTableTester, renderer: GroupTableControl },
    { tester: arrayListTester, renderer: ArrayListControl },
    { tester: dynamicTableTester, renderer: DynamicTableControl },
    { tester: disciplinesTagInputTester, renderer: DisciplinesTagControl },
    { tester: buildingPartTagInputTester, renderer: BuildingPartTagControl },
]
