import React from 'react'
import { CustomerTabCard, CustomerTabCardContent } from 'components/customersAdmin/CustomerTabCard'
import { useTranslation } from 'react-i18next'
import { Customer } from 'redux/types/customer.type'
import { useGetArchivedProjectsQuery, useGetProjectsQuery } from 'services/projects.service'
import { useDataGridFiltersState } from 'utils/hooks/useDataGridFiltersState'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { Box, SortDirection } from '@mui/material'
import { DataGrid, GridColDef, GridRowHeightParams } from '@mui/x-data-grid'
import { ProjectStatusCell } from 'components/projects/ProjectStatusCell'
import { ProjectBuildingListLinkCell } from 'components/general/table/ProjectBuildingListCell'
import { ProjectWithoutForms } from 'redux/types/project.type'
import { dateOrDefaultEmpty, valueOrDefaultEmpty } from 'utils/general'
import { DataGridNoRows } from 'components/general/table/DataGridNoRows'
import { DataGridTitle } from 'components/general/table/DataGridTitle'
import { DATE_LOCALE_OPTIONS } from 'constants/locales'
import { DataGridLinkCell } from 'components/general/table/DataGridLinkCell'
import { navigationLinks } from 'utils/navigation-utils'
import { PROJECT_NO_EDIT_ACTIONS } from 'constants/util'

type CustomerProjectsProps = {
    customer: Customer
}

export const CustomerProjects: React.FC<CustomerProjectsProps> = ({ customer }) => {
    const { t } = useTranslation()

    const { handlePageChange, handleSortChange, page, sortBy } = useDataGridFiltersState({
        sort: 'desc',
        field: 'name',
    })

    const {
        handlePageChange: handleFinishedProjectPageChange,
        handleSortChange: handleFinishedProjectSortChange,
        page: finishedProjectsPage,
        sortBy: finishedProjectsSortBy,
    } = useDataGridFiltersState({ sort: 'desc', field: 'name' })

    const { data: projects, isFetching: isFetchingProjects } = useGetProjectsQuery({
        page: page || 0,
        itemsPerPage: DEFAULT_ROWS_PER_PAGE,
        direction: sortBy?.sort as SortDirection,
        sortBy: sortBy?.field,
        customerIds: [customer.id],
    })
    const { data: finishedProjects, isFetching: isFetchingFinishedProjects } = useGetArchivedProjectsQuery({
        page: finishedProjectsPage || 0,
        itemsPerPage: DEFAULT_ROWS_PER_PAGE,
        direction: finishedProjectsSortBy?.sort as SortDirection,
        sortBy: finishedProjectsSortBy?.field,
        customerIds: [customer.id],
    })

    const customerPageLink = navigationLinks.toCustomer(customer.id)

    const ongoingProjectColumns: GridColDef<ProjectWithoutForms>[] = [
        {
            field: 'type',
            headerName: t('dashboard.projectType'),
            flex: 1,
            editable: false,
            minWidth: 160,
            maxWidth: 200,
            valueGetter: (params) => valueOrDefaultEmpty(params.row.type?.label),
        },
        {
            field: 'name',
            headerName: t('dashboard.projectName'),
            flex: 1,
            editable: false,
            minWidth: 200,
            renderCell: (params) => {
                let link
                const state = {
                    from: customerPageLink,
                }

                if (PROJECT_NO_EDIT_ACTIONS.includes(params.row.status))
                    link = navigationLinks.toReviewProject(params.row.id)
                else link = navigationLinks.toCreateProject(params.row.id)

                return (
                    <DataGridLinkCell
                        label={params.row.name || t('common.noProjectNamePlaceholder')}
                        to={link}
                        state={state}
                    />
                )
            },
        },
        {
            field: 'buildings',
            headerName: t('dashboard.projectBuildings'),
            flex: 1,
            editable: false,
            minWidth: 350,
            width: 350,
            sortable: false,
            renderCell: (params) => <ProjectBuildingListLinkCell buildings={params.row.buildings} />,
        },
        {
            field: 'status',
            headerName: t('dashboard.projectStatus'),
            flex: 1,
            editable: false,
            maxWidth: 170,
            minWidth: 170,
            renderCell: (params) => <ProjectStatusCell status={params.row.status} />,
        },
    ]

    const finishedProjectsColumns: GridColDef<ProjectWithoutForms>[] = [
        {
            field: 'type',
            headerName: t('dashboard.projectType'),
            flex: 1,
            editable: false,
            minWidth: 160,
            valueGetter: (params) => valueOrDefaultEmpty(params.row.type?.label),
        },
        {
            field: 'name',
            headerName: t('dashboard.projectName'),
            flex: 1,
            editable: false,
            minWidth: 300,
            renderCell: (params) => {
                const state = {
                    from: navigationLinks.toCustomer(customer.id),
                }
                const link = {
                    search: `accessToken=${params.row.accessToken!}`,
                    pathname: navigationLinks.toCustomerViewProject(params.row.id),
                }

                return (
                    <DataGridLinkCell
                        label={params.row.name || t('common.noProjectNamePlaceholder')}
                        to={link}
                        state={state}
                    />
                )
            },
        },
        {
            field: 'buildings',
            headerName: t('dashboard.projectBuildings'),
            flex: 1,
            editable: false,
            minWidth: 350,
            width: 350,
            sortable: false,
            renderCell: (params) => <ProjectBuildingListLinkCell buildings={params.row.buildings} />,
        },
        {
            field: 'publishDate',
            headerName: t('dashboard.projectPublishDate'),
            flex: 1,
            editable: false,
            maxWidth: 170,
            minWidth: 170,
            valueGetter: (params) =>
                dateOrDefaultEmpty(params.row.publishDate, (value) =>
                    new Date(value).toLocaleDateString('no', DATE_LOCALE_OPTIONS['no']),
                ),
        },
    ]

    return (
        <>
            <CustomerTabCard>
                <CustomerTabCardContent>
                    <Box display="flex" flexDirection="column" width="100%">
                        <DataGrid
                            sx={{
                                '& .MuiDataGrid-columnHeader': {
                                    textTransform: 'capitalize',
                                },
                                mt: 6,
                            }}
                            autoHeight
                            rows={projects?.data || []}
                            columns={ongoingProjectColumns}
                            rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                            pageSize={DEFAULT_ROWS_PER_PAGE}
                            rowCount={projects?.total || 0}
                            isRowSelectable={() => false}
                            disableColumnMenu
                            loading={isFetchingProjects}
                            hideFooterSelectedRowCount
                            components={{
                                NoRowsOverlay: DataGridNoRows,
                                Toolbar: DataGridTitle,
                            }}
                            componentsProps={{
                                toolbar: { title: t('projectsPage.currentProjects') },
                            }}
                            paginationMode="server"
                            sortingMode="server"
                            onPageChange={handlePageChange}
                            onSortModelChange={handleSortChange}
                            page={page}
                            sortModel={sortBy ? [{ field: sortBy.field, sort: sortBy.sort }] : []}
                            getRowHeight={({ model }: GridRowHeightParams) => {
                                if (model.buildings.length > 1) return 'auto'

                                return null
                            }}
                        />

                        <DataGrid
                            sx={{
                                '& .MuiDataGrid-columnHeader': {
                                    textTransform: 'capitalize',
                                },
                            }}
                            autoHeight
                            rows={finishedProjects?.data || []}
                            columns={finishedProjectsColumns}
                            rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                            pageSize={DEFAULT_ROWS_PER_PAGE}
                            rowCount={finishedProjects?.total || 0}
                            isRowSelectable={() => false}
                            disableColumnMenu
                            loading={isFetchingFinishedProjects}
                            hideFooterSelectedRowCount
                            components={{
                                NoRowsOverlay: DataGridNoRows,
                                Toolbar: DataGridTitle,
                            }}
                            componentsProps={{
                                toolbar: { title: t('projectsPage.completeProjects') },
                            }}
                            paginationMode="server"
                            sortingMode="server"
                            onPageChange={handleFinishedProjectPageChange}
                            onSortModelChange={handleFinishedProjectSortChange}
                            page={page}
                            sortModel={sortBy ? [{ field: sortBy.field, sort: sortBy.sort }] : []}
                            getRowHeight={({ model }: GridRowHeightParams) => {
                                if (model.buildings.length > 1) return 'auto'

                                return null
                            }}
                        />
                    </Box>
                </CustomerTabCardContent>
            </CustomerTabCard>
        </>
    )
}
