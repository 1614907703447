import React from 'react'

import { Box, Button, Stack, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { Project } from 'redux/types/project.type'
import { Config } from 'redux/config'
import { navigationLinks } from 'utils/navigation-utils'
import { ContentCopy } from '@mui/icons-material'

interface QrCodeDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions'> {
    project: Project
}

const QrCodeDialog: React.FC<QrCodeDialogProps> = ({ open, handleClose, project }) => {
    const { t } = useTranslation()
    const [isCopied, setIsCopied] = React.useState(false)
    const timerRef = React.useRef<NodeJS.Timeout | null>(null)

    // qr code actually should always exist here, the button to open this dialog in project creation is disabled if qrcode is null
    const QRCodeFileLink = project.qrCode
        ? `${Config.backendUrl}/v1/files/${project.qrCode.id}?accessToken=${project.qrCode.accessToken}`
        : ''
    const customerViewLink = `${window.location.origin}${navigationLinks.toCustomerViewProject(
        project.id,
    )}?accessToken=${project.accessToken}`

    React.useEffect(() => {
        return () => {
            if (timerRef.current) clearTimeout(timerRef.current)
        }
    }, [])

    const onCopyToClipboard = () => {
        navigator.clipboard.writeText(customerViewLink)
        setIsCopied(true)
        timerRef.current = setTimeout(() => setIsCopied(false), 3000)
    }

    return (
        <ModalsWrapper
            open={open}
            handleClose={handleClose}
            fullWidth
            maxWidth="sm"
            body={
                <ContentWrapper>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <ImageContainer>
                            <StyledImg src={QRCodeFileLink} alt="QR code" />
                        </ImageContainer>
                    </Box>
                </ContentWrapper>
            }
            actions={
                <ActionWrapper mx={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onCopyToClipboard}
                        startIcon={<ContentCopy />}
                        sx={{ minWidth: 130 }}
                    >
                        {isCopied ? `${t('common.copied')}!` : t('common.copyLink')}
                    </Button>

                    <Button component="a" variant="contained" color="primary" href={QRCodeFileLink}>
                        {t('common.downloadImage')}
                    </Button>
                </ActionWrapper>
            }
        />
    )
}

export default QrCodeDialog

const ActionWrapper = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'spacee-between',
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))

const ImageContainer = styled('div')({
    maxWidth: '200px',
})

const StyledImg = styled('img')({
    maxWidth: '100%',
    height: 'auto',
    verticalAlign: 'middle',
    fontStyle: 'italic',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    shapeMargin: '0.75rem',
})
