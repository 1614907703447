import React from 'react'
import { styled } from '@mui/system'
import { replaceLineBreaksWithBrTag } from 'utils/string-utils'
import { Typography } from '@mui/material'
import { JSONFormViewComp, SchemaElementTypeMap } from 'redux/types/schemaView.type'

export const TextAreaView: React.FC<JSONFormViewComp<SchemaElementTypeMap['textArea']>> = React.memo(({ node }) => {
    if (!node.isVisible || !node.isVisibleInWeb || node.value === undefined) return null

    return (
        <ItemContainer>
            {node.label && node.isLabelVisible && <Label variant="body1">{node.label}</Label>}
            <Typography
                variant="body2"
                component="span"
                dangerouslySetInnerHTML={{
                    __html: replaceLineBreaksWithBrTag(node.value),
                }}
            />
        </ItemContainer>
    )
})

const Label = styled(Typography)({
    fontWeight: 600,
    display: 'block',
    marginRight: 'auto',
})

const ItemContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '& span:last-of-type': {
        marginLeft: 0,
        marginTop: theme.spacing(1),
    },

    '& a': {
        color: theme.palette.secondary.main,
    },
}))
