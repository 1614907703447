import React from 'react'
import PrivateAdminRoute from 'router/PrivateAdminRoute'
import { Outlet } from 'react-router-dom'
import { Menu } from 'components/general/navigation/menu'
import { Footer } from 'components/general/footer'
import { PageContentWrapper } from 'components/general/styles'

export const PrivateAdminWithSidebar: React.FC = React.memo(() => {
    return (
        <PrivateAdminRoute>
            <Menu />
            <PageContentWrapper>
                <Outlet />
                <Footer />
            </PageContentWrapper>
        </PrivateAdminRoute>
    )
})
