import React from 'react'
import { useFormContext, useWatch, Control } from 'react-hook-form'
import { Typography } from '@mui/material'
import { CreateOrUpdateBuildingForm } from 'redux/types/building.type'
import { ControlledMuiDateField } from 'components/inputs/ControlledMuiDateField'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { useTranslation } from 'react-i18next'
import { FloorFormTable } from './FloorFormTable'
import { FormItem } from 'components/buildings/FormItem'
import { SectionBlock } from 'components/buildings/SectionBlock'

// moved to diff component to isolate rendering
const FloorCount: React.FC<{ control: Control<CreateOrUpdateBuildingForm> }> = ({ control }) => {
    const { t } = useTranslation()
    const floorsState = useWatch({ control: control, name: 'floors' })
    const floorCount = (floorsState || []).filter((floor) => floor.counting).length

    return (
        <FormItem label={t('createEditBuildingPage.buildingFloorCount')}>
            <Typography>{floorCount}</Typography>
        </FormItem>
    )
}

export const FloorsSectionForm: React.FC = () => {
    const { t } = useTranslation()
    const { control } = useFormContext<CreateOrUpdateBuildingForm>()

    return (
        <>
            <SectionBlock>
                <FormItem label={t('common.year')}>
                    <ControlledMuiDateField
                        control={control}
                        name="year"
                        disableFuture
                        fullWidth
                        inputProps={{
                            sx: { maxWidth: 150 },
                            variant: 'filled',
                        }}
                        views={['year']}
                    />
                </FormItem>

                <FloorCount control={control} />

                <FormItem label={t('common.height')}>
                    <ControlledMuiTextField
                        control={control}
                        name="height"
                        sx={{ maxWidth: 150 }}
                        type="number"
                        inputSuffix={<span>{t('common.meter')}</span>}
                        placeholder={t('common.height')}
                    />
                </FormItem>

                <FormItem label={t('createEditBuildingPage.buildingFootprint')}>
                    <ControlledMuiTextField
                        control={control}
                        name="area"
                        sx={{ maxWidth: 150 }}
                        type="number"
                        placeholder={t('createEditBuildingPage.buildingFootprint')}
                        inputSuffix={
                            <div>
                                m
                                <sup>
                                    <sup>2</sup>
                                </sup>
                            </div>
                        }
                    />
                </FormItem>
                <FloorFormTable />
            </SectionBlock>
        </>
    )
}
