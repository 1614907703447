import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, FormControlLabel, Switch } from '@mui/material'
import { PrebuiltFormNavigationControlls } from './PrebuiltFormNavigationControlls'

type NoTemplateAssignedProps = {
    title?: string
    handleNavigateToNextStructure: (direction: 'next' | 'prev') => void
    prevNode: string | null
    nextNode: string | null
    handleDisableSchema?: (event: React.ChangeEvent<HTMLInputElement>) => void
    isFormDisabled?: boolean
}

export const PrebuiltFormNotAssigned: React.FC<NoTemplateAssignedProps> = ({
    handleNavigateToNextStructure,
    title,
    prevNode,
    nextNode,
    isFormDisabled,
    handleDisableSchema,
}) => {
    const { t } = useTranslation()

    return (
        <Card sx={{ px: 4, height: '100%', py: 2, borderRadius: '5px 5px 0 0' }} elevation={1}>
            <PrebuiltFormNavigationControlls
                title={title}
                prevNode={prevNode}
                nextNode={nextNode}
                handleNavigateToNextStructure={handleNavigateToNextStructure}
            />
            {handleDisableSchema && (
                <FormControlLabel
                    control={<Switch onChange={handleDisableSchema} color="success" checked={isFormDisabled} />}
                    label={t('projectPage.disableSchema')}
                    sx={(theme) => ({ paddingLeft: theme.spacing(2) })}
                />
            )}
        </Card>
    )
}
