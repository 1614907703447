import React from 'react'
import { ProjectGoldenStandardEditMode } from './ProjectGoldenStandardEditMode'
import { Project } from 'redux/types/project.type'
import { ProjectGoldenStandardViewMode } from './ProjectGoldenStandardViewMode'

interface ProjectGoldenStandardTabProps {
    editable: boolean
    isEditMode: boolean
    onEditModeChange: (value: boolean) => void
    completed: boolean
    projectGoldenStandards: Project['goldenStandards']
}

export const ProjectGoldenStandardTab: React.FC<ProjectGoldenStandardTabProps> = ({
    editable,
    isEditMode,
    onEditModeChange,
    completed,
    projectGoldenStandards,
}) => {
    if ((editable && !isEditMode) || completed) {
        return (
            <ProjectGoldenStandardViewMode
                projectGoldenStandards={projectGoldenStandards || []}
                onEditModeChange={onEditModeChange}
                completed={completed}
            />
        )
    }

    return <ProjectGoldenStandardEditMode isEditMode={isEditMode} onEditModeChange={onEditModeChange} />
}
