import React from 'react'
import { ProjectDocumentsViewMode } from '../create/ProjectDocumentsViewMode'
import { useProjectView } from '../hooks/useProjectView'
import { Box, Card, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { FileService } from 'services/file.service'

export const ArchivedProjectFiles: React.FC = () => {
    const { t } = useTranslation()
    const { project, isCustomerView } = useProjectView()

    const getDownloadAllLink = () => {
        if (!project) return

        return FileService.BuildDownloadAllProjectFilesUrl(project)
    }

    if (!project) return null

    return (
        <Card sx={{ py: 2, px: 3, mb: 2, mt: 2 }}>
            <Box display="flex" justifyContent="space-between" mb={1.5}>
                <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.25rem', lineHeight: '1.5rem' }}>
                    {t('common.projectFiles')}
                </Typography>
                {(project.files.length > 0 || project.report) && (
                    <LoadingButton
                        type="button"
                        variant="outlined"
                        size="small"
                        sx={(theme) => ({
                            ml: 'auto',
                            maxHeight: 35,
                            '&:hover': {
                                background: theme.palette.primary.main,
                                color: '#FFF',
                            },
                        })}
                        component="a"
                        href={getDownloadAllLink()}
                    >
                        {t('common.downloadAll')}
                    </LoadingButton>
                )}
            </Box>
            <ProjectDocumentsViewMode
                files={project.files}
                completed={true}
                projectReport={project.report}
                isCustomerView={Boolean(isCustomerView)}
                projectBuildings={project.buildings}
            />
        </Card>
    )
}
