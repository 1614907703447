import React from 'react'
import { RenderView } from './RenderView'
import { Box } from '@mui/material'
import { JSONFormViewComp, SchemaElementTypeMap } from 'redux/types/schemaView.type'

export const HorizontalLayoutView: React.FC<JSONFormViewComp<SchemaElementTypeMap['HorizontalLayout']>> = React.memo(
    ({ node }) => {
        if (!node.isVisible || !node.isVisibleInWeb || node.nodes.length === 0) return null
        return (
            <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
                {node.nodes.map((childNode, idx) => (
                    <RenderView element={childNode} key={`horizontal-L-${idx}`} direction="row" />
                ))}
            </Box>
        )
    },
)
