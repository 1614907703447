import { AppBar, styled } from '@mui/material'
import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from 'constants/styles'

export const StyledAppBar = styled(AppBar)<{ secondary?: boolean }>(({ theme, secondary }) => ({
    display: 'flex',
    zIndex: 10,
    padding: theme.spacing(0, 2.5),
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: secondary ? '#333333' : theme.palette.primary.main,
    boxShadow: 'none',
    height: HEADER_HEIGHT,
    [theme.breakpoints.down('sm')]: {
        height: HEADER_HEIGHT_MOBILE,
    },
}))

export const ActionWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
})

export const SecondaryText = styled('div')(({ theme }) => ({
    color: '#FFF',
    marginLeft: theme.spacing(2.5),
    fontSize: '1.125rem',
}))

export const Filler = styled('div')({
    flexGrow: 1,
})

export const LogoTextContainer = styled('div')({
    fontSize: '1.125rem',
    fontWeight: 600,
    cursor: 'pointer',
    width: 'fit-content',
})

export const LogoContainer = styled('div')(({ theme }) => ({
    marginRight: 20,
    '& > img': {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '100%',
        maxWidth: 170,
        minWidth: 100,
    },

    [theme.breakpoints.down('sm')]: {
        '& > img': {
            maxWidth: 110,
            minWidth: 80,
        },
    },
}))

export const RightAction = styled('div')(({ theme }) => ({
    margin: theme.spacing(0, 1.25),
}))

export const LeftActionsWrapper = styled('div')(({ theme }) => ({
    marginLeft: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(2),
    },
}))

export const PreLogoActionsWrapper = styled('div')(({ theme }) => ({
    marginRight: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(2),
    },
}))
