import React from 'react'
import { FieldValues } from 'react-hook-form'
import { ControlledMuiTextField, TFormInputProps } from 'components/inputs/ControlledMuiTextField'
import { IconButton } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

export const ControlledPasswordInput = <T extends FieldValues>(props: Omit<TFormInputProps<T>, 'type'>) => {
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false)

    return (
        <ControlledMuiTextField
            {...props}
            type={isPasswordVisible ? 'text' : 'password'}
            InputProps={{
                endAdornment: isPasswordVisible ? (
                    <IconButton onClick={() => setIsPasswordVisible(false)} size="large" sx={{ padding: 1 }}>
                        <Visibility />
                    </IconButton>
                ) : (
                    <IconButton onClick={() => setIsPasswordVisible(true)} size="large" sx={{ padding: 1 }}>
                        <VisibilityOff />
                    </IconButton>
                ),
            }}
        />
    )
}
