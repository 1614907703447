import React from 'react'
import { GroupTableColSizeDefinition } from 'types/util'
import { TableRow } from '@mui/material'
import { StyledTableCell } from './styles'

type GroupTableHiddenRowProps = {
    columnDefinitions: Record<number, GroupTableColSizeDefinition>
    tableName: string
}

export const GroupTableHiddenRow: React.FC<GroupTableHiddenRowProps> = React.memo(
    ({ columnDefinitions, tableName }) => {
        return (
            <TableRow sx={{ visibility: 'collapse' }}>
                {Object.keys(columnDefinitions).map((_, idx) => {
                    return (
                        <StyledTableCell
                            key={`${tableName}-hidden-col-${idx}`}
                            sx={{
                                width:
                                    columnDefinitions[idx].size === 'auto'
                                        ? 'initial'
                                        : `${columnDefinitions[idx].size}%`,
                            }}
                        />
                    )
                })}
            </TableRow>
        )
    },
)
