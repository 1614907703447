import React from 'react'
import { Box, Table, TableBody, TableContainer, TableRow, Typography } from '@mui/material'
import { groupElementsByRowsWithLabel } from 'utils/forms-utils'
import { StyledTableCell } from './styles'
import { GroupTableHiddenRow } from './GroupTableHiddenRow'
import { ElementValueBlock } from './ElementValueBlock'
import { JSONFormViewComp, SchemaElementTypeMap } from 'redux/types/schemaView.type'

export const GroupTable: React.FC<JSONFormViewComp<SchemaElementTypeMap['groupTable']>> = React.memo(({ node }) => {
    if (!node.isVisible || !node.isVisibleInWeb || node.nodes.length === 0) return null

    const groupedNodes = groupElementsByRowsWithLabel(node.nodes, node.options.columnCount)

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {node.label && node.isLabelVisible && (
                <Typography sx={{ fontWeight: 600 }} variant="caption">
                    {node.label}
                </Typography>
            )}
            <TableContainer>
                <Table>
                    <TableBody>
                        {/* hidden row to set column widths */}
                        <GroupTableHiddenRow columnDefinitions={node.options.columnDefinitions} tableName={node.name} />
                        {groupedNodes.map((row, rowIdx) => (
                            <TableRow key={`row-${node.name}-${rowIdx}`}>
                                {row.map((cell, cellIdx) => (
                                    <React.Fragment key={`cell-${cell.name}-${rowIdx}-${cellIdx}`}>
                                        {'label' in cell && typeof cell.label === 'string' && (
                                            <StyledTableCell
                                                sx={{
                                                    '&::first-letter': {
                                                        textTransform: 'capitalize',
                                                    },
                                                }}
                                            >
                                                {cell.label}
                                            </StyledTableCell>
                                        )}
                                        <StyledTableCell>
                                            <ElementValueBlock
                                                value={cell.value}
                                                type={cell.type}
                                                isOrderedList={cell.type === 'list' && cell.isOrdered}
                                            />
                                        </StyledTableCell>
                                    </React.Fragment>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
})
