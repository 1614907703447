import { FormControl, TextField, TextFieldProps } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, { Dayjs } from 'dayjs'
import { FieldValues, Control, Path, Controller } from 'react-hook-form'
import 'dayjs/locale/nn'
import 'dayjs/locale/en'
import { useTranslation } from 'react-i18next'

interface ControlledMuiDateFieldProps<T extends FieldValues>
    extends Omit<DatePickerProps<unknown, Dayjs>, 'name' | 'error' | 'renderInput' | 'onChange' | 'value'> {
    control: Control<T>
    name: Path<T>
    label?: string
    fullWidth?: boolean
    inputProps?: TextFieldProps
}

export const ControlledMuiDateField = <T extends FieldValues>({
    control,
    name,
    label,
    fullWidth,
    inputProps,
    ...rest
}: ControlledMuiDateFieldProps<T>) => {
    const { t } = useTranslation()
    return (
        // todo when language selection is implemented pass to adapterlocale
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nn">
            <FormControl fullWidth={fullWidth}>
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { ref, onBlur, onChange, name, value, ...field }, fieldState }) => (
                        <DatePicker
                            {...field}
                            {...rest}
                            inputRef={ref}
                            label={label}
                            value={dayjs(value)}
                            onChange={(date: dayjs.Dayjs | null) => onChange(date?.toDate() || '')}
                            renderInput={(props) => (
                                <TextField
                                    {...inputProps}
                                    {...props}
                                    onBlur={onBlur}
                                    name={name}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message ? t(fieldState.error.message) : undefined}
                                />
                            )}
                        />
                    )}
                />
            </FormControl>
        </LocalizationProvider>
    )
}
