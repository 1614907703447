import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, styled, Divider } from '@mui/material'
import { LoadingButton } from '@mui/lab'

interface ActionProps {
    onCancel: () => void
    onSaveDraft: () => void
    isSubmitting: boolean
    isDraftBtnVisible: boolean
}

export const ProjectDetailsActions: React.FC<ActionProps> = React.memo(
    ({ onCancel, isSubmitting, onSaveDraft, isDraftBtnVisible }) => {
        const { t } = useTranslation()

        return (
            <>
                <Divider sx={{ my: 2 }} />
                <ButtonContainer>
                    <Button
                        variant="outlined"
                        color="secondary"
                        type="button"
                        onClick={onCancel}
                        disabled={isSubmitting}
                    >
                        {t('common.cancel')}
                    </Button>
                    {isDraftBtnVisible && (
                        <LoadingButton
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            sx={{ ml: 2 }}
                            onClick={onSaveDraft}
                        >
                            {t('projectPage.saveDraft')}
                        </LoadingButton>
                    )}
                    <LoadingButton
                        variant="contained"
                        color="secondary"
                        type="submit"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        sx={{ ml: 2 }}
                    >
                        {t('common.confirm')}
                    </LoadingButton>
                </ButtonContainer>
            </>
        )
    },
)

const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
}))
