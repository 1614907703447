import React from 'react'
import { ArrowBackIos } from '@mui/icons-material'
import { Box, Card, IconButton, Tab, Tabs, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { TopBar } from '../../components/general/top-bar/top-bar'
import { useGetCustomerQuery } from '../../services/customers.service'
import { PageContainer } from 'components/general/containers/PageContainer'
import { ROUTES } from 'constants/routes'
import { PageLoader } from 'components/general/loaders/PageLoader'
import { CustomerGeneralInformation } from 'modules/customersAdmin/customer/CustomerGeneralInformation'
import { ContactInfo } from 'modules/shared/contacts/ContactsInfo'
import { CustomerProjects } from 'modules/customersAdmin/customer/CustomerProjects'

export enum ECustomerTabs {
    General = 'General',
    Contacts = 'Contacts',
    CustomerProjects = 'CustomerProjects',
}

const CUSTOMER_TABS = [
    {
        label: 'customerPage.tabs.general',
        value: ECustomerTabs.General,
    },
    {
        label: 'customerPage.tabs.contacts',
        value: ECustomerTabs.Contacts,
    },
    {
        label: 'customerPage.tabs.customerProject',
        value: ECustomerTabs.CustomerProjects,
    },
]

export const CustomerPage: React.FC = () => {
    const { t } = useTranslation()
    const params = useParams()
    const customerId = params.id!
    const { data: customer, isLoading, isSuccess } = useGetCustomerQuery(customerId)

    const [activeTab, setActiveTab] = React.useState<ECustomerTabs>(ECustomerTabs.General)

    if (isLoading) return <PageLoader />
    if (!isSuccess) return null

    return (
        <>
            <TopBar />
            <PageContainer>
                <Card
                    sx={{
                        mb: 2,
                        padding: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box display="flex" alignItems="center">
                        <IconButton to={ROUTES.customers} component={Link}>
                            <ArrowBackIos />
                        </IconButton>
                        <Typography
                            sx={{ textDecoration: 'none', color: '#000', ml: 1 }}
                            variant="body1"
                            component={Link}
                            to={ROUTES.customers}
                        >
                            {t('customerPage.backToCustomers')}
                        </Typography>
                    </Box>

                    <div>
                        <Typography variant="h5">{customer.name}</Typography>
                    </div>
                </Card>
                <Tabs value={activeTab} onChange={(_, newTab) => setActiveTab(newTab)}>
                    {CUSTOMER_TABS.map((tab) => {
                        return <Tab key={tab.value} value={tab.value} label={t(tab.label)} />
                    })}
                </Tabs>
                {activeTab === ECustomerTabs.General && <CustomerGeneralInformation customer={customer} />}
                {activeTab === ECustomerTabs.Contacts && <ContactInfo customer={customer} contactType="customer" />}
                {activeTab === ECustomerTabs.CustomerProjects && <CustomerProjects customer={customer} />}
            </PageContainer>
        </>
    )
}
