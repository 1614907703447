import React from 'react'
import { ArrowBackIos } from '@mui/icons-material'
import { Box, Card, IconButton, Tab, Tabs, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { TopBar } from '../../components/general/top-bar/top-bar'
import { PageContainer } from 'components/general/containers/PageContainer'
import { ROUTES } from 'constants/routes'
import { PageLoader } from 'components/general/loaders/PageLoader'
import { useGetOrganizationQuery } from 'services/organizations.service'
import { OrganizationGeneralInfo } from 'modules/organizations/organization/OrganizationGeneralInfo'
import { ContactInfo } from 'modules/shared/contacts/ContactsInfo'
import { OrganizationPermissionInfo } from 'modules/organizations/organization/OrganizationPermissionInfo'

export enum EOrganizationTabs {
    General = 'General',
    Contacts = 'Contacts',
    Permissions = 'Permissions',
}

const ORGANIZATION_TABS = [
    {
        label: 'organizationPage.tabs.general',
        value: EOrganizationTabs.General,
        defaultTranslation: 'Project data',
    },
    {
        label: 'organizationPage.tabs.contacts',
        value: EOrganizationTabs.Contacts,
        defaultTranslation: 'Documents',
    },
    {
        label: 'organizationPage.tabs.permissions',
        value: EOrganizationTabs.Permissions,
        defaultTranslation: 'Building information',
        renderAfterCreateMode: true,
    },
]

export const OrganizationPage: React.FC = () => {
    const { t } = useTranslation()
    const params = useParams()
    const orgId = params.id!
    const { data: organization, isLoading, isSuccess } = useGetOrganizationQuery({ id: orgId })

    const [activeTab, setActiveTab] = React.useState<EOrganizationTabs>(EOrganizationTabs.General)

    if (isLoading) return <PageLoader />
    if (!isSuccess) return null

    return (
        <>
            <TopBar />
            <PageContainer>
                <Card
                    sx={{
                        mb: 2,
                        padding: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box display="flex" alignItems="center">
                        <IconButton to={ROUTES.organizations} component={Link}>
                            <ArrowBackIos />
                        </IconButton>
                        <Typography
                            sx={{ textDecoration: 'none', color: '#000', ml: 1 }}
                            variant="body1"
                            component={Link}
                            to={ROUTES.organizations}
                        >
                            {t('organizationPage.backToOrganizations')}
                        </Typography>
                    </Box>

                    <div>
                        <Typography variant="h5">{organization.name}</Typography>
                    </div>
                </Card>
                <Tabs value={activeTab} onChange={(_, newTab) => setActiveTab(newTab)}>
                    {ORGANIZATION_TABS.map((tab) => {
                        return <Tab key={tab.value} value={tab.value} label={t(tab.label, tab.defaultTranslation)} />
                    })}
                </Tabs>
                {activeTab === EOrganizationTabs.General && <OrganizationGeneralInfo organization={organization} />}
                {activeTab === EOrganizationTabs.Contacts && (
                    <ContactInfo organization={organization} contactType="organization" />
                )}
                {activeTab === EOrganizationTabs.Permissions && (
                    <OrganizationPermissionInfo
                        organizationBuildings={organization.buildings}
                        organizationId={organization.id}
                    />
                )}
            </PageContainer>
        </>
    )
}
