import { Divider, Grid, styled } from '@mui/material'
import Button from '@mui/material/Button'
import * as O from 'optics-ts'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import ModalsWrapper from 'components/general/dialogs/DialogWrapper'
import { FieldType, SchemaElement } from 'redux/types/schema.type'
import { selectBuildingPartsOptionsFlat, selectDisciplinesOptions } from 'redux/selectors/settings.selectors'
import { useAppSelector } from 'redux/hooks'
import { DATE_FORMATING_LOCALE } from 'constants/locales'

const HIDE_GROUP_FIELD_IN: Partial<FieldType>[] = ['Group', 'array', 'HorizontalLayout', 'groupTable']
// const HIDE_VERTICAL_FIELD_IN: Partial<FieldType>[] = ['VerticalLayout', 'array', 'groupTable']
const HIDE_HORIZONTAL_FIELD_IN: Partial<FieldType>[] = ['HorizontalLayout', 'array', 'groupTable']
const HIDE_ARRAY_FIELD_IN: Partial<FieldType>[] = ['HorizontalLayout', 'array', 'groupTable']
const HIDE_LABEL_FIELD_IN: Partial<FieldType>[] = ['array', 'groupTable']
const HIDE_GROUP_TABLE_IN: Partial<FieldType>[] = ['array', 'groupTable', 'HorizontalLayout']
const HIDE_DYNAMIC_TABLE_IN: Partial<FieldType>[] = ['array', 'groupTable', 'HorizontalLayout']

type Props = {
    nodesLens: any
    setState: (x: any) => any
    parentField?: FieldType
}

export const AddFieldDialog: React.FC<Props> = ({ nodesLens, setState, parentField }) => {
    const { t } = useTranslation()
    const disciplinesOptions = useAppSelector(selectDisciplinesOptions)
    const buildingParts = useAppSelector(selectBuildingPartsOptionsFlat)

    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const append = (node: SchemaElement) => {
        setState(
            O.modify(nodesLens)((x) => {
                const nodes = x as SchemaElement[]
                return [...nodes, node]
            }),
        )
    }
    // see comment in jsx
    // const addVerticalLayout = () => {
    //     append({
    //         type: 'VerticalLayout',
    //         nodes: [],
    //     })
    // }

    const addHorizontalLayout = () => {
        append({
            type: 'HorizontalLayout',
            nodes: [],
            options: {
                fieldName: new Date().getTime().toString(),
            },
        })
    }

    const addGroupLayout = () => {
        append({
            type: 'Group',
            name: '',
            label: '',
            nodes: [],
            options: {
                hideLabel: false,
                hideInPdf: false,
                fieldName: '',
            },
        })
    }

    const addGroupTableLayout = () => {
        append({
            type: 'groupTable',
            name: '',
            label: '',
            nodes: [],
            options: {
                uniqueValue: true,
                columnDefinitions: {},
                columnCount: 2,
                hideLabel: false,
                hideInPdf: false,
                fieldName: '',
            },
        })
    }

    const addDynamicTableLayout = () => {
        append({
            type: 'dynamicTable',
            name: '',
            label: '',
            nodes: [],
            options: {
                columnCount: 2,
                columnDefinitions: {},
                dynamicTable: true,
                hideLabel: false,
                hideInPdf: false,
                fieldName: '',
            },
            isGoldenStandard: false,
        })
    }

    const addArray = () => {
        append({
            type: 'array',
            name: '',
            label: '',
            nodes: [],
            options: {
                showSortButtons: false,
                columnDefinitions: {},
                hideLabel: false,
                hideInPdf: false,
                fieldName: '',
            },
        })
    }
    const addList = () => {
        append({
            type: 'list',
            name: '',
            label: '',
            element: 'string',
            options: { hideLabel: false, listType: 'ordered', isList: true, hideInPdf: false, fieldName: '' },
        })
    }
    const addString = () => {
        append({
            type: 'string',
            name: '',
            label: '',
            required: false,
            isGoldenStandard: false,
            options: { multi: false, hideLabel: false, hideInPdf: false, fieldName: '' },
        })
    }
    const addTextField = () => {
        append({
            type: 'string',
            name: '',
            label: '',
            required: false,
            isGoldenStandard: false,
            options: { multi: true, hideLabel: false, hideInPdf: false, fieldName: '' },
        })
    }
    const addDate = () => {
        append({
            type: 'date',
            name: '',
            label: '',
            required: false,
            isGoldenStandard: false,
            options: {
                hideLabel: false,
                hideInPdf: false,
                fieldName: '',
                dateFormat: DATE_FORMATING_LOCALE.no.yearDisplay,
            },
        })
    }
    // const addFile = () => {
    //     append({ type: 'file', name: '', label: '', isGoldenStandard: false })
    // }
    const addNumber = () => {
        append({
            type: 'number',
            name: '',
            label: '',
            required: false,
            isGoldenStandard: false,
            options: {
                hideLabel: false,
                hideInPdf: false,
                fieldName: '',
            },
        })
    }
    const addBoolean = () => {
        append({
            type: 'boolean',
            name: '',
            label: '',
            isGoldenStandard: false,
            options: {
                hideLabel: false,
                hideInPdf: false,
                fieldName: '',
            },
        })
    }
    const addLabel = () => {
        append({ type: 'Label', text: '' })
    }
    const addSelect = () => {
        append({
            type: 'select',
            name: '',
            label: '',
            enum: ['option1'],
            isGoldenStandard: false,
            options: {
                hideLabel: false,
                hideInPdf: false,
                fieldName: '',
            },
        })
    }

    const addDisciplinesTagField = () => {
        append({
            type: 'disciplinesTag',
            name: '',
            label: '',
            enum: disciplinesOptions,
            options: {
                hideLabel: false,
                disciplinesTag: true,
                selectOptions: [],
                hideInPdf: false,
                fieldName: '',
            },
        })
    }

    const addBuildingPartField = () => {
        append({
            type: 'buildingPart',
            name: '',
            label: '',
            enum: buildingParts,
            options: { hideLabel: false, buildingPart: true, selectOptions: [], hideInPdf: false, fieldName: '' },
        })
    }

    const addRadio = () => {
        append({
            type: 'radio',
            name: '',
            label: '',
            enum: ['option1'],
            isGoldenStandard: false,
            options: {
                hideLabel: false,
                hideInPdf: false,
                fieldName: '',
            },
        })
    }

    const addCheckbox = () => {
        append({
            type: 'checkbox',
            name: '',
            label: '',
            enum: ['option1'],
            isGoldenStandard: false,
            options: {
                hideLabel: false,
                hideInPdf: false,
                fieldName: '',
            },
        })
    }

    return (
        <>
            <Button variant="outlined" onClick={handleClickOpen}>
                {t('schemaPage.addFieldDialogTitle')}
            </Button>
            <ModalsWrapper
                sx={{
                    zIndex: 9999,
                }}
                open={open}
                title={t('schemaPage.addNewField')}
                handleClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                body={
                    <>
                        <Grid container spacing={2}>
                            {(!parentField || !HIDE_ARRAY_FIELD_IN.includes(parentField)) && (
                                <Grid item xs={6}>
                                    <StyledButton variant="outlined" onClick={addArray}>
                                        {t('fields.array')}
                                    </StyledButton>
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                <StyledButton variant="outlined" onClick={addList}>
                                    {t('fields.list')}
                                </StyledButton>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledButton variant="outlined" onClick={addString}>
                                    {t('fields.string')}
                                </StyledButton>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledButton variant="outlined" onClick={addTextField}>
                                    {t('fields.textfield')}
                                </StyledButton>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledButton variant="outlined" onClick={addNumber}>
                                    {t('fields.number')}
                                </StyledButton>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledButton variant="outlined" onClick={addBoolean}>
                                    {t('fields.bool')}
                                </StyledButton>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledButton variant="outlined" onClick={addSelect}>
                                    {t('fields.select')}
                                </StyledButton>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledButton variant="outlined" onClick={addRadio}>
                                    {t('fields.radio')}
                                </StyledButton>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledButton variant="outlined" onClick={addCheckbox}>
                                    {t('fields.checkbox')}
                                </StyledButton>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledButton variant="outlined" onClick={addDate}>
                                    {t('fields.date')}
                                </StyledButton>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledButton variant="outlined" onClick={addDisciplinesTagField}>
                                    {t('fields.disciplinesTag')}
                                </StyledButton>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledButton variant="outlined" onClick={addBuildingPartField}>
                                    {t('fields.buildingPart')}
                                </StyledButton>
                            </Grid>
                            {/* temp disabled 2023-07-13 */}
                            {/* <Grid item xs={6}>
                                <StyledButton variant="outlined" onClick={addFile}>
                                    {t('fields.file')}
                                </StyledButton>
                            </Grid> */}
                        </Grid>
                        <Divider sx={{ mt: 4 }} />
                        <Grid container spacing={2} mt={2}>
                            {(!parentField || !HIDE_GROUP_FIELD_IN.includes(parentField)) && (
                                <Grid item xs={6}>
                                    <StyledButton variant="outlined" onClick={addGroupLayout}>
                                        {t('fields.group')}
                                    </StyledButton>
                                </Grid>
                            )}
                            {(!parentField || !HIDE_GROUP_TABLE_IN.includes(parentField)) && (
                                <Grid item xs={6}>
                                    <StyledButton variant="outlined" onClick={addGroupTableLayout}>
                                        {t('fields.uniqueGroupTable')}
                                    </StyledButton>
                                </Grid>
                            )}
                            {(!parentField || !HIDE_DYNAMIC_TABLE_IN.includes(parentField)) && (
                                <Grid item xs={6}>
                                    <StyledButton variant="outlined" onClick={addDynamicTableLayout}>
                                        {t('fields.dynamicTable')}
                                    </StyledButton>
                                </Grid>
                            )}
                            {/* commented out vertical layout for now, because we cant fint use case (2023-09-28) not supported */}
                            {/* {(!parentField || !HIDE_VERTICAL_FIELD_IN.includes(parentField)) && (
                                <Grid item xs={6}>
                                    <StyledButton variant="outlined" onClick={addVerticalLayout}>
                                        {t('fields.verticalLayout')}
                                    </StyledButton>
                                </Grid>
                            )} */}
                            {(!parentField || !HIDE_HORIZONTAL_FIELD_IN.includes(parentField)) && (
                                <Grid item xs={6}>
                                    <StyledButton variant="outlined" onClick={addHorizontalLayout}>
                                        {t('fields.horizontalLayout')}
                                    </StyledButton>
                                </Grid>
                            )}
                            {(!parentField || !HIDE_LABEL_FIELD_IN.includes(parentField)) && (
                                <Grid item xs={6}>
                                    <StyledButton variant="outlined" onClick={addLabel}>
                                        {t('fields.label')}
                                    </StyledButton>
                                </Grid>
                            )}
                        </Grid>
                    </>
                }
                actions={<Button onClick={handleClose}>{t('common.cancel')}</Button>}
            />
        </>
    )
}

const StyledButton = styled(Button)({
    minWidth: 210,
})
