import { RuleEffect } from '@jsonforms/core'
import { Add, Close } from '@mui/icons-material'
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
} from '@mui/material'
import { TFunction } from 'i18next'
import * as O from 'optics-ts'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RuleField, getSchemaRuleFields } from '../../../utils/ui-schema-converter'
import { SchemaElement } from 'redux/types/schema.type'
import {
    Condition,
    ConditionType,
    ConditionTypes,
    FieldRule,
    FieldRuleType,
    FieldRuleTypes,
} from 'redux/types/schemaRule.type'

type Props = {
    schema: SchemaElement
    setRule: any
    existingRule?: FieldRule
}

const checkboxSelected = (selected: any, value: string): boolean => {
    return Array.isArray(selected) && selected.includes(value)
}

const selectCheckbox = (selected: any, value: string, isSelected: boolean) => {
    if (!Array.isArray(selected)) {
        return isSelected ? [value] : []
    }

    if (isSelected) {
        return [...selected, value]
    } else {
        return selected.filter((x) => x !== value)
    }
}

type FieldConditionOption = {
    label: string
    value: ConditionType
}

const getFieldConditionTypes = (field: SchemaElement, t: TFunction): FieldConditionOption[] => {
    if (field.type === 'checkbox') {
        return [
            { label: t('selected_all', 'Selected all'), value: ConditionTypes.Contains },
            { label: t('selected_none', 'Selected none'), value: ConditionTypes.NotContains },
        ]
    }

    return [
        { label: t('equal', 'Equal'), value: ConditionTypes.Const },
        { label: t('not_equal', 'Not equal'), value: ConditionTypes.NotConst },
        { label: t('regex_pattern', 'Regex'), value: ConditionTypes.Pattern },
    ]
}

export const RulesEditor: React.FC<Props> = ({ schema, setRule, existingRule }) => {
    const { t } = useTranslation()

    const [state, setState] = useState<FieldRule>(
        existingRule ?? {
            effect: RuleEffect.SHOW,
            type: FieldRuleTypes.And,
            conditions: [],
        },
    )

    const conditionsLens = O.optic<FieldRule>().prop('conditions')
    const effectLens = O.optic<FieldRule>().prop('effect')
    const typeLens = O.optic<FieldRule>().prop('type')

    const setEffect = (value: RuleEffect) => {
        setState(O.set(effectLens)(value))
    }

    const setType = (value: FieldRuleType) => {
        setState(O.set(typeLens)(value))
    }

    const fields = getSchemaRuleFields(schema)

    const append = (field: RuleField) => {
        const condition: Condition = { field, value: undefined, type: ConditionTypes.Const }
        setState(
            O.modify(conditionsLens)((x) => {
                const nodes = x as Condition[]
                return [...nodes, condition]
            }),
        )
    }

    const addField = () => {
        append(fields[0])
    }
    useEffect(() => {
        setRule(state)
    }, [state, setRule])

    return (
        <Stack spacing={2} sx={{ maxWidth: 400 }}>
            <Stack spacing={2} direction="row">
                <FormControl fullWidth>
                    <InputLabel id="effect-label">{t('Effect')}</InputLabel>
                    <Select
                        labelId="effect-label"
                        id="effect-select"
                        value={state.effect}
                        label="Effect"
                        onChange={(e) => setEffect(e.target.value as RuleEffect)}
                    >
                        <MenuItem value={RuleEffect.SHOW}>{t('Show')}</MenuItem>
                        <MenuItem value={RuleEffect.HIDE}>{t('Hide')}</MenuItem>
                        <MenuItem value={RuleEffect.ENABLE}>{t('Enable')}</MenuItem>
                        <MenuItem value={RuleEffect.DISABLE}>{t('Disable')}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="effect-label">{t('ruleType')}</InputLabel>
                    <Select
                        labelId="type-label"
                        id="type-select"
                        value={state.type}
                        label="Effect"
                        onChange={(e) => setType(e.target.value as FieldRuleType)}
                    >
                        <MenuItem value={FieldRuleTypes.And}>{t('All of (AND)')}</MenuItem>
                        <MenuItem value={FieldRuleTypes.Or}>{t('Any of (OR)')}</MenuItem>
                    </Select>
                </FormControl>
            </Stack>

            {state.conditions.map((condition: Condition, i: number) => {
                const conditionLens = conditionsLens.at(i)
                const fieldLens = conditionLens.prop('field')
                const valueLens = conditionLens.prop('value')
                const typeLens = conditionLens.prop('type')
                const fieldConditions = getFieldConditionTypes(condition.field.node, t)

                const handleChange = (event: any) => {
                    const newOption = fields.find((option) => option.name === event.target.value)!
                    setState(O.set(fieldLens)(newOption))
                }

                const setConditionType = (value: ConditionType) => {
                    setState(O.set(typeLens)(value))
                }

                return (
                    <React.Fragment key={i}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                                marginTop: 32,
                            }}
                        >
                            <div style={{ flexGrow: 1, marginRight: 16 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="field-label">{t('Field')}</InputLabel>
                                    <Select
                                        labelId="field-label"
                                        id="field-select"
                                        value={condition.field.name}
                                        label="Field"
                                        onChange={handleChange}
                                    >
                                        {fields.map((x, i) => (
                                            <MenuItem key={i} value={x.name}>
                                                {x.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <InputLabel id="field-type-label">{t('ruleType')}</InputLabel>
                                    <Select
                                        labelId="field-type-label"
                                        id="field-type-select"
                                        value={condition.type}
                                        label="Type"
                                        onChange={(e) => setConditionType(e.target.value as ConditionType)}
                                    >
                                        {fieldConditions.map((x, i) => (
                                            <MenuItem key={i} value={x.value}>
                                                {x.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {condition.field.node.type === 'string' ? (
                                    <TextField
                                        sx={{ mt: 1 }}
                                        fullWidth
                                        label="Value"
                                        variant="outlined"
                                        value={condition.value}
                                        onChange={(x) => setState(O.set(valueLens)(x.target.value))}
                                    />
                                ) : null}

                                {condition.field.node.type === 'number' ? (
                                    <TextField
                                        sx={{ mt: 1 }}
                                        fullWidth
                                        label="Value"
                                        variant="outlined"
                                        type="number"
                                        value={condition.value}
                                        onChange={(x) => setState(O.set(valueLens)(parseInt(x.target.value, 10)))}
                                    />
                                ) : null}

                                {condition.field.node.type === 'boolean' ? (
                                    <FormControlLabel
                                        sx={{ mt: 1 }}
                                        control={
                                            <Checkbox
                                                checked={condition.value as boolean}
                                                onChange={(x) => setState(O.set(valueLens)(x.target.checked))}
                                            />
                                        }
                                        label="Is selected?"
                                    />
                                ) : null}

                                {condition.field.node.type === 'date' ? (
                                    <TextField
                                        sx={{ mt: 1 }}
                                        fullWidth
                                        label="Value"
                                        variant="outlined"
                                        value={condition.value}
                                        onChange={(x) => setState(O.set(valueLens)(x.target.value))}
                                    />
                                ) : null}

                                {condition.field.node.type === 'radio' ? (
                                    <FormControl sx={{ mt: 1 }}>
                                        <RadioGroup
                                            value={condition.value}
                                            onChange={(x) => setState(O.set(valueLens)(x.target.value))}
                                        >
                                            {condition.field.node.enum.map((x) => (
                                                <FormControlLabel value={x} control={<Radio />} label={x} />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                ) : null}

                                {condition.field.node.type === 'checkbox' ? (
                                    <FormControl sx={{ mt: 1 }}>
                                        {condition.field.node.enum.map((x) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkboxSelected(condition.value, x)}
                                                        onChange={(e) =>
                                                            setState(
                                                                O.set(valueLens)(
                                                                    selectCheckbox(
                                                                        condition.value,
                                                                        x,
                                                                        e.target.checked,
                                                                    ),
                                                                ),
                                                            )
                                                        }
                                                    />
                                                }
                                                label={x}
                                            />
                                        ))}
                                    </FormControl>
                                ) : null}
                            </div>
                            <div>
                                <IconButton onClick={(x) => setState(O.remove(conditionLens))}>
                                    <Close />
                                </IconButton>
                            </div>
                        </div>
                    </React.Fragment>
                )
            })}
            <Button style={{ marginTop: 16 }} onClick={addField} startIcon={<Add />}>
                {t('Add new rule')}
            </Button>
        </Stack>
    )
}
