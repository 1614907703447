import React from 'react'
import { IconButton, IconButtonProps } from '@mui/material'

type HeaderIconProps = {
    icon: React.ReactNode
} & IconButtonProps

export const HeaderIconButton: React.FC<HeaderIconProps> = ({ icon, ...rest }) => {
    return (
        <IconButton
            sx={(theme) => ({
                '&:hover': {
                    background: theme.palette.primary.light,
                },
                '&:disabled': {
                    color: theme.palette.primary.light,
                },
            })}
            size="small"
            edge="start"
            color="inherit"
            {...rest}
        >
            {icon}
        </IconButton>
    )
}
