import { TextField } from '@mui/material'
import * as O from 'optics-ts'
import React from 'react'
import { RulesEditor } from '../rules/rules-editor'
import { useName, useRule } from '../hooks/schema-hooks'
import { BuilderFieldProps } from './builder-field-props'
import { Draggable } from './droppable'
import { FieldDialog } from './field-dialog'
import { FieldItem } from './field-item'
import { NameLabel } from './general/field-name-label'
import { TabPanel } from './general/field-tab-panel'
import { FieldTabs } from './general/field-tabs'
import {
    BooleanSchemaElement,
    DateSchemaElement,
    FileSchemaElement,
    LabelSchemaElement,
    NumberSchemaElement,
    StringSchemaElement,
} from 'redux/types/schema.type'

export const BuilderString: React.FC<BuilderFieldProps<StringSchemaElement>> = ({
    node,
    state,
    setState,
    lens,
    path,
    droppable,
}) => {
    const nameHook = useName(lens, state, setState)
    const rule = useRule(lens, state, setState)
    const [tabIndex, setTabIndex] = React.useState(0)
    const remove = () => setState(O.remove(lens)(state))

    if (!droppable) {
        return (
            <>
                <FieldItem
                    node={node}
                    nameValue={nameHook.name}
                    hasRules={rule.hasRules}
                    isGoldenStandard={nameHook.isGoldenStard}
                />
                <FieldDialog node={node} remove={remove} onSave={rule.onSaveRules}>
                    <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                    <TabPanel value={tabIndex} index={0}>
                        <NameLabel nameHook={nameHook} fieldType={node.type} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                    </TabPanel>
                </FieldDialog>
            </>
        )
    }

    return (
        <Draggable id={`${path}`} styling={{ alignItems: 'center' }}>
            <FieldItem
                node={node}
                nameValue={nameHook.name}
                hasRules={rule.hasRules}
                isGoldenStandard={nameHook.isGoldenStard}
            />
            <FieldDialog node={node} remove={remove} onSave={rule.onSaveRules}>
                <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                <TabPanel value={tabIndex} index={0}>
                    <NameLabel nameHook={nameHook} fieldType={node.type} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                </TabPanel>
            </FieldDialog>
        </Draggable>
    )
}

export const BuilderBoolean: React.FC<BuilderFieldProps<BooleanSchemaElement>> = ({
    node,
    state,
    setState,
    lens,
    path,
    droppable,
}) => {
    const nameHook = useName(lens, state, setState)
    const rule = useRule(lens, state, setState)
    const [tabIndex, setTabIndex] = React.useState(0)
    const remove = () => setState(O.remove(lens)(state))

    if (!droppable) {
        return (
            <>
                <FieldItem
                    node={node}
                    nameValue={nameHook.name}
                    hasRules={rule.hasRules}
                    isGoldenStandard={nameHook.isGoldenStard}
                />
                <FieldDialog node={node} remove={remove} onSave={rule.onSaveRules}>
                    <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                    <TabPanel value={tabIndex} index={0}>
                        <NameLabel nameHook={nameHook} fieldType={node.type} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                    </TabPanel>
                </FieldDialog>
            </>
        )
    }

    return (
        <Draggable id={`${path}`} styling={{ alignItems: 'center' }}>
            <FieldItem
                node={node}
                nameValue={nameHook.name}
                hasRules={rule.hasRules}
                isGoldenStandard={nameHook.isGoldenStard}
            />
            <FieldDialog node={node} remove={remove} onSave={rule.onSaveRules}>
                <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                <TabPanel value={tabIndex} index={0}>
                    <NameLabel nameHook={nameHook} fieldType={node.type} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                </TabPanel>
            </FieldDialog>
        </Draggable>
    )
}

export const BuilderNumber: React.FC<BuilderFieldProps<NumberSchemaElement>> = ({
    node,
    state,
    setState,
    lens,
    path,
    droppable,
}) => {
    const nameHook = useName(lens, state, setState)
    const rule = useRule(lens, state, setState)
    const [tabIndex, setTabIndex] = React.useState(0)
    const remove = () => setState(O.remove(lens)(state))

    if (!droppable) {
        return (
            <>
                <FieldItem
                    node={node}
                    nameValue={nameHook.name}
                    hasRules={rule.hasRules}
                    isGoldenStandard={nameHook.isGoldenStard}
                />
                <FieldDialog node={node} remove={remove} onSave={rule.onSaveRules}>
                    <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                    <TabPanel value={tabIndex} index={0}>
                        <NameLabel nameHook={nameHook} fieldType={node.type} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                    </TabPanel>
                </FieldDialog>
            </>
        )
    }

    return (
        <Draggable id={`${path}`} styling={{ alignItems: 'center' }}>
            <FieldItem
                node={node}
                nameValue={nameHook.name}
                hasRules={rule.hasRules}
                isGoldenStandard={nameHook.isGoldenStard}
            />
            <FieldDialog node={node} remove={remove} onSave={rule.onSaveRules}>
                <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                <TabPanel value={tabIndex} index={0}>
                    <NameLabel nameHook={nameHook} fieldType={node.type} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                </TabPanel>
            </FieldDialog>
        </Draggable>
    )
}

export const BuilderDate: React.FC<BuilderFieldProps<DateSchemaElement>> = ({
    node,
    state,
    setState,
    lens,
    path,
    droppable,
}) => {
    const nameHook = useName(lens, state, setState)
    const rule = useRule(lens, state, setState)
    const [tabIndex, setTabIndex] = React.useState(0)
    const remove = () => setState(O.remove(lens)(state))

    if (!droppable) {
        return (
            <>
                <FieldItem
                    node={node}
                    nameValue={nameHook.name}
                    hasRules={rule.hasRules}
                    isGoldenStandard={nameHook.isGoldenStard}
                />
                <FieldDialog node={node} remove={remove} onSave={rule.onSaveRules}>
                    <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                    <TabPanel value={tabIndex} index={0}>
                        <NameLabel nameHook={nameHook} fieldType={node.type} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                    </TabPanel>
                </FieldDialog>
            </>
        )
    }

    return (
        <Draggable id={`${path}`} styling={{ alignItems: 'center' }}>
            <FieldItem
                node={node}
                nameValue={nameHook.name}
                hasRules={rule.hasRules}
                isGoldenStandard={nameHook.isGoldenStard}
            />
            <FieldDialog node={node} remove={remove} onSave={rule.onSaveRules}>
                <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                <TabPanel value={tabIndex} index={0}>
                    <NameLabel nameHook={nameHook} fieldType={node.type} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                </TabPanel>
            </FieldDialog>
        </Draggable>
    )
}

export const BuilderFilePicker: React.FC<BuilderFieldProps<FileSchemaElement>> = ({
    node,
    state,
    setState,
    lens,
    path,
}) => {
    const nameHook = useName(lens, state, setState)
    const rule = useRule(lens, state, setState)
    const [tabIndex, setTabIndex] = React.useState(0)
    const remove = () => setState(O.remove(lens)(state))

    return (
        <Draggable id={`${path}`} styling={{ alignItems: 'center' }}>
            <FieldItem
                node={node}
                nameValue={nameHook.name}
                hasRules={rule.hasRules}
                isGoldenStandard={nameHook.isGoldenStard}
            />
            <FieldDialog node={node} remove={remove} onSave={rule.onSaveRules}>
                <FieldTabs value={tabIndex} handleChange={setTabIndex} />

                <TabPanel value={tabIndex} index={0}>
                    <NameLabel nameHook={nameHook} fieldType={node.type} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                </TabPanel>
            </FieldDialog>
        </Draggable>
    )
}

export const BuilderLabel: React.FC<BuilderFieldProps<LabelSchemaElement>> = ({
    node,
    state,
    setState,
    lens,
    path,
}) => {
    const rule = useRule(lens, state, setState)
    const textLens = lens.prop('text')
    const [tabIndex, setTabIndex] = React.useState(0)
    const labelValue = O.get(textLens)(state)
    const remove = () => setState(O.remove(lens)(state))

    return (
        <Draggable id={`${path}`} styling={{ alignItems: 'center' }}>
            <FieldItem node={node} nameValue={labelValue} hasRules={rule.hasRules} />
            <FieldDialog node={node} remove={remove} onSave={rule.onSaveRules}>
                <FieldTabs value={tabIndex} handleChange={setTabIndex} />
                <TabPanel value={tabIndex} index={0}>
                    <TextField
                        style={{ marginTop: '10px' }}
                        value={labelValue}
                        id="outlined-basic"
                        label="Label"
                        variant="outlined"
                        onChange={(e) => setState(O.set(textLens)(e.target.value))}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <RulesEditor schema={state} existingRule={rule.rule} setRule={rule.setRuleValue} />
                </TabPanel>
            </FieldDialog>
        </Draggable>
    )
}
