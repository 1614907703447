import React from 'react'
import { Alert, Grid, Hidden } from '@mui/material'
import { Buildings } from './Buildings'
import { PrebuiltFormNavigationFilterable } from './PrebuiltFormNavigationFilterable'
import { PrebuiltFormView } from './PrebuiltFormView'
import { Details } from './Details'
import { PrebuiltFormGridContainer } from '../shared/PrebuiltFormGridContainer'
import { PrebuiltFormNavigationFilterableMobile } from './PrebuiltFormNavigationFilterableMobile'
import { useTranslation } from 'react-i18next'

type ProjectReadViewProps = {
    isNavigationDrawerOpen: boolean
    onDrawerToggle: (open: boolean) => void
    isNotificationHidden?: boolean
}

export const ProjectReadView: React.FC<ProjectReadViewProps> = ({
    onDrawerToggle,
    isNavigationDrawerOpen,
    isNotificationHidden,
}) => {
    const { t } = useTranslation()

    return (
        <>
            {!isNotificationHidden && (
                <Alert sx={{ mb: 2 }} severity="info">
                    {t('projectPage.infoAboutPreview')}
                </Alert>
            )}
            <Details />
            <Buildings />

            <PrebuiltFormGridContainer>
                <Hidden mdDown>
                    <Grid item xs={12} md={4}>
                        <PrebuiltFormNavigationFilterable />
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={8}>
                    <PrebuiltFormView />
                </Grid>
            </PrebuiltFormGridContainer>
            <PrebuiltFormNavigationFilterableMobile
                onDrawerToggle={onDrawerToggle}
                isNavigationDrawerOpen={isNavigationDrawerOpen}
            />
        </>
    )
}
