import { EditSchemaPage } from 'pages/schemas/edit-schema.page'
import { SchemaBuilderPage } from 'pages/schemas/schema-builder.page'
import { SchemasPage } from 'pages/schemas/schemas.page'
import { ROUTES } from 'constants/routes'
import { generateNestedRouteUrl } from 'utils/navigation-utils'

const schemasRoutes = [
    {
        path: '',
        element: <SchemasPage />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.schemaAdd, ROUTES.schemas),
        element: <SchemaBuilderPage />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.schemaEdit, ROUTES.schemas),
        element: <EditSchemaPage />,
    },
]

export default schemasRoutes
