import React from 'react'
import { Button, Stack, styled } from '@mui/material'

import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { useTranslation } from 'react-i18next'
import { Building } from 'redux/types/building.type'
import { BuildingInformation } from 'modules/buildings/building/BuildingInformation'

interface PublicBuildingViewDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions' | 'open'> {
    building: Building | null
}

export const PublicBuildingViewDialog: React.FC<PublicBuildingViewDialogProps> = ({ building, handleClose }) => {
    const { t } = useTranslation()

    if (!building) return null

    return (
        <ModalsWrapper
            open={Boolean(building)}
            handleClose={handleClose}
            fullWidth={true}
            maxWidth="lg"
            body={
                <ContentWrapper>
                    <BuildingInformation building={building} />
                </ContentWrapper>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button variant="outlined" onClick={handleClose} color="secondary">
                        {t('common.cancel')}
                    </Button>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
