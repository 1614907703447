import React from 'react'
import { Drawer as MuiDrawer, Toolbar } from '@mui/material'

import { DRAWER_WIDTH } from 'constants/styles'

interface DrawerProps {
    children: React.ReactNode
    customWidth?: number
}
/**
 * @param customWidth width in pixels if not fallbacks to DRAWER_WIDTH const
 */
export const Drawer: React.FC<DrawerProps> = React.memo(({ children, customWidth = DRAWER_WIDTH }) => {
    return (
        <MuiDrawer
            variant="permanent"
            sx={{
                zIndex: 3,
                width: customWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: customWidth, boxSizing: 'border-box' },
                [`& .MuiPaper-root `]: { zIndex: 3 },
            }}
        >
            <Toolbar />
            {children}
        </MuiDrawer>
    )
})
