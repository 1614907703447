import React from 'react'
import { useTranslation } from 'react-i18next'
import { ViewSectionProps } from './GeneralSection'
import { valueOrDefaultEmpty } from 'utils/general'
import { SectionBlock } from 'components/buildings/SectionBlock'
import { FormItem } from 'components/buildings/FormItem'
import { Box } from '@mui/material'
import { TagChip } from 'components/general/chips/TagChip'

export const SpecialRiskSection: React.FC<ViewSectionProps> = ({ building }) => {
    const { t } = useTranslation()
    const { specialRisks, specialRiskDescription } = building

    return (
        <SectionBlock density="dense">
            <FormItem label={t('createEditBuildingPage.specialRisks')}>
                <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
                    {specialRisks.length > 0
                        ? specialRisks.map((risk) => <TagChip label={risk.label} key={risk.value} />)
                        : '-'}
                </Box>
            </FormItem>
            <FormItem
                label={t('createEditBuildingPage.specialRisksDescription')}
                sxTitleContainer={{ alignSelf: 'flex-start' }}
            >
                {valueOrDefaultEmpty(specialRiskDescription)}
            </FormItem>
        </SectionBlock>
    )
}
