import { Button, Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TopBar } from '../../components/general/top-bar/top-bar'
import { ROUTES } from 'constants/routes'
import { Helmet } from 'react-helmet'
import { PageContainer } from 'components/general/containers/PageContainer'

export const SettingsPage: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                <title>{t('navigationMenu.settings')}</title>
            </Helmet>
            <TopBar />
            <PageContainer>
                <Box display="flex" gap={1}>
                    <Button variant="outlined" color="primary" onClick={() => navigate(ROUTES.schemas)}>
                        {t('settingsPage.formBuilder')}
                    </Button>
                    <Button variant="outlined" color="primary" onClick={() => navigate(ROUTES.forms)}>
                        {t('settingsPage.structureBuilder')}
                    </Button>
                    <Button variant="outlined" color="primary" onClick={() => navigate(ROUTES.prebuiltOptions)}>
                        {t('settingsPage.prebuiltOptionList')}
                    </Button>
                    <Button variant="outlined" color="primary" onClick={() => navigate(ROUTES.schemaTemplates)}>
                        {t('settingsPage.schemaTemplates')}
                    </Button>
                </Box>
            </PageContainer>
        </>
    )
}
