import React from 'react'
import { Menu, Add, Update } from '@mui/icons-material'
import {
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    styled,
} from '@mui/material'
import { HEADER_HEIGHT } from 'constants/styles'
import { Trans, useTranslation } from 'react-i18next'
import { Project } from 'redux/types/project.type'
import { Link, useNavigate } from 'react-router-dom'
import { navigationLinks } from 'utils/navigation-utils'
import { Revision } from 'redux/types/projectRevision.type'
import { formatVersionNumber } from 'utils/string-utils'
import { ArchiveProjectDialog } from './ArchiveProjectDialog'
import ConfirmDialog from 'components/general/dialogs/ConfirmDialog'
import { useStartMinorPatchMutation } from 'services/projectRevision.service'

type ProjectActionsMenuProps = {
    isOpen: boolean
    onClose: (event: React.KeyboardEvent | React.MouseEvent) => void
    project?: Project
    revisions?: Revision[]
}

export const ProjectActionsMenu: React.FC<ProjectActionsMenuProps> = ({ isOpen, onClose, project, revisions }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [patchProject, { isLoading }] = useStartMinorPatchMutation()

    const [isCreateRevisionDialogOpen, setIsCreateRevisionDialogOpen] = React.useState(false)
    const [isUpdateDialogOpen, setIsUpdateDialogOpen] = React.useState(false)

    if (!project || !revisions) throw new Error('Cant open this drawer without initialized project')

    const onConfirmUpdate = async () => {
        try {
            await patchProject({ projectId: project.id }).unwrap()
            setIsUpdateDialogOpen(false)
            navigate(navigationLinks.toCreateProject(project.id))
        } catch {}
    }
    const currentRevisionVersion = revisions.length
    const currentRevisionLink =
        project?.status === 'Complete'
            ? navigationLinks.toPreviewProject(project.id)
            : navigationLinks.toCreateProject(project.id)

    return (
        <>
            <Drawer anchor="right" open={isOpen} onClose={onClose} variant="temporary">
                <Box sx={{ width: 240, position: 'relative' }} role="presentation">
                    <HeaderContainer>
                        <IconButton
                            size="large"
                            edge="start"
                            color="primary"
                            sx={{ ml: 'auto', mr: '55px' }}
                            onClick={onClose}
                        >
                            <Menu />
                        </IconButton>
                    </HeaderContainer>

                    <ContentContainer>
                        <ContentSection>
                            <SectionTitle variant="body1" color="primary" sx={{ fontWeight: 600 }}>
                                {project.name}
                            </SectionTitle>

                            <List>
                                <ListItem disablePadding dense>
                                    <ListItemButton
                                        disableGutters
                                        disabled={project.status !== 'Complete'}
                                        onClick={() => setIsUpdateDialogOpen(true)}
                                    >
                                        <ListItemIcon>
                                            <Update color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary={t('common.update')} />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </ContentSection>

                        <ContentSection>
                            <SectionTitle variant="body2" sx={{ fontWeight: 600 }}>
                                {t('projectPage.previewActionsMenu.revisions')}
                            </SectionTitle>

                            <List>
                                <ListItem disablePadding dense sx={{ mb: 1 }}>
                                    <ListItemButton
                                        disableGutters
                                        disabled={project.status !== 'Complete'}
                                        onClick={() => setIsCreateRevisionDialogOpen(true)}
                                    >
                                        <ListItemIcon>
                                            <Add color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary={t('projectPage.previewActionsMenu.createRevision')} />
                                    </ListItemButton>
                                </ListItem>
                                {/* current revision */}
                                <ListItem disablePadding dense>
                                    <ListItemButton disableGutters component={Link} to={currentRevisionLink}>
                                        <ListItemText
                                            primary={t('projectPage.previewActionsMenu.revisionVersion', {
                                                version: formatVersionNumber(currentRevisionVersion),
                                            })}
                                            sx={{ '& span': { fontWeight: 700 } }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                {/* old revisions */}
                                {(revisions || []).map((revision) => (
                                    <ListItem disablePadding dense key={revision.id}>
                                        <ListItemButton
                                            disableGutters
                                            component={Link}
                                            to={`${navigationLinks.toPreviewProject(project.id)}?revision=${
                                                revision.id
                                            }`}
                                            replace
                                        >
                                            <ListItemText
                                                primary={t('projectPage.previewActionsMenu.revisionVersion', {
                                                    version: formatVersionNumber(revision.version),
                                                })}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </ContentSection>
                    </ContentContainer>
                </Box>
            </Drawer>

            <ArchiveProjectDialog
                handleClose={() => setIsCreateRevisionDialogOpen(false)}
                open={isCreateRevisionDialogOpen}
                projectId={project.id}
                projectName={project.name!}
            />
            <ConfirmDialog
                open={isUpdateDialogOpen}
                handleClose={() => setIsUpdateDialogOpen(false)}
                onConfirm={onConfirmUpdate}
                content={
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        <Trans
                            i18nKey="projectPage.minorProjectUpdateDialogContent"
                            values={{ projectName: project.name }}
                        />
                    </Typography>
                }
                isLoading={isLoading}
            />
        </>
    )
}

const HeaderContainer = styled('div')(({ theme }) => ({
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2.5),
}))

const ContentContainer = styled('div')(({ theme }) => ({
    position: 'relative',
}))

const ContentSection = styled('div')(({ theme }) => ({
    padding: theme.spacing(1.5, 2),
    borderBottom: `1px solid ${theme.general.borderColor}`,
    minHeight: 40,
}))

const SectionTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5, 0),
}))
