import { Role } from 'types/role'
import { appApi } from '../redux/api'

export type LoginResponse = {
    accessToken: string
    refreshToken: string
    expires: string
    userId: string
    roles: Role[]
    tenantId: string | null
    userCustomerIds: string[] | null
    userOrganizationIds: string[] | null
}

export type LoginRequest = {
    email: string
    password: string
}

export type LoginCustomerRequest = {
    token: string
}

export type SendLoginEmailRequest = {
    email: string
}

export type ForgotPasswordRequest = {
    email: string
}

export type ResetPasswordRequest = {
    password: string
    confirmPassword: string
    email: string
    token: string
}

export const authApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginRequest>({
            query: (payload) => ({
                url: '/v1/auth/login',
                method: 'POST',
                body: payload,
            }),
        }),
        sendLoginEmail: builder.mutation<void, SendLoginEmailRequest>({
            query: (payload) => ({
                url: '/v1/auth/send-login-email',
                method: 'POST',
                body: payload,
            }),
        }),
        loginCustomer: builder.mutation<LoginResponse, LoginCustomerRequest>({
            query: (payload) => ({
                url: '/v1/auth/customer-login',
                method: 'POST',
                body: payload,
            }),
        }),
        forgotPassword: builder.mutation<void, ForgotPasswordRequest>({
            query: (payload) => ({
                url: '/v1/auth/forgot',
                method: 'POST',
                body: payload,
            }),
        }),
        resetPassword: builder.mutation<LoginResponse, ResetPasswordRequest>({
            query: (payload) => ({
                url: '/v1/auth/reset',
                method: 'POST',
                body: payload,
            }),
        }),
    }),
})

export const {
    useLoginMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useSendLoginEmailMutation,
    useLoginCustomerMutation,
} = authApi
