import { NormalizedFormSectionEntity, StructureStaticType } from 'redux/types/formStructure.type'
import { Project, ProjectFormState, UpdateProjectFormInfoRequest } from 'redux/types/project.type'
import { NormalizationUtils } from './normalization-utils'
import { Schema } from 'redux/types/schema.type'
import { buildViewSchemaFromForm } from './schema-to-view-utils'
import { extractFieldValues, removeEmptyValues } from './forms-utils'

type RequestParams =
    | {
          type: 'finish-schema'
          structureId: string
          formValues: Record<string, any>
          currentSchema: Schema
      }
    | {
          type: 'save-draft'
          structureId: string
          formValues: Record<string, any>
      }
    | {
          type: 'disable' | 'enable'
          structureId: string
      }
    | {
          type: 'enable-static'
          structureId: string
          staticFormType: StructureStaticType
      }

export class DraftRequestBuilder {
    private _project: Project
    private _normalizedData: NormalizedFormSectionEntity
    private _completeSchemasSet: Set<string>
    private _draftSchemasSet: Set<string>
    private _disabledSchemasSet: Set<string>
    private _unnasignedSchemasSet: Set<string>

    constructor(normalizedData: NormalizedFormSectionEntity, project?: Project) {
        if (!project || !project.formState) throw new Error('Cant initialize without project')
        const { formState } = project
        this._normalizedData = normalizedData
        this._project = project
        this._completeSchemasSet = new Set(formState.completeSchemas)
        this._disabledSchemasSet = new Set(formState.disabledSchemas)
        this._draftSchemasSet = new Set(formState.draftSchemas)
        this._unnasignedSchemasSet = new Set(formState.unassignedSchemas)
    }

    public buildRequest(request: RequestParams): UpdateProjectFormInfoRequest {
        switch (request.type) {
            case 'save-draft': {
                return this._createDraftRequest(request.structureId, request.formValues)
            }
            case 'finish-schema': {
                return this._finishSchemaRequest(request.structureId, request.formValues, request.currentSchema)
            }
            case 'disable': {
                return this._disableSchemaRequest(request.structureId)
            }
            case 'enable': {
                return this._enableSchemaReqeust(request.structureId)
            }
            case 'enable-static': {
                return this._enableStaticSchemaRequest(request.structureId)
            }
        }
    }

    private _enableStaticSchemaRequest(currentSchemaId: string): UpdateProjectFormInfoRequest {
        this._completeSchemasSet.add(currentSchemaId)
        this._disabledSchemasSet.delete(currentSchemaId)

        const newFormSnapshot: ProjectFormState['formSnapshot'] = {
            ...this._project.formState?.formSnapshot,
        }

        return {
            id: this._project.id,
            completeSchemas: Array.from(this._completeSchemasSet),
            draftSchemas: Array.from(this._draftSchemasSet),
            disabledSchemas: Array.from(this._disabledSchemasSet),
            unassignedSchemas: Array.from(this._unnasignedSchemasSet),
            answers: this._project.formState?.answers || {},
            formSnapshot: newFormSnapshot as any,
        }
    }

    private _enableSchemaReqeust(currentSchemaId: string): UpdateProjectFormInfoRequest {
        this._disabledSchemasSet.delete(currentSchemaId)

        if (this._normalizedData && !this._normalizedData.entities[currentSchemaId].schemaId) {
            this._unnasignedSchemasSet.add(currentSchemaId)
        }

        const parentSchemas = NormalizationUtils.GetChildParents(currentSchemaId, this._normalizedData!.entities)

        parentSchemas.forEach((id) => {
            this._disabledSchemasSet.delete(id)

            if (this._normalizedData && !this._normalizedData.entities[id].schemaId) {
                this._unnasignedSchemasSet.add(id)
            }
        })

        return {
            id: this._project.id,
            completeSchemas: Array.from(this._completeSchemasSet),
            draftSchemas: Array.from(this._draftSchemasSet),
            disabledSchemas: Array.from(this._disabledSchemasSet),
            unassignedSchemas: Array.from(this._unnasignedSchemasSet),
            answers: this._project.formState?.answers || {},
            formSnapshot: this._project.formState?.formSnapshot || {},
        }
    }

    private _disableSchemaRequest(currentSchemaId: string): UpdateProjectFormInfoRequest {
        const newAnswers = {
            ...this._project.formState?.answers,
        }
        const newUsedSchemaState = {
            ...this._project.formState?.formSnapshot,
        }

        this._disabledSchemasSet.add(currentSchemaId)
        this._draftSchemasSet.delete(currentSchemaId)
        this._completeSchemasSet.delete(currentSchemaId)
        this._unnasignedSchemasSet.delete(currentSchemaId)
        delete newAnswers[currentSchemaId]
        delete newUsedSchemaState[currentSchemaId]

        const childSchemas = NormalizationUtils.GetSectionChildren(currentSchemaId, this._normalizedData!.entities)

        childSchemas.forEach((id) => {
            this._disabledSchemasSet.add(id)
            this._draftSchemasSet.delete(id)
            this._completeSchemasSet.delete(id)
            this._unnasignedSchemasSet.delete(id)

            delete newAnswers[id]
            delete newUsedSchemaState[id]
        })

        return {
            id: this._project.id,
            completeSchemas: Array.from(this._completeSchemasSet),
            draftSchemas: Array.from(this._draftSchemasSet),
            disabledSchemas: Array.from(this._disabledSchemasSet),
            unassignedSchemas: Array.from(this._unnasignedSchemasSet),
            answers: newAnswers,
            formSnapshot: newUsedSchemaState,
        }
    }

    private _finishSchemaRequest(
        currentSchemaId: string,
        formValues: Record<string, any>,
        currentSchema: Schema,
    ): UpdateProjectFormInfoRequest {
        const schemaFieldNames = extractFieldValues(currentSchema.appSchema, 'name', 'nodes')
        const withoutEmptyValues = removeEmptyValues(formValues)

        const withoutOldValues: Record<string, any> = {}

        schemaFieldNames?.forEach((name) => {
            if (withoutEmptyValues[name] !== undefined) {
                withoutOldValues[name] = withoutEmptyValues[name]
            }
        })

        const newAnswers = {
            ...this._project.formState?.answers,
            [currentSchemaId]: withoutOldValues,
        }

        const newUsedSchemaState = {
            ...this._project.formState?.formSnapshot,
            [currentSchemaId]: buildViewSchemaFromForm(currentSchema, formValues),
        }

        this._completeSchemasSet.add(currentSchemaId)
        this._draftSchemasSet.delete(currentSchemaId)
        this._disabledSchemasSet.delete(currentSchemaId)

        return {
            id: this._project.id,
            completeSchemas: Array.from(this._completeSchemasSet),
            draftSchemas: Array.from(this._draftSchemasSet),
            disabledSchemas: Array.from(this._disabledSchemasSet),
            unassignedSchemas: Array.from(this._unnasignedSchemasSet),
            answers: newAnswers,
            formSnapshot: newUsedSchemaState,
        }
    }

    private _createDraftRequest(
        currentSchemaId: string,
        formValues: Record<string, any>,
    ): UpdateProjectFormInfoRequest {
        const newAnswers = {
            ...this._project.formState?.answers,
            [currentSchemaId]: removeEmptyValues(formValues),
        }
        const newUsedSchemaState = {
            ...this._project.formState?.formSnapshot,
        }
        delete newUsedSchemaState[currentSchemaId]

        this._draftSchemasSet.add(currentSchemaId)
        this._completeSchemasSet.delete(currentSchemaId)
        this._disabledSchemasSet.delete(currentSchemaId)

        return {
            id: this._project.id,
            completeSchemas: Array.from(this._completeSchemasSet),
            draftSchemas: Array.from(this._draftSchemasSet),
            disabledSchemas: Array.from(this._disabledSchemasSet),
            unassignedSchemas: Array.from(this._unnasignedSchemasSet),
            answers: newAnswers,
            formSnapshot: newUsedSchemaState,
        }
    }
}
