import { Close, SpeakerNotesOff } from '@mui/icons-material'
import { Box, Drawer, IconButton, Typography, styled } from '@mui/material'
import { HEADER_HEIGHT } from 'constants/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Project } from 'redux/types/project.type'
import { replaceLineBreaksWithBrTag } from 'utils/string-utils'

type PProjectReviewNotesProps = {
    isDrawerOpen: boolean
    closeDrawer: () => void
    project?: Project
}

export const ProjectReviewNotes: React.FC<PProjectReviewNotesProps> = ({ isDrawerOpen, closeDrawer, project }) => {
    const { t } = useTranslation()

    // we should have project by now here
    if (!project || (project && project.status !== 'WaitingForConfirmation')) return null

    return (
        <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer} variant="temporary">
            <Box sx={{ width: 350, px: 2, position: 'relative' }} role="presentation">
                <FloatingContainer>
                    <IconButton onClick={closeDrawer} color="primary">
                        <Close />
                    </IconButton>
                </FloatingContainer>
                <Box sx={{ pt: (HEADER_HEIGHT - 16) / 8 }}>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                        {t('projectPage.QANotes')}
                    </Typography>
                    <NotesContainer>
                        {project.reviewNotes ? (
                            <Typography
                                variant="subtitle2"
                                dangerouslySetInnerHTML={{
                                    __html: replaceLineBreaksWithBrTag(project.reviewNotes),
                                }}
                            />
                        ) : (
                            <>
                                <Typography variant="subtitle2" align="center">
                                    {t('projectPage.noQANotes')}
                                </Typography>
                                <Box textAlign="center" mt={2}>
                                    <SpeakerNotesOff fontSize="large" />
                                </Box>
                            </>
                        )}
                    </NotesContainer>
                </Box>
            </Box>
        </Drawer>
    )
}

const FloatingContainer = styled('div')({
    position: 'absolute',
    top: 16,
    right: 46,
})

const NotesContainer = styled('div')({
    maxHeight: '50vh',
    height: '100%',
    overflow: 'auto',
})
