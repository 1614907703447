import { appApi } from '../redux/api'
import { PaginatedResults } from '../components/general/models/paginated-results'
import {
    CopyGeneralSchemaRequest,
    CreateSchemaRequest,
    Schema,
    SchemaLightWithDeletable,
    SchemasPaginationParams,
    UpdateSchemaRequest,
} from 'redux/types/schema.type'

export const schemasApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getSchemas: builder.query<PaginatedResults<Schema>, SchemasPaginationParams>({
            query: (params) => ({
                url: '/v1/schemas',
                method: 'GET',
                params,
            }),
            providesTags: ['Schemas'],
        }),
        getSchemasWithDeletable: builder.query<PaginatedResults<SchemaLightWithDeletable>, SchemasPaginationParams>({
            query: (params) => ({
                url: '/v1/schemas/with-deletable',
                method: 'GET',
                params,
            }),
            providesTags: ['Schemas'],
        }),
        getStructureSchemas: builder.query<Schema[], { structureId: string }>({
            query: (params) => ({
                url: '/v1/schemas/by-structure',
                method: 'GET',
                params,
            }),
            providesTags: ['Schemas'],
        }),
        getSchema: builder.query<Schema, string>({
            query: (params) => ({
                url: `/v1/schemas/${params}`,
                method: 'GET',
            }),
            providesTags: ['Schemas'],
        }),
        createSchema: builder.mutation<Schema, CreateSchemaRequest>({
            query: (params) => ({
                url: '/v1/schemas',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['Schemas'],
        }),
        updateSchema: builder.mutation<Schema, UpdateSchemaRequest>({
            query: (params) => ({
                url: `/v1/schemas/${params.id}`,
                method: 'PUT',
                body: params,
            }),
            invalidatesTags: ['Schemas'],
        }),
        deleteSchema: builder.mutation<void, { id: string }>({
            query: (params) => ({
                url: `v1/schemas/${params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result) => (result === null ? ['Schemas'] : []),
        }),
        copyGeneralSchema: builder.mutation<Schema, CopyGeneralSchemaRequest>({
            query: (payload) => ({
                url: '/v1/schemas/copy-general',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Schemas'],
        }),
    }),
})

export const {
    useGetSchemasQuery,
    useLazyGetSchemasQuery,
    useCreateSchemaMutation,
    useGetSchemaQuery,
    useLazyGetSchemaQuery,
    useUpdateSchemaMutation,
    useGetSchemasWithDeletableQuery,
    useDeleteSchemaMutation,
    useGetStructureSchemasQuery,
    useCopyGeneralSchemaMutation,
} = schemasApi
