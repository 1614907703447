import Grid from '@mui/material/Grid'
import { TopBar } from '../../components/general/top-bar/top-bar'
import { FormStructure } from '../../modules/structures/create/FormStructure'
import { FormStructureEntity } from 'redux/types/formStructure.type'
import { PageContainer } from 'components/general/containers/PageContainer'
import { HeaderBackButton } from 'components/general/navigation/HeaderBackButton'
import { ROUTES } from 'constants/routes'

type Props = {
    initialData?: FormStructureEntity
}

export const FormStructureBuilderPage: React.FC<Props> = ({ initialData }) => {
    return (
        <>
            <TopBar
                leftActions={
                    initialData ? <HeaderBackButton label={initialData.name} href={ROUTES.forms} /> : undefined
                }
                rightActions={[]}
            />
            <PageContainer>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormStructure initialData={initialData} />
                    </Grid>
                </Grid>
            </PageContainer>
        </>
    )
}
