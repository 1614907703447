import React from 'react'
import { AccountCircle } from '@mui/icons-material'
import { useGetTenantQuery } from '../../../services/tenants.service'
import { useUserTenantId } from '../../../redux/slices/auth.slice'
import {
    ActionWrapper,
    LeftActionsWrapper,
    LogoContainer,
    PreLogoActionsWrapper,
    RightAction,
    StyledAppBar,
} from './styles'
import { ReactComponent as LogoWhite } from 'assets/icons/logo_white.svg'
import { Link } from 'react-router-dom'
import { ROUTES } from 'constants/routes'
import { useAppSelector } from 'redux/hooks'
import { selectMyUser } from 'redux/selectors/user.selectors'
import { HeaderIconButton } from '../buttons/HeaderIconButton'
import { AccountMenu } from './AccountMenu'
import { LanguageSelect } from './LanguageSelect'
import { Hidden } from '@mui/material'

export type TopBarProps = {
    rightActions?: React.ReactNode[]
    leftActions?: React.ReactNode
    preLogoActions?: React.ReactNode
}

export const TopBar: React.FC<TopBarProps> = React.memo(({ rightActions = [], leftActions, preLogoActions }) => {
    const tenantId = useUserTenantId()
    const myUser = useAppSelector(selectMyUser)

    const { data: tenant, isFetching } = useGetTenantQuery(tenantId)

    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleOpenAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (!myUser) return
        setMenuAnchorEl(event.currentTarget)
    }

    if (isFetching) return null

    return (
        <>
            <StyledAppBar>
                <ActionWrapper>
                    {preLogoActions && <PreLogoActionsWrapper>{preLogoActions}</PreLogoActionsWrapper>}
                    <Link to={ROUTES.dashboard}>
                        {tenant?.config?.logoUrl ? (
                            <LogoContainer>
                                <img alt="logo" src={tenant.config.logoUrl} />
                            </LogoContainer>
                        ) : (
                            <div>
                                <LogoWhite width={140} height="100%" />
                            </div>
                        )}
                    </Link>
                    <LeftActionsWrapper>{leftActions && leftActions}</LeftActionsWrapper>
                </ActionWrapper>
                <ActionWrapper>
                    <Hidden mdDown>
                        <LanguageSelect />
                    </Hidden>
                    {rightActions.map((a: any, index: number) => (
                        <RightAction key={`action_${index}`}>{a}</RightAction>
                    ))}
                    <HeaderIconButton icon={<AccountCircle />} onClick={handleOpenAccountMenu} size="small" />
                </ActionWrapper>
            </StyledAppBar>
            <AccountMenu user={myUser!} onClose={() => setMenuAnchorEl(null)} anchorEl={menuAnchorEl} />
        </>
    )
})
