import { JsonFormsCore, isVisible } from '@jsonforms/core'
import { defaultAjv } from 'components/general/jsonForm/RegisteredJsonForm'
import {
    ArraySchemaElement,
    BooleanSchemaElement,
    BuildingPartSchemaElement,
    CheckboxSchemaElement,
    DateSchemaElement,
    DisciplinesTagSchemaElement,
    DynamicTableShemaElement,
    FieldType,
    GroupSchemaElement,
    GroupTableSchemaElement,
    HorizontalLayoutSchemaElement,
    LabelSchemaElement,
    ListSchemaElement,
    NumberSchemaElement,
    RadioSchemaElement,
    Schema,
    SchemaElement,
    SelectSchemaElement,
    StringSchemaElement,
} from 'redux/types/schema.type'
import {
    ArrayViewSchemaElement,
    BaseViewSchemaElements,
    BooleanViewSchemaElement,
    BuildingPartViewSchemaElement,
    CheckboxViewSchemaElement,
    DateViewSchemaElement,
    DisciplinesTagViewSchemaElement,
    DynamicViewTableShemaElement,
    FormViewState,
    GroupTableViewSchemaElement,
    GroupViewSchemaElement,
    HorizontalViewLayoutSchemaElement,
    LabelViewSchemaElement,
    ListViewSchemaElement,
    NumberViewSchemaElement,
    RadioViewSchemaElement,
    SelectViewSchemaElement,
    TextAreaViewSchemaElement,
    TextFieldViewSchemaElement,
} from 'redux/types/schemaView.type'

const castedUndefinedPath = undefined as unknown as string
const BASE_ELEMENTS: FieldType[] = [
    'boolean',
    'buildingPart',
    'checkbox',
    'date',
    'disciplinesTag',
    'list',
    'number',
    'radio',
    'select',
    'string',
]
const ADVANCED_ELEMENTS: FieldType[] = ['dynamicTable', 'array', 'groupTable']
const LAYOUT_ELEMENTS: FieldType[] = ['Group', 'HorizontalLayout']

const findElementByFieldName = (obj: any, target: string): any | undefined => {
    if (obj.options?.fieldName === target) {
        return obj
    }

    if (obj.elements) {
        for (const element of obj.elements) {
            const foundElement = findElementByFieldName(element, target)
            if (foundElement) {
                return foundElement
            }
        }
    }

    return undefined
}

// this serves the same purpose as findElementByFieldName except it is used for label elements
const findElementByText = (obj: any, target: string): any | undefined => {
    if (obj?.text === target) {
        return obj
    }

    if (obj.elements) {
        for (const element of obj.elements) {
            const foundElement = findElementByText(element, target)
            if (foundElement) {
                return foundElement
            }
        }
    }

    return undefined
}

const isNodeVisible = (
    nodeName: string,
    rootUiSchema: Schema['uiSchema'],
    formValues: JsonFormsCore['data'],
    rootFormValues: JsonFormsCore['data'],
) => {
    let isVisibleInForm = nodeName in formValues && formValues[nodeName] !== undefined

    // if visible check if not hidden
    if (isVisibleInForm) {
        const nodeUiSchema = findElementByFieldName(rootUiSchema, nodeName)
        isVisibleInForm = nodeUiSchema
            ? isVisible(nodeUiSchema, rootFormValues, castedUndefinedPath, defaultAjv)
            : false
    }

    return isVisibleInForm
}

const isLabelNodeVisible = (
    labelText: string,
    rootUiSchema: Schema['uiSchema'],
    rootFormValues: JsonFormsCore['data'],
) => {
    const nodeUiSchema = findElementByText(rootUiSchema, labelText)
    return nodeUiSchema ? isVisible(nodeUiSchema, rootFormValues, castedUndefinedPath, defaultAjv) : false
}

const isLayoutNodeVisible = (nodeName: string, rootUiSchema: Schema['uiSchema'], formValues: JsonFormsCore['data']) => {
    const nodeUiSchema = findElementByFieldName(rootUiSchema, nodeName)
    return nodeUiSchema ? isVisible(nodeUiSchema, formValues, castedUndefinedPath, defaultAjv) : false
}

const buildBaseSchemaElement = (
    node: SchemaElement,
    rootUiSchema: Schema['uiSchema'],
    formValues: JsonFormsCore['data'],
    skipVisibilityCheck: boolean = false,
    rootFormValues: JsonFormsCore['data'],
): BaseViewSchemaElements | undefined => {
    // have to check because SchemaElement is all types not just base
    if (!('name' in node)) throw new Error('element must have an assigned name')
    if (!('options' in node) || node.options === undefined)
        throw new Error('base element must containt mandatory options')
    let isVisibleInForm = node.options.hideInPdf === false

    // that means node is a child which doesnt have rules for inside fields
    if (!node.options.hideInPdf) {
        if (!skipVisibilityCheck) {
            isVisibleInForm = isNodeVisible(node.name, rootUiSchema, formValues, rootFormValues)
        }
    }

    switch (node.type) {
        case 'string': {
            return node.options?.multi
                ? buildTextAreaViewSchemaElement(node, isVisibleInForm, formValues[node.name])
                : buildTextFieldViewSchemaElement(node, isVisibleInForm, formValues[node.name])
        }
        case 'number': {
            return buildNumberViewSchemaElement(node, isVisibleInForm, formValues[node.name])
        }
        case 'boolean': {
            return buildBooleanViewSchemaElement(node, isVisibleInForm, formValues[node.name])
        }
        case 'select': {
            return buildDropdownViewSchemaElement(node, isVisibleInForm, formValues[node.name])
        }
        case 'radio': {
            return buildRadioViewSchemaElement(node, isVisibleInForm, formValues[node.name])
        }
        case 'checkbox': {
            return buildCheckboxViewSchemaElement(node, isVisibleInForm, formValues[node.name])
        }
        case 'date': {
            return buildDateViewSchemaElement(node, isVisibleInForm, formValues[node.name])
        }
        case 'disciplinesTag': {
            return buildDisciplineTagViewSchemaElement(node, isVisibleInForm, formValues[node.name])
        }
        case 'buildingPart': {
            return buildBuildingPartViewSchemaElement(node, isVisibleInForm, formValues[node.name])
        }
        case 'list': {
            return buildListViewSchemaElement(node, isVisibleInForm, formValues[node.name])
        }
    }
}

const buildAdvancedSchemaElement = (
    node: SchemaElement,
    rootUiSchema: Schema['uiSchema'],
    formValues: JsonFormsCore['data'],
    skipVisibilityCheck: boolean = false,
    rootFormValues: JsonFormsCore['data'],
) => {
    // have to check because SchemaElement is all types not just base
    if (!('name' in node)) throw new Error('element must have an assigned name')
    if (!('options' in node) || node.options === undefined)
        throw new Error('base element must containt mandatory options')
    let isVisibleInForm = node.options.hideInPdf === false
    // that means node is a child which doesnt have rules for inside fields
    if (!node.options.hideInPdf) {
        if (!skipVisibilityCheck) {
            isVisibleInForm = isNodeVisible(node.name, rootUiSchema, formValues, rootFormValues)
        }
    }

    switch (node.type) {
        case 'array': {
            return buildArrayViewSchemaElement(
                node,
                isVisibleInForm,
                formValues[node.name],
                rootUiSchema,
                rootFormValues,
            )
        }
        case 'groupTable': {
            return buildGroupTableViewSchemaElement(
                node,
                isVisibleInForm,
                formValues[node.name],
                rootUiSchema,
                rootFormValues,
            )
        }
        case 'dynamicTable': {
            return buildDynamicTableViewSchemaElement(
                node,
                isVisibleInForm,
                formValues[node.name],
                rootUiSchema,
                rootFormValues,
            )
        }
    }
}

const buildLayoutSchemaElement = (
    node: SchemaElement,
    rootUiSchema: Schema['uiSchema'],
    formValues: JsonFormsCore['data'],
    rootFormValues: JsonFormsCore['data'],
) => {
    switch (node.type) {
        case 'HorizontalLayout': {
            const isVisibleInForm = isLayoutNodeVisible(node.options.fieldName, rootUiSchema, formValues)
            return buildHorizontalLayoutSchemaElement(node, isVisibleInForm, formValues, rootUiSchema, rootFormValues)
        }
        case 'Group': {
            const isVisibleInForm =
                node.options.hideInPdf === false
                    ? isNodeVisible(node.name, rootUiSchema, formValues, rootFormValues)
                    : false
            return buildGroupLayoutSchemaElement(
                node,
                isVisibleInForm,
                formValues[node.name],
                rootUiSchema,
                rootFormValues,
            )
        }
    }
}
// entry point
export const buildViewSchemaFromForm = (schema: Schema, formValues: JsonFormsCore['data']): FormViewState => {
    const viewSchema: FormViewState = { type: 'Group', elements: [], staticType: null }
    for (const node of schema.appSchema.nodes) {
        if (node.type === 'Label') {
            const labelSchemaElement = buildLabelViewSchemaElement(node, schema.uiSchema, formValues)
            viewSchema.elements.push(labelSchemaElement)
            continue
        }
        if (BASE_ELEMENTS.includes(node.type)) {
            // we are passing 2nd formValue because first one was built for nesting and accessing the values that are needed step by step
            // but jsonforms isVisible function doesnt work if we dont pass root form values, thats why 2nd formValues - rootFormValues
            // are passed for easy accesing that root state
            const baseSchemaElement = buildBaseSchemaElement(node, schema.uiSchema, formValues, false, formValues)!
            viewSchema.elements.push(baseSchemaElement)
            continue
        }
        if (ADVANCED_ELEMENTS.includes(node.type)) {
            const advancedSchemaElement = buildAdvancedSchemaElement(
                node,
                schema.uiSchema,
                formValues,
                false,
                formValues,
            )!
            viewSchema.elements.push(advancedSchemaElement)
            continue
        }
        if (LAYOUT_ELEMENTS.includes(node.type)) {
            const layoutSchemaElement = buildLayoutSchemaElement(node, schema.uiSchema, formValues, formValues)!
            viewSchema.elements.push(layoutSchemaElement)
            continue
        }
        throw new Error('Unsupported element, please report error trace' + JSON.stringify(node))
    }

    return viewSchema
}

const buildGroupLayoutSchemaElement = (
    node: GroupSchemaElement,
    isVisible: boolean,
    value: JsonFormsCore['data'] = {},
    rootUiSchema: Schema['uiSchema'],
    rootFormValues: JsonFormsCore['data'],
): GroupViewSchemaElement => {
    const nodes: GroupViewSchemaElement['nodes'] = []

    for (const childNode of node.nodes) {
        let viewNode = null as unknown as GroupViewSchemaElement['nodes'][number]
        if (childNode.type === 'Label') {
            viewNode = buildLabelViewSchemaElement(childNode, rootUiSchema, rootFormValues)
        }
        if (BASE_ELEMENTS.includes(childNode.type)) {
            viewNode = buildBaseSchemaElement(childNode, rootUiSchema, value, false, rootFormValues)!
        }
        if (ADVANCED_ELEMENTS.includes(childNode.type)) {
            viewNode = buildAdvancedSchemaElement(childNode, rootUiSchema, value, false, rootFormValues)!
        }
        if (childNode.type === 'HorizontalLayout') {
            const isVisibleInForm = isLayoutNodeVisible(childNode.options.fieldName, rootUiSchema, value)
            viewNode = buildHorizontalLayoutSchemaElement(childNode, isVisibleInForm, value, false, rootUiSchema)
        }

        if (isVisible === false) {
            viewNode.isVisible = false
            viewNode.isVisibleInWeb = false
        }
        if (viewNode !== null) {
            nodes.push(viewNode)
        }
    }

    return {
        isVisible,
        isVisibleInWeb: isVisible,
        type: 'Group',
        isLabelVisible: !Boolean(node.options.hideLabel),
        label: node.label,
        name: node.name,
        nodes,
    }
}

const buildHorizontalLayoutSchemaElement = (
    node: HorizontalLayoutSchemaElement,
    isVisible: boolean,
    value: JsonFormsCore['data'] = {},
    rootUiSchema: Schema['uiSchema'],
    rootFormValues: JsonFormsCore['data'],
): HorizontalViewLayoutSchemaElement => {
    const nodes: BaseViewSchemaElements[] = []

    for (const childNode of node.nodes) {
        const baseSchemaElement = buildBaseSchemaElement(childNode, rootUiSchema, value, false, rootFormValues)!

        if (isVisible === false) {
            baseSchemaElement.isVisible = false
            baseSchemaElement.isVisibleInWeb = false
        }

        nodes.push(baseSchemaElement)
    }

    return {
        isVisible,
        isVisibleInWeb: isVisible,
        type: 'HorizontalLayout',
        nodes,
    }
}

const buildDynamicTableViewSchemaElement = (
    node: DynamicTableShemaElement,
    isVisible: boolean,
    value: Record<string, unknown> = {},
    rootUiSchema: Schema['uiSchema'],
    rootFormValues: JsonFormsCore['data'],
): DynamicViewTableShemaElement => {
    const nodes: BaseViewSchemaElements[] = []

    for (const childNode of node.nodes) {
        const baseSchemaElement = buildBaseSchemaElement(childNode, rootUiSchema, value, true, rootFormValues)!
        baseSchemaElement.isVisible = isVisible
        baseSchemaElement.isVisibleInWeb = isVisible
        nodes.push(baseSchemaElement)
    }

    return {
        isLabelVisible: !Boolean(node.options?.hideLabel),
        isGoldenStandard: Boolean(node.isGoldenStandard),
        selectedStandardId: node.selectedStandardId,
        isVisible,
        isVisibleInWeb: isVisible,
        label: node.label,
        name: node.name,
        type: 'dynamicTable',
        nodes,
        options: {
            columnCount: node.options.columnCount,
            columnDefinitions: node.options.columnDefinitions,
        },
    }
}

const buildGroupTableViewSchemaElement = (
    node: GroupTableSchemaElement,
    isVisible: boolean,
    value: Record<string, unknown> = {},
    rootUiSchema: Schema['uiSchema'],
    rootFormValues: JsonFormsCore['data'],
): GroupTableViewSchemaElement => {
    const nodes: BaseViewSchemaElements[] = []

    for (const childNode of node.nodes) {
        const baseSchemaElement = buildBaseSchemaElement(childNode, rootUiSchema, value, true, rootFormValues)!
        baseSchemaElement.isVisible = isVisible
        baseSchemaElement.isVisibleInWeb = isVisible
        nodes.push(baseSchemaElement)
    }

    return {
        isLabelVisible: !Boolean(node.options?.hideLabel),
        isVisible,
        isVisibleInWeb: isVisible,
        label: node.label,
        name: node.name,
        type: 'groupTable',
        nodes,
        options: {
            columnCount: node.options.columnCount,
            columnDefinitions: node.options.columnDefinitions,
        },
    }
}

const buildArrayViewSchemaElement = (
    node: ArraySchemaElement,
    isVisible: boolean,
    value: ArrayViewSchemaElement['value'] = [],
    rootUiSchema: Schema['uiSchema'],
    rootFormValues: JsonFormsCore['data'],
): ArrayViewSchemaElement => {
    const nodes: BaseViewSchemaElements[] = []

    for (const childNode of node.nodes) {
        // we dont need value for this element so jsut pass empty object and skip checking for visibility
        const baseSchemaElement = buildBaseSchemaElement(childNode, rootUiSchema, {}, true, rootFormValues)!
        baseSchemaElement.isVisible = isVisible
        baseSchemaElement.isVisibleInWeb = isVisible
        nodes.push(baseSchemaElement)
    }

    return {
        isGoldenStandard: false,
        isLabelVisible: !Boolean(node.options?.hideLabel),
        isVisible,
        isVisibleInWeb: isVisible,
        label: node.label,
        name: node.name,
        type: 'array',
        value,
        nodes,
        options: {
            columnDefinitions: node.options.columnDefinitions,
        },
        selectedStandardId: undefined,
    }
}

const buildLabelViewSchemaElement = (
    node: LabelSchemaElement,
    rootUiSchema: Schema['uiSchema'],
    rootFormValues: JsonFormsCore['data'],
): LabelViewSchemaElement => {
    const isVisibleInForm = isLabelNodeVisible(node.text, rootUiSchema, rootFormValues)
    return {
        isVisible: isVisibleInForm,
        isVisibleInWeb: isVisibleInForm,
        isLabelVisible: isVisibleInForm,
        type: 'Label',
        label: node.text,
    }
}

const buildListViewSchemaElement = (
    node: ListSchemaElement,
    isVisible: boolean,
    value: ListViewSchemaElement['value'],
): ListViewSchemaElement => {
    let valueType: ListViewSchemaElement['listValueType']
    if (!value || value.length === 0) valueType = 'string'
    else if (typeof value[0] === 'string') valueType = 'string'
    else if (typeof value[0] === 'number') valueType = 'number'
    else valueType = 'boolean'

    return {
        isGoldenStandard: false,
        isLabelVisible: !Boolean(node.options?.hideLabel),
        isVisible,
        isVisibleInWeb: isVisible,
        label: node.label,
        name: node.name,
        type: 'list',
        value,
        selectedStandardId: undefined,
        isOrdered: node.options.listType === 'ordered',
        listValueType: valueType,
    }
}

const buildBuildingPartViewSchemaElement = (
    node: BuildingPartSchemaElement,
    isVisible: boolean,
    value: BuildingPartViewSchemaElement['value'],
): BuildingPartViewSchemaElement => {
    return {
        isGoldenStandard: false,
        isLabelVisible: !Boolean(node.options?.hideLabel),
        isVisible,
        isVisibleInWeb: isVisible,
        label: node.label,
        name: node.name,
        type: 'buildingPart',
        value,
        selectedStandardId: undefined,
    }
}

const buildDisciplineTagViewSchemaElement = (
    node: DisciplinesTagSchemaElement,
    isVisible: boolean,
    value: DisciplinesTagViewSchemaElement['value'],
): DisciplinesTagViewSchemaElement => {
    return {
        isGoldenStandard: false,
        isLabelVisible: !Boolean(node.options?.hideLabel),
        isVisible,
        isVisibleInWeb: isVisible,
        label: node.label,
        name: node.name,
        type: 'disciplinesTag',
        value,
        selectedStandardId: undefined,
    }
}

const buildDateViewSchemaElement = (
    node: DateSchemaElement,
    isVisible: boolean,
    value: DateViewSchemaElement['value'],
): DateViewSchemaElement => {
    return {
        isGoldenStandard: node.isGoldenStandard,
        isLabelVisible: !Boolean(node.options?.hideLabel),
        isVisible,
        isVisibleInWeb: isVisible,
        label: node.label,
        name: node.name,
        type: 'date',
        value,
        selectedStandardId: node.selectedStandardId,
    }
}

const buildCheckboxViewSchemaElement = (
    node: CheckboxSchemaElement,
    isVisible: boolean,
    value: CheckboxViewSchemaElement['value'],
): CheckboxViewSchemaElement => {
    return {
        isGoldenStandard: node.isGoldenStandard,
        isLabelVisible: !Boolean(node.options?.hideLabel),
        isVisible,
        isVisibleInWeb: isVisible,
        label: node.label,
        name: node.name,
        type: 'checkbox',
        value,
        selectedStandardId: node.selectedStandardId,
    }
}

const buildRadioViewSchemaElement = (
    node: RadioSchemaElement,
    isVisible: boolean,
    value: RadioViewSchemaElement['value'],
): RadioViewSchemaElement => {
    return {
        isGoldenStandard: node.isGoldenStandard,
        isLabelVisible: !Boolean(node.options?.hideLabel),
        isVisible,
        isVisibleInWeb: isVisible,
        label: node.label,
        name: node.name,
        type: 'radio',
        value,
        selectedStandardId: node.selectedStandardId,
    }
}

const buildDropdownViewSchemaElement = (
    node: SelectSchemaElement,
    isVisible: boolean,
    value: SelectViewSchemaElement['value'],
): SelectViewSchemaElement => {
    return {
        isGoldenStandard: node.isGoldenStandard,
        isLabelVisible: !Boolean(node.options?.hideLabel),
        isVisible,
        isVisibleInWeb: isVisible,
        label: node.label,
        name: node.name,
        type: 'select',
        value,
        selectedStandardId: node.selectedStandardId,
    }
}

const buildBooleanViewSchemaElement = (
    node: BooleanSchemaElement,
    isVisible: boolean,
    value: BooleanViewSchemaElement['value'],
): BooleanViewSchemaElement => {
    return {
        isGoldenStandard: node.isGoldenStandard,
        isLabelVisible: !Boolean(node.options?.hideLabel),
        isVisible,
        isVisibleInWeb: isVisible,
        label: node.label,
        name: node.name,
        type: 'boolean',
        value,
        selectedStandardId: node.selectedStandardId,
    }
}

const buildNumberViewSchemaElement = (
    node: NumberSchemaElement,
    isVisible: boolean,
    value: NumberViewSchemaElement['value'],
): NumberViewSchemaElement => {
    return {
        isGoldenStandard: node.isGoldenStandard,
        isLabelVisible: !Boolean(node.options?.hideLabel),
        isVisible,
        isVisibleInWeb: isVisible,
        label: node.label,
        name: node.name,
        type: 'number',
        value,
        selectedStandardId: node.selectedStandardId,
    }
}

const buildTextFieldViewSchemaElement = (
    node: StringSchemaElement,
    isVisible: boolean,
    value: TextFieldViewSchemaElement['value'],
): TextFieldViewSchemaElement => {
    return {
        isGoldenStandard: node.isGoldenStandard,
        isLabelVisible: !Boolean(node.options?.hideLabel),
        isVisible,
        isVisibleInWeb: isVisible,
        label: node.label,
        name: node.name,
        type: 'textField',
        value,
        selectedStandardId: node.selectedStandardId,
    }
}

const buildTextAreaViewSchemaElement = (
    node: StringSchemaElement,
    isVisible: boolean,
    value: TextAreaViewSchemaElement['value'],
): TextAreaViewSchemaElement => {
    return {
        isGoldenStandard: node.isGoldenStandard,
        isLabelVisible: !Boolean(node.options?.hideLabel),
        isVisible,
        isVisibleInWeb: isVisible,
        label: node.label,
        name: node.name,
        type: 'textArea',
        value,
        selectedStandardId: node.selectedStandardId,
    }
}
