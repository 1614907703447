import React from 'react'
import authRoutes from './authRoutes'
import { AuthLayout } from 'layouts/AuthLayout'
import { PrivateAdminWithSidebar } from 'layouts/PrivateAdminWithSidebar'
import { PrivateAdminWithSchemaTree } from 'layouts/PrivateAdminWithSchemaTree'
import customerAdminRoutes from './customerAdminRoutes'
import { ROUTES } from 'constants/routes'
import tenantsRoutes from './tenantsRoutes'
import buildingRoutes from './buildingRoutes'
import usersRoutes from './usersRoutes'
import settingsRoutes from './settingsRoutes'
import schemasRoutes from './schemasRoutes'
import formsRoutes from './formsRoutes'
import projectsRoutes from './projectsRoutes'
import { TemplateEditPage } from 'pages/settings/TemplateEdit.page'
import { PrivateAdminEmpty } from 'layouts/PrivateAdminEmpty'
import dashboardRoutes from './dashboardRoutes'
import { ProjectsPage } from 'pages/project/Projects.page'
import publicRoutes from './publicRoutes'
import { PublicEmpty } from 'layouts/PublicEmpty'
import organizationRoutes from './organizationRoutes'
import { PrivateEmpty } from 'layouts/PrivateEmpty'
import customerRoutes from './customerRoutes'
import redirectRoutes from './redirectRoutes'
import { NotFoundPage } from 'pages/NotFoundPage'

const globalRouter = [
    {
        path: '/',
        children: redirectRoutes,
    },
    {
        path: '/',
        element: <AuthLayout />,
        children: authRoutes,
    },
    {
        path: '/',
        element: <PublicEmpty />,
        children: publicRoutes,
    },
    {
        path: '/',
        element: <PrivateEmpty />,
        children: customerRoutes,
    },
    {
        path: ROUTES.customers,
        element: <PrivateAdminWithSidebar />,
        children: customerAdminRoutes,
    },
    {
        path: ROUTES.organizations,
        element: <PrivateAdminWithSidebar />,
        children: organizationRoutes,
    },
    {
        path: ROUTES.tenants,
        element: <PrivateAdminWithSidebar />,
        children: tenantsRoutes,
    },
    {
        path: ROUTES.buildings,
        element: <PrivateAdminWithSidebar />,
        children: buildingRoutes,
    },
    {
        path: ROUTES.users,
        element: <PrivateAdminWithSidebar />,
        children: usersRoutes,
    },
    {
        path: ROUTES.settings,
        element: <PrivateAdminWithSidebar />,
        children: settingsRoutes,
    },
    {
        path: ROUTES.settings,
        element: <PrivateAdminWithSchemaTree />,
        children: [
            {
                path: ROUTES.schemTemplateEdit,
                element: <TemplateEditPage />,
            },
        ],
    },
    {
        path: ROUTES.schemas,
        element: <PrivateAdminWithSidebar />,
        children: schemasRoutes,
    },
    {
        path: ROUTES.forms,
        element: <PrivateAdminWithSidebar />,
        children: formsRoutes,
    },
    {
        path: ROUTES.projects,
        element: <PrivateAdminWithSidebar />,
        children: [
            {
                path: '',
                element: <ProjectsPage />,
            },
        ],
    },
    {
        path: ROUTES.projects,
        element: <PrivateAdminEmpty />,
        children: projectsRoutes,
    },
    {
        path: ROUTES.dashboard,
        element: <PrivateAdminWithSidebar />,
        children: dashboardRoutes,
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
]

export default globalRouter
