import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ViewSectionProps } from './GeneralSection'
import { TagChip } from 'components/general/chips/TagChip'
import { SectionBlock } from 'components/buildings/SectionBlock'
import { FormItem } from 'components/buildings/FormItem'

export const FireInstallationSection: React.FC<ViewSectionProps> = ({ building }) => {
    const { t } = useTranslation()
    const { safetyInstallations } = building

    return (
        <SectionBlock density="dense">
            <FormItem label={t('createEditBuildingPage.fireSafetyInstallations')}>
                <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
                    {safetyInstallations.length > 0
                        ? safetyInstallations.map((installation) => (
                              <TagChip label={installation.label} key={installation.value} />
                          ))
                        : '-'}
                </Box>
            </FormItem>
        </SectionBlock>
    )
}
