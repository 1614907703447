import * as yup from 'yup'

export type CreateTenantForm = {
    name: string
    logo: File | null
    documentLogo: File | null
}

export const tenantSchema: yup.ObjectSchema<CreateTenantForm> = yup.object().shape({
    name: yup.string().required('required'),
    logo: yup.mixed<File>().required('required'),
    documentLogo: yup.mixed<File>().required('required'),
})
