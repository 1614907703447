import React from 'react'
import ControlledMuiSelect from 'components/inputs/ControlledMuiSelect'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'redux/hooks'
import { selectClearableFireClassTagOptions } from 'redux/selectors/settings.selectors'
import { CreateOrUpdateBuildingForm } from 'redux/types/building.type'
import { SectionBlock } from 'components/buildings/SectionBlock'
import { FormItem } from 'components/buildings/FormItem'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'

export const FireClassForm: React.FC = () => {
    const { t } = useTranslation()
    const { control } = useFormContext<CreateOrUpdateBuildingForm>()

    const fireClassTagOptions = useAppSelector(selectClearableFireClassTagOptions)

    return (
        <SectionBlock>
            <FormItem label={t('createEditBuildingPage.fireClass')}>
                <ControlledMuiSelect
                    control={control}
                    name="fireClass"
                    sx={{ maxWidth: 120 }}
                    label={t('createEditBuildingPage.fireClass')}
                    fullWidth
                    defaultValue=""
                    options={fireClassTagOptions}
                />
            </FormItem>

            <FormItem
                label={t('createEditBuildingPage.fireClassDescription')}
                sxTitleContainer={{ alignSelf: 'flex-start' }}
            >
                <ControlledMuiTextField
                    control={control}
                    name="fireClassDescription"
                    placeholder={t('common.description')}
                    fullWidth
                    variant="outlined"
                    rows={5}
                    sx={{ maxWidth: 600 }}
                    multiline
                />
            </FormItem>
        </SectionBlock>
    )
}
