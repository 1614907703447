import React from 'react'

import { Button, SortDirection, Stack, Typography, styled } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { FormStructureEntity } from 'redux/types/formStructure.type'
import { LoadingButton } from '@mui/lab'
import { useDeleteProjectsMutation, useGetProjectsQuery } from 'services/projects.service'
import { DEFAULT_ROWS_PER_PAGE } from 'constants/pagination'
import { useDeleteTemplatesMutation, useGetTemplatesQuery } from 'services/templates.service'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useDataGridFiltersState } from 'utils/hooks/useDataGridFiltersState'
import { ProjectWithoutForms } from 'redux/types/project.type'
import { valueOrDefaultEmpty } from 'utils/general'
import { ProjectStatusCell } from 'components/projects/ProjectStatusCell'
import { DataGridNoRows } from 'components/general/table/DataGridNoRows'
import { TemplateEntityWithPartialStructure } from 'redux/types/template.type'
import { DataGridTitle } from 'components/general/table/DataGridTitle'
import { useDeleteFormStructureMutation } from 'services/formStructures.service'

interface DeleteStructureDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions'> {
    structure: FormStructureEntity
}

const DeleteStructureDialog: React.FC<DeleteStructureDialogProps> = ({ open, handleClose, structure }) => {
    const { t } = useTranslation()

    const { handlePageChange, handleSortChange, page, sortBy } = useDataGridFiltersState({
        sort: 'desc',
        field: 'updated',
    })

    const {
        data: relatedOngoingProjects,
        isLoading: isLoadingProjects,
        isFetching,
    } = useGetProjectsQuery({
        structureId: structure.id,
        page: page || 0,
        itemsPerPage: DEFAULT_ROWS_PER_PAGE,
        direction: sortBy?.sort as SortDirection,
        sortBy: sortBy?.field,
    })
    const { data: relatedTemplates, isLoading: isLoadingTemplates } = useGetTemplatesQuery({
        structureId: structure.id,
    })
    const isLoading = isLoadingProjects || isLoadingTemplates

    const [deleteProjects, { isLoading: isDeletingProjects }] = useDeleteProjectsMutation()
    const [deleteTemplates, { isLoading: isDeletingTemplates }] = useDeleteTemplatesMutation()
    const [deleteStructure, { isLoading: isDeletingStructure }] = useDeleteFormStructureMutation()
    const isDeleting = isDeletingProjects || isDeletingTemplates || isDeletingStructure

    const columns: GridColDef<ProjectWithoutForms>[] = [
        {
            field: 'name',
            headerName: t('dashboard.projectName'),
            flex: 1,
            editable: false,
            minWidth: 200,
        },
        {
            field: 'type',
            headerName: t('dashboard.projectType'),
            flex: 1,
            editable: false,
            minWidth: 160,
            valueGetter: (params) => valueOrDefaultEmpty(params.row.type?.label),
        },
        {
            field: 'status',
            headerName: t('dashboard.projectStatus'),
            flex: 1,
            editable: false,
            width: 170,
            minWidth: 170,
            renderCell: (params) => <ProjectStatusCell status={params.row.status} />,
        },
        {
            field: 'updated',
            headerName: t('projectsTable.updatedHeader'),
            flex: 1,
            editable: false,
        },
    ]

    const columns2: Array<GridColDef<TemplateEntityWithPartialStructure>> = [
        {
            field: 'name',
            headerName: t('common.name'),
            flex: 1,
            minWidth: 250,
            editable: false,
        },
    ]

    const handleClick = async () => {
        const deleteProjectsTask = deleteProjects({
            ids: (relatedOngoingProjects?.data || []).map((project) => project.id),
        }).unwrap()
        const deleteTemplatesTask = deleteTemplates({
            ids: (relatedTemplates || []).map((template) => template.id),
        }).unwrap()
        const deleteStructureTask = deleteStructure({ id: structure.id }).unwrap()

        await Promise.all([deleteStructureTask, deleteTemplatesTask, deleteProjectsTask])

        handleClose()
    }

    return (
        <ModalsWrapper
            open={open}
            title={
                <Trans i18nKey="structurePage.deleteStructureDialogTitle" values={{ structureName: structure.name }} />
            }
            handleClose={handleClose}
            fullWidth
            maxWidth="md"
            isLoading={isLoading}
            body={
                <ContentWrapper>
                    <Typography variant="body2">{t('structurePage.deletetructureExplanation')}</Typography>
                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                textTransform: 'capitalize',
                            },
                            '& .MuiDataGrid-row': { cursor: 'pointer' },
                        }}
                        autoHeight
                        rows={relatedOngoingProjects?.data || []}
                        columns={columns}
                        pageSize={DEFAULT_ROWS_PER_PAGE}
                        rowCount={relatedOngoingProjects?.total || 0}
                        isRowSelectable={() => false}
                        disableColumnMenu
                        loading={isFetching}
                        hideFooterSelectedRowCount
                        rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
                        components={{
                            NoRowsOverlay: DataGridNoRows,
                            Toolbar: DataGridTitle,
                        }}
                        componentsProps={{
                            toolbar: { title: t('common.projects') },
                        }}
                        paginationMode="server"
                        sortingMode="server"
                        onPageChange={handlePageChange}
                        onSortModelChange={handleSortChange}
                        page={page || 0}
                        sortModel={sortBy ? [{ field: sortBy.field, sort: sortBy.sort }] : []}
                        // hide updated column
                        // we need to add updated column
                        // so that we can initially sort by updated
                        columnVisibilityModel={{
                            updated: false,
                        }}
                    />

                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-columnHeader': {
                                textTransform: 'capitalize',
                            },
                            '& .MuiDataGrid-row': { cursor: 'pointer' },
                            mt: 4,
                        }}
                        autoHeight
                        rows={relatedTemplates || []}
                        columns={columns2}
                        rowsPerPageOptions={[relatedTemplates?.length || DEFAULT_ROWS_PER_PAGE]}
                        pageSize={relatedTemplates?.length || DEFAULT_ROWS_PER_PAGE}
                        rowCount={relatedTemplates?.length || 0}
                        isRowSelectable={() => false}
                        disableColumnMenu
                        loading={isLoading}
                        components={{
                            NoRowsOverlay: DataGridNoRows,
                            Toolbar: DataGridTitle,
                        }}
                        componentsProps={{
                            toolbar: { title: t('common.templates') },
                        }}
                        hideFooterSelectedRowCount
                    />
                </ContentWrapper>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={2}>
                    <Button variant="outlined" onClick={handleClose} color="secondary" disabled={isDeleting}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        form="copy-structure-and-template-form"
                        loading={isDeleting}
                        onClick={handleClick}
                    >
                        {t('common.continue')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

export default DeleteStructureDialog

const ActionWrapper = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
