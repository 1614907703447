import { EditFormStructurePage } from 'pages/structures/EditFormStructure.page'
import { FormStructureBuilderPage } from 'pages/structures/FormStructureBuilder.page.'
import { FormsPage } from 'pages/structures/FormStructures.page'
import { ROUTES } from 'constants/routes'
import { generateNestedRouteUrl } from 'utils/navigation-utils'

const formsRoutes = [
    {
        path: '',
        element: <FormsPage />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.formAdd, ROUTES.forms),
        element: <FormStructureBuilderPage />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.formEdit, ROUTES.forms),
        element: <EditFormStructurePage />,
    },
]

export default formsRoutes
