import { useDraggable, useDroppable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { IconButton } from '@mui/material'
import React, { PropsWithChildren } from 'react'

export const Droppable: React.FC<PropsWithChildren<Props>> = ({ children, id, dragData, styling }) => {
    const { isOver, setNodeRef } = useDroppable({ id, data: dragData })

    const style = {
        backgroundColor: isOver ? 'lightgrey' : undefined,
        height: isOver ? '50px' : '20px',
    }

    return (
        <div ref={setNodeRef} style={{ ...style, ...styling }}>
            {children}
        </div>
    )
}

type Props = {
    id: string
    dragData?: Record<string, any>
    styling?: React.CSSProperties
}

export const Draggable: React.FC<PropsWithChildren<Props>> = ({ children, id, dragData, styling }) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({ id, data: dragData })
    const style = transform ? { transform: CSS.Translate.toString(transform) } : undefined

    return (
        <div
            ref={setNodeRef}
            {...attributes}
            style={{ display: 'flex', alignItems: 'flex-start', flexGrow: 1, padding: '0 10px', ...style, ...styling }}
        >
            <div>
                <IconButton size={'small'} sx={{ marginTop: dragData ? '24px' : 0 }} aria-label="drag" {...listeners}>
                    <DragIndicatorIcon />
                </IconButton>
            </div>
            {children}
        </div>
    )
}
