import { RootState } from 'redux/store'
import { appApi } from '../redux/api'
import { PaginatedResults } from '../components/general/models/paginated-results'
import {
    CopyFormStructureAndTemplateRequest,
    CreateFormStructureRequest,
    FormStructureEntity,
    FormStructuresPaginationParams,
    UpdateFormStructureRequest2,
} from 'redux/types/formStructure.type'

export const formstructuresApi = appApi.injectEndpoints({
    endpoints: (builder) => ({
        getFormStructures: builder.query<PaginatedResults<FormStructureEntity>, FormStructuresPaginationParams>({
            query: (params) => ({
                url: '/v1/formStructures',
                method: 'GET',
                params,
            }),
            providesTags: ['FormStructures'],
        }),

        getFormStructure: builder.query<FormStructureEntity, string>({
            query: (params) => ({
                url: `/v1/formStructures/${params}`,
                method: 'GET',
            }),
            providesTags: ['FormStructures'],
        }),

        createFormStructure: builder.mutation<FormStructureEntity, CreateFormStructureRequest>({
            query: (params) => ({
                url: '/v1/formStructures',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['FormStructures'],
        }),

        updateFormStructure: builder.mutation<FormStructureEntity, UpdateFormStructureRequest2>({
            query: (params) => ({
                url: `/v1/formStructures/${params.id}`,
                method: 'PUT',
                body: params,
            }),
            invalidatesTags: ['FormStructures'],
        }),

        publishFormStructure: builder.mutation<void, { id: string }>({
            query: (params) => ({
                url: `/v1/formStructures/publish/${params.id}`,
                method: 'PUT',
            }),
            invalidatesTags: (result) => (result !== undefined ? ['FormStructures'] : []),
        }),

        copyFormStructureAndTemplate: builder.mutation<FormStructureEntity, CopyFormStructureAndTemplateRequest>({
            query: (payload) => ({
                url: '/v1/formStructures/full-copy',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result) => (result ? ['FormStructures', 'Templates', 'Schemas'] : []),
        }),

        deleteFormStructure: builder.mutation<void, { id: string }>({
            query: (params) => ({
                url: `/v1/formStructures/${params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result) => (result === null ? ['FormStructures'] : []),
        }),
    }),
})

export const {
    useGetFormStructuresQuery,
    useLazyGetFormStructuresQuery,
    useCreateFormStructureMutation,
    useGetFormStructureQuery,
    useLazyGetFormStructureQuery,
    useUpdateFormStructureMutation,
    usePublishFormStructureMutation,
    useCopyFormStructureAndTemplateMutation,
    useDeleteFormStructureMutation,
} = formstructuresApi

export const selectFormStructures = (state: RootState, paginationParams: FormStructuresPaginationParams) =>
    formstructuresApi.endpoints.getFormStructures.select(paginationParams)(state)

export const selectFormStructure = (state: RootState, id: string) =>
    formstructuresApi.endpoints.getFormStructure.select(id)(state)
