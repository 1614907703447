import { SettingsPage } from 'pages/settings/settings.page'
import { ROUTES } from 'constants/routes'
import { OptionsBuilderPage } from 'pages/settings/OptionsBuilder.page'
import { TemplatePage } from 'pages/settings/Templates.page'
import { generateNestedRouteUrl } from 'utils/navigation-utils'

const settingsRoutes = [
    {
        path: '',
        element: <SettingsPage />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.prebuiltOptions, ROUTES.settings),
        element: <OptionsBuilderPage />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.schemaTemplates, ROUTES.settings),
        element: <TemplatePage />,
    },
]

export default settingsRoutes
