import { Checkbox, TableSortLabel } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import { useState } from 'react'
import { TableStructure } from './table-structure.type'
import { Order, Pagination } from './pagination'

type CustomTableProps<T> = {
    tableStructure: TableStructure<T>[]
    total: number
    rows: T[] | undefined
    pagination?: Pagination
    selectedKey?: string
    selectable?: boolean
    selectedItems?: T[]
    defaultOrder?: Order
    orderBy?: string
    rowsPerPageOptions?: number[]
    onRowClick?: (item: T) => any
    onSelectChange?: (items: T[]) => any
}

export const CustomTable: React.FC<CustomTableProps<any>> = ({
    tableStructure,
    pagination,
    total,
    selectedKey = 'id',
    defaultOrder = 'desc',
    selectable = true,
    rows = [],
    rowsPerPageOptions = [10, 25, 50, 100],
    onRowClick,
    selectedItems = [],
    onSelectChange,
}) => {
    const [order, setOrder] = useState<Order>(defaultOrder)
    const [orderBy, setOrderBy] = useState<string>(defaultOrder)
    const onChangeOrder = (columnName: string) => {
        let newOrder = defaultOrder
        if (orderBy === columnName) {
            newOrder = order === 'asc' ? 'desc' : 'asc'
        }
        setOrder(newOrder)
        setOrderBy(columnName)
        if (pagination?.onSortChanged) {
            pagination.onSortChanged(columnName, newOrder)
        }
    }

    const setItems = (items: any) => {
        if (selectable && onSelectChange) {
            onSelectChange(items)
        }
    }
    const handleSelectAll = (checked: boolean) => {
        if (checked) {
            //filter before spreading to add only unique items
            setItems([
                ...selectedItems.filter(
                    (selectedItem) => !rows.find((row) => row[selectedKey] === selectedItem[selectedKey]),
                ),
                ...rows,
            ])
        } else {
            setItems(
                selectedItems.filter(
                    (selectedItem) => !rows.find((row) => row[selectedKey] === selectedItem[selectedKey]),
                ),
            )
        }
    }
    const handleSelectSingle = (item: any, checked: boolean) => {
        if (checked) {
            setItems([...selectedItems, item])
        } else {
            setItems(selectedItems.filter((selectedItem) => selectedItem[selectedKey] !== item[selectedKey]))
        }
    }
    const isSelected = (item: any): boolean => {
        return selectedItems.find((selectedItem) => selectedItem[selectedKey] === item[selectedKey]) !== undefined
    }
    const isAllPageSelected = (): boolean => {
        if (!rows) return false
        return rows.every((rowInPage) => isSelected(rowInPage))
    }

    return (
        <>
            <TableContainer>
                <Table
                    sx={{
                        minWidth: '100%',
                        '.MuiTableCell-head ': {
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#000',
                            borderBottom: '4px solid #f8f8f8',
                        },
                        '.MuiTableCell-body ': {
                            textAlign: 'left',
                            borderBottom: '1px solid #f8f8f8',
                        },
                    }}
                    stickyHeader
                    aria-label="sticky table"
                >
                    <TableHead
                        sx={{
                            '.MuiTableRow-head ': {
                                borderBottom: 'solid 2px #000',
                            },
                        }}
                    >
                        <TableRow>
                            {selectable && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        checked={isAllPageSelected()}
                                        onChange={(event) => handleSelectAll(event.target.checked)}
                                    />
                                </TableCell>
                            )}
                            {tableStructure.map((ts) => (
                                <TableCell
                                    key={ts.columnName}
                                    sortDirection={orderBy === ts.columnName ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === ts.columnName}
                                        direction={orderBy === ts.columnName ? order : defaultOrder}
                                        onClick={(event) => onChangeOrder(ts.columnName)}
                                    >
                                        {ts.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows &&
                            rows.map((row, tableRowIndex) => {
                                return (
                                    <TableRow
                                        onClick={() => (onRowClick ? onRowClick(row) : null)}
                                        sx={
                                            onRowClick
                                                ? {
                                                      cursor: 'pointer',
                                                  }
                                                : null
                                        }
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={tableRowIndex}
                                    >
                                        {selectable && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onClick={(e) => e.stopPropagation()}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    checked={isSelected(row)}
                                                    onChange={(event) => handleSelectSingle(row, event.target.checked)}
                                                />
                                            </TableCell>
                                        )}
                                        {tableStructure.map((ts, index) => (
                                            <TableCell key={`${ts.columnName}_${index}`}>{ts.value(row)}</TableCell>
                                        ))}
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {pagination && (
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={total}
                    rowsPerPage={pagination.itemsPerPage}
                    page={pagination.page}
                    onPageChange={(event, newPage) => pagination.handleChangePage(newPage)}
                    onRowsPerPageChange={(event) => pagination.handleChangeRowsPerPage(event.target.value)}
                />
            )}
        </>
    )
}
