import React, { ReactNode } from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import { useIsAuthenticated, useUserTenantId } from 'redux/slices/auth.slice'
import { ROUTES } from 'constants/routes'
import { useGetTenantSettingsQuery } from 'services/settings.service'
import { PageLoader } from 'components/general/loaders/PageLoader'
import { useGetTenantQuery } from 'services/tenants.service'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useGetMyselfQuery } from 'services/tenantUsers.service'

interface PrivateRouteProps {
    children: ReactNode
}
// used for private routes with user/admin role users
const PrivateAdminRoute = ({ children }: PrivateRouteProps) => {
    const { pathname: currentPath } = useLocation()
    const isAuthenticated = useIsAuthenticated()
    const tenantId = useUserTenantId()

    const { isLoading: isLoadingTenant, isError: isTenantError } = useGetTenantQuery(tenantId ?? skipToken)
    const { isLoading: isLoadingTenantSettings, isError: isSettingsError } = useGetTenantSettingsQuery(undefined, {
        skip: !isAuthenticated,
    })
    const { isLoading: isLoadingMe, isError: isMeError } = useGetMyselfQuery(undefined, { skip: !isAuthenticated })

    const isLoading = isLoadingTenant || isLoadingTenantSettings || isLoadingMe
    const isError = isTenantError || isSettingsError || isMeError

    if (!isAuthenticated || isError) {
        return <Navigate to={ROUTES.loginAdmin} state={{ from: currentPath }} replace />
    }

    if (isLoading) {
        return <PageLoader />
    }

    return <>{children}</>
}

export default PrivateAdminRoute
