import { useParams } from 'react-router-dom'
import { useGetSchemaQuery } from '../../services/schemas.service'
import { SchemaBuilderPage } from './schema-builder.page'

export const EditSchemaPage: React.FC<any> = () => {
    const params = useParams()
    const id = params.id!

    const schema = useGetSchemaQuery(id)

    return schema.isSuccess ? <SchemaBuilderPage initialSchema={schema.data} /> : null
}
