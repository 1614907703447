import React from 'react'

import { Button, Stack, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ModalsWrapper from './DialogWrapper'
import { LoadingButton } from '@mui/lab'

interface ConfirmDialogProps {
    open: boolean
    handleClose: () => void
    onConfirm: () => void
    isLoading?: boolean
    content?: React.ReactNode
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, handleClose, onConfirm, isLoading, content }) => {
    const { t } = useTranslation()

    return (
        <ModalsWrapper
            sx={{
                zIndex: 9999,
            }}
            open={open}
            title={t('common.confirmDialogTitle')}
            handleClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
            body={content ? <ContentWrapper spacing={0}>{content}</ContentWrapper> : undefined}
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={1.5}>
                    <Button
                        variant="outlined"
                        onClick={(e) => {
                            e.stopPropagation()
                            handleClose()
                        }}
                        color="secondary"
                    >
                        {t('common.back')}
                    </Button>
                    <LoadingButton
                        loading={Boolean(isLoading)}
                        disabled={isLoading}
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                            e.stopPropagation()
                            onConfirm()
                        }}
                        sx={(theme) => ({
                            [theme.breakpoints.down('sm')]: {
                                marginTop: theme.spacing(2),
                            },
                        })}
                    >
                        {t('common.confirm')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

export default ConfirmDialog

const ActionWrapper = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))
