import { ROUTES } from 'constants/routes'
import { ProjectCreate } from 'pages/project/ProjectCreate.page'
import { ProjectPreview } from 'pages/project/ProjectPreview.page'
import { ProjectPreviewInProgress } from 'pages/project/ProjectPreviewInProgress.page'
import { ProjectReview } from 'pages/project/ProjectReview.page'
import { generateNestedRouteUrl } from 'utils/navigation-utils'

const projectsRoutes = [
    {
        path: generateNestedRouteUrl(ROUTES.projectCreate, ROUTES.projects),
        element: <ProjectCreate />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.projectReview, ROUTES.projects),
        element: <ProjectReview />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.projectPreview, ROUTES.projects),
        element: <ProjectPreview />,
    },
    {
        path: generateNestedRouteUrl(ROUTES.projectPreviewInProgress, ROUTES.projects),
        element: <ProjectPreviewInProgress />,
    },
]

export default projectsRoutes
