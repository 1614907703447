import React from 'react'
import { Link } from 'react-router-dom'
import { IconButton, Typography } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'

type HeaderBackButtonProps = {
    href: string
    label?: string | null
}

export const HeaderBackButton: React.FC<HeaderBackButtonProps> = React.memo(({ href, label }) => {
    return (
        <>
            <IconButton to={href} component={Link} sx={{ color: '#FFF' }}>
                <ArrowBackIos />
            </IconButton>
            {label && (
                <Typography sx={{ typography: { sm: 'h5', xs: 'body2' }, marginLeft: { xs: 0.5, sm: 1.25 } }}>
                    {label}
                </Typography>
            )}
        </>
    )
})

HeaderBackButton.displayName = 'HeaderBackButtonProps'
