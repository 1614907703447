import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Stack, TextField, styled } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SchemaBuilder } from 'components/forms/schema-builder'
import { useAppSchema } from 'components/forms/hooks/schema-hooks'
import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { ErrorBoundary } from 'components/forms/error'
import { RegisteredJsonForm } from 'components/general/jsonForm/RegisteredJsonForm'
import { JsonFormsCore } from '@jsonforms/core'
import { Schema } from 'redux/types/schema.type'

interface FullPageFormDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions'> {
    structureId?: string
    schema?: Schema
}

export const FullPageFormDialog: React.FC<FullPageFormDialogProps> = ({ handleClose, open, schema, structureId }) => {
    const { t } = useTranslation()

    const { appSchema, setAppSchema, updatePreview, jsonSchema, uiSchema, submit, name, setName, fieldErrors } =
        useAppSchema(schema, {
            onCreate: handleClose,
            assignedStructureId: structureId,
        })

    const [formData, setFormData] = React.useState({})
    const [formErrors, setFormErrors] = React.useState<any>()
    const [convertToGeneral, setConvertToGeneral] = React.useState(false)

    const handleChange = ({ errors, data }: Pick<JsonFormsCore, 'data' | 'errors'>) => {
        setFormData(data)
        setFormErrors(errors)
    }

    return (
        <ModalsWrapper
            sx={{
                zIndex: 9999,
            }}
            open={open}
            title={schema ? schema.name : t('structurePage.createStructureSpecificSchema')}
            handleClose={handleClose}
            fullScreen
            PaperProps={{
                style: {
                    borderRadius: 0,
                },
            }}
            body={
                <>
                    <Box display="flex" alignItems="center" gap={4} flexWrap="wrap">
                        <FormControl fullWidth sx={{ my: 2, maxWidth: 300 }}>
                            <TextField
                                label="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                error={Boolean(fieldErrors?.name)}
                                helperText={Boolean(fieldErrors?.name) ? t(fieldErrors.name) : ''}
                            />
                        </FormControl>
                        {schema?.assignedStructureId && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={convertToGeneral}
                                        onChange={(e) => setConvertToGeneral(e.target.checked)}
                                    />
                                }
                                label={t('structurePage.convertSchemaCheckboxLabel')}
                            />
                        )}
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button onClick={() => updatePreview(appSchema)} variant="outlined">
                                {t('update_preview', 'Update preview')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SchemaBuilder appSchema={appSchema} setAppSchema={setAppSchema} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <ErrorBoundary>
                                <RegisteredJsonForm
                                    data={formData}
                                    onChange={handleChange}
                                    schema={jsonSchema}
                                    uischema={uiSchema}
                                />
                                {jsonSchema ? (
                                    <Stack>
                                        <Button variant="outlined" onClick={() => console.log(formData, formErrors)}>
                                            {t('test_submit', 'Test submit')}
                                        </Button>
                                    </Stack>
                                ) : null}
                            </ErrorBoundary>
                        </Grid>
                    </Grid>
                </>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="flex-end" width="100%" mb={2} mx={1.5}>
                    <Button
                        type="button"
                        variant="outlined"
                        onClick={(e) => submit(convertToGeneral)}
                        color="secondary"
                        sx={{ minWidth: 200 }}
                    >
                        {t('common.save')}
                    </Button>
                </ActionWrapper>
            }
        />
    )
}

const ActionWrapper = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))
