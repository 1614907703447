import React from 'react'
import { PageLoader } from 'components/general/loaders/PageLoader'
import { selectExpirationTokenTime } from 'redux/slices/auth.slice'
import { useLoginCustomerMutation } from 'services/auth.service'
import { StringParam, useQueryParam } from 'use-query-params'
import { Navigate, useNavigate } from 'react-router-dom'
import { ROUTES } from 'constants/routes'
import { useAppSelector } from 'redux/hooks'

export const VerifyCustomerTokenPage: React.FC = () => {
    const exp = useAppSelector(selectExpirationTokenTime)
    const [token] = useQueryParam('code', StringParam)

    const navigate = useNavigate()
    const [loginCustomer, { isError }] = useLoginCustomerMutation()

    React.useEffect(() => {
        if (!token || exp) return
        ;(async () => {
            await loginCustomer({ token })
            navigate(ROUTES.customerHome)
        })()
    }, [exp, loginCustomer, token, navigate])

    // failed to get token or has no token written and wasnt provided in the url
    if (isError || (!token && !exp)) return <Navigate to={ROUTES.login} replace />
    // has token and didnt expire
    if (exp && Date.now() < exp) return <Navigate to={ROUTES.customerHome} replace />

    return <PageLoader />
}
