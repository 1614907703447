import React from 'react'

import { Button, Stack, Typography, styled } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import ModalsWrapper, { ModalWrapperProps } from 'components/general/dialogs/DialogWrapper'
import { useForm } from 'react-hook-form'
import { ControlledMuiTextField } from 'components/inputs/ControlledMuiTextField'
import { LoadingButton } from '@mui/lab'
import { useCopyGeneralSchemaMutation } from 'services/schemas.service'
import { SchemaLightWithDeletable } from 'redux/types/schema.type'

type CopySchemaForm = {
    name: string | null
}

const DEFAULT_VALUES: CopySchemaForm = {
    name: '',
}

interface CopyGeneralSchemaDialogProps extends Omit<ModalWrapperProps, 'title' | 'body' | 'actions'> {
    schema?: SchemaLightWithDeletable
}

const CopyGeneralSchemaDialog: React.FC<CopyGeneralSchemaDialogProps> = ({ open, handleClose, schema }) => {
    const { t } = useTranslation()

    const [copySchema, { isLoading: isCreating }] = useCopyGeneralSchemaMutation()

    const { handleSubmit, control } = useForm<CopySchemaForm>({
        defaultValues: DEFAULT_VALUES,
    })

    const onSubmit = async (values: CopySchemaForm) => {
        if (!schema) throw new Error('Unhandled case, cant call submit without assigned schema')

        await copySchema({
            id: schema.id,
            name: values.name || undefined,
        })
        handleClose()
    }

    return (
        <ModalsWrapper
            open={open}
            title={<Trans i18nKey="schemaPage.copyGeneralSchema" values={{ schemaName: schema?.name }} />}
            handleClose={handleClose}
            fullWidth
            maxWidth="sm"
            body={
                <form id="copy-general-schema-form" onSubmit={handleSubmit(onSubmit)}>
                    <ContentWrapper>
                        <Typography variant="body2">{t('structurePage.copyStructureExplanation')}</Typography>
                        <FormInsideContainer>
                            <ControlledMuiTextField
                                fullWidth
                                control={control}
                                name="name"
                                placeholder={t('structurePage.copyStructureNamePlaceholder')}
                            />
                        </FormInsideContainer>
                    </ContentWrapper>
                </form>
            }
            actions={
                <ActionWrapper flexDirection="row" justifyContent="space-between" width="100%" mb={2} mx={2}>
                    <Button variant="outlined" onClick={handleClose} color="secondary" disabled={isCreating}>
                        {t('common.cancel')}
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        form="copy-general-schema-form"
                        loading={isCreating}
                    >
                        {t('common.save')}
                    </LoadingButton>
                </ActionWrapper>
            }
        />
    )
}

export default CopyGeneralSchemaDialog

const ActionWrapper = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const ContentWrapper = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: 'auto',
        maxWidth: '23.4375rem',
        margin: '1rem',
    },
}))

const FormInsideContainer = styled(Stack)(({ theme }) => ({
    gap: theme.spacing(2),
    margin: '0 auto',
    minWidth: 300,
}))
