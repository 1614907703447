import { Tab, Tabs } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

type FieldTabsProps = {
    value: number
    handleChange: (value: number) => void
    isLayoutItem?: boolean
    withColumnsTab?: boolean
}
export const FieldTabs: React.FC<FieldTabsProps> = ({ value, handleChange, isLayoutItem, withColumnsTab }) => {
    const { t } = useTranslation()

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }
    return (
        <Tabs value={value} onChange={(event, value) => handleChange(value)} aria-label="field tabs">
            {!isLayoutItem && <Tab label="General" {...a11yProps(0)} />}
            <Tab label="Rules" {...a11yProps(1)} />
            {withColumnsTab && <Tab label={t('schemaPage.columnSizesTab')} {...a11yProps(2)} />}
        </Tabs>
    )
}
